import React, { useState, useEffect } from "react";
import {
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
  CSpinner,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import NumberFormat from "react-number-format";
const AddForm = ({ defaultValues, ButtonComponent, action }) => {
  const { selectedItem, success, error, message, modalVisible } = useSelector(
    (state) => state.ProductR
  );
  const [price, setPrice] = useState(0);
  const [defaultCategory, setDefaultCategory] = useState();
  const [defaultUnit, setDefaultUnit] = useState();
  const [defaultSubunit, setDefaultSubunit] = useState();
  const categoryProps = useSelector((state) => state.categoryReducer);
  const unitProps = useSelector((state) => state.unitReducer);
  const { Categories } = categoryProps;
  const { Units, subunitList, subUnitSpin } = unitProps;
  const dispatch = useDispatch();

  // form validation rules

  let obj1 = {
    name: Yup.string().required(
      <IntlMessages id="productStock.name_message" />
    ),
    category: Yup.string().required(
      <IntlMessages id="productStock.category_message" />
    ),
    unit: Yup.string().required(
      <IntlMessages id="productStock.unit_message" />
    ),
    price: Yup.string().required(
      <IntlMessages id="productStock.price_message" />
    ),
    packaging: Yup.string().required(
      <IntlMessages id="productStock.packaging_message" />
    ),
  };

  let obj2 = {
    subunit: Yup.string().required(
      <IntlMessages id="productStock.subunit_message" />
    ),
  };

  const validationSchema = Yup.object().shape(
    subunitList && subunitList.length > 0 ? { ...obj1, ...obj2 } : obj1
  );

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
    reValidateMode: "onChange",
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, watch, formState, control, setValue } =
    useForm(formOptions);

  const { errors } = formState;

  const onFinish = (values) => {
    if (selectedItem === null) {
      if (values.subunit === "") {
        values.subunit = null;
      }
      dispatch(
        DataRequestAction(
          "POST",
          "addProductStock",
          values,
          "",
          "productStock",
          false
        )
      );
    } else {
      if (values.subunit === "") {
        values.subunit = null;
      }
      values._id = selectedItem._id;
      dispatch(
        DataRequestAction(
          "PUT",
          "editProductStock",
          values,
          "",
          "productStock",
          false
        )
      );
    }
  };
  if (success) {
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "productStock_UPDATE",
    });
  }
  if (error) {
    let messageLng = intlPlaceholder(message);
    notify("error", messageLng);
    dispatch({
      type: "productStock_UPDATE",
    });
  }

  const setInitialValue = () => {
    if (defaultValues && Categories && Categories.length > 0) {
      reset(defaultValues);

      let categoryLabelObj =
        Categories &&
        Categories.find((element) => element._id === defaultValues.category);
      let unitLabelObj =
        Units && Units.find((element) => element._id === defaultValues.unit);
      let subUnitsObj =
        defaultValues &&
        defaultValues.subUnits &&
        defaultValues.subUnits.find(
          (element) => element._id === defaultValues.subunit
        );

      setDefaultCategory({
        value: defaultValues && defaultValues.category,
        label: categoryLabelObj.name,
      });
      setDefaultUnit({
        value: defaultValues && defaultValues.unit,
        label: unitLabelObj.name,
      });
      if (subUnitsObj && defaultValues) {
        setDefaultSubunit({
          value: defaultValues && defaultValues.subunit,
          label: subUnitsObj.subUnitName + `(${subUnitsObj.factor})`,
        });
      }

      if (defaultValues && defaultValues.subUnits) {
        setValue("subunit", defaultValues && defaultValues.subunit);
        dispatch({
          type: "getSubunit_SUCCESS",
          payload: { Record: defaultValues.subUnits },
        });
      }
    }
  };

  useEffect(setInitialValue, [modalVisible, Categories]);

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  const renderSubunitOptions = (data, label, key, factor) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label] + `(${data[factor]})`,
        value: data[key],
      }));
    }
  };

  const callSubUnitApi = (value) => {
    setDefaultSubunit({ value: "", label: "" });
    setValue("subunit", "");
    dispatch({
      type: "getSubunit_SUCCESS",
      payload: { Record: [] },
    });
    dispatch(
      DataGetAction("getSubunit", { unitId: value }, "fetchSubUnitSpin")
    );
  };

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <CFormFloating className="mb-3">
              <CFormInput
                className={errors.name ? "is-invalid" : ""}
                {...register("name")}
                placeholder={intlPlaceholder("productStock.name")}
                type="text"
                id="name"
              />
              <CFormLabel htmlFor="name">
                {intlPlaceholder("productStock.name")}
              </CFormLabel>
              <div className="invalid-feedback">{errors.name?.message}</div>
            </CFormFloating>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        {action === "edit" && defaultCategory && (
          <div className="col-md-12 mb-3">
            <Controller
              control={control}
              name="category"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.category ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  defaultValue={defaultCategory}
                  placeholder={intlPlaceholder("productStock.category")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  options={renderOptions([...Categories], "name", "_id")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.category?.message}</div>
          </div>
        )}

        {action === "add" && (
          <div className="col-md-12 mb-3">
            <Controller
              control={control}
              name="category"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.category ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  placeholder={intlPlaceholder("productStock.category")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  options={renderOptions([...Categories], "name", "_id")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.category?.message}</div>
          </div>
        )}

        {action === "edit" && defaultUnit && (
          <div className="col-md-12">
            <Controller
              control={control}
              name="unit"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.category ? "is-invalid" : ""}
                  isSearchable={true}
                  defaultValue={defaultUnit}
                  styles={selectStyle}
                  placeholder={intlPlaceholder("productStock.unit")}
                  onChange={(e) => {
                    onChange(e.value);
                    callSubUnitApi(e.value);
                  }}
                  options={renderOptions([...Units], "name", "_id")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.unit?.message}</div>
          </div>
        )}
        {action === "add" && (
          <div className="col-md-12">
            <Controller
              control={control}
              name="unit"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.category ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  placeholder={intlPlaceholder("productStock.unit")}
                  onChange={(e) => {
                    callSubUnitApi(e.value);
                    onChange(e.value);
                  }}
                  options={renderOptions([...Units], "name", "_id")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.unit?.message}</div>
          </div>
        )}
        {subUnitSpin && <CSpinner variant="border" className="mt-3 ms-3" color="primary" />}
        {action === "edit" && defaultSubunit && (
          <>
            {subunitList && subunitList.length > 0 && (
              <div className="col-md-12 mt-3">
                <Controller
                  control={control}
                  name="subunit"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Select
                      className={errors.subunit ? "is-invalid" : ""}
                      isSearchable={true}
                      isLoading={subUnitSpin}
                      styles={selectStyle}
                      defaultValue={defaultSubunit}
                      placeholder={intlPlaceholder("productStock.subunit")}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      options={renderSubunitOptions(
                        [...subunitList],
                        "subUnitName",
                        "_id",
                        "factor"
                      )}
                    />
                  )}
                />

                <div className="invalid-feedback">
                  {errors.subunit?.message}
                </div>
              </div>
            )}
          </>
        )}
        {action === "add" && (
          <>
            {subunitList && subunitList.length > 0 && (
              <div className="col-md-12 mt-3">
                <Controller
                  control={control}
                  name="subunit"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Select
                      isDisabled={subunitList && subunitList.length === 0}
                      className={errors.subunit ? "is-invalid" : ""}
                      isSearchable={true}
                      isLoading={subUnitSpin}
                      styles={selectStyle}
                      placeholder={intlPlaceholder("productStock.subunit")}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      options={renderSubunitOptions(
                        [...subunitList],
                        "subUnitName",
                        "_id",
                        "factor"
                      )}
                    />
                  )}
                />

                <div className="invalid-feedback">
                  {errors.subunit?.message}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <CFormFloating className="mb-3">
            <CFormInput
              className={errors.packaging ? "is-invalid" : ""}
              {...register("packaging")}
              placeholder={intlPlaceholder("productStock.packaging")}
              type="number"
              id="packaging"
              defaultValue="1"
              min={0}
            />
            <CFormLabel htmlFor="packaging">
              {intlPlaceholder("productStock.packaging")}
            </CFormLabel>
            <div className="invalid-feedback">{errors.packaging?.message}</div>
          </CFormFloating>
        </div>

        <div className="col-md-6">
          <div className="mb-3 number-format-input large">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <NumberFormat
                  className={errors.price ? "is-invalid" : ""}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(v) => onChange(v.value)}
                  value={value}
                  placeholder={intlPlaceholder("productStock.price")}
                />
              )}
              name={"price"}
              control={control}
            />
            <div className="invalid-feedback">{errors.price?.message}</div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <CFormFloating className="mb-3">
            <CFormInput
              {...register("barCode")}
              placeholder={intlPlaceholder("productStock.barCode")}
              type="text"
              id="barCode"
            />
            <CFormLabel htmlFor="barCode">
              {intlPlaceholder("productStock.barCode")}
            </CFormLabel>
          </CFormFloating>
        </div>
        <div className="col-md-6">
          <CFormFloating className="mb-3">
            <CFormInput
              {...register("size")}
              placeholder={intlPlaceholder("productStock.size")}
              type="text"
              id="size"
            />
            <CFormLabel htmlFor="size">
              {intlPlaceholder("productStock.size")}
            </CFormLabel>
          </CFormFloating>
        </div>
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default AddForm;
