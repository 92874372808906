import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  DataRequestAction } from "../../redux/actions/http";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SubProductForm from "./components/SubProductForm";
import SubProductList from "./components/SubProductList";
import {notify} from '../../util/ShowToast'
import Select from 'react-select';


const AddUnit = () => {



  const [SelectedSupplier, setSelectedSupplier] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false)
  const dispatch = useDispatch();
  const prodProps = useSelector((state) => state.productReducer);
  const { Suppliers, SubProducts, selectedReferenceNo,updatedProductList } = prodProps;
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, SetSelectedRow] = useState([]);
  const [CurrencyDefault, setCurrencyDefault] = useState();

  const validationSchema = Yup.object().shape({
    referenceNo: Yup.string().required(
      <IntlMessages id="product.addProductPage.referenceNo_message" />
    ),
    supplier: Yup.string().required(
      <IntlMessages id="product.addProductPage.pleaseSelectSupplier_message" />
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    watch,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

 

  const onFinish = (values) => {


    const referenceNo = getValues("referenceNo");
    if (referenceNo && SubProducts && SubProducts.length > 0) {
      setSelectedRowKeys([]);
      SetSelectedRow([]);
      const data = {
        referenceNo: referenceNo,
        products: SubProducts,
      };
     

      reset();
      dispatch(
        DataRequestAction("POST", "addProduct", data, "", "product", false)
      );
    }
    else{
      notify('error',intlPlaceholder('addAtLeastOneProduct'))
    }
  };

  const checkTheRefNoExit = (e) => {
    // dispatch(DataRequestAction('POST', 'checkRefNo', { referenceNo: e }, '', 'checkRefNoSpinier', false))
  };

  if (prodProps.success) {
    let messageText = intlPlaceholder("Stock.add.success");
   
    notify('success',messageText);
    history.push("/stock");
    dispatch({
      type: "product_UPDATE",
    });
  }

  if (prodProps.error) {
    let messageText = intlPlaceholder("Stock.add.error");
    notify('error',messageText);
    dispatch({
      type: "product_update_error",
    });
  }
  const fetchList = () => {
    return () => {
      dispatch({
        type: "product_UPDATE",
      });
    };
  };
  useEffect(fetchList, []);

  // const onCreate = (onFinish) => {
  // };

  const renderSupplier = (data) =>{
    if(data && data.length > 0){
      return (data.map(data =>({label:data.label,value:data.key})))
    }
   
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '59px',
      height:  '59px',
      boxShadow: state.isFocused ?  `0 0 0 0.25rem rgba(50, 31, 219 , 25%)` : '',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '59px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '59px',
    }),
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
        
        {/* <ToastContainer /> */}
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <h1>
              <IntlMessages id="product.addProductPage.products" />
            </h1>
          </CCardHeader>
          <CCardBody>
            <CForm >
              <div className="row mb-4">
                {/* referenceNo */}
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      className={errors.referenceNo ? "is-invalid" : ""}
                      {...register("referenceNo")}
                      placeholder={intlPlaceholder(
                        "product.addProductPage.referenceNo"
                      )}
                      type="text"
                      disabled={SubProducts.length > 0 ? true : false}
                    />
                    <CFormLabel htmlFor="text">
                    {intlPlaceholder(
                      "product.addProductPage.referenceNo"
                    )}
                  </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.referenceNo?.message}
                    </div>
                  </CFormFloating>
                </div>
                {/* supplier */}
                <div className="col-md-6">
                  <div className="mb-3 ">
                    <Controller
                      control={control}
                      name="supplier"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          className={errors.supplier ? "is-invalid" : ""}
                          styles={customStyles}
                          isDisabled={SubProducts.length > 0 ? true : false}
                          placeholder={intlPlaceholder("product.addProductPage.pleaseSelectSupplier")}
                          // value={value}
                          options={renderSupplier(Suppliers)}
                          isSearchable={true}
                          onChange={(e) => {
                            setSelectedSupplier(e.value);
                         
                            const supplierHasCurrency = Suppliers.filter((item) => item._id === e.value);
                            const currencyDefault = supplierHasCurrency && supplierHasCurrency[0].currencyId;
                            setCurrencyDefault(currencyDefault);

                            onChange(e.value);
                          }}
                        />
                        //   {Suppliers &&
                        //     Suppliers.map((Item) => (
                        //       <option value={Item.key}>{Item.label}</option>
                        //     ))}
                        // </Select>
                      )}
                    />

                    <div className="invalid-feedback">
                      {errors.supplier?.message}
                    </div>
                  </div>
                </div>
              </div>
            </CForm>

            <SubProductForm selectedSupplier={SelectedSupplier} currencyDefault={CurrencyDefault} />

            <div className="mt-4">
              <SubProductList
                selectedRowKeys={selectedRowKeys}
                selectedRow={selectedRow}
                setSelectedRowKeys={setSelectedRowKeys}
                SetSelectedRow={SetSelectedRow}
                setDisableSaveBtn={setDisableSaveBtn}
              />
            </div>

            <div className="row">
              <div className="col-md-4">
                <CButton
                  className="d-block w-100 btn btn-light btn-block"
                  color="dark"
                  variant="outline"
                  onClick={() => {
                    dispatch({
                      type: "product_UPDATE",
                    });
                    reset();
                    history.push("/stock");
                  }}
                >
                  <IntlMessages id="product.addProductPage.cancel" />
                </CButton>
              </div>
              <div className="col-md-4">
                <CButton
                  className="d-block w-100 btn btn-light btn-block"
                  color="danger"
                  variant="outline"
                  onClick={() => {
                    dispatch({
                      type: "product_UPDATE",
                    });
                   
                  }}
                >
                  <IntlMessages id="product.addProductPage.reset" />
                </CButton>
              </div>
              <div className="col-md-4">
                <CLoadingButton
                  className="d-block w-100"
                  onClick={handleSubmit((d) => onFinish(d))}
                  color="primary"
                  loading={prodProps.productLoader}
                  disabled={disableSaveBtn}
                >
                  <span className="text-white">
                    <IntlMessages id="product.addProductPage.save" />
                  </span>
                </CLoadingButton>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
   
    </CRow>
  );
};

export default AddUnit;
