import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import BankTable from "./components/BankTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddBankModal from "./components/AddBankModal";
import EditBankModal from "./components/EditBankModal";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.bankReducer);
  const modalType = "show_bank_modal";

  const fetchBank = () => {
    dispatch(DataGetAction("getAllBank", { query: "all" }, "bankSpinner"));
  };
  useEffect(fetchBank, [comProps.fetchBankInfo]);
  const create = CheckUserAccess('bank',['create']) 
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="bank.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3">
              
               {create&&  <AddBankModal />}
             
              </div>

              <DocsExample href="components/smart-table/">
                <BankTable
                  Banks={comProps.Banks}
                  bankSpinner={comProps.bankSpinner}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditBankModal />
      </CRow>
    </>
  );
};

export default Index;
