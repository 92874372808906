import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CBadge,
  CCardHeader,
  CCollapse,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import CompanyExpenseForm from "./components/CompanyExpenseForm";
import ExpenseList from "../../components/CSSmartTable";
import Filter from './components/Filter'
import { intlPlaceholder } from "src/util/intlPlaceholder";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({ status: 'approved' });

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const columns = [
    {
      label: <IntlMessages id="ce.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="ce.Ref" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="ce.account" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="ce.type" />,
      key: "type",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="ce.amount" />,
      key: "amount",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approved'), value: 'approved', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },
    {
      label: '',
      sorter: false,
      filter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.ceReducer);

  const modalType = "show_ce_modal";

  const viewRecord = (data) => {
    history.push({ pathname: "/viewExpenseDetail", state: { _id: data._id } });
  };

  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const fetchBankDrop = () => {
    dispatch(DataGetAction("fetchCompanyBank", "", "fetching"));
  };
  useEffect(fetchBankDrop, []);

  const fetchBank = () => {
    dispatch(
      DataGetAction("getAllExpense", { query: "all" }, "companyExpensesSpinner")
    );
    dispatch({
      type: "createdByFromExpense",
    });
  };
  useEffect(fetchBank, [comProps.fetchBankInfo]);

  const fetchCreatedBy = () => {
    dispatch({
      type: "createdByFromExpense",
    });
  };
  useEffect(fetchCreatedBy, [comProps.Banks]);

  const getBadge = (status) => {
    switch (status) {
      case 'approved':
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'pending':
        return 'warning'
      case 'block':
        return 'danger'
      default:
        return 'primary'
    }
  }

  const create = CheckUserAccess('companyExpenses', ['create'])


  return (
    <>
      <CRow>
        <CCol xs={12}>

          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="ce.title" />
              </h1>

            </CCardHeader>
            <CCardBody>
              <div className="d-flex flex-wrap" style={{ gap: 10 }}>
                <Filter name="name" />
                {create && <CompanyExpenseForm />}

              </div>

              <div className="px-3">

              </div>
              <DocsExample href="components/smart-table/">
                <ExpenseList
                  loading={comProps.companyExpensesSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={comProps.companyExpensesSpinner ? [] : comProps.Banks}
                  columns={columns}
                  filterValueChangeTo={(value) => {
                    if (value.payableStatus) {
                      setStaticFilterValue(value)
                    }
                  }}
                  columnFilter
                  columnFilterValue={staticFilterValue}
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },
                    Ref: (record) => {
                      return (
                        <td>
                          <span>{record.prefix + "-" + record.expenseNo}</span>
                        </td>
                      );
                    },
                    bankName: (record) => {
                      return <td style={{ textTransform: "capitalize" }}>{record.bankName === 'cashCaisse' ? <IntlMessages id={`${record.bankName}.bankName`} /> : record.bankName}</td>;
                    },
                    type: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                            {record.type === "office_Operating_Cost"
                              ? "Office Operating Cost"
                              : record.type}
                          </span>
                        </td>
                      );
                    },
                    expenseNo: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                            {record.expenseNo}
                          </span>
                        </td>
                      );
                    },

                    amount: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            parseFloat(record.amount).toFixed(2)
                          )}
                        </td>
                      );
                    },

                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.status === 'approved' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.status}`)}</CBadge>
                        </td>
                      );
                    },
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => viewRecord(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let totalExpense = 0;

                    pageData.forEach(({ amount }) => {
                      totalExpense += amount;
                    });

                    return (
                      <tr>
                        <th colSpan={2} className="gx-text-center"><h5>{intlPlaceholder('TotalExpense')}</h5></th>
                        <th colSpan={3} className="text-end" >
                          <span><h5>{ChangeNumberFormate(totalExpense.toFixed(2))}</h5></span>
                        </th>
                        <th colSpan={2}></th>
                      </tr>

                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
