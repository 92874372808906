import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CProgress,
    CRow,
} from '@coreui/react-pro'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import {
    cilCloudDownload,
} from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'src/redux/actions/http'
import { intlPlaceholder } from 'src/util/intlPlaceholder'
import Select from "react-select";
import { NumberWithCommas, selectStyle } from 'src/constants/CommonFn'


const CompanyExpensesGraph = () => {

    const dispatch = useDispatch()
    const stateProps = useSelector(state => state.dashboard)
    const { companyExpensesGraphData, companyExpensesGraphLoader } = stateProps

    let constructionTypeValue = ['construction', 'divers', 'office_Operating_Cost', 'manutention', 'salaire']

    const fetchGraphDetail = () => {
        dispatch(DataGetAction('companyExpensesGraph',
            {
                'query': "all",
                startDate: moment(),
                constructionType: constructionTypeValue
            },
            'companyExpensesGraphSpinner'))
    }



    useEffect(fetchGraphDetail, [])





    const renderGraph = () => {
        if (!companyExpensesGraphLoader && companyExpensesGraphData) {

            const { labels, dataSetValue, totalEachDayDataSet, currentDayTotal } = companyExpensesGraphData

            return (
                <CChartLine
                    style={{ height: '300px', marginTop: '10px' }}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                label: intlPlaceholder("Total"),
                                backgroundColor: hexToRgba(getStyle('--cui-info'), 20),
                                borderColor: getStyle('--cui-info'),
                                pointHoverBackgroundColor: getStyle('--cui-info'),
                                borderWidth: 2,
                                data: totalEachDayDataSet || [],
                                fill: true,
                            },
                            {
                                label: intlPlaceholder("Construction"),
                                borderColor: getStyle('--cui-primary'),
                                pointHoverBackgroundColor: getStyle('--cui-primary'),
                                borderWidth: 2,
                                data: dataSetValue && dataSetValue['construction'] ? dataSetValue['construction'] : [],
                                fill: true,
                            },
                            {
                                label: intlPlaceholder('Diver'),
                                backgroundColor: 'transparent',
                                borderColor: getStyle('--cui-secondary'),
                                pointHoverBackgroundColor: getStyle('--cui-secondary'),
                                borderWidth: 2,
                                data: dataSetValue && dataSetValue['divers'] ? dataSetValue['divers'] : [],
                            },
                            {
                                label: intlPlaceholder('FonctionnementBureaux'),
                                backgroundColor: 'transparent',
                                borderColor: getStyle('--cui-green'),
                                pointHoverBackgroundColor: getStyle('--cui-green'),
                                borderWidth: 2,
                                data: dataSetValue && dataSetValue['office_Operating_Cost'] ? dataSetValue['office_Operating_Cost'] : [],
                            },
                            {
                                label: intlPlaceholder('Manutention'),
                                backgroundColor: 'transparent',
                                borderColor: getStyle('--cui-yellow'),
                                pointHoverBackgroundColor: getStyle('--cui-yellow'),
                                borderWidth: 2,
                                data: dataSetValue && dataSetValue['manutention'] ? dataSetValue['manutention'] : [],
                            },
                            {
                                label: intlPlaceholder('salaries'),
                                backgroundColor: 'transparent',
                                borderColor: getStyle('--cui-gray'),
                                pointHoverBackgroundColor: getStyle('--cui-gray'),
                                borderWidth: 2,
                                data: dataSetValue && dataSetValue['salaire'] ? dataSetValue['salaire'] : [],
                            },

                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                align: 'end'
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    drawOnChartArea: true,
                                },
                            },
                            y: {
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 5,
                                    stepSize: Math.ceil(250 / 5),
                                    max: 250,
                                },
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.4,
                            },
                            point: {
                                radius: 0,
                                hitRadius: 10,
                                hoverRadius: 4,
                                hoverBorderWidth: 3,
                            },
                        },
                    }}
                />
            )
        }

        if (companyExpensesGraphLoader) {
            return <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        }
    }



    return (
        <CCard className="mb-4">
            <CCardBody>
                <CRow>
                    <CCol sm={5}>
                        <h4 id="traffic" className="card-title mb-0">
                            {intlPlaceholder('companyExpenseGraphTitle')}
                        </h4>
                        <div className="small text-medium-emphasis"> {intlPlaceholder('companyExpenseGraphSubTitle')}</div>
                    </CCol>
                    <CCol sm={2}></CCol>
                </CRow>
                {renderGraph()}
                <CRow>
                    <CCol style={{textAlign:'center' }}>
                      {intlPlaceholder('currentDayTotal')}:{companyExpensesGraphData && NumberWithCommas(companyExpensesGraphData.currentDayTotal) || 0}
                    </CCol>
                </CRow>
            </CCardBody>

        </CCard>
    )
}

export default CompanyExpensesGraph