import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "../../../util/ShowToast";
import moment from "moment";
import NumberFormat from "react-number-format";
const AddModal = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const modalType = "show_fundTransfer_modal";
  // form validation rules

  const validationSchema = Yup.object().shape({
    sourceAccount: Yup.string().required(
      <IntlMessages id="fundTransfer.sourceAccount.error_message" />
    ),
    destinationAccount: Yup.string().required(
      <IntlMessages id="fundTransfer.destinationAccount.error_message" />
    ),
    transferMode: Yup.string().required(
      <IntlMessages id="fundTransfer.transferMode.error_message" />
    ),
    transferDate: Yup.string().required(
      <IntlMessages id="fundTransfer.transferDate.error_message" />
    ),
    amount: Yup.string().required(
      <IntlMessages id="fundTransfer.amount.error_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;
  const accountProps = useSelector((state) => state.accountReducer);
  const {
    Accounts,
    DestinationAccount,
    transferringLoader,
    transferringSuccess,
    transferringError,
    showTransferModal,
  } = accountProps;

  const setInitialValues = () => {
    reset({
      transferDate: new Date().toISOString(),
    });
  };
  useEffect(setInitialValues, [showTransferModal]);

  const fetchAllAccount = () => {
    dispatch(
      DataGetAction("getAllAccount", { query: "all" }, "gettingAccount")
    );
  };

  useEffect(fetchAllAccount, []);

  if (transferringSuccess) {
    fetchAllAccount()
    reset();
    let messageLng = intlPlaceholder(accountProps.message);
    notify("success", messageLng);
    setAmount(undefined);
    dispatch(
      DataGetAction(
        "getAllBankTransfer",
        { query: "all" },
        "allBankTransferSpinner"
      )
    );
    dispatch({
      type: "account_UPDATE",
    });
  }

  const renderDestinationAccount = () => {
    if (DestinationAccount && DestinationAccount.length) {
      return DestinationAccount.map((Item) => {
        return (
          <option value={Item._id}>
            {Item.bank} ({Item.number} - {Item.currencySymbol})
          </option>
        );
      });
    }
  };

  const handleSourceAccountChange = (data) => {
    const selectedAccount = Accounts.filter((Item) => Item._id === data);
    if (selectedAccount && selectedAccount.length) {
      dispatch(
        DataGetAction(
          "getSameCurrencyAccount",
          {
            query: "all",
            accountId: data,
            currencyId: selectedAccount[0].currencyId,
            paymentMode: selectedAccount[0].paymentMode,
          },
          "gettingAccount"
        )
      );
    }
  };

  const transferFund = (values) => {

    const selectedAccount = Accounts.filter(
      (Item) => Item._id === values.sourceAccount
    );
    const selectedDestinationAccount = Accounts.filter(
      (Item) => Item._id === values.destinationAccount
    );
    values.currencyId = selectedAccount[0].currencyId;
    values.selectedPaymentMode = selectedAccount[0].paymentMode;
    values.selectedDestinationPaymentMode =
      selectedDestinationAccount &&
      selectedDestinationAccount.length > 0 &&
      selectedDestinationAccount[0].paymentMode;

    dispatch(
      DataRequestAction(
        "POST",
        "transferAmount",
        values,
        "",
        "transferringAmount",
        false
      )
    );
  };

  // const transferModeWatch = watch("transferMode");
  // const sourceAccountWatch = watch("sourceAccount");
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  // useEffect(() => {
  //   if (sourceAccountWatch) {
  //     handleSourceAccountChange(sourceAccountWatch);
  //   }
  // }, [sourceAccountWatch]);

  const renderItems = (Item) => {
    if (Item.bank === "Cash") {
      return (
        <option value={Item._id}>
          {intlPlaceholder("cashCaisse.bankName")} ({Item.number} -{" "}
          {Item.currencySymbol})
        </option>
      );
    } else {
      return (
        <option value={Item._id}>
          {Item.bank} ({Item.number} - {Item.currencySymbol})
        </option>
      );
    }
  };

  return (
    <div>
      <CButton
        color="primary"
        className="d-flex align-items-center"
        onClick={() => showModal(null)}
      >
        <i className="gx-icon-Trust-Fund me-2"></i>
        <IntlMessages id="fundTransfer.transferMode.button" />
      </CButton>

      <CModal visible={showTransferModal} size="lg">
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            <IntlMessages id="fundTransfer.title" />
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(transferFund)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-6">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="sourceAccount"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.sourceAccount ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          handleSourceAccountChange(e.target.value);

                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder(
                            "fundTransfer.sourceAccount.placeholder"
                          )}
                        </option>
                        {Accounts &&
                          Accounts.length > 0 &&
                          Accounts.map((Item) => {
                            return <>{renderItems(Item)}</>;
                          })}
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                    {intlPlaceholder("fundTransfer.sourceAccount.placeholder")}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.sourceAccount?.message}
                  </div>
                </CFormFloating>
              </div>
              <div className="col-md-6">
                <CFormFloating className="mb-3">
                  <CFormSelect
                    {...register("destinationAccount")}
                    className={errors.destinationAccount ? "is-invalid" : ""}
                    disabled={
                      DestinationAccount && DestinationAccount.length === 0
                        ? true
                        : false
                    }
                  >
                    <option value="" hidden>
                      {intlPlaceholder(
                        "fundTransfer.destinationAccount.placeholder"
                      )}
                    </option>
                    {DestinationAccount &&
                      DestinationAccount.length > 0 &&
                      DestinationAccount.map((Item) => {
                        if (Item.bank === "Cash") {
                          return (
                            <option value={Item._id}>
                              {intlPlaceholder("cashCaisse.bankName")} (
                              {Item.number} - {Item.currencySymbol})
                            </option>
                          );
                        } else {
                          return (
                            <option value={Item._id}>
                              {Item.bank} ({Item.number} - {Item.currencySymbol}
                              )
                            </option>
                          );
                        }
                      })}
                  </CFormSelect>
                  <CFormLabel htmlFor="">
                    {intlPlaceholder(
                      "fundTransfer.destinationAccount.placeholder"
                    )}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.destinationAccount?.message}
                  </div>
                </CFormFloating>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="transferMode"
                    className={errors.transferMode ? "is-invalid" : ""}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.transferMode ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          handleSourceAccountChange(e.target.value);

                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder(
                            "fundTransfer.transferMode.placeholder"
                          )}
                        </option>
                        <option value="cheque">
                          {intlPlaceholder("Cheque")}
                        </option>
                        <option value="virement">
                          {intlPlaceholder("Virement")}
                        </option>
                        <option value="versement">
                          {intlPlaceholder("Versement")}{" "}
                        </option>
                        <option value="caisse">
                          {intlPlaceholder("Caisse")}
                        </option>
                        <option value="espece">
                          {intlPlaceholder("Espece")}
                        </option>
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                    {intlPlaceholder("fundTransfer.transferMode.placeholder")}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.transferMode?.message}
                  </div>
                </CFormFloating>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="transferDate"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <DatePicker
                        value={moment(value).format("YYYY-MM-DD")}
                        className={
                          errors.transferDate
                            ? "is-invalid custom-input-style"
                            : "custom-input-style"
                        }
                        selected={selectedDate}
                        format="dd/mm/yyyy"
                        onChange={(date) => {
                          setSelectedDate(date);
                          onChange(date.toISOString());
                        }}
                        wrapperClassName="custom-date-picker"
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.transferDate?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.remark ? "is-invalid " : ""}
                    {...register("referenceNumber")}
                    placeholder={intlPlaceholder(
                      "fundTransfer.ReferenceNumber.placeholder"
                    )}
                    type="text"
                    id="remark"
                  />
                  <CFormLabel htmlFor="remark">
                    {intlPlaceholder(
                      "fundTransfer.ReferenceNumber.placeholder"
                    )}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.referenceNumber?.message}
                  </div>
                </CFormFloating>
              </div>
              <div className="col-md-6">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={
                          errors.amount
                            ? "is-invalid  custom-input-style"
                            : "custom-input-style"
                        }
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder("amount")}
                      />
                    )}
                    name={"amount"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amount?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <CFormFloating>
                  <CFormTextarea
                    className={errors.note ? "is-invalid" : ""}
                    {...register("note")}
                    placeholder={intlPlaceholder(
                      "fundTransfer.note.placeholder"
                    )}
                    rows={5}
                    id="note"
                  />
                  <CFormLabel htmlFor="note">
                    {intlPlaceholder("fundTransfer.note.placeholder")}
                  </CFormLabel>
                </CFormFloating>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="admin.addAdminModal.cancel" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={transferringLoader}
              disabled={transferringLoader}
            >
              {intlPlaceholder("Submit")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddModal;
