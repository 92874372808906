import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormSelect,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataRequestAction,
  DataGetAction,
} from "../../../redux/actions/http";
import NumberFormat from "react-number-format";
import moment from "moment";
import { notify } from '../../../util/ShowToast'
import Select from "react-select";
import { selectStyle } from '../../../constants/CommonFn'
import { useHistory } from "react-router-dom";
const WithDrawForm = ({ findBankByCurrencyId, type, title }) => {
  const modalType = "WithDraw_modal";
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [amount, setAmount] = useState(undefined);
  const history = useHistory()
  const [selectedUserTyp, setSelectedUserType] = useState("employee");
  const comProps = useSelector((state) => state.trustFund);
  const currencyProps = useSelector((state) => state.currencyReducer);
  const {
    Employee,
    LastBalance,
    balanceChange,
    CompanyBankList,
    withDrawModalVisible,
    selectedItem,
    trustLoader,
    latestWithDrawRecord
  } = comProps;
  const { Currencies } = currencyProps;



  // form validation rules
  const validationSchema = Yup.object().shape({
    trusty: Yup.string().required(<IntlMessages id="trust.nameRequired" />),

    currency: Yup.string().when("trusty", {
      is: (val) => selectedUserTyp === "trusty",
      then: Yup.string().required(
        <IntlMessages id="trust.currencyRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),
    bank: Yup.string().when("trusty", {
      is: (val) => selectedUserTyp === "trusty",
      then: Yup.string().required(
        <IntlMessages id="trust.accountRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),
    depositDate: Yup.string().when("trusty", {
      is: (val) => selectedUserTyp === "trusty",
      then: Yup.string().required(
        <IntlMessages id="trust.accountRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),
    amount: Yup.string().required(<IntlMessages id="trust.amountRequired" />),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    watch,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;




  const findTheBackByCurrency = (e) => {
    findBankByCurrencyId(e);
    fetchTrustLastBalance(e);
  };

  const setUserType = (userId) => {
    const selectedUser = Employee.filter((Item) => Item._id === userId);


    dispatch({
      type: "getUserLastBalance_SUCCESS",
      payload: { LastBalance: 0 },
    });
    if (selectedUser[0].role === "trusty") {
      setSelectedUserType("trusty");
    } else {
      dispatch(
        DataGetAction(
          "getUserLastBalance",
          { userId, type: "nTrust" },
          "fetchingData"
        )
      );
      setSelectedUserType("employee");
    }
  };

  const setInitialValue = () => {
    reset({

      depositDate: new Date().toISOString(),
    });
  }

  useEffect(setInitialValue, [withDrawModalVisible])

  const findTheBackByCurrencyDefault = () => {
    if (
      Currencies &&
      Currencies.length > 0 &&
      withDrawModalVisible &&
      selectedUserTyp === "trusty"
    ) {
      findTheBackByCurrency(Currencies[0]._id);
    }

  };

  useEffect(findTheBackByCurrencyDefault, [
    Currencies,
    withDrawModalVisible,
    selectedUserTyp,
  ]);

  const fetchTrustLastBalance = (currencyId) => {
    const selectedUser = getValues("trusty");
    // const selectedUser = form.getFieldValue(trustValue);

    dispatch(
      DataGetAction(
        "getUserLastBalance",
        { userId: selectedUser, currencyId: currencyId, type: "trust" },
        "fetchingData"
      )
    );
  };

  if (balanceChange) {
    // form.setFieldsValue({ balance: LastBalance });
    // reset({
    //   balance: LastBalance,
    // });
    setValue("balance", LastBalance, { shouldValidate: true });
    dispatch({
      type: "resetAmount",
    });
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  const onFinish = (values) => {
    let selectedBank = CompanyBankList.filter(
      (Item) => Item._id === values.bank
    );

    if (selectedBank && selectedBank.length > 0) {
      values.paymentMode = selectedBank[0].paymentMode;
      values.accountNumber = selectedBank[0].number;
      if (values.paymentMode === 'bank') {
        values.bankId = selectedBank[0].bankId;
      }
    }

    values.userType = selectedUserTyp;



    dispatch(
      DataRequestAction("POST", "withDrawAmount", values, "", "trust", false)
    );
  };

  const fetchData = () => {
    dispatch(DataGetAction("balanceSheetForTrustUser", {
      query: 'withdraw', startDate: moment(),
      endDate: moment(),
    }, "fetchingBalanceSheetForTrustUser"));
  }

  if (comProps.success) {
    reset();
    notify('success', intlPlaceholder('withDrawSuccess'));
    if (latestWithDrawRecord.prefix === "TF") {
      history.push("/viewTrustDetail", { _id: latestWithDrawRecord._id });
    }
    dispatch({
      type: "trust_UPDATE",
    });
  }

  const renderOptions = (Employee) => {

    if (Employee && Employee.length > 0) {
      return Employee.map((data) => ({
        label: `${data.firstName} ${data.lastName ? data.lastName : ''} ${(data.empId)}`,
        value: data._id,
      }));
    }
    else {
      return []
    }
  }
  const renderCompanyBankList = (CompanyBankList) => {
    if (CompanyBankList && CompanyBankList.length > 0) {
      return CompanyBankList.map((data) => {
        if (data.bank === 'Cash') {
          return {
            label: `${intlPlaceholder("cashCaisse.bankName")}  (${data.currencySymbol})`,
            value: data._id,
          };
        } else {
          return {
            label: `${data.bank}  (${data.currencySymbol})`,
            value: data._id,
          };
        }

      });
    } else {
      return [];
    }
  };

  const renderCurrency = (Currency) => {

    if (Currency && Currency.length > 0) {
      return Currency.map((data) => ({
        label: `${data.name}  (${data.symbol})`,
        value: data._id,
      }));
    }
    else {
      return []
    }
  }

  return (
    <div>
      <CModal visible={withDrawModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder('WithDrawTitle')}
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="mb-3">

              <Controller
                control={control}
                name="trusty"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <>
                    <Select
                      styles={selectStyle}
                      className={errors.trusty ? "is-invalid" : ""}
                      isSearchable={true}
                      placeholder={intlPlaceholder("selectClient")}

                      onChange={(e) => {
                        setUserType(e.value);
                        onChange(e.value);
                      }}
                      options={renderOptions(Employee)}
                    />

                  </>
                )}
              />
              <div className="invalid-feedback">{errors.trusty?.message}</div>
            </div>

            {selectedUserTyp === "trusty" && (
              <div className="mb-3">
                <Controller
                  control={control}
                  name="currency"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <>
                      <Select
                        styles={selectStyle}
                        className={errors.currency ? "is-invalid" : ""}
                        isSearchable={true}
                        placeholder={intlPlaceholder(
                          "account.createAccountModal.currency_placeholder"
                        )}
                        onChange={(e) => {
                          findTheBackByCurrency(e.value)
                          onChange(e.value);
                        }}
                        options={renderCurrency(Currencies)}
                      />

                    </>
                  )}
                />

                <div className="invalid-feedback">
                  {errors.currency?.message}
                </div>
              </div>
            )}
            {selectedUserTyp === "trusty" && (
              <div className="mb-3">
                <Controller
                  control={control}
                  name="bank"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <>
                      <Select
                        styles={selectStyle}
                        className={errors.bank ? "is-invalid" : ""}
                        isSearchable={true}
                        placeholder={intlPlaceholder("selectAccount")}
                        onChange={(e) => {
                          onChange(e.value);
                        }}
                        options={renderCompanyBankList(CompanyBankList)}
                      />

                    </>
                  )}
                />

                <div className="invalid-feedback">{errors.bank?.message}</div>
              </div>
            )}

            <div className="mb-3">
              <CFormInput
                className={errors.balance ? "is-invalid" : ""}
                {...register("balance")}
                placeholder={intlPlaceholder(
                  "account.createAccountModal.balanceAmount"
                )}
                type="text"
                id="balance"
                disabled
              />
              <div className="invalid-feedback">{errors.balance?.message}</div>
            </div>

            <div className="mb-3 number-format-input">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <NumberFormat
                    className={errors.amount ? "is-invalid" : ""}
                    allowNegative={false}
                    thousandSeparator={true}
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    placeholder={intlPlaceholder('amount')}
                  />
                )}
                name={'amount'}
                control={control}

              />
              <div className="invalid-feedback">{errors.amount?.message}</div>
            </div>

            {selectedUserTyp === "trusty" && <div className="mb-3">
              <Controller
                control={control}
                name="depositDate"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <DatePicker
                    value={moment(value).format('YYYY-MM-DD')}
                    maxDate={new Date()}
                    selected={selectedDate}
                    format="dd/mm/yyyy"
                    onChange={(date) => {
                      setSelectedDate(date)
                      onChange(date.toISOString())
                    }}
                    wrapperClassName="custom-date-picker"
                  />
                )}
              />
            </div>}

            <CFormFloating className="mb-3">
              <CFormInput
                className={errors.remark ? "is-invalid" : ""}
                {...register("remark")}
                placeholder={intlPlaceholder("Remark")}
                type="text"
                id="remark"
              />
              <CFormLabel htmlFor="remark">
                {intlPlaceholder("Remark")}
              </CFormLabel>
              <div className="invalid-feedback">{errors.remark?.message}</div>
            </CFormFloating>
          </CModalBody>

          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              {intlPlaceholder("CancelButtonTitle")}
            </CButton>

            <CLoadingButton type="submit" color="primary" loading={trustLoader} disabled={trustLoader}>
              {intlPlaceholder("SaveButtonTitle")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default WithDrawForm;
