import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    modalEditVisible: false,
    passModalVisible: false,
    selectedItem: null,
    bankLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Banks: [],
    fetchBankInfo: false,
    bankSpinner: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getAllBank_SUCCESS': {
            return { ...state, Banks: action.payload.Record, fetchBankInfo: false };
        }
        case 'bankSpinner_START':{
            return { ...state,  bankSpinner: true };
        }
        case 'bankSpinner_STOP':{
            return { ...state,   bankSpinner: false };
        }

        case 'show_bank_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'show_bank_modal_edit': {
            return { ...state, modalEditVisible: !state.modalEditVisible, selectedItem: action.payload };
        }
   
        case 'bank_START': {
            return { ...state, bankLoader: true };
        }
        case 'bank_STOP': {
            return { ...state, bankLoader: false };
        }

        case 'addBank_SUCCESS': {
            return { ...state, success: true, message: "recordAddedSuccessfully", Banks: [action.payload.Record, ...state.Banks], bankLoader: false };
        }
        case 'addBank_FAILURE': {
            return { ...state, error: true, success: false, bankLoader: false, message: action.payload };
        }


        case 'editBank_SUCCESS': {
            let oldCat = state.Banks;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Banks: newCat, bankLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editBank_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, bankLoader: false, message: action.payload, modalVisible: false };
        }

        case 'bank_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                modalEditVisible: false,
                resendError: false,
                resendSuccess: false,
                bankLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null

            };
        }

        case 'deleteBank_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Banks;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Banks: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }

}
