import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import { CForm, CButton, CLoadingButton } from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import * as Yup from "yup";
// import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { ChangeNumberFormate, selectStyle } from "../../../constants/CommonFn";
import { notify } from "src/util/ShowToast";
const AddQuotationsForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [totalValue, setTotalValue] = useState(0);
  const [DisableSaveBtn, setDisableSaveBtn] = useState(false);
  const [productIds, selectProductIds] = useState([]);
  const [paymentMethodValue, setPaymenyMethod] = useState("cash");
  const quotationsProps = useSelector((state) => state.quotationsR);
  const { localProducts, quotationAddSpin, quotationSuccess } = quotationsProps;
  const storeProps = useSelector((state) => state.storeReducer);
  const { storeDropDownLoader, StoresDropDownList } = storeProps;
  const productProps = useSelector((state) => state.ProductR);
  const { ProductList, distributeDropSpin } = productProps;
  const comProps = useSelector((state) => state.receiptReducer);
  const { CompanyAccount, fetchCompanyBankSpin } = comProps;
  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");
  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="distributeProducts.pleaseSelectStore_message" />
    ),
    bank:
      paymentMethodValue === "bank" &&
      Yup.object().required(<IntlMessages id="quotation_bank_error_messgae" />),
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };
  const { handleSubmit, reset, formState, control, getValues } =
    useForm(formOptions);
  const { errors } = formState;
  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setQuotationLocalProduct",
      payload: values,
      ProductList: ProductList,
    });
  };

  const callDropDownApies = () => {
    dispatch(
      DataGetAction("getStoresDropDownList", "", "storesDropDownListSpin")
    );
    dispatch(
      DataGetAction(
        "getAllProductStock",
        { query: "all" },
        "getAllProductStockSpin"
      )
    );
    dispatch(DataGetAction("fetchCompanyBank", "", "fetchCompanyBankSpin"));
  };

  useEffect(callDropDownApies, []);
  const renderTotalPrice = (rebateValue = 0) => {
    let total = 0;
    localProducts &&
      localProducts.length > 0 &&
      localProducts.forEach((item) => {
        total = item.totalPrice + total;
      });
    total = total - parseFloat(rebateValue);
    total = ChangeNumberFormate(total.toFixed(2));
    setTotalValue(total);
  };

  useEffect(renderTotalPrice, [localProducts]);
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  const renderBankOptions = (data, label, number, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: `${data[label]} (${data[number]})`,
        value: data[key],
        bankName:`${data[label]}`,
        accountNumber: `${data[number]}`,
      }));
    }
  };
  const deleteRecord = (record) => {
    dispatch({
      type: "quotationDeleteLocalProduct",
      payload: record,
    });
  };
  let columnsNew = [
    {
      dataField: "productName",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },
    {
      dataField: "unitName",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },

    {
      dataField: "Qty",
      text: <IntlMessages id="product.quantity" />,
      editable: true,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="stock.numeric" />,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "price",
      text: <IntlMessages id="product.UnitPrice" />,
      editable: true,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue < 1) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeBiggerThan1,
          };
        } else {
          setDisableSaveBtn(false);
          return {
            valid: true,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "totalPrice",
      text: <IntlMessages id="product.TotalPrice" />,
      editable: false,
      formatter: (cell, record) => {
        return <span> {ChangeNumberFormate(parseFloat(cell).toFixed(2))}</span>;
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },
    },
  ];
  const submitAllValue = () => {
    let payObj = {
      mode: "cash",
    };
    if (getValues("paymentMethod") === "bank") {
      payObj = {
        mode: getValues("paymentMethod"),
        bank: {
          _id: getValues("bank").value,
          name: getValues("bank").bankName,
          accountNumber: getValues("bank").accountNumber,
        },
      };
    }
    let obj = {
      storeId: getValues("store"),
      paymentMode: payObj,
      products: localProducts,
      totalPrice: totalValue && totalValue.props && totalValue.props.value,
    };
    dispatch(
      DataRequestAction("POST", "addQuotation", obj, "", "addQuotationSpin")
    );
  };

  if (quotationSuccess) {
    history.push("/quotations");
    reset();
    notify("success", intlPlaceholder("recordAddeddSuccessfully"));
    dispatch({ type: "quotationReset" });
    dispatch({ type: "RESET_Quotation_LOCAL_PRODUCT" });
  }

  return (
    <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="store"
              render={({ field: { onChange } }) => (
                <Select
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  isLoading={storeDropDownLoader}
                  styles={selectStyle}
                  isDisabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder("quotations.store_placeholder")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  options={renderOptions(StoresDropDownList, "label", "value")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="product"
              key={1}
              render={({ field: { onChange } }) => (
                <Select
                  styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  isLoading={distributeDropSpin}
                  disabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder(
                    "quotations.product_placeholder"
                  )}
                  onChange={(values) => {
                    let selectedIds = values.map((item) => item.value);
                    selectProductIds(selectedIds);
                    onChange(selectedIds);
                  }}
                  options={renderOptions(ProductList, "name", "_id")}
                />
              )}
            />

            <div className="error-message">{errors.product?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="paymentMethod"
              key={1}
              render={({ field: { onChange, value } }) => (
                <Select
                  defaultValue={{
                    label: intlPlaceholder("cash"),
                    value: "cash",
                  }}
                  styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  isLoading={distributeDropSpin}
                  disabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder("choosePaymentMethod")}
                  onChange={(value) => {
                    setPaymenyMethod(value.value);
                    onChange(value.value);
                  }}
                  options={renderOptions(
                    [
                      { label: intlPlaceholder("cash"), value: "cash" },
                      { label: intlPlaceholder("bankTransfer"), value: "bank" },
                    ],
                    "label",
                    "value"
                  )}
                />
              )}
            />

            <div className="error-message">{errors.paymentMethod?.message}</div>
          </div>
        </div>
        {paymentMethodValue === "bank" && (
          <div className="col-6">
            <div className="mb-3">
              <Controller
                control={control}
                name="bank"
                key={1}
                render={({ field: { onChange } }) => (
                  <Select
                    styles={selectStyle}
                    className={errors.store ? "is-invalid" : ""}
                    isSearchable={true}
                    isLoading={fetchCompanyBankSpin}
                    disabled={
                      localProducts && localProducts.length > 0 ? true : false
                    }
                    placeholder={intlPlaceholder("chooseBank")}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    options={renderBankOptions(
                      CompanyAccount,
                      "bankName",
                      "number",
                      "_id"
                    )}
                  />
                )}
              />

              <div className="error-message">{errors.bank?.message}</div>
            </div>
          </div>
        )}
      </div>

      <div className="row mb-4">
        <div className="col-md-12">
          <CButton
            disabled={productIds && productIds.length > 0 ? false : true}
            className="d-block w-100"
            type="submit"
          >
            <i className="icon icon-add me-1"></i>
            <IntlMessages id="distributeProducts.add" />
          </CButton>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <BootstrapTable
            keyField="_id"
            striped
            data={localProducts}
            columns={columnsNew}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,

              beforeSaveCell: (oldValue, newValue, row, column) => {
                let changedField = column.dataField;
                dispatch({
                  type: "updateQuatationLocalproducts",
                  payload: {
                    fieldChanged: changedField,
                    updatedRow: row,
                    newValue: newValue,
                  },
                });
              },
            })}
          />

          <table style={{ width: "100%", tableLayout: "fixed" }}>
            <tr>
              <td colspan="8">
                <span style={{ height: 10, display: "block" }}></span>
              </td>
            </tr>
            <tr>
              <td colspan="5" style={{ textAlign: "center" }}>
                <h5>Total</h5>
              </td>

              <td colspan="3" style={{ textAlign: "center" }}>
                <h5>{totalValue}</h5>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_Quotation_LOCAL_PRODUCT",
              });
              reset();
              history.push("/quotations");
            }}
          >
            <IntlMessages id="quotations.cancel" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_Quotation_LOCAL_PRODUCT",
              });
            }}
          >
            <IntlMessages id="quotations.reset" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CLoadingButton
            loading={quotationAddSpin}
            onClick={() => submitAllValue()}
            disabled={DisableSaveBtn || quotationAddSpin}
            className="d-block w-100"
            color="primary"
          >
            <span className="text-white">
              <IntlMessages id="quotations.create" />
            </span>
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default AddQuotationsForm;
