import React, { useEffect,useState,useRef } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import ReactToPrint from "react-to-print";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import moment from 'moment'
import BankBalanceSheetList from "../../components/CSSmartTable";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { useHistory, useLocation } from 'react-router-dom'
import EmployeeFilter from './employeeFilter'
const Index = () => {
  const param = useLocation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.balanceSheet);
  const history = useHistory();
  const [filter, setFilters] = useState(null);


  const redirectToPage = (url, referenceNo) => {
    if (url === "salaryPosted") {
      history.push({pathname : `/viewSalaryDetail`, state : { _id: referenceNo }});
    }
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "date",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.name" />,
      key: "name",
      _style: { width: "40%" },
    },
    {
      label:  <IntlMessages id="balanceSheet.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label:  <IntlMessages id="balanceSheet.Ref" />,
      key: "Ref",
      _style: { width: "40%" },
    },
    {
      label:  <IntlMessages id="balanceSheet.debit" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label:  <IntlMessages id="balanceSheet.credit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];

  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  const fetchBalanceSheet = () => {
    if (param.state && param.state._id) {
      const { _id } = param.state;
      dispatch(
        DataGetAction(
          "employeeBalanceSheet",
          { employeeId: _id, startDate: moment(), endDate: moment() },
          "fetchingEmployeeBalanceSheet"
        )
      );
    }
    dispatch(DataGetAction("getAllEmployee", { query: "all" }));

    return () => {
      dispatch({
        type: "removeTheResult",
      });
    };
  };

  useEffect(fetchBalanceSheet, []);
  const { EmployeeBalanceSheet } = blProps;

  const renderTitle = () => {
    if (filter) {
      return (
        <p className="h4">
          TRANSACTION SUMMARY OF {filter.bankName} in {filter.currencyName} FROM{" "}
          {moment(filter.startDate).format("MM-DD-YYYY")} TO{" "}
          {moment(filter.endDate).format("MM-DD-YYYY")}
        </p>
      );
    }
  };
  return (
    <>
      <CRow>
        <CCol xs={12}>
         
          <div className="pe-5 ps-5" ref={componentRef}>
            <CCard className="mb-3">
            <div className="d-flex  align-items-center">
                <div >
                    <EmployeeFilter
                      setFilters={setFilters}
                      initialValues={{
                        employeeId: param && param.state && param.state._id,
                      }}
                    />
                </div>
                <ReactToPrint
                  trigger={() => (
                    <CButton
                    color="info"
                    className="text-white me-3 d-flex align-items-center"
                    >
                      
                      <CIcon icon={cilPrint} className="me-2" />
                      <IntlMessages id="print" />
                    </CButton>
                  )}
                  content={() => componentRef.current}
                />
            </div>
              <CCardBody>
               
                <DocsExample href="components/smart-table/">
                  <BankBalanceSheetList
                    // loading={storeBalanceSheetSpinner}
                    sorterValue={{ column: "name", state: "asc" }}
                    clickableRows
                    tableProps={{
                      striped: true,
                      hover: true,
                    }}
                    activePage={3}
                    items={EmployeeBalanceSheet}
                    columns={columns}
                    columnFilter
                    tableFilter
                    cleaner
                    itemsPerPageSelect
                    itemsPerPage={100}
                    columnSorter
                    pagination
                    scopedColumns={{
                      description: (record) => {
                        return  <td>{ <IntlMessages id={record.description} />}</td>
                      },
                      date: (record) => {
                        return <td>{ChangeDateFormate(record.createdAt)}</td>;
                      },
                      Ref: (record) => {
                        if (record.reference === "0000") {
                            return <td>----</td>
                        } else {
                            return <td onClick={() =>
                                redirectToPage(record.receiptType, record.reference)} style={{
                                    cursor: 'pointer',
        
                                    color: 'blue'
                                }}>{record.receiptType === "companyExpense" || record.receiptType === "salaryPosted" ? record.prefix + "-" + record.extraDetail : record.prefix + "-" + record.reference}</td>
                        }
                    },
                      debit: (record) => {
                        return (
                          <td>
                            {ChangeNumberFormate(record.debit && record.debit > 0?parseFloat(record.debit).toFixed(2):0)}
                          </td>
                        );
                      },
                      credit: (record) => {
                        return (
                          <td>
                            {ChangeNumberFormate(record.credit && record.credit > 0 ? parseFloat(record.credit).toFixed(2) : 0)}
                          </td>
                        );
                      },
                      balance: (record) => {
                        return (
                          <td>
                           {ChangeNumberFormate(record.balance && parseFloat(record.balance).toFixed(2))}
                          </td>
                        );
                      },
                    }}
                    summary={(pageData) => {
                      let totalBorrow = 0;
                      let totalRepayment = 0;

                      pageData.forEach(({ debit, credit }) => {
                        totalBorrow += parseInt(debit);
                        totalRepayment += parseInt(credit);
                      });

                      return (
                        <tr>
                          <td
                            colSpan={4}
                            className="text-center"
                          >
                            Total
                          </td>
                          <td>
                            <span>
                              {ChangeNumberFormate(
                                totalBorrow > 0 ? totalBorrow.toFixed(2) : 0
                              )}
                            </span>
                          </td>
                          <td>
                          
                              {ChangeNumberFormate(
                                totalRepayment > 0
                                  ? totalRepayment.toFixed(2)
                                  : 0
                              )}
                          </td>
                          <td>
                            <span></span>
                          </td>
                        </tr>
                      );
                    }}
                  />
                </DocsExample>
              </CCardBody>
            </CCard>
          </div>
        </CCol>

       
      </CRow>
    </>
  );
};

export default Index;
