import React, { useState, useEffect } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";

import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage
} from "src/constants/CommonFn";
import { cilFile } from "@coreui/icons";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { DataGetAction } from "../../../redux/actions/http";
import CheckUserAccess from "../../../util/checkUserAccess";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const TrustUserSummaryList = ({ filterDate, setFilterDate,setFilteredCurrency }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const {locale} = useSelector((state) => state.Setting);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  let headerTitleAccountSummery = intlPlaceholder("report.supplier.summary");
  let textIn = intlPlaceholder("report.in");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("Description"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("Debit"), style: "tableHeader" },
      { text: intlPlaceholder("Credit"), style: "tableHeader" },
      { text: intlPlaceholder("Balance"), style: "tableHeader" },
    ],
  ];

  let TotalText = intlPlaceholder("Total");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  const blProps = useSelector((state) => state.balanceSheet);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;
  const {
     trustUserBalanceSummary,
    trustUserBalanceSummarySpinner,
  } = blProps;
  const history = useHistory();
  const fetchBalanceSheet = () => {
    dispatch(
      DataGetAction(
        "getTrustUserBalanceSheet",
        {
          startDate: moment().startOf("year").toString(),
          endDate: moment().toString(),
          initialEndDate: moment().toString(),
          noDateSelected: "noDateSelected",
        },
        "getTrustBalanceSheetSpinner"
      )
    );
  };

  useEffect(fetchBalanceSheet, []);



  const viewDetail = (data) => {
    history.push({
      pathname: "/TrustUserBalanceSheetList",
      state: { data, filterDate },
    });
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.names" />,
      key: "trustUserName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.currency" />,
      key: "currencySymbole",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.montant" />,
      key: "balance",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.initial" />,
      key: "initial",
      _style: { width: "40%" },
    },

    {
      label: "",
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];

  const downloadPDF = async (data) => {
    const token = localStorage.getItem("token");
    const { currencyId, trustyId, currencySymbole, trustUserName } = data;
    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();

    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let param = {
      startDate: startDate,
      endDate: endDate,
      employeeId: trustyId,
      currency: currencyId,
    };

    param = JSON.stringify(param);
    fetch(`${ROOT_URL}/balanceSheetForTrustUser/${param}`, { headers }).then(
      (response) => {
        response.json().then((response) => {
          let Record = response.Record.reverse();

          let Total = 0;
          let debitTotal = 0;
          let creditTotal = 0;

          Record.forEach((Item, index) => {
            let debit = parseFloat(Item.debit).toFixed(2);
            let credit = parseFloat(Item.credit).toFixed(2);
            let balance = parseFloat(Item.balance).toFixed(2);

            debitTotal = debitTotal + parseFloat(debit);
            creditTotal = creditTotal + parseFloat(credit);
            Total = Total + parseFloat(balance);

            debit = Item.debit !== 0 ? NumberWithCommas(debit) : "";
            credit = Item.credit !== 0 ? NumberWithCommas(credit) : "";
            balance = NumberWithCommas(balance);
            let date = ChangeDateFormate(Item.createdAt);

            let objArr = [date];

            //let desc = conversionInLanguage(locale.locale ,Item.description)
            let remarks = [
              {
                text: `${Item.remark}`,
                alignment: "left"
              }
            ];
            objArr.push(remarks);
            let ref = [
              {
                text: `${Item.refNo}`,
                alignment: "center"
              }
            ]
            objArr.push(ref);
            objArr.push(debit);
            objArr.push(credit);
            objArr.push(balance);

            bodyData = [...bodyData, objArr];
          });

          debitTotal = parseFloat(debitTotal).toFixed(2);
          creditTotal = parseFloat(creditTotal).toFixed(2);

          let letTotal = [
            {
              text: TotalText,
              style: "tableHeader",
              colSpan: 3,
              alignment: "center",
            },
            {},
            {},
            { text: NumberWithCommas(debitTotal), style: "tableBody", bold: true },
            { text: NumberWithCommas(creditTotal), style: "tableBody", bold: true },
            {},
          ];
          bodyData = [...bodyData, letTotal];

          let headerTitle = ` ${headerTitleAccountSummery.toUpperCase()} ${trustUserName.toUpperCase()} ${textIn.toUpperCase()} ${currencySymbole} ${reportFrom} ${ChangeDateFormate(
            startDate,
            "DD-MM-YYYY"
          )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;

          let footerAddress = localStorage.getItem("address");

          let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
          let docDefinition = {
            pageOrientation: 'landscape',
            content: [
              {
                image: `data:image/jpeg;base64,${logoImage}`,
                fit: [250, 250],
                width: 150,
                height: 150,
              },

              {
                text: headerTitle,
                margin: [15, 10],
                style: { fontSize: 22, bold: true, alignment: "center" },
              },
              {
                style: "tableBody",
                headerRows: 1,

                table: {
                  widths: [80, "*", "auto", 100, 100, 100],
                  body: bodyData,
                },
              },
            ],
            footer: {
              text: [
                {
                  text: footerAddress,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
                {
                  text: `\n ${footerAddress2}`,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
              ],
            },

            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                textAlign: "center",
              },
              tableExample: {
                margin: [0, 5, 0, 15],
                width: "100%",
              },
              tableOpacityExample: {
                margin: [0, 5, 0, 15],
                fillColor: "blue",
                fillOpacity: 0.3,
              },
              tableHeader: {
                bold: true,
                fontSize: 14,
                color: "black",
                alignment: "center"
              },
              tableBody: {
                bold: false,
                fontSize: 10,
                alignment: "right"
              },
            },
          };
          pdfMake.createPdf(docDefinition).download("trustUserSummaryReport.pdf");
        });
      }
    );
  };

  const pdfAccess = CheckUserAccess("trustUserAccounts", ["pdf"]) 

  return (
    <>
      <CSmartTable
        loading={trustUserBalanceSummarySpinner}
        sorterValue={{ column: "trustUserName", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={trustUserBalanceSummarySpinner ? [] :  trustUserBalanceSummary}
        columns={columns}
        columnFilter
        onColumnFilterChange={(obj)=>{
         
          
          if(obj['currencySymbole'] !== undefined || obj['currencySymbole'] !== null){
            let value = obj['currencySymbole']
            setFilteredCurrency(value)
          }
        }}
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={500}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          trustUserName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.trustUserName && record.trustUserName}
                </td>
              </>
            );
          },
          currencySymbole: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}> 
                  {record.currencySymbole && record.currencySymbole}
                </td>
              </>
            );
          },
          balance: (record) => {
            return (
              <td className="text-end">
                {record.balance && record.balance > 0 ? (
                  <span style={{ color: "green" }}>
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </span>
                )}
              </td>
            );
          },
          initial: (record) => {
            return (
              <td className="text-end">
                {record.initial && record.initial > 0 ? (
                  <span style={{ color: "green" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.initial).toFixed(2)
                    ) || 0}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.initial).toFixed(2)
                    ) || 0}
                  </span>
                )}
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  <CButton
                    color="warning"
                    className="me-3"
                    onClick={() => viewDetail(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-editor"></span>
                      <IntlMessages id="viewDetails" />
                    </span>
                  </CButton>

                  {pdfAccess && (
                    <CButton color="danger" onClick={() => downloadPDF(record)}>
                      <span style={{ color: "white" }}>
                        <i className="gx-icon-pdf-300 me-1"></i>
                        <IntlMessages id="pdf" />
                      </span>
                    </CButton>
                  )}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
        summary={(pageData) => {
          let totalBalance = 0;
          let totalInitialBalance = 0;
          pageData.forEach(({ balance, initial }) => {
            totalBalance += parseFloat(balance);
            totalInitialBalance += parseFloat(initial);
          });
       
          return (
            <>
              <tr>
                <th colSpan={2} className="text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th colSpan={1} className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalBalance.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th colSpan={1} className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalInitialBalance.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th></th>
              </tr>
            </>
          );
        }}
      />
    </>
  );
};

export default TrustUserSummaryList;
