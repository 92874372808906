import React, { useEffect } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import DocsExample from "../../../components/DocsExample";
import { DataGetAction } from "../../../redux/actions/http";
import AddUnitForm from './AddUnitForm'
const AddUnit = () => {

    const dispatch = useDispatch();
    const comProps = useSelector(state => state.unitReducer);
    const history = useHistory();

    const fetchUnit = () => {
        dispatch(DataGetAction('getAllUnit', { query: "all" }, 'unitSpinner'));
    }
    useEffect(fetchUnit, [comProps.fetchUnitInfo])

    const redirectToAddStockPage = (Item) => {
        history.push("/addUnit");
    };



    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader className="d-flex justify-content-between align-items-center">
                        <strong>
                            <IntlMessages id="unit.addUnitPage.addUnit" />
                        </strong>
                    </CCardHeader>
                    <CCardBody>
                        <DocsExample href="components/smart-table/">
                            <AddUnitForm />
                        </DocsExample>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default AddUnit
