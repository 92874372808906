import React, { useEffect } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import DocsExample from "../../components/DocsExample";
import { DataGetAction } from "../../redux/actions/http";
import UnitList from './components/unitList'
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {

    const dispatch = useDispatch();
    const comProps = useSelector(state => state.unitReducer);
    const history = useHistory();

    const fetchUnit = () => {
        dispatch(DataGetAction('getAllUnit', { query: "all" }, 'unitSpinner'));
    }
    useEffect(fetchUnit, [comProps.fetchUnitInfo])

    const redirectToAddStockPage = (Item) => {
        history.push("/addUnit");
    };

    const create = CheckUserAccess('units',['create']) 

    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader className="d-flex justify-content-between align-items-center">
                        <h1>
                            <IntlMessages id="unit.title" />
                        </h1>
                       
                    </CCardHeader>
                    <CCardBody>
                    <div className="ps-3">
                    {create&&  <CButton
                                color="primary"
                                onClick={() => redirectToAddStockPage(null)}
                            >
                                 <i className="icon icon-add me-1"></i>

                                <IntlMessages id="unit.add.unit.button" />
                            </CButton>}
                    </div>
                        <DocsExample href="components/smart-table/">
                            <UnitList data={comProps.Units} unitSpinner={comProps.unitSpinner}/>
                        </DocsExample>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default Index
