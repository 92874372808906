import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import StoreBalanceSummaryList from "./components/StoreBalanceSummaryList";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { selectStyle } from "../../constants/CommonFn";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DataGetAction } from "../../redux/actions/http";
import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage,
} from "src/constants/CommonFn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const Index = () => {
  const [stateSpinner, setSpinner] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [statusState, setStatusState] = useState("active");
  const [endDate, setEndDate] = useState(null);
  const [filterDate, setFilterDate] = useState({
    startDate: moment().toString(),
    endDate: moment().toString(),
  });

  const { LogeInUser } = useSelector((state) => state.auth);
  const { locale } = useSelector((state) => state.Setting);

  const { StoreSummery } = useSelector((state) => state.balanceSheet);

  const dispatch = useDispatch();
  const onDateChange = (data, status = "active") => {
    const [start, end] = data;
    setStartDate(start);
    setEndDate(end);
    if (data && data.length > 0 && data[0] !== null && data[1] !== null) {
      setFilterDate({
        startDate: moment(data[0]).toString(),
        endDate: moment(data[1]).toString(),
      });
      dispatch(
        DataGetAction(
          "StoreBalanceSummery",
          {
            accountStatus: statusState,
            startDate: moment(data[0]).toString(),
            endDate: moment(data[1]).toString(),
            initialEndDate: moment(data[0]).toString(),
            noDateSelected: "selected",
          },
          "storeBalanceSummarySpinner"
        )
      );
    } else if (
      data &&
      data.length > 0 &&
      data[0] === null &&
      data[1] === null
    ) {
      dispatch(
        DataGetAction(
          "StoreBalanceSummery",
          {
            accountStatus: statusState,
            startDate: moment().startOf("today").toString(),
            endDate: moment().endOf("year").toString(),
            initialEndDate: moment().toString(),
            noDateSelected: "noDateSelected",
          },
          "storeBalanceSummarySpinner"
        )
      );
    }
  };

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  let TotalText = intlPlaceholder("Total");
  let headerTitleAccountSummery = intlPlaceholder("storeAccountReport");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let headerBodyData = [
    [
      { text: intlPlaceholder("balanceSheet.names"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.montant"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.initial"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.purchases"), style: "tableHeader" },
      {
        text: intlPlaceholder("balanceSheet.totalSettlement"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("balanceSheet.cededStock"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("balanceSheet.receivedStock"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("balanceSheet.withdrawal"),
        style: "tableHeader",
      },
    ],
  ];

  const downloadSummaryPdf = async () => {
    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let Record = StoreSummery;
    let bodyData = [...headerBodyData];

    let Total = 0;
    let balanceTotal = 0;
    let initialBalanceTotal = 0;

    let purchasesTotal = 0;
    let settlementTotal = 0;
    let cededStockTotal = 0;
    let receivedStockTotal = 0;
    let withdrawalTotal = 0;

    Record.forEach((Item) => {
      let balance = parseFloat(Item.balance).toFixed(2);
      let initialBalance = parseFloat(Item.initialBalance).toFixed(2);

      //purchases, settlement, cededStock, receivedStock, withdrawal

      let purchases = parseFloat(Item.purchases).toFixed(2);
      let settlement = parseFloat(Item.settlement).toFixed(2);
      let cededStock = parseFloat(Item.cededStock).toFixed(2);
      let receivedStock = parseFloat(Item.receivedStock).toFixed(2);
      let withdrawal = parseFloat(Item.withdrawal).toFixed(2);
      let name = [
        {
          text: Item.storeName,
          alignment: "center",
        },
      ];

      balanceTotal = balanceTotal + parseFloat(balance);
      initialBalanceTotal = initialBalanceTotal + parseFloat(initialBalance);

      purchasesTotal = purchasesTotal + parseFloat(purchases);
      settlementTotal = settlementTotal + parseFloat(settlement);
      cededStockTotal = cededStockTotal + parseFloat(cededStock);
      receivedStockTotal = receivedStockTotal + parseFloat(receivedStock);
      withdrawalTotal = withdrawalTotal + parseFloat(withdrawal);

      balance = balance !== 0 ? NumberWithCommas(balance) : "";
      initialBalance =
        initialBalance !== 0 ? NumberWithCommas(initialBalance) : "";

      purchases = purchases !== 0 ? NumberWithCommas(purchases) : "";
      settlement = settlement !== 0 ? NumberWithCommas(settlement) : "";
      cededStock = cededStock !== 0 ? NumberWithCommas(cededStock) : "";
      receivedStock =
        receivedStock !== 0 ? NumberWithCommas(receivedStock) : "";
      withdrawal = withdrawal !== 0 ? NumberWithCommas(withdrawal) : "";

      // let date = ChangeDateFormate(Item.createdAt);

      let objArr = [];
      objArr.push(name);
      objArr.push(balance);
      objArr.push(initialBalance);
      objArr.push(purchases);
      objArr.push(settlement);
      objArr.push(cededStock);
      objArr.push(receivedStock);
      objArr.push(withdrawal);

      bodyData = [...bodyData, objArr];
    });

    balanceTotal = parseFloat(balanceTotal).toFixed(2);
    initialBalanceTotal = parseFloat(initialBalanceTotal).toFixed(2);

    let letTotal = [
      {
        text: TotalText,
        style: "tableHeader",
      },
      { text: NumberWithCommas(balanceTotal), style: "tableBody" },
      { text: NumberWithCommas(initialBalanceTotal), style: "tableBody" },
      { text: NumberWithCommas(purchasesTotal), style: "tableBody" },
      { text: NumberWithCommas(settlementTotal), style: "tableBody" },
      { text: NumberWithCommas(cededStockTotal), style: "tableBody" },
      { text: NumberWithCommas(receivedStockTotal), style: "tableBody" },
      { text: NumberWithCommas(withdrawalTotal), style: "tableBody" },
    ];
    bodyData = [...bodyData, letTotal];

    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headerTitle = `${headerTitleAccountSummery} ${reportFrom} ${ChangeDateFormate(
      startDate,
      "DD-MM-YYYY"
    )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

    let docDefinition = {
      pageMargins: [10, 50, 10, 45],
      pageOrientation: "landscape",
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },
        {
          text: headerTitle,
          style: { fontSize: 22, bold: true, alignment: "center" },
          margin: [15, 10],
        },
        {
          style: "tableBody",
          headerRows: 1,

          table: {
            widths: ["*", 90, 90, 80, 80, 80, 80, 80],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 0],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 0, 0, 0],
          fontSize: 10,
          width: "90%",
        },
        tableOpacityExample: {
          margin: [0, 0, 0, 0],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
          alignment: "center",
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          alignment: "right",
        },
      },
    };

    setTimeout(function () {
      pdfMake
        .createPdf(docDefinition)
        .download("storeAccountSummaryReport.pdf");
      setSpinner(false);
    }, 1000);
  };
  const onChangeStatus = (status = "active") => {
    dispatch(
      DataGetAction(
        "StoreBalanceSummery",
        {
          accountStatus: status,
          startDate: filterDate.startDate,
          endDate: filterDate.endDate,
          initialEndDate: moment().toString(),
          noDateSelected: "selected",
        },
        "storeBalanceSummarySpinner"
      )
    );
  };
  const optionsValus = [
    {
      label: intlPlaceholder("activeDropDown"),
      value: "active",
    },
    { label: intlPlaceholder("inActive"), value: "inActive" },
  ];
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="balanceSheet.store.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex align-items-center">
                <div style={{ width: "240px" }}>
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment(),
                      endDate: moment(),
                    }}
                    onApply={handleApply}
                    onCallback={(start, end) => {
                      onDateChange([start, end]);
                    }}
                  >
                    <input type="text" className="form-control col-4" />
                  </DateRangePicker>
                </div>
                <div className="ms-3" style={{ width: "150px" }}>
                  <Select
                    value={
                      statusState === "active"
                        ? optionsValus[0]
                        : optionsValus[1]
                    }
                    styles={selectStyle}
                    defaultValue={optionsValus[0]}
                    onChange={(e) => {
                      setStatusState(e.value);
                      onChangeStatus(e.value);
                    }}
                    options={optionsValus}
                  />
                </div>
                <div className="ms-3">
                  <CLoadingButton
                    loading={stateSpinner}
                    disabled={stateSpinner}
                    color="danger"
                    className="text-white"
                    onClick={() => {
                      setSpinner(true);
                      downloadSummaryPdf();
                    }}
                  >
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf.generate.button" />
                    </span>
                  </CLoadingButton>
                </div>
              </div>

              <DocsExample href="components/smart-table/">
                <StoreBalanceSummaryList
                  filterDate={filterDate}
                  setFilterDate={setFilterDate}
                  accountStatus={statusState}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
