import React, { useState } from "react";
import {
  
  CButton,
  CCardBody,
  CCollapse,
  
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import DeleteModal from '../../../components/Modals/DeleteModal'
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import CheckUserAccess from "../../../util/checkUserAccess";
const BankTable = ({ Data,Spinner}) => {


  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const modalType = "show_bank_modal";


  let columns = [
    {
      label:<IntlMessages id="category.name" />,
      key: "name",
      _style: { width: "40%" },
    },

  ];


  let found = CheckUserAccess("category", ["edit","delete"])
  if(found){
    columns.push({
      key: "actions",
      label : '',
      _style: { width: "40%" },
      filter: false,
      sorter : false
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  
  const editRecord = (data) => {
    dispatch({
      type: 'edit_category_modal',
      payload: data,
    });
  };

  const edit = CheckUserAccess('category',['edit'])
  const deleted = CheckUserAccess('category',['delete'])

  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={Spinner}
        items={Spinner?[]:Data && Data.length > 0 ? Data : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
         
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  
                {edit &&  
                  <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                    <span className="icon icon-edit me-2"></span>
                    <IntlMessages id="Edit" />
                    </span>
                    
                  </CButton>}
  {deleted &&  
                    <DeleteModal
                      contentLangueId="areYouSureYouWantToDeleteThisCategory"
                      type="deleteCategory"
                      selectedItem={record}
                      ButtonTitleId="Delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />}
                  


                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
     
    </>
  );
};

export default BankTable;
