import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import CTableHead from './components/CTableHead'
import CTableRow from './components/CTableRow'
import CTableHeaderCell from './components/CTableHeaderCell'

import { CFormInput, CFormSelect } from '@coreui/react-pro'

const CSmartTableHead = forwardRef(({
  columnFilter,
  columnSorter,
  component: Component = CTableHead,
  columns,
  handleFilterOnChange,
  handleFilterOnInput,
  handleSort,
  sorterState,
  sortingIcon,
  sortingIconAscending,
  sortingIconDescending,
  ...rest
},
  ref) => {


  const tableHeaderCellProps = (column) => {
    if (typeof column === 'object' && column._props) {
      return column._props
    }
    return {}
  }

  const key = (column) => (typeof column === 'object' ? column.key : column)


  const tableHeaderCellStyles = (column) => {
    const style = { verticalAlign: 'middle', overflow: 'hidden', cursor: '' }

    if (
      columnSorter &&
      (typeof column !== 'object' ||
        (typeof column === 'object' && (typeof column.sorter === 'undefined' || column.sorter)))
    ) {
      style.cursor = 'pointer'
    }

    if (typeof column === 'object' && column._props) {
      return { ...style, ...column._style }
    }
    return style
  }

  const getColumnSorterState = (key) => {
    if (sorterState && sorterState.column === key) {
      if (sorterState.state) {
        return sorterState.state
      }
      return 0
    }

    return 0
  }

  const columnSorterIcon = (column) => {
    if (getColumnSorterState(key(column)) === 0) {
      return <span className="opacity-25 float-end me-1">{sortingIcon}</span>
    }
    if (getColumnSorterState(key(column)) === 'asc') {
      return <span className="float-end me-1">{sortingIconAscending}</span>
    }
    if (getColumnSorterState(key(column)) === 'desc') {
      return <span className="float-end me-1">{sortingIconDescending}</span>
    }
    return
  }


  const pretifyName = (name) => {
    return name
      .replace(/[-_.]/g, ' ')
      .replace(/ +/g, ' ')
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const label = (column) =>
    typeof column === 'object'
      ? column.label !== undefined
        ? column.label
        : pretifyName(column.key)
      : pretifyName(column)



  const renderColumnFilter = (column) => {


    if (column.filterType === 'dropDown') {
      let options = column.filterDropDownValue
      return <CFormSelect
        size="sm"
        aria-label="Default select example"
        onChange={(event) => {
          if (handleFilterOnChange) {
            if (event.target.value === 'all') {
              handleFilterOnChange(key(column), '')
            } else {
              handleFilterOnChange(key(column), event.target.value)
            }

          }

        }}
      >
        {options.map(Item => {
          return <option value={Item.value} selected={Item.selected ? Item.selected : false}>{Item.label}</option>
        })}
      </CFormSelect>
    } else {
      return (
        <CFormInput
          size="sm"
          onInput={(event) => {
            if (handleFilterOnInput) {
              return handleFilterOnInput(key(column), event.target.value)
            }
          }
          }
          onChange={(event) =>
            handleFilterOnChange &&
            handleFilterOnChange(key(column), event.target.value)
          }
          // value={columnFilterState[colName] || ''}
          aria-label={`column name: '${label(column)}' filter input`}
        />
      )
    }
  }

  return (
    <Component {...rest} ref={ref}>
      <CTableRow>
        {columns.map((column, index) => {
          return (
            <CTableHeaderCell
              {...tableHeaderCellProps(column)}
              onClick={() => handleSort && handleSort(key(column), index)}
              style={tableHeaderCellStyles(column)}
              customStyle={column._customStyle}
              key={index}
            >
              <div className="d-inline">{label(column)}</div>
              {columnSorter &&
                (typeof column !== 'object'
                  ? true
                  : typeof column.sorter === 'undefined'
                    ? true
                    : column.sorter) &&
                columnSorterIcon(column)}
            </CTableHeaderCell>
          )
        })}
      </CTableRow>
      {columnFilter && (
        <CTableRow>
          {columns.map((column, index) => {
            return (
              <CTableHeaderCell {...tableHeaderCellProps(column)} key={index}>
                {(typeof column !== 'object'
                  ? true
                  : typeof column.filter === 'undefined'
                    ? true
                    : column.filter) && renderColumnFilter(column)}
              </CTableHeaderCell>
            )
          })}
        </CTableRow>
      )}
    </Component>
  )
})


CSmartTableHead.propTypes = {
  columnFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  columnSorter: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any, PropTypes.string])).isRequired, // TODO: improve this Prop Type,
  handleFilterOnChange: PropTypes.func,
  handleFilterOnInput: PropTypes.func,
  handleSort: PropTypes.func,
  sorterState: PropTypes.object,
  sortingIcon: PropTypes.node,
  sortingIconAscending: PropTypes.node,
  sortingIconDescending: PropTypes.node,
}

export default CSmartTableHead
