import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../../components/DocsExample";
import IntlMessages from "../../../util/IntlMessages";
import UsersList from "./components/UsersList";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import { notify } from "../../../util/ShowToast";
import CheckUserAccess from "../../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const Index = () => {
  const dispatch = useDispatch();
  const modalType = "show_productStock_modal";

  const userProps = useSelector((state) => state.users);
  const create = CheckUserAccess('manageUsers',['create']) 




  const fetchUsers = () => {
    dispatch(DataGetAction("getAllUsers", { query: "all" }, "gettingAllUsers"));
    dispatch(
      DataGetAction("getRoleDropdown", { query: "all" }, "gettingAllUserRoles")
    );
    dispatch(DataGetAction('getAllEmployee', { query: "all" },'employeeSpinner'));
    dispatch(DataGetAction('fetchCompanyBank', '', 'fetching'));
  };





  useEffect(fetchUsers, []);
  if (userProps.success) {
    let messageLng = intlPlaceholder(userProps.message)
    notify("success", messageLng);
    dispatch({
      type: "user_UPDATE",
    });
  }
  if (userProps.error) {
    let messageLng = intlPlaceholder(userProps.message)
    notify("error", messageLng);
    dispatch({
      type: "user_UPDATE",
    });
  }
  const data = [
    {
      email: "red@mailinator.com",
      firstName: "Red",
      lastName: "ming",
      "name": "Red ming",
      phoneNumber: "13254354354",
      role: "manager",
      username: "redmingle",
      status: "active",
    },
    {
      email: "blue@mailinator.com",
      firstName: "Blue",
      lastName: "Dinga",
      "name": "Blue Dinga",
      phoneNumber: "13254354354",
      role: "employee",
      username: "dingadong",
      status: "block",
    },
  ];

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="manageUsers.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3">
            {create &&    <AddModal />}
              </div>
              <DocsExample href="components/smart-table/">
                <UsersList
                  //Data={data}
                  Data={userProps.Users}
                  Spinner={userProps.allUsersSpinner}
                  //Spinner={false}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
        <EditModal/>
      </CRow>
    </>
  );
};

export default Index;
