import React from "react";
import IntlMessages from "src/util/IntlMessages";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { useDispatch, useSelector } from "react-redux";
import { CSpinner } from "@coreui/react-pro";

const AddDeliveredList = ({
  setDisableSaveBtn,
  deliveredLocalProduct,
  deliveredLocalProductSpin,
}) => {
  const dispatch = useDispatch();
  const columns = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="addDelivered.productName" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },
    {
      dataField: "qtyToDeliver",
      text: <IntlMessages id="addDelivered.qtyToDeliver" />,
      editable: true,
      formatter: (cell, record) => {
        return <span>{cell}</span>;
      },
      validator: (newValue, row, column) => {
        let QtyToDeliver = newValue;
        let allowedQty = row.minimumAllowedQty;
        if (parseFloat(QtyToDeliver) < 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="negativeQuantity" />,
          };
        } else if (parseFloat(QtyToDeliver) <= parseFloat(allowedQty)) {
          setDisableSaveBtn(false);
          return true;
        } else {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="reduceDeliveredValue" />,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "minimumAllowedQty",
      text: <IntlMessages id="addDelivered.allowedQty" />,
      editable: false,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(3)}</span>;
      },
    },
    {
      dataField: "Qty",
      text: <IntlMessages id="addDelivered.purchasedQty" />,
      editable: false,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(3)}</span>;
      },
    },
    {
      dataField: "delivered",
      text: <IntlMessages id="addDelivered.delivered" />,
      editable: false,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(3)}</span>;
      },
    },
    {
      dataField: "sockQty",
      text: <IntlMessages id="addDelivered.availableStock" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <span>
            {record.retail
              ? (
                  parseFloat(record.sockQty) * parseFloat(record.packaging)
                ).toFixed(3)
              : parseFloat(cell).toFixed(3)}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        data={deliveredLocalProduct}
        columns={columns}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField;
            dispatch({
              type: "updateDeliveryLocalProduct",
              payload: {
                fieldChanged: changedField,
                updatedRow: row,
                newValue: newValue,
              },
            });
          },
        })}
      />
      {!deliveredLocalProductSpin &&
        deliveredLocalProduct &&
        deliveredLocalProduct.length === 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <h4
              style={{ fontSize: "18px", fontWeight: "bold", color: "#969EAC" }}
            >
              <IntlMessages id={"emptyRecordMessage"} />
            </h4>
          </div>
        )}
      {deliveredLocalProductSpin && (
        <div className="d-flex align-items-center justify-content-center">
          <CSpinner variant="grow" color="primary" />
        </div>
      )}
    </>
  );
};

export default AddDeliveredList;
