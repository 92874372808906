import React, { useState } from "react";
import { CForm, CCol, CFormSelect } from "@coreui/react-pro";
import Select from "react-select";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import IntlMessages from "src/util/IntlMessages";

const ContractFilter = ({ setFilter, Properties }) => {
  const [status, setStatus] = useState("active");
  const [property, setProperty] = useState({ value: "all", label: <IntlMessages id="contract.allPropertiesPlaceholder" /> });

  const uniqueProperties = [
    { value: "all", label: <IntlMessages id="contract.allPropertiesPlaceholder" /> },
    ...Array.from(
      new Set(Properties.map((contract) => JSON.stringify({ propertyId: contract._id, propertyName: contract.propertyName })))
    )
      .map((str) => JSON.parse(str))
      .map((prop) => ({
        value: prop.propertyId,
        label: prop.propertyName,
      })),
  ];

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setStatus(value);
    setFilter((prev) => ({ ...prev, status: value }));
  };

  const handlePropertyChange = (selectedOption) => {
    setProperty(selectedOption);
    setFilter((prev) => ({ ...prev, property: selectedOption ? selectedOption.value : null }));
  };

  return (
    <CForm className="d-flex gap-3">
      <CCol>
        <CFormSelect
          style={{ width: "250px" }}
          id="status"
          placeholder={intlPlaceholder("contract.statusFilter")}
          value={status}
          onChange={handleStatusChange}
        >
          <option value="all">{intlPlaceholder("contract.allPLaceholder")}</option>
          <option value="active">{intlPlaceholder("contract.activeStatus")}</option>
          <option value="expired">{intlPlaceholder("contract.expiredStatus")}</option>
        </CFormSelect>
      </CCol>

      <CCol>
        <Select
          styles={{ container: (base) => ({ ...base, width: 250 }) }}
          id="property"
          placeholder={intlPlaceholder("contract.propertyFilter")}
          value={property}
          onChange={handlePropertyChange}
          options={uniqueProperties}
          isSearchable
        />
      </CCol>
    </CForm>
  );
};

export default ContractFilter;
