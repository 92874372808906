import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceProductList from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import DeleteModal from "src/components/Modals/DeleteModal";
import { useHistory } from "react-router-dom";
import InvoiceSignature from "src/components/InvoiceSignature";
import { notify } from "src/util/ShowToast";

const InvoiceDetail = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.invoiceReducer);
  const authProps = useSelector((state) => state.auth);
  const { permissionAccess, LogeInUser } = authProps;
  const { refundSuccessfully } = invProps;
  const { locale } = useSelector(({ Setting }) => Setting);
  let [printBalance, setPrintBalance] = useState(true);
  const { languageId } = locale;

  const {
    invoiceNumber,
    prefix,
    createdAt,
    store,
    createdBy,
    customer,
    products,
    currencyInfo,
    totalAmountDue,
    totalPrice,
    rebate,
    status,
    units,
    _id,
  } = invProps.InvoiceDetail !== null ? invProps.InvoiceDetail : "000-000-000";

  const storeName = store && store[0] ? store[0].label : "";
  const storeEmail = store && store[0] ? store[0].email : "";
  const storeAddress = store && store[0] ? store[0].address : "";
  const customerName = customer && customer[0] ? customer[0].label : "";
  const customerAddress = customer && customer[0] ? customer[0].address : "";
  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";
  const history = useHistory();
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "productName",
      _style: { width: "50%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantity" />,
      key: "quantity",
      _style: { width: "20%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unit" />,
      key: "unit",
      _style: { width: "20%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unitPrice" />,
      key: "unitPrice",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.total" />,
      key: "total",
      _style: { width: "40%" },
    },
  ];

  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  if (refundSuccessfully) {
    history.push("/storeInvoice");
    let messageLng = intlPlaceholder("Success");
    notify("success", messageLng);
    dispatch({
      type: "Reset_refundClientInvoice",
    });
  }

  const renderDueAmount = () => {
    if (prefix === "FM") {
      return ChangeNumberFormate(parseFloat(totalAmountDue).toFixed(2));
    } else {
      return ChangeNumberFormate(parseFloat(totalAmountDue).toFixed(2));
    }
  };

  const renderUnit = (unitId, subunit = null) => {
    if (units) {
      const selectedParentIndex = _.findIndex(units, (x) => x._id === unitId);

      if (selectedParentIndex > -1) {
        if (subunit) {
          let subunits = units[selectedParentIndex].subUnits;
          let filterUnitName = _.filter(subunits, (x) => x._id === subunit);
          return filterUnitName[0].subUnitName;
        } else {
          return units[selectedParentIndex].name;
        }
      } //else {
      //   //unit is child unit
      //   let parentIndex = 0;
      //   let childIndex = 0;
      //   units.map((x, index) => {
      //     const childIndexObj = _.findIndex(
      //       x.subUnits,
      //       (j) => j._id === unitId
      //     );
      //     if (childIndexObj > -1) {
      //       parentIndex = index;
      //       childIndex = childIndexObj;
      //     }
      //     return x;
      //   });

      //   return units[parentIndex].subUnits[childIndex].subUnitName;
      // }
    }
  };

  const getAmountBill = () => {
    if (totalPrice) {
      let total = totalPrice;
     

      return (
        <>
          <div className="d-flex justify-content-between mt-4">
            <p></p>
            <div style={{ width: "430px" }}>
              <div className="d-flex">
                <span
                  className="d-inline-block"
                  style={{ width: "20%", fontSize: 13}}
                >
                  <IntlMessages id="invoiceDetail.rebate" />:
                </span>
                <span
                  className="d-inline-block "
                  style={{
                    width: "80%",
                    fontSize: 13
                  }}
                >
                  {ChangeNumberFormate(rebate.toFixed(2))}
                  {" "}
                  {currencyInfo && currencyInfo.length > 0
                    ? currencyInfo[0].name
                    : ""}
                  
                </span>
              </div>
              <div className="d-flex">
                <span
                  className="d-inline-block"
                  style={{ width: "20%", fontSize: 13}}
                >
                  <IntlMessages id="invoiceDetail.totalBill" />:
                </span>
                <span
                  className="d-inline-block "
                  style={{
                    width: "80%",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#003366",
                  }}
                >
                  {ChangeNumberFormate(total.toFixed(2))}
                  {" "}
                  {currencyInfo && currencyInfo.length > 0
                    ? currencyInfo[0].name
                    : ""}
                  
                </span>
              </div>
              <div className="d-flex">
                <span
                  className="d-inline-block "
                  style={{ width: "20%", fontSize: 13 }}
                >
                  <IntlMessages id="invoiceDetail.totalBillWords" />:
                </span>
                <span
                  className="d-inline-block text-capitalize"
                  style={{ width: "80%", fontSize: 13}}
                >
                  {total && ChangeNumberToWord(total, languageId)}
                </span>
              </div>
              {printBalance && (
                <div className="d-flex mt-2">
                  <span
                    className="d-inline-block "
                    style={{ width: "20%", fontSize: 13}}
                  >
                    <IntlMessages id="invoiceDetail.amountDue" />:
                  </span>
                  <span
                    className="d-inline-block text-capitalize"
                    style={{
                      width: "80%",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "#003366",
                    }}
                  >
                    {renderDueAmount()}
                    {" "}
                    {currencyInfo && currencyInfo.length > 0
                      ? currencyInfo[0].name
                      : ""}
                    
                  </span>
                </div>
              )}
              {printBalance && renderAmountDueInWord()}
            </div>
          </div>
        </>
      );
    }
  };

  const renderAmountDueInWord = () => {
    let dueAmount = totalAmountDue;
    if (totalAmountDue < 0) {
      dueAmount = -1 * parseInt(totalAmountDue);
    }

    return (
      <div className="d-flex">
        <span
          className="d-inline-block "
          style={{ width: "20%", fontSize: 13 }}
        >
          <IntlMessages id="invoiceDetail.amountDueWord" />:
        </span>
        <span
          className="d-inline-block text-capitalize"
          style={{ width: "80%", fontSize: 13 }}
        >
          {totalAmountDue < 0
            ? ChangeNumberToWord(dueAmount, languageId)
            : ChangeNumberToWord(totalAmountDue, languageId)}
        </span>
      </div>
    );
  };

  const getRecordValue = () => {
    const paymentModeArray = products;
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    let total = 0;
    if (paymentModeArray) {
      paymentModeArray.map((x) => {
        total = total + x.total;
        return x;
      });
    }

    let totalBillFigure = `${
      currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : ""
    } ${NumberWithCommas(parseFloat(total))}`;
    let totalBillWords = ChangeNumberToWord(total, languageId);

    let dueAmount = totalAmountDue;
    if (totalAmountDue < 0) {
      dueAmount = -1 * parseInt(totalAmountDue);
    }

    let totalBalanceFigure = `${
      currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : ""
    } ${NumberWithCommas(parseFloat(dueAmount))}`;
    let totalBalanceWords = ChangeNumberToWord(dueAmount, languageId);

    let data = [];

    if (paymentModeArray && paymentModeArray.length > 0) {
      paymentModeArray.map((item) => {
        let {
          productName,
          quantity,
          unit,
          subunit,
          salePrice,
          total,
          ...rest
        } = item;

        salePrice = NumberWithCommas(salePrice);
        total = NumberWithCommas(total);
        quantity = quantity.toFixed(3);
        let unitName = renderUnit(unit, subunit);

        let obj = {
          productName,
          quantity,
          unitName,
          salePrice,
          total,
        };

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.store");
    let key2 = intlPlaceholder("customer");

    let key3 = intlPlaceholder("receiptDetail.invoiceNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("invoiceDetail.productName"),
      intlPlaceholder("invoiceDetail.quantity"),
      intlPlaceholder("invoiceDetail.unit"),
      intlPlaceholder("invoiceDetail.unitPrice"),
      intlPlaceholder("invoiceDetail.total"),
    ];

    let detailObj = {
      [key1]: { name: storeName, address: storeAddress },
      ...(customerName && { [key2]: customerName }),
    };

    detailObj = {
      ...detailObj,
      [key3]: prefix + "-" + invoiceNumber,
      [key4]: createdByName,
    };

    let obj = {
      subject: `${intlPlaceholder(
        "invoiceDetail.InvoiceLabel"
      )} from ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: intlPlaceholder("invoiceDetail.InvoiceLabel"),
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,
      totalBillKey: intlPlaceholder("invoiceDetail.totalBill"),
      totalBillWorldKey: intlPlaceholder("invoiceDetail.totalBillWords"),
      totalBalanceKey: intlPlaceholder("invoiceDetail.amountDue"),
      totalBalanceWorldKey: intlPlaceholder("invoiceDetail.amountDueWord"),
      totalBillFigures: totalBillFigure,
      totalBillWords: totalBillWords,
      totalBalanceFigure: totalBalanceFigure,
      totalBalanceWords: totalBalanceWords,
      note: intlPlaceholder("invoiceDetail.note"),
    };
    return obj;
  };

  const styles = {
    label: {
      fontSize: "14px",
      color: "grey",
      minWidth: "20px",
      marginBottom: 0,
    },
    para: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    to: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    address: {
      color: "grey",
      maxWidth: "255px",
      textTransform: "capitalize",
      fontSize: "12px",
      textAlign: "center",
    },
    storeAddress: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      fontSize: "12px",
      textTransform: "capitalize",
      color: "grey",
    },
  };

  const checkButtonPermission = (buttonPermissionKey) => {
    if (permissionAccess && LogeInUser.role !== "superAdmin") {
      let storeInvoicePermission =
        permissionAccess && permissionAccess["storeInvoices"];
      if (storeInvoicePermission.includes(buttonPermissionKey)) {
        return true;
      } else {
        return false;
      }
    } else if (LogeInUser.role === "superAdmin") {
      return true;
    }
  };

  const areYouSureYouWantToRefundTheInvoice = intlPlaceholder(
    "areYouSureYouWantToRefundTheInvoice"
  );

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          {status !== "cancelled" && checkButtonPermission("cancel") && (
            <DeleteModal
              contentLangueId={`${areYouSureYouWantToRefundTheInvoice} ${prefix}-${invoiceNumber}`}
              method="POST"
              type="refundClientInvoice"
              selectedItem={{ _id, invoiceNumber, prefix }}
              textColor={"#ffffff"}
              ButtonTitleId="receipt.refund"
              titleLanguageId="receipt.refund"
              size="md"
              color="danger"
              variant=""
            />
          )}
          {status === "cancelled" && (
            <CButton
              color="danger"
              size="md"
              disabled
              className="text-white me-3"
            >
              <IntlMessages id="refunded" />
            </CButton>
          )}

          {status !== "cancelled" && (
            <CButton
              color="warning"
              className="text-white me-3"
              onClick={() => {
                dispatch({
                  type: "send_email_modal",
                });
              }}
            >
              <i className="icon icon-mail-open me-1 mb-1"></i>
              <IntlMessages id="sendMail" />
            </CButton>
          )}
          {status !== "cancelled" && (
            <ReactToPrint
              trigger={() => (
                <CButton
                  color="info"
                  className="text-white me-3 d-flex align-items-center"
                >
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="print" />
                </CButton>
              )}
              content={() => componentRef.current}
            />
          )}
          {status !== "cancelled" && (
            <ReactToPrint
              trigger={() => (
                <CButton
                  color="info"
                  className="text-white me-3 d-flex align-items-center"
                >
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="printNotBalance" />
                </CButton>
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => {
                setPrintBalance(false);
                return Promise.resolve();
              }}
              onAfterPrint={() => {
                setPrintBalance(true);
                return Promise.resolve();
              }}
            />
          )}
        </div>
      </div>

      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5"
        ref={componentRef}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <img src={logoImage} alt="" width="300" className="mb-2" />
            <p className="mb-0" style={styles.address}>
              {address}
            </p>
          </div>

          <div>
            <div className="d-flex">
              <div>
                <p style={styles.to}> CLIENT: </p>
                <p style={styles.para}>{storeName}</p>
                <p style={styles.storeAddress}>{storeAddress}</p>
              </div>
            </div>
            {customerName && (
              <div className="d-flex">
                <h4 style={styles.label}>
                  <IntlMessages id="customer" />:
                </h4>

                <div>
                  <p style={styles.para}>{customerName}</p>
                  <p style={styles.para}>{customerAddress}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="me-1">
                  <IntlMessages id="invoiceDetail.InvoiceLabel" />
                </span>
                :<span className="ms-1">{invoiceNumber}</span>
              </h2>
            </div>

            <small style={{ color: "grey" }}>
              {ChangeDateFormate(createdAt)}
            </small>
          </div>
        </div>
        <br />

        <div className="Table">
          <InvoiceProductList
            items={products}
            columns={columns}
            scopedColumns={{
              quantity: (record) => {
                return <td>{record.quantity.toFixed(3)}</td>;
              },
              unit: (record) => {
                return <td>{renderUnit(record.unit, record.subunit)}</td>;
              },
              unitPrice: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.salePrice).toFixed(2)
                    )}
                  </td>
                );
              },
              total: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(parseFloat(record.total).toFixed(2))}
                  </td>
                );
              },
            }}
          />
        </div>
        {getAmountBill()}
        <InvoiceSignature data={{ clientName: storeName }} />
      </div>
      <SendEmailModal
        initialValues={{ email: storeEmail }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default InvoiceDetail;
