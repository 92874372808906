import React, { useState } from "react";
import {
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";

const AddForm = ({ defaultValues, ButtonComponent }) => {
  const comProps = useSelector((state) => state.currencyReducer);

  const { customerLoader, selectedItem, modalVisible, success } = comProps;

  const dispatch = useDispatch();

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      <IntlMessages id="currency.addCurrencyModal.name_message" />
    ),
    symbol: Yup.string().required(
      <IntlMessages id="currency.addCurrencyModal.symbol_message" />
    ),

    factor: Yup.string().required(
      <IntlMessages id="currency.addCurrencyModal.1Symbol=1GNF_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    if (comProps.selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "addCurrency", values, "", "currency", false)
      );
    } else {
      values._id = comProps.selectedItem._id;
      dispatch(
        DataRequestAction("PUT", "editCurrency", values, "", "currency", false)
      );
    }
  };
  if (comProps.success) {
     let message = intlPlaceholder(comProps.message)
    notify("success", message);
    dispatch({
      type: "currency_UPDATE",
    });
  }
  if (comProps.error) {
    let message = intlPlaceholder(comProps.message)
    notify("error", message);
    dispatch({
      type: "currency_UPDATE",
    });
  }
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.name ? "is-invalid" : ""}
            {...register("name")}
            placeholder={intlPlaceholder("currency.addCurrencyModal.name")}
            type="text"
            id="name"
          />
          <CFormLabel htmlFor="name">
            {intlPlaceholder("currency.addCurrencyModal.name")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </CFormFloating>

        {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.symbol ? "is-invalid" : ""}
            {...register("symbol")}
            placeholder={intlPlaceholder("currency.addCurrencyModal.symbol")}
            type="text"
            id="symbol"
          />
          <CFormLabel htmlFor="symbol">
            {intlPlaceholder("currency.addCurrencyModal.symbol")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.symbol?.message}</div>
        </CFormFloating>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.factor ? "is-invalid" : ""}
            {...register("factor")}
            placeholder= {intlPlaceholder("currency.addCurrencyModal.factor")}
            type="text"
            id="factor"
          />
          <CFormLabel htmlFor="factor">
            {intlPlaceholder("currency.addCurrencyModal.factor")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.factor?.message}</div>
        </CFormFloating>
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default AddForm;
