




import React from "react";
import IntlMessages from "src/util/IntlMessages";
import { useSelector } from "react-redux";
const InvoiceSignature = ({ data, showClient = "true" }) => {
  const { LogeInUser } = useSelector((state) => state.auth);
  let {  name, companyName,jobTitle } = LogeInUser;
  const { clientName } = data || {};
  const styles = {
    sign :{
      width: "100px", 
      fontSize: "11px", 
      textAlign: "center", 
      fontWeight: "bold", 
      color: "grey"
    }
  }
  return (
    <div className="mb-3 mt-5 d-flex w-100 all-p-small">
      <div  className="me-3 me-md-5">
        <p className="mb-1" style={{ textAlign: "center"}}>______________</p>
        <p className="mb-1" style={styles.sign}>
          <IntlMessages id="invoiceReceipts.the" /> {jobTitle ||  <IntlMessages id="invoiceReceipts.admin" />}
        </p>
        <p className="text-capitalize" style={styles.sign}>{name || companyName ||  <IntlMessages id="invoiceReceipts.company" />}</p>
      </div>

      {showClient === "true" && (
        <div>
         <p className="mb-1" style={{ textAlign: "center"}}>______________</p>
          <p className="mb-1" style={styles.sign}>
            <IntlMessages id="invoiceReceipts.theClient" />
          </p>
          <p className="text-capitalize" style={styles.sign}>{clientName || <IntlMessages id="invoiceReceipts.clientName" />}</p>
        </div>
      )}
    </div>
  );
};

export default InvoiceSignature;

