import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DepositModal from "./components/DepositForm";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import CSmartTable from '../../components/CSSmartTable'
import CheckUserAccess from "../../util/checkUserAccess";
import TrustModal from '../../components/Modals/viewTrustFundModal'
import DateRangePicker from "react-bootstrap-daterangepicker";
const Index = () => {
  const param = useLocation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.trustFund);
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [filterDate, setFilterDate] = useState({
    startDate: moment(),
    endDate: moment()
  });


  const redirectToPage = (referenceNo) => {
    history.push({ pathname: `/viewTrustDetail`, state: { _id: referenceNo } });
  };


  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "refNo",
      _style: { width: "10%", },

    },


    {
      label: <IntlMessages id="balanceSheet.name" />,
      key: "name",
      _style: { width: "30%", },
    },
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "createdAt",
      _style: { width: "20%", },
    },
    {
      label: <IntlMessages id="balanceSheet.currency" />,
      key: "currencySymbol",
      _style: { width: "10%", },
    },

    {
      label: <IntlMessages id="trust.balanceSheet.amount" />,
      key: "credit",
      _style: { width: "40%", },
    },
    {
      label: '',
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },

  ];
  const depositModalType = "Deposit_modal";



  const fetchBalanceSheet = () => {
    dispatch(DataGetAction("getAllEmployeeWithTrust", { query: "all" }));
    dispatch(DataGetAction("getAllCurrency", { query: "all" }, "currencySpinner"));
    dispatch(DataGetAction("balanceSheetForTrustUser", {
      query: 'deposit',
      startDate: filterDate.startDate,
      endDate: filterDate.endDate,
    }, "fetchingBalanceSheetForTrustUser"));

    return () => {
      dispatch({
        type: "removeTheResultTrustFund",
      });
    };
  };

  useEffect(fetchBalanceSheet, [filterDate]);

  const { BalanceSheetList, fetchingBalanceSheetForTrustUser, depositModalVisible, depositList, depositListSpinner } = blProps;



  const showDepositModal = () => {
    dispatch({
      type: depositModalType,
    });
  };


  const findBankByCurrencyId = (currencyId) => {
    dispatch(
      DataGetAction(
        "getCompanyAccountByCurrencyId",
        { currencyId },
        "fetchingCompanyBank"
      )
    );
  };

  const deposit = CheckUserAccess('deposit', ['deposit'])


  const onDateChange = (data) => {
    if (data && data.length > 0 && data[0] !== null && data[1] !== null) {
      setFilterDate({
        startDate: moment(data[0]).toString(),
        endDate: moment(data[1]).toString(),
      });

    }
  };


  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );


  };

  const openTrustModal = (id) => {
    setSelectedId(id)
    setShowModal(true)
  }

  const onDismiss = () => {
    setShowModal(false)
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <div ref={componentRef}>
            <CCard className="mb-4">
              <CCardHeader className="d-flex justify-content-between align-items-center">
                <h1>
                  <IntlMessages id="bc.userDeposit" />
                </h1>
              </CCardHeader>
              <CCardBody>

                <div className="d-flex  mb-3">
                  <div style={{ width: '240px', marginRight: 10 }}>
                    <DateRangePicker
                      initialSettings={{
                        startDate: moment(),
                        endDate: moment(),
                      }}
                      onApply={handleApply}
                      onCallback={(start, end) => {
                        onDateChange([start, end]);
                      }}
                    >
                      <input type="text" className="form-control col-4" />
                    </DateRangePicker>
                  </div>
                  <div className="">
                    {deposit && <CButton
                      color="primary"
                      // variant="outline"
                      className="me-3 d-flex text-white align-items-center"
                      onClick={() => showDepositModal()}
                    >
                      <i className="gx-icon-currency-exchange1 me-2"></i>
                      <IntlMessages id="balanceSheet.filter.deposit" />
                    </CButton>}

                  </div>
                </div>
                <CSmartTable
                  loading={fetchingBalanceSheetForTrustUser}

                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}

                  items={
                    fetchingBalanceSheetForTrustUser ? [] : BalanceSheetList
                  }
                  columns={columns}
                  columnFilter
                  tableFilter={true}
                  cleaner={true}
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    description: (record) => {
                      return <td>{<IntlMessages id={record.description} />}</td>
                    },
                    createdAt: (record) => {
                      return <td>{record.createdAt}</td>;
                    },
                    currency: (record) => {
                      return <td>{record.currency?.[0].symbol}</td>;
                    },

                    refNo: (record) => {
                      if (record.refNo === "0000") {
                        return <td>{record.refNo}</td>;
                      } else {
                        return (
                          <td
                            onClick={() => openTrustModal(record._id)}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            {record.refNo}
                          </td>
                        );
                      }
                    },
                    credit: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.credit && record.credit.toFixed(2)
                          )}
                        </td>
                      );
                    },
                    actions: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton

                              color="warning"
                              className="me-3"
                              onClick={() => openTrustModal(record._id)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-editor me-1"></span>
                                <IntlMessages id="viewDetails" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },

                  }}
                  summary={(pageData) => {
                    let totalBorrow = 0;
                    let totalRepayment = 0;

                    pageData.forEach(({ debit, credit }) => {
                      totalBorrow += parseInt(debit);
                      totalRepayment += parseInt(credit);
                    });

                    return (
                      <tr>
                        <th colSpan={2} className="text-center">
                          {intlPlaceholder("Total")}
                        </th>

                        <th colSpan={3} className="text-end">
                          <span>
                            {ChangeNumberFormate(
                              totalRepayment > 0 ? totalRepayment.toFixed(2) : 0
                            )}
                          </span>
                        </th>
                        <th>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>
      <TrustModal id={selectedId} visible={showModal} title={intlPlaceholder("DepositModalTitle")} onDismiss={onDismiss} />
      <DepositModal
        type={depositModalType}
        title={intlPlaceholder("DepositModalTitle")}
        findBankByCurrencyId={findBankByCurrencyId}
      />

    </>
  );
};

export default Index;
