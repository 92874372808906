import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import CTableBody from './components/CTableBody'
import CTableRow from './components/CTableRow'
import CTableDataCell from './components/CTableDataCell'
const CSmartTableBody = forwardRef(
    (
        {
            clickableRows,
            currentItems,
            firstItemOnActivePageIndex,
            noItemsLabel,
            onRowClick,
            rawColumnNames,
            scopedColumns,
            summary,
            ...rest
        },
        ref,
    ) => {


        const colspan = rawColumnNames.length
        
        const tableDataCellProps = (item, colName) => {
            const props = item._cellProps && {
                ...(item._cellProps['all'] && { ...item._cellProps['all'] }),
                ...(item._cellProps[colName] && { ...item._cellProps[colName] }),
            }

            return props
        }

        const getColumnName = (event) => {
            const target = event.target
            const closest = target.closest('tr')
            const children = closest ? Array.from(closest.children) : []
            const clickedCell = children.filter((child) => child.contains(target))[0]
            return rawColumnNames[children.indexOf(clickedCell)]
        }



        return (
            <CTableBody
                {...(clickableRows && {
                    style: { cursor: 'pointer' },
                })}
                {...rest}
                ref={ref}
            >

                {currentItems.map((item, trIndex) => {
                    return (
                        <React.Fragment key={trIndex}>
                            <CTableRow
                                {...(item._props && { ...item._props })}
                                {...(clickableRows && { tabIndex: 0 })}
                                onClick={(event) =>
                                    onRowClick &&
                                    onRowClick(
                                        item,
                                        trIndex + firstItemOnActivePageIndex,
                                        getColumnName(event),
                                        event,
                                    )
                                }
                            >
                                {rawColumnNames.map((colName, index) => {
                                    return (
                                        (scopedColumns &&
                                            scopedColumns[colName] &&
                                            React.cloneElement(
                                                scopedColumns[colName](item, trIndex + firstItemOnActivePageIndex),
                                                {
                                                    key: index,
                                                },
                                            )) || (
                                            <CTableDataCell {...tableDataCellProps(item, colName)} key={index}>
                                                {String(item[colName])}
                                            </CTableDataCell>
                                        )
                                    )
                                })}
                            </CTableRow>
                            {scopedColumns && scopedColumns.details && (
                                <>
                                    <CTableRow>
                                        <CTableDataCell
                                            colSpan={colspan}
                                            className="p-0"
                                            style={{ borderBottomWidth: 0 }}
                                            tabIndex={-1}
                                        ></CTableDataCell>
                                    </CTableRow>
                                    <CTableRow
                                        onClick={(event) =>
                                            onRowClick &&
                                            onRowClick(
                                                item,
                                                trIndex + firstItemOnActivePageIndex,
                                                getColumnName(event),
                                                true,
                                            )
                                        }
                                        className="p-0"
                                        key={`details${trIndex}`}
                                    >
                                        <CTableDataCell colSpan={colspan} className="p-0" style={{ border: 0 }}>
                                            {scopedColumns.details(item, trIndex + firstItemOnActivePageIndex)}
                                        </CTableDataCell>
                                    </CTableRow>
                                </>
                            )}
                        </React.Fragment>
                    )
                })}
                {!currentItems.length && (
                    <CTableRow>
                        <CTableDataCell colSpan={colspan} style={{textAlign: "center"}}>{noItemsLabel}</CTableDataCell>
                    </CTableRow>
                )}
                {summary &&  summary(currentItems)}
            </CTableBody>
        )
    }
)

CSmartTableBody.propTypes = {
    clickableRows: PropTypes.bool,
    currentItems: PropTypes.array.isRequired,
    firstItemOnActivePageIndex: PropTypes.number.isRequired,
    noItemsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onRowClick: PropTypes.func,
    rawColumnNames: PropTypes.array.isRequired,
    scopedColumns: PropTypes.object,
  }

export default CSmartTableBody
