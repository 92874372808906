import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  customerLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  Customers: [],
  customerSpinner: false,
  editModalVisible: false,
  customerBulkModalVisible: false,
  customerBulkUpload_error: false,
  customerBulkUpload_success: false,
  bulkCustomerSpinner: false,
  filterValues: null,
  stockStatusSheetList: [],
  stockStatusSheetSpin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getAllCustomer_SUCCESS": {
      return {
        ...state,
        Customers: action.payload.Record,
        customerSpinner: false,
      };
    }
    case "customerSpinner_START": {
      return { ...state, customerSpinner: true };
    }
    case "customerSpinner_STOP": {
      return { ...state, customerSpinner: false };
    }
    case "newCustomerActivatedInfo_SUCCESS": {
      const updateCustomerList = getCustomerList(
        action.payload,
        state.Customers
      );
      return { ...state, Customers: updateCustomerList };
    }

    case "show_customer_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }
    case "edit_customer_modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        selectedItem: action.payload,
      };
    }

    case "customer_START": {
      return { ...state, customerLoader: true };
    }
    case "customer_STOP": {
      return { ...state, customerLoader: false };
    }

    case "addCustomer_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        Customers: [action.payload.Record, ...state.Customers],
        customerLoader: false,
      };
    }
    case "addCustomer_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        customerLoader: false,
        message: action.payload,
      };
    }
    case "stockStatusSheet_SUCCESS": {
      return {
        ...state,
        stockStatusSheetList: action.payload.Record,
        stockStatusSheetSpin: false,
      };
    }
    case "stockStatusSheet_FAILURE": {
      return {
        ...state,
        stockStatusSheetSpin: false,
      };
    }
    case "stockStatusSheetSpin_START": {
      return {
        ...state,
        stockStatusSheetSpin: true,
      };
    }
    case "editCustomer_SUCCESS": {
      let oldCat = state.Customers;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        Customers: newCat,
        customerLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editCustomer_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        customerLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "bulk_import_customer": {
      return {
        ...state,
        customerBulkModalVisible: !state.customerBulkModalVisible,
      };
    }

    case "supplierBulkAccountSpinner": {
      return {
        ...state,
        bulkCustomerSpinner: true,
      };
    }

    case "bulkImportSupplierAccount_SUCCESS": {
      return {
        ...state,
        customerBulkUpload_success: true,
        bulkCustomerSpinner: false,
        textMessage: action.payload.message,
      };
    }
    case "setFilterDateInRedux": {
      return {
        ...state,
        filterValues: action.payload,
      };
    }
    case "bulkImportSupplierAccount_FAILURE": {
      return {
        ...state,
        customerBulkUpload_error: true,
        bulkCustomerSpinner: false,
        textMessage: action.message,
      };
    }

    case "customer_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        editModalVisible: false,
        resendError: false,
        resendSuccess: false,
        customerLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        message: "",
        selectedItem: null,
        customerBulkModalVisible: false,
        customerBulkUpload_error: false,
        customerBulkUpload_success: false,
        bulkCustomerSpinner: false,
      };
    }

    case "deleteCustomer_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Customers;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        Customers: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    default:
      return state;
  }

  function getCustomerList(latest, record) {
    const newArray = [];
    record.map((Item) => {
      if (Item._id === latest._id) {
        newArray.push(latest);
      } else {
        newArray.push(Item);
      }
      return Item;
    });

    return newArray;
  }
};
