import React from "react";
import { useSelector } from "react-redux";
const CheckUserAccess = (page, action) => {
  const authProps = useSelector((state) => state.auth);
  const { permissionAccess } = authProps;
  let role = authProps.LogeInUser.role;

  if (role === "superAdmin" || role === "mainSuperAdmin") {
    return true;
  } else {
    let arr = permissionAccess && permissionAccess[page];
    const found = action
      .map((item) => arr?.includes(item))
      .some((item) => item === true);

    if (found) {
      return true;
    } else {
      return false;
    }
  }
};

export default CheckUserAccess;
