import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react-pro";

import { AppSidebarNav } from "./AppSidebarNav";

import CIcon from "@coreui/icons-react";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigationMenu from "../_nav";
import logo from "../assets/images/avatars/white_logo.png";
import logo1 from "../assets/images/avatars/color_logo.png";
const AppSidebar = () => {
  const dispatch = useDispatch();

  const authProps = useSelector((state) => state.auth);
  const { accessControl } = useSelector((state) => state.commonR);
  const settingProps = useSelector((state) => state.Setting);
  const { unfoldable, sidebarShow } = settingProps;

  const { LogeInUser, permissions, permissionAccess } = authProps;

  let menuList = LogeInUser && navigationMenu[LogeInUser.role];

  let showGroups = ["home", "transactions", "administration", "reports"];

  const getMenuWithPermission = () => {
    if (
      menuList &&
      menuList.length > 0 &&
      permissions &&
      permissions.length > 0
    ) {
      let menuArray = [];
      menuList.map((menuItem) => {
        if (menuItem.items) {
          let subMenuItem = [];
          menuItem.items.map((Item) => {
            if (permissions.includes(Item.permissionKey)) {
              subMenuItem.push(Item);
            }
          });
          if (subMenuItem && subMenuItem.length > 0) {
            menuItem.items = subMenuItem;
            menuArray.push(menuItem);
          }
        } else {
          if (permissions.includes(menuItem.permissionKey)) {
            menuArray.push(menuItem);
          }
        }
      });
      return menuArray;
    } else {
      return [];
    }
  };

  const getFilteredItems = (navItems) => {
    let result = [];
    navItems.forEach((menuItem) => {
      if (menuItem.items) {
        let subMenuItem = [];
        menuItem.items.map((Item) => {
          if (showGroups.includes(Item.group)) {
            subMenuItem.push(Item);
          }
        });
        if (subMenuItem && subMenuItem.length > 0) {
          menuItem.items = subMenuItem;
          result.push(menuItem);
        }
      }
      if (showGroups.includes(menuItem.group)) {
        result.push(menuItem);
      }
    });

    return result;
  };

  let permittedItems =
    LogeInUser.role !== "mainSuperAdmin" ? getMenuWithPermission() : menuList;

  //filter items - user select which nav items to be display on sidebar from permitted items- he can select which item to show to sidebar by clicking at the buttons on topbar

  let filteredItems =
    LogeInUser.role !== "mainSuperAdmin"
      ? getFilteredItems(permittedItems)
      : menuList;

  return (
    <CSidebar
      position="fixed"
      selfHiding="md"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onHide={() => {
        dispatch({ type: "set", sidebarShow: false });
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        {/* <h3>SageBiz</h3> */}
        <img src={logo} width="140" />
        {/* <img src={logo1} width="140" /> */}
        {/* <CIcon className="sidebar-brand-full" name="logo-negative" height={35} />
        <CIcon className="sidebar-brand-narrow" name="sygnet" height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={permittedItems} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: "set", unfoldable: !unfoldable })}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
