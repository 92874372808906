import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormFloating,
  CFormLabel,
  CForm,
  CFormInput,
  CLoadingButton,
  CMultiSelect,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { notify } from "../../../util/ShowToast";
import menuData from "../_data";
const AddCompanyModal = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.company);
  const { modalVisible, selectedItem, success, companyLoader, error } =
    comProps;

  const modalType = "show_company_modal";
  const showModal = (Item) => {
    dispatch(DataEntryModalAction(modalType, Item));
  };

  const name_message = intlPlaceholder("companyModal.name_message");
  const localCurrencySymbol_message = intlPlaceholder(
    "companyModal.localCurrencySymbol_message"
  );
  const factor_message = intlPlaceholder("companyModal.factor_message");
  const firstNameRequired = intlPlaceholder("firstNameRequired");
  const lastNameRequired = intlPlaceholder("lastNameRequired");
  const companyNameRequired = intlPlaceholder("companyNameRequired");
  const emailRequired = intlPlaceholder("emailRequired");
  const phoneNumberRequired = intlPlaceholder("phoneNumberRequired");

  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(firstNameRequired),
    lastName: Yup.string().required(lastNameRequired),
    companyName: Yup.string().required(companyNameRequired),
    email: Yup.string().required(emailRequired).email(),
    phoneNumber: Yup.string().required(phoneNumberRequired),
    name: Yup.string().required(name_message),
    symbol: Yup.string().required(localCurrencySymbol_message),
    factor: Yup.string().required(factor_message),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (selectedItem) {
      reset(selectedItem);
    }
  }, [modalVisible]);

  const onFinish = (values) => {
    let data = [];
    values.permissions.map((item) => {
      let result = menuData.filter((x) => x.value === item);

      data = [...data, item, ...result[0].subMenu];
    });
    values.permissions = data;
    let newPermission = [...data, "resetPassword", "dashboard", "profile"];

    let obj = {
      ...values,
      permissions: newPermission,
    };

    if (selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "inviteCompany", obj, "", "company", false)
      );
    }
  };

  if (success) {
    reset();
    notify("success", intlPlaceholder("recordAddeddSuccessfully"));
    dispatch(updateAllState("company"));
  }
  if (error) {
    let messageLng = intlPlaceholder(comProps.message);
    reset();
    notify("error", messageLng);
    dispatch(updateAllState("company"));
  }

  const renderOptions = () => {
    if (menuData && menuData.length > 0) {
      let data = menuData.map((data) => ({
        text: intlPlaceholder(data.text),
        value: data.value,
      }));

      return data;
    } else return [];
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <IntlMessages id="admin.Add.Company.Button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>Invite Company</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.firstName ? "is-invalid" : ""}
                  {...register("firstName")}
                  placeholder={intlPlaceholder("companyModal.firstName")}
                  type="text"
                  id="firstName"
                />
                <CFormLabel htmlFor="firstName">
                  {intlPlaceholder("companyModal.firstName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </CFormFloating>

              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.lastName ? "is-invalid" : ""}
                  {...register("lastName")}
                  placeholder={intlPlaceholder("companyModal.lastName")}
                  type="text"
                  id="lastName"
                />
                <CFormLabel htmlFor="lastName">
                  {intlPlaceholder("companyModal.lastName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </CFormFloating>
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.companyName ? "is-invalid" : ""}
                  {...register("companyName")}
                  placeholder={intlPlaceholder("companyModal.companyName")}
                  type="text"
                  id="companyName"
                />
                <CFormLabel htmlFor="companyName">
                  {intlPlaceholder("companyModal.companyName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.companyName?.message}
                </div>
              </CFormFloating>
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.email ? "is-invalid" : ""}
                  {...register("email")}
                  placeholder={intlPlaceholder("companyModal.email")}
                  type="text"
                  id="email"
                />
                <CFormLabel htmlFor="email">
                  {intlPlaceholder("companyModal.email")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.email?.message}</div>
              </CFormFloating>
            </div>

            <Controller
              control={control}
              name={"phoneNumber"}
              placeholder={intlPlaceholder(
                "employee.addEmployeeModal.phoneNumber"
              )}
              render={(props) => {
                return (
                  <PhoneInput
                    country={"gn"}
                    value={selectedItem && selectedItem.phoneNumber}
                    onChange={(v) => props.field.onChange(v)}
                    placeholder={intlPlaceholder(
                      "employee.addEmployeeModal.phoneNumber"
                    )}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                  />
                );
              }}
            />
            <div className="error-message">{errors.phoneNumber?.message}</div>

            <div style={{ width: "100%" }} className="mt-3">
              <Controller
                control={control}
                name="permissions"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <>
                    <CMultiSelect
                      className={errors.permissions ? "is-invalid" : ""}
                      onChange={(value) => {
                        let updatedValue =
                          value && value.map((item) => item.value);
                        onChange(updatedValue);
                      }}
                      placeholder={intlPlaceholder("inviteCompany.permissions")}
                      optionsStyle="text"
                      options={renderOptions}
                      multiple={true}
                    />
                  </>
                )}
              />

              <div className="invalid-feedback">
                {errors.permissions?.message}
              </div>
            </div>

            <hr />

            <h4> {intlPlaceholder("companyModal.localCurrencyDetails")}</h4>

            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.lastName ? "is-invalid" : ""}
                  {...register("name")}
                  placeholder={intlPlaceholder("companyModal.name")}
                  type="text"
                  id="name"
                />
                <CFormLabel htmlFor="name">
                  {intlPlaceholder("companyModal.name")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.name?.message}</div>
              </CFormFloating>
            </div>

            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.lastName ? "is-invalid" : ""}
                  {...register("symbol")}
                  placeholder={intlPlaceholder(
                    "companyModal.localCurrencySymbol"
                  )}
                  type="text"
                  id="symbol"
                />
                <CFormLabel htmlFor="symbol">
                  {intlPlaceholder("companyModal.localCurrencySymbol")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.symbol?.message}</div>
              </CFormFloating>
            </div>

            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.factor ? "is-invalid" : ""}
                  {...register("factor")}
                  placeholder={intlPlaceholder("companyModal.factor")}
                  type="text"
                  id="symbol"
                />
                <CFormLabel htmlFor="symbol">
                  {intlPlaceholder("companyModal.factor")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.factor?.message}</div>
              </CFormFloating>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="companyModal.close" />
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={companyLoader}
              disabled={companyLoader}
            >
              <IntlMessages id="companyModal.invite" />
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddCompanyModal;
