import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import CheckUserAccess from "../../util/checkUserAccess";
import ContractTable from "./components/ContractTable";
import AddContractModal from "./components/AddContractModal";
import ContractFilter from "./components/ContractFilter";

const Index = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.contractReducer);
  const propertyProps = useSelector((state) => state.propertyReducer);
  
  const { LogeInUser } = useSelector((state) => state.auth);

  const [filter, setFilter] = useState({
    status: "active",
    property: "all",
    startDate: "",
    endDate: "",
    search: "",
  });

  console.log("==userInfo", LogeInUser);
  console.log("===Data", comProps);

  const fetchContract = () => {
    const query = {
      property: filter.property,
      status: filter.status,
    };
    dispatch(DataGetAction("contracts", query, "contractSpinner"));
  };

  const fetchProperty = () => {
    dispatch(DataGetAction("properties", { query: "all" }, "propertySpinner"));
  };

  useEffect(() => {
    fetchContract();
    fetchProperty();
  }, [comProps?.fetchContractInfo, filter]);

  console.log("===Data", comProps);
  console.log("===properties", propertyProps?.Properties);

  const create = CheckUserAccess("contract", ["create"]);

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="contract.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex align-items-center justify-content-start gap-2">
                <ContractFilter setFilter={setFilter} Properties={propertyProps?.Properties} />
                {create && <AddContractModal />}
              </div>

              <DocsExample href="components/smart-table/">
                <ContractTable Contracts={comProps?.Contracts} contractSpinner={comProps?.contractSpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
