import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CLoadingButton,
  CModalFooter,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { FormDataRequestAction } from "../../../redux/actions/http";
import bulkImportIcon from "../../../assets/product_bulk.svg";
const BulkImportUser = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.ProductR);
  const [bulkFile, setBulkFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const { productBulkModalVisible, bulkProductSpinner } = comProps;

  const modalType = "bulk_import_product";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const saveBulkFiles = () => {
    if (bulkFile) {
      dispatch(
        FormDataRequestAction(
          "bulkImportProduct",
          bulkFile,
          "",
          "",
          "bulkImportProductSpinner"
        )
      );
    } else {
      setShowError(true);
    }
  };

  const onFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setBulkFile(files[0]);
    setShowError(false);
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        {/* <i className="icon icon-add me-1"></i> */}
        <div className="d-flex align-items-center">
          <img
            src={bulkImportIcon}
            style={{ width: "20px", height: "20px", marginRight: 5 }}
          />
          <IntlMessages id="product.bulkImport" />
        </div>
      </CButton>

      <CModal visible={productBulkModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("product.bulkImport")}</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <div className="d-flex justify-content-between">
            <input id="files" type="file" onChange={onFileChange} />
            {/* <div>
              <label for="files" class="btn"><IntlMessages id="product.uploadCsv" /></label>
              
             
            </div> */}
            <a
              href={
                "https://sagebiz.s3.eu-west-3.amazonaws.com/profiles/productStockTemplate.csv"
              }
              target="_blank"
            >
              <IntlMessages id="product.sample" />
            </a>
          </div>
          {showError && (
            <span style={{ color: "red", fontSize: "12px" }}>
              <IntlMessages id="product.csvRequired" />
            </span>
          )}
        </CModalBody>
        <CModalFooter>
          <CLoadingButton
            type="submit"
            color="primary"
            loading={bulkProductSpinner}
            disabled={bulkProductSpinner}
            onClick={() => {
              saveBulkFiles();
            }}
          >
            {intlPlaceholder("product.addBulkModal.save")}
          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default BulkImportUser;
