import React, { useEffect } from "react";

import CSmartTable from '../../../components/CSSmartTable'
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";
import { useHistory, useLocation } from "react-router-dom";
import { DataGetAction } from '../../../redux/actions/http'
import moment from "moment";
const SupplierBalanceSheetList = () => {
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.balanceSheet);
  const history = useHistory();
  const param = useLocation();
  const { supplierId, currencyId } = param.state.data;
  const { filterDate } = param.state;


  const redirectToPage = (data) => {

    if (data.prefix === 'RF') {
      history.push(`/duesReceipt`, { ReceiptNo: parseInt(data.referenceNo) });
    } else {
      history.push(`/viewInvoice`, {
        InvoiceNo: data.referenceNo,
        supplierId: data.supplierId,
        currencyId: data.currencyId,
        ourId: data.ourId,
      });
    }
  };



  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "date",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "Ref",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.debit" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.credit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];
  const fetchBalanceSheet = () => {
    if (filterDate) {
      dispatch(
        DataGetAction(
          "supplierBalanceSheet",
          {
            startDate: filterDate.startDate,
            endDate: filterDate.endDate,
            supplier: supplierId,
            currency: currencyId,
          },
          "supplierBalanceSheetSpinner"
        )
      );
    } else {
      dispatch(
        DataGetAction(
          "supplierBalanceSheet",
          {
            startDate: moment().startOf("year").toString(),
            endDate: moment().endOf("year").toString(),
            supplier: supplierId,
            currency: currencyId,
          },
          "supplierBalanceSheetSpinner"
        )
      );
    }
    return () => {
      dispatch({
        type: "removeTheResult",
      });
    };
  };

  useEffect(fetchBalanceSheet, []);
  const { SupplierBalanceSheetData, supplierBalanceSheetSpinner } = blProps;





  let startDate = moment().startOf("year").toString();
  let endDate = moment().toString();

  if (filterDate) {
    startDate = filterDate.startDate;
    endDate = filterDate.endDate;
  }



  return (
    <>
      <CSmartTable
        loading={supplierBalanceSheetSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={supplierBalanceSheetSpinner ? [] : SupplierBalanceSheetData}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={200}
        columnSorter
        pagination
        underTableSlot={<>Total: {"sfds"}</>}
        scopedColumns={{
          description: (record) => {
            return <td>{record.remark ? record.remark :<IntlMessages id={record.description} />}</td>
          },
          date: (record) => {
            return <td>{ChangeDateFormate(record.createdAt)}</td>;
          },
          Ref: (record) => {
            return (
              <td
                onClick={() => {
                  if (record.ourId) {
                    redirectToPage(record)
                  }
                  else {
                    console.log('i')
                  }

                }}
                style={{
                  cursor: "pointer",

                  color: "blue",
                }}
              >
                {record.ourId ? <span>{`${record.prefix}-${record.ourId}`} / {record.showRef ? record.showRef : record.referenceNo}</span> : '----'}
              </td>
            );
          },
          debit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.debit && record.debit.toFixed(2))}
              </td>
            );
          },
          credit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.credit && record.credit.toFixed(2))}
              </td>
            );
          },
          balance: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.balance && parseFloat(record.balance).toFixed(2)
                )}
              </td>
            );
          },
        }}
        summary={pageData => {
          let totalBorrow = 0;
          let totalRepayment = 0;

          pageData.forEach(({ debit, credit }) => {
            totalBorrow += debit;
            totalRepayment += credit;
          });

          return (
            <tr>
              <th colSpan={3} className="gx-text-center"><IntlMessages id="total" /></th>
              <th className="text-end">
                <>{ChangeNumberFormate(totalBorrow > 0 ? totalBorrow.toFixed(2) : 0)}</>
              </th>
              <th className="text-end">
                <>{ChangeNumberFormate(totalRepayment > 0 ? totalRepayment.toFixed(2) : 0)}</>
              </th>
              <th>
                <></>
              </th>
            </tr>

          );
        }}
      />
    </>
  );
};

export default SupplierBalanceSheetList;
