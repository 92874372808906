import React, { useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CProgress,
  CRow,
} from '@coreui/react-pro'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import {
  cilCloudDownload,
} from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'src/redux/actions/http'
import { intlPlaceholder } from 'src/util/intlPlaceholder'
import { NumberWithCommas } from 'src/constants/CommonFn'

const ClientReceiptAndInvoiceGraph = () => {

  const dispatch = useDispatch()
  const stateProps = useSelector(state => state.dashboard)
  const { clientInvoiceAndReceiptData, clientInvoiceAndReceiptDataLoader } = stateProps
  const fetchClientReceiptAndInvoiceGraphData = () => {
    dispatch(DataGetAction('clientReceiptAndInvoice', { 'query': "all", startDate: moment() }, 'clientReceiptInvoiceSpinner'))
  }

  useEffect(fetchClientReceiptAndInvoiceGraphData, [])



  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }


  const renderGraph = () => {
    if (!clientInvoiceAndReceiptDataLoader && clientInvoiceAndReceiptData) {

      const { labels, dataSetInvoice, dataSetReceipt } = clientInvoiceAndReceiptData

      return (
        <CChartLine
          style={{ height: '300px', marginTop: '10px' }}
          data={{
            labels: labels,
            datasets: [
              {
                label: intlPlaceholder('SETTLEMENT'),
                backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
                borderColor: getStyle('--cui-primary'),
                pointHoverBackgroundColor: getStyle('--cui-info'),
                borderWidth: 2,
                data: dataSetReceipt,
                fill: true,
              },
              {
                label: intlPlaceholder('SALE'),
                backgroundColor: 'transparent',
                borderColor: getStyle('--cui-orange'),
                pointHoverBackgroundColor: getStyle('--cui-success'),
                borderWidth: 2,
                data: dataSetInvoice,
              },

            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                align: 'end'
              },
            },
            scales: {
              x: {
                grid: {
                  drawOnChartArea: true,

                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  stepSize: Math.ceil(250 / 5),
                  max: 250,
                },
              },
            },
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
              },
            },
          }}
        />
      )
    }

    if (clientInvoiceAndReceiptDataLoader) {
      return <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }
  }

  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm={5}>
            <h4 id="traffic" className="card-title mb-0">
              {intlPlaceholder('clientsGraphTitle')}
            </h4>
            <div className="small text-medium-emphasis"> {intlPlaceholder('clientsGraphSubTitle')}</div>
          </CCol>
        </CRow>
        {renderGraph()}
        <CRow>
          <CCol style={{ textAlign: 'center' }}> 
          {intlPlaceholder('TotalSettlement')}: {clientInvoiceAndReceiptData && NumberWithCommas(clientInvoiceAndReceiptData.currentDayTotalReceipt) || 0} <br></br>
          {intlPlaceholder('TotalSale')}: {clientInvoiceAndReceiptData && NumberWithCommas(clientInvoiceAndReceiptData.currentDayTotalInvoices) || 0}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default ClientReceiptAndInvoiceGraph