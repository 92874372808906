import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { useHistory } from "react-router";
import { notify } from "src/util/ShowToast";
const ReceiptDetail = () => {
  const componentRef = useRef();
  const invProps = useSelector((state) => state.receiptReducer);
  const {refundCustomerReceiptSuccess,message,refundCustomerReceiptError} = invProps
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;
  const dispatch = useDispatch();
  const history = useHistory()
  const {
    receiptNumber,
    prefix,
    createdAt,
    createdBy,
    paymentMode,
    currencyInfo,
    store,
    customer,
    totalAmountDue,
    paid,
    remark,
    receiptType,
    _id
  } = invProps.ReceiptDetail !== null ? invProps.ReceiptDetail : "000-000-000";

  const oldReceipt = invProps.ReceiptDetail && invProps.ReceiptDetail.oldReceiptNumber && invProps.ReceiptDetail.oldReceiptNumber 
  const isRefund = invProps.ReceiptDetail && invProps.ReceiptDetail.refund && invProps.ReceiptDetail.refund

  const storeName = store && store[0] ? store[0].label : "";
  const storeEmail = store && store[0] ? store[0].email : "";
  const storeAddress = store && store[0] ? store[0].address : "";
  const customerName = customer && customer[0] ? customer[0].label : "";

  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";

  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const columns = [
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.bankName" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionType" />,
      key: "TransactionType",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionNo" />,
      key: "TransactionNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.Amount" />,
      key: "Amount",
      _style: { width: "40%" },
    },
  ];

  const renderPaymentDetail = () => {
    
    const paymentModeArray = paymentMode ? [paymentMode] : [];
    return (
      <TransactionType
        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          mode: (record) => {
            return (
              <td>{<IntlMessages id={`${record.mode}.paymentMethod`} />}</td>
            );
          },
          bankName: (record) => {
            return <td>{record.bank ? record.bank.name : "---"}</td>;
          },
          TransactionType: (record) => {
            return (
              <td>{record.transactionType ? record.transactionType : "---"}</td>
            );
          },
          TransactionNo: (record) => {
            return (
              <td>
                {record.transactionNumber ? record.transactionNumber : "---"}
              </td>
            );
          },
          Amount: (record) => {
            return (
              <td>
                {ChangeNumberFormate(paid.toFixed(2))}{" "}
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""}
              </td>
            );
          },
        }}
      />
    );
  };

  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  const renderAmountDune = () => {
    let amountDue = totalAmountDue;
    if (totalAmountDue < 0) {
      amountDue = -1 * totalAmountDue;
    }

    return (
      <div className="d-flex">
        <span
          className="d-inline-block "
          style={{ width: "40%", fontSize: 13, color: "#003366" }}
        >
          <IntlMessages id="invoiceDetail.amountDueWord" />:
        </span>
        <span
          className="d-inline-block text-capitalize"
          style={{ width: "60%", fontSize: 13 }}
        >
          {totalAmountDue < 0
            ? ChangeNumberToWord(amountDue, languageId)
            : ChangeNumberToWord(totalAmountDue, languageId)}
        </span>
      </div>
    );
  };

  const getRecordValue = () => {
    const paymentModeArray = paymentMode ? [paymentMode] : [];
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    let totalBillFigure = `${
      currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : ""
    } ${NumberWithCommas(parseFloat(paid))}`;
    let totalBillWords = ChangeNumberToWord(paid, languageId);

    let amountDue = totalAmountDue;
    if (totalAmountDue < 0) {
      amountDue = -1 * totalAmountDue;
    }

    let totalBalanceFigure = `${
      currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : "" 
      
    } ${NumberWithCommas(parseFloat(amountDue))}`;
    let totalBalanceWords = ChangeNumberToWord(amountDue, languageId);

    let data = [];

    if (paymentModeArray && paymentModeArray.length > 0) {
      paymentModeArray.map((item) => {
        let { mode, quantity, salePrice, total, ...rest } = item;

        mode = intlPlaceholder(`${mode}.paymentMethod`)
      

        let amount = totalBillFigure;
        let obj = {
          mode,
          bankName: item.bank ? item.bank.name : "---",
          transactionType: item.transactionType ? item.transactionType : "---",
          transactionNumber: item.transactionNumber
            ? item.transactionNumber
            : "---",
          amount: amount,
        };

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.store");
    let key2 = intlPlaceholder("customer");
    let key3 = intlPlaceholder("receiptDetail.receiptNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("receiptDetail.PaymentMode"),
      intlPlaceholder("receiptDetail.bankName"),
      intlPlaceholder("receiptDetail.TransactionType"),
      intlPlaceholder("receiptDetail.TransactionNo"),
      intlPlaceholder("receiptDetail.Amount"),
    ];

    let detailObj = {
      [key1]: { name: storeName, address: storeAddress },
      [key2]: customerName,
    };

    detailObj = {
      ...detailObj,
      [key3]: prefix + "-" + receiptNumber,
      [key4]: createdByName,
    };

    let obj = {
      subject: `${intlPlaceholder(
        "receiptDetail.ReceiptLabel"
      )}  ${intlPlaceholder('from')} ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: intlPlaceholder("receiptDetail.ReceiptLabel"),
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,
      totalBillKey: intlPlaceholder("invoiceDetail.totalBill"),
      totalBillWorldKey: intlPlaceholder("invoiceDetail.totalBillWords"),
      totalBalanceKey: intlPlaceholder("invoiceDetail.amountDue"),
      totalBalanceWorldKey: intlPlaceholder("invoiceDetail.amountDueWord"),
      totalBillFigures: totalBillFigure,
      totalBillWords: totalBillWords,
      totalBalanceFigure: totalBalanceFigure,
      totalBalanceWords: totalBalanceWords,
      remark,
      note: intlPlaceholder("invoiceDetail.note"),
    };

    return obj;
  };

  const areYouSureYouWantToRefundTheReceiptRumber = intlPlaceholder(
    "areYouSureYouWantToRefundTheReceiptRumber"
  );


  if (refundCustomerReceiptSuccess) {
    history.push("/storeReceipt");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  if (refundCustomerReceiptError) {
    let messageLng = intlPlaceholder(message);
    history.push("/storeReceipt");
    notify("error", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }


  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
        {/* {isRefund &&   <CButton
            color="success"
            className="text-white me-3"
            
          >
                     <span><IntlMessages id="refunded" /></span>

          </CButton>} */}

{/* receipt comment */}
     {/* {(receiptType && receiptType !== 'refundCustomerSale' && !isRefund )&&  <DeleteModal
            contentLangueId={`${areYouSureYouWantToRefundTheReceiptRumber} ${prefix}-${receiptNumber}`}
            method="POST"
            type="refundCustomerReceipt"
            selectedItem={{ _id, receiptNumber }}
            ButtonTitleId="receipt.refund"
            titleLanguageId="receipt.refund"
            size="md"
            color="success"
            variant=""
          />} */}
          
      {(receiptType && receiptType !== 'refundCustomerSale' ) && <CButton
            color="warning"
            className="text-white me-3"
            onClick={() => {
              dispatch({
                type: "send_email_modal",
              });
            }}
          >
            <i className="icon icon-mail-open me-1 mb-1"></i>
            <IntlMessages id="sendMail" />
          </CButton>}

          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 "
        ref={componentRef}
      >
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          {/* <IntlMessages id="receiptDetail.ReceiptLabel" /> */}
          {( receiptType && receiptType === 'refundCustomerSale') ? `${intlPlaceholder('refundOfReceipt')} ${prefix}-${oldReceipt} ` : <IntlMessages id="receiptDetail.ReceiptLabel" />}
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="store" />
              </h4>
              <p style={{ color: "grey", marginBottom: 0 }}>{storeName}</p>
              <p style={{ color: "grey" }}>{storeAddress}</p>
            </div>

            <div>
              <h4 style={{ fontWeight: "bold" }}>
                {" "}
                <IntlMessages id="customer" />
              </h4>
              <p style={{ color: "grey" }}>{customerName}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.receiptNumber" />
              </h4>
              <p style={{ color: "grey" }}>{prefix + "-" + receiptNumber}</p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.createdBy" />
              </h4>
              <p style={{ color: "grey" }}>{createdByName}</p>
            </div>
          </div>
        </div>
        <br />

        <div className="w-100">{renderPaymentDetail()}</div>

        <div className="d-flex justify-content-between mt-4">
          <p>
            {remark && <IntlMessages id="invoiceDetail.note" />}
            {remark && `: ${remark === 'Refund Customer Payment' ? intlPlaceholder('Refund Customer Payment') : remark}`}
          </p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 13, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBill" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "60%", fontSize: 20,fontWeight : 'bold' }}
              >
                 {ChangeNumberFormate(parseFloat(paid).toFixed(2))}
                 {" "}
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""} 
               
              </span>
            </div>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 13, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBillWords" />:
              </span>
              <span
                className="d-inline-block text-capitalize "
                style={{
                  width: "60%",
                  fontSize: 13,
                  textTransform: "capitalize",

                }}
              >
                {ChangeNumberToWord(paid, languageId)}
              </span>
            </div>

            <div className="d-flex mt-2">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 13, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.amountDue" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "60%", fontSize: 20,fontWeight : 'bold' }}
              >
                {ChangeNumberFormate((parseFloat(totalAmountDue)).toFixed(2)
                )}
                {" "}
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""}
                
              </span>
            </div>
            {renderAmountDune()}
          </div>
        </div>
        <InvoiceSignature data={{ clientName: customerName }} />
      </div>
      <SendEmailModal
        initialValues={{ email: storeEmail }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default ReceiptDetail;
