import React, { useEffect } from "react";
import { CContainer, CRow, CCol } from "@coreui/react-pro";
import TrustFundStatistics from "./components/trustFundStatistics";
import StatisticsOfCashAccount from "./components/statisticsOfCashAccount";
import BankAccountStatistics from "./components/bankAccountStatistics";
import { DataGetAction } from "src/redux/actions/http";
import { useDispatch } from "react-redux";
import ClientReceiptAndInvoiceGraph from "./components/clientReceiptAndInvoiceGraph";
import StatisticOfCurrencyExchange from "./components/statisticsOfCurrencyExchange";
import SupplierInvoiceAndReceiptGraph from "./components/supplierInvoiceAndReceiptGraph";
import CompanyExpensesGraph from "./components/companyExpenseGraph";
import GrossProfitCard from "./components/grossProfitCard";
import CheckUserAccess from "src/util/checkUserAccess";

const Dashboard = () => {
  const dispatch = useDispatch();

  const fetchTrustFundStatistics = () => {
    //===== Fetching profile info for profile logo to set logo in localStorage
    dispatch(DataGetAction("getProfileInfo", "", "fetchingData"));
  };

  useEffect(fetchTrustFundStatistics, []);
  const CheckGrossProfitAccess = CheckUserAccess("dashboard", ["grossProfit"]);

  return (
    <>
      <CContainer>
        {CheckGrossProfitAccess && (
          <CRow>
            <CCol>
              <GrossProfitCard />
            </CCol>
          </CRow>
        )}
        <CRow>
          <CCol>
            <ClientReceiptAndInvoiceGraph />
          </CCol>
          <CCol>
            <SupplierInvoiceAndReceiptGraph />
          </CCol>
        </CRow>
        <CRow className="align-items-start mb-2">
          <CCol>
            <StatisticsOfCashAccount />
          </CCol>
          <CCol>
            <BankAccountStatistics />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            {" "}
            <TrustFundStatistics />
          </CCol>
          <CCol>
            {" "}
            <StatisticOfCurrencyExchange />
          </CCol>
        </CRow>
        <br></br>
        <CRow>
          <CCol>
            <CompanyExpensesGraph />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default Dashboard;
