import React from "react";
import moment from "moment";
import writtenNumber from "written-number";
import NumberFormat from "react-number-format";
import frenchLanguage from '../lngProvider/locales/fr_FR.json'
import englishLanguage from '../lngProvider/locales/en_US.json'
//YYYY-MM-DD HH:mm
export const ChangeDateFormate = (date, formateString = "DD-MM-YYYY HH:mm") =>
  moment(date).format(formateString);
export const ChangeNumberFormate = (number, currency = "") => (
  <NumberFormat
    decimalScale={3}
    value={number}
    displayType={"text"}
    thousandSeparator={true}
    prefix={`${currency}`}
  />
);

export const NumberWithCommas = (number, currency = "") => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
  return formatter.format(number);
};

export const ChangeNumberToWord = (amount, language) => {
  let lang = "fr";
  if (language === "english") {
    lang = "en";
  }
  return writtenNumber(amount, { lang });
};
export const selectStyle = {
  control: (provided, state) => {
    return {
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      boxShadow: state.isFocused ? `0 0 0 0.25rem rgba(50, 31, 219 , 25%)` : "",
      cursor: 'pointer',
    };
  },
};

export const conversionInLanguage = (language,key) => {

  
  let data = ''
  if(language === 'fr'){
    data = frenchLanguage[key]
  }
  else if(language === 'en'){
    data = englishLanguage[key]
  }
  
 
  return data
};