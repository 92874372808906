import React, { useEffect, useState } from "react";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
} from "@coreui/react-pro";
import IntlMessages from "src/util/IntlMessages";
import Filter from "./components/Filter";
import { useHistory } from "react-router-dom";
import CheckUserAccess from "src/util/checkUserAccess";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { useDispatch, useSelector } from "react-redux";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import DocsExample from "src/components/DocsExample";
import DeliveredList from "../../components/CSSmartTable";
import moment from "moment";
import { DataGetAction } from "src/redux/actions/http";
import ViewDelivered from "./components/viewDelivered";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { DeliveryListSpin, DeliveryList } = useSelector(
    (state) => state.deliveredR
  );
  const create = CheckUserAccess("delivered", ["create"]);
  const view = CheckUserAccess("delivered", ["view"]);
  const edit = CheckUserAccess("delivered", ["edit"]);
  const [filterValue, setFilterValue] = useState(null);
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({
    status: "approved",
  });
  const redirectToPage = () => {
    dispatch({ type: "RESET_Delivered_LOCAL_PRODUCT" });
    history.push("/addDelivered");
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const editRecord = (data) => {
    dispatch({
      type: "",
      payload: data,
    });
  };
  const renderAction = (data) => {
    return (
      <>
        {view && (
          <CButton
            size="sm"
            color="warning"
            className="me-3"
            onClick={() =>
              dispatch({ type: "Show_View_Delivered_Modal", payload: data })
            }
          >
            <span style={{ color: "white" }}>
              <span className="icon icon-custom-view me-2"></span>
              <IntlMessages id="view" />
            </span>
          </CButton>
        )}
        {/* {edit && (
          <CButton size="sm" color="warning" onClick={() => editRecord(data)}>
            <i className="icon icon-edit text-white me-1"></i>
            <span style={{ color: "white" }}>
              <IntlMessages id="Edit" />
            </span>
          </CButton>
        )} */}
      </>
    );
  };
  const columns = [
    {
      label: <IntlMessages id="delivered.deliveredId" />,
      key: "prefix",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="delivered.clientName" />,
      key: "clientName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="delivered.invoiceId" />,
      key: "invoiceId",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="delivered.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="delivered.createdBy" />,
      key: "createdByUser",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="delivered.status" />,
      key: "status",
      filterType: "dropDown",
      filterDropDownValue: [
        { label: intlPlaceholder("invoice.all"), value: "all" },
        {
          label: intlPlaceholder("invoice.approved"),
          value: "approved",
          selected: true,
        },
        { label: intlPlaceholder("invoice.cancelled"), value: "cancelled" },
      ],
      _style: { width: "40%" },
    },
    {
      label: "",
      filter: false,
      sorter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const fetchDeliveredProduct = () => {
    setFilterValue({
      startDate: moment().toString(),
      endDate: moment().toString(),
    });
    dispatch(
      DataGetAction(
        "getDeliveryList",
        {
          startDate: moment().toString(),
          endDate: moment().toString(),
        },
        "deliveryListSpin"
      )
    );
  };
  useEffect(fetchDeliveredProduct, []);
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="delivered.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter setFilter={setFilterValue} />
                <div className="d-flex align-items-center">
                  {create && (
                    <CButton
                      color="success"
                      className="me-3 text-white"
                      onClick={() => redirectToPage()}
                    >
                      <i className="icon icon-add me-1"></i>
                      <IntlMessages id="addDelivered" />
                    </CButton>
                  )}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <DeliveredList
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={DeliveryList}
                  loading={DeliveryListSpin}
                  columns={columns}
                  columnFilterValue={staticFilterValue}
                  filterValueChangeTo={(value) => {
                    if (value.status) {
                      setStaticFilterValue(value);
                    }
                  }}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge
                            style={{
                              textTransform: "capitalize",
                            }}
                            color={
                              record.status === "approved"
                                ? "success"
                                : "danger"
                            }
                          >
                            {" "}
                            {intlPlaceholder(`invoice.${record.status}`)}
                          </CBadge>
                        </td>
                      );
                    },

                    clientName: (record) => {
                      return (
                        <td>
                          <span>{record.clientName}</span>
                        </td>
                      );
                    },

                    createdAt: (record) => {
                      return (
                        <td>
                          <span>
                            {ChangeDateFormate(record.createdAt, "YYYY-MM-DD")}
                          </span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? (
                              <IntlMessages id="hide" />
                            ) : (
                              <IntlMessages id="show" />
                            )}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ViewDelivered />
    </>
  );
};

export default Index;
