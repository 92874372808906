import React, { useState, useEffect } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { notify } from "../../../../util/ShowToast";
import { DataGetAction } from "../../../../redux/actions/http";
import CheckUserAccess from "../../../../util/checkUserAccess";
const UserList = () => {
  const [details, setDetails] = useState([]);
  const dispatch = useDispatch();
  const {
    allTrustUserSpinner,
    TrustUsers,
    bulkUpload_success,
    bulkUpload_error,
    textMessage,
  } = useSelector((state) => state.trustFund);
  const modalType = "show_trust_modal";

  const editRecord = (data) => {
    dispatch({
      type: "edit_trust_modal",
      payload: data,
    });
  };
  const showModal = (Item) => {
    dispatch({
      type: "show_trust_modal",
      payload: Item,
    });
  };

  const fetchTrustUser = () => {
    dispatch(
      DataGetAction("getAllTrustUser", { query: "all" }, "allTrustUserSpinner")
    );
  };
  useEffect(fetchTrustUser, []);

  if (bulkUpload_success) {
    fetchTrustUser();
    let messageLng = intlPlaceholder(textMessage);
    notify("success", messageLng);
    dispatch({
      type: "trust_UPDATE",
    });
  }
  if (bulkUpload_error) {
    let messageLng = intlPlaceholder(textMessage);
    notify("error", messageLng);
    dispatch({
      type: "trust_UPDATE",
    });
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  let columns = [
    {
      label: <IntlMessages id="trust.name" />,
      key: "firstName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="trust.phone" />,
      key: "phoneNumbers",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="trust.address" />,
      key: "location",
      _style: { width: "40%" },
    },
  ];
  let found = CheckUserAccess("trustUser", ["edit", "delete"]);
  if (found) {
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    });
  }
  const edit = CheckUserAccess("trustUser", ["edit"]);
  const deleted = CheckUserAccess("trustUser", ["delete"]);

  const renderPhoneNumbers = (phoneNumbers) => {
    if (phoneNumbers && phoneNumbers.length > 0) {
      return phoneNumbers.map((item, index) => {
        return (
          <span>
            {index !== 0 ? ", " : ""}
            {item}
          </span>
        );
      });
    }
  };
  return (
    <>
      <CSmartTable
        loading={allTrustUserSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={allTrustUserSpinner ? [] : TrustUsers}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? (
                    <IntlMessages id="hide" />
                  ) : (
                    <IntlMessages id="show" />
                  )}
                </CButton>
              </td>
            );
          },
          location: (record) => {
            return (
              <td className="py-2">
                {record.location ? record.location : "---"}
              </td>
            );
          },
          phoneNumbers: (record) => {
            return (
              <td className="py-2">
                {renderPhoneNumbers(record.phoneNumbers)}
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  {edit && (
                    <CButton
                      size="sm"
                      color="warning"
                      className="me-3"
                      onClick={() => editRecord(record)}
                    >
                      <span style={{ color: "white" }}>
                        <span className="icon icon-edit me-2"></span>
                        {intlPlaceholder("Edit")}
                      </span>
                    </CButton>
                  )}
                  {deleted && (
                    <DeleteModal
                      contentLangueId="DeletePopText"
                      type="deleteTrustUser"
                      selectedItem={record}
                      ButtonTitleId="Delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />
                  )}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default UserList;
