import React from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction } from "../../../redux/actions/http";
import NumberFormat from "react-number-format";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
const StoreExpenseForm = () => {
  const modalType = "show_se_modal";
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.seReducer);
  const receiptProps = useSelector((state) => state.receiptReducer);
  const { CompanyAccount, Store } = receiptProps;


  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="se.addExpenseModal.store_message" />
    ),

    type: Yup.string().required(
      <IntlMessages id="se.addExpenseModal.type_message" />
    ),

    amount: Yup.string().required(
      <IntlMessages id="se.addExpenseModal.amount_message" />
    ),
    purpose: Yup.string().required(
      <IntlMessages id="se.addExpenseModal.purpose_message" />
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onCreate = (values) => {
    if (values.bank) {
      let selectedBank = CompanyAccount.filter(
        (Item) => Item._id === values.bank
      );
      values.balanceId = selectedBank[0].balanceId;
      values.bankName = selectedBank[0].bankName;
    }

    if (comProps.selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "addStoreExpense", values, "", "se", false)
      );
    } else {
      values._id = comProps.selectedItem._id;
      dispatch(
        DataRequestAction("PUT", "editStoreExpense", values, "", "se", false)
      );
    }
  };

  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message)
    notify("success", messageLng);
    reset();
    // message.success(alerts.record_updated_success);
    dispatch({
      type: "se_UPDATE",
    });
  }
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  return (
    <div>
      <CButton
        color="secondary"
        className="text-white"
        onClick={() => showModal(null)}
      >
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="se.add.se.button" />
      </CButton>

      <CModal visible={comProps.modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("se.addExpenseModal.addStoreExpense")}
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="store"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <>
                        <Select
                        styles={selectStyle}
                          className={errors.type ? "is-invalid" : ""}
                          isSearchable={true}
                          placeholder={intlPlaceholder(
                            "se.addExpenseModal.store_placeholder"
                          )}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={renderOptions([...Store], "label", "key")}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.store?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="type"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.type ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder(
                            "se.addExpenseModal.type_placeholder"
                          )}
                        </option>
                        <option value="construction">{intlPlaceholder("construction")}</option>
                        <option value="miscellaneous">{intlPlaceholder("miscellaneous")}</option>
                        <option value="office_Operating_Cost">
                         
                          {intlPlaceholder("office_Operating_Cost")}
                        </option>
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                  {intlPlaceholder(
                    "se.addExpenseModal.type_placeholder"
                  )}
                </CFormLabel>
                  <div className="invalid-feedback">{errors.type?.message}</div>
                </CFormFloating>
              </div>

              <div className="col-md-12">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.amount ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder(
                          "se.addExpenseModal.amount"
                        )}
                      />
                    )}
                    name={"amount"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amount?.message}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <CFormTextarea
                    rows="4"
                    className={errors.purpose ? "is-invalid" : ""}
                    {...register("purpose")}
                    placeholder={intlPlaceholder("se.addExpenseModal.purpose")}
                    type="purpose"
                    id="address"
                  />
                  <CFormLabel htmlFor="address">
                  {intlPlaceholder("se.addExpenseModal.purpose")}
                </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.purpose?.message}
                  </div>
                </CFormFloating>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="admin.addAdminModal.cancel" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.ceLoader}
              disabled={comProps.ceLoader}
            >
              {intlPlaceholder("ce.addExpenseModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default StoreExpenseForm;
