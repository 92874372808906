import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import QuickSaleForm from "./QuickSaleForm";
import IntlMessages from "../../../util/IntlMessages";
const AddQuickSale = () => {
  const dispatch = useDispatch();
  const fetchDistributeDropDown = () => {
    dispatch(DataGetAction("distributeDrop", ""));
  
  };
  useEffect(fetchDistributeDropDown, []);

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <h1>
                {" "}
                <IntlMessages id="bc.quickSale" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <QuickSaleForm />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AddQuickSale;
