const INIT_STATE = {
    Invoices: [],
    FirstLoadInvoiceList: [],
    CreatedByList: [],
    invoiceLoader: false,
    InvoiceDetail: null,
    companyDue: 0,
    storeDue: 0,
    storeSaleInvoiceSpinner: false,
    allStoreInvoicesSpinner: false,
    supplierInvoiceSpinner: false,
    storeInvoiceList: [],
    dueInvoices: [],
    refundSuccessfully: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case "refundClientInvoice_SUCCESS": {
            return {
                ...state,
                refundSuccessfully: true
            }
        }
        case 'Reset_refundClientInvoice': {
            return {
                ...state,
                refundSuccessfully: false
            }
        }
        case 'refundSupplierInvoice_SUCCESS': {
            return {
                ...state,
                refundSuccessfully: true
            }
        }
        case 'Reset_refundSupplierInvoice': {
            return {
                ...state,
                refundSuccessfully: false
            }
        }

        case 'Search_supplier_invoice_number': {
            const result = searchByInvoiceId(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                dueInvoices: [...result]
            }
        }
        case 'Search_invoice_supplier_Id': {
            const result = searchBySupplier(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                dueInvoices: [...result]
            }
        }
        case 'Search_supplier_Invoice_by_created_by': {
            const result = searchByCreatedBy(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                dueInvoices: [...result]
            }
        }

        case 'Search_store_invoice_number': {
            const result = searchByReceiptNumber(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                storeInvoiceList: [...result]
            }
        }
        case 'Search_storeInvoice_store_Id': {
            const result = searchByStore(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                storeInvoiceList: [...result]
            }
        }
        case 'Search_store_Invoice_by_created_by': {
            const result = searchByCreatedBy(action.payload, state.FirstLoadInvoiceList)
            return {
                ...state,
                storeInvoiceList: [...result]
            }
        }


        case 'getStoreDue_SUCCESS': {
            return { ...state, storeDue: action.payload.totalDueByStore }
        }
        case 'getCompanyDues_SUCCESS': {
            return { ...state, companyDue: action.payload.totalDueByCompany }
        }
        case 'getSupplerInvoiceDetail_SUCCESS': {
            return { ...state, invoiceLoader: false, InvoiceDetail: action.payload.Record && action.payload.Record[0] }
        }
        case 'getAllDues_SUCCESS': {
            const createdByList = getCreatedByList(action.payload.Record)
            return { ...state, CreatedByList: createdByList, FirstLoadInvoiceList: action.payload.Record, Invoices: action.payload.Record, dueInvoices: action.payload.Record }
        }
        case 'supplierInvoiceSpinner_START': {
            return { ...state, supplierInvoiceSpinner: true };
        }
        case 'supplierInvoiceSpinner_STOP': {
            return { ...state, supplierInvoiceSpinner: false };
        }
        case 'getAllInvoices_SUCCESS': {
            const createdByList = getCreatedByList(action.payload.Record)
            return { ...state, CreatedByList: createdByList, FirstLoadInvoiceList: action.payload.Record, Invoices: action.payload.Record, storeInvoiceList: action.payload.Record }
        }
        case 'storeSaleInvoiceSpinner_START': {
            return { ...state, storeSaleInvoiceSpinner: true };
        }
        case 'storeSaleInvoiceSpinner_STOP': {
            return { ...state, storeSaleInvoiceSpinner: false };
        }
        case 'getAllInvoices_ERROR': {
            return { ...state, Invoices: [] }
        }

        case 'fetchingInvoice_START': {
            return { ...state, invoiceLoader: true }
        }
        case 'fetchingInvoice_STOP': {
            return { ...state, invoiceLoader: false }
        }

        case 'getInVoiceDetail_SUCCESS': {
            return { ...state, invoiceLoader: false, InvoiceDetail: action.payload.Record && action.payload.Record[0] }
        }

        case 'allStoreInvoicesSpinner_START': {
            return { ...state, allStoreInvoicesSpinner: true };
        }
        case 'allStoreInvoicesSpinner_STOP': {
            return { ...state, allStoreInvoicesSpinner: false };
        }

        default: {
            return { ...state };
        }
    }
}


const searchByStore = (storeId, oldData) => {
    let result = []
    if (oldData) {
        result = oldData.filter(Item => Item.storeId === storeId);
    }
    return result
}
const searchBySupplier = (supplierId, oldData) => {
    let result = []
    if (oldData) {
        result = oldData.filter(Item => Item.supplierId === supplierId);
    }
    return result
}

const searchByCreatedBy = (createdBy, oldData) => {
    let result = []
    if (oldData) {
        result = oldData.filter(Item => Item.createdBy[0]._id === createdBy);
    }
    return result
}
const searchByReceiptNumber = (no, oldData) => {
    let result = []
    if (oldData) {

        result = oldData.filter(Item => {
            if (parseInt(Item.invoiceNumber) === parseInt(no)) {
                return Item
            }
        });
    }
    return result
}
const searchByInvoiceId = (no, oldData) => {
    let result = []
    if (oldData) {

        result = oldData.filter(Item => {
            if (Item.referenceNo === no) {
                return Item
            }
        });
    }
    return result
}

const getCreatedByList = (data) => {
    let cratedByList = [];
    if (data && data.length > 0) {
        let temp = data[0].createdBy[0]._id
        cratedByList.push(data[0].createdBy[0]);
        data.map(Item => {
            if (temp !== Item.createdBy[0]._id) {
                cratedByList.push(Item.createdBy[0]);
                temp = Item.createdBy[0]._id
            }

        })
    }

    return cratedByList;
}