import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";

import { useLocation } from "react-router-dom";
import InvoiceDetail from './InvoiceDetail'
const Index = () => {
   
    const dispatch = useDispatch();
    const location = useLocation();
    const invProps = useSelector(state => state.invoiceReducer);

    
    const fetchInvoiceInfo = () => {
        const { InvoiceNo, prefix } = location.state;
        dispatch(DataGetAction('getInVoiceDetail', { InvoiceNo, prefix }, 'fetchingInvoice'))
        dispatch(DataGetAction('getStoreDue', { InvoiceNo, prefix }, ))
    }

    useEffect(fetchInvoiceInfo, [])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            
            <CCardBody>
            {invProps.invoiceLoader ? <CSpinner/>: <InvoiceDetail />}
            </CCardBody>
          </CCard>
        </CCol>
        
      
      </CRow>
    </>
  );
};

export default Index;
