import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ChangeDateFormate,
  ChangeNumberToWord,
  ChangeNumberFormate,
} from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";

import CurrencyExchangeList from "../../components/CSSmartTable";
import {notify} from '../../util/ShowToast'
import AddSalary from "./components/addSalary";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [details, setDetails] = useState([]);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const history = useHistory();
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;
  const redirectToEmployeeBalanceSheet = (data) => {
    history.push({ pathname: "/employeeBalanceSheet", state: data });
  };

  const columns = [
    {
      label: <IntlMessages id="es.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.name" />,
      key: "name",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.source" />,
      key: "storeName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.monthlySalary" />,
      key: "monthlySalary",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.action" />,
      key: "show_details",
      sorter : false,
      filter : false,
      _style: { width: "40%" },
    },
  ];

  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.esReducer);
  const {
    Salary,
    salaryPostedSuccess,
    salaryPostedError,
    employeeSalarySpinner,
  } = comProps;
  const modalType = "show_es_modal";



  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Salary,
    });
  };

  const fetchBank = () => {
    dispatch(
      DataGetAction(
        "getEmployeeSalary",
        { query: "all" },
        "employeeSalarySpinner"
      )
    );
  };
  useEffect(fetchBank, []);

  if (salaryPostedSuccess) {
    fetchBank();
    setSelectedRows([]);
    let messageLng = intlPlaceholder(comProps.message)
    notify('success',messageLng);
    dispatch({
      type: "es_UPDATE",
    });
    dispatch({
      type: "show_es_modal",
      payload: [],
    });
  }

  if (salaryPostedError) {
    setSelectedRows([]);
    let messageLng = intlPlaceholder(comProps.message)
    notify('error',messageLng);
    dispatch({
      type: "es_UPDATE",
    });
  }

  const create = CheckUserAccess('salary',['create']) 
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="es.title" />
              </h1>
             
            </CCardHeader>
            <CCardBody>
            <div className="p-3 pb-0">
         {create&&  <CButton color="primary" className="d-flex align-items-center" onClick={() => showModal(null)}>
                  <i className="gx-icon-paper-send-2 me-2"></i>
                  <IntlMessages id="es.add.es.button" />
                </CButton>}
            </div>
              <DocsExample href="components/smart-table/">
                <CurrencyExchangeList
                  loading={employeeSalarySpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  
                  items={employeeSalarySpinner ? [] : Salary}
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },
                    storeName: (record) => {
                    
                      return <td style={{textTransform : 'capitalize'}}>{record.storeName === 'cashCaisse' ? <IntlMessages id={`${record.storeName}.bankName`} /> : record.storeName}</td>;
                    },
                    balance: (record) => {
                      return (
                        <td className="text-end">
                          <span>
                            { ChangeNumberFormate(
                              parseFloat(record.balance).toFixed(2)
                            )}
                          </span>
                        </td>
                      );
                    },
                    monthlySalary: (record) => {
                      return (
                        <td className="text-end">
                          <span>
                            {ChangeNumberFormate(
                              parseFloat(record.monthlySalary).toFixed(2)
                            )}
                          </span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() =>
                                redirectToEmployeeBalanceSheet(record)
                              }
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let totalExpense = 0;
                    pageData.forEach(({ monthlySalary }) => {
                      totalExpense += parseInt(monthlySalary);
                    });

                    return (
                      <>
                        <tr>
                          <th colSpan={2} className="gx-text-center">
                            <h5>{intlPlaceholder("TotalExpense")}</h5>
                          </th>
                          <th colSpan={3} className="text-end">
                            <>
                              <h5>
                                GNF{" "}
                                {ChangeNumberFormate(totalExpense.toFixed(2)) ||
                                  0}
                              </h5>
                            </>
                          </th>
                          <th></th>
                        </tr>
                      </>
                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <AddSalary 
      modalType={modalType}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default Index;
