import React from "react";


import App from './routes';

import { useRouteMatch } from "react-router-dom";
import DefaultLayout from './layout/DefaultLayout'

const MainApp = () => {

    const match = useRouteMatch();
    return (
        <DefaultLayout>
            <App match={match} />
        </DefaultLayout>
    )
};
export default MainApp;

