import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  supplierLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  Suppliers: [],
  supplierSpinner: false,
  editModalVisible: false,
  message: "",
  supplierBulkModalVisible: false,
  supplierBulkUpload_error: false,
  supplierBulkUpload_success: false,
  bulkASupplierSpinner: false,
  addSupplierDebitOrCreditSpin: false,
  addSupplierDebitOrCreditSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getAllSupplier_SUCCESS": {
      return {
        ...state,
        Suppliers: action.payload.Record,
        supplierSpinner: false,
      };
    }
    case "ResetAddSupplier": {
      return {
        ...state,
        addSupplierDebitOrCreditSuccess: false,
        addSupplierDebitOrCreditError: false,
      };
    }
    case "addSupplierDebitOrCredit_SUCCESS": {
      return {
        ...state,
        addSupplierDebitOrCreditSuccess: true,
        addSupplierDebitOrCreditSpin: false,
      };
    }
    case "addSupplierDebitOrCreditSpin_START": {
      return {
        ...state,
        addSupplierDebitOrCreditSpin: true,
      };
    }
    case "addSupplierDebitOrCreditSpin_FAILURE": {
      return {
        ...state,
        addSupplierDebitOrCreditSpin: false,
        addSupplierDebitOrCreditError: true,
      };
    }
    case "supplierSpinner_START": {
      return { ...state, supplierSpinner: true };
    }
    case "supplierSpinner_STOP": {
      return { ...state, supplierSpinner: false };
    }
    case "newSupplierActivatedInfo_SUCCESS": {
      const updateSupplierList = getSupplierList(
        action.payload,
        state.Suppliers
      );
      return { ...state, Suppliers: updateSupplierList };
    }

    case "show_supplier_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }
    case "show_supplier_add_modal": {
      return { ...state, modalVisible: !state.modalVisible };
    }

    case "edit_supplier_modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        selectedItem: action.payload,
      };
    }

    case "supplier_START": {
      return { ...state, supplierLoader: true };
    }
    case "supplier_STOP": {
      return { ...state, supplierLoader: false };
    }

    case "addSupplier_SUCCESS": {
      return {
        ...state,
        success: true,
        Suppliers: [action.payload.Record, ...state.Suppliers],
        supplierLoader: false,
        message: "recordAddedSuccessfully",
      };
    }
    case "addSupplier_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        supplierLoader: false,
        message: action.payload,
      };
    }

    case "editSupplier_SUCCESS": {
      let oldCat = state.Suppliers;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        Suppliers: newCat,
        supplierLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editSupplier_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        supplierLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "supplierBulkAccountSpinner_START": {
      return {
        ...state,
        bulkASupplierSpinner: true,
      };
    }

    case "bulk_import_supplier": {
      return {
        ...state,
        supplierBulkModalVisible: !state.supplierBulkModalVisible,
      };
    }
    case "bulkImportSupplierAccount_SUCCESS": {
      return {
        ...state,
        supplierBulkUpload_success: true,
        bulkASupplierSpinner: false,
        textMessage: action.payload.message,
      };
    }
    case "bulkImportSupplierAccount_FAILURE": {
      return {
        ...state,
        supplierBulkUpload_error: true,
        bulkASupplierSpinner: false,
        textMessage: action.payload,
      };
    }

    case "supplier_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        editModalVisible: false,
        resendError: false,
        resendSuccess: false,
        supplierLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        message: "",
        selectedItem: null,
        supplierBulkModalVisible: false,
        supplierBulkUpload_error: false,
        supplierBulkUpload_success: false,
        bulkASupplierSpinner: false,
      };
    }

    case "deleteSupplier_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Suppliers;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        Suppliers: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    default:
      return state;
  }

  function getSupplierList(latest, record) {
    const newArray = [];
    record.map((Item) => {
      if (Item._id === latest._id) {
        newArray.push(latest);
      } else {
        newArray.push(Item);
      }
      return Item;
    });

    return newArray;
  }
};
