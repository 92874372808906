import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Auth from "./Auth";
import Common from "./Common";
import Users from "./Users";
import Setting from "./Settings";
import Dashboard from "./dashboard";
import Company from "./Company";
import Supplier from "./Supplier";
import Employee from "./Employee";
import Admin from "./Admin";
import Customer from "./Customer";
import StoreReducer from "./Store";
import UnitReducer from "./Unit";
import BankReducer from "./Bank";
import AccountReducer from "./Account";
import CurrencyReducer from "./Currency";
import CurrencyExchange from "./CurrencyExchange";
import Filters from "./Filters";
import ProductReducer from "./Products";
import DistributeReducer from "./distribute";
import InvoiceReducer from "./Invoice";
import Receipt from "./Receipt";
import SaleStoreStock from "./SaleStoreStock";
import StoreToStoreReducer from "./StoreToStoreReducer";
import BalanceSheetReducer from "./BalanceSheet";
import ProfileReducer from "./ProfileReducer";
import CompanyExpense from "./CompanyExpense";
import StoreExpense from "./StoreExpense";
import EmployeeSalary from "./Salary";
import StoreEmployeeSalary from "./StoreSalary";
import TrustFund from "./TrustFund";
import Category from "./Category";
import Product from "./Product";
import HelperReducer from "./helper";
import quickSaleReducer from "./QuickSale";
import SaleInvoice from "./saleInvoice";
import delivered from "./delivered";
import grossProfit from "./grossProfit";
import Quotations from "./Quotations";
import PropertyReducer from "./Property";
import ContractReducer from "./Contract";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    Setting: Setting,
    Filter: Filters,
    auth: Auth,
    users: Users,
    commonR: Common,
    dashboard: Dashboard,
    company: Company,
    supplier: Supplier,
    employee: Employee,
    admin: Admin,
    customer: Customer,
    storeReducer: StoreReducer,
    unitReducer: UnitReducer,
    currencyReducer: CurrencyReducer,
    bankReducer: BankReducer,
    propertyReducer: PropertyReducer,
    contractReducer: ContractReducer,
    accountReducer: AccountReducer,
    productReducer: ProductReducer,
    distributeReducer: DistributeReducer,
    invoiceReducer: InvoiceReducer,
    receiptReducer: Receipt,
    saleStoreStock: SaleStoreStock,
    storeToStoreReducer: StoreToStoreReducer,
    balanceSheet: BalanceSheetReducer,
    profile: ProfileReducer,
    ceReducer: CompanyExpense,
    esReducer: EmployeeSalary,
    seReducer: StoreExpense,
    sesReducer: StoreEmployeeSalary,
    trustFund: TrustFund,
    currencyExchangeR: CurrencyExchange,
    categoryReducer: Category,
    ProductR: Product,
    helperReducer: HelperReducer,
    quickSaleReducer: quickSaleReducer,
    saleInvoiceReducer: SaleInvoice,
    deliveredR: delivered,
    grossProfit: grossProfit,
    quotationsR: Quotations,
  });
