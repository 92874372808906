import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import SupplierProductList from "./components/SupplierProductList";
import SupplierProductFilter from "./components/SupplierProductFilter";
import { useDispatch, useSelector } from "react-redux";
import { NumberWithCommas, ChangeDateFormate } from "src/constants/CommonFn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { cilFile } from "@coreui/icons";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";

import CheckUserAccess from "../../util/checkUserAccess";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {
  const dispatch = useDispatch();
  const [Filter, setFilter] = useState(null);
  const [stateSpinner, setSpinner] = useState(false);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;

  let bodyData = [
    [
      { text: intlPlaceholder("Product"), style: "tableHeader" },
      { text: intlPlaceholder("Category"), style: "tableHeader" },
      { text: intlPlaceholder("Quantity"), style: "tableHeader" },
      { text: intlPlaceholder("supplierProduct.total"), style: "tableHeader" },
    ],
  ];

  let totalLabel = intlPlaceholder("Total");
  let emailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  let headerTitleAccountSummery = intlPlaceholder("report.supplier.product");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  const { SupplierProduct } = useSelector((state) => state.balanceSheet);
  const downloadPDF = async (data) => {
    let Record = data;

    let startDate = moment().startOf("today").toString();
    let endDate = moment().toString();
    if (Filter && Filter.startDate && Filter.endDate) {
      startDate = Filter.startDate;
      endDate = Filter.endDate;
    }

    let TotalAmount = 0;
    let totalQuantity = 0;

    Record.forEach((Item, index) => {
      let amount = parseFloat(Item.amount).toFixed(2);
      let quantity = parseFloat(Item.qty).toFixed(3);

      TotalAmount = parseFloat(TotalAmount) + parseFloat(amount);
      totalQuantity = parseFloat(totalQuantity) + parseFloat(quantity);

      amount = NumberWithCommas(amount);
      let objArr = [];
      let productName = [
        {
          text: `${Item.productName}`,
          alignment: "left",
        },
      ];
      let prodCategory = [
        {
          text: Item.categoryName,
          alignment: "center",
        },
      ];
      objArr.push(productName);
      objArr.push(prodCategory);
      objArr.push(Item.qty.toFixed(3));
      objArr.push(amount);

      bodyData = [...bodyData, objArr];
    });

    TotalAmount = NumberWithCommas(TotalAmount.toFixed(2));
    totalQuantity = NumberWithCommas(totalQuantity.toFixed(3));

    let letTotal = [
      {
        text: totalLabel,
        style: "tableHeader",
        colSpan: 2,
        alignment: "center",
      },
      {},
      {text: totalQuantity, style: "tableBody", bold: true },
      { text: TotalAmount, style: "tableBody", bold: true },
    ];

    bodyData = [...bodyData, letTotal];

    let headerTitle = `${headerTitleAccountSummery} ${Filter.supplierName.toUpperCase()} ${reportFrom} ${ChangeDateFormate(
      startDate,
      "DD-MM-YYYY"
    )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `${emailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
    let docDefinition = {
      pageOrientation: "landscape",
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },

        {
          text: headerTitle,
          margin: [15, 10],
          style: { fontSize: 22, bold: true, alignment: "center" },
        },
        {
          style: "tableBody",
          headerRows: 1,

          table: {
            widths: ["*", 100, 80, 150],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
          alignment: "center",
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          alignment: "right",
        },
      },
    };
    setTimeout(function () {
      setSpinner(false);
      pdfMake.createPdf(docDefinition).download("supplierProduct.pdf");
    }, 1000);
  };

  const pdfAccess = CheckUserAccess("supplierOrderHistory", ["pdf"]);

  return (
    <>
      <CRow>
        <CCol>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="supplierProduct.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <SupplierProductFilter setFilter={setFilter} />
                {pdfAccess && (
                  <CLoadingButton
                    color="danger"
                    loading={stateSpinner}
                    disabled={
                      stateSpinner ||
                      (SupplierProduct && SupplierProduct.length === 0)
                    }
                    className="text-white"
                    onClick={() => {
                      downloadPDF(SupplierProduct);
                      setSpinner(true);
                    }}
                  >
                    <i className="gx-icon-pdf-300 me-1"></i>
                    <IntlMessages id="pdf.generate.button" />
                  </CLoadingButton>
                )}
              </div>
              <DocsExample href="components/smart-table/">
                <SupplierProductList />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
