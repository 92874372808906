const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const DataRequestAction = (method, type, data, redirectUrl, Loading = "LOADING", showNotification = true, cb, errorCb) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`);
    const token = localStorage.getItem("token");
    let requestStoreId = "";
    if (localStorage.getItem("selectedCompanyStore") && JSON.parse(localStorage.getItem("swithStoreToCompany"))) {
      const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
      if (obj && obj.value !== "null") {
        requestStoreId = obj.value;
      }
    }
    try {
      fetch(`${ROOT_URL}/${type}`, {
        method: method,
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: token,
          requestStoreId: requestStoreId,
        }),
        body: JSON.stringify(data),
      })
        .then((response) => {
          response.json().then((response) => {
            if (response.status === "ERROR") {
              RequestProgress(dispatch, `${Loading}_STOP`);
              RequestFail(dispatch, type, response.message, "error");

              if (showNotification) {
                addNotification(dispatch, response.message, "error");
              }

              errorCb && errorCb(response.message);
            } else {
              RequestSuccess(dispatch, type, response, data);
              if (showNotification) {
                addNotification(dispatch, response.message, "success", redirectUrl);
              }

              cb && cb(response.message);
            }
          });
        })
        .catch((err) => {
          RequestProgress(dispatch, `${Loading}_STOP`);
          RequestFail(dispatch, type, "", "error");

          if (showNotification) {
            addNotification(dispatch, err, "error");
          }
        });
    } catch (error) {
      RequestProgress(dispatch, `${Loading}_STOP`);
      RequestFail(dispatch, type, "", "error");

      if (showNotification) {
        addNotification(dispatch, error, "error");
      }
    }
  };
};

export const FormDataRequestAction = (type, data, imgList, redirectUrl, Loading = "LOADING", timeout = 300000) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`);
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      try {
        const dataObj = new FormData();
        if (data) dataObj.append("file", data);
        let requestStoreId = "";
        if (localStorage.getItem("selectedCompanyStore") && JSON.parse(localStorage.getItem("swithStoreToCompany"))) {
          const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
          if (obj && obj.value !== "null") {
            requestStoreId = obj.value;
          }
        }
        fetch(`${ROOT_URL}/${type}`, {
          method: "POST",
          headers: new Headers({
            Authorization: token,
            requestStoreId: requestStoreId,
          }),
          body: dataObj,
        })
          .then((response) => {
            response.json().then((response) => {
              if (response.status === "ERROR") {
                RequestProgress(dispatch, `${Loading}_STOP`);
                RequestFail(dispatch, type, response.message, "error");
                addNotification(dispatch, response.message, "error");
                reject();
              } else {
                RequestSuccess(dispatch, type, response, data);
                addNotification(dispatch, response.message, "success", redirectUrl);
                resolve();
              }
            });
          })
          .catch((err) => {
            RequestProgress(dispatch, `${Loading}_STOP`);
            RequestFail(dispatch, type, "", "error");

            addNotification(dispatch, err, "error");
            reject();
          });
      } catch (error) {
        RequestProgress(dispatch, `${Loading}_STOP`);
        RequestFail(dispatch, type, "", "error");

        addNotification(dispatch, error, "error");
        reject();
      }

      if (timeout) {
        const e = new Error("Connection timed out");
        setTimeout(reject, timeout, e);
      }
    });
  };
};
export const DataGetAction = (type, data, Loading = "Nothing", cb, errorCb) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`);
    const token = localStorage.getItem("token");
    let requestStoreId = "";
    if (localStorage.getItem("selectedCompanyStore") && JSON.parse(localStorage.getItem("swithStoreToCompany"))) {
      const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
      if (obj && obj.value !== "null") {
        requestStoreId = obj.value;
      }
    }
    let param = "";
    if (data) {
      param = `/${JSON.stringify(data)}`;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
      requestStoreId: requestStoreId,
    };

    try {
      fetch(`${ROOT_URL}/${type}${param}`, { headers })
        .then((response) => {
          if (response.status === 401) {
            RequestUnAuthorized(dispatch, `showUnauthorized`);
          } else {
            response
              .json()
              .then((response) => {
                RequestProgress(dispatch, `${Loading}_STOP`);
                RequestSuccess(dispatch, type, response, data);

                cb && cb(response);
              })
              .catch((err) => {
                RequestProgress(dispatch, `${Loading}_STOP`);
                RequestFail(dispatch, type, "Request Fail outer", err);

                errorCb && errorCb("Request Fail outer");
              });
          }
        })
        .catch((err) => {
          RequestProgress(dispatch, `${Loading}_STOP`);
          RequestFail(dispatch, type, "Request Fail outer", err);

          errorCb && errorCb("Request Fail outer");
        });
    } catch (error) {
      RequestProgress(dispatch, `${Loading}_STOP`);
      RequestFail(dispatch, type, "Request Fail", error);

      errorCb && errorCb("Request Fail");
    }
  };
};

function RequestUnAuthorized(dispatch, type) {
  dispatch({
    type: `UN_AUTHORIZED`,
  });
}
export const updateAllState = (type) => {
  return function (dispatch) {
    dispatch({
      type: `${type}_UPDATE`,
    });
  };
};

export const DataSelectUser = (type, Item) => {
  return function (dispatch) {
    dispatch({
      type: `${type}_SUCCESS`,
      payload: Item,
    });
  };
};

export const restNotificationState = () => {
  return function (dispatch) {
    dispatch({
      type: `REST_NOTIFICATION`,
    });
  };
};

function addNotification(dispatch, message, level, redirectUrl = "") {
  dispatch({
    type: "SHOW_NOTIFICATION",
    message,
    level,
    redirectUrl,
  });
}

function RequestFail(dispatch, type, message, error) {
  dispatch({
    type: `${type}_FAILURE`,
    payload: message,
    error,
  });
}

function RequestSuccess(dispatch, type, data, reqData) {
  dispatch({
    type: `${type}_SUCCESS`,
    payload: data,
    reqData,
  });
}

function RequestProgress(dispatch, type) {
  dispatch({
    type: `${type}`,
  });
}

export const SocketResponseState = (type, payload) => {
  return function (dispatch) {
    dispatch({
      type: `${type}_SUCCESS`,
      payload: payload,
    });
  };
};
