import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import moment from "moment";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { selectStyle } from "../../../constants/CommonFn";
import { useHistory } from "react-router-dom";
const DepositForm = ({ findBankByCurrencyId, type, title }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const comProps = useSelector((state) => state.trustFund);
  const currencyProps = useSelector((state) => state.currencyReducer);
  const history = useHistory()
  const { trustLoader, depositModalVisible, CompanyBankList, Employee, latestDepositRecord } =
    comProps;
  const { Currencies } = currencyProps;
  const modalType = "Deposit_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    trusty: Yup.string().required(<IntlMessages id="trust.nameRequired" />),
    currency: Yup.string().required(
      <IntlMessages id="trust.currencyRequired" />
    ),
    bank: Yup.string().required(<IntlMessages id="trust.accountRequired" />),
    amount: Yup.string().required(<IntlMessages id="trust.amountRequired" />),
    depositDate: Yup.string().required(<IntlMessages id="dateRequired" />),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, watch } =
    useForm(formOptions);
  const { errors } = formState;

  const findTheBackByCurrencyDefault = () => {
    if (Currencies && Currencies.length > 0 && depositModalVisible) {
      findTheBackByCurrency(Currencies[0]._id);

      reset({
        currency: Currencies && Currencies.length > 0 && Currencies[0]._id,
        depositDate: new Date().toISOString(),
      });
    }
  };
  useEffect(findTheBackByCurrencyDefault, [Currencies, depositModalVisible]);

  const onFinish = (values) => {
    let selectedBank = CompanyBankList.filter(
      (Item) => Item._id === values.bank
    );


    if (selectedBank && selectedBank.length > 0) {
      values.paymentMode = selectedBank[0].paymentMode;
      values.accountNumber = selectedBank[0].number;
      if (values.paymentMode === 'bank') {
        values.bankId = selectedBank[0].bankId;
      }

    }

    dispatch(
      DataRequestAction("POST", "depositAmount", values, "", "trust", false)
    );
  };

  const fetchData = () => {
    dispatch(DataGetAction("balanceSheetForTrustUser", {
      query: 'deposit', startDate: moment(),
      endDate: moment(),
    }, "fetchingBalanceSheetForTrustUser"));
  }

  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("success", messageLng);
    reset();
    setAmount(0);
    let id = latestDepositRecord.referenceId
    if(latestDepositRecord.paymentMode === 'cash'){
      id = latestDepositRecord.reference
    }
    history.push("/viewTrustDetail", { _id: id });
    dispatch({
      type: "trust_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("error", messageLng);
    dispatch({
      type: "trust_UPDATE",
    });
  }

  const currencyChangeWatch = watch("currency");

  const findTheBackByCurrency = (e) => {
    findBankByCurrencyId(e);
  };

  useEffect(() => {
    findBankByCurrencyId(currencyChangeWatch);
  }, [currencyChangeWatch]);

  const renderOptions = (Employee) => {
    if (Employee && Employee.length > 0) {
      let result = [];
      Employee.map((data) => {
        if (data.role === "trusty") {
          result.push({
            label: `${data.firstName} ${data.lastName ? data.lastName : ""} ${data.empId
              }`,
            value: data._id,
          });
        }
      });
      return result;
    } else {
      return [];
    }
  };
  const renderCurrency = (Currency) => {
    if (Currency && Currency.length > 0) {
      return Currency.map((data) => ({
        label: `${data.name}  (${data.symbol})`,
        value: data._id,
      }));
    } else {
      return [];
    }
  };
  const renderCompanyBankList = (CompanyBankList) => {
    if (CompanyBankList && CompanyBankList.length > 0) {
      return CompanyBankList.map((data) => {
        if (data.bank === 'Cash') {
          return {
            label: `${intlPlaceholder("cashCaisse.bankName")}  (${data.currencySymbol})`,
            value: data._id,
          };
        } else {
          return {
            label: `${data.bank}  (${data.currencySymbol})`,
            value: data._id,
          };
        }

      });
    } else {
      return [];
    }
  };
  const renderDefaultValue = () => {
    let name = Currencies && Currencies.length > 0 && Currencies[0].name;
    let symbol = Currencies && Currencies.length > 0 && Currencies[0].symbol;
    let value = `${name} (${symbol})`;

    return value;
  };


  return (
    <div>
      <CModal visible={depositModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("DepositModalTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="mb-3">
              <Controller
                control={control}
                name="trusty"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      styles={selectStyle}
                      className={errors.trusty ? "is-invalid" : ""}
                      isSearchable={true}
                      placeholder={intlPlaceholder("selectClient")}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      options={renderOptions(Employee)}
                    />
                  </>
                )}
              />

              <div className="invalid-feedback">{errors.trusty?.message}</div>
            </div>

            <div className="mb-3">
              <Controller
                control={control}
                name="currency"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      styles={selectStyle}
                      className={errors.currency ? "is-invalid" : ""}
                      isSearchable={true}
                      defaultValue={{
                        value:
                          Currencies &&
                          Currencies.length > 0 &&
                          Currencies[0]._id,
                        label: renderDefaultValue(),
                      }}
                      placeholder={intlPlaceholder(
                        "account.createAccountModal.currency_placeholder"
                      )}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      options={renderCurrency(Currencies)}
                    />
                  </>
                )}
              />

              <div className="invalid-feedback">{errors.currency?.message}</div>
            </div>

            <div className="mb-3">
              <Controller
                control={control}
                name="bank"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      styles={selectStyle}
                      className={errors.bank ? "is-invalid" : ""}
                      isSearchable={true}
                      placeholder={intlPlaceholder("selectAccount")}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      options={renderCompanyBankList(CompanyBankList)}
                    />
                  </>
                )}
              />

              <div className="invalid-feedback">{errors.bank?.message}</div>
            </div>

            <div className="mb-3 number-format-input">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <NumberFormat
                    className={errors.amount ? "is-invalid" : ""}
                    allowNegative={false}
                    thousandSeparator={true}
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    placeholder={intlPlaceholder("amount")}
                  />
                )}
                name={"amount"}
                control={control}
              />

              <div className="invalid-feedback">{errors.amount?.message}</div>

              <div className="mb-3 mt-3">
                <Controller
                  control={control}
                  name="depositDate"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      value={moment(value).format("YYYY-MM-DD")}
                      maxDate={new Date()}
                      selected={selectedDate}
                      format="dd/mm/yyyy"
                      onChange={(date) => {
                        setSelectedDate(date);
                        onChange(date.toISOString());
                      }}
                      wrapperClassName="custom-date-picker"
                    />
                  )}
                />
              </div>
            </div>

            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.remark ? "is-invalid" : ""}
                  {...register("remark")}
                  placeholder={intlPlaceholder("Remark")}
                  type="text"
                  id="remark"
                />
                <CFormLabel htmlFor="remark">
                  {intlPlaceholder("Remark")}
                </CFormLabel>
                <div className="invalid-feedback">{"Remark"}</div>
              </CFormFloating>
            </div>
          </CModalBody>

          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              {intlPlaceholder("CancelButtonTitle")}
            </CButton>

            <CLoadingButton type="submit" color="primary" loading={trustLoader} disabled={trustLoader}>
              {intlPlaceholder("SaveButtonTitle")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default DepositForm;
