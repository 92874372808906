import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const InvoiceCompanyStoreProductGrid = ({ setDisableSaveBtn }) => {
  const { localProducts, ProductList } = useSelector((state) => state.ProductR);
  const dispatch = useDispatch();

  let priceShouldBeNumeric = intlPlaceholder("priceShouldBeNumeric");
  let priceShouldBeBiggerThan0 = intlPlaceholder("priceShouldBeBiggerThan0");
  let quantityShouldBeNumeric = intlPlaceholder("quantityShouldBeNumeric");
  let quantityShouldBeBiggerThan0 = intlPlaceholder(
    "quantityShouldBeBiggerThan0"
  );

  const deleteRecord = (record) => {
    dispatch({
      type: "deleteLocalProduct",
      payload: record,
    });
  };

  let columns = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell, record) => {
        return <span>{cell || record.productName}</span>;
      },
    },
    {
      dataField: "unitName",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell, record) => {
        const index = _.findIndex(ProductList, { unit: record.unit });
        if (index > -1) {
          return <span>{ProductList[index].unitName}</span>;
        }

        return <span>{cell}</span>;
      },
    },
    {
      dataField: "quantity",
      text: <IntlMessages id="product.quantity" />,
      // editable: (cell, record) => {
      //   return !!+record.stockQuantity;
      // },
      editable: true,
      formatter: (cell, record) => {
        return <span>{parseFloat(cell || record.quantity)}</span>;
      },
      validator: (newValue, row, column) => {
        let qty = +newValue;
        let stockQty = +row.stockQuantity;
        let maxChangeStockQuantity = +row.maxChangeStockQuantity;

        if (isNaN(qty)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: quantityShouldBeNumeric,
          };
        }
        if (qty <= 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: quantityShouldBeBiggerThan0,
          };
        }
        if (qty > maxChangeStockQuantity) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        } else {
          setDisableSaveBtn(false);
          return true;
        }
      },
      classes: (cell, record) => {
        return "selectable-cell";
      },
    },
    {
      dataField: "maxChangeStockQuantity",
      editable: false,
      text: <IntlMessages id="product.stockQuantityAllow" />,
      formatter: (cell, record) => {
        return <span>{parseFloat(cell).toFixed(3)}</span>;
      },
    },
    // {
    //   dataField: "stockQuantity",
    //   text: <IntlMessages id="product.sockQty" />,
    //   editable: false,
    //   formatter: (cell) => {
    //     return <span>{parseFloat(cell)}</span>;
    //   },

    // },
    {
      dataField: "localPrice",
      text: <IntlMessages id="product.UnitPrice" />,
      editable: true,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue <= 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeBiggerThan0,
          };
        } else {
          setDisableSaveBtn(false);
          return {
            valid: true,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "totalPrice",
      text: <IntlMessages id="product.TotalPrice" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <span>
            {" "}
            {ChangeNumberFormate(parseFloat(cell || record.total).toFixed(2))}
          </span>
        );
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },
    },
  ];

  const renderTotalPrice = () => {
    let total = 0;

    localProducts &&
      localProducts.length > 0 &&
      localProducts.forEach((item) => {
        total = item.totalPrice + total;
      });
    total = ChangeNumberFormate(total.toFixed(2));
    return total;
  };

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        key={1}
        data={localProducts}
        columns={columns}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField;
            dispatch({
              type: "updateLocalProductStoreInvoice",
              payload: {
                fieldChanged: changedField,
                updatedRow: row,
                newValue: newValue,
              },
            });
          },
        })}
      />

      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tr>
          <td colSpan="5" style={{ textAlign: "center" }}>
            <h5>Total</h5>
          </td>

          <td colSpan="2" style={{ textAlign: "left" }}>
            <h5>{renderTotalPrice()}</h5>
          </td>
        </tr>
      </table>
    </>
  );
};

export default InvoiceCompanyStoreProductGrid;
