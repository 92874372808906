import React, { useState } from 'react'
import { CButton, CCardBody, CCollapse  } from '@coreui/react-pro'
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditModal from './EditModal'
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import AccessControl from '../../../components/AccessControl'
import CheckUserAccess from '../../../util/checkUserAccess'
const SmartTableBasicExample = ({ data, spinner }) => {
    const [details, setDetails] = useState([])
    const history = useHistory();
    const dispatch = useDispatch();

    const columns = [
        {
            label: <IntlMessages id="product.supplier" />,
            key: 'supplier',
            _style: { width: '40%' },
            columnSorter: false,
        },
        {
            label: <IntlMessages id="product.name" />,
            key: 'productName',
            _style: { width: '40%' },
        },
        {
            label: <IntlMessages id="stock.quantity" />,
            key: 'quantity',
            columnSorter: false,
            sorter: false,
        },
        {
            label: <IntlMessages id="product.price" />,
            key: 'price',
            columnSorter: false,
            _style: { width: '50%' },
            sorter: false,

        },
        {
            label: <IntlMessages id="product.currency" />,
            key: 'currencySymbol',
            columnSorter: false,
        },
        {
            label: <IntlMessages id="product.localPrice" />,
            key: 'localPrice',
            columnSorter: false,
            sorter: false,
        },      
        {
            label: <IntlMessages id="product.total" />,
            key: 'totalLocalPrice',
            columnSorter: false,
            sorter: false,
        },
        {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            filter: false,
            sorter: false,
        },
    ]


    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    const editRecord = (data) => {
        dispatch({
            type: 'Edit_Stock_Modal',
            payload: data
        })
    }
    const edit = CheckUserAccess('stockEntry', ['edit'])
    return (
        <>
            <CSmartTable
                loading={spinner}
                sorterValue={{ column: 'name', state: 'asc' }}
                clickableRows
                tableProps={{
                    striped: true,
                    hover: true,
                }}
                activePage={3}

                items={spinner ? [] : data}
                columns={columns}
                columnFilter
                tableFilter
                cleaner
                itemsPerPageSelect
                itemsPerPage={100}
                columnSorter
                pagination
                scopedColumns={{

                    price: (record) => {
                        return <td className="text-end"><span>{record.currencyType !== "local" ? ChangeNumberFormate(parseFloat(record.price).toFixed(2)) : "---"}</span></td>

                    },
                    localPrice: (record) => {
                        return <td className="text-end"><span>{ChangeNumberFormate(parseFloat(record.localPrice).toFixed(2))}</span></td>
                    },
                    quantity: (record) => {
                        return <td className="text-end"><span>{parseFloat(record.quantity).toFixed(3)}</span></td>
                    },
                    totalLocalPrice: (record) => {
                        return <td className="text-end"><span>{ChangeNumberFormate(parseFloat(record.totalLocalPrice).toFixed(2))}</span></td>
                    },
                    show_details: (item) => {
                        return (
                            <td className="py-2">
                                <CButton
                                    color="primary"
                                    variant="outline"
                                    shape="square"
                                    size="sm"
                                    onClick={() => {
                                        toggleDetails(item._id)
                                    }}
                                >
                                    {details.includes(item._id) ?  <IntlMessages id="hide" /> :   <IntlMessages id="show" />}
                                </CButton>
                            </td>
                        )
                    },
                    details: (record) => {
                        return <CCollapse visible={details.includes(record._id)}>
                            <CCardBody>


                                {edit && <CButton className="me-3 d-flex align-items-center" size="sm" color="warning" onClick={() => editRecord(record)}>
                                    <i className="icon icon-edit text-white me-1"></i>
                                    <span style={{ color: 'white' }}>
                                        <IntlMessages id="Edit" /></span>
                                </CButton>}


                            </CCardBody>
                        </CCollapse>

                    },
                }}
            />

            <EditModal modalType="Edit_Stock_Modal" title="Edit Stock" />
        </>
    )
}

export default SmartTableBasicExample
