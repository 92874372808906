import React , {useState} from "react";
import UnitList from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";

import { useDispatch,useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import {

    CCardBody,
    CButton,
    CCollapse,
  } from "@coreui/react-pro";

const DistributeProductList = ({ showAction = true,setValue,reset }) => {
    const [details, setDetails] = useState([]);
    const toggleDetails = (index) => {
      const position = details.indexOf(index);
      let newDetails = details.slice();
      if (position !== -1) {
        newDetails.splice(position, 1);
      } else {
        newDetails = [...details, index];
      }
      setDetails(newDetails);
    };
  const prodProps = useSelector((state) => state.distributeReducer);
  const dispatch = useDispatch();

  const deleteRecord = (record) => {
    // form.setFieldsValue({ product: undefined, unit: undefined });
    reset()
    // setValue('unit',undefined)
    dispatch({
      type: "deleteProductLocal_SUCCESS",
      payload: record,
    });
  };
  const { ProductsLocal } = prodProps;
  const columns = [
    {
      label: <IntlMessages id="product.name" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="product.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="product.quantity" />,
      key: "quantity",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="product.UnitPrice" />,
      key: "UnitPrice",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="product.TotalPrice" />,
      key: "TotalPrice",
      _style: { width: "40%" },
    },
  ];

  if (showAction) {
    columns.push({
      label: <IntlMessages id="product.action" />,
      key: "show_details",
      _style: { width: "40%" },
    });
  }
  return (
    <UnitList
      sorterValue={{ column: "name", state: "asc" }}
      clickableRows
      tableProps={{
        striped: true,
        hover: true,
      }}
      activePage={3}
      
      items={ProductsLocal}
      columns={columns}

      itemsPerPageSelect
      itemsPerPage={100}
      columnSorter
      pagination
      scopedColumns={{
        quantity: (record) => {
          return (
            <td>
              <span>{parseFloat(record.Qty).toFixed(3)}</span>
            </td>
          );
        },
        UnitPrice: (record) => {
          return (
            <td>
              <span>
                {ChangeNumberFormate(parseFloat(record.Price).toFixed(2))}
              </span>
            </td>
          );
        },
        TotalPrice: (record) => {
          return (
            <td>
              <span>
                {ChangeNumberFormate(
                  (parseFloat(record.Qty) * parseFloat(record.Price)).toFixed(2)
                )}
              </span>
            </td>
          );
        },

        show_details: (record) => {
          return (
            <td className="py-2">
              <CButton
                color="primary"
                variant="outline"
                shape="square"
                size="sm"
                onClick={() => {
                  toggleDetails(record._id);
                }}
              >
                 {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
              </CButton>
            </td>
          );
        },
        details: (record) => {
          return (
            <CCollapse visible={details.includes(record._id)}>
              <CCardBody>
                <CButton
                  size="sm"
                  color="danger"
                  className="me-3"
                  onClick={() => deleteRecord(record)}
                >
                  <span style={{ color: "white" }}>
                    <span className="icon icon-trash me-2"></span>
                    <IntlMessages id="delete" />
                  </span>
                </CButton>
              </CCardBody>
            </CCollapse>
          );
        },
      }}
      summary={(pageData) => {
        let totalPayment = 0;

        pageData.forEach(({ Qty, Price }) => {
          const totalPrice = parseFloat(Qty) * parseFloat(Price);
          totalPayment += totalPrice;
        });

        return (
          <tr>
            <th colSpan={4} className="gx-text-center gx-font-weight-bold">
              <IntlMessages id="product.Total" />
            </th>
            <th colSpan={2}>
              <span>{ChangeNumberFormate(totalPayment.toFixed(2))}</span>
            </th>
          </tr>
        );
      }}
    />
  );
};

export default DistributeProductList;
