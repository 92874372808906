import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
} from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import DocsExample from "src/components/DocsExample";
import IntlMessages from "src/util/IntlMessages";
import QuotationsList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "src/redux/actions/http";
import moment from "moment";
import QuotationsModal from "./components/QuotationsModal";
import CheckUserAccess from "src/util/checkUserAccess";

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quotationsProps = useSelector((state) => state.quotationsR);
  const { getQuotationsSpin, quotationsList } = quotationsProps;
  const [details, setDetails] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment().toString(),
    endDate: moment().toString(),
  });
  const create = CheckUserAccess("quotations", ["create"]);
  const edit = CheckUserAccess("quotations", ["edit"]);
  
  const renderAction = (record) => {


    
    return (
      <>
        <CButton
          size="sm"
          color="warning"
          className="me-3"
          onClick={() =>
            dispatch({ type: "Show_quotation_View_Modal", payload: record })
          }
        >
          <span style={{ color: "white" }}>
            <span className="icon icon-custom-view me-2"></span>
            <IntlMessages id="view" />
          </span>
        </CButton>
        {edit && <CButton
          onClick={() =>
            history.push({
              pathname: "editQuotations",
              state: { _id: record._id },
            })
          }
          size="sm"
          color="warning"
        >
          <i className="icon icon-edit text-white me-1"></i>
          <span style={{ color: "white" }}>
            <IntlMessages id="Edit" />
          </span>
        </CButton>}
      </>
    );
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  useEffect(() => {
    dispatch(
      DataGetAction(
        "getAllQuotation",
        {
          query: "all",
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        },
        "getQuotationsSpin"
      )
    );
  }, []);
  let columns = [
    {
      label: <IntlMessages id="quotations.prefix" />,
      key: "quotationNumber",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quotations.store" />,
      key: "storeName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quotations.amount" />,
      key: "totalPrice",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quotations.createdBy" />,
      key: "createdAt",
      _style: { width: "40%" },
    },

    {
      label: "",
      sorter: false,
      filter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="quotations" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter setFilter={setDateFilter} />
                {create && <div className="d-flex align-items-center">
                  <CButton
                    onClick={() => history.push("/addQuotations")}
                    color="success"
                    className="me-3 text-white"
                  >
                    <i className="icon icon-add me-1"></i>
                    <IntlMessages id="addQuotations" />
                  </CButton>
                </div>}
              </div>
              <DocsExample href="components/smart-table/">
                <QuotationsList
                  loading={getQuotationsSpin}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={getQuotationsSpin ? [] : quotationsList}
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>
                            {ChangeDateFormate(record.createdAt, "YYYY-MM-DD")}
                          </span>
                        </td>
                      );
                    },
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? (
                              <IntlMessages id="hide" />
                            ) : (
                              <IntlMessages id="show" />
                            )}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let total = 0;
                    pageData.forEach(({ totalPrice }) => {
                      total += totalPrice;
                    });
                    return (
                      <tr>
                        <th colSpan={1} className="text-center">
                          <h5>
                            <IntlMessages id="Total" />
                          </h5>
                        </th>
                        <th colSpan={1} className="text-end">
                          <span>
                            <h5>{ChangeNumberFormate(total.toFixed(2))}</h5>
                          </span>
                        </th>
                        <th colSpan={5}>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <QuotationsModal />
    </>
  );
};

export default Index;
