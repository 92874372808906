import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";

import { useLocation } from "react-router-dom";
import InvoiceReceiptDetail from './components/InvoiceReceiptDetail'
const Index = () => {
   
  const dispatch = useDispatch();
  const location = useLocation();
  const invProps = useSelector(state => state.storeToStoreReducer);

  const fetchInvoiceInfo = () => {
      const { InvoiceNo, prefix } = location.state;
      dispatch(DataGetAction('getStoreInVoiceDetail', { InvoiceNo, prefix, invoiceType:"StoreToStoreRecipt" }, 'fetchingInvoice'))
      dispatch(DataGetAction('getStoreDue', { InvoiceNo, prefix, invoiceType:"StoreToStoreRecipt" }, ))
  }

  useEffect(fetchInvoiceInfo, [])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            
            <CCardBody>

            {invProps.invoiceLoader ?<CSpinner/>: <InvoiceReceiptDetail />}


            </CCardBody>
          </CCard>
        </CCol>
        
      
      </CRow>
    </>
  );
};

export default Index;
