import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {CFormLabel, CFormInput} from '@coreui/react-pro'
const CSmartTableFilter = forwardRef(
    ({ filterLabel, filterPlaceholder, value, ...rest }, ref) => {
        return (
            <div className="row mb-2">
                <CFormLabel className="col-sm-auto col-form-label">{filterLabel}</CFormLabel>
                <div className="col-sm-auto">
                    <CFormInput placeholder={filterPlaceholder} value={value} {...rest} ref={ref} />
                </div>
            </div>
        )
    },
)


CSmartTableFilter.propTypes = {
    filterLabel: PropTypes.string,
    filterPlaceholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

export default CSmartTableFilter
