import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { DataGetAction } from "../../../redux/actions/http";
import CategoryForm from "./AccountForm";
const AddModal = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.accountReducer);

  const { categoryLoader, selectedItem, modalCreditVisible, success,modalVisible } = comProps;

  const modalType = "show_account_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const FetchBankInfo = () => {
    if (modalVisible) {
      dispatch(DataGetAction("getAccountDropDowns", ""));
    }
  };

  useEffect(FetchBankInfo, [modalVisible]);

  return (
    <div>
    

      <CModal visible={modalCreditVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("category.addCategoryModal.addCategory")}
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CategoryForm
            defaultValues={null}
            ButtonComponent={
              <CModalFooter>
                <CButton
                  color="secondary"
                  onClick={() =>
                    dispatch(DataEntryModalAction(modalType, null))
                  }
                >
                  <IntlMessages id="admin.addAdminModal.cancel" />
                </CButton>

                <CLoadingButton
                  type="submit"
                  color="primary"
                  loading={categoryLoader}
                  disabled={categoryLoader}
                >
                  {intlPlaceholder("category.addCategoryModal.save")}
                </CLoadingButton>
              </CModalFooter>
            }
          />
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddModal;
