import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CLoadingButton,
  CModalFooter
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../../redux/actions/Common";
import { FormDataRequestAction } from "../../../../redux/actions/http";
import bulkIcon from "../../../../assets/icons/importIcon.svg"
import sampleTrustFundCsv from "../../../../assets/trustFundSample.csv"
const BulkImportUser = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.trustFund);
  const [bulkFile, setBulkFile] = useState(null)
  const [showError, setShowError] = useState(false)
  const { bulkModalVisible,bulkUpload_success ,bulkUpload_error} = comProps;

  const modalType = "bulk_import_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const saveBulkFiles = () => {
    if(bulkFile){
      dispatch(
        FormDataRequestAction("bulkImportTrustUser", bulkFile, "", "", "trust")
      );
    }
    else{
      setShowError(true)
    }
   

  }

  const onFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setBulkFile(files[0])
    setShowError(false)

  };



  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        {/* <i className="icon icon-add me-1"></i> */}
        <div className="d-flex align-items-center">
          <img src={bulkIcon} style={{width : '18px', height : '18px', color : 'white',marginRight : '5px'}}/>
          <IntlMessages id="trustUser.bulkImport" />
        </div>
      </CButton>

      <CModal visible={bulkModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("trustUser.bulkImport")}
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <div className="d-flex justify-content-between">
            <input type="file" id="multi" onChange={onFileChange} multiple />
            <a href={sampleTrustFundCsv} target="_blank">
             
              <IntlMessages id="sample" />
              </a>
          </div>
         {showError && <span style={{color : 'red', fontSize : '12px'}}>
          
           <IntlMessages id="csvFileRequired" />
           </span>}

        </CModalBody>
        <CModalFooter>
          <CLoadingButton type="submit" color="primary" loading={comProps.trustLoader} disabled={comProps.trustLoader} onClick={() => { saveBulkFiles() }}>
            {intlPlaceholder("trust.addUserModal.save")}

          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default BulkImportUser;

