import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import DistributeProductList from "./LocalProductList";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CForm,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
const DistributeForm = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [defaultPriceValue, setDefaultPriceValue] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [selectedSource, setSelectedSource] = useState();

  const dispatch = useDispatch();
  const disProps = useSelector((state) => state.distributeReducer);
  const stsProps = useSelector((state) => state.storeToStoreReducer);

  const {
    ResourceStore,
    DestinationStore,
    Products,
    ProductsLocal,
    units,
    Price,
    Qty,
    selectedUnit,
    getStoretostore,
    localProducts,
    fetchProductsStoreStockSpinner,
  } = stsProps;
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    resourceStore: Yup.string().required(
      <IntlMessages id="storeToStoreInvoice.createInvoicePage.sourceStore_message" />
    ),
    destinationStore: Yup.string().required(
      <IntlMessages id="storeToStoreInvoice.createInvoicePage.destinationStore_message" />
    ),
    products: Yup.array()
      .min(1, "select at least one product")
      .required(
        <IntlMessages id="storeToStoreInvoice.createInvoicePage.products_message" />
      ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

  const changeUnitPrice = () => {
    setDefaultPriceValue(Price);
    if (Price) {
      setValue("Price", Price, { isDirty: false });
      setValue("premiumPrice", Price, { isDirty: false });
    } else {
      setValue("Price", undefined, { isDirty: false });
      setValue("premiumPrice", undefined, { isDirty: false });
    }
  };
  useEffect(changeUnitPrice, [Price]);

  const resetAll = () => {
    dispatch({
      type: "resetAll",
    });
    dispatch({
      type: "STORE_TO_STORE_PRODUCTS",
    });
  };
  const loadFun = () => {
    return () => {
      setSupplierData([]);
      setSelectedSource();
      resetAll();
    };
  };
  useEffect(loadFun, []);

  const onFinish = () => {
    const resourceStore = getValues("resourceStore");
    const destinationStore = getValues("destinationStore");

    let values = {};
    if (
      localProducts &&
      localProducts.length > 0 &&
      resourceStore &&
      destinationStore
    ) {
      let updatedResult = localProducts.map((item) => {
        return {
          Price: parseFloat(item.price),
          Qty: item.qty,
          destinationStore: destinationStore,
          premiumPrice: parseFloat(item.localPrice),
          productId: item.productId,
          productName: item.productName,
          resourceStore: resourceStore,
          //stockId: item.stockId,
          unit: item.unitId,
          unitName: item.unitName,
          units: item.unitId,
        };
      });

      values.products = [...updatedResult];
      values.destinationStore = destinationStore;
      values.resourceStore = resourceStore;
      // if (values.premiumPrice === "") {
      //   values.premiumPrice = 0;
      // }

      dispatch(
        DataRequestAction(
          "POST",
          "storeToStoreSale",
          values,
          "",
          "fetchingStoreToStoreSale",
          false
        )
      );
    } else {
      notify("error", intlPlaceholder("productNotSelected"));
    }
  };

  if (stsProps.invoiceCreatedSuccess) {
    notify("success", intlPlaceholder("invoiceCreatedSuccessfully"));
    history.push({
      pathname: "/storeInvoiceDetail",
      state: {
        InvoiceNo: stsProps.invoiceNumber,
        invoiceType: "StoreToStore",
        prefix: "FS",
      },
    });
    dispatch({
      type: "update_StoreToStoreState",
    });
  }
  if (stsProps.invoiceCreatedError) {
    notify("error", intlPlaceholder("oopsSomethingWentWrong"));
    // history.push({
    //   pathname: "/storeInvoiceDetail",
    //   state: {
    //     InvoiceNo: stsProps.invoiceNumber,
    //     invoiceType: stsProps.invoiceType,
    //   },
    // });
    dispatch({
      type: "update_StoreToStoreState",
    });
  }

  const fetchStore = () => {
    dispatch(DataGetAction("getAllStore", { query: "all" }));
  };

  useEffect(fetchStore, []);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setStoreToStoreProducts",
      payload: values,
    });
  };

  const fetchProductBySupplierId = (e) => {
    // form.resetFields(["products", "units"]);
    setValue("products", "");
    setValue("units", "");
    dispatch(
      DataGetAction(
        "fetchProductsStoreStock",
        { storeId: e },
        "fetchProductsStoreStockSpinner"
      )
    );
  };
  const fetchDestinationStoreAndSupplier = (e) => {
    setSelectedSource(e);
    fetchProductBySupplierId(e);
    dispatch({
      type: "fetchDestinationStore",
      payload: e,
    });
  };
  const changeThePriceAndQty = (e) => {
    dispatch({
      type: "ChangeThePriceAndQty",
      payload: e,
    });
  };

  if (disProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
    setValue("units", "");
    setValue("product", "");
    // form.setFieldsValue({
    //   units: "",
    //   product: "",
    // });
  }

  const fetchSupplier = (value) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    if (value) {
      fetch(`${process.env.REACT_APP_ROOT_URL}/getSupplierByStoreId/${value}`, {
        headers,
      })
        .then((response) => response.json())
        .then((body) => {
          const data = body.Record.map((Item) => ({
            text: `${Item.name}`,
            value: Item._id,
          }));
          setSupplierData(data);
        });
    }
  };

  const setUnitsForSelectedProduct = (e) => {
    dispatch({
      type: "setUnits",
      payload: e,
    });
  };

  if (selectedUnit) {
    setValue("unit", selectedUnit.unit);
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <h1>
              <IntlMessages id="create.invoice.title" />
            </h1>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
              <div className="row mb-4">
                {/* resourceStore */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <Controller
                      control={control}
                      name="resourceStore"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          styles={selectStyle}
                          className={errors.resourceStore ? "is-invalid" : ""}
                          isSearchable={true}
                          isDisabled={localProducts.length > 0 ? true : false}
                          placeholder={intlPlaceholder(
                            "storeToStoreInvoice.createInvoicePage.sourceStore"
                          )}
                          onChange={(e) => {
                            fetchDestinationStoreAndSupplier(e.value);
                            onChange(e.value);
                          }}
                          options={renderOptions(
                            ResourceStore &&
                              ResourceStore.length &&
                              ResourceStore,
                            "name",
                            "_id"
                          )}
                        />
                      )}
                    />

                    <div className="invalid-feedback">
                      {errors.resourceStore?.message}
                    </div>
                  </div>
                </div>
                {/* destinationStore */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <Controller
                      control={control}
                      name="destinationStore"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <>
                          <Select
                            styles={selectStyle}
                            className={
                              errors.destinationStore ? "is-invalid" : ""
                            }
                            isSearchable={true}
                            isDisabled={
                              DestinationStore.length === 0 ||
                              localProducts.length > 0
                                ? true
                                : false
                            }
                            placeholder={intlPlaceholder(
                              "storeToStoreInvoice.createInvoicePage.destinationStore"
                            )}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            options={renderOptions(
                              DestinationStore,
                              "name",
                              "_id"
                            )}
                          />
                        </>
                      )}
                    />

                    <div className="invalid-feedback">
                      {errors.destinationStore?.message}
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Items: */}
              {/* <div className="row mb-4">
                <div className="col-md-12">
                  <h3>
                    <IntlMessages id="storeToStoreInvoice.addItemLabel" />
                  </h3>
                </div>
              </div> */}

              <div className="row mb-4">
                {/* products */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <Controller
                      control={control}
                      name="products"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <>
                          <Select
                            styles={selectStyle}
                            className={errors.products ? "is-invalid" : ""}
                            isSearchable={true}
                            isLoading={fetchProductsStoreStockSpinner}
                            closeMenuOnSelect={false}
                            isMulti={true}
                            disabled={
                              DestinationStore.length === 0 ||
                              ProductsLocal.length > 0
                                ? true
                                : false
                            }
                            placeholder={intlPlaceholder(
                              "storeToStoreInvoice.createInvoicePage.products"
                            )}
                            onChange={(values) => {
                              let selectedIds = values.map(
                                (item) => item.value
                              );
                              onChange(selectedIds);
                              // setUnitsForSelectedProduct(e.target.value);
                              // onChange(e.target.value);
                            }}
                            options={renderOptions(
                              Products,
                              "productName",
                              "_id"
                            )}
                          />
                        </>
                      )}
                    />

                    <div className="invalid-feedback">
                      {errors.products?.message}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <CButton className="d-block w-100" type="submit">
                    <i className="icon icon-add me-1"></i>
                    <IntlMessages id="storeToStoreInvoice.createInvoicePage.add" />
                  </CButton>
                </div>
              </div>

              <div className="row mb-4">
                {/* unit */}
                {/* <div className="col-md-3">
                  <div className="mb-3">
                    <Controller
                      control={control}
                      name="units"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <CFormSelect
                          className={errors.units ? "is-invalid" : ""}
                          value={value}
                          onChange={(e) => {
                            changeThePriceAndQty(e.target.value);
                            onChange(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {intlPlaceholder(
                              "distributeProducts.pleaseSelectUnit"
                            )}
                          </option>
                          {units &&
                            units.map((Item) => {
                              if (Item.subUnits) {
                                return (
                                  <>
                                    <option value={Item.key}>
                                      {Item.label}
                                    </option>
                                    {Item.subUnits.map((x) => (
                                      <option value={x._id}>
                                        {x.subUnitName}
                                      </option>
                                    ))}
                                  </>
                                );
                              } else {
                                return (
                                  <option value={Item.key}>{Item.label}</option>
                                );
                              }
                            })}
                        </CFormSelect>
                      )}
                    />

                    <div className="invalid-feedback">
                      {errors.units?.message}
                    </div>
                  </div>
                </div> */}

                {/* Qty */}
                {/* <div className="col-md-3">
                  <div className="mb-3">
                    <CInputGroup>
                      <Controller
                        control={control}
                        name="Qty"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormInput
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            value={!isNaN(value) ? value : undefined}
                            className={errors.Qty ? "is-invalid" : ""}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            placeholder={intlPlaceholder(
                              "storeToStoreInvoice.createInvoicePage.qty"
                            )}
                          />
                        )}
                      />

                      <CInputGroupText id="basic-addon2">{`/${Qty.toFixed(
                        2
                      )}`}</CInputGroupText>
                    </CInputGroup>
                    <div className="error-message">{errors.Qty?.message}</div>
                  </div>
                </div> */}

                {/* unit price / credit price */}
                {/* <div className="col-md-2 mb-3">
                  <div className=" number-format-input">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <NumberFormat
                          className={errors.Price ? "is-invalid" : ""}
                          allowNegative={false}
                          thousandSeparator={true}
                          onValueChange={(v) => onChange(v.value)}
                          value={value}
                          defaultValue={defaultPriceValue}
                          placeholder={intlPlaceholder(
                            "storeToStoreInvoice.createInvoicePage.unitPrice"
                          )}
                        />
                      )}
                      name={"Price"}
                      control={control}
                    />
                    <div className="invalid-feedback">
                      {errors.Price?.message}
                    </div>
                  </div>
                  <p style={{color : '#848484', fontSize : '15px'}}>
                    
                    <IntlMessages id="storeToStoreInvoice.creditPrice" />
                    </p>
                </div> */}

                {/* enter price / debit price */}
                {/* <div className="col-md-2 mb-3">
                  <div className=" number-format-input">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <NumberFormat
                          className={errors.premiumPrice ? "is-invalid" : ""}
                          allowNegative={false}
                          thousandSeparator={true}
                          onValueChange={(v) => onChange(v.value)}
                          value={value}
                          defaultValue={defaultPriceValue}
                          placeholder={intlPlaceholder(
                            "storeToStoreInvoice.createInvoicePage.enterPremiumPrice"
                          )}
                        />
                      )}
                      name={"premiumPrice"}
                      control={control}
                    />
                    <div className="invalid-feedback">
                      {errors.premiumPrice?.message}
                    </div>
                  </div>
                  <p style={{color : '#848484', fontSize : '15px'}}>
                   
                    <IntlMessages id="storeToStoreInvoice.debitPrice" />
                    </p>
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <DistributeProductList
                    setValue={setValue}
                    reset={reset}
                    setDisableSaveBtn={setDisableSaveBtn}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <CButton
                    className="d-block w-100 btn btn-light btn-block"
                    color="dark"
                    variant="outline"
                    onClick={() => {
                      dispatch({
                        type: "STORE_TO_STORE_PRODUCTS",
                      });
                    }}
                  >
                    <IntlMessages id="storeToStoreInvoice.createInvoicePage.reset" />
                  </CButton>
                </div>
                <div className="col-md-4">
                  <CLoadingButton
                    disabled={disableSaveBtn}
                    className="d-block w-100"
                    onClick={() => onFinish()}
                    color="primary"
                    loading={getStoretostore}
                    disabled={getStoretostore}
                  >
                    <span className="text-white">
                      <IntlMessages id="storeToStoreInvoice.createInvoicePage.create" />
                    </span>
                  </CLoadingButton>
                </div>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default DistributeForm;
