import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceProductList from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import InvoiceSignature from "src/components/InvoiceSignature";
const InvoiceDetail = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.deliveredR);
  const authProps = useSelector((state) => state.auth);
  const { permissionAccess, LogeInUser } = authProps;
  const { refundSuccessfully } = invProps;
  const { locale } = useSelector(({ Setting }) => Setting);
  let [printBalance, setPrintBalance] = useState(true);
  const { languageId } = locale;

  const {
    prefix,
    createdAt,
    store,
    createdBy,
    customer,
    products,
    clientName,
    clientAddress,
    units,
  } = invProps.DeliveryDetailModal;

  const storeName = store && store[0] ? store[0].label : "";
  const storeEmail = store && store[0] ? store[0].email : "";
  const storeAddress = store && store[0] ? store[0].address : "";
  const customerName = customer && customer[0] ? customer[0].label : "";
  const customerAddress = customer && customer[0] ? customer[0].address : "";
  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";
  const history = useHistory();
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "name",
      _style: { width: "50%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantityDelivered" />,
      key: "qtyToDeliver",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantityUnit" />,
      key: "unit",
      _style: { width: "20%" },
    },
  ];

  const renderUnit = (unitId, subunit = null, retail) => {
    if (units) {
      const selectedParentIndex = _.findIndex(units, (x) => x._id === unitId);
      if (selectedParentIndex > -1) {
        if (subunit) {
          let subunits = units[selectedParentIndex].subUnits;
          let filterUnitName = _.filter(subunits, (x) => x._id === subunit);
          return filterUnitName[0].subUnitName;
        } else {
          return units[selectedParentIndex].name;
        }
      }
    }
  };

  const getRecordValue = () => {
    const productArray = products;
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    let data = [];

    if (productArray && productArray.length > 0) {
      productArray.map((item) => {
        let {
          productName,
          quantity,
          unit,
          salePrice,
          total,
          delivered,
          ...rest
        } = item;

        salePrice = NumberWithCommas(salePrice);
        total = NumberWithCommas(total);
        quantity = quantity;
        let unitName = renderUnit(unit);

        let obj = {
          productName,
          quantity,
          unitName,
          salePrice,
          delivered,
          total,
        };

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.store");
    let key2 = intlPlaceholder("customer");

    let key3 = intlPlaceholder("receiptDetail.invoiceNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("invoiceDetail.productName"),
      intlPlaceholder("invoiceDetail.quantity"),
      intlPlaceholder("invoiceDetail.unit"),
      intlPlaceholder("invoiceDetail.unitPrice"),
      intlPlaceholder("invoiceDetail.delivered"),
      intlPlaceholder("invoiceDetail.total"),
    ];

    let detailObj = {
      [key1]: { name: storeName, address: storeAddress },
      ...(customerName && { [key2]: customerName }),
    };

    detailObj = {
      ...detailObj,
      [key4]: createdByName,
    };

    let obj = {
      subject: `${intlPlaceholder(
        "invoiceDetail.InvoiceLabel"
      )} from ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: intlPlaceholder("invoiceDetail.InvoiceLabel"),
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,

      note: intlPlaceholder("invoiceDetail.note"),
    };
    return obj;
  };

  const styles = {
    label: {
      fontSize: "14px",
      color: "grey",
      minWidth: "20px",
      marginBottom: 0,
    },
    para: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    to: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    address: {
      color: "grey",
      maxWidth: "255px",
      textTransform: "capitalize",
      fontSize: "12px",
      textAlign: "center",
    },
    storeAddress: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      fontSize: "12px",
      textTransform: "capitalize",
      color: "grey",
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>

      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5"
        ref={componentRef}
      >
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <img src={logoImage} alt="" width="300" className="mb-2" />
            <p className="mb-0" style={styles.address}>
              {address}
            </p>
          </div>

          <div>
            <div className="d-flex">
              <div>
                <p style={styles.to}> CLIENT: </p>
                <p style={styles.para}>{clientName}</p>
                <p style={styles.storeAddress}>{clientAddress}</p>
              </div>
            </div>
            {customerName && (
              <div className="d-flex">
                <h4 style={styles.label}>
                  <IntlMessages id="customer" />:
                </h4>

                <div>
                  <p style={styles.para}>{customerName}</p>
                  <p style={styles.para}>{customerAddress}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <br />
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="ms-1">
                  <IntlMessages id="deliverySlipTitle" /> : {prefix}
                </span>
              </h2>
            </div>

            <small style={{ color: "grey" }}>
              {ChangeDateFormate(createdAt)}
            </small>
          </div>
        </div>
        <br />

        <div className="Table">
          <InvoiceProductList
            items={products}
            columns={columns}
            scopedColumns={{
              qtyToDeliver: (record) => {
                return <td>{parseFloat(record.qtyToDeliver).toFixed(3)}</td>;
              },
              unit: (record) => {
                return (
                  <td>
                    {renderUnit(record.unit, record.subunit, record.retail)}
                  </td>
                );
              },
            }}
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <InvoiceSignature data={{ clientName: storeName }} />
      </div>
      <SendEmailModal
        initialValues={{ email: storeEmail }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default InvoiceDetail;
