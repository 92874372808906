import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import InvoiceDetail from "./InvoiceDetail";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const ViewSaleInvoice = () => {
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.saleInvoiceReducer);
  const { initialVale, ShowSaleInvoiceModal } = invProps;

  const fetchInvoiceInfo = () => {
    if (ShowSaleInvoiceModal && initialVale) {
      let data = null;
      if (initialVale.sendRef === true) {
        data = {
          query: "reference",
          refNo: initialVale.reference,
        };
      } else {
        data = {
          _id: initialVale._id,
        };
      }
      dispatch(
        DataGetAction("getSaleInvoiceDetail", data, "fetchingSaleInvoice")
      );
    }
  };

  useEffect(fetchInvoiceInfo, [ShowSaleInvoiceModal]);

  const closeForm = () => {
    dispatch({ type: "Hide_View_Sale_Invoice_Modal" });
  };
  return (
    <>
      <CModal size="lg" visible={ShowSaleInvoiceModal}>
        <CModalHeader
          onDismiss={() => {
            closeForm();
          }}
        ></CModalHeader>
        <CForm>
          <CModalBody>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    {invProps.invoiceLoader ? <CSpinner /> : <InvoiceDetail />}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                closeForm();
              }}
            >
              {intlPlaceholder("companyModal.close")}
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export default ViewSaleInvoice;
