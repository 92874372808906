import React, { useState } from "react";
import {
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
  CFormSelect,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { notify } from "../../../../util/ShowToast";
import { DataGetAction } from "../../../../redux/actions/http";
import NumberFormat from "react-number-format";
const UserForm = ({ defaultValues, ButtonComponent }) => {
  const comProps = useSelector((state) => state.users);
  const {getRoleDropdown} = comProps
  const dispatch = useDispatch();



  const receiptProps = useSelector((state) => state.receiptReducer);
  const [salary, setSalary] = useState(0)
  const { CompanyAccount, Store } = receiptProps;



  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      <IntlMessages id="user.addModal.firstName_message" />
    ),
    lastName: Yup.string().required(
      <IntlMessages id="user.addModal.lastName_message" />
    ),
    phoneNumber: Yup.string().required(
      <IntlMessages id="user.addModal.phoneNumber_message" />
    ),
    roleId: Yup.string().required(
      <IntlMessages id="user.addModal.role_message" />
    ),
    type: Yup.string().required(
      'required!'
    ),
    store: Yup.string().when("type", {
      is: (val) => val === "store",
      then: Yup.string().required(
        <IntlMessages id="employee.addEmployeeModal.store_message" />
      ),
      otherwise: Yup.string().notRequired(),
    }),
    jobTitle: Yup.string().required(
      <IntlMessages id="employee.addEmployeeModal.jobTitle_message" />
    ),
    salary: Yup.string().required(
      <IntlMessages id="employee.addEmployeeModal.salary_message" />
    )
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue, watch} =
    useForm(formOptions);
  const { errors } = formState;



  const onFinish = (values) => {
    if (values.bank) {
      let selectedBank = CompanyAccount.filter(
        (Item) => Item._id === values.bank
      );
      values.bank = {
        accountId: selectedBank[0].balanceId,
        bankName: selectedBank[0].bankName,
      };
    }


    
    if (defaultValues === null) {
      dispatch(
        DataRequestAction("POST", "inviteUser", values, "", "addingUser", false)
      );
    } else {
      values._id = defaultValues._id;
      dispatch(
        DataRequestAction("PUT", "editUser", values, "", "addingUser", false)
      );
    }
  };
  const watchShowType = watch("type");
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.firstName ? "is-invalid" : ""}
            {...register("firstName")}
            placeholder={intlPlaceholder("user.addModal.firstName")}
            type="text"
            id="firstName"
          />
          <CFormLabel htmlFor="firstName">
            {intlPlaceholder("user.addModal.firstName")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.firstName?.message}</div>
        </CFormFloating>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.lastName ? "is-invalid" : ""}
            {...register("lastName")}
            placeholder={intlPlaceholder("user.addModal.lastName")}
            type="text"
            id="lastName"
          />
          <CFormLabel htmlFor="lastName">
            {intlPlaceholder("user.addModal.lastName")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.lastName?.message}</div>
        </CFormFloating>
      </div>
     
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            {...register("email")}
            placeholder={intlPlaceholder("user.addModal.email")}
            type="text"
            id="email"
            disabled={defaultValues ? true : false}
          />
          <CFormLabel htmlFor="email">
            {intlPlaceholder("user.addModal.email")}
          </CFormLabel>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <Controller
          control={control}
          defaultValues={defaultValues && defaultValues.phoneNumber}
          name={"phoneNumber"}
          render={(props) => {
            return (
              <PhoneInput
                country={"gn"}
                value={defaultValues && defaultValues.phoneNumber}
                onChange={(v) => props.field.onChange(v)}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                }}
              />
            );
          }}
        />
        <div className="error-message">{errors.phoneNumber?.message}</div>
      </div>

      <div className="mb-3">
        <CFormFloating
         
        >
          <CFormSelect  className="mb-3"
          {...register("roleId")}
          className={errors.roleId ? "is-invalid" : ""}
          id="roleId" >
          <option value="" hidden>
              Choose role
            </option>

            {getRoleDropdown && getRoleDropdown.length > 0 && getRoleDropdown.map(item=>{
              return <option value={item._id}>{item.name}</option>
            })}
            
          
          </CFormSelect>
          <CFormLabel htmlFor="roleId">
            {intlPlaceholder("user.addModal.role")}
          </CFormLabel>
          <div className="error-message">{errors.roleId?.message}</div>
        </CFormFloating>
       
      </div>

      <CFormFloating className="mb-3">
        <CFormSelect {...register("type")}
          className={errors.type ? "is-invalid" : ""}
        >
          <option value="" hidden>
            {intlPlaceholder("employee.addEmployeeModal.selectAEmployeeType")}
          </option>
          <option value="company">Company</option>
          <option value="store">Store</option>
        </CFormSelect>
        <div className="invalid-feedback">{errors.type?.message}</div>
        <CFormLabel htmlFor="">
        {intlPlaceholder("employee.addEmployeeModal.selectAEmployeeType")}
      </CFormLabel>
      </CFormFloating>


      {watchShowType === "store" && <CFormFloating className="mb-3">
        <CFormSelect {...register("store")}
          className={errors.store ? "is-invalid" : ""}
        >
          <option value="" hidden>
            {intlPlaceholder('employee.addEmployeeModal.store')}
          </option>
          {Store && Store.map((Item) => {
            return <option value={Item._id}>{Item.label}</option>;
          })}
        </CFormSelect>
        <div className="invalid-feedback">{errors.store?.message}</div>
        <CFormLabel htmlFor="">
        {intlPlaceholder('employee.addEmployeeModal.store')}
      </CFormLabel>
      </CFormFloating>}


      <div className="mb-3">
        <CFormFloating className="mb-3">


          <CFormInput
            className={errors.jobTitle ? "is-invalid" : ""}
            {...register("jobTitle")}
            placeholder={intlPlaceholder('employee.addEmployeeModal.jobTitle')}
            type="text"
            id="jobTitle"
          />
          <CFormLabel htmlFor="jobTitle">
            {intlPlaceholder("employee.addEmployeeModal.jobTitle")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.jobTitle?.message}</div>
        </CFormFloating>

      </div>

      <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.salary ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder('ce.addExpenseModal.amount')}
                      />
                    )}
                    name={"salary"}
                    control={control}
                  />
                 <div className="invalid-feedback">{errors.salary?.message}</div>
                </div>
      {/* <CFormFloating className="mb-3">
        

        <CFormInput
          className={errors.salary ? "is-invalid" : ""}
          onChange={(data) => {
            let valueText = data.target.value;
            if (valueText !== "") {
              let tempValue = parseInt(valueText.replace(/,/g, ''))
              setValue('salary', tempValue)
              valueText = tempValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              setSalary(valueText)
            } else {
              setValue('salary', valueText)
              setSalary(valueText)
            }


          }}
          value={salary}
          placeholder={intlPlaceholder('employee.addEmployeeModal.monthlySalary')}
          type="text"
          id="salary"
        />
        <div className="invalid-feedback">{errors.salary?.message}</div>
        <CFormLabel htmlFor="salary">
        {intlPlaceholder('employee.addEmployeeModal.monthlySalary')}
      </CFormLabel>
      </CFormFloating> */}


      <div className="mb-3">
      <CFormFloating className="mb-3">
        <CFormInput
          className={errors.note ? "is-invalid" : ""}
          {...register("note")}
          placeholder={intlPlaceholder('employee.addEmployeeModal.note')}
          type="text"
          id="note"
        />
        <CFormLabel htmlFor="note">
            {intlPlaceholder("employee.addEmployeeModal.note")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.note?.message}</div>
        </CFormFloating>
       
      </div>


      {ButtonComponent}
    </CForm>
  );
};

export default UserForm;
