import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import CurrencyForm from "./CurrencyForm";
const AddCustomerModal = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();

  const comProps = useSelector((state) => state.currencyReducer);

  const { currencyLoader, selectedItem, modalEditVisible, success } = comProps;

  const modalType = "show_currency_modal_edit";

  return (
    <div>
    
      <CModal visible={modalEditVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {  intlPlaceholder('currency.addCurrencyModal.editCurrency')}
          </CModalTitle>
        </CModalHeader>
       
          <CModalBody>
            <CurrencyForm
              defaultValues={selectedItem}
              ButtonComponent={
                <CModalFooter>
                  <CButton
                    color="secondary"
                    onClick={() =>
                      dispatch(DataEntryModalAction(modalType, null))
                    }
                  >
                    <IntlMessages id="customer.addCustomerModal.cancel" />
                  </CButton>

                  <CLoadingButton
                    type="submit"
                    color="primary"
                    loading={currencyLoader}
                    disabled={currencyLoader}
                  >
                    {intlPlaceholder("customer.addCustomerModal.save")}
                  </CLoadingButton>
                </CModalFooter>
              }
            />


          </CModalBody>
       
      </CModal>
    </div>
  );
};

export default AddCustomerModal;
