import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react-pro";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "src/redux/actions/http";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import Select from "react-select";
import { NumberWithCommas, selectStyle } from "src/constants/CommonFn";

const GrossProfitCard = () => {
  const dispatch = useDispatch();
  const stateProps = useSelector((state) => state.grossProfit);
  const [SelectedValue, setSelectedValue] = useState("weekly");
  const { grossProfitCalculationData, grossProfitCalculationSpin } = stateProps;
  const fetchGraphDetail = (value = "weekly") => {
    dispatch(
      DataGetAction(
        "grossProfitCalculation",
        {
          query: "all",
          graphType: value,
          // startDate: moment(),
          // duration: value,
        },
        "grossProfitCalculationSpin"
      )
    );
  };

  const renderGraph = () => {
    if (!grossProfitCalculationSpin && grossProfitCalculationData) {
      const { labels, dataSet } = grossProfitCalculationData;
      return (
        <CChartLine
          style={{ height: "300px", marginTop: "10px" }}
          data={{
            labels: labels,
            datasets: [
              {
                label: intlPlaceholder("grossProfitData"),
                backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
                borderColor: getStyle("--cui-info"),
                pointHoverBackgroundColor: getStyle("--cui-info"),
                borderWidth: 2,
                data: dataSet,
                fill: true,
              },
              // {
              //   label: intlPlaceholder("SupplierOrder"),
              //   backgroundColor: "transparent",
              //   borderColor: getStyle("--cui-success"),
              //   pointHoverBackgroundColor: getStyle("--cui-success"),
              //   borderWidth: 2,
              //   data: dataSetInvoice,
              // },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                align: "end",
              },
            },
            scales: {
              x: {
                grid: {
                  drawOnChartArea: true,
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  stepSize: Math.ceil(250 / 5),
                  max: 250,
                },
              },
            },
            elements: {
              line: {
                tension: 0.4,
              },
              point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
              },
            },
          }}
        />
      );
    }
    if (grossProfitCalculationSpin) {
      return (
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      );
    }
  };

  const optionsData = [
    {
      label: intlPlaceholder("dropdownlabel-weekly"),
      value: "weekly",
    },
    {
      label: intlPlaceholder("dropdownlabel-monthly"),
      value: "monthly",
    },
    {
      label: intlPlaceholder("dropdownlabel-yearly"),
      value: "yearly",
    },
  ];
  const fetchClientReceiptAndInvoiceGraphData = () => {
    fetchGraphDetail(optionsData[0].value);
  };
  useEffect(fetchClientReceiptAndInvoiceGraphData, []);
  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow>
          <CCol>
            <h4 id="traffic" className="card-title mb-0">
              {intlPlaceholder(`grossProfitCardTitle-${SelectedValue}`)}
            </h4>
            <div className="small text-medium-emphasis">
              {intlPlaceholder(`dropdownlabel-${SelectedValue}`)}
            </div>
          </CCol>
          <CCol sm={3}>
            <div className="">
              <Select
                value={{
                  label: intlPlaceholder(`dropdownlabel-${SelectedValue}`),
                  value: SelectedValue,
                }}
                isSearchable={true}
                styles={selectStyle}
                onChange={(e) => {
                  setSelectedValue(e.value);
                  fetchGraphDetail(e.value);
                }}
                options={optionsData}
              />
            </div>
          </CCol>
        </CRow>
        {renderGraph()}
        <CRow>
          <CCol style={{ textAlign: "center" }}>
            {intlPlaceholder("grossProfitCardTotal")}:{" "}
            {(grossProfitCalculationData &&
              NumberWithCommas(grossProfitCalculationData.profit)) ||
              0}{" "}
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default GrossProfitCard;
