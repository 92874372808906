import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import _ from "lodash";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceTable from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { useHistory } from "react-router-dom";
import { notify } from "src/util/ShowToast";

const InvoiceDetail = () => {
  const componentRef = useRef();
  const invProps = useSelector((state) => state.invoiceReducer);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser, permissionAccess } = authProps;
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;

  const dispatch = useDispatch();

  const { refundSuccessfully } = invProps;

  console.log('refundSuccessfully', refundSuccessfully)
  const {
    _id,
    referenceNo,
    invoiceId,
    prefix,
    createdAt,
    supplier,
    createdBy,
    products,
    currency,
    isRefund,
    status,
    units,
  } = invProps.InvoiceDetail !== null ? invProps.InvoiceDetail : "000-000-000";



  const supplierName = supplier && supplier[0] ? supplier[0].label : "";
  const supplierAddress = supplier && supplier[0] ? supplier[0].address : "";
  const supplierEmail = supplier && supplier[0] ? supplier[0].email : "";
  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");
  const history = useHistory();

  const renderUnit = (data) => {
    const unitId = data.unitId;
    if (units) {
      const selectedParentIndex = _.findIndex(units, (x) => x._id === unitId);
      if (selectedParentIndex > -1) {
        return units[selectedParentIndex].name;
       } //else {
      //   //unit is child unit
      //   let parentIndex = 0;
      //   let childIndex = 0;
      //   units.map((x, index) => {
      //     const childIndexObj = _.findIndex(
      //       x.subUnits,
      //       (j) => j._id === unitId
      //     );
      //     if (childIndexObj > -1) {
      //       parentIndex = index;
      //       childIndex = childIndexObj;
      //     }
      //     return x;
      //   });

      //   return units[parentIndex].subUnits[childIndex].subUnitName;
      // }
    }
  };

  if (refundSuccessfully) {
    history.push("/invoice");
    let messageLng = intlPlaceholder("success");
    notify("success", messageLng);
    dispatch({
      type: "Reset_refundSupplierInvoice",
    });
  }

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "productName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoiceDetail.quantity" />,
      key: "purchasedQty",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoiceDetail.unit" />,
      key: "unit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.currency" />,
      key: "currency",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unitPrice" />,
      key: "unitPrice",
      _style: { width: "40%" },
    },
  ];

  if (currency && currency[0].type === "foreign") {
    columns.push({
      label: <IntlMessages id="invoiceDetail.localPrice" />,
      key: "localPrice",
      _style: { width: "40%" },
    });
  }

  columns.push({
    label: <IntlMessages id="invoiceDetail.price" />,
    key: "price",
    _style: { width: "40%" },
  });

  const getAmountBill = () => {
    const Products = products && products;
    if (Products) {
      let total = 0;
      let totalInCurrency = 0;
      let totalLocalPrice = 0;
      Products.map((x) => {
        total = total + parseFloat(x.purchasedQty) * parseFloat(x.price);
        totalInCurrency = totalInCurrency + x.price;
        totalLocalPrice =
          totalLocalPrice +
          parseFloat(x.localPrice) * parseFloat(x.purchasedQty);
        return x;
      });

      return (
        <>
          <p className="mb-0 text-right">
            <span
              className="mr-3 font-weight-bold"
              style={{
                width: "150px",
                display: "inline-block",
              }}
            >
              <IntlMessages id="invoiceDetail.totalBillWords" />:
            </span>
            {ChangeNumberToWord(total, languageId)}{" "}
          </p>
          <p className="mb-0 text-right">
            <span
              className="mr-3 font-weight-bold"
              style={{
                width: "150px",
                display: "inline-block",
              }}
            >
              <IntlMessages id="invoiceDetail.totalBill" />:
            </span>
            {ChangeNumberFormate(total.toFixed(2))}{" "}
          </p>
          {/* <p className="mb-0 text-right"><span className="mr-3 font-weight-bold" style={{
  width: '100px',
  display: 'inline-block'
}}>Total local Bill:</span>{totalLocalPrice}</p> */}
        </>
      );
    }
  };
  const getBillAmount = () => {
    const Products = products && products;
    if (Products) {
      let total = 0;
      let totalInCurrency = 0;
      let totalLocalPrice = 0;
      Products.map((x) => {
        total = total + parseFloat(x.purchasedQty) * parseFloat(x.price);
        totalInCurrency = totalInCurrency + x.price;
        totalLocalPrice =
          totalLocalPrice +
          parseFloat(x.localPrice) * parseFloat(x.purchasedQty);
        return x;
      });
      return (
        <div className="d-flex justify-content-between mt-0">
          <p></p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 14, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBill" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "60%", fontSize: 14, fontWeight: "bold" }}
              >
                {ChangeNumberFormate(total.toFixed(2))}
              </span>
            </div>
            <div className="d-flex">
              <span
                className="d-inline-block "
                style={{ width: "40%", fontSize: 14, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBillWords" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "60%", fontSize: 14 }}
              >
                {ChangeNumberToWord(total, languageId)}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const getRecordValue = () => {
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    const Products = products && products;
    let total = 0;
    let totalInCurrency = 0;
    let totalLocalPrice = 0;
    Products &&
      Products.map((x) => {
        total = total + parseFloat(x.purchasedQty) * parseFloat(x.price);
        totalInCurrency = totalInCurrency + x.price;
        totalLocalPrice =
          totalLocalPrice +
          parseFloat(x.localPrice) * parseFloat(x.purchasedQty);
        return x;
      });

    let totalBillFigure = NumberWithCommas(parseFloat(total));
    let totalBillWords = ChangeNumberToWord(total, languageId);

    let data = [];

    if (
      invProps.InvoiceDetail &&
      invProps.InvoiceDetail.products &&
      invProps.InvoiceDetail.products.length > 0
    ) {
      invProps.InvoiceDetail.products.map((item) => {
        let { productName, purchasedQty, price, localPrice, ...rest } = item;
        let obj = {
          productName,
          purchasedQty,
          price: NumberWithCommas(parseFloat(price)),
          unitId: renderUnit(item),
          currency: currency && currency[0].name,
          unitPrice: NumberWithCommas(parseFloat(localPrice)),
        };
        if (currency && currency[0].type === "foreign") {
          obj["localPrice"] = NumberWithCommas(parseFloat(item.localPrice));
        }

        obj["price"] = NumberWithCommas(
          parseFloat(item.purchasedQty) * parseFloat(item.price)
        );

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.invoiceFor");
    let key2 = intlPlaceholder("receiptDetail.payableTo");
    let key3 = intlPlaceholder("receiptDetail.invoiceNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("invoiceDetail.productName"),
      intlPlaceholder("invoiceDetail.quantity"),
      intlPlaceholder("invoiceDetail.unit"),
      intlPlaceholder("invoiceDetail.currency"),
      intlPlaceholder("invoiceDetail.unitPrice"),
    ];

    if (currency && currency[0].type === "foreign") {
      tableColumn.push(intlPlaceholder("invoiceDetail.localPrice"));
    }

    tableColumn.push(intlPlaceholder("invoiceDetail.price"));

    let obj = {
      subject: `${intlPlaceholder("invoiceFrom")} ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: intlPlaceholder("supplierInvoiceDetail.title"),
      date: ChangeDateFormate(createdAt),

      details: {
        [key1]: { name: supplierName, address: supplierAddress },
        [key2]: prefix + "-" + invoiceId + "/" + referenceNo,
        [key3]: supplierName,
        [key4]: createdByName,
      },
      tableColumn: tableColumn,
      tableRow: data,
      totalBillKey: intlPlaceholder("invoiceDetail.totalBill"),
      totalBillWorldKey: intlPlaceholder("invoiceDetail.totalBillWords"),
      totalBillFigures: totalBillFigure,
      totalBillWords: totalBillWords,
    };
    return obj;
  };

  const areYouSureYouWantToRefundTheInvoice = intlPlaceholder(
    "areYouSureYouWantToRefundTheInvoice"
  );

  const checkButtonPermission = (buttonPermissionKey) => {
    if (permissionAccess && LogeInUser.role !== "superAdmin") {
      let supplierInvoicePermission =
        permissionAccess && permissionAccess["supplierInvoices"];
      if (supplierInvoicePermission.includes(buttonPermissionKey)) {
        return true;
      } else {
        return false;
      }
    } else if (LogeInUser.role === "superAdmin") {
      return true;
    }
  };


  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          {(status !== 'cancelled' && checkButtonPermission('cancel')) && <DeleteModal
            contentLangueId={`${areYouSureYouWantToRefundTheInvoice} ${prefix}-${invoiceId}/${referenceNo}`}
            method="POST"
            type="refundSupplierInvoice"
            selectedItem={{ _id, invoiceId, referenceNo, supplierId: supplier }}
            textColor={'#ffffff'}
            ButtonTitleId="receipt.refund"
            titleLanguageId="receipt.refund"
            size="sm"
            color="danger"
            variant=""
          />}
          {status === 'cancelled' && <CButton
            color="danger"
            size="sm"
            className="text-white me-3"
          >
            <IntlMessages id="refunded" />
          </CButton>}
          {status !== 'cancelled' && <CButton
            color="warning"
            size="sm"
            className="text-white me-3"
            onClick={() => {
              dispatch({
                type: "send_email_modal",
              });
            }}
          >
            <i className="icon icon-mail-open me-1 mb-1"></i>
            <IntlMessages id="sendMail" />
          </CButton>}

          {status !== 'cancelled' && checkButtonPermission('pdf') && <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                size="sm"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />}
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 "
        ref={componentRef}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <img src={logoImage} alt="" width="300" className="mb-2" />

            <p
              className="mb-0"
              style={{
                color: "grey",
                maxWidth: "255px",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              {address}
            </p>
          </div>
          <div className="">
            <div>
              <div className="d-flex">
                <p
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    minWidth: "120px",
                    marginBottom: 0,
                  }}
                >
                  <IntlMessages id="receiptDetail.invoiceFor" />:
                </p>
                <div>
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {supplierName}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {supplierAddress}
                  </p>
                </div>
              </div>

              <div className="d-flex">
                <p
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    minWidth: "120px",
                    marginBottom: 0,
                  }}
                >
                  <IntlMessages id="receiptDetail.payableTo" />:
                </p>
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {supplierName}
                </p>
              </div>
              <div className="d-flex">
                <p
                  style={{
                    fontSize: "14px",
                    color: "grey",
                    minWidth: "120px",
                    marginBottom: 0,
                  }}
                >
                  <IntlMessages id="receiptDetail.createdBy" />:
                </p>
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {createdByName}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="me-1">
                  <IntlMessages id="supplierInvoiceDetail.title" />
                </span>:
                <span className="ms-1">{prefix + "-" + invoiceId + "/" + referenceNo}</span>
              </h2>

            </div>

            <small style={{ color: 'grey' }}> {ChangeDateFormate(createdAt)}</small>
          </div>
        </div>

        <br />

        <div className="Table">
          <InvoiceTable
            items={products && products.length > 0 ? products : []}
            columns={columns}
            scopedColumns={{
              unit: (record) => {
                return (
                  <td>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {renderUnit(record)}
                    </span>
                  </td>
                );
              },
              currency: (record) => {
                return (
                  <td>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {currency && currency[0].name}
                    </span>
                  </td>
                );
              },
              unitPrice: (record) => {
                return (
                  <td>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {ChangeNumberFormate(parseFloat(record.price).toFixed(2))}
                    </span>
                  </td>
                );
              },
              localPrice: (record) => {
                return (
                  <td>
                    <span>
                      {ChangeNumberFormate(
                        parseFloat(record.localPrice).toFixed(2)
                      )}
                    </span>
                  </td>
                );
              },
              price: (record) => {
                return (
                  <td>
                    <span>
                      {ChangeNumberFormate(
                        (
                          parseFloat(record.purchasedQty) *
                          parseFloat(record.price)
                        ).toFixed(2)
                      )}
                    </span>
                  </td>
                );
              },
            }}
          />
        </div>

        {getBillAmount()}
        <InvoiceSignature data={{ clientName: supplierName }} />
      </div>
      <SendEmailModal
        initialValues={{ email: supplierEmail }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default InvoiceDetail;
