import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { ChangeDateFormate } from "src/constants/CommonFn";
import BankBalanceSheetList from "./components/BankBalanceSheetList";

const Index = () => {
  let headerTitleAccountSummery = intlPlaceholder("report.account.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");


  const dateFormat = "YYYY/MM/DD";
  const componentRef = useRef();
  const pdfRef = useRef();
  const dispatch = useDispatch();

  const blProps = useSelector((state) => state.balanceSheet);

  const history = useHistory();
  const param = useLocation();
  const [filter, setFilters] = useState(null);

  

  const { currencyId, paymentMode, filterDate, bankName, currencyName } =
    param.state || {};





  const bankId = param.state && param.state.bankId ? param.state.bankId : "";

  const fetchBalanceSheet = () => {
    let startDate = moment().startOf("today").toString();
    let endDate = moment().endOf("year").toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    dispatch(
      DataGetAction(
        "bankBalanceSheet",
        {
          startDate: startDate,
          endDate: endDate,
          type: paymentMode,
          currency: currencyId,
          bank: bankId,
        },
        "fetchingBankBalanceSheet"
      )
    );

    // return () => {
    //     dispatch({
    //         type: "removeTheResult"
    //     })
    // }
  };

  useEffect(fetchBalanceSheet, [param]);


  let startDate = moment().startOf('year').toString()
  let endDate = moment().toString()

  if (filterDate) {
      startDate = filterDate.startDate
      endDate = filterDate.endDate

  }
  return (
    <>
      <CRow>
     <CCol xs={12}>
        {/* <div className="text-end mb-3">
          <ReactToPrint
              trigger={() => (
                <CButton
                color="info"
                className="text-white"
                >
          
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="print" />
                </CButton>
              )}
              content={() => componentRef.current}
            />
        </div> */}
        <div  ref={componentRef}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <strong>
                {`${headerTitleAccountSummery} ${bankName} (${currencyName}) ${reportFrom} ${ChangeDateFormate(
                  startDate,
                  "YYYY-MM-DD"
                )} ${reportTo} ${ChangeDateFormate(endDate, "YYYY-MM-DD")}`}
              </strong>
            </CCardHeader>
            <CCardBody>
           

             
                <DocsExample href="components/smart-table/">
                  <BankBalanceSheetList
                    Data={blProps.BankBalanceSheet}
                    Spinner={blProps.bankBalanceSheetSpinner}
                  />
                </DocsExample>
              
            </CCardBody>
          </CCard>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(Index);
