import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import classNames from 'classnames'

import {  colorPropType } from './Types'



 const CTableFoot = forwardRef(
  ({ children, className, color, ...rest }, ref) => {
    const _className = classNames(
      {
        [`table-${color}`]: color,
      },
      className,
    )

    return (
      <tfoot className={_className ? _className : undefined} {...rest} ref={ref}>
        {children}
      </tfoot>
    )
  },
)

CTableFoot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: colorPropType,
}

export default CTableFoot
