
const INIT_STATE = {
    LoginVisible: false,
    SignUpVisible: false,
    ForgotPasswordVisible: false,
    SideBarCollapsed: false,
    DeleteSuccessFully: false,
    RestaurantCategory: ["All"],
    emailCheckerLoader: false,
    emailError: false,
    message: '',
    send_email_modal: false,
    success: false,
    spinner: false,
    error : false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getCategories_SUCCESS': {
            const category = action.payload.Record;
            const dataCategory = [];
            category.map(Item => {
                dataCategory.push(Item.title);
                return Item;
            });
            return { ...state, RestaurantCategory: ["All", ...dataCategory] }
        }
        case 'Simple_Modal_State_login': {
            return { ...state, LoginVisible: action.payload };
        }
        case 'Simple_Modal_State_forgotPassword': {
            return { ...state, ForgotPasswordVisible: action.payload };
        }
        case 'Simple_Modal_State_signUp': {
            return { ...state, SignUpVisible: action.payload };
        }
        case 'Side_Bar_Collapse': {
            return { ...state, SideBarCollapsed: !state.SideBarCollapsed };
        }
        case 'delete_success': {
            return { ...state, DeleteSuccessFully: true };
        }

        case 'emailCheckLoader_START': {
            return { ...state, emailCheckerLoader: true }
        }
        case 'emailCheckLoader_STOP': {
            return { ...state, emailCheckerLoader: false }
        }

        case 'emailCheck_SUCCESS': {
            return { ...state, emailError: false, message: '', emailCheckerLoader: false }
        }
        case 'emailCheck_FAILURE': {
            return { ...state, emailError: true, message: action.payload, emailCheckerLoader: false }
        }
        case 'send_email_modal': {
            return {
                ...state,
                send_email_modal: !state.send_email_modal
            }
        }
        case 'sendingInvoiceEmail_START': {
            return {
                ...state,
                spinner: true,
            }
        }
        case 'sendingInvoiceEmail_STOP': {
            return {
                ...state,
                spinner: false,
            }
        }
        case 'sendInvoiceEmail_SUCCESS': {
            return {
                ...state,
                success: true,
                spinner: false,
                message: action.payload.message,
                send_email_modal: !state.send_email_modal
            }
        }
        case 'sendInvoiceEmail_FAILURE': {
            return {
                ...state,
                error: true,
                spinner: false,
                message: action.payload
            }
        }

        case 'common_update': {

            return {
                ...state,
                emailError: false,
                emailCheckerLoader: false,
                message: '',
                success: false,
                spinner: false,
                error : false
            }

        }

        case 'sendEmailTrustFundDetail_SUCCESS': {
            return {
                ...state,
                success: true,
                spinner: false,
                message: action.payload.message,
                send_email_modal: !state.send_email_modal
            }
        }
        case 'sendEmailTrustFundDetail_FAILURE': {
            return {
                ...state,
                error: true,
                spinner: false,
                message: action.payload
            }
        }
        
        default:
            return state;
    }
}
