import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    modalEditVisible: false,
    passModalVisible: false,
    selectedItem: null,
    currencyLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Currencies: [],
    fetchCurrencyInfo: false,
    currencySpinner: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getAllCurrency_SUCCESS': {
            return { ...state, Currencies: action.payload.Record, fetchCurrencyInfo: false };
        }
        case 'currencySpinner_START': {
            return { ...state, currencySpinner: true };
        }
        case 'currencySpinner_STOP': {
            return { ...state, currencySpinner: false };
        }
        case 'show_currency_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'show_currency_modal_edit': {
            return { ...state, modalEditVisible: !state.modalEditVisible, selectedItem: action.payload };
        }

        case 'currency_START': {
            return { ...state, currencyLoader: true };
        }
        case 'currency_STOP': {
            return { ...state, currencyLoader: false };
        }

        case 'addCurrency_SUCCESS': {
            return { ...state, success: true, message: 'recordAddedSuccessfully', Currencies: [action.payload.Record, ...state.Currencies], currencyLoader: false };
        }
        case 'addCurrency_FAILURE': {
            return { ...state, error: true, success: false, currencyLoader: false, message: action.payload };
        }


        case 'editCurrency_SUCCESS': {
            let oldCat = state.Currencies;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Currencies: newCat, currencyLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editCurrency_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, currencyLoader: false, message: action.payload, modalVisible: false };
        }

        case 'currency_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                currencyLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null,
                modalEditVisible: false

            };
        }

        case 'deleteCurrency_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Currencies;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Currencies: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }

}
