import React, { useState } from "react";
import {
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilBuilding,
  cilUserFollow,
} from "@coreui/icons";
import { notify } from "../../../util/ShowToast";
const ProfileForm = ({ fileUrl }) => {
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);

  const { ShowRestLoader, success, error } = authProps;

  const onFinish = (values) => {
    dispatch(
      DataRequestAction("POST", "resetPassword", values, "", "reset", false)
    );
  };

  // form validation rules
  // form validation rules

  let currentPasswordRequired = intlPlaceholder("currentPasswordRequired");
  let passwordRequired = intlPlaceholder("passwordRequired");
  let confirmPasswordRequired = intlPlaceholder("confirmPasswordRequired");
  let passwordMustMatch = intlPlaceholder("passwordMustMatch");

  let currentPasswordLng = intlPlaceholder("currentPassword");
  let passwordLng = intlPlaceholder("password");
  let confirmPasswordLng = intlPlaceholder("confirmPassword");
  let resetPasswordLng = intlPlaceholder("resetPassword");

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(currentPasswordRequired),
    password: Yup.string().required(passwordRequired),
    confirm: Yup.string()
      .required(confirmPasswordRequired)
      .oneOf([Yup.ref("password")], passwordMustMatch),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;
  if (success) {
    let messageLng = intlPlaceholder(authProps.message);
    notify("success", messageLng);

    dispatch({
      type: "auth_UPDATE",
    });
    reset();
  }
  if (error) {
    let messageLng = intlPlaceholder(authProps.message);
    notify("error", messageLng);

    dispatch({
      type: "auth_UPDATE",
    });
  }

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <>
        <CFormFloating className="mb-3">
          <CFormInput
            type="password"
            placeholder={currentPasswordLng}
            autoComplete="new-password"
            id="oldPassword"
            className={errors.oldPassword ? "is-invalid" : ""}
            {...register("oldPassword")}
          />
          <CFormLabel htmlFor="oldPassword">{currentPasswordLng}</CFormLabel>
          <div className="invalid-feedback">{errors.oldPassword?.message}</div>
        </CFormFloating>
      </>

      <CFormFloating className="mb-3">
        <CFormInput
          type="password"
          placeholder={passwordLng}
          autoComplete="new-password"
          id="password"
          className={errors.password ? "is-invalid" : ""}
          {...register("password")}
        />
        <CFormLabel htmlFor="password">{passwordLng}</CFormLabel>
        <div className="invalid-feedback">{errors.password?.message}</div>
      </CFormFloating>

      <CFormFloating className="mb-4">
        <CFormInput
          type="password"
          placeholder={confirmPasswordLng}
          autoComplete="new-password"
          id="confirm"
          className={errors.confirm ? "is-invalid" : ""}
          {...register("confirm")}
        />
        <CFormLabel htmlFor="confirm">{confirmPasswordLng}</CFormLabel>
        <div className="invalid-feedback">{errors.confirm?.message}</div>
      </CFormFloating>

      <div className="mb-3 text-end">
        <CLoadingButton type="submit" color="primary" loading={ShowRestLoader} disabled={ShowRestLoader}>
          <IntlMessages id="resetPassword" />
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default ProfileForm;
