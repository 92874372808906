const INIT_STATE = {
  createQuickSaleLoader: false,
  storeSaleSpinner: false,
  SaleListData: [],
  showViewDialog: false,
  storeSaleDetailSpinner: false,
  data: null,
  saleDetailData: null,
  addSpinner: false,
  addSuccess: false,
  showEditModal: false,
  editData: null,
  editLocalProduct: [],
  editSuccess: false,
  editModalVisible: false,
  selectedEditQuickSale: null,
  UpdateSpinner: false,
  RecordSuccess: null,
  cancelQuickSaleInvoiceMessge: "",
  cancelQuickSaleInvoiceSuccess: false,
  cancelQuickSaleInvoiceFailure: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "cancelQuickSaleInvoice_SUCCESS": {
      return {
        ...state,
        cancelQuickSaleInvoiceSuccess: true,
        cancelQuickSaleInvoiceMessge: action.payload.message,
      };
    }
    case "cancelQuickSaleInvoice_FAILURE": {
      return {
        ...state,
        cancelQuickSaleInvoiceFailure: true,
        cancelQuickSaleInvoiceMessge: action.payload.message,
      };
    }
    case "resetCancelQuickSale": {
      return {
        ...state,
        cancelQuickSaleInvoiceMessge: "",
        cancelQuickSaleInvoiceSuccess: false,
        cancelQuickSaleInvoiceFailure: false,
      };
    }
    case "StartEditedSpinner_START": {
      return {
        ...state,
        UpdateSpinner: true,
      };
    }
    case "Close_Edit_Modal": {
      return {
        ...state,
        editModalVisible: false,
        selectedEditQuickSale: null,
      };
    }

    case "Show_Edit_Modal": {
      return {
        ...state,
        editModalVisible: true,
        selectedEditQuickSale: action.payload._id,
      };
    }

    case "reset_edit": {
      return {
        ...state,
        editSuccess: false,
        addSpinner: false,
      };
    }
    case "editSale_SUCCESS": {
      return {
        ...state,
        UpdateSpinner: false,
        editSuccess: true,
        addSpinner: false,
      };
    }
    case "editSale_FAILURE": {
      return {
        ...state,
        addSpinner: false,
        UpdateSpinner: false,
      };
    }

    case "resetEditQuickSale_Modal": {
      return {
        ...state,
        showEditModal: false,
        editData: null,
      };
    }

    case "editQuickSale_Modal": {
      return {
        ...state,
        showEditModal: true,
        editData: action.payload,
        editLocalProduct: action.payload.products,
      };
    }

    case "reset_add": {
      return {
        ...state,
        addSuccess: false,
      };
    }
    case "StartSpinnerAddSAlE_START": {
      return {
        ...state,

        addSpinner: true,
      };
    }
    case "addSale_SUCCESS": {
      return {
        ...state,
        addSuccess: true,
        addSpinner: false,
        RecordSuccess: action.payload.Record,
      };
    }
    case "addSale_FAILURE": {
      return {
        ...state,
        addSpinner: false,
      };
    }
    case "openSaleModalDialog": {
      return {
        ...state,
        showViewDialog: true,
        data: action.payload,
      };
    }
    case "closeViewSale": {
      return {
        ...state,
        showViewDialog: false,
        data: null,
      };
    }

    case "fetchingSaleInvoiceSpinner_START": {
      return {
        ...state,
        storeSaleDetailSpinner: true,
      };
    }
    case "getQuickSaleDetail_SUCCESS": {
      return {
        ...state,
        storeSaleDetailSpinner: false,
        saleDetailData:
          action.payload &&
          action.payload.Record.length > 0 &&
          action.payload.Record[0],
      };
    }
    case "SaleSpinner_START": {
      return {
        ...state,
        storeSaleSpinner: true,
      };
    }
    case "getSale_SUCCESS": {
      return {
        ...state,
        SaleListData: action.payload.Record,
        storeSaleSpinner: false,
      };
    }

    case "show_quick_sale_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
      };
    }

    default:
      return state;
  }
};
