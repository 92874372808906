import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
const SalaryDetail = () => {
  const componentRef = useRef();
  const comProps = useSelector((state) => state.esReducer);
  const { salaryDetail } = comProps;

  const { createdAt, prefix, accountNo, storeName, name } =
    salaryDetail && salaryDetail.length > 0 ? salaryDetail[0] : "";

  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const columns = [
    {
        label:<IntlMessages id="receiptDetail.PaymentMode" />,
        key: "paymentMethod",
        _style: { width: "40%" },
      },
    {
        label:<IntlMessages id="receiptDetail.monthlySalary" />,
        key: "monthlySalary",
        _style: { width: "40%" },
      },
    {
        label:<IntlMessages id="receiptDetail.Arrear" />,
        key: "bonus",
        _style: { width: "40%" },
      },
    {
        label:<IntlMessages id="receiptDetail.TotalPayable" />,
        key: "TotalPayable",
        _style: { width: "40%" },
      },
    {
        label:<IntlMessages id="receiptDetail.notes" />,
        key: "notes",
        _style: { width: "40%" },
      },
  ];

  const renderPaymentDetail = () => {
    const paymentModeArray = salaryDetail ? salaryDetail : [];
    return (
        <TransactionType
    
        items={paymentModeArray}
        columns={columns}
       
        scopedColumns={{
          monthlySalary : (record)=>{
              return <td>{ChangeNumberFormate(parseFloat(record.monthlySalary).toFixed(2))}</td>
          },
          paymentMethod : (record)=>{
            return  <td  style={{
              textTransform: "capitalize",
            }}>{ <IntlMessages id={`${record.paymentMethod}.paymentMethod`} /> }</td>
        },
            TotalPayable: (record) => {
            return <td><span>{
              ChangeNumberFormate(parseFloat(record.monthlySalary).toFixed(2) + (parseFloat(record.bonus).toFixed(2) ))
              
              }</span></td>;
          },

        }}
      />
    );
  };

  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  return (
    <div>
      <div className="text-end">
        <ReactToPrint
          trigger={() => (
            <CButton color="info" className="text-white me-3">
              <CIcon icon={cilPrint} className="me-2" />
              <IntlMessages id="print" />
            </CButton>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5"
        ref={componentRef}
      >
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1
          style={{ color: "#012447", fontWeight: "bold" }}
          className=" mb-2"
        >
          <IntlMessages id="es.EmployeeLabel" />
        </h1>
        <p
          style={{ color: "#003366" }}
          className=" mb-5 font-weight-bold"
        >
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}><IntlMessages id="name" /></h4>
              <p style={{ color: "grey" }}>{name}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}> <IntlMessages id="type" /> </h4>
              <p style={{ color: "grey" }}> <IntlMessages id="salary" /></p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>  <IntlMessages id="Sr" /> </h4>
              <p style={{ color: "grey" }}>{prefix + "-" + accountNo}</p>
            </div>
          </div>
        </div>
        <br />
        <div className="d-flex  pt-4 mb-0">
          <h3>
            <IntlMessages id="receiptDetail.PaymentDetail" />:
          </h3>
        </div>
        <div className="w-100">{renderPaymentDetail()}</div>
        <InvoiceSignature data={{clientName : name}} />
      </div>
    </div>
  );
};

export default SalaryDetail;
