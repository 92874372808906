import React from "react";
import UpdateUserInfoForm from "./components/UpdateUserInfoForm";
//import { PhoneTwoTone } from '@ant-design/icons';
import jwt_decode from "jwt-decode";
import { useParams, useLocation } from "react-router-dom";
import qr from 'query-string'
const Index = () => {
  const param = useParams();
  const location = useLocation();
  let tokenObj = qr.parse(location.search)
  const userInfo = jwt_decode(tokenObj.token);
  if (tokenObj.token) {
    localStorage.setItem("token", tokenObj.token);
  }
  return (
    
     
       
        <UpdateUserInfoForm
          initialValues={{
            _id: userInfo._id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            companyName: userInfo.companyName,
            email: userInfo.email,
            phoneNumber: userInfo.phoneNumber,
          }}
        />
      
    
  );
};
export default Index;