const INIT_STATE = {
  BankBalanceSheet: [],
  StoreBalanceSheetData: [],
  SupplierBalanceSheetData: [],
  EmployeeBalanceSheet: [],
  Banks: [],
  Currency: [],
  Supplier: [],
  Store: [],
  SummeryBank: [],
  SummeryBankOriginal: [],
  StoreSummery: [],
  StoreSummeryOriginal: [],
  SupplierSummery: [],
  SupplierSummeryOriginal: [],
  SupplierProduct: [],
  CompleteSupplierProductResult: [],
  storeBalanceSummarySpinner: false,
  balanceSummerySupplierSpinner: false,
  bankBalanceSummerySpinner: false,
  storeProductSpinner: false,
  StoreProductSheetReport: [],
  MainResultStoreProductReport: [],
  bankBalanceSheetSpinner: false,
  storeBalanceSheetSpinner: false,
  supplierBalanceSheetSpinner: false,
  storeFetchSuccess: false,
  getAllStoreSpinner: false,
  supplierProductSpinner: false,
  gotAllSupplier: false,
  trustUserBalanceSummary: [],
  trustUserBalanceSummarySpinner: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "resetSupplierProductList": {
      return {
        ...state,
        SupplierProduct: [],
      };
    }

    case "getTrustBalanceSheetSpinner_START": {
      return {
        ...state,
        trustUserBalanceSummarySpinner: true,
      };
    }

    case "getTrustUserBalanceSheet_SUCCESS": {
      return {
        ...state,
        trustUserBalanceSummary: action.payload.Record,
        trustUserBalanceSummarySpinner: false,
      };
    }
    case "getTrustUserBalanceSheet_FAILURE": {
      return {
        ...state,
        trustUserBalanceSummarySpinner: false,
      };
    }

    case "fetchingStoreProductSheet_START": {
      return {
        ...state,
        storeProductSpinner: true,
      };
    }
    case "fetchingStoreProductSheet_STOP": {
      return {
        ...state,
        storeProductSpinner: false,
      };
    }
    case "storeProductSheet_SUCCESS": {
      return {
        ...state,
        StoreProductSheetReport: action.payload.Record,
        MainResultStoreProductReport: action.payload.Record,
        storeProductSpinner: false,
      };
    }

    case "search_by_category_store_product_report": {
      const result = searchByCategory(
        action.payload,
        state.MainResultStoreProductReport
      );
      return {
        ...state,
        StoreProductSheetReport: [...result],
      };
    }

    case "search_by_category": {
      const result = searchByCategory(
        action.payload,
        state.CompleteSupplierProductResult
      );
      return {
        ...state,
        SupplierProduct: [...result],
      };
    }

    case "search_store_by_product": {
      const pResult = searchByProduct(
        action.payload,
        state.MainResultStoreProductReport
      );
      return {
        ...state,
        StoreProductSheetReport: [...pResult],
      };
    }
    case "search_by_product": {
      const pResult = searchByProduct(
        action.payload,
        state.CompleteSupplierProductResult
      );
      return {
        ...state,
        SupplierProduct: [...pResult],
      };
    }

    case "getSupplierProduct_SUCCESS": {
      return {
        ...state,
        SupplierProduct: action.payload.Record,
        CompleteSupplierProductResult: action.payload.Record,
      };
    }

    case "supplierProductSpinner_START": {
      return { ...state, supplierProductSpinner: true };
    }
    case "supplierProductSpinner_STOP": {
      return { ...state, supplierProductSpinner: false };
    }

    case "SupplierSummeryByName": {
      const summeryResultSupplier = getSummeryResultSupplier(
        state.SupplierSummeryOriginal,
        action.payload
      );
      return {
        ...state,
        SupplierSummery: summeryResultSupplier,
      };
    }
    case "BalanceSummerySupplier_SUCCESS": {
      return {
        ...state,
        SupplierSummery: action.payload.Record,
        SupplierSummeryOriginal: action.payload.Record,
      };
    }
    case "balanceSummerySupplierSpinner_START": {
      return { ...state, balanceSummerySupplierSpinner: true };
    }
    case "balanceSummerySupplierSpinner_STOP": {
      return { ...state, balanceSummerySupplierSpinner: false };
    }

    case "StoreBalanceSheetSummeryByName": {
      let storeSearchResult = getStoreSummeryBySearch(
        state.StoreSummeryOriginal,
        action.payload
      );

      return {
        ...state,
        StoreSummery: storeSearchResult,
      };
    }

    case "StoreBalanceSummery_SUCCESS": {
      return {
        ...state,
        StoreSummery: action.payload.Record,
        StoreSummeryOriginal: action.payload.Record,
      };
    }
    case "storeBalanceSummarySpinner_START": {
      return { ...state, storeBalanceSummarySpinner: true };
    }
    case "storeBalanceSummarySpinner_STOP": {
      return { ...state, storeBalanceSummarySpinner: false };
    }
    case "SearchSummeryByName": {
      const SearchResult = getSummeryBank(
        state.SummeryBankOriginal,
        action.payload
      );
      return {
        ...state,
        SummeryBank: SearchResult,
      };
    }
    case "BankBalanceSummery_SUCCESS": {
      return {
        ...state,
        SummeryBank: action.payload.Record,
        SummeryBankOriginal: action.payload.Record,
      };
    }
    case "bankBalanceSummerySpinner_START": {
      return { ...state, bankBalanceSummerySpinner: true };
    }
    case "bankBalanceSummerySpinner_STOP": {
      return { ...state, bankBalanceSummerySpinner: false };
    }
    case "fetchingEmployeeBalanceSheet_START": {
      return { ...state };
    }
    case "employeeBalanceSheet_SUCCESS": {
      return { ...state, EmployeeBalanceSheet: action.payload.Record };
    }
    case "getAllStore_SUCCESS": {
      return {
        ...state,
        Store: action.payload.Record,
        storeFetchSuccess: true,
        storeProductSpinner: false,
      };
    }
    
    case "getAllStorestart_START": {
      return {
        ...state,
        getAllStoreSpinner: true,
        storeProductSpinner: true,
      };
    }
    case "getAllStorestart_STOP": {
      return {
        ...state,
        storeFetchSuccess: false,
        getAllStoreSpinner: false,
        storeProductSpinner: false,
      };
    }
    case "resetAllStore": {
      return {
        ...state,
        storeFetchSuccess: false,
        getAllStoreSpinner: false,
      };
    }
    case "emptyProduct": {
      return {
        ...state,
        StoreProductSheetReport: [],
      };
    }

    case "getAllSupplier_SUCCESS": {
      return {
        ...state,
        Supplier: action.payload.Record,
        gotAllSupplier: true,
      };
    }
    case "gettingAllSuppliers_START": {
      return { ...state, supplierProductSpinner: true };
    }
    case "gettingAllSuppliers_STOP": {
      return { ...state, supplierProductSpinner: false };
    }
    case "reset_gotAllSupplier": {
      return {
        ...state,
        gotAllSupplier: false,
      };
    }

    case "getAllCurrency_SUCCESS": {
      return { ...state, Currency: action.payload.Record };
    }
    case "getAllBank_SUCCESS": {
      return { ...state, Banks: action.payload.Record };
    }
    case "bankBalanceSheet_SUCCESS": {
      return { ...state, BankBalanceSheet: action.payload.Record };
    }
    case "fetchingBankBalanceSheet_START": {
      return {
        ...state,
        bankBalanceSheetSpinner: true,
      };
    }
    case "fetchingBankBalanceSheet_STOP": {
      return {
        ...state,
        bankBalanceSheetSpinner: false,
      };
    }
    case "storeBalanceSheet_SUCCESS": {
      return { ...state, StoreBalanceSheetData: action.payload.Record };
    }
    case "storeBalanceSheetSpinner_START": {
      return {
        ...state,
        storeBalanceSheetSpinner: true,
      };
    }
    case "storeBalanceSheetSpinner_STOP": {
      return {
        ...state,
        storeBalanceSheetSpinner: false,
      };
    }
    case "supplierBalanceSheet_SUCCESS": {
      return { ...state, SupplierBalanceSheetData: action.payload.Record };
    }
    case "supplierBalanceSheetSpinner_START": {
      return {
        ...state,
        supplierBalanceSheetSpinner: true,
      };
    }
    case "supplierBalanceSheetSpinner_STOP": {
      return {
        ...state,
        supplierBalanceSheetSpinner: false,
      };
    }
    case "removeTheResult": {
      return {
        ...state,
        BankBalanceSheet: [],
        StoreBalanceSheetData: [],
        SupplierBalanceSheetData: [],
        EmployeeBalanceSheet: [],
      };
    }
    default:
      return { ...state };
  }
};

const getSummeryResultSupplier = (OldArray, searchQuery) => {
  let result = [];
  if (searchQuery) {
    if (OldArray && OldArray.length > 0) {
      OldArray.map((Item) => {
        if (Item.supplierId === searchQuery) {
          result.push(Item);
        }
      });
    }
  } else {
    result = OldArray;
  }
  return result;
};
const getStoreSummeryBySearch = (OldArray, searchQuery) => {
  let result = [];
  if (searchQuery) {
    if (OldArray && OldArray.length > 0) {
      OldArray.map((Item) => {
        if (Item.storeId === searchQuery) {
          result.push(Item);
        }
      });
    }
  } else {
    result = OldArray;
  }
  return result;
};
const getSummeryBank = (OldArray, searchQuery) => {
  let result = [];
  if (searchQuery) {
    if (OldArray && OldArray.length > 0) {
      OldArray.map((Item) => {
        if (Item.Record._id === searchQuery) {
          result.push(Item);
        }
      });
    }
  } else {
    result = OldArray;
  }
  return result;
};

const searchByCategory = (categoryId, allResult) => {
  let result = [];
  if (allResult) {
    result = allResult.filter((Item) => Item.categoryId === categoryId);
  }
  return result;
};
const searchByProduct = (productId, allResult) => {
  let result = [];
  if (allResult) {
    result = allResult.filter((Item) => Item.productId === productId);
  }
  return result;
};
