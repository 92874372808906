const INIT_STATE = {

    invoiceCreatedSuccess: false,
    invoiceCreatedError: false,
    message: '',
    invoiceNumber: 0,
    creatingInvoiceLoader: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'creatingStoreSaleInvoice_START': {
            return {
                ...state,
                creatingInvoiceLoader: true
            }
        }

        case 'storeSaleInvoice_SUCCESS': {
            return { ...state, creatingInvoiceLoader: false, message: 'invoiceCreatedSuccess', invoiceCreatedSuccess: true, invoiceNumber: action.payload.Record && action.payload.Record[0].invoiceNumber }
        }
        case 'storeSaleInvoice_FAILURE': {
            return { ...state, creatingInvoiceLoader: false, message: 'invoiceError', invoiceCreatedError: true }
        }

        case 'updateState': {
            return {
                ...state,
                invoiceCreatedSuccess: false,
                invoiceCreatedError: false,
                message: ''
            }
        }
        default:
            return { ...state }
    }
}