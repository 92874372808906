import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import { useHistory, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { ChangeDateFormate } from "src/constants/CommonFn";
import SupplierBalanceSheetList from "./components/SupplierBalanceSheetList";
const Index = () => {
  const componentRef = useRef();
  const param = useLocation();
  const { filterDate } = param.state;

 

  let headerTitleAccountSummery = intlPlaceholder("report.supplier.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let startDate = moment().startOf("today").toString();
  let endDate = moment().toString();

  if (filterDate) {
    startDate = filterDate.startDate;
    endDate = filterDate.endDate;
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          {/* <div className="text-end mb-3">
            <ReactToPrint
              trigger={() => (
                <CButton
                color="info"
                className="text-white"
                >
                  
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="print" />
                </CButton>
              )}
              content={() => componentRef.current}
            />
          </div> */}
          <div  ref={componentRef}>
            <CCard className="mb-4">
              <CCardHeader className="d-flex justify-content-between align-items-center">
                <strong>
                  {`${headerTitleAccountSummery} ${
                    param.state.data.supplierName
                  }
                  EN ${
                    param.state.data.currencySymbole
                  } ${reportFrom} ${ChangeDateFormate(
                    startDate,
                    "DD-MM-YYYY"
                  )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`}
                </strong>
              </CCardHeader>
              <CCardBody>
                <div>
                  <DocsExample href="components/smart-table/">
                    <SupplierBalanceSheetList />
                  </DocsExample>
                </div>
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
