import React, { useEffect, useRef } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import { useLocation, withRouter } from "react-router-dom";
import moment from "moment";
import { ChangeDateFormate } from "src/constants/CommonFn";
import ViewStockStatusList from "./viewStockStatusList";

const ViewStockStatus = () => {
  let headerTitleAccountSummery = intlPlaceholder("report.stockStatus.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { filterValues } = useSelector((state) => state.customer);
  const blProps = useSelector((state) => state.balanceSheet);
  const param = useLocation();
  const { _id, productName } = param.state || {};
  const fetchBalanceSheet = () => {
    let startDate = moment().startOf("today").toString();
    let endDate = moment().endOf("year").toString();
    if (filterValues) {
      startDate = filterValues.startDate;
      endDate = filterValues.endDate;
    }
    dispatch(
      DataGetAction(
        "stockStatusSheet",
        {
          startDate: startDate,
          endDate: endDate,
          productId: _id,
        },
        "stockStatusSheetSpin"
      )
    );
  };

  useEffect(fetchBalanceSheet, [param]);

  let startDate = moment().startOf("year").toString();
  let endDate = moment().toString();
  if (filterValues) {
    startDate = filterValues.startDate;
    endDate = filterValues.endDate;
  }
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <div ref={componentRef}>
            <CCard className="mb-4">
              <CCardHeader className="d-flex justify-content-between align-items-center">
                <strong>
                  {`${headerTitleAccountSummery} ${productName}  ${reportFrom} ${ChangeDateFormate(
                    startDate,
                    "YYYY-MM-DD"
                  )} ${reportTo} ${ChangeDateFormate(endDate, "YYYY-MM-DD")}`}
                </strong>
              </CCardHeader>
              <CCardBody>
                <DocsExample href="components/smart-table/">
                  <ViewStockStatusList
                    Data={blProps.BankBalanceSheet}
                    Spinner={blProps.bankBalanceSheetSpinner}
                  />
                </DocsExample>
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default withRouter(ViewStockStatus);
