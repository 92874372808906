import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'src/redux/actions/http'
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import moment from "moment";
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import TrustFundIcon from '../../../assets/trustfund.png'

const StatisticOfCurrencyExchange = () => {


    const dispatch = useDispatch()
    const { currencyExchangeStatisticData } = useSelector(state => state.dashboard)

    const fetchTrustFundStatistics = () => {
        dispatch(DataGetAction('currencyExchangeStatistic', {
            startDate: moment().startOf("today").toString(),
            endDate: moment().toString(),
        }, 'StartSpinner'))
    }

    useEffect(fetchTrustFundStatistics, [])


    let columns = [
        {
            label: <IntlMessages id="currencyExchangeStatistic.given" />,
            key: "givenAmount",
            _customStyle: { width: "40%", background: '#038FDE', color: '#ffffff', textAlign: "center" },
            filter: false,
            sorter: false,
        },

        {
            label: <IntlMessages id="currencyExchangeStatistic.received" />,
            key: "receivedAmount",
            _customStyle: { width: "40%", background: '#038FDE', color: '#ffffff', borderLeftWidth: '1px', textAlign: "center" },
            filter: false,
            sorter: false,
        },



    ];


    return (
        <div style={{
            border: '1px solid #DE9503',
            borderRadius: 8,

        }}>
            <div style={{
                background: '#DE9503',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8
            }}>
                <i class="gx-icon-currency-exchange" style={{
                    flex: '0 0 2rem',
                    height: '1.25rem',
                    fontSize: '25px',
                    color: '#ffffff'

                }}></i>
                <span style={{
                    fontSize: 16,
                    marginLeft: 8,
                    fontWeight: 'bold',
                    color: '#ffffff'
                }}>
                    <IntlMessages id="CurrencyExchangesStatisticTitle" /></span></div>
            <CSmartTable
                sorterValue={{ column: "name", state: "asc" }}
                clickableRows
                tableProps={{
                    striped: true,
                    hover: true,
                }}
                activePage={3}
                loading={false}
                items={currencyExchangeStatisticData}
                columns={columns}
                itemsPerPageSelect={false}
                itemsPerPage={5}
                columnSorter
                pagination
                scopedColumns={{

                    givenAmount: (record) => {
                        return (
                            <>
                                <td style={{ textTransform: "capitalize", textAlign: "right" }}>
                                    {record && record.givenCurrencyName} {record && record.givenAmount ? ChangeNumberFormate(parseFloat(record.givenAmount).toFixed(2)) : 0}
                                </td>
                            </>
                        );
                    },
                    receivedAmount: (record) => {
                        return (
                            <>
                                <td style={{ textTransform: "capitalize", textAlign: "right" }}>
                                    {record && record.receivedCurrencyName} {record && record.receivedAmount ? ChangeNumberFormate(parseFloat(record.receivedAmount).toFixed(2)) : 0}
                                </td>
                            </>
                        );
                    },
                }}

            />
        </div>
    )
}

export default StatisticOfCurrencyExchange