import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import TransactionType from "../../../components/InvoiceTable";
import IntlMessages from "../../../util/IntlMessages";
import { ChangeDateFormate, ChangeNumberFormate } from "../../../constants/CommonFn";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {

  CButton,

} from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { notify } from "src/util/ShowToast";
import { useHistory } from "react-router";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const ViewExpenseDetail = () => {
    const componentRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();


    const comProps = useSelector(state => state.seReducer);
    const { Banks,refundStoreExpenseSuccess,refundStoreExpenseError,message } = comProps
    
    const {
        createdAt,
        purpose,
        prefix,
        type,
        amount,
        createdByUser,
        createdBy,
        storeName,
        store,
        expenseNo,
        expenseType,
        _id
    } = Banks && Banks.length > 0 ? Banks[0] : ''


    const expenseNoOld = Banks && Banks.length > 0 && Banks[0].oldExpenseNo && Banks[0].oldExpenseNo;
    const isRefund = Banks && Banks.length > 0 && Banks[0].refund && Banks[0].refund;

    const logoImage = localStorage.getItem('profileImage');
    const address = localStorage.getItem('address');

    const columns = [
        {
            label:  <IntlMessages id="receiptDetail.PaymentMode" />,
            key: "PaymentMode",
            _style: { width: "40%" },
          },
        {
            label: <IntlMessages id="receiptDetail.type" />,
            key: "type",
            _style: { width: "40%" },
          },
        {
            label:<IntlMessages id="receiptDetail.Amount" />,
            key: "Amount",
            _style: { width: "40%" },
          },
        {
            label: <IntlMessages id="receiptDetail.Status" />,
            key: "Status",
            _style: { width: "40%" },
          },
       
    ];




    const renderPaymentDetail = () => {
        const paymentModeArray = Banks ? Banks : []
        return  <TransactionType
        
        items={paymentModeArray}
        columns={columns}
        
        scopedColumns={{
            PaymentMode: (record) => {
            return <td><span style={{
                textTransform: 'capitalize'
            }}><IntlMessages id="cashEspeces.paymentMethod" /></span></td>;
          },
          
          type: (record) => {
            return <td><span style={{
                textTransform: 'capitalize'
            }}><IntlMessages id={type} /></span></td>;
          },
          Amount: (record) => {
            return <td><span>{ChangeNumberFormate(parseFloat(amount).toFixed(2))} (GNF)</span></td>;
          },
          Status: (record) => {
            return <td><span style={{
                textTransform: 'capitalize'
            }}><IntlMessages id="approved" /></span></td>;
          },
          
          
        }}
      />
    }
    

    if (refundStoreExpenseSuccess) {
      history.push("/storeExpenses");
      let messageLng = intlPlaceholder(message);
      notify("success", messageLng);
      dispatch({
        type: "se_UPDATE",
      });
    }
  
    if (refundStoreExpenseError) {
      let messageLng = intlPlaceholder(message);
      notify("error", messageLng);
      dispatch({
        type: "se_UPDATE",
      });
    }
  
    const areYouSureYouWantToRefundTheReceiptRumber = intlPlaceholder(
      "areYouSureYouWantToRefundTheReceiptRumber"
    );



  return (
    <div>
      <div className="d-flex justify-content-end text-end">
      {/* {isRefund &&   <CButton
            color="success"
            className="text-white me-3"
            
          >
                     <span><IntlMessages id="refunded" /></span>

          </CButton>} */}
          {/* receipt comment */}
     {/* {(expenseType && expenseType !== 'refundStoreExpanse'  && !isRefund) && <DeleteModal
            contentLangueId={`${areYouSureYouWantToRefundTheReceiptRumber} ${prefix}-${expenseNo}`}
            method="POST"
            type="refundStoreExpense"
            selectedItem={{ _id }}
            ButtonTitleId="receipt.refund"
            titleLanguageId="receipt.refund"
            size="md"
            color="success"
            variant=""
          />} */}
        <ReactToPrint
          trigger={() => (
            <CButton
                color="info"
                className="text-white me-3"
                >
        
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="print" />
                </CButton>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="print-invoice p-3 float-left w-100 pe-5 ps-5 " ref={componentRef}>
    

                <img src={logoImage} alt="" width="300" className="mb-2" />

                <p className="mb-4" style={{ color: 'grey', width: '200px', textTransform: 'capitalize' }}>{address}</p>
                <h1 style={{ color: '#012447', fontWeight: 'bold' }} className=" mb-2">
                  
                  {/* <IntlMessages id="ce.ExpenseLabel" /> */}
                  
                  { expenseType === 'refundStoreExpanse' ? `${intlPlaceholder('refundOfReceipt')} ${prefix}-${expenseNoOld} ` : <IntlMessages id="ce.ExpenseLabel" />}  
                </h1>
                


                <p style={{ color: '#003366' }} className=" mb-5 font-weight-bold">{ChangeDateFormate(createdAt)}</p>


                <div className="d-flex flex-row justify-content-between">

                    <div style={{ flex: 1 }} className="d-flex flex-column justify-content-between">
                    <div>
                            <h4 style={{ fontWeight: 'bold' }}> <IntlMessages id="receiptStoretoStore.srNo" /> </h4>
                            <p style={{ color: 'grey' }}>{prefix + "-" + expenseNo}</p>
                        </div>
                        <div>
                            <h4 style={{ fontWeight: 'bold' }}><IntlMessages id="receiptStoretoStore.store" /></h4>
                            <p style={{ color: 'grey' }}>{storeName && storeName}</p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }} className="d-flex flex-column justify-content-between">
                    <div>
                            <h4 style={{ fontWeight: 'bold' }}><IntlMessages id="receiptStoretoStore.type" /></h4>
                        {type &&    <p style={{ color: 'grey' }}>{intlPlaceholder(type)}</p>}
                        </div>
                        
                        <div>
                            <h4 style={{ fontWeight: 'bold' }}><IntlMessages id="receiptStoretoStore.createdBy" /> </h4>
                            <p style={{ color: 'grey' }}>{createdBy && createdBy}</p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }} className="d-flex flex-column justify-content-between">
                        <div>
                            <h4 style={{ fontWeight: 'bold' }}> <IntlMessages id="receiptStoretoStore.purpose" /> </h4>
                            <p style={{ color: 'grey' }}>{purpose}</p>
                        </div>

                    </div>
                </div>
                <br />
                {/* <div className="d-flex mt-4 pt-4 mb-0">
                    <h3><IntlMessages id="receiptDetail.PaymentDetail" />:</h3>
                </div> */}
                <div className="w-100">
                    {renderPaymentDetail()}
                </div>

                <InvoiceSignature data={{clientName : storeName}}/>
            </div>
    </div>
  );
};

export default ViewExpenseDetail;
