import React from "react";
import CSmartTable from "../../components/CSSmartTable";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";

const ViewStockStatusList = () => {
  const { stockStatusSheetList, stockStatusSheetSpin } = useSelector(
    (state) => state.customer
  );
  const columns = [
    {
      label: <IntlMessages id="stockStatus.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="stockStatus.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockStatus.Ref" />,
      key: "prefix",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockStatus.out" />,
      key: "outStock",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockStatus.in" />,
      key: "inStock",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockStatus.stock" />,
      key: "stock",
      _style: { width: "40%" },
    },
  ];

  return (
    <>
      <CSmartTable
        loading={stockStatusSheetSpin}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={stockStatusSheetSpin ? [] : stockStatusSheetList}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={500}
        columnSorter
        pagination
        underTableSlot={<>Total: {"sfds"}</>}
        scopedColumns={{
          description: (record) => {
            return (
              <td>
                {record && record.description ? (
                  <IntlMessages id={record.description} />
                ) : (
                  "n/a"
                )}
              </td>
            );
          },
          createdAt: (record) => {
            return <td>{ChangeDateFormate(record.createdAt)}</td>;
          },
          prefix: (record) => {
            if (record.reference === "0000") {
              return <td>----</td>;
            } else {
              return <td>{record.prefix}</td>;
            }
          },
          outStock: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.outStock
                    ? record.outStock.toFixed(3)
                    : parseFloat(0).toFixed(3)
                )}
              </td>
            );
          },
          inStock: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.inStock
                    ? record.inStock.toFixed(3)
                    : parseFloat(0).toFixed(3)
                )}
              </td>
            );
          },
          stock: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.stock
                    ? parseFloat(record.stock).toFixed(3)
                    : parseFloat(0).toFixed(3)
                )}
              </td>
            );
          },
        }}
      />
    </>
  );
};

export default ViewStockStatusList;
