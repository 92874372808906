import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";

import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cilRestaurant } from "@coreui/icons";
import { notify } from "../../../util/ShowToast";
import NumberFormat from "react-number-format";
const CompanyExpenseForm = () => {
  const modalType = "show_ce_modal";
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.ceReducer);
  const receiptProps = useSelector((state) => state.receiptReducer);
  const { CompanyAccount } = receiptProps;
  const [transType, setTransactionType] = useState();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(
      <IntlMessages id="ce.addExpenseModal.pleaseSelectAType_message" />
    ),
    paymentMethod: Yup.string().required(
      <IntlMessages id="ce.addExpenseModal.choosePaymentMethod_message" />
    ),

    bank: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="ce.addExpenseModal.chooseBank_message" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    transactionType: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="ce.addExpenseModal.transactionType_message" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    chequeOrTransactionNo: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        `${
          transType === "online"
          ? intlPlaceholder("transactionRequired")
          : intlPlaceholder("chequeRequired")
        }`
      ),
      otherwise: Yup.string().notRequired(),
    }),

    amount: Yup.string().required(
      <IntlMessages id="ce.addExpenseModal.amount_message" />
    ),
    purpose: Yup.string().required(
      <IntlMessages id="ce.addExpenseModal.purpose_message" />
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;
  //   if (initialValues !== null) {
  //     reset(initialValues);
  //   } else {
  //     setValue({ name: "", address: "" });
  //   }

  const onCreate = (values) => {

    if (values.bank) {
      let selectedBank = CompanyAccount.filter(
        (Item) => Item._id === values.bank
      );
      values.balanceId = selectedBank[0].balanceId;
      values.bankName = selectedBank[0].bankName;
    }

    if (comProps.selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "addExpense", values, "", "ce", false)
      );
    } else {
      values._id = comProps.selectedItem._id;
      dispatch(
        DataRequestAction("PUT", "editExpense", values, "", "ce", false)
      );
    }
  };

  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("success", messageLng);
    reset();
    // message.success(alerts.record_updated_success);
    dispatch({
      type: "ce_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("error", messageLng);
    reset();
    dispatch({
      type: "ce_UPDATE",
    });
  }
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const paymentMethodWatch = useWatch({
    control,
    name: "paymentMethod",
    defaultValue: "cash", // default value before the render
  });
  const transactionTypeWatch = useWatch({
    control,
    name: "transactionType",
    defaultValue: "online", // default value before the render
  });
  return (
    <div>
      <CButton
        color="success"
        className="text-white"
        onClick={() => showModal(null)}
      >
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="ce.add.ce.button" />
      </CButton>

      <CModal visible={comProps.modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("ce.addExpenseModal.addComapnyExpense")}
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="type"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.type ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder(
                            "ce.addExpenseModal.pleaseSelectAType"
                          )}
                        </option>
                        <option value="construction">
                          {intlPlaceholder("Construction")}{" "}
                        </option>
                        <option value="divers">
                          {intlPlaceholder("Diver")}
                        </option>
                        <option value="office_Operating_Cost">
                          {intlPlaceholder("FonctionnementBureaux")}
                        </option>                       
                        <option value="manutention">
                          {intlPlaceholder("Manutention")}
                        </option>
                        <option value="salaire">
                          {intlPlaceholder("salaries")}
                        </option>
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                    {intlPlaceholder("ce.addExpenseModal.pleaseSelectAType")}
                  </CFormLabel>
                  <div className="invalid-feedback">{errors.type?.message}</div>
                </CFormFloating>
              </div>
              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.paymentMethod ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder(
                            "ce.addExpenseModal.choosePaymentMethod"
                          )}
                        </option>
                        <option value="cash">{intlPlaceholder("cash")}</option>
                        {CompanyAccount && CompanyAccount.length > 0 && (
                          <option value="bank">
                            {intlPlaceholder("bankTransfer")}
                          </option>
                        )}
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                    {intlPlaceholder("ce.addExpenseModal.choosePaymentMethod")}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.paymentMethod?.message}
                  </div>
                </CFormFloating>
              </div>

              {paymentMethodWatch === "bank" && (
                <>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="bank"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={errors.bank ? "is-invalid" : ""}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder("ce.addExpenseModal.chooseBank")}
                            </option>
                            {CompanyAccount &&
                              CompanyAccount.map((Item) => (
                                <option value={Item._id}>
                                  {Item.bankName}({Item.number})
                                </option>
                              ))}
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                        {intlPlaceholder("ce.addExpenseModal.chooseBank")}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.bank?.message}
                      </div>
                    </CFormFloating>
                  </div>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="transactionType"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.transactionType ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              setTransactionType(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder(
                                "ce.addExpenseModal.transactionType"
                              )}
                            </option>
                            <option value="online">
                              {intlPlaceholder("Online")}{" "}
                            </option>
                            <option value="cheque">
                              {intlPlaceholder("Cheque")}
                            </option>
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                        {intlPlaceholder("ce.addExpenseModal.transactionType")}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.transactionType?.message}
                      </div>
                    </CFormFloating>
                  </div>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <CFormInput
                        className={
                          errors.chequeOrTransactionNo ? "is-invalid" : ""
                        }
                        {...register("chequeOrTransactionNo")}
                        placeholder={`${
                          transactionTypeWatch === "online"
                          ? intlPlaceholder("transactionNumber")
                          : intlPlaceholder("chequeNumber")
                        } ${intlPlaceholder("Number")}`}
                        type="text"
                        id="chequeOrTransactionNo"
                      />
                      <CFormLabel htmlFor="chequeOrTransactionNo">
                        {intlPlaceholder(
                          `${
                            transactionTypeWatch === "online"
                            ? intlPlaceholder("transactionNumber")
                            : intlPlaceholder("chequeNumber")
                          } ${intlPlaceholder("Number")}`
                        )}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.chequeOrTransactionNo?.message}
                      </div>
                    </CFormFloating>
                  </div>
                </>
              )}
              <div className="col-md-12">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.amount ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder(
                          "ce.addExpenseModal.amount"
                        )}
                      />
                    )}
                    name={"amount"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amount?.message}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <CFormTextarea
                      rows="4"
                      className={errors.purpose ? "is-invalid" : ""}
                      {...register("purpose")}
                      placeholder={intlPlaceholder(
                        "ce.addExpenseModal.purpose"
                      )}
                      type="purpose"
                      id="address"
                    />
                    <CFormLabel htmlFor="address">
                      {intlPlaceholder("ce.addExpenseModal.purpose")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.purpose?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="admin.addAdminModal.cancel" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.ceLoader}
              disabled={comProps.ceLoader}
            >
              {intlPlaceholder("ce.addExpenseModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default CompanyExpenseForm;
