import React, { useState } from 'react'
import { CBadge, CButton, CCardBody, CCollapse, CSmartTable, } from '@coreui/react-pro'
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CSmartTableCustom from '../../../components/CSSmartTable'
import DeleteModal from '../../../components/Modals/DeleteModal'
import CheckUserAccess from "../../../util/checkUserAccess";
const SmartTableBasicExample = ({ data,unitSpinner }) => {
    const [details, setDetails] = useState([])
    const history = useHistory();
    const dispatch = useDispatch();

    let columns = [
        {
            label: <IntlMessages id="unit.name"/>,
            key: 'name',
            _style: { width: '40%' },
        },
        {
            label: <IntlMessages id="unit.subUnitName"/>,
            key: 'subUnitName',
            _style: { width: '40%' },
            columnSorter: false,
        },
        {
            label: <IntlMessages id="unit.createdBy"/>,
            key: 'createdBy',
            _style: { width: '40%' },
        },
        ,
    ]


  const found =CheckUserAccess('supplier',['edit','delete']) 

  if(found){
    columns.push({
        key: 'show_details',
        label: '',
        _style: { width: '1%' },
        filter: false,
        sorter: false,
    })
  }


    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    const editRecord = (data) => {
        dispatch({
            type: 'edit_unit_record',
            payload: data
        })
        history.push('/editUnit')
    }

    const edit = CheckUserAccess('units',['edit']) 
    const deleted = CheckUserAccess('units',['delete'])
    return (
        <>
            <CSmartTableCustom
                sorterValue={{ column: 'name', state: 'asc' }}
                clickableRows
                tableProps={{
                    striped: true,
                    hover: true,
                }}
                activePage={3}
                loading={unitSpinner}
                items={unitSpinner ? [] : data}
                columns={columns}
                columnFilter
                tableFilter
                cleaner
                itemsPerPageSelect
                itemsPerPage={100}
                columnSorter
                pagination
                scopedColumns={{
                    show_details: (item) => {
                        return (
                            <td className="py-2">
                                <CButton
                                    color="primary"
                                    variant="outline"
                                    shape="square"
                                    size="sm"
                                    onClick={() => {
                                        toggleDetails(item._id)
                                    }}
                                >
                                    {details.includes(item._id) ?  <IntlMessages id="hide" /> :   <IntlMessages id="show" />}
                                </CButton>
                            </td>
                        )
                    },
                    details: (record) => {
                        
                        return <CCollapse visible={details.includes(record._id)}>
                            <CCardBody>
                            {edit&& <CButton className="me-3" size="sm"  color="primary" onClick={() => editRecord(record)}>
                                    <span style={{ color: 'white' }}>
                                        <IntlMessages id="Edit" /></span>
                                </CButton>}
                             {deleted &&   <DeleteModal
                                    contentLangueId='DeleteUnit'
                                    size="sm"
                                    type="deleteUnit"
                                    selectedItem={record}
                                    ButtonTitleId="Delete"
                                    titleLanguageId="unit.deleteModal.message"
                                />
}

                            </CCardBody>
                        </CCollapse>

                    },
                }}

            />
        </>
    )
}

export default SmartTableBasicExample
