const INIT_STATE = {
  localProducts: [],
  quotationsList: [],
  getQuotationsSpin: false,
  initialData: null,
  quotationModal: false,
  quotationModalApiData: null,
  quotationDetailSpin: false,
  quotationSuccess: false,
  editQuotationRecordSuccess: false,
  editQuotationRecord: null,
  editQuotationSuccess:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "editQuotation_SUCCESS" :{
      return {
        ...state,
        editQuotationSuccess: true,
        editQuotationSpin: false,
        initialData: action.payload.Record,
        quotationModal: true,
      }
    }
    case "editQuotationFormSpin_START": {
      return {
        ...state,
        editQuotationSpin: true,
      };
    }

    case "getEditQuotation_SUCCESS": {
      return {
        ...state,
        editQuotationRecord: action.payload.Record,
        localProducts: action.payload.Record.products,
        editQuotationRecordSpin: false,
        editQuotationRecordSuccess: true,
      };
    }
    case "editQuotationSpin_START": {
      return {
        ...state,
        editQuotationRecordSpin: true,
      };
    }
    case "editQuotationReset": {
      return {
        ...state,
        editQuotationRecordSuccess: false,
      };
    }
    case "addQuotation_SUCCESS": {
      return {
        ...state,
        quotationSuccess: true,
        quotationAddSpin: false,
        initialData: action.payload.Record,
        quotationModal: true,
      };
    }
    case "quotationReset": {
      return {
        ...state,
        quotationSuccess: false,
        editQuotationSuccess:false
      };
    }
    case "addQuotationSpin_START": {
      return {
        ...state,
        quotationAddSpin: true,
      };
    }
    case "getQuotationDetail_SUCCESS": {
      return {
        ...state,
        quotationModalApiData: action.payload.Record,
        quotationDetailSpin: false,
      };
    }
    case "getQuotationDetailSpin_START": {
      return {
        ...state,
        quotationDetailSpin: true,
      };
    }
    case "getAllQuotation_SUCCESS": {
      return {
        ...state,
        quotationsList: action.payload.Record,
        getQuotationsSpin: false,
      };
    }
    case "getQuotationsSpin_START": {
      return {
        ...state,
        getQuotationsSpin: true,
      };
    }
    case "Show_quotation_View_Modal": {
      return {
        ...state,
        quotationModal: true,
        initialData: action.payload,
      };
    }
    case "Hide_quotation_View_Modal": {
      return {
        ...state,
        quotationModal: false,
        initialData: null,
      };
    }
    case "setQuotationLocalProduct": {
      const result = getLocalProducts(action.payload, state.localProducts, action.ProductList);
      console.log("=====[...result]", [...result]);
      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "updateQuatationLocalproducts": {
      const result = getUpdatedLocalProducts(action.payload, state.localProducts);
      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "quotationDeleteLocalProduct": {
      const updatedProducts = deleteLocalProduct(state.localProducts, action.payload);

      return {
        ...state,
        localProducts: [...updatedProducts],
      };
    }
    case "RESET_Quotation_LOCAL_PRODUCT": {
      return {
        localProducts: [],
      };
    }
    default:
      return state;
  }
};
function deleteLocalProduct(oldRecord, deletedRecord) {
  const newRecord = oldRecord.filter((Item) => {
    if (Item._id !== deletedRecord._id) {
      return Item;
    }
  });
  return [...newRecord];
}
const findUniqueArray = (arr1, arr2) => {
  let result = [];
  let indexFound = [];
  arr1.map((item, index) => {
    let d = arr2.findIndex((items) => items === item);
    if (d >= 0) {
      indexFound.push(d);
    }
  });
  for (let i = 0; i !== arr2.length; i++) {
    let d = indexFound.filter((items) => items === i);
    if (d.length < 1) {
      result.push(arr2[i]);
    } else {
      console.log("eeee", indexFound[i]);
    }
  }
  return result;
};
const getUpdatedLocalProducts = (payload, localProducts) => {
  let updatedResult = [];
  let { fieldChanged, updatedRow, newValue } = payload;
  if (fieldChanged === "Qty") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(newValue) * parseFloat(item.price),
          Qty: parseFloat(newValue),
          oldQty: item.Qty,
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "price") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(item.Qty) * parseFloat(newValue),
          price: parseFloat(newValue),
        };
      } else {
        return item;
      }
    });
  }
  return [...updatedResult];
};
const getLocalProducts = (payload, localProducts, apiProducts) => {
  let { product: productIds } = payload;
  let prevSelectedIds = localProducts && localProducts.length > 0 ? localProducts.map((item) => item._id) : [];

  let originalProducts = JSON.parse(JSON.stringify(apiProducts));

  let uniqueIds = findUniqueArray(prevSelectedIds, productIds); // [1,2]
  let filterAndModifiData = originalProducts.filter((item) => {
    if (uniqueIds.includes(item._id)) {
      item.totalPrice = parseFloat(item.Qty) * parseFloat(item.price);
      return item;
    }
  });
  let uniqueResult = [];
  filterAndModifiData.map((item) => {
    let obj = {
      _id: item._id,
      Qty: item.Qty,
      productName: item.name,
      unitName: item.unitName,
      price: parseFloat(item.price),
      totalPrice: item.totalPrice,
      sockQty: item.sockQty,
    };
    uniqueResult.push(obj);
  });
  let updatedArray = [];
  if (localProducts && localProducts.length > 0) {
    updatedArray = localProducts.filter((item) => {
      if (productIds.includes(item._id)) {
        item.Qty = parseFloat(item.Qty) + 1;
        item.totalPrice = parseFloat(item.Qty) * parseFloat(item.price);
        console.log("===== return here 1 =====", item);
        return item;
      } else {
        return item;
      }
    });
  }
  return [...updatedArray, ...uniqueResult];
};
