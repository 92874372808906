import React, { useState } from 'react'
import {
    CButton,

    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CLoadingButton
} from '@coreui/react-pro'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder"
import { useDispatch, useSelector } from 'react-redux'
import { DataEntryModalAction } from '../../../redux/actions/Common'
import AddForm from './AddForm'
import {notify} from '../../../util/ShowToast'
const AddSupplierModal = () => {



    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState()
    const [defaultValue, setDefaultValue] = useState(null)


    const comProps = useSelector((state) => state.supplier);

    const { supplierLoader, selectedItem, editModalVisible, success } = comProps;





    const modalType = "edit_supplier_modal";
    const showModal = (Item) => {
        dispatch({
            type: modalType,
            payload: Item,
        });
    }


    return (
        <div>
           
            <CModal visible={editModalVisible} >
                <CModalHeader onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}>
                    <CModalTitle>{intlPlaceholder('supplier.addSupplierModal.editSupplier')}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <AddForm defaultValues={selectedItem} ButtonComponent={
                        <CModalFooter>
                            <CButton color="secondary" onClick={() => dispatch(DataEntryModalAction(modalType, null))}>
                                <IntlMessages id="supplier.addSupplierModal.cancel" />
                            </CButton>

                            <CLoadingButton type="submit" color="primary" loading={supplierLoader} disabled={supplierLoader}>
                                {selectedItem === null ? intlPlaceholder('supplier.addSupplierModal.save') : intlPlaceholder('supplier.addSupplierModal.save')}
                            </CLoadingButton>

                        </CModalFooter>
                    } />
                </CModalBody>


            </CModal>
        </div>
    )
}

export default AddSupplierModal




