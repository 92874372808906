import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './redux/reducers'
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middleware = [thunk, routeMiddleware];


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'Setting'],
  stateReconciler: hardSet,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const store = createStore(
  persistedReducer, // root reducer with router state
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      ...middleware
    ),
  ),
);

export const persisStore = persistStore(store)
export default store

