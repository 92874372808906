import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import StoreProductReportList from "./components/StoreProductReportList";
import StoreProductFilter from "./components/StoreProductFilter";
import { useSelector } from "react-redux";
import { NumberWithCommas, ChangeDateFormate } from "src/constants/CommonFn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import CheckUserAccess from "../../util/checkUserAccess";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {


    const blProps = useSelector(state => state.balanceSheet);
    const authProps = useSelector(state => state.auth);
    const { LogeInUser } = authProps;
    const [Filter, setFilter] = useState(null)
    const { StoreProductSheetReport } = blProps;


    let headerTitleStoreProductSummery = intlPlaceholder('report.store.product')
    let reportFrom = intlPlaceholder('report.from');
    let reportTo = intlPlaceholder('report.to');


    let bodyData = [[
        { text: intlPlaceholder('Date'), style: 'tableHeader' },
        { text: intlPlaceholder('Product'), style: 'tableHeader' },
        { text: intlPlaceholder('Category'), style: 'tableHeader' },
        { text: intlPlaceholder('storeProduct.unit'), style: 'tableHeader' },
        { text: intlPlaceholder('Quantity'), style: 'tableHeader' },
        { text: intlPlaceholder('storeProduct.total'), style: 'tableHeader' }
    ]];
    let TotalLabel = intlPlaceholder('Total')
    let EmailLabel = intlPlaceholder('Email')
    let CellLabel = intlPlaceholder('Cell')
    
    const downloadPDF = async (data) => {


        let Record = data.reverse();
        let startDate = moment().startOf("year").toString();
        let endDate = moment().toString();
        if (Filter.startDate && Filter.endDate) {
          startDate =Filter.startDate;
          endDate = Filter.endDate;
        }
       

        let TotalAmount = 0;
        let totalQuantity = 0;

        Record.forEach((Item, index) => {

            let date = [
              {
                text: ChangeDateFormate(Item.createdAt),
                alignment: "center"
              }
            ];

            let amount = (parseFloat(Item.amount)).toFixed(2)
            let quantity = parseFloat(Item.qty)

            TotalAmount = parseFloat(TotalAmount) + parseFloat(amount)
            totalQuantity = parseFloat(totalQuantity) + quantity
            amount = NumberWithCommas(amount);

            let objArr = [
                date
            ]

            let productName = [
              {
                text: `${Item.productName}`,
                alignment: "left"
              }
            ]

            objArr.push(productName)
            let prodCategory = [
              {
                text: Item.categoryName,
                alignment: "center"
              }
            ];
            let unitName = [
              {
                text: Item.unitName,
                alignment: "center"
              }
            ];
            objArr.push(prodCategory);
            objArr.push(unitName);
            objArr.push(Item.qty.toFixed(3));
            objArr.push(amount)


            bodyData = [...bodyData, objArr];
        })


        TotalAmount = NumberWithCommas(TotalAmount.toFixed(2));
        totalQuantity = NumberWithCommas(totalQuantity.toFixed(3));

       
        let letTotal = [
            { text: TotalLabel, style: 'tableHeader', colSpan: 4, alignment: 'center' },
            {},
            {},
            {},
            {text: totalQuantity, style: 'tableBody', bold: true},
            { text: TotalAmount, style: 'tableBody', bold: true },
        ]
        bodyData = [...bodyData, letTotal]

        let headerTitle = `${headerTitleStoreProductSummery.toUpperCase()} ${Filter.storeName.toUpperCase()} ${reportFrom} ${ChangeDateFormate(startDate, 'DD-MM-YYYY')} ${reportTo} ${ChangeDateFormate(endDate, 'DD-MM-YYYY')}`;
         const logoImage = localStorage.getItem('image') ?  localStorage.getItem('image') : process.env.REACT_APP_LOGO_DUMMY;;

        let footerAddress = localStorage.getItem('address');
        
        let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
        let docDefinition = {
          pageOrientation: 'landscape',
          content: [
            {
              image: `data:image/jpeg;base64,${logoImage}`,
              fit: [250, 250],
              width: 150,
              height: 150,
            },

              {
                  text: headerTitle,
                  margin: [15, 10],
                  style: { fontSize: 22, bold: true, alignment: 'center' }
              },
              {
                  style: 'tableBody',
                  headerRows: 1,

                  table: {
                      widths: [80, '*', 80, 80, 80, 110],
                      body: bodyData
                  }
              },

          ],
          footer: {
              text: [{
                  text: footerAddress,
                  alignment: 'center',
                  fontSize: 10,
                  width: '100%',
              },
              {
                  text: `\n ${footerAddress2}`,
                  alignment: 'center',
                  fontSize: 10,
                  width: '100%'
              }
              ]
          },

          styles: {
              header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                  textAlign: 'center'
              },
              tableExample: {
                  margin: [0, 5, 0, 15],
                  width: '100%'
              },
              tableOpacityExample: {
                  margin: [0, 5, 0, 15],
                  fillColor: 'blue',
                  fillOpacity: 0.3
              },
              tableHeader: {
                  bold: true,
                  fontSize: 14,
                  color: 'black',
                  alignment: "center"
              },
              tableBody: {
                bold: false,
                fontSize: 10,
                alignment: "right"
              },
          }
        }
        pdfMake.createPdf(docDefinition).download('storeProductReport.pdf');

    }

    const pdfAccess = CheckUserAccess("customerOrderHistory", ["pdf"]) 

  return (
    <>
      <CRow>
        <CCol xs={12}>
          
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="storeProduct.title" />
              </h1>

            
            </CCardHeader>
            <CCardBody>
            <div className="d-flex align-items-center">
              <StoreProductFilter setFilters={setFilter} />
            {pdfAccess&&  <CButton
                  color="danger"
                  className="text-white"
                  onClick={() => downloadPDF(StoreProductSheetReport)}
                >
                   <i className="gx-icon-pdf-300 me-1"></i>

                 <IntlMessages id="pdf.generate.button" />
                </CButton>}
            </div>
              <DocsExample href="components/smart-table/">
                <StoreProductReportList />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
