import React, { useEffect, useState } from 'react'
import {
    CForm,
    CFormInput, CFormFloating, CFormLabel, CFormSelect
} from '@coreui/react-pro'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder"
import { DataGetAction, DataRequestAction, updateAllState } from '../../../redux/actions/http'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import { notify } from '../../../util/ShowToast'

const AddForm = ({ defaultValues, ButtonComponent }) => {

    const comProps = useSelector((state) => state.supplier);
    const { Currencies } = useSelector((state) => state.currencyReducer);
    
    const { success } = comProps;

    const dispatch = useDispatch();


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(<IntlMessages id="supplier.addSupplierModal.name_message"/>),
        // address: Yup.string()
        //     .required(<IntlMessages id="supplier.addSupplierModal.address_message"/>),
        // email: Yup.string()
        //     .required(<IntlMessages id="supplier.addSupplierModal.email_message"/>).email(),
        currencyId: Yup.string()
            .required(<IntlMessages id="supplier.selectCurrency_message" />),
        // phoneNumber: Yup.string()
        //     .required(<IntlMessages id="supplier.addSupplierModal.phonenNumber_message"/>),
    });

    const formOptions = { resolver: yupResolver(validationSchema), defaultValues };


    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
    const { errors } = formState;

    const onFinish = values => {
        if (defaultValues === null) {
            dispatch(DataRequestAction('POST', 'addSupplier', values, '', 'supplier', false));
        } else {
            values._id = defaultValues._id;
            dispatch(DataRequestAction('PUT', 'editSupplier', values, '', 'supplier', false));
        }

    };


    // form validation rules 
    if (success) {
        let message = intlPlaceholder(comProps.message)
        notify('success', message)
        reset()
        dispatch({
            type: 'supplier_UPDATE',
        });

    }
    if (comProps.error) {
        let message = intlPlaceholder(comProps.message)
        notify('error', message)

        dispatch({
            type: 'supplier_UPDATE',
        });

    }

    return (
        <CForm
            onSubmit={handleSubmit(onFinish)}
        >
            <div className="mb-3">
                <CFormFloating className="mb-3">
                    <CFormInput className={errors.name ? 'is-invalid' : ''} {...register("name")}
                        placeholder={intlPlaceholder("supplier.addSupplierModal.name")} type="text" id="name" />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                    <CFormLabel htmlFor="name">
                        {intlPlaceholder("supplier.addSupplierModal.name")}
                    </CFormLabel>

                </CFormFloating>
            </div>

            <div className="mb-3">
            <Controller
                control={control}
                defaultValues={defaultValues && defaultValues.phoneNumber}
                name={'phoneNumber'}
                render={(props) => {
                    return (
                        <PhoneInput
                            country={'gn'}
                            value={defaultValues && defaultValues.phoneNumber}
                            onChange={(v) => props.field.onChange(v)}
                            inputProps={{
                                name: 'phoneNumber',
                                required: false,
                            }}
                        />
                    );
                }}
            />
            <div className="error-message">{errors.phoneNumber?.message}</div>
            </div>
            

            <div className="mb-3">
                <CFormFloating className="mb-3">
                    <CFormInput className={errors.email ? 'is-invalid' : ''} {...register("email")}
                        placeholder={intlPlaceholder("supplier.addSupplierModal.email")} type="text" id="email" />

                    <div className="invalid-feedback">{errors.email?.message}</div>
                    <CFormLabel htmlFor="email">
                        {intlPlaceholder("supplier.addSupplierModal.email")}
                    </CFormLabel>
                </CFormFloating>
            </div>

            <div className="mb-3">
                <CFormFloating className="mb-3">
                    <CFormInput className={errors.address ? 'is-invalid' : ''} {...register("address")}
                        placeholder={intlPlaceholder("supplier.addSupplierModal.address")}
                        type="text" id="address" />

                    <div className="invalid-feedback">{errors.address?.message}</div>
                    <CFormLabel htmlFor="address">
                        {intlPlaceholder("supplier.addSupplierModal.address")}
                    </CFormLabel>
                </CFormFloating>
            </div>
            
            <div className="mb-3">
            <CFormSelect
              {...register("currencyId")}
              className={errors.currencyId ? "is-invalid" : ""}
            >
              <option value="" hidden>
                {intlPlaceholder("supplier.selectCurrency_message")}
              </option>
              {Currencies &&
                Currencies.map((Item) => {
                  return (
                    <option value={Item._id}>
                      {Item.symbol} ({Item.name})
                    </option>
                  );
                })}
            </CFormSelect>
            <div className="invalid-feedback">{errors.currencyId?.message}</div>
            </div>
            {ButtonComponent}
        </CForm>
    )
}

export default AddForm
