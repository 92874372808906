import React, { useEffect } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react-pro'
import DocsExample from '../../components/DocsExample'
import IntlMessages from '../../util/IntlMessages'
import SupplierTable from './components/SupplierTable'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction, DataRequestAction } from '../../redux/actions/http'
import AddSupplierModal from './components/AddSupplierModal'
import EditSupplierModal from './components/EditSupplierModal'
import BulkImportSupplier from './components/BulkImportSupplier'
import CheckUserAccess from '../../util/checkUserAccess'
import { intlPlaceholder } from 'src/util/intlPlaceholder'
import { notify } from 'src/util/ShowToast'
const SmartTable = () => {
  const dispatch = useDispatch()
  const comProps = useSelector((state) => state.supplier)

  const { supplierSpinner, Suppliers, supplierBulkUpload_success, supplierBulkUpload_error,textMessage } = comProps;

  const fetchCategory = () => {
    dispatch(
      DataGetAction("getAllSupplier", { query: "all" }, "supplierSpinner")      
    );
    dispatch(DataGetAction('getAllCurrency', { query: "all" }));
  };
  useEffect(fetchCategory, []);
  const create = CheckUserAccess('supplier',['create']) 


  


if(supplierBulkUpload_success){
  let messageLng =  intlPlaceholder(textMessage)
  fetchCategory()
  notify('success',messageLng)
  dispatch({
    type : 'supplier_UPDATE'
  })
}
if(supplierBulkUpload_error){
 
  let messageLng =  intlPlaceholder(textMessage)
  notify('error',messageLng)
  dispatch({
    type : 'supplier_UPDATE'
  })
}
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="supplier.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex" style={{ gap: 10 }}>
                {create && <AddSupplierModal />}
                <BulkImportSupplier />
              </div>

              <DocsExample href="components/smart-table/">
                <SupplierTable Suppliers={Suppliers} supplierSpinner={supplierSpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <EditSupplierModal />
    </>
  )
}

export default SmartTable
