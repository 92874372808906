import React, { useState, useEffect } from "react";
import {
  CButton,
  CModalBody,
  CModalFooter,
  CLoadingButton,
  CForm,
  CSpinner,
} from "@coreui/react-pro";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction, DataRequestAction } from "src/redux/actions/http";
import { notify } from "../../../util/ShowToast";
import InvoiceCompanyStoreProductForm from "./invoice-company-store-product-form";
import InvoiceCompanyStoreProductGrid from "./invoice-company-store-product-grid";
import { Modal } from "./../../../components/Modals/CModal";

const InvoiceCompanyToStoreModal = ({ modalType, title }) => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rebateValue, setRebateValue] = useState(0);
  const [selectedRow, SetSelectedRow] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const { editModalRecord, editModalVisible, distributeUpdateLoader } =
    useSelector((state) => state.ProductR);
  const { loadingSpinner } = useSelector((state) => state.storeToStoreReducer);
  const { localProducts } = useSelector((state) => state.ProductR);

  // form validation rules
  const validationSchema = Yup.object().shape({});

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;
  const addAtLeastOneProduct = intlPlaceholder("addAtLeastOneProduct");
  const notifySuccess = intlPlaceholder("Stock.edit.success");

  const closeForm = () => {
    dispatch(DataEntryModalAction(modalType, null));

    document.getElementById("filterStoreInvoice").click();
  };

  const onUpdate = () => {
    const storeValue = getValues("store");

    if (rebateValue < 0) {
      return;
    }

    if (localProducts.length > 0 && storeValue) {
      let updatedProducts = localProducts.map((item) => {
        return {
          price: item.localPrice,
          quantity: item.quantity,
          product: item._id,
          productId: item._id,
          productName: item.productName || item.name,
          store: storeValue,
          unit: item.unit,
          unitName: item.unitName,
          totalPrice: item.totalPrice,
          stockQuantity: item.stockQuantity,
          retail: item.retail,
          subunit: item.subunit,
        };
      });
      const data = {
        _id: editModalRecord._id,
        storeId: storeValue,
        products: updatedProducts,
        rebate: rebateValue,
      };

      setDisableSaveBtn(true);

      dispatch(
        DataRequestAction(
          "POST",
          "distributeUpdate",
          data,
          "",
          "distributeUpdate",
          false,
          (resp) => {
            //notify('success', resp);
            notify("success", notifySuccess);
            setDisableSaveBtn(false);
            dispatch(DataGetAction("getAllProductStock", { query: "all" }));
            closeForm();
          },
          (error) => {
            notify("error", error);

            setDisableSaveBtn(false);
          }
        )
      );
    } else {
      notify("error", addAtLeastOneProduct);
    }
  };

  useEffect(() => {
    // dispatch(DataGetAction("distributeDrop", ""));
    dispatch(DataGetAction("getAllProductStock", { query: "all" }));
  }, []);

  if (loadingSpinner) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
      >
        <CSpinner variant="grow" color="primary" />
      </div>
    );
  }

  return (
    <Modal
      title={`${title} (Company to store)`}
      visible={editModalVisible}
      onDismiss={() => closeForm()}
    >
      <CModalBody>
        <InvoiceCompanyStoreProductForm
          key="invoiceCompanyStoreProductForm"
          setValue={setValue}
        />

        <div className="mt-4">
          <InvoiceCompanyStoreProductGrid
            key="invoiceCompanyStoreProductGrid"
            selectedRowKeys={selectedRowKeys}
            selectedRow={selectedRow}
            setSelectedRowKeys={setSelectedRowKeys}
            SetSelectedRow={SetSelectedRow}
            setDisableSaveBtn={setDisableSaveBtn}
            setRebateValue={setRebateValue}
            rebateValue={rebateValue}
          />
        </div>
      </CModalBody>

      <CForm onSubmit={handleSubmit((d) => onUpdate(d))}>
        <CModalFooter>
          <CButton color="secondary" onClick={() => closeForm()}>
            {intlPlaceholder("CancelButtonTitle")}
          </CButton>

          <CLoadingButton
            type="submit"
            color="primary"
            disabled={disableSaveBtn || (localProducts.length ? false : true)}
            loading={distributeUpdateLoader}
          >
            {intlPlaceholder("SaveButtonTitle")}
          </CLoadingButton>
        </CModalFooter>
      </CForm>
    </Modal>
  );
};

export default InvoiceCompanyToStoreModal;
