import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import EmployeeFilter from "./components/EmployeeFilter";
import { DataGetAction } from "../../../redux/actions/http";
import DocsExample from "../../../components/DocsExample";
import DepositModal from "./components/DepositForm";
import WithDrawModal from "./components/WithDrawForm";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import ReactToPrint from "react-to-print";
import CSmartTable from '../../../components/CSSmartTable'
import CheckUserAccess from "../../../util/checkUserAccess";
const Index = () => {
  const param = useLocation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.trustFund);
  const history = useHistory();
  const [filter, setFilters] = useState(null);

  const redirectToPage = (referenceNo) => {
    history.push({ pathname: `/viewTrustDetail`, state: { _id: referenceNo } });
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.name" />,
      key: "name",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="trust.balanceSheet.withdraw" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="trust.balanceSheet.deposit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];
  const depositModalType = "Deposit_modal";
  const withDrawModalType = "WithDraw_modal";

  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  const fetchBalanceSheet = () => {
    if (param.state && param.state._id) {
      const { _id } = param.state;
      dispatch(
        DataGetAction(
          "employeeBalanceSheet",
          { employeeId: _id, startDate: moment(), endDate: moment() },
          "fetchingEmployeeBalanceSheet"
        )
      );
    }
    dispatch(DataGetAction("getAllEmployeeWithTrust", { query: "all" }));

    return () => {
      dispatch({
        type: "removeTheResultTrustFund",
      });
    };
  };

  useEffect(fetchBalanceSheet, []);
  const { BalanceSheetList, fetchingBalanceSheetForTrustUser } = blProps;



  const showDepositModal = () => {
    dispatch({
      type: depositModalType,
    });
  };

  const showWithDrawModal = () => {
    dispatch({
      type: withDrawModalType,
    });
  };

  const findBankByCurrencyId = (currencyId) => {
    dispatch(
      DataGetAction(
        "getCompanyAccountByCurrencyId",
        { currencyId },
        "fetchingCompanyBank"
      )
    );
  };

  const deposit = CheckUserAccess('trustFunds',['deposit'])
  const withdraw = CheckUserAccess('trustFunds',['withdraw']) 
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <div  ref={componentRef}>
            <CCard className="mb-4">
              <CCardHeader className="d-flex justify-content-between align-items-center">
                <h1>
                  <IntlMessages id="balanceSheet.pageTitle" />
                </h1>
              </CCardHeader>
              <CCardBody>
                <div className="d-flex  mb-3">
                  <EmployeeFilter
                    setFilters={setFilters}
                    initialValues={{
                      employeeId: param && param.state && param.state._id,
                    }}
                  />
                  <div className="d-flex flex-row  justify-content-center align-items-center ms-3">
                 {deposit &&    <CButton
                      color="danger"
                      // variant="outline"
                      className="me-3 d-flex text-white align-items-center"
                      onClick={() => showDepositModal()}
                    >
                       <i className="gx-icon-currency-exchange1 me-2"></i>
                      <IntlMessages id="balanceSheet.filter.deposit" />
                    </CButton>}
                   
                 {withdraw&&   <CButton
                      color="success"
                      className="me-3 d-flex text-white align-items-center"
                      onClick={() => showWithDrawModal()}
                    >
                      <i className="gx-icon-cash-withdrawal1 me-2"></i>
                      <IntlMessages id="balanceSheet.filter.withDraw" />
                    </CButton>}

                    {/* <ReactToPrint
                      trigger={() => (
                        <CButton
                        color="info"
                        className="text-white me-3 d-flex align-items-center"
                        >
                          
                          <CIcon icon={cilPrint} className="me-2" />
                          <IntlMessages id="print" />
                        </CButton>
                      )}
                      content={() => componentRef.current}
                    /> */}
                  </div>
                </div>
                <CSmartTable
                  loading={fetchingBalanceSheetForTrustUser}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  
                  items={
                    fetchingBalanceSheetForTrustUser ? [] : BalanceSheetList
                  }
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    description: (record) => {
                      return  <td>{ <IntlMessages id={record.description} />}</td>
                    },
                    createdAt: (record) => {
                      return <td>{record.createdAt}</td>;
                    },

                    refNo: (record) => {
                      if (record.refNo === "0000") {
                        return <td>{record.refNo}</td>;
                      } else {
                        return (
                          <td
                            onClick={() => redirectToPage(record._id)}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            {record.refNo}
                          </td>
                        );
                      }
                    },
                    debit: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.debit && record.debit.toFixed(2)
                          )}
                        </td>
                      );
                    },
                    credit: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.credit && record.credit.toFixed(2)
                          )}
                        </td>
                      );
                    },
                    balance: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.balance &&
                              parseFloat(record.balance).toFixed(2)
                          )}
                        </td>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let totalBorrow = 0;
                    let totalRepayment = 0;

                    pageData.forEach(({ debit, credit }) => {
                      totalBorrow += parseInt(debit);
                      totalRepayment += parseInt(credit);
                    });

                    return (
                      <tr>
                        <th colSpan={4} className="text-center">
                          {intlPlaceholder("Total")}
                        </th>
                        <th className="text-end">
                          <span>
                            {ChangeNumberFormate(
                              totalBorrow > 0 ? totalBorrow.toFixed(2) : 0
                            )}
                          </span>
                        </th>
                        <th className="text-end">
                          <span>
                            {ChangeNumberFormate(
                              totalRepayment > 0 ? totalRepayment.toFixed(2) : 0
                            )}
                          </span>
                        </th>
                        <th>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>

      <DepositModal
        type={depositModalType}
        title={intlPlaceholder("DepositModalTitle")}
        findBankByCurrencyId={findBankByCurrencyId}
      />
      <WithDrawModal
        type={withDrawModalType}
        findBankByCurrencyId={findBankByCurrencyId}
        title={intlPlaceholder("WithDrawTitle")}
      />
    </>
  );
};

export default Index;
