import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCollapse,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import CSmartTable from "src/components/CSSmartTable"
import { useDispatch } from "react-redux";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CheckUserAccess from "../../../util/checkUserAccess";
const CustomerTable = ({ Customers, customerSpinner }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  let columns = [
    {
      label: <IntlMessages id="customer.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="customer.email" />,
      key: "email",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="customer.phone" />,
      key: "phoneNumber",
      _style: { width: "40%" },
    },
   
  ];


  const found =CheckUserAccess('customer',['edit','delete']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const editRecord = (data) => {
    dispatch({
      type: "edit_customer_modal",
      payload: data,
    });
  };
  const edit =CheckUserAccess('customer',['edit']) 
  const deleted =CheckUserAccess('customer',['delete'])
  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={customerSpinner}
        items={
          customerSpinner
            ? []
            : Customers && Customers.length > 0
            ? Customers
            : []
        }
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                {edit &&   <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-edit me-2"></span>
                      <IntlMessages id="store.action.edit" />
                    </span>
                  </CButton>}

                  { deleted &&  <DeleteModal
                    contentLangueId="customer.deleteModal.message"
                    type="deleteCustomer"
                    selectedItem={record}
                    ButtonTitleId="store.action.delete"
                    titleLanguageId="Delete"
                    size="sm"
                  />}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default CustomerTable;
