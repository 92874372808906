import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CLoadingButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import BankSummaryList from "./components/BankSummaryList";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage,
} from "src/constants/CommonFn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {
  const dispatch = useDispatch();
  const [stateSpinner, setSpinner] = useState(false);
  const modalType = "show_productStock_modal";
  const [filterDate, setFilterDate] = useState(null);
  const [filteredCurrency, setFilteredCurrency] = useState("");
  const blProps = useSelector((state) => state.balanceSheet);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { LogeInUser } = useSelector((state) => state.auth);
  const { locale } = useSelector((state) => state.Setting);
  const fetchBalanceSheet = () => {
    dispatch(
      DataGetAction(
        "BankBalanceSummery",
        {
          startDate: moment().startOf("today").toString(),
          endDate: moment().toString(),
          initialEndDate: moment().toString(),
          noDateSelected: "noDateSelected",
        },
        "bankBalanceSummerySpinner"
      )
    );
  };

  useEffect(fetchBalanceSheet, []);

  const fetchRecord = (data) => {
    if (data) {
      setFilterDate({
        startDate: data[0].toString(),
        endDate: data[1].toString(),
      });
      dispatch(
        DataGetAction(
          "BankBalanceSummery",
          {
            startDate: moment(data[0]).toString(),
            endDate: moment(data[1]).toString(),
            initialEndDate: moment(data[0]).toString(),
            noDateSelected: "selected",
          },
          "bankBalanceSummerySpinner"
        )
      );
    } else {
      dispatch(
        DataGetAction(
          "BankBalanceSummery",
          {
            startDate: moment().startOf("year").toString(),
            endDate: moment().toString(),
            initialEndDate: moment().toString(),
            noDateSelected: "noDateSelected",
          },
          "bankBalanceSummerySpinner"
        )
      );
    }
  };

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  let TotalText = intlPlaceholder("Total");
  let headerTitleAccountSummery = intlPlaceholder("bankAccountReport");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  let textIn = intlPlaceholder("report.in");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let headerBodyData = [
    [
      { text: intlPlaceholder("balanceSheet.names"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.montant"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.initial"), style: "tableHeader" },

      { text: intlPlaceholder("balanceSheet.In"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.out"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.deposit"), style: "tableHeader" },
      {
        text: intlPlaceholder("balanceSheet.bankWithdrawal"),
        style: "tableHeader",
      },
      { text: intlPlaceholder("balanceSheet.expense"), style: "tableHeader" },
      {
        text: intlPlaceholder("balanceSheet.clientWithdrawal"),
        style: "tableHeader",
      },
    ],
  ];

  const downloadSummaryPdf = async () => {
    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let Record = blProps.SummeryBank;
    let bodyData = [...headerBodyData];

    if (filteredCurrency) {
      Record = Record.filter((item) => {
        let currencySymbole = item.currencySymbole.toLowerCase();
        let curr = filteredCurrency.toLowerCase();
        if (currencySymbole.includes(curr)) {
          return item;
        }
      });
    }
    Record.sort((a, b) => a.bankName.localeCompare(b.bankName));

    let Total = 0;
    let balanceTotal = 0;
    let initialBalanceTotal = 0;
    let InTotal = 0;
    let outTotal = 0;
    let depositTotal = 0;
    let withdrawalTotal = 0;
    let expenseTotal = 0;
    let clientWithdrawalTotal = 0;

    Record.forEach((Item) => {
      let balance = parseFloat(Item.balance).toFixed(2);
      let initialBalance = parseFloat(Item.initialBalance).toFixed(2);

      let In = parseFloat(Item.In).toFixed(2);
      let out = parseFloat(Item.out).toFixed(2);
      let deposit = parseFloat(Item.deposit).toFixed(2);
      let withdrawal = parseFloat(Item.withdrawal).toFixed(2);
      let expense = parseFloat(Item.expense).toFixed(2);
      let clientWithdrawal = parseFloat(Item.clientWithdrawal).toFixed(2);

      let nameContent = [
        {
          text: Item.bankName,
          alignment: "center",
        },
      ];
      let currencySymbole = Item.currencySymbole;

      if (Item.paymentMode === "cash") {
        nameContent[0].text = conversionInLanguage(
          locale.locale,
          "account.cash"
        );
      }
      nameContent[0].text = nameContent[0].text + ` - ${currencySymbole} `;
      let name = nameContent;

      balanceTotal = balanceTotal + parseFloat(balance);
      initialBalanceTotal = initialBalanceTotal + parseFloat(initialBalance);

      InTotal = InTotal + parseFloat(In);
      outTotal = outTotal + parseFloat(out);
      depositTotal = depositTotal + parseFloat(deposit);
      withdrawalTotal = withdrawalTotal + parseFloat(withdrawal);
      expenseTotal = expenseTotal + parseFloat(expense);
      clientWithdrawalTotal =
        clientWithdrawalTotal + parseFloat(clientWithdrawal);

      balance = balance !== 0 ? NumberWithCommas(balance) : "";
      initialBalance =
        initialBalance !== 0 ? NumberWithCommas(initialBalance) : "";

      In = In !== 0 ? NumberWithCommas(In) : "";
      out = out !== 0 ? NumberWithCommas(out) : "";
      deposit = deposit !== 0 ? NumberWithCommas(deposit) : "";
      withdrawal = withdrawal !== 0 ? NumberWithCommas(withdrawal) : "";
      expense = expense !== 0 ? NumberWithCommas(expense) : "";
      clientWithdrawal =
        clientWithdrawal !== 0 ? NumberWithCommas(clientWithdrawal) : "";

      // let date = ChangeDateFormate(Item.createdAt);

      let objArr = [];
      objArr.push(name);
      objArr.push(balance);
      objArr.push(initialBalance);

      objArr.push(In);
      objArr.push(out);
      objArr.push(deposit);
      objArr.push(withdrawal);
      objArr.push(expense);
      objArr.push(clientWithdrawal);

      bodyData = [...bodyData, objArr];
    });

    balanceTotal = parseFloat(balanceTotal).toFixed(2);
    initialBalanceTotal = parseFloat(initialBalanceTotal).toFixed(2);

    let letTotal = [
      {
        text: TotalText,
        style: "tableHeader",
      },
      { text: NumberWithCommas(balanceTotal), style: "tableBody" },
      { text: NumberWithCommas(initialBalanceTotal), style: "tableBody" },

      { text: NumberWithCommas(InTotal), style: "tableBody" },
      { text: NumberWithCommas(outTotal), style: "tableBody" },
      { text: NumberWithCommas(depositTotal), style: "tableBody" },
      { text: NumberWithCommas(withdrawalTotal), style: "tableBody" },
      { text: NumberWithCommas(expenseTotal), style: "tableBody" },
      { text: NumberWithCommas(clientWithdrawalTotal), style: "tableBody" },
    ];
    bodyData = [...bodyData, letTotal];

    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headerTitle = `${headerTitleAccountSummery} ${reportFrom} ${ChangeDateFormate(
      startDate,
      "DD-MM-YYYY"
    )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

    let docDefinition = {
      pageMargins: [10, 50, 10, 45],
      pageOrientation: "landscape",
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },
        {
          text: headerTitle,
          style: { fontSize: 22, bold: true, alignment: "center" },
          margin: [15, 10],
        },
        {
          style: "tableBody",
          headerRows: 1,

          table: {
            widths: [90, 80, 80, 80, 80, 80, 80, 80, 80],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          fontSize: 10,
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          alignment: "center",
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          alignment: "right",
        },
      },
    };
    setTimeout(function () {
      pdfMake.createPdf(docDefinition).download("bankSummaryReport.pdf");
      setSpinner(false);
    }, 1000);
  };

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="balanceSheet.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex align-items-center">
                <div style={{ width: "240px" }}>
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment(),
                      endDate: moment(),
                    }}
                    onApply={handleApply}
                    onCallback={(start, end) => {
                      fetchRecord([start, end]);
                    }}
                  >
                    <input type="text" className="form-control col-4" />
                  </DateRangePicker>
                </div>

                <div className="ms-3">
                  <CLoadingButton
                    loading={stateSpinner}
                    disabled={stateSpinner}
                    color="danger"
                    className="text-white"
                    onClick={() => {
                      setSpinner(true);
                      downloadSummaryPdf();
                    }}
                  >
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf.generate.button" />
                    </span>
                  </CLoadingButton>
                </div>
              </div>

              <DocsExample href="components/smart-table/">
                <BankSummaryList
                  Data={blProps.SummeryBank}
                  Spinner={blProps.bankBalanceSummerySpinner}
                  filterDate={filterDate}
                  setFilterDate={setFilterDate}
                  setFilteredCurrency={setFilteredCurrency}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
