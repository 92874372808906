import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  conversionInLanguage
} from "src/constants/CommonFn";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import ReceiptForm from "./components/ReceiptForm";
import ReceiptList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CheckUserAccess from "../../util/checkUserAccess";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Index = () => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.Setting);
  const [staticFilterValue, setStaticFilterValue] = useState({ payableStatus: 'approved' });

  const [ReceiptNumber, setReceiptNumber] = useState();
  const comProps = useSelector((state) => state.receiptReducer);
  const storeProps = useSelector((state) => state.storeReducer);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;
  const modalType = "show_receipt_modal";
  const location = useLocation();

  const { Stores } = storeProps;
  const { Receipts, ReceiptCreatedBy, BankList, storeReceiptSpinner } =
    comProps;



  const [filterValue, setFilterValue] = useState(null);
  let ConstHeader = intlPlaceholder("report.store.receipt");
  let constHeaderTo = intlPlaceholder("report.to");
  let constHeaderFrom = intlPlaceholder("report.from");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("storeName"), style: "tableHeader" },
      { text: intlPlaceholder("Amount"), style: "tableHeader" },
      { text: intlPlaceholder("Account"), style: "tableHeader" },
      { text: intlPlaceholder("PaymentMethod"), style: "tableHeader" },
      { text: intlPlaceholder("chequeDate"), style: "tableHeader" },
    ],
  ];

  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  const ViewReceipt = (data) => {
    if (data.prefix === "RM") {
      history.push({
        pathname: "/viewStoreReceipt",
        state: { ReceiptNo: data.receiptNumber },
      });
    } else {
      history.push({
        pathname: "/viewReceipt",
        state: { ReceiptNo: data.receiptNumber },
      });
    }
  };

  const searchByStore = (e) => {
    dispatch({
      type: "Search_by_store",
      payload: e,
    });
  };
  const searchByPaymentMode = (e) => {
    dispatch({
      type: "Search_by_payment_mode",
      payload: e,
    });
  };
  const searchByBank = (e) => {
    dispatch({
      type: "Search_by_Bank",
      payload: e,
    });
  };
  const Search_by_createdBy = (e) => {
    dispatch({
      type: "Search_by_created_by",
      payload: e,
    });
  };
  const handleSearch = () => {
    let splitOnArray = ReceiptNumber.split("-");
    let number = splitOnArray[0];
    if (splitOnArray.length === 2) {
      number = splitOnArray[1];
    }

    dispatch({
      type: "Search_by_receipt_number",
      payload: number,
    });
  };

  const checkDate = (data) => {
    if (data) {
      dispatch({
        type: "Search_store_by_chequeDate",
        payload: data,
      });
    }
  };

  const ViewCompanySaleReceipt = (data) => {
    //history.push('/storeToStoreReceiptDetail', { ReceiptNo: data.receiptNumber })
    let invoiceNumber =
      data.storeToStoreReceiptData &&
      data.storeToStoreReceiptData.length > 0 &&
      data.storeToStoreReceiptData[0].invoiceNumber;
    if (invoiceNumber) {
      history.push({
        pathname: "/storeToStoreCredit",
        state: { InvoiceNo: invoiceNumber },
      });
    }
  };

  const renderAction = (record) => {


    if (record.receiptType === "storeToStoreSale") {
      return (
        <CButton
          size="sm"
          color="warning"
          className="me-3"
          onClick={() => ViewCompanySaleReceipt(record)}
        >
          <span style={{ color: "white" }}>
            <span className="icon icon-custom-view me-2"></span>
            <IntlMessages id="view" />
          </span>
        </CButton>
      );
    } else {
      return (
        <CButton
          size="sm"
          color="warning"
          className="me-3"
          onClick={() => ViewReceipt(record)}
        >
          <span style={{ color: "white" }}>
            <span className="icon icon-custom-view me-2"></span>
            <IntlMessages id="view" />
          </span>
        </CButton>
      );
    }
  };

  const downloadPDF = async (data) => {

    let Record = data;


    Record.forEach((Item, index) => {
      if (Item.payableStatus === staticFilterValue.payableStatus) {
        let bankName = "Cash";
        let PaymentMethod = "Cash";


        if (Item.paymentMode.mode !== "cash") {
          bankName = Item.paymentMode.bank.name;

          const lower = Item.paymentMode.transactionType.toLowerCase();
          let str =
            Item.paymentMode.transactionType.charAt(0).toUpperCase() +
            lower.slice(1);
          PaymentMethod = str;
        }

        let checkDate = "---";
        if (Item.paymentMode.mode === "bank") {
          checkDate = ChangeDateFormate(
            Item.paymentMode.chequeDate,
            "YYYY-MM-DD"
          );
        }

        let date = ChangeDateFormate(Item.createdAt);
        let amount = parseFloat(Item.paid).toFixed(2);
        amount = NumberWithCommas(amount);
        let objArr = [date];

        let bankNameLng = Item.bankName === 'cashCaisse' ? conversionInLanguage(locale.locale, `cashCaisse.bankName`) : Item.bankName
        let paymentMethodLng = conversionInLanguage(locale.locale, `${Item.mode}.paymentMethod`)



        objArr.push(`${Item.prefix}-${Item.receiptNumber}`);
        objArr.push(Item.storeId[0].name);
        objArr.push(amount);
        objArr.push(bankNameLng);
        objArr.push(paymentMethodLng);
        objArr.push(checkDate);

        bodyData = [...bodyData, objArr];
      }
    });







    let startDate = moment().startOf("year").toString();
    startDate = ChangeDateFormate(startDate, "YYYY-MM-DD");
    let endDate = moment().toString();
    endDate = ChangeDateFormate(endDate, "YYYY-MM-DD");

    if (filterValue.startDate && filterValue.endDate) {
      startDate = ChangeDateFormate(filterValue.startDate, "YYYY-MM-DD");
      endDate = ChangeDateFormate(filterValue.endDate, "YYYY-MM-DD");
    }

    let headerTitle =
      ConstHeader.toUpperCase() +
      " " +
      constHeaderFrom +
      " " +
      startDate +
      " " +
      constHeaderTo +
      " " +
      endDate;

    const logoImage = localStorage.getItem('image') ? localStorage.getItem('image') : process.env.REACT_APP_LOGO_DUMMY;;

    let footerAddress = localStorage.getItem("address");

    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
    let docDefinition = {
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },

        {
          text: headerTitle,
          margin: [15, 10],
          style: { fontSize: 12, bold: true, alignment: "center" },
        },
        {
          style: "tableExample",
          headerRows: 1,

          table: {
            widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download("storeReport.pdf");
  };

  const getStoreName = (record) => {
    if (record.receiptType === "storeToStoreSale") {
      return record.storeId && record.storeId.name;
    } else {
      return record.storeId && record.storeId.name;
    }
  };

  const renderPrefix = (record) => {
    if (record.receiptType !== "storeToStoreSale") {
      return record.prefix + "-" + record.receiptNumber;
    } else {
      return (
        record.storeToStoreReceiptData &&
        record.storeToStoreReceiptData.length > 0 &&
        record.storeToStoreReceiptData[0].prefix +
        "-" +
        record.storeToStoreReceiptData[0].invoiceNumber
      );
    }
  };

  const columns = [
    {
      label: <IntlMessages id="receipt.number" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.storeName" />,
      key: "storeName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.amountPaid" />,
      key: "paid",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.paymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="receipt.chequeDate" />,
      key: "chequeDate",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.bank" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "payableStatus",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approved'), value: 'approved', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },

    {
      label: '',
      sorter: false,
      filter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const create = CheckUserAccess("storeReceipts", ["create"])
  const pdf = CheckUserAccess("storeReceipts", ["pdf"])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="receipt.storeReceipt.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter
                  setFilter={setFilterValue}
                  receiptType={
                    location.pathname === "/storeReceipt"
                      ? "storeSale"
                      : "companySale"
                  }
                />
                <div className="d-flex align-items-center mb-1">
                {create && <ReceiptForm modalType={modalType} />}

                  {pdf && <CButton
                    color="danger"
                    onClick={() => downloadPDF(comProps.Receipts)}
                    className="ms-3"
                  >
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf" />
                    </span>
                  </CButton>}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <ReceiptList
                  loading={storeReceiptSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={storeReceiptSpinner ? [] : Receipts}
                  columns={columns}
                  filterValueChangeTo={(value) => {
                    if (value.payableStatus) {
                      setStaticFilterValue(value)
                    }
                  }}
                  columnFilterValue={staticFilterValue}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    payableStatus: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.payableStatus === 'approved' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.payableStatus}`)}</CBadge>
                        </td>
                      );
                    },
                    mode: (record) => {
                      return <td style={{
                        textTransform: "capitalize",
                      }}>{<IntlMessages id={`${record.mode}.paymentMethod`} />}</td>
                    },
                    number: (record) => {
                      return (
                        <td>
                          <span>{renderPrefix(record)}</span>
                        </td>
                      );
                    },


                    paid: (record) => {
                      return (
                        <td className="text-end">{ChangeNumberFormate(record.paid.toFixed(2))}</td>
                      );
                    },


                    chequeDate: (record) => {
                      return (
                        <td>
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {record.chequeDate}
                          </span>
                        </td>
                      );
                    },
                    bankName: (record) => {
                      return <td>{record.bankName === 'cashCaisse' ? <IntlMessages id={`${record.bankName}.bankName`} /> : record.bankName}</td>;
                    },
                    createdBy: (record) => {
                      return (
                        <td>
                          <span>
                            {record.createdBy}
                          </span>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let total = 0;


                    pageData.forEach(({ paid, oldReceiptNumber }) => {
                      if (oldReceiptNumber) {
                        total -= paid;
                      }
                      else {
                        total += paid;
                      }

                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center"><h5><IntlMessages id='Total' /></h5></th>

                        <th colSpan={2} className="text-end">
                          <span><h5>{ChangeNumberFormate(total.toFixed(2))}</h5></span>
                        </th>
                        <th colSpan={5}>
                          <span></span>
                        </th>
                      </tr>

                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
