import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilBuilding,
  cilUserFollow,
} from "@coreui/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import {intlPlaceholder} from "../../../util/intlPlaceholder";
import {notify} from '../../../util/ShowToast'
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const { message, success, error, ShowForgotLoader } = authProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const redirectToPage = (url) => {
    history.push(url);
  };

  const redirectToLoginPage = () => {
    dispatch(updateAllState("auth"));
    localStorage.clear();
    history.push("/");
  };

  const LoadPage = () => {
    return () => {
      dispatch(updateAllState("auth"));
    };
  };
  useEffect(LoadPage, []);

  const onFinish = (values) => {
    dispatch(
      DataRequestAction("POST", "forgetPassword", values, "", "forgot", false)
    );
  };

  if (authProps.error) {
    let messageLng = intlPlaceholder(authProps.message)
    notify('error',messageLng);
    dispatch(updateAllState("auth"));
  }

  const showSweetAlert = () => {
    if (authProps.success) {
      return (
        <SweetAlert
          success
          title="Congrats!!!"
          onConfirm={() => redirectToLoginPage()}
        >
         { intlPlaceholder('forgotPassword_success')}
        </SweetAlert>
      );
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div>
      {showSweetAlert()}
      </div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit(onFinish)}>
                  <div className="welcomeMessage text-center mb-5">
                    <h2 className="font-weight-bold mb-3">
                      <IntlMessages id="login.forgotPassword" />
                    </h2>
                    <p className="mb-0">
                      <IntlMessages id="forgotPassword_message" />,
                    </p>
                    <p
                      className={`text-center ${
                        authProps.error ? "error" : ""
                      }`}
                    >
                      {authProps.message}
                    </p>
                  </div>

                  <CInputGroup className="mb-3 custom-forget-width">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormFloating >
                    <CFormInput
                      placeholder= {intlPlaceholder('email')}
                      autoComplete="email"
                      id="email"
                      className={errors.email ? "is-invalid" : ""}
                      {...register("email")}
                    />
                   
                    <CFormLabel htmlFor="">
                    {intlPlaceholder('email')}
                    </CFormLabel>
                   
                    </CFormFloating>
                    <div className="error-message">
                      {errors.email?.message}
                    </div>
                  </CInputGroup>

                  <div className="d-grid">
                    <CLoadingButton
                      color="success"
                      type="submit"
                      loading={ShowForgotLoader}
                      disabled={ShowForgotLoader}
                    >
                      <IntlMessages id="send" />
                    </CLoadingButton>
                  </div>

                  <p
                    className="text-center mt-4"
                    onClick={() => redirectToPage("/login")}
                  >
                    
                    <IntlMessages id="clickHereFor" />
                    <span className="pointer link text-primary" style={{cursor : 'pointer'}}><IntlMessages id="login" /></span>
                  </p>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Index;
