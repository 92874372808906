import moment from "moment";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch } from "react-redux";
import { DataGetAction } from "src/redux/actions/http";

const Filter = () => {
  const dispatch = useDispatch();
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
    dispatch({
      type: "resetLocalProduct",
    });
    dispatch({
      type: "RestProductStock",
    });

  

    dispatch({
      type: "RESET_LOCAL_PRODUCT",
    });
    dispatch({
      type: "reset_add",
    });
    dispatch(
      DataGetAction(
        "getSale",
        {
          query: "all",
          startDate: picker.startDate,
          endDate: picker.endDate,
        },
        "SaleSpinner"
      )
    );
  };

  return (
    <div style={{ width: "250px", marginRight: 10 }}>
      <DateRangePicker
        initialSettings={{
          startDate: moment(),
          endDate: moment(),
        }}
        onApply={handleApply}
        onCallback={(start, end) => {
          console.log([start.toISOString(), end.toISOString()]);
        }}
      >
        <input type="text" className="form-control col-4" />
      </DateRangePicker>
    </div>
  );
};

export default Filter;
