import React , {useState} from "react";
import {
  CButton,
  CFormSelect,
  CForm,
  CFormInput,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/http";
import { useForm,Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import UnitListLocal from "./UnitListLocal";
import SubUnitForm from "./SubUnitform";
import { notify } from "src/util/ShowToast";
const AddUnitForm = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState, control } = useForm();
  const [selectedUnitName, setUnitName] = useState('')
  const { errors } = formState;


  const SubUnit = useSelector((state) => state.unitReducer.SubUnits);
  const subProps = useSelector((state) => state.unitReducer);
  const history = useHistory();
  const onFinish = (values) => {
    const data = {
      name: values.name,
      subUnits: SubUnit,
    };

    dispatch(DataRequestAction("POST", "addUnit", data, "", "unit", false));
  };
  let unitAddedSuccess = intlPlaceholder("unitAddedSuccess");
  if (subProps.success) {
    notify("success", unitAddedSuccess);
    history.push("/units");
    dispatch({
      type: "unit_UPDATE",
    });
  }

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row">
        <div className="col">
          
        <Controller
          control={control}
          name={"name"}
          render={(props) => {
            return (
              <CFormFloating>

              <CFormInput
                className={errors.name ? "is-invalid" : ""}
               
                onChange={(v) =>{ 
                  setUnitName(v.target.value)
                  props.field.onChange(v.target.value)
                }}
                placeholder="Name"
                type="text"
                id="name"
              />
              <div className="invalid-feedback">{errors.name?.message}</div>
              <CFormLabel htmlFor="name">
                {intlPlaceholder("customer.addCustomerModal.name")}
              </CFormLabel>
            </CFormFloating>
            );
          }}
        />
          
        </div>
      </div>
      <SubUnitForm />
      <UnitListLocal selectedUnitName={selectedUnitName} />

      <div className="row">
        <div className="col-4 d-grid gap-2">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                  type: 'unit_UPDATE'
              })
              reset();
              history.push('/units')
          }}
          >
            <IntlMessages id="unit.addUnitPage.cancel" />
          </CButton>
        </div>
        <div className="col-4 d-grid gap-2">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              if (subProps.editSubUnits && subProps.editSubUnits !== null) {
                  dispatch({
                      type: 'reset_form_edit'
                  })
              } else {
                  dispatch({
                      type: 'unit_UPDATE'
                  })
              }

              reset();
          }
          }
          >
            <IntlMessages id="unit.addUnitPage.reset" 
            />
          </CButton>
        </div>
        <div className="col-4 d-grid gap-2">
          <CLoadingButton
            className="btn btn-primary  btn-block"
            type="submit"
            color="primary"
            loading={subProps.unitLoader}
            disabled={subProps.unitLoader}
          >
            <IntlMessages id="unit.addUnitPage.save" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default AddUnitForm;
