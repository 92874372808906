import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";
import React, { useEffect } from "react";
import IntlMessages from "src/util/IntlMessages";
import EditQuotationsForm from "./EditQuotationsForm";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "src/redux/actions/http";
import { useLocation } from "react-router-dom";

const EditQuotation = () => {
  const location = useLocation();
  const { _id } = location.state;
  const dispatch = useDispatch();
  const { editQuotationRecordSpin, editQuotationRecord } = useSelector(
    (state) => state.quotationsR
  );
  useEffect(() => {
    if (_id) {
      dispatch(
        DataGetAction("getEditQuotation", { _id: _id }, "editQuotationSpin")
      );
    }
  }, [_id]);

  console.log("=====editQuotationRecord", editQuotationRecord);
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <h1>
              <IntlMessages id="editQuotation.title" />
            </h1>
          </CCardHeader>
          <CCardBody>
            {editQuotationRecordSpin && <CSpinner />}
            {!editQuotationRecordSpin && editQuotationRecord && (
              <EditQuotationsForm
                initialValues={{
                  paymentMode:
                    editQuotationRecord.paymentMode &&
                    editQuotationRecord.paymentMode.mode,
                  bank:
                    (editQuotationRecord.paymentMode &&
                      editQuotationRecord.paymentMode.bank &&
                      editQuotationRecord.paymentMode.bank._id) ||
                    null,
                  bankName:
                    (editQuotationRecord.paymentMode &&
                      editQuotationRecord.paymentMode.bank &&
                      editQuotationRecord.paymentMode.bank.name) ||
                    null,
                    accountNumber:(editQuotationRecord.paymentMode &&
                      editQuotationRecord.paymentMode.bank &&
                      editQuotationRecord.paymentMode.bank.accountNumber) ||
                    null,
                  store: editQuotationRecord.storeId,
                  product: editQuotationRecord.products,
                  _id: editQuotationRecord._id,
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default EditQuotation;
