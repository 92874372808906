import React, { useState } from "react";
import {
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";

const AddForm = ({ defaultValues, ButtonComponent }) => {
  const comProps = useSelector((state) => state.bankReducer);

  const dispatch = useDispatch();

  // form validation rules
 

  const validationSchema = Yup.object().shape({
  
    name: Yup.string().required(
      <IntlMessages id="bank.addBankModal.name_message" />
    ),
    address: Yup.string().required(
      <IntlMessages id="bank.addBankModal.address_message" />
    )
   
  });




  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    if (comProps.selectedItem === null) {
      dispatch(DataRequestAction("POST", "addBank", values, "", "bank", false));
    } else {
      values._id = comProps.selectedItem._id;
      //values.createdByName = comProps.createdBy
      dispatch(DataRequestAction("PUT", "editBank", values, "", "bank", false));
    }
  };
  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message)
    notify("success", messageLng);
    dispatch({
      type: "bank_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message)
    notify("error", messageLng);
    dispatch({
      type: "bank_UPDATE",
    });
  }
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.name ? "is-invalid" : ""}
            {...register("name")}
            placeholder={intlPlaceholder("abank.addBankModal.name")}
            type="text"
            id="name"
          />
          <CFormLabel htmlFor="name">
            {intlPlaceholder("abank.addBankModal.name")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </CFormFloating>

        {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
      </div>

      <div className="mb-3">
      <CFormFloating className="mb-3">
      <CFormInput
      className={errors.name ? "is-invalid" : ""}
          {...register("address")}
          placeholder={intlPlaceholder("bank.addBankModal.address")}
          type="text"
          id="address"
        />
          <CFormLabel htmlFor="address">
          {intlPlaceholder("bank.addBankModal.address")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.address?.message}</div>
        </CFormFloating>
       
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default AddForm;
