import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import CurrencyTable from "./components/CurrencyTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddCurrencyModal from "./components/AddCurrencyModal";
import EditCurrencyModal from "./components/EditCurrencyModal";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.currencyReducer);
  const modalType = "show_currency_modal";

  const fetchCurrency = () => {
    dispatch(
      DataGetAction("getAllCurrency", { query: "all" }, "currencySpinner")
    );
  };
  useEffect(fetchCurrency, [comProps.fetchCurrencyInfo]);

  const create = CheckUserAccess('currency',['create'])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="currency.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3">
               {create && <AddCurrencyModal />}
              </div>

              <DocsExample href="components/smart-table/">
                <CurrencyTable
                  Currencies={comProps.Currencies}
                  currencySpinner={comProps.currencySpinner}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditCurrencyModal />
      </CRow>
    </>
  );
};

export default Index;
