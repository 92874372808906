import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    visible: false,
    passModalVisible: false,
    selectedItem: null,
    employeeLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    selectedEmployee: null,
    Employees: [],
    employeeSpinner: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'show_view_employee_modal': {
            return { ...state, visible: !state.visible, selectedEmployee: action.payload ? action.payload : null }
        }
        case 'getAllEmployee_SUCCESS': {
            return { ...state, Employees: action.payload.Record };
        }
        case 'employeeSpinner_START':{
            return { ...state,  employeeSpinner: true };
        }
        case 'employeeSpinner_STOP':{
            return { ...state,   employeeSpinner: false };
        }
        case 'newEmployeeActivatedInfo_SUCCESS': {
            const updateEmployeeList = getEmployeeList(action.payload, state.Employees);
            return { ...state, Employees: updateEmployeeList }
        }

        case 'show_employee_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }



        case 'employee_START': {
            return { ...state, employeeLoader: true };
        }
        case 'employee_STOP': {
            return { ...state, employeeLoader: false };
        }

        case 'inviteEmployee_SUCCESS': {
            return { ...state, success: true, message: "InvitationSendSuccessfully", Employees: [action.payload.user, ...state.Employees], employeeLoader: false };
        }
        case 'inviteEmployee_FAILURE': {
            return { ...state, error: true, success: false, employeeLoader: false, message: action.payload };
        }


        case 'editEmployee_SUCCESS': {
            let oldCat = state.Employees;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Employees: newCat, employeeLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editEmployee_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, employeeLoader: false, message: action.payload, modalVisible: false };
        }

        case 'employee_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                employeeLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                message: "",
                selectedItem: null

            };
        }

        case 'deleteEmployee_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Employees;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Employees: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }


    function getEmployeeList(latest, record) {
        const newArray = [];
        record.map(Item => {
            if (Item._id === latest._id) {
                newArray.push(latest);
            } else {
                newArray.push(Item);
            }
            return Item
        })

        return newArray;
    }

}
