import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import IntlMessages from "../../../util/IntlMessages";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceProductList from "../../../components/InvoiceTable";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
const QuotationsDetail = () => {
  const componentRef = useRef();
  const logoImage = localStorage.getItem("profileImage");
  let address = localStorage.getItem("address");
  const quotationsProps = useSelector((state) => state.quotationsR);
  const { quotationModalApiData } = quotationsProps;
  const invProps = useSelector((state) => state.quickSaleReducer);
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;

  const { customerName, phoneNumber } =
    invProps.saleDetailData !== null ? invProps.saleDetailData : "";

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantity" />,
      key: "Qty",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unitPrice" />,
      key: "price",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.total" />,
      key: "totalPrice",
      _style: { width: "40%" },
    },
  ];

  const getAmountBill = () => {
    if (quotationModalApiData && quotationModalApiData.totalPrice) {
      return (
        <>
          <div className="d-flex justify-content-end mt-4">
            <p></p>
            <div>
              <div className="d-flex">
                <span
                  className="d-inline-block"
                  style={{ width: "170px", fontSize: 13, color: "#003366" }}
                >
                  <IntlMessages id="invoiceDetail.totalBill" />:
                </span>
                <span
                  className="d-inline-block "
                  style={{
                    width: "80%",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#003366",
                  }}
                >
                  {quotationModalApiData &&
                    quotationModalApiData.totalPrice &&
                    ChangeNumberFormate(
                      quotationModalApiData.totalPrice.toFixed(2)
                    )} GNF
                </span>
              </div>
              <div className="d-flex">
                <span
                  className="d-inline-block "
                  style={{ width: "170px", fontSize: 13, color: "#003366" }}
                >
                  <IntlMessages id="invoiceDetail.totalBillWords" />:
                </span>
                <span
                  className="d-inline-block text-capitalize"
                  style={{ width: "80%", fontSize: 13, color: "#003366" }}
                >
                  {quotationModalApiData &&
                    quotationModalApiData.totalPrice &&
                    ChangeNumberToWord(
                      quotationModalApiData.totalPrice,
                      languageId
                    )}
                </span>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 mt-5"
        ref={componentRef}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <img src={logoImage} alt="" width="300" className="mb-2" />

            <p
              className="mb-0"
              style={{
                color: "grey",
                maxWidth: "255px",
                textAlign: "center",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              {address}
            </p>
          </div>
          {quotationModalApiData && quotationModalApiData.storeName && (
            <div
              className="d-flex flex-row justify-content-between"
              style={{
                textAlign: "center",
                color: "#012447",
                fontSize: "15px",
              }}
            >
              CLIENT:
              <br />
              {quotationModalApiData.storeName}
            </div>
          )}
        </div>
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="me-1">
                  <IntlMessages id="quotation" /> :
                </span>
                <span className="ms-1">
                  {quotationModalApiData &&
                    quotationModalApiData.quotationNumber}
                </span>
              </h2>
            </div>

            <small style={{ color: "grey" }}>
              {quotationModalApiData &&
                ChangeDateFormate(quotationModalApiData.createdAt)}
            </small>
          </div>
        </div>
        <br />
        <h6>
          <IntlMessages id="PaymentMode" /> :{" "}
          <span className="text-capitalize">
            {quotationModalApiData && quotationModalApiData.paymentMode && (
              <IntlMessages id={quotationModalApiData.paymentMode.mode} />
            )}
          </span>
        </h6>
        {quotationModalApiData &&
        quotationModalApiData.paymentMode &&
        quotationModalApiData.paymentMode.bank ? (
          <h6>
            <IntlMessages id="PaymentModeBankName" /> :{" "}
            <span className="text-capitalize">
              {quotationModalApiData.paymentMode.bank.name}
            </span>
          </h6>
        ) : (
          ""
        )}
        {quotationModalApiData &&
        quotationModalApiData.paymentMode &&
        quotationModalApiData.paymentMode.bank && quotationModalApiData.paymentMode.bank.accountNumber ? (
          <h6>
            <IntlMessages id="accountNumber" /> :{" "}
            <span className="text-capitalize">
              {quotationModalApiData.paymentMode.bank.accountNumber}
            </span>
          </h6>
        ) : (
          ""
        )}
        <div className="Table">
          <InvoiceProductList
            items={quotationModalApiData && quotationModalApiData.products}
            columns={columns}
            scopedColumns={{
              Qty: (record) => {
                return <td>{parseFloat(record.Qty).toFixed(3)}</td>;
              },
              unitName: (record) => {
                return (
                  <td>
                    {record.retail ? record.subunitName : record.unitName}
                  </td>
                );
              },
              price: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(parseFloat(record.price).toFixed(2))}
                  </td>
                );
              },
              totalPrice: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.totalPrice).toFixed(2)
                    )}
                  </td>
                );
              },
            }}
          />
        </div>

        {getAmountBill()}
        <br />
        <br />
        <InvoiceSignature showClient={false} />
      </div>
    </div>
  );
};

export default QuotationsDetail;
