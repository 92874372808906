import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CFormLabel, CFormSelect } from '@coreui/react-pro'




export const CSmartTableItemsPerPageSelector = forwardRef(({ itemsPerPage, itemsPerPageLabel, itemsPerPageOptions, ...rest }, ref) => {
  return (
    <div className="row">
      <CFormLabel className="col-auto col-form-label">{itemsPerPageLabel}</CFormLabel>
      <div className="col-auto">
        <CFormSelect defaultValue={itemsPerPage} {...rest} ref={ref}>
          {itemsPerPageOptions &&
            itemsPerPageOptions.map((number, index) => {
              return (
                <option value={number} key={index}>
                  {number}
                </option>
              )
            })}
        </CFormSelect>
      </div>
    </div>
  )
})

CSmartTableItemsPerPageSelector.propTypes = {
  itemsPerPage: PropTypes.number,
  itemsPerPageLabel: PropTypes.string,
  itemsPerPageOptions: PropTypes.array,
}
export default CSmartTableItemsPerPageSelector
