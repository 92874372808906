const INIT_STATE = {
    Salary: [],
    Salaries: [],
    ShowEmployeeSalaryModal: false,
    postingSalary: false,
    salaryPostedSuccess: false,
    salaryPostedError: false,
    message: '',
    salaryDetail: [],
    fetchDetail: false,
    PostSalary: [],
    AllEmployeeFetched: [],
    Employees: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'filterTheEmployeeArray': {
            let filterPostSalary = state.AllEmployeeFetched;
            if (action.payload !== "all") {
                filterPostSalary = getFilteredPostSalary(state.AllEmployeeFetched, action.payload);
            }
            return { ...state, PostSalary: filterPostSalary }
        }
        case 'getStoreEmployeeList_SUCCESS': {
            return { ...state, Employees: action.payload.Record }
        }
        case 'getStoreEmployeeSalaryList_SUCCESS': {
            return { ...state, PostSalary: action.payload.Record, AllEmployeeFetched: action.payload.Record }
        }
        case 'updateStoreSalaryData': {
            const updateRecord = recordAfterUpdate(state.PostSalary, action.payload)
            return { ...state, PostSalary: updateRecord, fetchDetail: false }
        }
        case 'getStoreSalaryDetails_SUCCESS': {
            return { ...state, Salaries: action.payload.Record, fetchDetail: false }
        }
        case 'fetchingStoreSalaryDetail_START': {
            return { ...state, fetchDetail: true }
        }
        case 'fetchingStoreSalaryDetails_START': {
            return { ...state, fetchDetail: true }
        }
        case 'getStoreSalaryDetail_SUCCESS': {
            return { ...state, salaryDetail: action.payload.Record, fetchDetail: false }
        }
        case 'postingStoreSalary_START': {
            return { ...state, postingSalary: true }
        }
        case 'postStoreSalaryToEmployee_SUCCESS': {
            return { ...state, postingSalary: false, salaryPostedSuccess: true, message: "salaryPostedToEmployee" }
        }
        case 'postStoreSalaryToEmployee_ERROR': {
            return { ...state, postingSalary: false, salaryPostedSuccess: false, salaryPostedError: true, message: "errorWhilePostingSalary" }
        }
        case 'show_ses_modal': {
            return { ...state, ShowEmployeeSalaryModal: !state.ShowEmployeeSalaryModal, PostSalary: [] }
        }

        case 'getStoreEmployeeSalary_SUCCESS': {
            return { ...state, Salary: action.payload.Record }
        }
        case 'ses_UPDATE': {
            return {
                ...state,
                postingSalary: false,
                salaryPostedSuccess: false,
                salaryPostedError: false,
                message: ''
            };
        }

        default:
            return state;
    }

}


const getFilteredPostSalary = (oldData, employeeId) => {
    const filterData = oldData.filter(Item => Item._id === employeeId)
    return filterData
}
const recordAfterUpdate = (oldData, newData) => {
    let newArray = [];
    oldData.map(Item => {
        if (Item._id === newData._id) {
            newArray.push(newData)
        } else {
            newArray.push(Item);
        }
        return Item
    })
    return newArray;
}