const initialState = {
  sidebarShow: true,
  asideShow: false,
  unfoldable: false,
  theme: "default",
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
};

const Settings = (state = initialState, { type,payload, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };

    case 'SWITCH_LANGUAGE':
       
      return {
        ...state,
        locale: payload,
      };
    default:
      return state;
  }
};
export default Settings;
