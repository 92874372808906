const permissionData = [
  {
    name: "dashboard",
    menus: [
      {
        name: "dashboard",
        value: "dashboard",
        permissions: ["fullAccess", "view", "grossProfit"],
        subMenu: [],
        permissionsKey: "dashboard",
      },
    ],
  },
  {
    name: "Property Management",
    menus: [
      {
        name: "property",
        value: "property",
        permissions: ["create"],
        subMenu: [],
        permissionsKey: "property",
      },
      {
        name: "contract",
        value: "contract",
        permissions: ["create"],
        subMenu: [],
        permissionsKey: "contract",
      },
    ],
  },
  {
    name: "waNotification",
    permissionsKey: "waNotification",
    menus: [
      {
        name: "waNotification",
        value: "waNotification",
        permissions: ["fullAccess", "invoice", "storeToStore", "receipt", "trustFund", "quickSale"],
        subMenu: [],
        permissionsKey: "waNotification",
      },
    ],
  },
  {
    name: "stock",
    menus: [
      {
        name: "stockEntry",
        value: "stockEntry",
        permissions: ["fullAccess", "view", "create", "edit"],
        subMenu: ["addStock"],
        permissionsKey: "stockEntry",
      },
      {
        name: "quotations",
        value: "quotations",
        permissions: ["fullAccess", "view", "create", "edit"],
        subMenu: ["addQuotations", "editQuotations"],
        permissionsKey: "quotations",
      },
      {
        name: "quickSale",
        permissionsKey: "quickSale",
        value: "quickSale",
        permissions: ["fullAccess", "view", "create", "edit", "cancel"],
        subMenu: ["addQuickSale", "editQuickSale"],
      },

      {
        name: "supplier",
        value: "supplier",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "supplier",
      },
      {
        name: "currency",
        value: "currency",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "currency",
      },
      {
        name: "product",
        value: "product",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "product",
      },
      {
        name: "units",
        value: "units",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: ["addUnit", "editUnit"],
        permissionsKey: "units",
      },
      {
        name: "category",
        value: "category",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "category",
      },
    ],
  },
  {
    name: "stockTransfer",
    menus: [
      {
        name: "companyToStore",
        value: "companyToStore",
        permissions: ["create"],
        subMenu: ["viewStoreInvoice"],
        permissionsKey: "companyToStore",
      },
      {
        name: "storeToStore",
        value: "storeToStore",
        permissions: ["create"],
        subMenu: ["storeInvoiceDetail"],
        permissionsKey: "storeToStore",
      },
      {
        name: "debitNotes",
        value: "debitNotes",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["debitNotes"],
        permissionsKey: "debitNotes",
      },
      {
        name: "creditNotes",
        value: "creditNotes",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["creditNotes"],
        permissionsKey: "creditNotes",
      },
    ],
  },
  {
    name: "invoices",
    menus: [
      {
        name: "supplierInvoices",
        value: "supplierInvoices",
        permissions: ["fullAccess", "view", "pdf", "cancel"],
        subMenu: ["viewInvoice"],
        permissionsKey: "supplierInvoices",
      },
      {
        name: "saleInvoice",
        value: "saleInvoice",
        permissions: ["fullAccess", "view", "create", "edit", "cancel"],
        subMenu: [],
        permissionsKey: "saleInvoice",
      },
      {
        name: "delivered",
        value: "delivered",
        permissions: ["fullAccess", "view", "create", "edit", "cancel"],
        subMenu: [],
        permissionsKey: "delivered",
      },
      {
        name: "storeInvoices",
        value: "storeInvoices",
        permissions: ["fullAccess", "view", "create", "pdf", "cancel"],
        subMenu: ["storeInvoiceDetail"],
        permissionsKey: "storeInvoices",
      },
      {
        name: "stores",
        value: "stores",
        permissions: ["fullAccess", "view", "create", "edit", "delete", "assignUnassign"],
        subMenu: [],
        permissionsKey: "stores",
      },
    ],
  },
  {
    name: "receipts",
    menus: [
      {
        name: "supplierReceipts",
        value: "supplierReceipts",
        permissions: ["fullAccess", "view", "create", "pdf"],
        subMenu: ["duesReceipt"],
        permissionsKey: "supplierReceipts",
      },
      {
        name: "storeReceipts",
        value: "storeReceipts",
        permissions: ["fullAccess", "view", "create", "pdf"],
        subMenu: ["storeToStoreCredit", "viewReceipt"],
        permissionsKey: "storeReceipts",
      },
      {
        name: "bank",
        value: "bank",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "bank",
      },
    ],
  },
  {
    name: "storeSales",
    menus: [
      {
        name: "invoices",
        value: "invoices",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["viewStoreInvoice", "createInvoice"],
        permissionsKey: "invoices",
      },
      {
        name: "receipts",
        value: "receipts",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["viewStoreReceipt"],
        permissionsKey: "receipts",
      },
      {
        name: "customer",
        value: "customer",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "customer",
      },
    ],
  },
  {
    name: "currencyExchange",
    menus: [
      {
        name: "currencyExchange",
        value: "currencyExchange",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["viewCurrencyExchangeReceipt"],
        permissionsKey: "currencyExchange",
      },
    ],
    permissionsKey: "currencyExchange",
  },
  {
    name: "expenses",
    menus: [
      {
        name: "companyExpenses",
        value: "companyExpenses",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["viewExpenseDetail"],
        permissionsKey: "companyExpenses",
      },
      {
        name: "salary",
        value: "salary",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["employeeBalanceSheet", "viewSalaryDetail"],
        permissionsKey: "salary",
      },
      {
        name: "storeExpenses",
        value: "storeExpenses",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["viewStoreExpenseDetail"],
        permissionsKey: "storeExpenses",
      },
    ],
  },
  {
    name: "storeWithDrawals",
    menus: [
      {
        name: "storeWithDrawals",
        value: "storeWithDrawals",
        permissions: ["fullAccess", "view", "create"],
        subMenu: [],
        permissionsKey: "storeWithDrawals",
      },
    ],
  },
  {
    name: "fundTransfers",
    menus: [
      {
        name: "fundTransfers",
        value: "fundTransfers",
        permissions: ["fullAccess", "view", "create"],
        subMenu: ["fundTransferDetail"],
        permissionsKey: "fundTransfers",
      },
    ],
    permissionsKey: "fundTransfers",
  },
  {
    name: "trustFunds",
    menus: [
      {
        name: "deposit",
        value: "deposit",
        permissions: ["fullAccess", "view", "deposit"],
        subMenu: ["viewTrustDetail"],
        permissionsKey: "deposit",
      },
      {
        name: "withdraw",
        value: "withdraw",
        permissions: ["fullAccess", "view", "withdraw"],
        subMenu: ["viewTrustDetail"],
        permissionsKey: "withdraw",
      },
    ],
  },
  {
    name: "reports",
    menus: [
      {
        name: "companyAccounts",
        value: "companyAccounts",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: [
          "bankBalanceSheet",
          "viewTrustDetail",
          "fundTransferDetail",
          "viewReceipt",
          "viewSalaryDetails",
          "viewExpenseDetail",
          "storeToStoreReceiptDetail",
          "viewStoreReceipt",
          "viewCurrencyExchangeReceipt",
        ],
        permissionsKey: "companyAccounts",
      },
      {
        name: "storeAccounts",
        value: "storeAccounts",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: [
          "storeBalanceSheet",
          "viewSalaryDetails",
          "viewStoreExpenseDetail",
          "viewStoreReceipt",
          "storeInvoiceDetail",
          "storeToStoreCredit",
          "viewStoreInvoice",
          "viewReceipt",
        ],
        permissionsKey: "storeAccounts",
      },
      {
        name: "supplierAccounts",
        value: "supplierAccounts",
        permissions: ["fullAccess", "view", "pdf", "debitCredit"],
        subMenu: ["supplierBalanceSheet", "duesReceipt", "viewInvoice"],
        permissionsKey: "supplierAccounts",
      },
      {
        name: "supplierOrderHistory",
        value: "supplierOrderHistory",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: [],
        permissionsKey: "supplierOrderHistory",
      },
      {
        name: "customerOrderHistory",
        value: "customerOrderHistory",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: [],
        permissionsKey: "customerOrderHistory",
      },
      {
        name: "trustUserAccounts",
        value: "trustUserAccounts",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: ["TrustUserBalanceSheetList"],
        permissionsKey: "trustUserAccounts",
      },
      {
        name: "stockStatus",
        value: "stockStatus",
        permissions: ["fullAccess", "view", "pdf"],
        subMenu: ["stockStatus", "viewStockStatus"],
        permissionsKey: "stockStatus",
      },
    ],
  },
  {
    name: "utilities",
    menus: [
      {
        name: "trustUser",
        value: "trustUser",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: [],
        permissionsKey: "trustUser",
      },
      {
        name: "account",
        value: "account",
        permissions: ["fullAccess", "view", "create"],
        subMenu: [],
        permissionsKey: "account",
      },
    ],
  },
  {
    name: "teamManagement",
    menus: [
      {
        name: "userRoles",
        value: "userRoles",
        permissions: ["fullAccess", "view", "create", "edit", "delete"],
        subMenu: ["addUserRoles"],
        permissionsKey: "userRoles",
      },
      {
        name: "manageUsers",
        value: "manageUsers",
        permissions: ["fullAccess", "view", "create", "edit", "delete", "resetPassword"],
        subMenu: [],
        permissionsKey: "manageUsers",
      },
      {
        name: "selectStoreName",
        value: "selectStoreName",
        permissions: ["view"],
        subMenu: [],
        permissionsKey: "selectStoreName",
      },
    ],
  },
];

export default permissionData;
