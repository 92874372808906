import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Colors, colorPropType } from './Types'

const CTableHeaderCell = forwardRef(
    ({ children, className, color, customStyle, ...rest }, ref) => {
        const _className = classNames(
            {
                [`table-${color}`]: color,
            },
            className,
        )

        return (
            <th className={_className ? _className : undefined} {...rest} ref={ref} style={{ ...rest.style, ...customStyle }}>
                {children}
            </th>
        )
    },
)

CTableHeaderCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
}

export default CTableHeaderCell
