import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CLoadingButton,
  CModalFooter
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { FormDataRequestAction } from "../../../redux/actions/http";
import bulkImportIcon from "../../../assets/product_bulk.svg"
const BulkImportAccount = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.accountReducer);
  const [bulkFile, setBulkFile] = useState(null)
  const [showError, setShowError] = useState(false)
  const { accountBulkModalVisible,bulkAccountSpinner} = comProps;

  const modalType = "bulk_import_account";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const saveBulkFiles = () => {
    if (bulkFile) {
      dispatch(
        FormDataRequestAction("bulkImportCompanyAccount", bulkFile, "", "", "accountBulkSpinner")
      );
    }
    else {
      setShowError(true)
    }


  }

  const onFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setBulkFile(files[0])
    setShowError(false)

  };



  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        {/* <i className="icon icon-add me-1"></i> */}
        <div className="d-flex align-items-center">
          <img src={bulkImportIcon} style={{width : '20px', height : '20px',marginRight : 5}}  />
          <IntlMessages id="account.bulkImport" />
        </div>
      </CButton>

      <CModal visible={accountBulkModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("account.bulkImport")}
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <div className="d-flex justify-content-between">
          <input id="files" type="file" onChange={onFileChange} />
            {/* <div>
              <label for="files" class="btn"><IntlMessages id="product.uploadCsv" /></label>
              
             
            </div> */}
            <a href={"https://sagebiz.s3.eu-west-3.amazonaws.com/profiles/1640605988758-BULK_IMPORT_COMPANYACCOUNT%20-%201640349407793-nafaCompanyAccount%20-%20Sheet1%20%282%29%20%284%29.csv"} target="_blank"><IntlMessages id="product.sample" /></a>
          </div>
          {showError && <span style={{ color: 'red', fontSize: '12px' }}><IntlMessages id="account.csvRequired" /></span>}

        </CModalBody>
        <CModalFooter>
          <CLoadingButton disabled={bulkAccountSpinner} type="submit" color="primary" loading={bulkAccountSpinner} onClick={() => { saveBulkFiles() }}>
            {intlPlaceholder("account.addBulkModal.save")}

          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default BulkImportAccount;

