import React, { useEffect } from "react";
import { CForm, CLoadingButton } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import _ from "lodash";
import { FormInput } from "../../../components/form/form-input";

const InvoiceCompanyStoreProductForm = (props) => {
  const dispatch = useDispatch();
  const { localProducts, ProductList, editModalRecord } = useSelector(
    (state) => state.ProductR
  );
  const { stores } = useSelector((state) => state.distributeReducer);

  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    store: Yup.array()
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectStore_message" />
      )
      .nullable(),
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      )
      .nullable(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { handleSubmit, formState, control } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    dispatch({
      type: "setLocalProductStoreInvoice",
      payload: {
        product: _.map(values.product, "value"),
        isAddProduct: true,
        productsOfApi: editModalRecord?.products,
      },
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  useEffect(() => {
    if (editModalRecord?.storeId) {
      props.setValue("store", editModalRecord.storeId);
    }
  }, [editModalRecord]);

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row mb-4">
        {/* store */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(stores, function (o) {
                return o._id === editModalRecord?.storeId;
              });

              return (
                <FormInput
                  control={control}
                  errors={errors}
                  defaultValue={renderOptions(render, "label", "key")}
                  placeholder={intlPlaceholder(
                    "distributeProducts.pleaseSelectStore"
                  )}
                  name="store"
                  type="select"
                  isDisabled={true}
                  options={renderOptions(stores, "label", "key")}
                  onHandleChange={(value) => {
                    props.setValue("store", value?.value);

                    return !_.isEmpty(value) ? [value] : false;
                  }}
                />
              );
            })()}
          </div>
        </div>
        {/* product */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(ProductList, function (o) {
                return _.map(localProducts, "_id").includes(o._id);
              });

              return (
                <FormInput
                  control={control}
                  errors={errors}
                  defaultValue={renderOptions(render, "name", "_id")}
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                  name="product"
                  type="select"
                  isMulti={true}
                  options={renderOptions(ProductList, "name", "_id")}
                  onHandleChange={(value) => (value.length ? value : false)}
                />
              );
            })()}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CLoadingButton
            className="w-100 d-block"
            type="submit"
            color="primary"
          >
            <CIcon icon={cilPlus} className="me-2" />
            <IntlMessages id="product.addProductPage.addItem" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default InvoiceCompanyStoreProductForm;
