import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CBadge,
  CCardHeader,
  CCollapse,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import StoreExpenseForm from "./components/StoreExpenseForm";
import ExpenseList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const columns = [
    {
      label: <IntlMessages id="se.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.noDoc" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.storeName" />,
      key: "storeName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.type" />,
      key: "type",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="se.amount" />,
      key: "amount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.purpose" />,
      key: "purpose",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.createdBy" />,
      key: "createdBy",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="se.status" />,
      key: "status",
      _style: { width: "40%" },
    },

    {
      label: '',
      sorter : false,
      filter : false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.seReducer);

  const modalType = "show_se_modal";

  const viewRecord = (data) => {
    history.push({
      pathname: "/viewStoreExpenseDetail",
      state: { _id: data._id },
    });
  };

  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const fetchBankDrop = () => {
    dispatch(DataGetAction("fetchCompanyBank", "", "fetching"));
  };
  useEffect(fetchBankDrop, []);

  const fetchBank = () => {
    dispatch(
      DataGetAction(
        "getAllStoreExpense",
        { query: "all" },
        "allStoreExpenseSpinner"
      )
    );
    dispatch({
      type: "createdByFromStoreExpense",
    });
  };
  useEffect(fetchBank, [comProps.fetchBankInfo]);

  const fetchCreatedBy = () => {
    dispatch({
      type: "createdByFromStoreExpense",
    });
  };
  useEffect(fetchCreatedBy, [comProps.Banks]);

  const getBadge = (status) => {
    switch (status) {
      case 'approved':
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'pending':
        return 'warning'
      case 'block':
        return 'danger'
      default:
        return 'primary'
    }
  }
  if (comProps.isStoreExpensedAdded) {
    fetchBank()
    dispatch({
      type: "se_UPDATE",
    });
  }


  const create = CheckUserAccess('storeExpenses',['create']) 
  return (
    <>
      <CRow>
        <CCol xs={12}>
        
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="se.title" />
              </h1>
           
            </CCardHeader>
            <CCardBody>
            <div className="d-flex" style={{gap : '16px'}}>
              <Filter />
             {create&&  <StoreExpenseForm />}
            </div>
            <div className="ps-3">
              
            </div>
              <DocsExample href="components/smart-table/">
                <ExpenseList
                  loading={comProps.allStoreExpenseSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  
                  items={comProps.allStoreExpenseSpinner ? [] : comProps.Banks}
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },
                    storeName: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                            {record.storeName}
                            
                          </span>
                        </td>
                      );
                    },
                    type: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                              
                             <IntlMessages id={record.type} />
                          </span>
                        </td>
                      );
                    },
                   
                    amount: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            parseFloat(record.amount).toFixed(2)
                          )}
                        </td>
                      );
                    },


                    status: (record) => {
                      return (
                        <td>
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            <CBadge color={getBadge(record.status)}> <IntlMessages id={record.status} /> </CBadge>
                          </span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => viewRecord(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let totalExpense = 0;

                    pageData.forEach(({ amount }) => {
                      totalExpense += amount;
                    });

                    return (
                      <tr>
                        <th colSpan={4} className="gx-text-center"><h5>{intlPlaceholder('TotalExpense')}</h5></th>
                        <th colSpan={1} className="text-end">
                          <h5>{ChangeNumberFormate(totalExpense > 0 ? totalExpense.toFixed(2) : 0)}</h5>
                        </th>
                        <th colSpan={4}></th>
                      </tr>

                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
