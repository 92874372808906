import React, {useState} from 'react'
import {

    CForm,
    CFormInput,
    CFormFloating,
    CFormLabel,
  } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder"
import { DataRequestAction } from '../../../redux/actions/http'
import { useDispatch,useSelector } from 'react-redux'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import {notify} from '../../../util/ShowToast'
const AddForm = ({defaultValues, ButtonComponent}) => {

    const comProps = useSelector((state) => state.customer);

    const { customerLoader, selectedItem, modalVisible, success } = comProps;

    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState()


  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      <IntlMessages id="customer.addCustomerModal.name_message" />
    ),
    phoneNumber: Yup.string().required(
      <IntlMessages id="customer.addCustomerModal.phoneNumber_message" />
    ),
  });

    const formOptions = { resolver: yupResolver(validationSchema), defaultValues};


    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
    const { errors } = formState;

    const onFinish = values => {
     

        if (defaultValues === null) {
            dispatch(DataRequestAction('POST', 'addCustomer', values, '', 'customer', false));
        } else {
            values._id = defaultValues._id;
            dispatch(DataRequestAction('PUT', 'editCustomer', values, '', 'customer', false));
        }

    };
    if (success) {
      notify('success',intlPlaceholder('recordAddeddSuccessfully'));
        reset();
        dispatch({
          type: "customer_UPDATE",
        });
      }
      if (comProps.error) {
        let message = intlPlaceholder(comProps.message)
        notify('error',message);
        dispatch({
          type: "customer_UPDATE",
        });
      }
    return (
        <CForm
        onSubmit={handleSubmit(onFinish)}
    >
                   <CFormFloating  className="mb-3">
              <CFormInput
                className={errors.name ? "is-invalid" : ""}
                {...register("name")}
                placeholder={intlPlaceholder("customer.addCustomerModal.name")}
                type="text"
                id="name"
              />
              <CFormLabel htmlFor="name">{intlPlaceholder("customer.addCustomerModal.name")}</CFormLabel>
              <div className="invalid-feedback">{errors.name?.message}</div>
              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </CFormFloating>

            <CFormFloating className="mb-3">
              <CFormInput
               
                {...register("email")}
                placeholder={intlPlaceholder("customer.addCustomerModal.email")}
                type="text"
                id="email"
              />
              <CFormLabel htmlFor="email">{intlPlaceholder("customer.addCustomerModal.email")}</CFormLabel>
              
            </CFormFloating>

            <div className="mb-3">
            <Controller
            control={control}
            defaultValues={defaultValues && defaultValues.phoneNumber}
            name={'phoneNumber'}
            render={(props) => {
                return (
                    <PhoneInput
                        country={'us'}
                        value={defaultValues && defaultValues.phoneNumber}
                        onChange={(v) => props.field.onChange(v)}
                        inputProps={{
                            name: 'phoneNumber',
                            required: true,
                        }}
                    />
                );
            }}
        />
              <div className="error-message">{errors.phoneNumber?.message}</div>
            </div>

           
        {ButtonComponent}
    </CForm>
    )
}

export default AddForm
