import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import DistributeForm from "./components/DistributeForm";
import IntlMessages from "../../util/IntlMessages";
const Index = () => {
  const dispatch = useDispatch();
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <h1>
                {" "}
                <IntlMessages id="distributeProducts.distributeProducts" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <DistributeForm />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
