import React, { useState } from "react";

import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import CSmartTable from "src/components/CSSmartTable"

const AccountList = ({ Data,Spinner}) => {


  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const modalType = "show_account_modal";


  const columns = [
    {
      label: <IntlMessages id="account.number" />,
      key: "number",
      _style: { width: "40%" },
    },

    {
      label:<IntlMessages id="account.bank" />,
      key: "bank",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="account.currency" />,
      key: "currency",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="account.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },

    
  ];

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  
  const editRecord = (data) => {
    dispatch({
      type: 'edit_category_modal',
      payload: data,
    });
  };



  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={Spinner}
        items={Spinner?[]:Data && Data.length > 0 ? Data : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          bank: (record) => (
            <td>
              {record.bank === "Cash" ?  <IntlMessages id="account.cash" />  : record.bank}
            </td>
          ),
          balance: (record) => (
            <td className="text-end">
              {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
            </td>
          ),
      // {
        //     title: <IntlMessages id="account.action" />,
        //     render: (record) => {
        //         return (
        //             <Popover placement="bottom" content={
        //                 <div className="userActionMenu">
        //                     <span style={{ cursor: 'pointer' }} onClick={() => creditRecord(record.number)}><EditOutlined style={{ color: 'green' }} /> <span>Credit</span></span>
        //                     {/* <DeleteConfirm
        //                         type="deleteAccount"
        //                         selectedItem={record}
        //                         buttonName="Delete"
        //                         title="Are you sure delete Account?"
        //                         content=""
        //                         Icon={<span><DeleteOutlined style={{ color: '#eb2f96' }} /> <span style={{ color: '#545454' }}>Delete</span></span>}
        //                     /> */}
        //                 </div>
        //             } title="" trigger="click" >
        //                 <span className="gx-pointer" style={{ fontSize: '20px', fontWeight: 'bold' }}><MoreOutlined /></span>
        //             </Popover >)
        //     },
        //     width: 200
        // },
        }}
      />
     
    </>
  );
};

export default AccountList;
