import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'src/redux/actions/http'
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import moment from "moment";
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import cashAccountIcon from '../../../assets/image.png'
const StatisticsOfCashAccount = () => {


    const dispatch = useDispatch()
    const { cashAccountDetail } = useSelector(state => state.dashboard)

    const fetchTrustFundStatistics = () => {
        dispatch(DataGetAction('statisticsOfCashAccount', {
            startDate: moment().startOf("today").toString(),
            endDate: moment().toString(),
            initialDate: moment().subtract(1, 'days').endOf("day").toString(),

        }, 'StartSpinner'))
    }

    useEffect(fetchTrustFundStatistics, [])

    let columns = [
        {
            label: <IntlMessages id="dashboard.cashAccount.currency" />,
            key: "currencyName",
            _customStyle: { width: "20%", background: '#038FDE', color: '#ffffff', textAlign: "center" },
            filter: false,
            sorter: false,
        },

        {
            label: <IntlMessages id="dashboard.cashAccount.balance" />,
            key: "balance",
            _customStyle: { width: "40%", background: '#038FDE', color: '#ffffff', borderLeftWidth: '1px', textAlign: "center" },
            filter: false,
            sorter: false,
        },
        {
            label: <IntlMessages id="dashboard.cashAccount.initial" />,
            key: "initialBalance",
            _customStyle: { width: "40%", background: '#038FDE', color: '#ffffff', borderLeftWidth: '1px', textAlign: "center" },
            filter: false,
            sorter: false,
        }



    ];


    return (
        <div style={{
            border: '1px solid #DE9503',
            borderRadius: 8,

        }}>
            <div style={{
                background: '#DE9503',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8
            }}>
                <img src={cashAccountIcon} />
                <span style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginLeft: 8,
                    color: '#ffffff'
                }}><IntlMessages id="cashAccount" /></span></div>
            <CSmartTable
                sorterValue={{ column: "name", state: "asc" }}
                clickableRows
                tableProps={{
                    striped: true,
                    hover: true,
                }}
                activePage={3}
                loading={false}
                items={cashAccountDetail}
                columns={columns}
                itemsPerPageSelect={false}
                itemsPerPage={5}
                columnSorter
                pagination
                scopedColumns={{
                    currencyName: (record) => {
                        return (
                            <>
                                <td style={{ textTransform: "capitalize", textAlign: "center" }}>
                                    {record && record.currencyName}
                                </td>
                            </>
                        );
                    },

                    initialBalance: (record) => {
                        return (
                            <>
                                <td style={{ textTransform: "capitalize", textAlign: "right" }}>
                                    {record && record.initialBalance ? ChangeNumberFormate(parseFloat(record.initialBalance).toFixed(2)) : 0}
                                </td>
                            </>
                        );
                    },
                    balance: (record) => {
                        return (
                            <>
                                <td style={{ textTransform: "capitalize",  textAlign: "right" }}>
                                    {record && record.balance ? ChangeNumberFormate(parseFloat(record.balance).toFixed(2)) : 0}
                                </td>
                            </>
                        );
                    },
                }}

            />
        </div>
    )
}

export default StatisticsOfCashAccount