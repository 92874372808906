import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge,
  CLoadingButton,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
} from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import InvoiceList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import moment from "moment";
import pdfImage from "../../assets/images/avatars/pdf.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CheckUserAccess from "../../util/checkUserAccess";
import InvoiceCompanyToStoreModal from "./components/invoice-company-store-modal";
import _ from "lodash";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {
  const [details, setDetails] = useState([]);
  const [stateSpinner, setSpinner] = useState(false);
  const [staticFilterValue, setStaticFilterValue] = useState({
    status: "approved",
  });
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.invoiceReducer);
  const storeProps = useSelector((state) => state.storeReducer);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;
  const [ReceiptNumber, setReceiptNumber] = useState();
  const { CreatedByList } = invProps;
  const { Stores } = storeProps;

  const [filterValue, setFilterValue] = useState(null);
  let ConstHeader = intlPlaceholder("report.store.invoice");
  let constHeaderTo = intlPlaceholder("report.to");
  let constHeaderFrom = intlPlaceholder("report.from");

  const history = useHistory();

  const fetchProduct = () => {
    dispatch({
      type: "resetLocalProduct",
    });
    setFilterValue({
      startDate: moment().toString(),
      endDate: moment().toString(),
    });
     dispatch(
       DataGetAction(
         "getAllInvoices",
         {
           startDate: moment().toString(),
           endDate: moment().toString(),
           rk: "invoiceType",
           rt: "CompanyToStore",
         },
         "allStoreInvoicesSpinner"
       )
     );
  };
  useEffect(fetchProduct, []);

  const redirectToPage = () => {
    history.push("/distribute");
  };

  const ViewInvoice = (data) => {
    history.push({
      pathname: "/viewStoreInvoice",
      state: { InvoiceNo: data.invoiceNumber, prefix: data.prefix },
    });
  };

  const handleSearch = () => {
    let splitOnArray = ReceiptNumber.split("-");
    let number = splitOnArray[0];
    if (splitOnArray.length === 2) {
      number = splitOnArray[1];
    }

    dispatch({
      type: "Search_store_invoice_number",
      payload: number,
    });
  };

  const searchBySupplierName = (e) => {
    dispatch({
      type: "Search_storeInvoice_store_Id",
      payload: e,
    });
  };

  const Search_by_createdBy = (e) => {
    dispatch({
      type: "Search_store_Invoice_by_created_by",
      payload: e,
    });
  };

  const ViewStoreInvoice = (data) => {
    if (data.prefix === "FC") {
      history.push({
        pathname: "/viewStoreInvoice",
        state: {
          InvoiceNo: data.invoiceNumber,
          prefix: data.prefix,
          invoiceType: data.invoiceType,
        },
      });
    } else {
      history.push({
        pathname: "/storeInvoiceDetail",
        state: {
          InvoiceNo: data.invoiceNumber,
          prefix: data.prefix,
          invoiceType: data.invoiceType,
        },
      });
    }
  };

  const renderAction = (data) => {
    if (data.invoiceType === "CompanyToClient") {
      return (
        <>
          <CButton
            size="sm"
            color="warning"
            className="me-3"
            onClick={() => ViewInvoice(data)}
          >
            <span style={{ color: "white" }}>
              <span className="icon icon-custom-view me-2"></span>
              <IntlMessages id="view" />
            </span>
          </CButton>
        </>
      );
    } else {
      return (
        <>
          <CButton
            size="sm"
            color="warning"
            className="me-3"
            onClick={() => ViewStoreInvoice(data)}
          >
            <span style={{ color: "white" }}>
              <span className="icon icon-custom-view me-2"></span>
              <IntlMessages id="view" />
            </span>
          </CButton>
          {true && (
            <CButton size="sm" color="warning" onClick={() => editRecord(data)}>
              <i className="icon icon-edit text-white me-1"></i>
              <span style={{ color: "white" }}>
                <IntlMessages id="Edit" />
              </span>
            </CButton>
          )}
        </>
      );
    }
  };

  let bodyData = [
    [
      intlPlaceholder("Date"),
      intlPlaceholder("RefNumber"),
      intlPlaceholder("storeName"),
      intlPlaceholder("amount"),
      intlPlaceholder("createdBy"),
    ],
  ];

  const downloadPDF = async (data) => {
    let Record = data;

    Record.forEach((Item, index) => {
      let date = ChangeDateFormate(Item.createdAt);
      let amount = parseFloat(Item.totalPrice).toFixed(2);
      amount = NumberWithCommas(amount);
      let objArr = [date];

      objArr.push(`${Item.prefix}-${Item.invoiceNumber}`);
      objArr.push(Item.store);
      objArr.push(amount);
      objArr.push(Item.createdBy);

      bodyData = [...bodyData, objArr];
    });

    let startDate = moment().startOf("year").toString();
    startDate = ChangeDateFormate(startDate, "YYYY-MM-DD");
    let endDate = moment().toString();
    endDate = ChangeDateFormate(endDate, "YYYY-MM-DD");

    if (filterValue.startDate && filterValue.endDate) {
      startDate = ChangeDateFormate(filterValue.startDate, "YYYY-MM-DD");
      endDate = ChangeDateFormate(filterValue.endDate, "YYYY-MM-DD");
    }

    let headerTitle =
      ConstHeader.toUpperCase() +
      " " +
      constHeaderFrom +
      " " +
      startDate +
      " " +
      constHeaderTo +
      " " +
      endDate;

    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `Email : ${LogeInUser.email}  - Cell.: +${LogeInUser.phoneNumber}`;
    let docDefinition = {
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },

        {
          text: headerTitle,
          style: { fontSize: 12, bold: true, alignment: "center" },
        },
        {
          style: "tableExample",
          headerRows: 1,

          table: {
            widths: ["*", "*", "*", "auto", "auto"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
        },
      },
    };
    setTimeout(function () {
      pdfMake.createPdf(docDefinition).download("storeInvoice.pdf");
      setSpinner(false);
    }, 1000);
  };

  const columns = [
    {
      label: <IntlMessages id="invoice.number" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.storeName" />,
      key: "store",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoice.amount" />,
      key: "totalPrice",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoice.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: "dropDown",
      filterDropDownValue: [
        { label: intlPlaceholder("invoice.all"), value: "all" },
        {
          label: intlPlaceholder("invoice.approved"),
          value: "approved",
          selected: true,
        },
        { label: intlPlaceholder("invoice.cancelled"), value: "cancelled" },
      ],
      _style: { width: "40%" },
    },
    {
      label: "",
      filter: false,
      sorter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const editRecord = (data) => {
    let productList = _.map(data.products, "_id");

    if (data.invoiceType === "StoreToStore") {
      //show popup comming soon
    }
    if (data.invoiceType === "CompanyToStore") {
      dispatch({
        type: "setLocalProductStoreInvoice",
        payload: { product: productList, dataProducts: data.products },
      });

      dispatch({
        type: "editProductStoreInvoice_Modal",
        payload: data,
      });
    }
  };

  const create = CheckUserAccess("storeInvoices", ["create"]);
  const pdf = CheckUserAccess("storeInvoices", ["pdf"]);
  const edit = CheckUserAccess("storeInvoices", ["edit"]);

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="invoice.storeInvoice.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter setFilter={setFilterValue} />
                <div className="d-flex align-items-center">
                  {create && (
                    <CButton
                      color="success"
                      className="me-3 text-white"
                      onClick={() => redirectToPage()}
                    >
                      <i className="icon icon-add me-1"></i>
                      <IntlMessages id="addInvoice" />
                    </CButton>
                  )}
                  {pdf && (
                    <CLoadingButton
                      color="danger"
                      loading={stateSpinner}
                      disabled={stateSpinner}
                      className="text-white"
                      onClick={() => {
                        setSpinner(true);
                        downloadPDF(invProps.Invoices);
                      }}
                    >
                      <span style={{ color: "white" }}>
                        <i className="gx-icon-pdf-300 me-1"></i>
                        <IntlMessages id="pdf" />
                      </span>
                    </CLoadingButton>
                  )}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <InvoiceList
                  loading={invProps.allStoreInvoicesSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={
                    invProps.allStoreInvoicesSpinner
                      ? []
                      : invProps.storeInvoiceList
                  }
                  columns={columns}
                  columnFilterValue={staticFilterValue}
                  filterValueChangeTo={(value) => {
                    if (value.status) {
                      setStaticFilterValue(value);
                    }
                  }}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge
                            style={{
                              textTransform: "capitalize",
                            }}
                            color={
                              record.status === "approved"
                                ? "success"
                                : "danger"
                            }
                          >
                            {" "}
                            {intlPlaceholder(`invoice.${record.status}`)}
                          </CBadge>
                        </td>
                      );
                    },

                    refNo: (record) => {
                      return (
                        <td>
                          <span>{record.refNo}</span>
                        </td>
                      );
                    },

                    store: (record) => {
                      return (
                        <td>
                          <span>{record.store}</span>
                        </td>
                      );
                    },
                    address: (record) => {
                      return (
                        <td>
                          <span>{record.address}</span>
                        </td>
                      );
                    },
                    totalPrice: (record) => {
                      return (
                        <td className="text-end">
                          <span>
                            {ChangeNumberFormate(record.totalPrice.toFixed(2))}
                          </span>
                        </td>
                      );
                    },

                    createdBy: (record) => {
                      return (
                        <td>
                          <span>{record.createdBy}</span>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? (
                              <IntlMessages id="hide" />
                            ) : (
                              <IntlMessages id="show" />
                            )}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let total = 0;
                    pageData.forEach(({ totalPrice }) => {
                      total += totalPrice;
                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center">
                          <h5>
                            <IntlMessages id="Total" />
                          </h5>
                        </th>

                        <th colSpan={2} className="text-end">
                          <span>
                            <h5>{ChangeNumberFormate(total.toFixed(2))}</h5>
                          </span>
                        </th>
                        <th colSpan={2}>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <InvoiceCompanyToStoreModal
        key="invoiceCompanyToStoreModal"
        modalType="editProductStoreInvoice_Modal"
        title={"Edit Store Invoice"}
      />
    </>
  );
};

export default Index;
