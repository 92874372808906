import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormSelect,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction } from "../../../../redux/actions/http";
import Select from "react-select";
import {selectStyle} from '../../../../constants/CommonFn'
const EmployeeFilter = ({ setFilters, initialValues }) => {
  const dispatch = useDispatch();
  const emProps = useSelector((state) => state.trustFund);
  const currencyProps = useSelector((state) => state.currencyReducer);

  const fetchAllBankAndCurrencyList = () => {
    dispatch(DataGetAction("getAllBank", { query: "all" }));
    dispatch(DataGetAction("getAllCurrency", { query: "all" }));
  };

  useEffect(fetchAllBankAndCurrencyList, []);
  const { Employee } = emProps;
  

  const { Currencies } = currencyProps;

  const onFinish = (values) => {
    let SelectedEmployee = Employee.filter((x) => x._id === values.employeeId);
    let selectedCurrency = Currencies.filter((x) => x._id === values.currency);
    values.employee = SelectedEmployee;
    values.currencyS = selectedCurrency;
    setFilters(values);
    let data = {
      employeeId: values.employeeId,
      currency: values.currency,
    };
    dispatch(
      DataGetAction(
        "balanceSheetForTrustUser",
        data,
        "fetchingBalanceSheetForTrustUser"
      )
    );
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    currency: Yup.string().required(
      <IntlMessages id="balanceSheet.filter.selectCurreny_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;


  const renderOptions = (Employee) =>{

    if(Employee && Employee.length > 0 ){
      return Employee.map((data) => ({
        label: `${data.firstName} ${data.lastName ? data.lastName : ''} ${(data.empId)}`,
        value: data._id,
      }));
    }
    else {
      return []
    }
  }



  return (
    <div>
      <CForm onSubmit={handleSubmit(onFinish)}>
        <div className="d-flex">
          <div className="me-3">
            <div className="w-100" style={{minWidth : '100%', width : '150px'}}>
            <Controller
              control={control}
              name="employeeId"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                styles={selectStyle}
                className={errors.employeeId ? "is-invalid w-100-always" : "w-100-always"}
                isSearchable={true}
                placeholder={intlPlaceholder("balanceSheet.filter.employeeType")}
                onChange={(e) => {
                  onChange(e.value);
                }}
                options={renderOptions(Employee)}
              />
              )}
            />
             
            </div>

            <div className="invalid-feedback">{errors.employeeId?.message}</div>
          </div>

          <div className="me-3">
            <CFormSelect
              {...register("currency")}
              className={errors.trusty ? "is-invalid" : ""}
            >
              <option value="" hidden>
                {intlPlaceholder("balanceSheet.filter.selectCurreny")}
              </option>
              {Currencies &&
                Currencies.map((Item) => {
                  return (
                    <option value={Item._id}>
                      {Item.symbol} ({Item.name})
                    </option>
                  );
                })}
            </CFormSelect>
            <div className="invalid-feedback">{errors.currency?.message}</div>
          </div>
          <CLoadingButton
            className="d-flex align-items-center"
            type="submit"
            color="primary"
          >
            <i className="gx-icon-filter-300 me-1 text-white"></i>
            <IntlMessages id="balanceSheet.generateButton" />
          </CLoadingButton>
        </div>
      </CForm>
    </div>
  );
};

export default EmployeeFilter;
