import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceProductList from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
const ReceiptDetail = () => {
  const componentRef = useRef();

  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const dispatch = useDispatch();

  const invProps = useSelector((state) => state.storeToStoreReducer);
  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  let [printBalance, setPrintBalance] = useState(true);
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;
  const {
    invoiceNumber,
    prefix,
    createdAt,
    store,
    dStore,
    createdBy,
    products,
    currencyInfo,
    invoiceType,
  } = invProps.InvoiceDetail !== null ? invProps.InvoiceDetail : "000-000-000";

  const { storeDue } = invoiceReducer;
  const storeName = store && store[0] ? store[0].label : "";
  const storeAddress = store && store[0] ? store[0].address : "";
  const DStoreName = dStore && dStore[0] ? dStore[0].label : "";
  const DStoreAddress = dStore && dStore[0] ? dStore[0].address : "";
  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";

  const emailSent = store && store[0] ? store[0].email : "";

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantity" />,
      key: "quantity",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unitPrice" />,
      key: "unitPrice",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.total" />,
      key: "total",
      _style: { width: "40%" },
    },
  ];

  const renderChangeWord = () => {
    let amount = parseFloat(storeDue);
    if (amount < 0) {
      amount = -1 * amount;
    }
    return ChangeNumberToWord(amount, languageId);
  };
  const getAmountBill = () => {
    const Products = products && products;
    if (Products) {
      let total = 0;
      Products.map((x) => {
        total = total + x.total;
        return x;
      });

      return (
        <div className="d-flex justify-content-between mt-4">
          <p></p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "20%", fontSize: 12, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBill" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "80%", fontSize: 12, fontWeight: "bold" }}
              >
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""}
                {ChangeNumberFormate(total.toFixed(2))}
              </span>
            </div>
            <div className="d-flex">
              <span
                className="d-inline-block "
                style={{ width: "20%", fontSize: 12, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBillWords" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "80%", fontSize: 12 }}
              >
                {ChangeNumberToWord(total, languageId)}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };
  const getAmountDue = () => {
    const Products = products && products;
    if (Products) {
      let total = 0;
      Products.map((x) => {
        total = total + x.total;
        return x;
      });

      return (
        <div className="d-flex justify-content-between mt-2">
          <p></p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "20%", fontSize: 12, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.amountDue" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "80%", fontSize: 12, fontWeight: "bold" }}
              >
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""}
                {ChangeNumberFormate(storeDue.toFixed(2))}
              </span>
            </div>
            <div className="d-flex">
              <span
                className="d-inline-block "
                style={{ width: "20%", fontSize: 12, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.amountDueWord" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "80%", fontSize: 12 }}
              >
                {renderChangeWord()}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const getRecordValue = () => {
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");
    let totalBillFigure = 0;
    let totalBillWords = 0;
    const Products = products && products;
    if (Products) {
      let total = 0;
      Products.map((x) => {
        total = total + x.total;
        return x;
      });

      totalBillFigure = `${
        currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : ""
      } ${NumberWithCommas(parseFloat(total))}`;
      totalBillWords = ChangeNumberToWord(total, languageId);
    }
    let data = [];

    let totalBalanceFigure = `${
      currencyInfo && currencyInfo.length > 0 ? currencyInfo[0].name : ""
    } ${NumberWithCommas(parseFloat(storeDue))}`;
    let amount = storeDue;
    if (amount < -1) {
      amount = -1 * amount;
    }

    let totalBalanceWords = ChangeNumberToWord(amount, languageId);

    if (products && products.length > 0) {
      products.map((item) => {
        let { productName, quantity, unitName, salePrice, total, ...rest } = item;
        let obj = {
          productName,
          quantity,
          unitName,
          unitPrice: NumberWithCommas(parseFloat(salePrice)),
          total:
            currencyInfo &&
            currencyInfo[0].name + NumberWithCommas(parseFloat(total)),
        };

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("invoiceDetail.destinationStore");
    let key2 = "";
    if (invoiceType === "CompanyToStore") {
      key1 = intlPlaceholder("invoiceDetail.storeLabel");
    } else {
      key2 = intlPlaceholder("invoiceDetail.resourceStore");
    }

    let key3 = intlPlaceholder("invoiceDetail.StoreToStoreCreditNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("invoiceDetail.productName"),
      intlPlaceholder("invoiceDetail.quantity"),
      intlPlaceholder("invoiceDetail.unit"),
      intlPlaceholder("invoiceDetail.unitPrice"),
      intlPlaceholder("invoiceDetail.total"),
    ];

    let storeDetail = {};
    if (invoiceType === "CompanyToStore") {
      storeDetail = { name: storeName, address: storeAddress };
    } else {
      storeDetail = { name: DStoreName, address: DStoreAddress };
    }

    let detailObj = {
      [key1]: storeDetail,
    };

    if (key2 !== "") {
      detailObj[`${key2}`] = { name: storeName, address: storeAddress };
    }
    detailObj = {
      ...detailObj,
      [key3]: prefix + "-" + invoiceNumber,
      [key4]: createdByName,
    };

    let obj = {
      subject: `${intlPlaceholder(
        "invoiceDetail.StoreToStoreCreditLabel"
      )}  ${intlPlaceholder('from')} ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: `${intlPlaceholder("invoiceDetail.StoreToStoreCreditLabel")}`,
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,
      totalBillKey: intlPlaceholder("invoiceDetail.totalBill"),
      totalBillWorldKey: intlPlaceholder("invoiceDetail.totalBillWords"),

      totalBillFigures: totalBillFigure,
      totalBillWords: totalBillWords,
      totalBalanceKey: intlPlaceholder("invoiceDetail.amountDue"),
      totalBalanceWorldKey: intlPlaceholder("invoiceDetail.amountDueWord"),
      totalBalanceFigure: totalBalanceFigure,
      totalBalanceWords: totalBalanceWords,
      note: intlPlaceholder("invoiceDetail.note"),
    };

    if (invoiceType !== "StoreToStoreRecipt") {
      obj = {
        ...obj,
      };
    }

    return obj;
  };

  const styles = {
    label: {
      fontSize: "14px",
      color: "grey",
      width: "90px",
      color: "grey",
      fontWeight: "bold",
      textTransform: "capitalize",
      marginBottom: 0,
    },
    para: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    to:{
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      color: "grey",
      fontWeight: "bold",
      fontSize: "14px",
    },
    address: {
      color: "grey",
      maxWidth: "255px",
      textTransform: "capitalize",
      fontSize: "12px",
      textAlign: "center",
    },
    storeAddress: {
      marginBottom: 0,
      width: "200px",
      textAlign: "center",
      fontSize: "12px",
      textTransform: "capitalize",
      color: "grey",
    },
    priceAlign: {
      textAlign: "right"
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <CButton
            color="warning"
            className="text-white me-3"
            onClick={() => {
              dispatch({
                type: "send_email_modal",
              });
            }}
          >
            <i className="icon icon-mail-open me-1 mb-1"></i>
            <IntlMessages id="sendMail" />
          </CButton>

          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />

          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="printNotBalance" />
              </CButton>
            )}
            content={() => componentRef.current}
            onBeforeGetContent={() => {
              setPrintBalance(false)
              return Promise.resolve();
            }
            }
            onAfterPrint={() => {
              setPrintBalance(true)
              return Promise.resolve();
            }
            }
          />

        </div>
      </div>
      <div className="print-invoice p-3 float-left w-100 pe-5 ps-5 mt-5" ref={componentRef}>

      <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <img src={logoImage} alt="logo" width="300" className="mb-2" />

            <p
              className="mb-0"
              style={styles.address}
            >
              {address}
            </p>
          </div>
          <div>
            <div className="d-flex">
            </div>
            <div className="d-flex">
              {invoiceType !== "CompanyToStore" ? (
                <>
                 <p style={styles.label}>
                    <IntlMessages id="invoiceDetail.resourceStore" />:
                  </p>
                  <div>
                    <p style={styles.para}>{storeName}</p>
                    <p style={styles.storeAddress}>{storeAddress}</p>
                  </div>
                 
                </>
              ) : (
                <>
                  <p style={styles.label}>
                    <IntlMessages id="invoiceDetail.storeLabel" />:
                  </p>
                  <div>
                    <p style={styles.para}>{storeName}</p>
                    <p style={styles.storeAddress}>{storeAddress}</p>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex">
              {invoiceType !== "CompanyToStore" && (
                <>
                   <p style={styles.label}>
                    <IntlMessages id="invoiceDetail.destinationStore" />:
                  </p>
                  <div>
                    <p style={styles.para}>{DStoreName}</p>

                    <p style={styles.storeAddress}>{DStoreAddress}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="me-1">
                <IntlMessages id="invoiceDetail.StoreToStoreCreditLabel" />
                </span>:
                <span className="ms-1">{ invoiceNumber}</span>
              </h2>
            </div>

            <small style={{ color: "grey" }}>
              
              {ChangeDateFormate(createdAt)}
            </small>
          </div>
        </div>
        <br />
        <div className="Table">
          <InvoiceProductList
            items={products}
            columns={columns}
            scopedColumns={{
              quantity: (record) => {
                return <td>{parseFloat(record.quantity).toFixed(3)}</td>;
              },
              unit: (record) => {
                return <td>{record.unitName}</td>;
              },
              unitPrice: (record) => {
                return (
                  <td style={styles.priceAlign}>
                    {ChangeNumberFormate(
                      parseFloat(record.salePrice).toFixed(2)
                    )}
                  </td>
                );
              },
              total: (record) => {
                return (
                  <td style={styles.priceAlign}>
                    
                    {ChangeNumberFormate(parseFloat(record.total).toFixed(2))}
                  </td>
                );
              },
            }}
          />
        </div>

        {getAmountBill()}
        {printBalance && getAmountDue()}
        <InvoiceSignature/>
      </div>
      <SendEmailModal
        initialValues={{ email: emailSent }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default ReceiptDetail;
