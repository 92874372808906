import React, { useEffect } from "react";
import {
    CForm,
    CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../util/IntlMessages";
import { intlPlaceholder } from "../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../redux/actions/Common";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {DataRequestAction} from '../redux/actions/http'
import {notify} from '../util/ShowToast'
const SendEmailModal = ({  initialValues,  record, type = 'invoice' }) => {


  const dispatch = useDispatch();
  const commonProps = useSelector((state) => state.commonR);
  const { send_email_modal, spinner, success, error, message } = commonProps;
  const modalType = "send_email_modal";

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(
      <IntlMessages id="sendEmailModal_message" />
    ).email(),
  });


  const formOptions = {
    resolver: yupResolver(validationSchema),
    // defaultValues : {
    //   email : initialValues.email
    // }

  };
  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if(send_email_modal){
      reset(initialValues)
    }
  }, [send_email_modal])

  const onFinish = (values) => {
    let finalValue = { ...values, ...record };
    
    switch (type) {
      case 'trustFundDetail':
        dispatch(
          DataRequestAction(
            "POST",
            "sendEmailTrustFundDetail",
            finalValue,
            "",
            "sendingInvoiceEmail",
            false
          )
        );
        break;
      case 'invoice':
        dispatch(
          DataRequestAction(
            "POST",
            "sendInvoiceEmail",
            finalValue,
            "",
            "sendingInvoiceEmail",
            false
          )
        );
        break;
    }    
  };

  if (success) {
   
    let messageLng = intlPlaceholder(message)
    notify('success',messageLng)
    
    dispatch({
      type: "common_update",
    });
  }
  if (error) {
    let messageLng = intlPlaceholder(message)
    notify('error',messageLng)
   
    dispatch({
      type: "common_update",
    });
  }
  const sendEmailDesc = intlPlaceholder("sendEmailDesc")

  return (
    <div>
      <CModal visible={send_email_modal}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("sendEmailModalTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <p>
              {sendEmailDesc}
              
            </p>

            <div className="mb-3">
              <CFormInput
                className={errors.email ? "is-invalid" : ""}
                {...register("email")}
                placeholder={intlPlaceholder("sendEmailModalPlaceholder")}
                type="text"
                id="email"
              />
              <div className="invalid-feedback">
                {errors.email?.message}
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CLoadingButton type="submit" color="primary" loading={spinner} disabled={spinner}>
              {intlPlaceholder("sendEmailButton")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default SendEmailModal;
