import React from "react";
import {
  CForm,
  CFormInput,
  CFormSelect,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";
const AccountForm = ({ defaultValues, ButtonComponent }) => {
  const comProps = useSelector((state) => state.accountReducer);
  const { Banks, Currencies, success, error, message } = comProps;

  const dispatch = useDispatch();

  // form validation rules
  const validationSchema = Yup.object().shape({
    bankId: Yup.string().required(
      <IntlMessages id="account.createAccountModal.account_message" />
    ),
    currencyId: Yup.string().required(
      <IntlMessages id="account.createAccountModal.currency_message" />
    ),
    number: Yup.string().required(
      <IntlMessages id="account.createAccountModal.accountNumber" />
    ),
    balance: Yup.number()
      .typeError(
        <IntlMessages id="account.createAccountModal.balance_message" />
      )
      .required(
        <IntlMessages id="account.createAccountModal.balance_message" />
      ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;


  const onFinish = (values) => {
    dispatch(
      DataRequestAction("POST", "addAccount", values, "", "account", false)
    );
  };
  if (success) {
    let messageLng = intlPlaceholder(message)
    notify("success", messageLng);
    dispatch({
      type: "account_UPDATE",
    });
  }
  if (error) {
    let messageLng = intlPlaceholder(message)
    notify("error", messageLng);
    dispatch({
      type: "account_UPDATE",
    });
  }
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormSelect
            {...register("bankId")}
            className={errors.bankId ? "is-invalid" : ""}
            id="bankId"
          >
            <option value="" hidden>
              {intlPlaceholder(
                "account.createAccountModal.account_placeholder"
              )}
            </option>
            {Banks.map((Item) => {
              return <option value={Item.Key}>{Item.label}</option>;
            })}
          </CFormSelect>
          <CFormLabel htmlFor="location">
            {intlPlaceholder("account.createAccountModal.account_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.bankId?.message}</div>
        </CFormFloating>

        <div className="invalid-feedback">{errors.bankId?.message}</div>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormSelect
            {...register("currencyId")}
            className={errors.currencyId ? "is-invalid" : ""}
            id="currencyId"
          >
            <option value="" hidden>
              {intlPlaceholder(
                "account.createAccountModal.currency_placeholder"
              )}
            </option>
            {Currencies.map((Item) => {
              return <option value={Item.Key}>{Item.label}</option>;
            })}
          </CFormSelect>

          <CFormLabel htmlFor="currencyId">
            {intlPlaceholder("account.createAccountModal.currency_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.currencyId?.message}</div>
        </CFormFloating>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.number ? "is-invalid" : ""}
            {...register("number")}
            placeholder={intlPlaceholder(
              "account.createAccountModal.accountNumber"
            )}
            type="text"
            id="number"
          />
          <CFormLabel htmlFor="number">
            {intlPlaceholder("account.createAccountModal.accountNumber")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.number?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
      <CFormFloating className="mb-3">
      <CFormInput
          className={errors.balance ? "is-invalid" : ""}
          {...register("balance")}
          placeholder={intlPlaceholder("account.createAccountModal.balance")}
          type="text"
          id="balance"
        />
          <CFormLabel htmlFor="balance">
            {intlPlaceholder("account.createAccountModal.balance")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.balance?.message}</div>
        </CFormFloating>
       
       
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default AccountForm;
