import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory} from "react-router-dom";
import CSmartTable from "src/components/CSSmartTable"
import {  ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import AddModal from './components/AddModal'
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const ViewReceipt = (data) => {
    history.push({
      pathname: "/fundTransferDetail",
      state: { transferId: data._id },
    });
  };

  const columns = [
    {
      label: <IntlMessages id="fundTransfer.referenceNumber" />,
      key: "refNo",
      _style: { width: "40%" },
      sorter: false,

    },
    {
      label: <IntlMessages id="fundTransfer.source" />,
      key: "sourceBankName",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.destination" />,
      key: "destinationBankName",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.currency" />,
      key: "currencySymbol",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.amount" />,
      key: "amount",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.transferMode" />,
      key: "transferMode",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.transferDate" />,
      key: "transferDate",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="fundTransfer.createdDate" />,
      key: "createdAt",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      key: "show_details",
      label : '',
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.accountReducer);
  const modalType = "show_fundTransfer_modal";

  const { TransferRecord, transferringSuccess, allBankTransferSpinner } =
    comProps;




  const showModal = () => {
    dispatch({
      type: modalType,
    });
  };

  const fetchFundTransfer = () => {
    dispatch(
      DataGetAction(
        "getAllBankTransfer",
        { query: "all" },
        "allBankTransferSpinner"
      )
    );
  };
  useEffect(fetchFundTransfer, []);

  let cash = intlPlaceholder("fundTransfer.cash")
  const create = CheckUserAccess('fundTransfers',['create'])
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="fundTransfer.title" />
              </h1>
              
            </CCardHeader>
            <CCardBody>
            <div className="p-3 pb-0">
            {create &&  <AddModal />}
            </div>
              <DocsExample href="components/smart-table/">
                <CSmartTable
                  loading={allBankTransferSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={allBankTransferSpinner ? [] : TransferRecord}
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    referenceNumber: (record) => {
                      return <td>{record.prefix + "-" + record.transferId}</td>;
                    },
                    sourceBankName: (record) => {
                      return (
                        <td>
                        {record.sourceBankName ||  `${cash}(0000)` }
                       
                      </td>
                       
                      );
                    },
                    destinationBankName: (record) => {
                      
                      return (
                        <td>
                          {record.destinationBankName ||  `${cash}(0000)` }
                         
                        </td>
                      );
                    },
                    amount: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            parseFloat(record.amount).toFixed(2)
                          )}
                        </td>
                      );
                    },
                    transferMode: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                            {record.transferMode.replace(
                              /([a-z0-9])([A-Z])/g,
                              "$1 $2"
                            )}
                          </span>
                        </td>
                      );
                    },
                    transferDate: (record) => {
                      return <td>{record.transferDate}</td>;
                    },
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                           <CCardBody>
                           <CButton
                            size="sm"
                            color="warning"
                            className="me-3"
                            onClick={() => ViewReceipt(record)}
                          >
                            <span style={{ color: "white" }}>
                              <span className="icon icon-custom-view me-2"></span>
                              <IntlMessages id="view" />
                            </span>
                          </CButton>
                           </CCardBody>
                        
                        </CCollapse>
                      );
                    },
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
