import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import ProfileForm from "./components/ProfileForm";
import Upload from 'rc-upload'
import { notify } from "src/util/ShowToast";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {
  const dispatch = useDispatch();
  const [profileLogoSpinner, setProfileLogoSpinner] = useState(false)
  const { loader } = useSelector((state) => state.profile);
  const [fileUrl, setFileUrl] = useState('')
  const fetchCompanyDetail = () => {
    dispatch(DataGetAction("getProfileInfo", "", "fetchingData"));
  };

  useEffect(fetchCompanyDetail, []);

  const {profileInfo} = useSelector(state => state.profile)



  const setInitialPageData = () =>{
    if(profileInfo &&profileInfo.profileImage){
      setFileUrl(profileInfo.profileImage)
    }
  }

  useEffect(setInitialPageData, [profileInfo])

  let someErrorOccured = intlPlaceholder('someErrorOccured')
  let imageSizeShouldBeLessThan1Mb = intlPlaceholder('imageSizeShouldBeLessThan1Mb')
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <strong>
                <IntlMessages id="profile.companyProfile" />
              </strong>
            </CCardHeader>
            {loader ? (
              <div
                style={{ height: 200 }}
                className="w-100 d-flex align-items-center justify-content-center"
              >
                <CSpinner variant="grow" color="primary" />
              </div>
            ) : (
              <CCardBody>
                <div className="row">
                  <div className="col-md-4">
                    <div className="App">
                   {!fileUrl && <Upload 
                    onSuccess={(file)=>{
                      setProfileLogoSpinner(false)
                     
                      
                      if(file &&file.url && file.url.location){
                        setFileUrl(file.url.location)
                        dispatch({
                          type: "profileImageUrl_Upload",
                          payload: file.url,
                          image: file.image
          
                      })
                      }
                    }}
                    name="file" 
                    multiple={false} 
                    
                    
                    action={`${ROOT_URL}/uploadProfileImage`}  
                    onStart={(file)=>{
                      setProfileLogoSpinner(true)
                      
                    }}
                    onError={(error)=>{
                      setProfileLogoSpinner(false)
                      notify('error',someErrorOccured)
                      
                    }}
                    beforeUpload={(file)=>{
                      
                      let originalSize = file.size
                      if((originalSize/1000000 )> 1){
                        notify('error',imageSizeShouldBeLessThan1Mb)
                       
                        return false
                      }
                      else return true
                     
                    }}
                    />}
                    {fileUrl && 
                    
                    <div
                    className="position-relative"
                    >
                      <i onClick={()=>{setFileUrl('')}} className="icon icon-close-circle" style={{position : 'absolute', top : 10, right : 10, cursor : 'Pointer', color : 'white', background : 'black', display : 'flex', justifyContent : 'between', alignItems : 'center', borderRadius : '10px'}}></i>
                      <img src={fileUrl} width={'100%'} style={{height : '190px', objectFit : 'contain'}} alt=""/>
                    
                    </div>
                    }
                    {profileLogoSpinner && <CSpinner/>}
                     
                    </div>
                  </div>
                  <div className="col-md-8">
                    <ProfileForm fileUrl={fileUrl} />
                  </div>
                </div>
              </CCardBody>
            )}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
