import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  storeLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  Stores: [],
  Employees: [],
  fetchStoreInfo: false,
  ShowWithDrawModal: false,
  WithDrawLoader: false,
  withDrawSuccess: false,
  storeWithdrawList: [],
  storeSpinner: false,
  storeWithDrawSpinner: false,
  editModalVisible: false,
  storeBulkModalVisible: false,
  storeBulkUpload_success: false,
  storeBulkUpload_error: false,
  clientBulkSpinner: false,
  StoresDropDownList: [],
  storeDropDownLoader:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getAllStoreWithdraw_SUCCESS": {
      return {
        ...state,
        storeWithdrawList: action.payload.Record,
      };
    }
    case "storeWithDrawSpinner_START": {
      return { ...state, storeWithDrawSpinner: true };
    }
    case "storeWithDrawSpinner_STOP": {
      return { ...state, storeWithDrawSpinner: false };
    }
    case "withdrawing_START": {
      return {
        ...state,
        WithDrawLoader: true,
      };
    }
    case "storeWithdraw_SUCCESS": {
      return {
        ...state,
        ShowWithDrawModal: false,
        WithDrawLoader: false,
        withDrawSuccess: true,
        selectedItem: null,
        // storeWithdrawList: [action.payload.Record,...state.storeWithdrawList]
      };
    }
    case "showWithDrawModal": {
      return {
        ...state,
        ShowWithDrawModal: !state.ShowWithDrawModal,
      };
    }

    case "getAllStore_SUCCESS": {
      return { ...state, Stores: action.payload.Record, fetchStoreInfo: false };
    }
    case "CleanStore_SUCCESS": {
      return {
        ...state,
        Stores:[],
        
      };
    }
    case "findStore_SUCCESS": {
      return {
        ...state,
        Stores: action.payload.Record,
        storeProductSpinner: false,
      };
    }
    case "storeSpinner_START": {
      return { ...state, storeSpinner: true };
    }
    case "storeSpinner_STOP": {
      return { ...state, storeSpinner: false };
    }

    case "fetchEmployees_SUCCESS": {
      const employeeList = getEmployeeList(action.payload.Record);
      return { ...state, Employees: employeeList };
    }

    case "newStoreActivatedInfo_SUCCESS": {
      const updateStoreList = getStoreList(action.payload, state.Stores);
      return { ...state, Stores: updateStoreList };
    }

    case "show_store_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }
    case "edit_store_modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        selectedItem: action.payload,
      };
    }
    case "assign_employee_modal": {
      return {
        ...state,
        assignEmployeeModalVisible: !state.assignEmployeeModalVisible,
        selectedItem: action.payload,
      };
    }

    case "assignEmployees_SUCCESS": {
      return {
        ...state,
        fetchStoreInfo: true,
        success: true,
        message: "recordUpdateSuccessfully",
      };
    }
    case "store_START": {
      return { ...state, storeLoader: true };
    }
    case "store_STOP": {
      return { ...state, storeLoader: false };
    }

    case "addStore_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        Stores: [action.payload.Record, ...state.Stores],
        storeLoader: false,
      };
    }
    case "addStore_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        storeLoader: false,
        message: action.payload,
      };
    }

    case "editStore_SUCCESS": {
      let oldCat = state.Stores;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        Stores: newCat,
        storeLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        editModalVisible: false,
      };
    }
    case "editStore_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        storeLoader: false,
        message: action.payload,
        editModalVisible: false,
      };
    }

    case "bulk_import_store": {
      return {
        ...state,
        storeBulkModalVisible: !state.storeBulkModalVisible,
      };
    }
    case "clientAccountSpinner_START": {
      return {
        ...state,
        clientBulkSpinner: true,
      };
    }
    case "bulkImportClientAccount_SUCCESS": {
      return {
        ...state,
        storeBulkUpload_success: true,
        clientBulkSpinner: false,
        textMessage: action.payload.message,
      };
    }
    case "bulkImportClientAccount_FAILURE": {
      return {
        ...state,
        storeBulkUpload_error: true,
        clientBulkSpinner: false,
        textMessage: action.payload,
      };
    }

    case "store_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        editModalVisible: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        storeLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        withDrawSuccess: false,
        storeBulkModalVisible: false,
        storeBulkUpload_success: false,
        storeBulkUpload_error: false,
      };
    }

    case "deleteStore_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Stores;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        Stores: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    case "getStoresDropDownList_SUCCESS": {
      return {
        ...state,
        StoresDropDownList: action.payload.Record,
        storeDropDownLoader: false,
      };
    }
    case "storesDropDownListSpin_START": {
      return {
        ...state,
        storeDropDownLoader: true,
      };
    }
    default:
      return state;
  }

  function getEmployeeList(data) {
    let obj = [];
    data.map((Item) => {
      obj.push({
        value: Item._id,
        label: Item.name,
      });
      return Item;
    });
    return obj;
  }

  function getStoreList(latest, record) {
    const newArray = [];
    record.map((Item) => {
      if (Item._id === latest._id) {
        newArray.push(latest);
      } else {
        newArray.push(Item);
      }
      return Item;
    });

    return newArray;
  }
};
