import React, { forwardRef, HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { colorPropType } from './Types'
const CTableHead = forwardRef(({ children, className, color, ...rest }, ref) => {
    const _className = classNames(
        {
            [`table-${color}`]: color,
        },
        className,
    )

    return (
        <thead className={_className ? _className : undefined} {...rest} ref={ref}>
            {children}
        </thead>
    )
})


CTableHead.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
}

export default CTableHead
