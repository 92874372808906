import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../../redux/actions/Common";
import UserForm from "./UserForm";
import { DataGetAction } from "../../../../redux/actions/http";
const AddModal = () => {
  const dispatch = useDispatch();


  const comProps = useSelector((state) => state.users);

  const { addingUser,  modalVisible } = comProps;



  const fetchReceipt = () => {
    if (modalVisible) {
      dispatch(DataGetAction("getReceiptDropDown", ""));
    }
  };
  useEffect(fetchReceipt, [modalVisible]);

  const modalType = "show_user_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };



  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
      <i className="icon icon-add me-1"></i>
      <IntlMessages id="users.addButton" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            { intlPlaceholder('users.addModalTitle')}
          </CModalTitle>
        </CModalHeader>
       
          <CModalBody>
            <UserForm
              defaultValues={null}
              isModalVisible={modalVisible}
              ButtonComponent={
                <CModalFooter>
                  <CButton
                    color="secondary"
                    onClick={() =>
                      dispatch(DataEntryModalAction(modalType, null))
                    }
                  >
                <IntlMessages id="users.modal.cancel" />
                  </CButton>

                  <CLoadingButton
                    type="submit"
                    color="primary"
                    loading={addingUser}
                    disabled={addingUser}
                  >
                    { intlPlaceholder('users.modal.save') }
                  </CLoadingButton>
                </CModalFooter>
              }
            />


          </CModalBody>
       
      </CModal>
    </div>
  );
};

export default AddModal;
