import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import StoreTable from "./components/StoreTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddStoreModal from "./components/AddStoreModal";
import BulkImportStore from "./components/BulkImportStore";
import EditStoreModal from "./components/EditStoreModal";
import AssignEmployee from "./components/AssignEmployee";
import CheckUserAccess from "../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { notify } from "src/util/ShowToast";
const SmartTable = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.storeReducer);

  const { fetchStoreInfo, storeSpinner, Stores } = comProps;

  const fetchCategory = () => {
    dispatch(DataGetAction("getAllStore", { query: "all" }, "storeSpinner"));
  };
  useEffect(fetchCategory, [fetchStoreInfo]);
  const create = CheckUserAccess("stores", ["create"]);

  const { storeBulkUpload_success, storeBulkUpload_error, textMessage } =
    comProps;

  if (storeBulkUpload_success) {
    let messageLng = intlPlaceholder(textMessage);
    fetchCategory();
    notify("success", messageLng);
    dispatch({
      type: "store_UPDATE",
    });
  }
  if (storeBulkUpload_error) {
    let messageLng = intlPlaceholder(textMessage);
    notify("error", messageLng);
    dispatch({
      type: "store_UPDATE",
    });
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="store.title" />
              </h1>
              
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex" style={{gap : 10}}>
                {create && <AddStoreModal />}
                <BulkImportStore/>
                </div>
              <DocsExample href="components/smart-table/">
                <StoreTable Stores={Stores} storeSpinner={storeSpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
        <AssignEmployee type="assign_employee_modal" title="Assign Employee" />
        <EditStoreModal />
      </CRow>
    </>
  );
};

export default SmartTable;
