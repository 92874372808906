import React, { useState } from "react";
import CSmartTable from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";
import { useHistory } from "react-router-dom";

const BankBalanceSheetList = () => {
  const dispatch = useDispatch();
  const { BankBalanceSheet, bankBalanceSheetSpinner } = useSelector(
    (state) => state.balanceSheet
  );
  const history = useHistory();

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "prefix",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.out" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.in" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];

  const redirectToPage = (record) => {
    const url = record.receiptType;
    const referenceNo = record.reference;


    if (url === "withdraw") {
      history.push({
        pathname: `/viewTrustDetail`,
        state: {
          query: "id",
          _id:
            record.paymentMode === "cash"
              ? record.reference
              : record.referenceId,
        },
      });
    } else if (url === "Deposit") {
      history.push({
        pathname: `/viewTrustDetail`,
        state: {
          query: "id",
          _id:
            record.paymentMode === "cash"
              ? record.reference
              : record.referenceId,
        },
      });
    } else if (url === "bankFundTransfer") {
      history.push({
        pathname: `/fundTransferDetail`,
        state: { transferId: record.referenceId },
      });
    } else if (url === "storeWithdraw") {
      history.push({
        pathname: `/viewReceipt`,
        state: { ReceiptNo: parseInt(referenceNo) },
      });
    } else if (url === "salaryPosted") {
      history.push({
        pathname: `/viewSalaryDetails`,
        state: { guid: record.reference },
      });
    } else if (url === "companyExpense" || url === "refundCompanyExpense") {
      history.push({
        pathname: `/viewExpenseDetail`,
        state: { _id: referenceNo },
      });
    } else if (url === "storeInvoiceDetail") {
      history.push({
        pathname: `/${url}`,
        state: { InvoiceNo: parseInt(referenceNo) },
      });
    } else if (url === "storeToStoreSaleReceipt") {
      history.push({
        pathname: `/storeToStoreReceiptDetail`,
        state: { ReceiptNo: parseInt(referenceNo) },
      });
    } else if (url === "storeToCustomer" || url === "refundStoreToCustomer") {
      history.push({
        pathname: `/viewStoreReceipt`,
        state: { ReceiptNo: parseInt(referenceNo) },
      });
    } else if (url === "viewCurrencyExchangeReceipt") {
      history.push({
        pathname: `/viewCurrencyExchangeReceipt`,
        state: { currencyExchangeId: parseInt(referenceNo) },
      });
    } else {
      history.push({
        pathname: `/${url}`,
        state: { ReceiptNo: parseInt(referenceNo) },
      });
    }
  };

  return (
    <>
      <CSmartTable
        loading={bankBalanceSheetSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={bankBalanceSheetSpinner ? [] : BankBalanceSheet}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={500}
        columnSorter
        pagination
        underTableSlot={<>Total: {"sfds"}</>}
        scopedColumns={{
          description: (record) => {
            return <td>{<IntlMessages id={record.description} />}</td>;
          },
          createdAt: (record) => {
            return <td>{ChangeDateFormate(record.createdAt)}</td>;
          },
          prefix: (record) => {
            if (record.reference === "0000") {
              return <td>----</td>;
            } else {
              return (
                <td
                  onClick={() => redirectToPage(record)}
                  style={{
                    cursor: "pointer",

                    color: "blue",
                  }}
                >
                 {record.prefix}
                </td>
              );
            }
          },
          debit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.debit && record.debit.toFixed(2))}
              </td>
            );
          },
          credit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.credit && record.credit.toFixed(2))}
              </td>
            );
          },
          balance: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.balance && parseFloat(record.balance).toFixed(2)
                )}
              </td>
            );
          },
        }}
        summary={(pageData) => {
          let totalBorrow = 0;
          let totalRepayment = 0;

          pageData.forEach(({ debit, credit }) => {
            totalBorrow += debit;
            totalRepayment += credit;
          });

          return (
            <tr>
              <th colSpan={3} className="gx-text-center">
                TOTAL
              </th>
              <th className="text-end">
                <span>
                  {ChangeNumberFormate(
                    totalBorrow > 0 ? totalBorrow.toFixed(2) : totalBorrow
                  )}
                </span>
              </th>
              <th className="text-end"> 
                <span>
                  {ChangeNumberFormate(
                    totalRepayment > 0
                      ? totalRepayment.toFixed(2)
                      : totalRepayment
                  )}
                </span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          );
        }}
      />
    </>
  );
};

export default BankBalanceSheetList;
