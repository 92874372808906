import React from 'react'
import Table from "./CSSmartTable";
const InvoiceTable = ({items,columns,scopedColumns}) => {
    return (
        <Table
        clickableRows
        tableProps={{
          striped: false,
          hover: true,
          bordered: true,
          borderColor: 'info'
        }}
        
        customClass="custom-table-inovice"
        pagination={false}
        itemsPerPage={(items && items.length ) || 100}
        items={items}
        columns={columns}
        scopedColumns={scopedColumns}
        />
       
    )
}

export default InvoiceTable
