import React, { useState, useEffect } from "react";
import UnitList from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";

import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import { CCardBody, CButton, CCollapse } from "@coreui/react-pro";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const SubProductList = ({ showAction = true, setValue, reset, setDisableSaveBtn }) => {
  const [details, setDetails] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [key, setKey] = useState(0);
  const prodProps = useSelector((state) => state.productReducer);
  const dispatch = useDispatch();


  let priceShouldBeNumeric = intlPlaceholder("priceShouldBeNumeric")
  let priceShouldBeBiggerThan1 = intlPlaceholder("priceShouldBeBiggerThan1")

  const deleteRecord = (record) => {
    dispatch({
      type: "deleteSubProduct_SUCCESS",
      payload: record,
    });
  };
  const { Currency, Units, SubProducts } = prodProps;

  useEffect(() => {
    setTableData(SubProducts);
  }, [SubProducts]);

  const setAmount = (result) => {
    let total = 0;
    result &&
      result.map((item) => {
        total = total + item.totalLocalPrice;
      });

    dispatch({
      type: "updatedProductList",
      payload: [...result],
    });
    setTableData([...result]);
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}
      >
        <span className="icon icon-phone"></span>
      </div>
    );
  }
  let columns = [
    {
      dataField: "productName",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
     
    },
    {
      dataField: "quantity",
      text: <IntlMessages id="product.quantity" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setKey(key + 1);
          return {
            valid: false,
            message: "qty should be numeric",
          };
        }
        if (newValue < 1) {
          setKey(key + 1);
          return {
            valid: false,
            message: "qty should bigger than 1",
          };
        }
        return 1;
      },
      classes: () => {
        return "selectable-cell";
      },
     
    },
    {
      dataField: "unit",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell, record) => {
        const index = _.findIndex(Units, { _id: record.unit });
        if (index > -1) {
          return <span>{Units[index].label}</span>;
        } else {
          let data = null;
          Units.map((x, index) => {
            let subIndex = _.findIndex(x.subUnits, { _id: record.unit });
            if (subIndex > 0) {
              data = { index, subIndex };
            }
            return x;
          });
          return (
            <span>
              {data && Units[data.index].subUnits[data.subIndex].subUnitName}
            </span>
          );
        }
      },
     
    },
    {
      dataField: "currency",
      editable: false,
      text: <IntlMessages id="product.currency" />,
      formatter: (cell, record) => {
        const index = _.findIndex(Currency, { _id: record.currency });
        return <span>{Currency[index].label}</span>;
      },
     
    },
    {
      dataField: "price",
      text: <IntlMessages id="product.price" />,
      formatter: (cell, record) => {
        return (
          <span>
            {ChangeNumberFormate(parseFloat(record.price).toFixed(2))}
          </span>
        );
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true)
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue < 1) {
          setDisableSaveBtn(true)
          return {
            valid: false,
            message: priceShouldBeBiggerThan1,
          };
        }
        else {
          setDisableSaveBtn(false)
          return {
            valid: true
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    
    },
    {
      dataField: "totalUnitPrice",
      editable: false,
      text: <IntlMessages id="product.totalPrice" />,
      formatter: (cell, record) => {
        return (
          <span>
            {ChangeNumberFormate(parseFloat(record.totalUnitPrice).toFixed(2))}
          </span>
        );
      },
    },
    {
      dataField: "localPrice",
      editable: false,
      text: <IntlMessages id="product.localPrice" />,
      formatter: (cell, record) => {
        return (
          <span>
            {ChangeNumberFormate(parseFloat(record.localPrice).toFixed(2))}
          </span>
        );
      },
    
    },
    {
      dataField: "totalLocalPrice",
      editable: false,
      text: <IntlMessages id="product.totalLocalPrice" />,
      formatter: (cell, record) => {
        return (
          <span>
            {ChangeNumberFormate(parseFloat(record.totalLocalPrice.toFixed(2)))}
          </span>
        );
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },

    },
  ];

  let currencyType = ''
  if(SubProducts && SubProducts.length > 0 ){
    let subProductItems = SubProducts && SubProducts[0];
    let currencyItems = _.findIndex(Currency, { _id: subProductItems.currency });
    currencyType = Currency[currencyItems].type 
  
  }

  if (SubProducts.length > 0) {
    const Data = SubProducts && SubProducts[0];
    const index = _.findIndex(Currency, { _id: Data.currency });
    if (Currency[index].type === "local") {
      columns = [
        {
          dataField: "productName",
          editable: false,
          text: <IntlMessages id="product.name" />,
          formatter: (cell) => {
            return <span>{cell}</span>;
          },
        },
        {
          dataField: "quantity",
          text: <IntlMessages id="product.quantity" />,
          formatter: (cell) => {
            return <span>{cell}</span>;
          },
          classes: () => {
            return "selectable-cell";
          },
        },
        {
          dataField: "unit",
          text: <IntlMessages id="product.unit" />,
          editable: false,
          formatter: (cell, record) => {
            const index = _.findIndex(Units, { _id: record.unit });
            if (index > -1) {
              return <span>{Units[index].label}</span>;
            } else {
              let data = null;
              Units.map((x, index) => {
                let subIndex = _.findIndex(x.subUnits, { _id: record.unit });
                if (subIndex > 0) {
                  data = { index, subIndex };
                }
                return x;
              });
              return (
                <span>
                  {data &&
                    Units[data.index].subUnits[data.subIndex].subUnitName}
                </span>
              );
            }
          },
        },
        {
          dataField: "currency",
          editable: false,
          text: <IntlMessages id="product.currency" />,
          formatter: (cell, record) => {
            const index = _.findIndex(Currency, { _id: record.currency });
            return <span>{Currency[index].label}</span>;
          },
        },

        {
          dataField: "price",
          text: <IntlMessages id="product.localPrice" />,
          formatter: (cell, record) => {
            return (
              <span>
                {(parseFloat(record.price).toFixed(2))}
              </span>
            );
          },
          validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
              setDisableSaveBtn(true)
              return {
                valid: false,
                message: priceShouldBeNumeric,
              };
            }
            if (newValue < 1) {
              setDisableSaveBtn(true)
              return {
                valid: false,
                message: priceShouldBeBiggerThan1,
              };
            }
            else{
              setDisableSaveBtn(false)
              return {
                valid: true
              };
            }            
          },
          classes: () => {
            return "selectable-cell";
          },
         
        },
        {
          dataField: "totalLocalPrice",
          editable: false,
          text: <IntlMessages id="product.TotalPrice" />,
          formatter: (cell, record) => {
            return (
              <span>
                {ChangeNumberFormate(
                  parseFloat(record.totalLocalPrice).toFixed(2)
                )}
              </span>
            );
          },
        },
        {
          dataField: "action",
          editable: false,
          text: <IntlMessages id="product.action" />,
          formatter: (cell, record) => {
            return (
              <span
                style={{
                  cursor: "pointer",
                  color: "red",
                }}
                onClick={() => deleteRecord(record)}
              >
                <span className="icon icon-trash"></span>
              </span>
            );
          },
        },
      ];
    }
  }

  const renderTotalPriceLocal = () => {
    let total = 0

    tableData.forEach(item => {
      total = item.totalLocalPrice + total
    })
    total = ChangeNumberFormate(total.toFixed(2))
    return total
  }
  const renderTotalPriceForeign = () => {
    let total = 0

    tableData.forEach(item => {
      total = parseFloat(item.totalUnitPrice) + parseFloat(total)
    })
    total = ChangeNumberFormate(total.toFixed(2))
    return total
  }

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        key={key}
        data={tableData}
        columns={columns}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField; // it could only be 'quantity or 'price' or 'localPrice'

            const Data = SubProducts && SubProducts[0];
            const index = _.findIndex(Currency, { _id: Data.currency });

            if (Currency[index].type === "local") {
              if (changedField === "quantity") {
                let newData =
                  tableData &&
                  tableData.length > 0 &&
                  tableData.map((item) => {
                    if (item.productId === row.productId) {
                      item[changedField] = newValue;
                      item.totalLocalPrice = newValue * parseFloat(item.price);
                      item.totalUnitPrice =
                        parseFloat(item.price) * parseFloat(item.quantity);
                      return { ...item };
                    } else {
                      return { ...item };
                    }
                  });

                setAmount(newData);
              } else if (changedField === "price") {
                let newData =
                  tableData &&
                  tableData.length > 0 &&
                  tableData.map((item) => {
                    if (item.productId === row.productId) {
                      item[changedField] = newValue;
                      // item.totalLocalPrice = (newValue * item.totalLocalPrice) / oldValue;
                      item.localPrice = parseFloat(item.price);
                      item.totalLocalPrice =  parseFloat(newValue) * parseFloat(item.quantity);
                      item.totalUnitPrice = parseFloat(newValue) * parseFloat(item.quantity);
                      return { ...item };
                    } else {
                      return { ...item };
                    }
                  });

                setAmount(newData);
              }
            } else {
              if (changedField === "quantity") {
                let newData =
                  tableData &&
                  tableData.length > 0 &&
                  tableData.map((item) => {
                    if (item.productId === row.productId) {
                      item[changedField] = newValue;
                      item.totalLocalPrice =
                        newValue * parseFloat(item.localPrice);
                      item.totalUnitPrice = newValue * parseFloat(item.price);

                      return { ...item };
                    } else {
                      return { ...item };
                    }
                  });

                setAmount(newData);
              } else if (changedField === "price") {
                let newData =
                  tableData &&
                  tableData.length > 0 &&
                  tableData.map((item) => {
                    if (item.productId === row.productId) {
                      item[changedField] = parseFloat(newValue);
                      // item.totalLocalPrice = (newValue * item.totalLocalPrice) / oldValue;
                      item.localPrice = parseFloat(newValue) * parseFloat(item.currencyFactor);
                      item.totalLocalPrice = parseFloat(newValue) * parseFloat(item.quantity) *  parseFloat(item.currencyFactor);
                      item.totalUnitPrice =  parseFloat(newValue) * parseFloat(item.quantity);

                
                      return { ...item };
                    } else {
                      return { ...item };
                    }
                  });
                setAmount(newData);
              }
            }
          },
        })}
      />




      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tr>
          <td colspan="5" style={{ textAlign: 'center' }}>
            <h5>Total</h5>
          </td>

        {currencyType === 'foreign' && <td style={{ textAlign: 'center' }} colspan="2">
            <h5>{renderTotalPriceForeign()}</h5>

          </td>}
          <td colspan="3" style={{ textAlign: 'center' }}>
            <h5>{renderTotalPriceLocal()}</h5>
          </td>
        </tr>

      </table>
    </>
  );
};

export default SubProductList;
