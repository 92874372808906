import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  productLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  ProductList: [],
  fetchProductInfo: false,
  productSpinner: false,
  editModalVisible: false,
  productForMultiSelect: [],
  localProducts: [],
  productBulkModalVisible: false,
  productBulkUpload_success: false,
  productBulkUpload_error: false,
  accountBulkModalVisible: false,
  accountBulkUpload_error: false,
  accountBulkUpload_success: false,
  bulkAccountSpinner: false,
  textMessage: "",
  bulkProductSpinner: false,
  editModalRecord: [],
  distributeUpdateLoader: false,
  originalLocalProduct: [],
  editFetchSuccess: false,
  editSpinner: false,
  initialValue: null,
  loadingProductSpinner: false,
  subunitList: [],
  distributeDropSpin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RestProductStock": {
      return {
        ...state,
        ProductList: [],
      };
    }

    case "EditRecordSpinner_START": {
      return {
        ...state,
        editSpinner: true,
      };
    }

    case "reset_edit_success": {
      return {
        ...state,
        editFetchSuccess: false,
      };
    }

    case "getEditProduct_SUCCESS": {
      let SelectedData = [];
      if (
        action.payload &&
        action.payload.Record &&
        action.payload.Record.length > 0
      ) {
        action.payload.Record.map((Item) => {
          SelectedData.push(Item.productId);
        });
      }
      return {
        ...state,
        localProducts: action.payload.Record,
        originalLocalProduct: action.payload.Record,
        editFetchSuccess: true,
        editSpinner: false,
        initialValue: {
          product: SelectedData,
        },
      };
    }

    case "LoadingProduct_START": {
      return {
        ...state,
        loadingProductSpinner: true,
      };
    }

    case "getAllProductStockByCategory_SUCCESS": {
      return {
        ...state,
        ProductList: action.payload.Record,
        fetchProductInfo: false,
        productForMultiSelect: [],
        ProductList: [],
      };
    }
    case "getAllProductStock_SUCCESS": {
      const productForMultiSelect =
        action.payload.Record &&
        action.payload.Record.length > 0 &&
        action.payload.Record.map((item) => {
          return {
            ...item,
            value: JSON.stringify(item),
            text: item.name,
          };
        });

      return {
        ...state,
        ProductList: action.payload.Record,
        fetchProductInfo: false,
        productForMultiSelect: productForMultiSelect,
        loadingProductSpinner: false,
        distributeDropSpin: false,
      };
    }
    case "getAllProductStockSpin_START": {
      return {
        ...state,
        distributeDropSpin: true,
      };
    }
    case "setLocalProduct": {
      const result = getLocalProducts(
        action.payload,
        state.localProducts,
        state.ProductList
      );

      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "updateLocalProduct": {
      const result = getUpdatedLocalProducts(
        action.payload,
        state.localProducts
      );
      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "resetLocalProduct": {
      return {
        ...state,
        localProducts: [],
      };
    }

    case "deleteLocalProduct": {
      const updatedProducts = deleteLocalProduct(
        state.localProducts,
        action.payload
      );

      return {
        ...state,
        localProducts: [...updatedProducts],
      };
    }
    case "RESET_LOCAL_PRODUCT": {
      return {
        ...state,
        localProducts: [],
      };
    }

    case "productSpinner_START": {
      return { ...state, productSpinner: true };
    }
    case "productSpinner_STOP": {
      return { ...state, productSpinner: false };
    }
    case "show_productStock_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }

    case "edit_productStock_modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        selectedItem: action.payload,
      };
    }

    case "productStock_START": {
      return { ...state, productLoader: true };
    }
    case "productStock_STOP": {
      return { ...state, productLoader: false };
    }
    case "editProductStock_SUCCESS": {
      let oldCat = state.ProductList;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        ProductList: newCat,
        productLoader: false,
        success: true,
        message: "Record updated successfully",
        modalVisible: false,
      };
    }
    case "editProductStock_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        productLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }
    case "bulk_import_product": {
      return {
        ...state,
        productBulkModalVisible: !state.productBulkModalVisible,
      };
    }
    case "bulkImportProductSpinner_START": {
      return {
        ...state,
        bulkProductSpinner: true,
      };
    }
    case "bulkImportProduct_SUCCESS": {
      return {
        ...state,
        productBulkUpload_success: true,
        textMessage: action.payload.message,
        bulkProductSpinner: false,
      };
    }
    case "bulkImportProduct_FAILURE": {
      return {
        ...state,
        productBulkUpload_error: true,
        textMessage: action.payload,
        bulkProductSpinner: false,
      };
    }

    case "productStock_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        productLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        editModalVisible: false,
        productBulkUpload_success: false,
        productBulkUpload_error: false,
        productBulkModalVisible: false,
      };
    }

    case "addProductStock_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        ProductList: [action.payload.Record, ...state.ProductList],
        productLoader: false,
      };
    }
    case "addProductStock_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        productLoader: false,
        message: action.payload,
      };
    }

    case "editProductStock_SUCCESS": {
      let oldCat = state.ProductList;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        ProductList: newCat,
        productLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editProductStock_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        productLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "productStock_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        productLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        editModalVisible: false,
      };
    }

    case "deleteProductStock_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.ProductList;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        ProductList: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    case "editProductStoreInvoice_Modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        editModalRecord: action.payload,
        distributeUpdateLoader: false,
        ...(!action.payload && { editModalRecord: [], localProducts: [] }),
      };
    }

    case "setLocalProductStoreInvoice": {
      const newLocalProducts =
        state.localProducts.length || action.payload?.isAddProduct
          ? state.localProducts
          : action.payload?.dataProducts;

      const result = getLocalProductsStoreInvoice(
        action.payload,
        newLocalProducts,
        state.ProductList
      );
      return {
        ...state,
        localProducts: [...result],
      };
    }

    case "updateLocalProductStoreInvoice": {
      const result = updateLocalProductStoreInvoice(
        action.payload,
        state.localProducts
      );
      return {
        ...state,
        localProducts: [...result],
      };
    }

    case "distributeUpdate_START": {
      return { ...state, distributeUpdateLoader: true };
    }

    default:
      return state;
  }
};

const findUniqueArray2 = (arr1, arr2) => {
  let obj = [];
  if (arr1.length > arr2.length) {
    //Find values that are in newProduct but not in oldProduct
    obj = arr1.filter(function (obj) {
      return !arr2.some(function (obj2) {
        return obj == obj2;
      });
    });
  } else {
    //Find values that are in oldProduct but not in newProduct
    obj = arr2.filter(function (obj) {
      return !arr1.some(function (obj2) {
        return obj == obj2;
      });
    });
  }

  //Combine the two arrays of unique entries
  var result = obj;

  return result;
};

const findUniqueArray = (arr1, arr2) => {
  let result = [];
  let indexFound = [];
  arr1.map((item, index) => {
    let d = arr2.findIndex((items) => items === item);
    if (d >= 0) {
      indexFound.push(d);
    }
  });
  for (let i = 0; i !== arr2.length; i++) {
    let d = indexFound.filter((items) => items === i);
    if (d.length < 1) {
      result.push(arr2[i]);
    } else {
      console.log("eeee", indexFound[i]);
    }
  }
  return result;
};

const getLocalProducts = (payload, localProducts, apiProducts) => {
  let { product: productIds } = payload;
  let prevSelectedIds =
    localProducts && localProducts.length > 0
      ? localProducts.map((item) => item._id)
      : [];

  let originalProducts = JSON.parse(JSON.stringify(apiProducts));

  let uniqueIds = findUniqueArray(prevSelectedIds, productIds); // [1,2]
  let uniqueResult = originalProducts.filter((item) => {
    if (uniqueIds.includes(item._id)) {
      item.totalPrice = parseFloat(item.Qty) * parseFloat(item.localPrice);
      return item;
    }
  });

  let updatedArray = [];
  if (localProducts && localProducts.length > 0) {
    updatedArray = localProducts.filter((item) => {
      if (productIds.includes(item._id)) {
        if (item.Qty < item.sockQty) {
          item.Qty = parseFloat(item.Qty) + 1;
          item.totalPrice = parseFloat(item.Qty) * parseFloat(item.localPrice);
          return item;
        } else {
          return item;
        }
      } else {
        return item;
      }
    });
  }
  return [...updatedArray, ...uniqueResult];
};

const getUpdatedLocalProducts = (payload, localProducts) => {
  let updatedResult = [];
  let { fieldChanged, updatedRow, newValue } = payload;
  if (fieldChanged === "Qty") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(newValue) * parseFloat(item.localPrice),
          Qty: parseFloat(newValue),
          oldQty: item.Qty,
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "localPrice") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(item.Qty) * parseFloat(newValue),
          localPrice: parseFloat(newValue),
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "retail") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        if (updatedRow.retail) {
          return {
            ...item,
            unitName: updatedRow.subunitName,
            oldUnitName: updatedRow.unitName,
            oldQty: item.Qty,
            oldSockQty: parseFloat(updatedRow.sockQty),
            retail: updatedRow.retail,
            sockQty:
              parseFloat(updatedRow.packaging) * parseFloat(item.sockQty),
            edit: true,
          };
        } else {
          return {
            ...item,
            unitName: item.oldUnitName ? item.oldUnitName : item.unitName,
            retail: updatedRow.retail,
            Qty: item.oldQty ? item.oldQty : item.Qty,
            sockQty: item.oldSockQty ? item.oldSockQty : item.sockQty,
            edit: false,
          };
        }
      } else {
        return item;
      }
    });
  }
  return [...updatedResult];
};
function deleteLocalProduct(oldRecord, deletedRecord) {
  const newRecord = oldRecord.filter((Item) => {
    if (Item._id !== deletedRecord._id) {
      return Item;
    }
  });
  return [...newRecord];
}

const getLocalProductsStoreInvoice = (payload, localProducts, apiProducts) => {
  let { product: productIds } = payload;
  let prevSelectedIds =
    localProducts && localProducts.length > 0
      ? localProducts.map((item) => item._id)
      : [];
  let originalProducts = JSON.parse(JSON.stringify(apiProducts));
  let uniqueIds = findUniqueArray(prevSelectedIds, productIds);

  let uniqueResult = originalProducts.filter((item) => {
    if (uniqueIds.includes(item._id)) {
      item.totalPrice = parseFloat(item.Qty) * parseFloat(item.localPrice);
      return item;
    }
  });

  // find Product into API
  if (payload?.productsOfApi && uniqueResult && uniqueResult.length) {
    uniqueResult.forEach((item2) => {
      const temp = payload.productsOfApi.find(
        (item1) => item2._id === item1._id
      );
      return (item2.productQuantityOfApi = temp?.quantity);
    });
  }

  if (uniqueResult && uniqueResult.length) {
    uniqueResult.forEach((item) => {
      item.quantity = item.Qty;
      item.stockQuantity = item.sockQty;
      item.salePrice = item.localPrice;
      item.maxChangeStockQuantity = item.productQuantityOfApi
        ? parseFloat(item.productQuantityOfApi) + parseFloat(item.sockQty)
        : item.sockQty;
    });
  }
  const result =
    localProducts &&
    localProducts.length &&
    localProducts.map((item2) => {
      const temp = apiProducts.find((item1) => item2._id === item1._id);

      return { ...item2, stockQuantity: temp?.sockQty || 0 };
    });

  let updatedArray = [];
  if (result && result.length > 0) {
    updatedArray = result.filter((item) => {
      if (productIds.includes(item._id)) {
        if (
          payload?.isAddProduct &&
          item.quantity < (item.maxChangeStockQuantity || item.stockQuantity)
        ) {
          item.quantity = parseFloat(item.quantity) + 1;
        }
        let quantity = item.quantity;
        if (item.retail) {
          let pack = apiProducts.filter((x) => x._id === item._id);

          quantity = parseFloat(quantity) / parseFloat(pack[0].packaging);
        }
        item.localPrice = item?.localPrice || item.salePrice;
        item.totalPrice = parseFloat(quantity) * parseFloat(item.localPrice);
        item.maxChangeStockQuantity =
          item.maxChangeStockQuantity ||
          parseFloat(quantity) + parseFloat(item.stockQuantity);
        return item;
      } else {
        return item;
      }
    });
  }
  return [...updatedArray, ...uniqueResult];
};

const updateLocalProductStoreInvoice = (payload, localProducts) => {
  let updatedResult = [];
  let { fieldChanged, updatedRow, newValue } = payload;
  if (fieldChanged === "quantity") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(newValue) * parseFloat(item.localPrice),
          quantity: parseFloat(newValue),
          oldQty: item.quantity,
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "localPrice") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(item.quantity) * parseFloat(newValue),
          localPrice: parseFloat(newValue),
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "retail") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        if (updatedRow.retail) {
          return {
            ...item,
            subunit: updatedRow.subunit,
            unitName: updatedRow.subunitName,
            oldUnitName: updatedRow.unitName,
            oldSockQty: parseFloat(updatedRow.sockQty),
            retail: updatedRow.retail,
            oldQty: item.quantity,
            edit: true,
            sockQty:
              parseFloat(updatedRow.packaging) * parseFloat(updatedRow.sockQty),
          };
        } else {
          return {
            ...item,
            unitName: item.oldUnitName,
            quantity: item.oldQty ? item.oldQty : item.quantity,
            edit: false,
            retail: updatedRow.retail,
            sockQty: item.oldSockQty,
          };
        }
      } else {
        return item;
      }
    });
  }

  return [...updatedResult];
};
