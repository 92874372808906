import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react-pro";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import DebitNotesEditProductForm from "./debit-notes-edit-product-form";
import DebitNotesEditProductGrid from "./debit-notes-edit-product-grid";
import { useForm } from "react-hook-form";
import { notify } from "../../util/ShowToast";

const DebitNotesEditModal = () => {
  const dispatch = useDispatch();
  const invProps = useSelector(state => state.storeToStoreReducer);
  const helperProps = useSelector((state) => state.helperReducer);
  const debitData = helperProps.data;
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  //const storeProps = useSelector((state) => state.storeReducer);

  const { localProducts, } = invProps;


  const title = intlPlaceholder("stockTransfer.debitNotes.editTitle")
  const notifySuccess = intlPlaceholder('Stock.edit.success');
  // form validation rules
  const validationSchema = Yup.object().shape({
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue, getValues } = useForm(formOptions);

  const closeForm = () => {
    dispatch({ type: "closeEditDialog" });
    dispatch({ type: "loadingSpinner_STOP" });
    document.getElementById('filterDebitNotes').click();
  };

  const fetchDebitNotesDetail = () => {
    if (debitData) {
      // dispatch({ type: "setResourceStore", payload: storeProps.StoresDropDownList, });
      setDisableSaveBtn(false);
    }
  };

  useEffect(fetchDebitNotesDetail, [helperProps.showEditDialog]);

  const onUpdate = () => {
    const resourceStore = getValues("resourceStore");
    const destinationStore = getValues("destinationStore");
    let data = {};

    if (
      localProducts &&
      localProducts.length > 0 &&
      resourceStore &&
      destinationStore
    ) { 
      let updatedResult = localProducts.map((item) => {
        return {
          basePrice: parseFloat(item.localPrice),          
          price: parseFloat(item.localPrice),
          salePrice: parseFloat(item.localPrice),
          productId: item.productId,
          productName: item.productName,
          unit: item.unit,
          unitName: item.unitName,
          units: item.unitId,
          total: parseFloat(item.total),

          //new change values
          qty: item.qty,
          quantity: item.qty,
          localPrice: parseFloat(item.localPrice),
          totalPrice: parseFloat(item.localPrice) * parseFloat(item.qty)
        };
      });

      data.invoiceId = debitData._id;
      data.products = [...updatedResult];
      data.destinationStore = destinationStore;
      data.resourceStore = resourceStore;

      setDisableSaveBtn(true);

      dispatch(
        DataRequestAction(
          "POST",
          "updateDebitNotes",
          data,
          "",
          "loadingSpinner",
          false,
          resp => {
            notify('success', notifySuccess);
            setDisableSaveBtn(false);
            closeForm();            
          },
          error => {
            notify('error', error);

            setDisableSaveBtn(false);
          }
        )
      );
    } else {
      notify("error", intlPlaceholder("productNotSelected"));
    }
  };

  return (
    <>
      <CModal size="xl" visible={helperProps.showEditDialog}>
        <CModalHeader
          onDismiss={() => {
            closeForm();
          }}
        >
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DebitNotesEditProductForm
            key="debitNotesEditProductForm"
            setValue={setValue}
            setDisableSaveBtn={setDisableSaveBtn}
          />

          <div className="mt-4">
            <DebitNotesEditProductGrid
              key="debitNotesEditProductGrid"
              setDisableSaveBtn={setDisableSaveBtn}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CForm onSubmit={handleSubmit((d) => onUpdate(d))}>
            <CModalFooter>
              <CButton color="secondary" onClick={() => closeForm()}>
                {intlPlaceholder("CancelButtonTitle")}
              </CButton>

              <CLoadingButton type="submit"
                color="primary"
                disabled={disableSaveBtn}
                loading={invProps.loadingSpinner}
              >
                {intlPlaceholder("SaveButtonTitle")}
              </CLoadingButton>
            </CModalFooter>
          </CForm>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DebitNotesEditModal;
