import React, { useState, useEffect } from "react";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataGetAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as Yup from "yup";
import Select from "react-select";
import { selectStyle } from '../../../constants/CommonFn'
const SupplierProductFilter = ({ setFilter }) => {

  const [key, setKey] = useState(1);
  const dispatch = useDispatch();
  const BSProps = useSelector((state) => state.balanceSheet);
  const categoryProps = useSelector((state) => state.categoryReducer);
  const supplierProps = useSelector((state) => state.supplier);
  const productProps = useSelector((state) => state.ProductR);
  const { gotAllSupplier, supplierProductSpinner } = BSProps;
  const { Suppliers } = supplierProps;

  const validationSchema = Yup.object().shape({
    supplier: Yup.string().required(
      <IntlMessages id="SupplierProductFilter.supplier_error_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValue: {
      supplier: Suppliers && Suppliers.length > 0 && Suppliers[0]._id,
      date: [new Date().toISOString(), new Date().toISOString()],
    },
  };

  // get functions to build form with useForm() hook
  const { handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {

  
  
    let findSupplier = Suppliers.filter((Item) => Item._id === values.supplier);
    let filterObj;
    
    if (values.date) {
      filterObj = {
        supplier: findSupplier[0]._id,
        supplierName: findSupplier[0].name,
        startDate: moment(values.date[0]).toString(),
        endDate:  moment(values.date[1]).toString(),
      };
    } else {
  
      filterObj = {
        supplier: findSupplier[0]._id,
        supplierName: findSupplier[0].name,
        startDate: moment().toString(),
        endDate: moment().toString(),
      };
    }



    setFilter(filterObj);
    dispatch(DataGetAction("getSupplierProduct", filterObj, ""));
  };

  const fetchAllBankAndCurrencyList = () => {
    // dispatch(DataGetAction('getAllCategory', { query: "all" }));
    dispatch(
      DataGetAction("getAllSupplier", { query: "all" }, "gettingAllSuppliers")
    );
  };

  useEffect(fetchAllBankAndCurrencyList, []);

  const setInitialValue = () => {
    if (Suppliers && Suppliers.length > 0) {
      reset({
        supplier: Suppliers && Suppliers.length > 0 && Suppliers[0]._id,
      });
    }
  };

  useEffect(setInitialValue, [supplierProductSpinner]);

  if (gotAllSupplier) {
    // if (Suppliers && Suppliers.length) {
    //   //   form.setFieldsValue({ supplier: Suppliers[0]._id });

    //   let startDate = moment().startOf("year").toString();
    //   let endDate = moment().toString();

    //   setFilter({ supplier: Suppliers[0].name, startDate, endDate });
    //   setKey(key + 1);
    //   dispatch(
    //     DataGetAction(
    //       "getSupplierProduct",
    //       { supplier: Suppliers[0]._id, startDate, endDate },
    //       "supplierProductSpinner"
    //     )
    //   );
    // }
    dispatch({
      type: "reset_gotAllSupplier",
    });
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };




  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
      ' - ' +
      picker.endDate.format('MM/DD/YYYY')
    );
  };
  return (
    <div className="p-3">

      <CForm className="d-flex" onSubmit={handleSubmit(onFinish)} key={key}>
        <div className=" me-3">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, value, },
            }) => (
              <DateRangePicker
                initialSettings={{
                  startDate: moment(),
                  endDate: moment(),
                }}

                value={value}
                onApply={handleApply}
                onCallback={(start, end) => {
                  onChange([start.toISOString(), end.toISOString()]);
                }}
              >
                <input type="text" className="form-control col-4" />
              </DateRangePicker >
            )}
          />
          <div className="error-message ">{errors.date?.message}</div>
        </div>
        <div className="me-3">
          <div style={{ width: "220px" }}>
            <Controller
              control={control}
              name="supplier"
              render={({
                field: { onChange, }
              }) => (
                <Select
                  className={errors.supplier ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                 
                  placeholder={intlPlaceholder(
                    "SupplierProductFilter.selectSupplier"
                  )}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                  options={renderOptions([...Suppliers], "name", "_id")}
                />
              )}
            />

            <div className="invalid-feedback">{errors.supplier?.message}</div>
          </div>
        </div>
        <div className="">
          <div className="">
            <CLoadingButton
              type="submit"
              color="primary"
              className="d-flex align-items-center"
            >
              <i className="gx-icon-filter-300 me-1 text-white"></i>
              <IntlMessages id="balanceSheet.generateButton" />
            </CLoadingButton>
          </div>
        </div>
      </CForm>
    </div>
  );
};

export default SupplierProductFilter;
