import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import InvoiceList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import moment from "moment";
import CheckUserAccess from "../../util/checkUserAccess";
import InvoiceCompanyToStoreModal from "./components/invoice-company-store-modal";
import _ from "lodash";
import ViewSaleInvoice from "./components/viewSaleInvoice";
import EditSaleInvoiceModal from "./components/EditSaleInvoiceModal";

const Index = () => {
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({
    status: "approved",
  });
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.saleInvoiceReducer);
  const [filterValue, setFilterValue] = useState(null);

  const history = useHistory();
  const create = CheckUserAccess("saleInvoice", ["create"]);
  const view = CheckUserAccess("saleInvoice", ["view"]);
  const edit = CheckUserAccess("saleInvoice", ["edit"]);
  const fetchProduct = () => {
    setFilterValue({
      startDate: moment().toString(),
      endDate: moment().toString(),
    });
    dispatch(
      DataGetAction(
        "getSaleList",
        {
          startDate: moment().toString(),
          endDate: moment().toString(),
        },
        "allSaleInvoicesSpinner"
      )
    );
    return () => {
      dispatch({ type: "saleInvoiceList", payload: { Record: [] } });
    };
  };
  useEffect(fetchProduct, []);

  const redirectToPage = () => {
    history.push("/addSaleInvoice");
  };

  const renderAction = (data) => {
    return (
      <>
        {view && (
          <CButton
            size="sm"
            color="warning"
            className="me-3"
            onClick={() =>
              dispatch({ type: "Show_View_Sale_Invoice_Modal", payload: data })
            }
          >
            <span style={{ color: "white" }}>
              <span className="icon icon-custom-view me-2"></span>
              <IntlMessages id="view" />
            </span>
          </CButton>
        )}
        {edit && (
          <CButton size="sm" color="warning" onClick={() => editRecord(data)}>
            <i className="icon icon-edit text-white me-1"></i>
            <span style={{ color: "white" }}>
              <IntlMessages id="Edit" />
            </span>
          </CButton>
        )}
      </>
    );
  };

  let bodyData = [
    [
      intlPlaceholder("Date"),
      intlPlaceholder("RefNumber"),
      intlPlaceholder("storeName"),
      intlPlaceholder("amount"),
      intlPlaceholder("createdBy"),
    ],
  ];

  const columns = [
    {
      label: <IntlMessages id="invoice.number" />,
      key: "prefix",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.storeName" />,
      key: "store",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.amount" />,
      key: "totalPrice",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoice.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: "dropDown",
      filterDropDownValue: [
        { label: intlPlaceholder("invoice.all"), value: "all" },
        {
          label: intlPlaceholder("invoice.approved"),
          value: "approved",
          selected: true,
        },
        { label: intlPlaceholder("invoice.cancelled"), value: "cancelled" },
      ],
      _style: { width: "40%" },
    },
    {
      label: "",
      filter: false,
      sorter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];

  const editRecord = (data) => {
    dispatch({
      type: "editSaleInvoiceModalVisible",
      payload: data,
    });
  };

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="saleInvoice.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter setFilter={setFilterValue} />
                <div className="d-flex align-items-center">
                  {create && (
                    <CButton
                      color="success"
                      className="me-3 text-white"
                      onClick={() => redirectToPage()}
                    >
                      <i className="icon icon-add me-1"></i>
                      <IntlMessages id="addInvoice" />
                    </CButton>
                  )}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <InvoiceList
                  loading={invProps.SaleInvoiceLoader}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={
                    invProps.SaleInvoiceLoader ? [] : invProps.saleInvoiceList
                  }
                  columns={columns}
                  columnFilterValue={staticFilterValue}
                  filterValueChangeTo={(value) => {
                    if (value.status) {
                      setStaticFilterValue(value);
                    }
                  }}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge
                            style={{
                              textTransform: "capitalize",
                            }}
                            color={
                              record.status === "approved"
                                ? "success"
                                : "danger"
                            }
                          >
                            {" "}
                            {intlPlaceholder(`invoice.${record.status}`)}
                          </CBadge>
                        </td>
                      );
                    },

                    store: (record) => {
                      return (
                        <td>
                          <span>{record.store}</span>
                        </td>
                      );
                    },

                    totalPrice: (record) => {
                      return (
                        <td className="text-end">
                          <span>
                            {ChangeNumberFormate(record.totalPrice.toFixed(2))}
                          </span>
                        </td>
                      );
                    },

                    createdAt: (record) => {
                      return (
                        <td>
                          <span>
                            {ChangeDateFormate(record.createdAt, "YYYY-MM-DD")}
                          </span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? (
                              <IntlMessages id="hide" />
                            ) : (
                              <IntlMessages id="show" />
                            )}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let total = 0;
                    pageData.forEach(({ totalPrice }) => {
                      total += totalPrice;
                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center">
                          <h5>
                            <IntlMessages id="Total" />
                          </h5>
                        </th>

                        <th colSpan={2} className="text-end">
                          <span>
                            <h5>{ChangeNumberFormate(total.toFixed(2))}</h5>
                          </span>
                        </th>
                        <th colSpan={2}>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <InvoiceCompanyToStoreModal
        key="invoiceCompanyToStoreModal"
        modalType="editProductStoreInvoice_Modal"
        title={"Edit Store Invoice"}
      />
      <ViewSaleInvoice />
      <EditSaleInvoiceModal />
    </>
  );
};

export default Index;
