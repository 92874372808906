import { CModal, CModalHeader, CModalTitle } from "@coreui/react-pro";

export const Modal = ({
  title = "",
  size = "xl",
  visible = false,
  onDismiss,
  children,
}) => (
  <CModal size={size} visible={visible}>
    <CModalHeader onDismiss={onDismiss}>
      <CModalTitle>{title}</CModalTitle>
    </CModalHeader>
    {children}
  </CModal>
);
