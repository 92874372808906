import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import CurrencyForm from "./CurrencyForm";
const AddCurrencyModal = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();

  const comProps = useSelector((state) => state.currencyReducer);

  const { currencyLoader, selectedItem, modalVisible, success } = comProps;

  const modalType = "show_currency_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };



  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
      <i className="icon icon-add me-1"></i>
        <IntlMessages id="currency.add.currency.button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder('currency.addCurrencyModal.addCurrency')}
          </CModalTitle>
        </CModalHeader>
       
          <CModalBody>
            <CurrencyForm
              defaultValues={null}
              ButtonComponent={
                <CModalFooter>
                  <CButton
                    color="secondary"
                    onClick={() =>
                      dispatch(DataEntryModalAction(modalType, null))
                    }
                  >
                    <IntlMessages id="currency.addCurrencyModal.cancel" />
                  </CButton>

                  <CLoadingButton
                    type="submit"
                    color="primary"
                    loading={currencyLoader}
                    disabled={currencyLoader}
                  >
                    {intlPlaceholder("currency.addCurrencyModal.save")}
                  </CLoadingButton>
                </CModalFooter>
              }
            />


          </CModalBody>
       
      </CModal>
    </div>
  );
};

export default AddCurrencyModal;
