import moment from "moment";
const INIT_STATE = {
  modalVisible: false,
  receiptLoader: false,
  error: false,
  success: false,
  Supplier: [],
  Receipts: [],
  ReceiptsFetch: [],
  fetchReceiptInfo: false,
  Store: [],
  Bank: [],
  Currency: [],
  totalAmountDue: 0,
  ReceiptDetail: null,
  totalDueByCompany: 0,
  companyReceiptSuccess: false,
  SupplierReceipts: [],
  SupplierBankList: [],
  SupplierCreatedByList: [],
  SupplierReceiptsFirstLoadData: [],
  selectedSupplierReceipt: null,
  SupplierReceiptLoader: false,
  receiptNo: 0,
  customerDue: 0,
  supplierRefError: false,
  validatingSupplierRefNo: false,
  CompanyAccount: [],
  ReceiptCreatedBy: [],
  BankList: [],
  allStoreReceiptSpinner: false,
  supplierReceiptSpinner: false,
  storeReceiptSpinner: false,
  refundCompanyReceiptSuccess: false,
  refundCompanyReceiptError: false,
  refundStoreReceiptSuccess: false,
  successStoreWithdrawRefund: false,
  refundStoreReceiptError: false,
  message: "",
  refundCustomerReceiptSuccess: false,
  refundCustomerReceiptError: false,
  fetchCompanyBankSpin:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "refundSupplierReceipts_SUCCESS": {
      return {
        ...state,
        refundCompanyReceiptSuccess: true,
        message: action.payload.message,
      };
    }

    case "refundSupplierReceipts_FAILURE": {
      return {
        ...state,
        refundCompanyReceiptError: true,
        message: action.payload,
      };
    }

    case "refundStoreReceipt_SUCCESS": {
      return {
        ...state,
        refundStoreReceiptSuccess: true,
        message: action.payload.message,
      };
    }
    case "storeWithdrawRefund_SUCCESS": {
      return {
        ...state,
        successStoreWithdrawRefund: true,
        message: action.payload.message,
      };
    }
    case "refundStoreReceipt_FAILURE": {
      return {
        ...state,
        refundStoreReceiptError: true,
        message: action.payload,
      };
    }
    case "refundCustomerReceipt_SUCCESS": {
      return {
        ...state,
        refundCustomerReceiptSuccess: true,
        message: action.payload.message,
      };
    }
    case "refundCustomerReceipt_FAILURE": {
      return {
        ...state,
        refundCustomerReceiptError: true,
        message: action.payload,
      };
    }

    case "Search_supplier_by_chequeDate": {
      const result = searchByChequeDate(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_Supplier_by_created_by": {
      const result = searchByCreatedBy(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_Supplier_by_Bank": {
      const result = searchByBank(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_supplier_receipt_by_payment_mode": {
      const result = searchSupplierByPaymentMode(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_supplier_by_name_number": {
      const result = searchSupplierBySupplierName(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_supplier_receipt_number": {
      const result = searchSupplierByReceiptNumber(
        action.payload,
        state.SupplierReceiptsFirstLoadData
      );
      return {
        ...state,
        SupplierReceipts: [...result],
      };
    }
    case "Search_by_receipt_number": {
      const result = searchByReceiptNumber(action.payload, state.ReceiptsFetch);
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "Search_store_by_chequeDate": {
      const result = searchByPaymentModeChequeDate(
        action.payload,
        state.ReceiptsFetch
      );
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "Search_by_payment_mode": {
      const result = searchByPaymentMode(action.payload, state.ReceiptsFetch);
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "Search_by_Bank": {
      const result = searchByBank(action.payload, state.ReceiptsFetch);
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "Search_by_store": {
      const result = searchByStore(action.payload, state.ReceiptsFetch);
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "Search_by_created_by": {
      const result = searchByCreatedBy(action.payload, state.ReceiptsFetch);
      return {
        ...state,
        Receipts: [...result],
      };
    }
    case "fetchCompanyBank_SUCCESS": {
      return { ...state, CompanyAccount: action.payload.Record ,fetchCompanyBankSpin:false};
    }
    case "fetchCompanyBankSpin_START" :{
      return {
        ...state,
        fetchCompanyBankSpin:true
      }
    }
    case "getCompanyAccounts_SUCCESS": {
      return { ...state, CompanyAccount: action.payload.Record };
    }
    case "checkSupplierRefNo_START": {
      return {
        ...state,
        validatingSupplierRefNo: true,
      };
    }
    case "checkSupplierRefNo_STOP": {
      return {
        ...state,
        validatingSupplierRefNo: false,
      };
    }
    case "checkSupplierRefNo_SUCCESS": {
      return {
        ...state,
        supplierRefError: false,
        validatingSupplierRefNo: false,
      };
    }
    case "checkSupplierRefNo_FAILURE": {
      return {
        ...state,
        supplierRefError: true,
        message: "refAlreadyInUser",
      };
    }
    case "removeAllReceipt": {
      return { ...state, Receipts: [] };
    }
    case "getAllStoreReceipt_SUCCESS": {
      const createdByList = getCreatedByList(action.payload.Record);
      const bankList = getBankByList(action.payload.Record);
      return {
        ...state,
        BankList: bankList,
        Receipts: action.payload.Record,
        ReceiptsFetch: action.payload.Record,
        ReceiptCreatedBy: createdByList,
      };
    }
    case "allStoreReceiptSpinner_START": {
      return { ...state, allStoreReceiptSpinner: true };
    }
    case "allStoreReceiptSpinner_STOP": {
      return { ...state, allStoreReceiptSpinner: false };
    }
    case "addStoreReceipt_SUCCESS": {
      return {
        ...state,
        message: "receiptGeneratedSuccessfully",
        success: true,
        receiptNo: action.payload.Record.receiptNumber,
      };
    }
    case "getCustomerDue_SUCCESS": {
      return { ...state, customerDue: action.payload.Record.CustomerDues };
    }
    case "getSupplierReceiptDetail_SUCCESS": {
      return { ...state, selectedSupplierReceipt: action.payload.Record };
    }

    case "fetchingSupplierReceipt_START": {
      return { ...state, SupplierReceiptLoader: true };
    }
    case "fetchingSupplierReceipt_STOP": {
      return { ...state, SupplierReceiptLoader: false };
    }

    case "getSupplierReceipt_SUCCESS": {
      let supplierBankList = getSupplierBankList(action.payload.Record);
      let supplierCreatedByList = getSupplierCreatedByList(
        action.payload.Record
      );
      return {
        ...state,
        SupplierBankList: supplierBankList,
        SupplierCreatedByList: supplierCreatedByList,
        SupplierReceiptsFirstLoadData: action.payload.Record,
        SupplierReceipts: action.payload.Record,
        totalDueByCompany: 0,
      };
    }
    case "supplierReceiptSpinner_START": {
      return { ...state, supplierReceiptSpinner: true };
    }
    case "supplierReceiptSpinner_STOP": {
      return { ...state, supplierReceiptSpinner: false };
    }
    case "createSupplierReceipts_SUCCESS": {
      return {
        ...state,
        companyReceiptSuccess: true,
        supplierRefError: false,
        message: "receiptCreatedSuccessfully",
        receiptNo: action.payload.Record.receiptNo,
      };
    }

    case "CompanyDue_START": {
      return { ...state, totalDueByCompany: 0 };
    }
    case "getCompanyDues_SUCCESS": {
      const newCompanyDue = action.payload.totalDueByCompany;
      return { ...state, totalDueByCompany: newCompanyDue };
    }
    case "getCompanyDues_FAILURE": {
      return { ...state, totalDueByCompany: 0 };
    }

    case "fetchingReceipt_START": {
      return { ...state, receiptLoader: true };
    }
    case "fetchingReceipt_STOP": {
      return { ...state, receiptLoader: false };
    }
    case "getReceiptDetail_SUCCESS": {
      return {
        ...state,
        receiptLoader: false,
        ReceiptDetail: action.payload.Record && action.payload.Record[0],
      };
    }
    case "getStoreReceiptDetail_SUCCESS": {
      return {
        ...state,
        receiptLoader: false,
        ReceiptDetail: action.payload.Record && action.payload.Record[0],
      };
    }

    case "getStoreDue_SUCCESS": {
      return { ...state, totalAmountDue: action.payload.totalDueByStore };
    }
    case "getReceiptDropDown_SUCCESS": {
      return {
        ...state,
        Store: action.payload.stores,
        Bank: action.payload.banks,
        Currency: action.payload.currencies,
        Supplier: action.payload.suppliers,
        totalAmountDue: 0,
      };
    }
    case "getAllReceipt_SUCCESS": {
      const createdByList = getCreatedByList(action.payload.Record.payload);
      const bankList = getBankByList(action.payload.Record.payload);
      return {
        ...state,
        BankList: bankList,
        ReceiptCreatedBy: createdByList,
        ReceiptsFetch: action.payload.Record.payload,
        Receipts: action.payload.Record.payload,
        fetchReceiptInfo: false,
        totalAmountDue: 0,
      };
    }
    case "storeReceiptSpinner_START": {
      return { ...state, storeReceiptSpinner: true };
    }
    case "storeReceiptSpinner_STOP": {
      return { ...state, storeReceiptSpinner: false };
    }
    case "show_receipt_modal": {
      return { ...state, modalVisible: !state.modalVisible };
    }

    case "receipt_START": {
      return { ...state, receiptLoader: true };
    }
    case "receipt_STOP": {
      return { ...state, receiptLoader: false };
    }

    case "addReceipt_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        fetchReceiptInfo: true,
        receiptLoader: false,
        totalAmountDue: 0,
        receiptNo: action.payload.receipt.receiptNumber,
      };
    }
    case "addReceipt_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        receiptLoader: false,
        message: action.payload,
      };
    }

    case "receipt_UPDATE": {
      return {
        ...state,
        modalVisible: false,
        receiptLoader: false,
        companyReceiptSuccess: false,
        error: false,
        success: false,
        message: "",
        totalDueByCompany: 0,
        totalAmountDue: 0,
        customerDue: 0,
        refundCompanyReceiptSuccess: false,
        refundCompanyReceiptError: false,
        refundStoreReceiptSuccess: false,
        successStoreWithdrawRefund: false,
        refundStoreReceiptError: false,
        refundCustomerReceiptSuccess: false,
        refundCustomerReceiptError: false,
      };
    }

    default:
      return state;
  }
};

const getCreatedByList = (data) => {
  let cratedByList = [];
  if (data && data.length > 0) {
    let temp = data[0].createdBy._id;
    cratedByList.push(data[0].createdBy);
    data.map((Item) => {
      if (temp !== Item.createdBy._id) {
        cratedByList.push(Item.createdBy);
        temp = Item.createdBy._id;
      }
    });
  }

  return cratedByList;
};
const getSupplierCreatedByList = (data) => {
  let cratedByList = [];
  if (data && data.length > 0) {
    let temp = data[0].createdBy._id;
    cratedByList.push(data[0].createdBy);
    data.map((Item) => {
      if (temp !== Item.createdBy._id) {
        cratedByList.push(Item.createdBy);
        temp = Item.createdBy._id;
      }
    });
  }

  return cratedByList;
};

const getBankByList = (data) => {
  let cratedByList = ["Cash"];
  if (data && data.length > 0) {
    let onlyBankRecord = data.filter(
      (Item) => Item && Item.paymentMode && Item.paymentMode.mode === "bank"
    );
    if (onlyBankRecord && onlyBankRecord.length > 0) {
      let temp = onlyBankRecord[0].paymentMode.bank.name;
      cratedByList.push(temp);
      onlyBankRecord.map((Item) => {
        if (temp !== Item.paymentMode.bank.name) {
          cratedByList.push(Item.paymentMode.bank.name);
          temp = Item.paymentMode.bank.name;
        }
      });
    }
  }

  return cratedByList;
};
const getSupplierBankList = (data) => {
  let cratedByList = ["Cash"];
  if (data && data.length > 0) {
    let onlyBankRecord = data.filter(
      (Item) => Item.paymentMode.mode === "bank"
    );
    if (onlyBankRecord && onlyBankRecord.length > 0) {
      let temp = onlyBankRecord[0].paymentMode.bank.name;
      cratedByList.push(temp);
      onlyBankRecord.map((Item) => {
        if (temp !== Item.paymentMode.bank.name) {
          cratedByList.push(Item.paymentMode.bank.name);
          temp = Item.paymentMode.bank.name;
        }
      });
    }
  }

  return cratedByList;
};

const searchByStore = (storeId, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.storeId._id === storeId);
  }
  return result;
};
const searchSupplierBySupplierName = (supplierId, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.supplierId === supplierId);
  }
  return result;
};
const searchByPaymentMode = (paymentMode, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.paymentMode.mode === paymentMode);
  }
  return result;
};
const searchSupplierByPaymentMode = (paymentMode, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.paymentMode.mode === paymentMode);
  }
  return result;
};
const searchByBank = (paymentMode, oldData) => {
  let result = [];
  if (oldData) {
    if (paymentMode === "Cash") {
      result = oldData.filter((Item) => Item.paymentMode.mode === "cash");
    } else {
      result = oldData.filter((Item) => {
        if (Item.paymentMode.mode === "bank") {
          if (
            Item.paymentMode.bank.name.toLowerCase() ===
            paymentMode.toLowerCase()
          ) {
            return Item;
          }
        }
      });
    }
  }
  return result;
};
const searchByCreatedBy = (createdBy, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.createdBy._id === createdBy);
  }
  return result;
};
const searchByReceiptNumber = (no, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => {
      if (parseInt(Item.receiptNumber) === parseInt(no)) {
        return Item;
      }
    });
  }
  return result;
};
const searchSupplierByReceiptNumber = (no, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => {
      if (Item.referenceNo === no) {
        return Item;
      }
    });
  }
  return result;
};
const searchByChequeDate = (data, oldData) => {
  let startDate = moment(data[0]).format("YYYY-MM-DD");
  let endDate = moment(data[1]).format("YYYY-MM-DD");

  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => {
      if (
        Item.paymentMode.mode === "bank" &&
        Item.paymentMode.transactionType === "cheque"
      ) {
        let chequeDate = moment(Item.paymentMode.chequeDate).format(
          "YYYY-MM-DD"
        );
        if (
          moment(startDate).isSameOrBefore(chequeDate) &&
          moment(endDate).isSameOrAfter(chequeDate)
        ) {
          return Item;
        }
      }
    });
  }
  return result;
};

const searchByPaymentModeChequeDate = (data, oldData) => {
  let startDate = moment(data[0]).format("YYYY-MM-DD");
  let endDate = moment(data[1]).format("YYYY-MM-DD");

  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => {
      if (
        Item.paymentMode.mode === "bank" &&
        Item.paymentMode.transactionType === "cheque"
      ) {
        let chequeDate = moment(Item.paymentMode.chequeDate).format(
          "YYYY-MM-DD"
        );
        if (
          moment(startDate).isSameOrBefore(chequeDate) &&
          moment(endDate).isSameOrAfter(chequeDate)
        ) {
          return Item;
        }
      }
    });
  }
  return result;
};
