import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "src/redux/actions/http";

const CreditNotesGrid = ({ data, spinner }) => {
  const [details, setDetails] = useState([]);
  const dispatch = useDispatch();
  const storeProps = useSelector((state) => state.storeReducer);

  const columns = [
    {
      label: <IntlMessages id="stockTransfer.creditNotes.invoice" />,
      key: "invoice",
      _style: { width: "40%" },
      columnSorter: false,
    },
    {
      label: <IntlMessages id="stockTransfer.creditNotes.sourceStore" />,
      key: "sourceStore",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockTransfer.creditNotes.creditAmount" />,
      key: "creditAmount",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="stockTransfer.creditNotes.date" />,
      key: "date",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
        label: "",
        sorter: false,
        filter: false,
        key: "show_details",
        _style: { width: "40%" },
      },
  ];

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const viewDetail = (data) => {
    dispatch({
      type: "openViewDialog",
      payload: data,
    });
  };

  const editDetail = (data) => {    
    dispatch({ type: "setResourceStore", payload: storeProps.StoresDropDownList, });
    dispatch(      
      DataGetAction('getCreditNotesDetail', { invoiceId: data._id}, 'fetchingInvoice',
      resp => {dispatch({ type: "openEditDialog", payload: data});})
      )    
  };

  const renderAction = (data) => {
    return (
      <>
        <CButton
          size="sm"
          color="warning"
          className="me-3"
          onClick={() => viewDetail(data)}
        >
          <span style={{ color: "white" }}>
            <span className="icon icon-custom-view me-2"></span>
            <IntlMessages id="view" />
          </span>
        </CButton>
        {true && (
              <CButton size="sm" color="warning" onClick={() => editDetail(data)}>
                <i className="icon icon-edit text-white me-1"></i>
                <span style={{ color: "white" }}>
                  <IntlMessages id="Edit" />
                </span>
              </CButton>
            )}
      </>
    );
  };

  return (
    <>
      <CSmartTable
        loading={spinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={spinner ? [] : data}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          creditAmount: (record) => {
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(
                    parseFloat(record.creditAmount).toFixed(2)
                  )}
                </span>
              </td>
            );
          },
          show_details: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? (
                    <IntlMessages id="hide" />
                  ) : (
                    <IntlMessages id="show" />
                  )}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>{renderAction(record)}</CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default CreditNotesGrid;
