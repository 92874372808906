import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormTextarea,
  CForm,
  CFormInput,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { notify } from "../../../util/ShowToast";
const AddStoreModal = () => {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null);
  const comProps = useSelector((state) => state.storeReducer);

  const { storeLoader, selectedItem, editModalVisible, success } = comProps;
  console.log("=== selectedItem ====", selectedItem);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [phoneNum, setPhoneNum] = useState("");
  const modalType = "edit_store_modal";
  useEffect(() => {
    setPhoneNum(null);
  }, [editModalVisible]);
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      <IntlMessages id="store.addStoreModal.name_message" />
    ),
    // address: Yup.string().required(
    //   <IntlMessages id="store.addStoreModal.address_message" />
    // ),

    email: Yup.string()
      // .required(<IntlMessages id="store.addStoreModal.email_message" />)
      .email(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: userData,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (editModalVisible) {
      setPhoneNumberList([]);
    }
    if (editModalVisible && selectedItem && selectedItem.phoneNumbers) {
      setPhoneNumberList(selectedItem.phoneNumbers);
    }
    if (editModalVisible && selectedItem) {
      setValue("name", selectedItem.name);
      setValue("email", selectedItem.email);
      setValue("address", selectedItem.address);
    }
    return () => {
      setValue("name", "");
      setValue("email", "");
      setValue("address", "");
    };
  }, [editModalVisible]);

  const onFinish = (values) => {
    if (phoneNum && phoneNum.length > 0) {
      if (phoneNumberList && phoneNumberList.length > 0) {
        values["phoneNumbers"] = [...phoneNumberList, phoneNum];
      } else {
        values["phoneNumbers"] = [phoneNum];
      }
    } else {
      values["phoneNumbers"] = phoneNumberList;
    }
    if (selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "addStore", values, "", "store", false)
      );
    } else {
      values._id = selectedItem._id;

      dispatch(
        DataRequestAction("PUT", "editStore", values, "", "store", false)
      );
    }
  };

  if (success) {
    let message = intlPlaceholder(comProps.message);
    notify("success", message);
    reset();
    dispatch({
      type: "store_UPDATE",
    });
  }
  if (comProps.error) {
    let message = intlPlaceholder(comProps.message);
    notify("error", message);
    dispatch({
      type: "store_UPDATE",
    });
  }
  const setPhoneNumerInArray = () => {
    if (phoneNumberList.includes(phoneNum)) {
      setPhoneNum("");
    } else {
      setPhoneNumberList((oldData) => [`+${phoneNum}`, ...oldData]);
      setPhoneNum("");
    }
  };
  const onRemoveImageFormList = (index) => {
    setPhoneNumberList([
      ...phoneNumberList.slice(0, index),
      ...phoneNumberList.slice(index + 1, phoneNumberList.length),
    ]);
  };

  const renderPhoneList = () => {
    if (phoneNumberList && phoneNumberList.length > 0) {
      return phoneNumberList.map((item, index) => {
        if (item && item.length > 0) {
          return (
            <div
              className="mb-2"
              style={{ width: "fit-content", position: "relative" }}
            >
              <span
                style={{ background: "#C7C9C9", borderRadius: "6px" }}
                className="ps-2 pe-3 py-1"
              >
                {item}
              </span>
              <span
                onClick={() => onRemoveImageFormList(index)}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  cursor: "pointer",
                }}
              >
                ⛔
              </span>
            </div>
          );
        }
      });
    }
  };
  return (
    <div>
      <CModal visible={editModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{`${intlPlaceholder(
            "store.addStoreModal.editStore"
          )}`}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)} defaultValues={selectedItem}>
          <CModalBody>
            <div className="mb-3">
              <CFormInput
                className={errors.name ? "is-invalid" : ""}
                {...register("name")}
                placeholder={intlPlaceholder("store.addStoreModal.name")}
                type="text"
                id="name"
              />
              <div className="invalid-feedback">{errors.name?.message}</div>
              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </div>

            <div className="d-flex align-items-center gap-3 mb-3">
              <div style={{ width: "100%" }}>
                <Controller
                  control={control}
                  name={"phoneNumbers"}
                  render={(props) => {
                    return (
                      <PhoneInput
                        country={"gn"}
                        value={phoneNum}
                        onChange={(v) => {
                          if (v && v.length > 8) {
                            setPhoneNum(v);
                          }
                          props.field.onChange(v);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <CButton
                disabled={!(phoneNum && phoneNum.length > 7)}
                size="sm"
                color="primary"
                className="py-1 mb-1"
                onClick={() => setPhoneNumerInArray()}
              >
                Add
              </CButton>
            </div>
            <div className="d-flex align-items-center gap-3 flex-wrap">
              {renderPhoneList()}
            </div>

            <div className="mb-3">
              <CFormInput
                className={errors.email ? "is-invalid" : ""}
                {...register("email")}
                placeholder={intlPlaceholder(
                  "store.addStoreModal.email_placeholder"
                )}
                type="text"
                id="email"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="mb-3">
              <CFormTextarea
                rows="4"
                className={errors.address ? "is-invalid" : ""}
                {...register("address")}
                placeholder={intlPlaceholder("store.addStoreModal.address")}
                type="textArea"
                id="address"
              />
              <div className="invalid-feedback">{errors.address?.message}</div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="store.addStoreModal.cancel" />
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={storeLoader}
              disabled={storeLoader}
            >
              {`${
                selectedItem === null
                  ? intlPlaceholder("store.addStoreModal.save")
                  : intlPlaceholder("store.addStoreModal.save")
              }`}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddStoreModal;
