import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CSpinner,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DocsExample from "../../components/DocsExample";
import { DataGetAction } from "../../redux/actions/http";
import CheckUserAccess from "../../util/checkUserAccess";
import CreditNotesGrid from "./credit-notes-grid";
import CreditNotesFilter from "./credit-notes-filter";
import moment from "moment";
import CreditNotesViewDetail from "./credit-notes-view-detail";
import CreditNotesEditModal from "./credit-notes-edit-modal";

const CreditNotes = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.productReducer);
  const invProps = useSelector(state => state.storeToStoreReducer);
  const [filterValue, setFilterValue] = useState(null);
  const history = useHistory();

  const fetchData = () => {
    if (filterValue)
      dispatch(DataGetAction("getCreditNotesList", filterValue, "spinner"));
    else {
      let filterObj = {
        store: "all",
        startDate: moment().toString(),
        endDate: moment().toString(),
      };
      dispatch(DataGetAction("getCreditNotesList", filterObj, "spinner"));
    }
  };
  const fetchDataDropDownList = () => {
    dispatch(DataGetAction("fetchProductsStoreStock", {},'fetchProductsStoreStockSpinner'));
    dispatch(DataGetAction("getStoresDropDownList", "", "spinner"));
  };

  useEffect(fetchDataDropDownList, []);
  useEffect(fetchData, [filterValue]);

  const redirectToPage = () => {
    history.push("/storeToStoreCreateInvoice");
  };

  const create = CheckUserAccess("creditNotes", ["create"]);

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="bc.creditNotes" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <CreditNotesFilter
                  key={"CreditNotesFilter"}
                  setFilter={setFilterValue}
                />
                {create && (
                  <CButton
                    color="secondary"
                    className="me-3 text-white"
                    onClick={() => redirectToPage()}
                  >
                    <i className="icon icon-add me-1"></i>
                    <IntlMessages id="stockTransfer.creditNotes.addNewCreditNotes" />
                  </CButton>
                )}
              </div>
              <DocsExample href="components/smart-table/">
              {invProps.invoiceLoader ? <div class="d-flex justify-content-center"><CSpinner/></div>: <></>}
                <CreditNotesGrid
                  key={"creditNotes"}
                  data={comProps.CreditNotesList}
                  spinner={comProps.spinner}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CreditNotesViewDetail key="creditNotesViewDetail" />
      <CreditNotesEditModal key="creditNotesEditModal" />
    </>
  );
};

export default CreditNotes;
