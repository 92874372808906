import React, { useState } from "react";
import {
  CBadge,
  CButton,
  CCardBody,
  CCollapse,
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { DataRequestAction } from "../../redux/actions/http";
import CIcon from "@coreui/icons-react";
import {
  cilBan,
  cilCheckAlt,
  cilLockUnlocked,
  cilHttps,
  cilSend,
  cilPen,
} from "@coreui/icons";
import { DataEntryModalAction } from "../../redux/actions/Common";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordModal from "./components/ResetPasswordModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import { notify } from "../../util/ShowToast";
import PermissionModal from "./components/PermissionModal";
import EditCompanyModal from './components/EditCompanyModal'
import menuData from "./_data";


const SmartTableBasicExample = () => {
  const comProps = useSelector((state) => state.company);
  const { resendSuccess, recordEditSuccess,Companies } = comProps;

  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const showPasswordModal = "show_password_modal";

  const columns = [
    {
      label: <IntlMessages id="name" />,
      key: "companyName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="owner" />,
      key: "owner",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="inviteCompany.permissions" />,
      key: "permissions",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="email" />,
      key: "email",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="phone" />,
      key: "phone",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="inviteStatus" />,
      key: "inviteStatus",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="accountStatus" />,
      key: "accountStatus",
      _style: { width: "40%" },
    },
    {
      key: "actions",
      _style: { width: "40%" },
    },

    // { key: 'role', _style: { width: '20%' } },
    // { key: 'status', _style: { width: '20%' } },
    // {
    //   key: 'show_details',
    //   label: '',
    //   _style: { width: '1%' },
    //   filter: false,
    //   sorter: false,
    // },
  ];
  const getBadge = (status) => {
    switch (status) {
      case "accepted":
        return "success";
      case "Inactive":
        return "secondary";
      case "pending":
        return "warning";
      case "block":
        return "danger";
      default:
        return "primary";
    }
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const updateCompany = (_id, key, value) => {
    dispatch(
      DataRequestAction("POST", "editCompany", { _id: _id, [key]: value }, "")
    );
  };

  const resendEmail = (data) => {
    dispatch(
      DataRequestAction(
        "POST",
        "resendInvitation",
        {
          _id: data._id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
        ""
      )
    );
  };

  const resetPassword = (data) => {
    dispatch(DataEntryModalAction(showPasswordModal, data));
  };

  if (resendSuccess) {
    notify("success", intlPlaceholder("emailResentSuccess"));
    dispatch({
      type: "company_UPDATE",
    });
  }

  if (recordEditSuccess) {
    notify("success", intlPlaceholder("recordUpdatedSuccess"));
    dispatch({
      type: "company_UPDATE",
    });
  }
  const Permission_Modal = "Permission_Modal";
  const renderPermissions = (permissions,companyName) => {

    let updatedPermissions  = []
    menuData.map(item=>{
      if(permissions && permissions.length > 0 && permissions.includes(item.value)){
        updatedPermissions.push(item)
      }
    })

 

    if(permissions && permissions.length > 0 ){
      return (
        <div>
          <p className="mb-0">
           {updatedPermissions && updatedPermissions.length > 0 &&  <IntlMessages id={updatedPermissions[0].value} />}
          </p>
          {updatedPermissions && updatedPermissions.length > 1 && (
            <p
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                dispatch({
                  type: Permission_Modal,
                  payload: {permissions : permissions,companyName : companyName},
                  
                });
              }}
            >
              + {updatedPermissions.length - 1} more
            </p>
          )}
        </div>
      );
    }
    else {
      return '----'
    }

  };

  const showModal = (Item) => {
    dispatch(DataEntryModalAction('edit_company_modal', Item));
  };
  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={Companies}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          owner: (record) => {
            return (
              <td>
                <span>{record.firstName + " " + record.lastName}</span>
              </td>
            );
          },
          permissions: (record) => {
            return <td>{renderPermissions(record.permissions,record.companyName)}</td>;
          },
          phone: (record) => {
            return (
              <td>
                <span>{record.phoneNumber ? record.phoneNumber : "---"}</span>
              </td>
            );
          },
          inviteStatus: (record) => {
            return (
              <td>
                <span>
                  {record.invitationGuid === "newUser" ? (
                    <CBadge color={getBadge("pending")}>
                      <IntlMessages id="pending" />
                    </CBadge>
                  ) : (
                    <CBadge color={getBadge("accepted")}>
                      <IntlMessages id="accepted" />
                    </CBadge>
                  )}
                </span>
              </td>
            );
          },
          accountStatus: (record) => {
            return (
              <td>
                <span>
                  {record.status === "pending" ? (
                    <CBadge color={getBadge("pending")}>
                      <IntlMessages id="pending" />
                    </CBadge>
                  ) : record.status === "active" ? (
                    <CBadge color={getBadge("accepted")}>
                      <IntlMessages id="active" />
                    </CBadge>
                  ) : (
                    <CBadge color={getBadge("block")}>
                      <IntlMessages id="block" />
                    </CBadge>
                  )}
                </span>
              </td>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            const invitationStatus = record.invitationGuid;
            const status = record.status;
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
              
                      <CButton
                        size="sm"
                        color="success"
                        className="me-3"
                        onClick={() => showModal(record)}
                      >
                        <span style={{ color: "white" }}>
                          <CIcon icon={cilPen} className="me-2" />
                          <IntlMessages id="edit" />
                        </span>
                      </CButton>
                 
                  {status === "pending" && (
                    <CButton
                      className="me-3"
                      size="sm"
                      color="success"
                      onClick={() =>
                        updateCompany(record._id, "status", "active")
                      }
                    >
                      <span style={{ color: "white" }}>
                        <CIcon icon={cilCheckAlt} className="me-2" />
                        <IntlMessages id="approve" />
                      </span>
                    </CButton>
                  )}
                  {status === "active" && (
                    <CButton
                      size="sm"
                      color="danger"
                      className="me-3"
                      onClick={() =>
                        updateCompany(record._id, "status", "block")
                      }
                    >
                      <span style={{ color: "white" }}>
                        <CIcon icon={cilBan} className="me-2" />
                        <IntlMessages id="block" />
                      </span>
                    </CButton>
                  )}

                  {status === "block" && (
                    <CButton
                      size="sm"
                      color="danger"
                      className="me-3"
                      onClick={() =>
                        updateCompany(record._id, "status", "active")
                      }
                    >
                      <span style={{ color: "white" }}>
                        <CIcon icon={cilLockUnlocked} className="me-2" />
                        <IntlMessages id="unBlock" />
                      </span>
                    </CButton>
                  )}

                  {invitationStatus === "newCompany" && (
                    <CButton
                      size="sm"
                      color="light"
                      className="me-3"
                      onClick={() => resendEmail(record)}
                    >
                      <span style={{ color: "white" }}>
                        <CIcon icon={cilSend} className="me-2" />
                        <IntlMessages id="resendEmail" />
                      </span>
                    </CButton>
                  )}

                  {invitationStatus !== "newCompany" &&
                    status !== "pending" &&
                    status !== "block" && (
                      <CButton
                        size="sm"
                        color="dark"
                        className="me-3"
                        onClick={() => resetPassword(record._id)}
                      >
                        <span style={{ color: "white" }}>
                          <CIcon icon={cilHttps} className="me-2" />
                          <IntlMessages id="resetPassword" />
                        </span>
                      </CButton>
                    )}
                  {(status === "active" ||
                    status === "block" ||
                    status === "pending") && (
                    <DeleteModal
                      contentLangueId={intlPlaceholder("companyDeleteMessage")}
                      type="deleteCompany"
                      selectedItem={record}
                      ButtonTitleId="Delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />
                  )}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
      <ResetPasswordModal />
      <EditCompanyModal />
      <PermissionModal Permission_Modal={Permission_Modal} />
    </>
  );
};

export default SmartTableBasicExample;
