import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CheckUserAccess from '../../../util/checkUserAccess'
const BankTable = ({ Banks, bankSpinner }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);




  let columns = [
    {
      label: <IntlMessages id="bank.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="bank.address" />,
      key: "address",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="currency.createdBy" />,
      key: "createdByName",
      _style: { width: "40%" },
    },
    
  ];

  const found =CheckUserAccess('bank',['edit','delete']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }



  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const editRecord = (data) => {
    dispatch({
      type: "show_bank_modal_edit",
      payload: data,
    });
  };

  const edit = CheckUserAccess('bank',['edit']) 
  const deleted =CheckUserAccess('bank',['delete'])

  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={bankSpinner}
        items={bankSpinner ? [] : Banks && Banks.length > 0 ? Banks : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  
                 {edit&&   <CButton
                      size="sm"
                      color="warning"
                      className="me-3"
                      onClick={() => editRecord(record)}
                    >
                      <span style={{ color: "white" }}>
                        <span className="icon icon-edit me-2"></span>
                        <IntlMessages id="bank.edit" />
                      </span>
                    </CButton>}
                 

                 
                    {deleted &&  <DeleteModal
                      contentLangueId="bank.deleteModal.message"
                      type="deleteBank"
                      selectedItem={record}
                      ButtonTitleId="store.action.delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />}
                 
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default BankTable;
