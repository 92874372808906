import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import {CButton} from '@coreui/react-pro'
import InvoiceSignature from "src/components/InvoiceSignature";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const FundTransferDetail = () => {
  const componentRef = useRef();
  const invProps = useSelector((state) => state.accountReducer);
  const {
    sourceAccountNo,
    prefix,
    sourceBankName,
    destinationBankName,
    destinationAccountNo,
    transferDate,
    transferId,
    note,
  } =
    invProps.FundTransferDetail !== null
      ? invProps.FundTransferDetail
      : "000-000-000";

  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const columns = [
    {
        label:<IntlMessages id="receiptDetail.PaymentMode" />,
        key: "PaymentMode",
        _style: { width: "40%" },
      },
  
    {
        label: <IntlMessages id="receiptDetail.Amount" />,
        key: "Amount",
        _style: { width: "40%" },
      },
    {
        label: <IntlMessages id="receiptDetail.currencySymbol" />,
        key: "currencySymbol",
        _style: { width: "40%" },
      },
    {
        label: <IntlMessages id="receiptDetail.referenceNumber" />,
        key: "referenceNumber",
        _style: { width: "40%" },
      },
  
   
  ];

  const renderPaymentDetail = () => {
    // return <TransactionType
    //     columns={columns}
    //     data={paymentModeArray}
    //     className="w-100 receipt-table"
    //     size="small"
    //     rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
    //     />
    const paymentModeArray = invProps.FundTransferDetail
      ? [invProps.FundTransferDetail]
      : [];
    return (
      <TransactionType
       
        items={paymentModeArray}
        columns={columns}
        
        scopedColumns={{
            PaymentMode: (record) => {
                return (<td style={{
                    textTransform: 'capitalize'
                }}>{record.transferMode}</td>)
            },
            Amount: (record) => {
                return (<td>{ChangeNumberFormate(record.amount && parseFloat(record.amount).toFixed(2))}</td>)
            },
            currencySymbol: (record) => {
                return (<td>{record.currencySymbol && record.currencySymbol}</td>)
            },
            referenceNumber: (record) => {
                return (<td>{record.referenceNumber ? record.referenceNumber : '---'}</td>)
            },
        }}
      />
    );
  };

  const cashLng = intlPlaceholder('fundTransfer.cash')
  const sourceLng = intlPlaceholder('source')
  const noteLng = intlPlaceholder('note')
  const destinationLng = intlPlaceholder('destination')
  const serialNumber = intlPlaceholder('fundTransfer.referenceNumber')

  return (
    <div>
      <div className="text-end">
        <ReactToPrint
          trigger={() => (
            <CButton
                  color="info"
                  className="text-white me-3 "
                >
                  <CIcon icon={cilPrint} className="me-2" />
                  <IntlMessages id="print" />
                </CButton>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="print-invoice p-3 float-left w-100 pe-5 ps-5 " ref={componentRef}>
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          <IntlMessages id="receiptDetail.fundTransferLabel" />
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(transferDate)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>{sourceLng}</h4>
              <p style={{ color: "grey" }}>
                {sourceBankName && sourceBankName ? sourceBankName : cashLng} (
                {sourceAccountNo && sourceAccountNo ? sourceAccountNo : "0000"})
              </p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}> {noteLng}: </h4>
              <p style={{ color: "grey" }}>{note}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>{destinationLng}</h4>
              <p style={{ color: "grey" }}>
                {destinationBankName && destinationBankName
                  ? destinationBankName
                  : cashLng}
                (
                {destinationAccountNo && destinationAccountNo
                  ? destinationAccountNo
                  : "0000"}
                )
              </p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}> {serialNumber} </h4>
              <p style={{ color: "grey" }}>{prefix + "-" + transferId}</p>
            </div>
          </div>
        </div>
        <br />
        <div>{renderPaymentDetail()}</div>
        <InvoiceSignature data={{clientName : sourceBankName ? sourceBankName : cashLng }}  />
      </div>
    </div>
  );
};

export default FundTransferDetail;
