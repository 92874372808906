import React, { useState, useEffect } from "react";
import UnitList from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";

import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import {
  CCardBody,
  CButton,
  CCollapse,
  CFormCheck,
  CFormInput,
} from "@coreui/react-pro";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const InvoiceCompanyStoreProductGrid = ({
  showAction = true,
  setValue,
  reset,
  setDisableSaveBtn,
  setRebateValue,
  rebateValue
}) => {
  const [key, setKey] = useState(0);
  
  const { localProducts, ProductList, editModalRecord } = useSelector(
    (state) => state.ProductR
  );
  const { rebate } = editModalRecord;
  const dispatch = useDispatch();

  let priceShouldBeNumeric = intlPlaceholder("priceShouldBeNumeric");
  let priceShouldBeBiggerThan0 = intlPlaceholder("priceShouldBeBiggerThan0");
  let quantityShouldBeNumeric = intlPlaceholder("quantityShouldBeNumeric");
  let quantityShouldBeBiggerThan0 = intlPlaceholder(
    "quantityShouldBeBiggerThan0"
  );

  const changeRebateValue = () => {
    if (rebate) {
      setRebateValue(rebate);
    }
  };

  useEffect(changeRebateValue, [rebate]);

  const renderUnit = (unitId, subunit = null, retail, productId) => {
    if (ProductList) {
      const selectedParentIndex = _.findIndex(ProductList, {
        unit: unitId,
      });

      if (selectedParentIndex > -1) {
        if (subunit && retail) {
          let subunits = ProductList[selectedParentIndex].subUnits;
          let filterUnitName = _.filter(subunits, (x) => x._id === subunit);
          return filterUnitName[0].subUnitName;
        } else if (retail) {
          let selectedProduct = _.filter(
            ProductList,
            (item) => item._id === productId
          );
          let subunits = ProductList[selectedParentIndex].subUnits;
          let subunit = selectedProduct[0].subunit;
          let filterUnitName = _.filter(subunits, (x) => x._id === subunit);
          return (
            filterUnitName && filterUnitName[0] && filterUnitName[0].subUnitName
          );
        } else {
          return ProductList[selectedParentIndex].unitName;
        }
      }
    }
  };
  const deleteRecord = (record) => {
    dispatch({
      type: "deleteLocalProduct",
      payload: record,
    });
  };
  const convertParentToSubUnit = (productId, maxStockVal) => {
    let Product = ProductList.filter((item) => item._id === productId);
    if (Product && Product.length > 0) {
      return parseFloat(Product[0].packaging) * parseFloat(maxStockVal);
    }
  };
  const ParentPackage = (productId) => {
    let Product = ProductList.filter((item) => item._id === productId);
    if (Product && Product.length > 0) {
      return parseFloat(Product[0].packaging);
    }
  };

  let columns = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell, record) => {
        return <span>{cell || record.productName}</span>;
      },
    },

    {
      dataField: "unitName",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell, record) => {
        return renderUnit(
          record.unit,
          record.subunit,
          record.retail,
          record._id
        );
      },
    },
    {
      text: <IntlMessages id="product.retail" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <CFormCheck
            defaultChecked={record.retail}
            onChange={(e) => {
              let obj = {
                fieldChanged: "retail",
                updatedRow: {
                  ...record,
                  retail: e.target.checked,
                },
              };
              if (e.target.checked) {
                let selectedProduct = _.filter(
                  ProductList,
                  (item) => item._id === record._id
                );
                let subunit = selectedProduct[0].subunit;
                obj["updatedRow"]["subunit"] = subunit;
              }

              dispatch({
                type: "updateLocalProductStoreInvoice",
                payload: obj,
              });
            }}
          />
        );
      },
    },
    {
      dataField: "quantity",
      text: <IntlMessages id="product.quantity" />,
      // editable: (cell, record) => {
      //   return !!+record.stockQuantity;
      // },
      editable: true,
      formatter: (cell, record) => {
        return <span>{parseFloat(cell || record.quantity)}</span>;
      },
      validator: (newValue, row, column) => {
        let packaging = ParentPackage(row._id);
        let qty = +newValue;
        let stockQty = +row.stockQuantity;
        let maxChangeStockQuantity = row.retail
          ? convertParentToSubUnit(row._id, row.maxChangeStockQuantity)
          : +row.maxChangeStockQuantity;

        if (isNaN(qty)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: quantityShouldBeNumeric,
          };
        }
        if (qty > maxChangeStockQuantity && !row.retail) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        }
        if (
          qty > maxChangeStockQuantity * packaging &&
          row.retail &&
          !row.edit
        ) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        }
        if (qty <= 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: quantityShouldBeBiggerThan0,
          };
        }
        if (qty > maxChangeStockQuantity) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        } else {
          setDisableSaveBtn(false);
          return true;
        }
      },
      classes: (cell, record) => {
        return "selectable-cell";
      },
    },
    {
      dataField: "maxChangeStockQuantity",
      editable: false,
      text: <IntlMessages id="product.stockQuantityAllow" />,
      formatter: (cell, record) => {
        if (record.retail) {
          return (
            <span>
              {convertParentToSubUnit(
                record._id,
                record.maxChangeStockQuantity
              ).toFixed(3)}
            </span>
          );
        } else {
          return <span>{parseFloat(cell).toFixed(3)}</span>;
        }
      },
    },
    // {
    //   dataField: "stockQuantity",
    //   text: <IntlMessages id="product.sockQty" />,
    //   editable: false,
    //   formatter: (cell) => {
    //     return <span>{parseFloat(cell)}</span>;
    //   },

    // },
    {
      dataField: "localPrice",
      text: <IntlMessages id="product.UnitPrice" />,
      editable: true,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue <= 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeBiggerThan0,
          };
        } else {
          setDisableSaveBtn(false);
          return {
            valid: true,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "totalPrice",
      text: <IntlMessages id="product.TotalPrice" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <span>
            {" "}
            {ChangeNumberFormate(parseFloat(cell || record.total).toFixed(2))}
          </span>
        );
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },
    },
  ];

  const renderTotalPrice = () => {
    let total = 0;

    localProducts &&
      localProducts.length > 0 &&
      localProducts.forEach((item) => {
        total = item.totalPrice + total;
      });

    total = total - parseFloat(rebateValue);

    total = ChangeNumberFormate(total.toFixed(2));
    return total;
  };

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        key={key}
        data={localProducts}
        columns={columns}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField;
            dispatch({
              type: "updateLocalProductStoreInvoice",
              payload: {
                fieldChanged: changedField,
                updatedRow: row,
                newValue: newValue,
              },
            });
          },
        })}
      />

      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tr>
          <td colspan="1">
            <IntlMessages id="invoiceDetail.rebate" />
          </td>
          <td colspan="3">
            <CFormInput
              defaultValue={rebate}
              type={"number"}
              className={rebateValue < 0 ? "is-invalid" : ""}
              onChange={(e) => {
                setRebateValue(e.target.value);
              }}
            />
            {rebateValue < 0 && (
              <div id="validationServer03Feedback" class="invalid-feedback">
               <IntlMessages id="invoiceDetail.rebateErrorMessage" />
              </div>
            )}
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colSpan="6" style={{ textAlign: "center" }}>
            <h5>Total</h5>
          </td>

          <td colSpan="2" style={{ textAlign: "left" }}>
            <h5>{renderTotalPrice()}</h5>
          </td>
        </tr>
      </table>
    </>
  );
};

export default InvoiceCompanyStoreProductGrid;
