import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { useSelector } from "react-redux";
import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage
} from "src/constants/CommonFn";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import CSmartTable from '../../../components/CSSmartTable'
import CheckUserAccess from "../../../util/checkUserAccess";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const BankSummaryList = ({ Data, Spinner, filterDate, setFilteredCurrency }) => {

  const [details, setDetails] = useState([]);

  const authProps = useSelector((state) => state.auth);
  const { locale } = useSelector((state) => state.Setting);

  const { LogeInUser } = authProps;
  let headerTitleAccountSummery = intlPlaceholder("report.account.summary");
  let textIn = intlPlaceholder("report.in");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");
  let TotalText = intlPlaceholder("Total");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("Description"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("Debit"), style: "tableHeader" },
      { text: intlPlaceholder("Credit"), style: "tableHeader" },
      { text: intlPlaceholder("Balance"), style: "tableHeader" },
    ],
  ];

  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  const history = useHistory();

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.names" />,
      key: "bankName",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.currency" />,
      key: "currencySymbole",
      _style: { width: "40%" },
      sorter: false,
    },

    {
      label: <IntlMessages id="balanceSheet.montant" />,
      key: "balance",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.initial" />,
      key: "initialBalance",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.In" />,
      key: "In",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.out" />,
      key: "out",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.deposit" />,
      key: "deposit",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.bankWithdrawal" />,
      key: "withdrawal",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.expense" />,
      key: "expense",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.clientWithdrawal" />,
      key: "clientWithdrawal",
      _style: { width: "40%" },
      sorter: false,
    },

    {
      label: '',
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const viewDetail = (data) => {
    data.filterDate = filterDate;



    history.push({ pathname: "/bankBalanceSheet", state: data });
  };

  const getDesc = (item) => {
    let desc = conversionInLanguage(locale.locale, item)
    return desc
  }

  const downloadPDF = async (data) => {
    const token = localStorage.getItem("token");
    const { bankId, currencyId, paymentMode, bankName, currencySymbole } = data;

    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let dataObj = {
      startDate: startDate,
      endDate: endDate,
      type: paymentMode,
      currency: currencyId,
      bank: bankId,
      recordFor: "bankBalanceSheet",
      fileName: "balanceSheetDetail",
    };

    fetch(`${ROOT_URL}/create-pdf`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: token,
      }),
      body: JSON.stringify(dataObj),
    }).then((response) => {
      response.json().then((response) => {
        let Record = response.Record.reverse();

        let Total = 0;
        let debitTotal = 0;
        let creditTotal = 0;


        Record.forEach((Item, index) => {
          let debit = parseFloat(Item.debit).toFixed(2);
          let credit = parseFloat(Item.credit).toFixed(2);
          let balance = parseFloat(Item.balance).toFixed(2);

          debitTotal = debitTotal + parseFloat(debit);
          creditTotal = creditTotal + parseFloat(credit);
          Total = Total + parseFloat(balance);


          debit = Item.debit !== 0 ? NumberWithCommas(debit) : "";

          credit = Item.credit !== 0 ? NumberWithCommas(credit) : "";
          balance = NumberWithCommas(balance);
          let date = ChangeDateFormate(Item.createdAt);

          let objArr = [date];
          let descContent = conversionInLanguage(locale.locale, Item.description)
          let desc = [
            {
              text: descContent,
              alignment: "left"
            }
          ];

          objArr.push(desc);

          let ref = [
            {
              text: "",
              alignment: "center"
            }
          ];

          if (
            Item.receiptType === "Deposit" ||
            Item.receiptType === "withdraw" ||
            Item.receiptType === "companyExpense" ||
            Item.receiptType === "salaryPosted"
          ) {
            ref[0].text = `${Item.prefix}-${Item.extraDetail}`;
            objArr.push(ref);
          } else {
            ref[0].text = `${Item.prefix}-${Item.reference}`;
            objArr.push(ref);
          }


          objArr.push(debit);
          objArr.push(credit);
          objArr.push(balance);



          bodyData = [...bodyData, objArr];
        });

        creditTotal = parseFloat(creditTotal).toFixed(2);
        debitTotal = parseFloat(debitTotal).toFixed(2);


        let letTotal = [
          {
            text: TotalText,
            style: "tableHeader",
            colSpan: 3,
            alignment: "center",
          },
          {},
          {},
          { text: NumberWithCommas(debitTotal), style: "tableBody" },
          { text: NumberWithCommas(creditTotal), style: "tableBody" },
          {},
        ];
        bodyData = [...bodyData, letTotal];

        const logoImage = localStorage.getItem('image') ? localStorage.getItem('image') : process.env.REACT_APP_LOGO_DUMMY;


        let headerTitle = `${headerTitleAccountSummery.toUpperCase()} ${bankName.toUpperCase()} ${textIn.toUpperCase()} ${currencySymbole} ${reportFrom} ${ChangeDateFormate(
          startDate,
          "DD-MM-YYYY"
        )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
        let footerAddress = localStorage.getItem("address");
        let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

        let docDefinition = {
          pageOrientation: 'landscape',
          content: [
            {
              image: `data:image/jpeg;base64,${logoImage}`,
              fit: [250, 250],
              width: 150,
              height: 150,
            },
            {
              text: headerTitle,
              style: { fontSize: 18, bold: true, alignment: "center" },
              margin: [15, 10],
            },
            {
              style: "tableBody",
              headerRows: 1,

              table: {
                widths: [80, "*", 100, "auto", "auto", 110],
                body: bodyData,
              },
            },
          ],
          footer: {
            text: [
              {
                text: footerAddress,
                alignment: "center",
                fontSize: 10,
                width: "100%",
              },
              {
                text: `\n ${footerAddress2}`,
                alignment: "center",
                fontSize: 10,
                width: "100%",
              },
            ],
          },

          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              textAlign: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: "100%",
            },
            tableOpacityExample: {
              margin: [0, 5, 0, 15],
              fillColor: "blue",
              fillOpacity: 0.3,
            },
            tableHeader: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center"
            },
            tableBody: {
              bold: false,
              fontSize: 10,
              alignment: "right"
            },
          },
        };
        pdfMake.createPdf(docDefinition).download("accountDetail.pdf");
      });
    });
  };

  const pdfAccess = CheckUserAccess('companyAccounts', ['pdf'])

  return (
    <>
      <CSmartTable
        loading={Spinner}
        sorterValue={{ column: "bankName", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={Spinner ? [] : Data}
        columns={columns}
        columnFilter
        onColumnFilterChange={(obj) => {


          if (obj['currencySymbole'] !== undefined || obj['currencySymbole'] !== null) {
            let value = obj['currencySymbole']
            setFilteredCurrency(value)
          }
        }}
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          bankName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.paymentMode === "cash" ? (
                    <IntlMessages id="account.cash" />
                  ) : (
                    record.bankName && record.bankName
                  )}
                  {/* <span className="ms-2">
                    ({record.currencySymbole && record.currencySymbole}){" "}
                  </span> */}
                </td>
              </>
            );
          },
          currencySymbole: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.currencySymbole}
                </td>
              </>
            );
          },
          balance: (record) => {
            return (
              <>
                {record.balance && record.balance > 0 ? (
                  <td style={{ color: "green" }} className="text-end">
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </td>
                ) : (
                  <td style={{ color: "red" }} className="text-end">
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </td>
                )}
              </>
            );
          },
          initialBalance: (record) => {
            return (
              <>
                {record.initialBalance && record.initialBalance > 0 ? (
                  <td style={{ color: "green" }} className="text-end">
                    {ChangeNumberFormate(
                      parseFloat(record.initialBalance).toFixed(2)
                    ) || 0}
                  </td>
                ) : (
                  <td style={{ color: "red" }} className="text-end">
                    {ChangeNumberFormate(
                      parseFloat(record.initialBalance).toFixed(2)
                    ) || 0}
                  </td>
                )}
              </>
            );
          },
          In: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.In).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
          out: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.out).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
          deposit: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.deposit).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
          withdrawal: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.withdrawal).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
        
          expense: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.expense).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
          clientWithdrawal: (record) => {
            return (
              <>
                <td className="text-end">
                  {ChangeNumberFormate(
                    parseFloat(record.clientWithdrawal).toFixed(2)
                  ) || 0}
                </td>

              </>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  <CButton

                    color="warning"
                    className="me-3"
                    onClick={() => viewDetail(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-editor me-1"></span>
                      <IntlMessages id="viewDetails" />
                    </span>
                  </CButton>
                  {pdfAccess && <CButton
                    color="danger"
                    onClick={() => downloadPDF(record)}

                  >
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf" />
                    </span>
                  </CButton>}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
        summary={(pageData) => {
          let totalExpense = 0;
          let initial = 0;

          let InTotal = 0;
          let outTotal = 0;
          let depositTotal = 0;
          let withdrawalTotal = 0;
          let expenseTotal = 0;
          let clientWithdrawalTotal = 0;

          pageData.forEach(({ balance, initialBalance, In, out, deposit, withdrawal,expense, clientWithdrawal }) => {
            totalExpense += parseFloat(balance);
            initial += parseFloat(initialBalance);
            InTotal += parseFloat(In);
            outTotal += parseFloat(out);
            depositTotal += parseFloat(deposit);
            withdrawalTotal += parseFloat(withdrawal);
            expenseTotal += parseFloat(expense);
            clientWithdrawalTotal += parseFloat(clientWithdrawal);

          });


          return (
            <>
              <tr>
                <th colSpan={2} className="text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalExpense.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(initial.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(InTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(outTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(depositTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(withdrawalTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(expenseTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(clientWithdrawalTotal.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th></th>
              </tr>
            </>
          );
        }}
      />
    </>
  );
};

export default BankSummaryList;
