import React from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import SaleInvoiceForm from "./SaleInvoiceForm.js";
import IntlMessages from "../../../util/IntlMessages";

const Index = () => {
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <h1>
                <IntlMessages id="addSaleInvoice.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <SaleInvoiceForm />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
