import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  modalEditVisible: false,
  passModalVisible: false,
  selectedItem: null,
  propertyLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  Properties: [],
  fetchPropertyInfo: false,
  propertySpinner: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "properties_SUCCESS": {
      return { ...state, Properties: action.payload.Record, fetchPropertyInfo: false };
    }
    case "propertySpinner_START": {
      return { ...state, propertySpinner: true };
    }
    case "propertySpinner_STOP": {
      return { ...state, propertySpinner: false };
    }

    case "show_property_modal": {
      return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
    }

    case "property_START": {
      return { ...state, propertyLoader: true };
    }
    case "property_STOP": {
      return { ...state, propertyLoader: false };
    }

    case "addProperty_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        Properties: [action.payload.Record, ...state.Properties],
        propertyLoader: false,
      };
    }
    case "addProperty_FAILURE": {
      return { ...state, error: true, success: false, propertyLoader: false, message: action.payload };
    }

    case "property_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        modalEditVisible: false,
        resendError: false,
        resendSuccess: false,
        propertyLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
      };
    }

    case "deleteProperty_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Properties;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return { ...state, Properties: newCat, selectedItem: null, success: true, message: "recordDeletedSuccessfully" };
    }

    default:
      return state;
  }
};
