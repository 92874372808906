import React, { useEffect } from "react";
import CSmartTable from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";

import { DataGetAction } from "../../../redux/actions/http";

const SupplierProductList = () => {
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.balanceSheet);
  const { StoreProductSheetReport, storeProductSpinner, getAllStoreSpinner } =
    blProps;

  const fetchBalanceSheet = () => {
    dispatch(DataGetAction("getAllCategory", { query: "all" }));
    dispatch(DataGetAction("getAllProductStock", { query: "all" }));

    return () => {
      dispatch({
        type: "emptyProduct",
      });
    };
  };

  useEffect(fetchBalanceSheet, []);

  const columns = [
    {
      label: <IntlMessages id="storeProduct.createdAt" />,
      key: "createdAt",
      filter : (data)=>{
      },
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="storeProduct.category" />,
      key: "categoryName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="storeProduct.product" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="storeProduct.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="storeProduct.quantity" />,
      key: "qty",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="storeProduct.total" />,
      key: "amount",
      _style: { width: "40%" },
    },
  ];

  return (
    <>
      <CSmartTable
        loading={storeProductSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={
          storeProductSpinner || getAllStoreSpinner
            ? []
            : StoreProductSheetReport
        }
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          categoryName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.categoryName && record.categoryName}
                </td>
              </>
            );
          },
          productName: (record) => {
            return <td>{record.productName && record.productName}</td>;
          },
          qty: (record) => {
            return (
              <td className="text-end">
                {Number.isInteger(parseFloat(record.qty))
                  ? record.qty
                  : parseFloat(record.qty).toFixed(3)}
              </td>
            );
          },

          unitName: (record) => {
            return (
              <td className="text-center">
                {record.unitName && record.unitName}
              </td>
            );
          },

          amount: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.amount && parseFloat(record.amount).toFixed(2)
                )}
              </td>
            );
          },
          createdAt: (record) => {
            
            return <td>{record.createdAt}</td>;
          },
        }}
        summary={(pageData) => {
          let totalAmount = 0;
          let totalQuantity = 0;
          pageData.forEach(({ amount, qty }) => {
            totalAmount += parseFloat(amount);
            totalQuantity += parseFloat(qty);
          });

          return (
            <>
              <tr>
                <th colSpan={4} className="text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th className="text-end">
                  <h5>{ChangeNumberFormate(totalQuantity.toFixed(3)) || 0}</h5>
                </th>
                <th className="text-end">
                  <h5>{ChangeNumberFormate(totalAmount.toFixed(2)) || 0}</h5>
                </th>
              </tr>
            </>
          );
        }}
      />
    </>
  );
};

export default SupplierProductList;
