import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CMultiSelect,
  CFormSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGetAction } from "../../../redux/actions/http";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { selectStyle } from "../../../constants/CommonFn";
import _ from "lodash";
import Product from "src/redux/reducers/Product";
import { FormInput } from "./../../../components/form/form-input";

const InvoiceCompanyStoreProductForm = (props) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(1);

  const { localProducts, ProductList, editModalRecord } = useSelector(
    (state) => state.ProductR
  );
  const { stores } = useSelector((state) => state.distributeReducer);

  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      )
      .nullable(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    dispatch({
      type: "setLocalProductStoreInvoice",
      payload: {
        product: _.map(values.product, "value"),
        isAddProduct: true,
        productsOfApi: editModalRecord?.products,
      },
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => {
        return {
          label: data[label],
          value: data[key],
        };
      });
    }
  };

  const renderStockOptions = (data, label, key) => {
    if (data && data.length > 0) {
      let arrayResult = [];
      data.map((data) => {
        if (data.sockQty > 0) {
          arrayResult.push({
            label: data[label],
            value: data[key],
          });
        }
      });
      return arrayResult;
    }
  };

  useEffect(() => {
    if (editModalRecord?.storeId) {
      props.setValue("store", editModalRecord.storeId);
    }
  }, [editModalRecord]);
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row mb-4">
        {/* store */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(stores, function (o) {
                return o._id === editModalRecord?.storeId;
              });

              return (
                <div
                  className="d-flex px-2 align-items-center"
                  style={{
                    border: "1px solid #9E9E9E",
                    borderRadius: "6px",
                    height: "38px",
                  }}
                >
                  {editModalRecord && editModalRecord.store}
                  <div
                    className="d-none"
                    style={{ height: "0px", width: "0px", overflow: "hidden" }}
                  >
                    <FormInput
                      control={control}
                      errors={errors}
                      defaultValue={editModalRecord && editModalRecord.storeId}
                      name="storeId"
                      type="select"
                    />
                  </div>
                </div>
              );
            })()}
          </div>
        </div>
        {/* product */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(ProductList, function (o) {
                return _.map(localProducts, "_id").includes(o._id);
              });

              return (
                <FormInput
                  control={control}
                  errors={errors}
                  defaultValue={renderStockOptions(render, "name", "_id")}
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                  name="product"
                  type="select"
                  isMulti={true}
                  options={renderStockOptions(ProductList, "name", "_id")}
                  onHandleChange={(value) => (value.length ? value : false)}
                />
              );
            })()}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CLoadingButton
            className="w-100 d-block"
            type="submit"
            color="primary"
          >
            <CIcon icon={cilPlus} className="me-2" />
            <IntlMessages id="product.addProductPage.addItem" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default InvoiceCompanyStoreProductForm;
