import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import TransactionType from "../../../components/InvoiceTable";
import IntlMessages from "../../../util/IntlMessages";
import { ChangeDateFormate, ChangeNumberFormate } from "../../../constants/CommonFn";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { useHistory } from "react-router";
import { notify } from "src/util/ShowToast";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const ViewDetail = () => {
  const componentRef = useRef();
  const comProps = useSelector((state) => state.ceReducer);

  const { Banks, refundExpenseSuccess, refundExpenseError, message } = comProps;
  const history = useHistory();
  const dispatch = useDispatch()
  const {
    createdAt,
    prefix,
    purpose,
    type,
    amount,
    createdByUser,
    expenseNo,
    currencyInfo,
    status,
    _id,
    expenseType,
  } = Banks && Banks.length > 0 ? Banks[0] : "";
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");
  const oldReceipt = Banks && Banks.length > 0 && Banks[0].oldExpenseNo && Banks[0].oldExpenseNo;
  const isRefund = Banks && Banks.length > 0 && Banks[0].refund && Banks[0].refund;

  const columns = [
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.bankName" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionType" />,
      key: "TransactionType",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionNo" />,
      key: "TransactionNo",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="receiptDetail.Status" />,
      key: "Status",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.Amount" />,
      key: "Amount",
      _style: { width: "40%" },
    },
  ];

  const areYouSureYouWantToRefundTheReceiptNumber = intlPlaceholder(
    "areYouSureYouWantToRefundTheReceiptNumber"
  );

  if (refundExpenseSuccess) {
    history.push("/companyExpenses");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "ce_UPDATE",
    });
  }

  if (refundExpenseError) {
    let messageLng = intlPlaceholder(message);
    history.push("/companyExpenses");
    notify("error", messageLng);
    dispatch({
      type: "ce_UPDATE",
    });
  }

  const renderPaymentDetail = () => {
    const paymentModeArray = Banks ? Banks : [];

    return (
      <TransactionType
        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          mode: (record) => {
            return (
              <td>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {<IntlMessages id={`${record.mode}.paymentMethod`} />}
                </span>
              </td>
            );
          },
          bankName: (record) => {
            return (
              <td>
                {record.bankName === "cashCaisse" ? (
                  <IntlMessages id={`${record.bankName}.bankName`} />
                ) : (
                  record.bankName
                )}
              </td>
            );
          },
          TransactionType: (record) => {
            return (
              <td>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {record.paymentMode.mode === "bank"
                    ? record.paymentMode.transactionType
                    : "---"}
                </span>
              </td>
            );
          },
          TransactionNo: (record) => {
            return (
              <td>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {record.paymentMode.mode === "bank"
                    ? record.paymentMode.transactionNumber
                    : "---"}
                </span>
              </td>
            );
          },
          Status: (record) => {
            return (
              <td>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {intlPlaceholder(status)}
                </span>
              </td>
            );
          },
          Amount: (record) => {
            return (
              <td>
                {currencyInfo && currencyInfo.length > 0
                  ? currencyInfo[0].name
                  : ""}{" "}

                {ChangeNumberFormate(parseFloat(amount).toFixed(2))}
              </td>
            );
          },
        }}
      />
    );
  };
  const renderLogoHere = () => {
    const imagePath = localStorage.getItem("profileImage");
    if (imagePath && imagePath !== "") {
      return <img src={imagePath} alt="logo" width="120px" />;
    } else {
      return "Logo Here";
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end text-end">
        {isRefund && <CButton
          color="success"
          className="text-white me-3"

        >
          <span><IntlMessages id="refunded" /></span>

        </CButton>}
        {/* receipt comment */}
        {(expenseType && expenseType !== 'refundExpense' && !isRefund) && <DeleteModal
          contentLangueId={`${areYouSureYouWantToRefundTheReceiptNumber} ${prefix}-${expenseNo}`}
          method="POST"
          type="refundExpense"
          textColor="#ffffff"
          selectedItem={{ _id }}
          ButtonTitleId="receipt.refund"
          titleLanguageId="receipt.refund"
          size="md"
          color="danger"
          variant=""
        />}

        {!isRefund && <ReactToPrint
          trigger={() => (
            <CButton color="info" className="text-white me-3">
              <CIcon icon={cilPrint} className="me-2" />
              <IntlMessages id="print" />
            </CButton>
          )}
          content={() => componentRef.current}
        />}
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 "
        ref={componentRef}
      >
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          {/* <IntlMessages id="ce.ExpenseLabel" /> */}
          {(expenseType && expenseType === 'refundExpense') ? `${intlPlaceholder('refundOfExpense')} ${prefix}-${oldReceipt} ` : <IntlMessages id="ce.ExpenseLabel" />}
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                {" "}
                <IntlMessages id="Sr" />#{" "}
              </h4>
              <p style={{ color: "grey" }}>{prefix + "-" + expenseNo}</p>
            </div>

            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="jobTitle" />
              </h4>
              <p style={{ color: "grey", textTransform: "capitalize" }}>
                {createdByUser && createdByUser[0].role === 'team' ? createdByUser && createdByUser[0].jobTitle : <IntlMessages id="invoiceReceipts.admin" />}
              </p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="type" />
              </h4>
              <p style={{ color: "grey", textTransform: "capitalize" }}>
                {type}
              </p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                {" "}
                <IntlMessages id="createdBy" />
              </h4>
              <p style={{ color: "grey", textTransform: "capitalize" }}>
                {createdByUser && createdByUser[0].name}
              </p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                {" "}
                <IntlMessages id="purpose" />{" "}
              </h4>
              <p style={{ color: "grey", textTransform: "capitalize" }}>
                {purpose}
              </p>
            </div>
          </div>
        </div>
        <br />

        <div className="w-100">{renderPaymentDetail()}</div>
        <InvoiceSignature showClient={"false"} />
      </div>
    </div>
  );
};

export default ViewDetail;
