import React, { useState, useEffect } from "react";
import { DataEntryModalAction } from "../../../../redux/actions/Common";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction } from "../../../../redux/actions/http";
import { notify } from "../../../../util/ShowToast";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CForm,
  CFormInput,
  CToaster,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";

const ResetPasswordModal = () => {
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.company);
  const {
    passModalVisible,
    selectedItem,
    resetPasswordSuccess,
    passwordLoader,
  } = comProps;

  const passwordRequired = intlPlaceholder("passwordRequired")
  const confirmPasswordRequired = intlPlaceholder("confirmPasswordRequired")
  const passwordMustMatch = intlPlaceholder("passwordMustMatch")

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(passwordRequired),
    confirm: Yup.string()
      .required(confirmPasswordRequired)
      .oneOf([Yup.ref("password")], passwordMustMatch),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const showPasswordModal = "show_password_modal";

  useEffect(() => {
    reset();
  }, [passModalVisible]);

  if (resetPasswordSuccess) {
    notify("success", intlPlaceholder("passwordResetSuccessfully"));
    dispatch({
      type: "reset_company_states",
    });
    dispatch({
      type: "hide_password_modal",
    });
    reset();
  }

  const onFinish = (values) => {
    values._id = selectedItem;

    dispatch(
      DataRequestAction(
        "POST",
        "resetUserPassword",
        values,
        "",
        "resettingPassword",
        false
      )
    );
  };

  return (
    <>
      <CModal visible={passModalVisible}>
        <CModalHeader
          onDismiss={() =>
            dispatch(DataEntryModalAction(showPasswordModal, null))
          }
        >
          <CModalTitle>
            <IntlMessages id="resetPassword" />
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)} validated={validated}>
          <CModalBody>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.password ? "is-invalid" : ""}
                  {...register("password")}
                  placeholder={intlPlaceholder("newPassword")}
                  type="password"
                  id="password"
                />
                <CFormLabel htmlFor="firstName">{intlPlaceholder("newPassword")}</CFormLabel>
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </CFormFloating>

              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.confirm ? "is-invalid" : ""}
                  {...register("confirm")}
                  placeholder={intlPlaceholder("confirmNewPassword")}
                  type="password"
                  id="confirm"
                />
                <CFormLabel htmlFor="firstName">
                {intlPlaceholder("confirmNewPassword")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.confirm?.message}
                </div>
              </CFormFloating>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() =>
                dispatch(DataEntryModalAction(showPasswordModal, null))
              }
            >
                {intlPlaceholder("close")}
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={passwordLoader}
              disabled={passwordLoader}
            >
              {intlPlaceholder("submit")}
            </CLoadingButton>
            {/* <CButton type="submit" color="primary" className="px-4">
                            Login
                        </CButton> */}
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export default ResetPasswordModal;

// import React from 'react';
// import { useForm } from 'react-hook-form';

// export default function App() {
//   const { register, handleSubmit, formState: { errors } } = useForm();



//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <input type="text" placeholder="First name" {...register("First name", {required: true, maxLength: 80})} />
//       <input type="text" placeholder="Last name" {...register("Last name", {required: true, maxLength: 100})} />
//       <input type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
//       <input type="tel" placeholder="Mobile number" {...register("Mobile number", {required: true, minLength: 6, maxLength: 12})} />
//       <select {...register("Title", { required: true })}>
//         <option value="Mr">Mr</option>
//         <option value="Mrs">Mrs</option>
//         <option value="Miss">Miss</option>
//         <option value="Dr">Dr</option>
//       </select>

//       <input {...register("Developer", { required: true })} type="radio" value="Yes" />
//       <input {...register("Developer", { required: true })} type="radio" value="No" />

//       <input type="submit" />
//     </form>
//   );
// }
