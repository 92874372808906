import React from "react";
import {
  CModal,
  CLoadingButton,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../util/IntlMessages";

const SendEmailModal = () => {
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);

  const logoutFn = () => {
    dispatch({
      type: "logout_success"
    })
  }

  return (
    <div>
      <CModal visible={authProps.unAuthorized_modal}>
        <div className="text-center p-5">
          <h2 className="mb-3"> <IntlMessages id="unAuthorizedModal.message" /></h2>

          <CLoadingButton
            type="submit"
            color="primary"
            onClick={() => {
              dispatch({
                type: "UN_AUTHORIZED_close",
              });
              logoutFn();
            }}
          >
            
            <IntlMessages id="login.login" />
          </CLoadingButton>
        </div>
      </CModal>
    </div>
  );
};

export default SendEmailModal;
