import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import CurrencyExchangeForm from "./components/CurrencyExchangeForm";
import CurrencyExchangeList from "../../components/CSSmartTable";
import CheckUserAccess from "../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const Index = () => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({ status: 'approve' });

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const dispatch = useDispatch();
  const comProps = useSelector(state => state.currencyExchangeR);



  const ViewCurrencyExchangeReceipt = (data) => {
      history.push({pathname : '/viewCurrencyExchangeReceipt', state :  { currencyExchangeId: data.currencyExchangeId }})
  }




  const columns = [
    {
      label: <IntlMessages id="currencyExchange.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.customerTransactionId" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label:<IntlMessages id="currencyExchange.givenAmount" />,
      key: "givenAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.receivedAmount" />,
      key: "receivedAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.factor" />,
      key: "factor",
      _style: { width: "40%" },
    },
    {
      label:<IntlMessages id="currencyExchange.customerName" />,
      key: "createdBy",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approve'), value: 'approve', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },
    {
      label: '',
      sorter : false,
      filter : false,
      key: "show_details",
      _style: { width: "40%" },
    },
    
  ];




  const fetchCurrency = () => {
      dispatch(DataGetAction('getAllCurrencyExchange', { query: "all" },'fetchingCurrencyExchange'));
      dispatch(DataGetAction('getAllCurrency', { query: "all" }));
  }
  useEffect(fetchCurrency, [])

  const create = CheckUserAccess('currencyExchange',['create'])
  return (
    <>
      <CRow>
        <CCol xs={12}>
          
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="currencyExchange.title" />
              </h1>
             
            </CCardHeader>
            <CCardBody>
            <div className="p-3">
            {create &&    <CurrencyExchangeForm />}
             
            </div>
              <DocsExample href="components/smart-table/">
                <CurrencyExchangeList
                  loading={comProps.currencyExchangeSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={comProps.currencyExchangeSpinner ? [] : comProps.CurrencyExchangeList}
                  columns={columns}
                  filterValueChangeTo={(value) => {
                    if (value.payableStatus) {
                      setStaticFilterValue(value)
                    }
                  }}
                  columnFilterValue={staticFilterValue}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.status === 'approve' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.status}`)}</CBadge>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                    
                      return (
                        <td>
                          <span>{record.createdAt}</span>
                        </td>
                      );
                    },
                    
                    givenAmount: (record) => {
                      return (
                        <td className="text-end"> 
                          <span>{record.givenCurrencySymbol} {ChangeNumberFormate(parseFloat(record.givenAmount).toFixed(2))}</span>
                        </td>
                      );
                    },
                    receivedAmount: (record) => {
                      return (
                        <td className="text-end">
                          <span>{record.receivedCurrencySymbol} {ChangeNumberFormate(parseFloat(record.receivedAmount).toFixed(2))}</span>
                        </td>
                      );
                    },
                    factor: (record) => {
                      return (
                        <td className="text-end">
                         {Number.isInteger(parseFloat(record.factor)) ? record.factor :  parseFloat(record.factor).toFixed(2)}
                        </td>
                      );
                    },

                    createdBy: (record) => {
                      return (
                        <td>
                         <span>{record.createdBy}</span>
                        </td>
                      );
                    },
                   
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => ViewCurrencyExchangeReceipt(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
