import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCollapse,
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch,useSelector } from "react-redux";
import DeleteModal from '../../../components/Modals/DeleteModal'
import CheckUserAccess from "../../../util/checkUserAccess";
import { notify } from "src/util/ShowToast";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const SupplierTable = ({ Suppliers ,supplierSpinner}) => {
  const comProps = useSelector((state) => state.supplier);

  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  

  let columns = [
    {
      label: <IntlMessages id="supplier.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="supplier.email" />,
      key: "email",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="supplier.phone" />,
      key: "phoneNumber",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="supplier.address" />,
      key: "address",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="supplier.currency" />,
      key: "currencyName",
      _style: { width: "40%" },
    },
  ];

  const found =CheckUserAccess('supplier',['edit','delete']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const editRecord = (data) => {
    dispatch({
      type: 'edit_supplier_modal',
      payload: data,
    });
  };

  const edit = CheckUserAccess('supplier',['edit'])
  const deleted = CheckUserAccess('supplier',['delete'])

  if(comProps.success){
    let message = intlPlaceholder(comProps.message)
    notify('error',message)

    dispatch({
        type: 'supplier_UPDATE',
    });
  }

  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={supplierSpinner}
        items={supplierSpinner ? [] :  Suppliers && Suppliers.length > 0 ? Suppliers : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
         
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                {edit  &&  <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                    <span className="icon icon-edit me-2"></span>
                      <IntlMessages id="Edit" />
                    </span>
                  </CButton>}
                  {deleted &&   <DeleteModal
                      contentLangueId="supplier.deleteModal.message"
                      type="deleteSupplier"
                      selectedItem={record}
                      ButtonTitleId="Delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />}
                  


                </CCardBody>
              </CCollapse>
            );
          },
          currencyName: (record) => {
            return (
              <td>
                <span>
                  {record.currencyName ? record.currencyName: ''}
                </span>
              </td>
            );
          },
        }}
      />
 
    </>
  );
};

export default SupplierTable;
