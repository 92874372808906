import React, { useState, useEffect } from "react";
import { CCard, CCardBody, CCol, CRow, CButton, CCardHeader, CCollapse, CBadge } from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import SaleList from "../../components/CSSmartTable";
import CheckUserAccess from "../../util/checkUserAccess";
import Filter from "./component/Filter";
import { DataGetAction } from "src/redux/actions/http";
import SaleNotesViewDetail from "./component/SaleNotesViewDetail";
import EditModal from "./component/EditQuickSaleModal";
import moment from "moment";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { notify } from "src/util/ShowToast";

const Index = () => {
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({
    status: "approved",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.quickSaleReducer);

  const {
    SaleListData,
    storeSaleSpinner,
    editModalVisible,
    selectedEditQuickSale,
    cancelQuickSaleInvoiceSuccess,
    cancelQuickSaleInvoiceFailure,
    cancelQuickSaleInvoiceMessge,
  } = comProps;
  console.log("======= SaleListData =======", SaleListData);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const callSaleApi = () => {
    dispatch(
      DataGetAction(
        "getSale",
        {
          query: "all",
          startDate: moment().toString(),
          endDate: moment().toString(),
        },
        "SaleSpinner"
      )
    );
  };
  useEffect(() => {
    dispatch({
      type: "resetLocalProduct",
    });
    dispatch({
      type: "RestProductStock",
    });

    callSaleApi();

    dispatch({
      type: "RESET_LOCAL_PRODUCT",
    });
    dispatch({
      type: "reset_add",
    });
  }, []);

  const editRecord = (data) => {
    dispatch({
      type: "Show_Edit_Modal",
      payload: data,
    });
    //history.push("/editQuickSale", { _id: data._id });
  };

  const renderAction = (record) => {
   

    return (
      <>
        <CButton size="sm" color="warning" className="me-3" onClick={() => viewDetail(record)}>
          <span style={{ color: "white" }}>
            <span className="icon icon-custom-view me-2"></span>
            <IntlMessages id="view" />
          </span>
        </CButton>
        {edit && (
          <CButton size="sm" color="warning" onClick={() => editRecord(record)}>
            <i className="icon icon-edit text-white me-1"></i>
            <span style={{ color: "white" }}>
              <IntlMessages id="Edit" />
            </span>
          </CButton>
        )}
      </>
    );
  };

  const viewDetail = (data) => {
    dispatch({ type: "openSaleModalDialog", payload: data });
  };

  const columns = [
    {
      label: <IntlMessages id="quickSale.prefix" />,
      key: "prefix",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quickSale.amountPaid" />,
      key: "transactionAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quickSale.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="quickSale.createdBy" />,
      key: "createdByUser",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: "dropDown",
      filterDropDownValue: [
        { label: intlPlaceholder("invoice.all"), value: "all" },
        {
          label: intlPlaceholder("invoice.approved"),
          value: "approved",
          selected: true,
        },
        { label: intlPlaceholder("invoice.cancelled"), value: "cancelled" },
      ],
      _style: { width: "40%" },
    },
    {
      label: "",
      sorter: false,
      filter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];
  const edit = CheckUserAccess("quickSale", ["edit"]);
  const create = CheckUserAccess("quickSale", ["create"]);

  const redirectToPage = () => {
    history.push("/addQuickSale");
  };

  if (cancelQuickSaleInvoiceSuccess) {
    callSaleApi();
    dispatch({ type: "closeViewSale" });
    notify("success", intlPlaceholder("recordCancelSuccessfully"));
    dispatch({ type: "resetCancelQuickSale" });
  }
  if (cancelQuickSaleInvoiceFailure) {
    dispatch({ type: "resetCancelQuickSale" });
    notify("error", intlPlaceholder("oopsSomethingWentWrong"));
  }

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="quickSale.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter />
                <div className="d-flex align-items-center mb-1">
                  {create && (
                    <CButton color="primary" className="me-3 text-white" onClick={() => redirectToPage()}>
                      <i className="icon icon-add me-1"></i>
                      <IntlMessages id="quick.sale.add.button.title" />
                    </CButton>
                  )}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <SaleList
                  loading={storeSaleSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={storeSaleSpinner ? [] : SaleListData}
                  columns={columns}
                  columnFilterValue={staticFilterValue}
                  filterValueChangeTo={(value) => {
                    if (value.status) {
                      setStaticFilterValue(value);
                    }
                  }}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge
                            style={{
                              textTransform: "capitalize",
                            }}
                            color={
                              record.status === "approved"
                                ? "success"
                                : "danger"
                            }
                          >
                            {" "}
                            {intlPlaceholder(`invoice.${record.status}`)}
                          </CBadge>
                        </td>
                      );
                    },
                    transactionAmount: (record) => {
                      return <td className="text-end">{ChangeNumberFormate(record.transactionAmount.toFixed(2))}</td>;
                    },

                    createdByUser: (record) => {
                      return (
                        <td>
                          <span>{record.createdByUser && record.createdByUser}</span>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                      return (
                        <td>
                          <span>{ChangeDateFormate(record.createdAt, "YYYY-MM-DD")}</span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>{renderAction(record)}</CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={(pageData) => {
                    let total = 0;

                    pageData.forEach(({ transactionAmount }) => {
                      total += transactionAmount;
                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center">
                          <h5>
                            <IntlMessages id="Total" />
                          </h5>
                        </th>

                        <th colSpan={1} className="text-end">
                          <span>
                            <h5>{ChangeNumberFormate(total.toFixed(2))}</h5>
                          </span>
                        </th>
                        <th colSpan={5}>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
        <SaleNotesViewDetail key="SaleNotesViewDetail" />
        <EditModal editModalVisible={editModalVisible} _id={selectedEditQuickSale} />
      </CRow>
    </>
  );
};

export default Index;
