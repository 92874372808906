import React, { useEffect, useRef } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch,useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ChangeDateFormate,ChangeNumberFormate } from "src/constants/CommonFn";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "src/util/IntlMessages";

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const componentRef = useRef();
  const param = useLocation();
  const { filterDate, data } = param.state || {};

  const blProps = useSelector((state) => state.trustFund);
  const { BalanceSheetList, fetchingBalanceSheetForTrustUser } = blProps;



  const trustyId = data.trustyId ? data.trustyId : "trustyId";
  const currencyId = data.currencyId ? data.currencyId : "currencyId";
  const currencyName = data.currencyName ? data.currencyName : "currencyName";
  const currencySymbol = data.currencySymbole
    ? data.currencySymbole
    : "currencySymbol";

  let headerTitleAccountSummery = intlPlaceholder("report.trustUser.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  const fetchBalanceSheet = () => {
    if (filterDate) {
      dispatch(
        DataGetAction(
          "balanceSheetForTrustUser",
          {
            startDate: filterDate.startDate,
            endDate: filterDate.endDate,
            employeeId: trustyId,
            currency: currencyId,
          },
          "fetchingBalanceSheetForTrustUser"
        )
      );
    } else {
      dispatch(
        DataGetAction(
          "balanceSheetForTrustUser",
          {
            startDate: moment().startOf("today").toString(),
            endDate: moment().endOf("year").toString(),
            employeeId: trustyId,
            currency: currencyId,
          },
          "fetchingBalanceSheetForTrustUser"
        )
      );
    }
    return () => {
      dispatch({
        type: "removeTheResult",
      });
    };
  };

  useEffect(fetchBalanceSheet, []);
  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "createdAt",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.name" />,
      key: "name",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.description" />,
      key: "remark",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="trust.balanceSheet.withdraw" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="trust.balanceSheet.deposit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];

  const redirectToPage = (referenceNo) => {
    history.push({ pathname: `/viewTrustDetail`, state: { _id: referenceNo } });
  };

  let startDate = moment().startOf("today").toString();
  let endDate = moment().toString();

  
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <div ref={componentRef}>
            <CCard className="mb-4">
              <CCardHeader className="d-flex justify-content-between align-items-center">
                <strong>
                  {`${headerTitleAccountSummery} ${currencySymbol} ${reportFrom} ${ChangeDateFormate(
                    startDate,
                    "DD-MM-YYYY"
                  )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`}
                </strong>
              </CCardHeader>
              <CCardBody>
                <div>
                  <DocsExample href="components/smart-table/">
                  <CSmartTable
                  loading={fetchingBalanceSheetForTrustUser}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  
                  items={
                    fetchingBalanceSheetForTrustUser ? [] : BalanceSheetList
                  }
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    description: (record) => {
                      return  <td>{ <IntlMessages id={record.description} />}</td>
                    },
                    createdAt: (record) => {
                      return <td>{record.createdAt}</td>;
                    },

                    refNo: (record) => {
                      if (record.refNo === "0000") {
                        return <td>{record.refNo}</td>;
                      } else {
                        return (
                          <td
                            onClick={() => redirectToPage(record._id)}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            {record.refNo}
                          </td>
                        );
                      }
                    },
                    debit: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.debit && record.debit.toFixed(2)
                          )}
                        </td>
                      );
                    },
                    credit: (record) => {
                      return (
                        <td className="text-end"> 
                          {ChangeNumberFormate(
                            record.credit && record.credit.toFixed(2)
                          )}
                        </td>
                      );
                    },
                    balance: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(
                            record.balance &&
                              parseFloat(record.balance).toFixed(2)
                          )}
                        </td>
                      );
                    },
                    remark: (record) => {
                      return  <td>{ record.remark ? record.remark : ""}</td>
                    },
                  }}
                  summary={(pageData) => {
                    let totalBorrow = 0;
                    let totalRepayment = 0;

                    pageData.forEach(({ debit, credit }) => {
                      totalBorrow += parseInt(debit);
                      totalRepayment += parseInt(credit);
                    });

                    return (
                      <tr>
                        <th colSpan={4} className="text-center">
                          {intlPlaceholder("Total")}
                        </th>
                        <th className="text-end">
                          <span>
                            {ChangeNumberFormate(totalBorrow.toFixed(2))}
                          </span>
                        </th>
                        <th className="text-end">
                          <span>
                            {ChangeNumberFormate(totalRepayment.toFixed(2))}
                          </span>
                        </th>
                        <th>
                          <span></span>
                        </th>
                      </tr>
                    );
                  }}
                />
                  </DocsExample>
                </div>
              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
