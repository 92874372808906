import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  unitLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  Units: [],
  SubUnits: [],
  fetchUnitInfo: false,
  selectedUnitName: "",
  enableSubUnitForm: true,
  checkUnitLoader: false,
  unitError: false,
  editSubUnits: null,
  unitSpinner: false,
  subunitList: [],
  subUnitSpin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getAllUnit_SUCCESS": {
      return { ...state, Units: action.payload.Record, fetchUnitInfo: false };
    }
    case "unitSpinner_START": {
      return { ...state, unitSpinner: true };
    }
    case "unitSpinner_STOP": {
      return { ...state, unitSpinner: false };
    }

    case "deleteSubUnit_SUCCESS": {
      const deletedRecord = getRecordAfterDeleted(
        state.SubUnits,
        action.payload
      );
      return { ...state, SubUnits: deletedRecord };
    }

    case "addSubUnit_SUCCESS": {
      const newArray =
        state.SubUnits.length > 0
          ? [action.payload, ...state.SubUnits]
          : [action.payload];
      return { ...state, SubUnits: newArray };
    }

    case "checkUnitSpinier_START": {
      return { ...state, checkUnitLoader: true };
    }
    case "checkUnitSpinier_STOP": {
      return { ...state, checkUnitLoader: false };
    }

    case "checkUnit_SUCCESS": {
      return {
        ...state,
        checkUnitLoader: false,
        unitError: false,
        selectedUnitName: action.payload.Record.unit,
        enableSubUnitForm: action.payload.Record.unit !== "" ? false : true,
      };
    }
    case "checkUnit_FAILURE": {
      return {
        ...state,
        unitError: true,
        message: action.payload,
        enableSubUnitForm: true,
      };
    }

    case "edit_unit_record": {
      return {
        ...state,
        editSubUnits: action.payload,
        SubUnits: action.payload.subUnits,
        selectedUnitName: action.payload.name,
        selectedItem: action.payload,
      };
    }
    case "reset_form_edit": {
      return {
        ...state,
        SubUnits: state.editSubUnits.subUnits,
        selectedItem: state.editSubUnits,
      };
    }

    case "unit_START": {
      return { ...state, unitLoader: true };
    }
    case "unit_STOP": {
      return { ...state, unitLoader: false };
    }
    case "getSubunit_SUCCESS": {
      return {
        ...state,
        subunitList: action.payload.Record,
        subUnitSpin: false,
      };
    }
    case "getSubunit_FAILURE": {
      return {
        ...state,
        subunitList: [],
        subUnitSpin: false,
      };
    }
    case "fetchSubUnitSpin_START": {
      return {
        ...state,
        subUnitSpin: true,
      };
    }
    case "addUnit_SUCCESS": {
      return {
        ...state,
        success: true,
        message: "recordAddedSuccessfully",
        Units: [action.payload.Record, ...state.Units],
        unitLoader: false,
      };
    }
    case "addUnit_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        unitLoader: false,
        message: action.payload,
      };
    }

    case "editUnit_SUCCESS": {
      let oldCat = state.Units;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        Units: newCat,
        unitLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editUnit_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        unitLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "unit_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        unitLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        SubUnits: [],
        fetchUnitInfo: false,
        selectedUnitName: "",
        enableSubUnitForm: true,
        checkUnitLoader: false,
        unitError: false,
      };
    }

    case "deleteUnit_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Units;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        Units: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    default:
      return state;
  }

  function getRecordAfterDeleted(oldRecord, deletedRecord) {
    const newRecord = [];
    oldRecord.map((Item) => {
      if (Item.subUnitName !== deletedRecord.subUnitName) {
        newRecord.push(Item);
      }
      return Item;
    });
    return newRecord;
  }
};
