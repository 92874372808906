import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Colors, colorPropType } from './Types'
const CTable = forwardRef(
    (
        {
            children,
            align,
            borderColor,
            bordered,
            borderless,
            caption,
            className,
            color,
            hover,
            responsive,
            small,
            striped,
            ...rest
        },
        ref,
    ) => {


        const _className = classNames(
            'table',
            {
                [`align-${align}`]: align,
                [`caption-${caption}`]: caption,
                [`border-${borderColor}`]: borderColor,
                'table-bordered': bordered,
                'table-borderless': borderless,
                [`table-${color}`]: color,
                'table-hover': hover,
                'table-sm': small,
                'table-striped': striped,
            },
            className,
        )

        return responsive ? (
            <div
                className={
                    typeof responsive === 'boolean' ? 'table-responsive' : `table-responsive-${responsive}`
                }
            >
                <table className={_className ? _className : undefined} {...rest} ref={ref}>
                    {children}
                </table>
            </div>
        ) : (
            <table className={_className ? _className : undefined} {...rest} ref={ref}>
                {children}
            </table>
        )
    }
)


CTable.propTypes = {
    align: PropTypes.oneOf(['bottom', 'middle', 'top']),
    borderColor: PropTypes.string,
    bordered: PropTypes.bool,
    borderless: PropTypes.bool,
    caption: PropTypes.oneOf(['top']),
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
    hover: PropTypes.bool,
    responsive: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf < 'sm' | 'md' | 'lg' | 'xl' | 'xxl' > (['sm', 'md', 'lg', 'xl', 'xxl']),
    ]),
    small: PropTypes.bool,
    striped: PropTypes.bool,
}

export default CTable
