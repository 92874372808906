import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import _ from "lodash";
import moment from "moment";
import { CButton } from "@coreui/react-pro";
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
import CheckUserAccess from '../../../util/checkUserAccess'
const Filter = ({ setFilter, downloadPDF }) => {
  const dispatch = useDispatch();
  const BSProps = useSelector((state) => state.balanceSheet);

  const categoryProps = useSelector((state) => state.categoryReducer);
  const supplierProps = useSelector((state) => state.supplier);
  const productProps = useSelector((state) => state.ProductR);
  const [SelectedType, setSelectedType] = useState();
  const { Banks, Currency } = BSProps;
  const { Categories } = categoryProps;
  const { Suppliers } = supplierProps;
  const { ProductList } = productProps;
  const  all  = intlPlaceholder("All")
  // form validation rules
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(<IntlMessages id="dateRequired" />),
    supplier: Yup.string().required(
      <IntlMessages id="SupplierProductFilter.supplier_error_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [moment().utc(), moment().utc()],
      supplier : 'all'
      // Default value here
    },
  };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {

    




    if (values.date) {
      values.startDate = values.date[0];
      values.endDate = values.date[1];
    } else {
      values.startDate =  moment().startOf("year").utc();
      values.endDate = moment().utc();
    }

    let findSupplier = [{ name: "all" }];
    if (values.supplier !== "all") {
      let findSupplier = Suppliers.filter(
        (Item) => Item._id === values.supplier
      );
    }

    let filterObj;
    if (values.date) {
      filterObj = {
        supplier: findSupplier[0].name,
        startDate: moment(values.startDate).utc(),
        endDate: moment(values.endDate).utc(),
      };
    } else {
      filterObj = {
        supplier: findSupplier[0].name,
        startDate: moment().utc(),
        endDate: moment().utc(),
      };
    }
    setFilter(filterObj);
    dispatch(DataGetAction("getAllDues", values, "supplierInvoiceSpinner"));
  };

  const fetchAllBankAndCurrencyList = () => {
    // dispatch(DataGetAction('getAllCategory', { query: "all" }));
    dispatch(DataGetAction("getAllSupplier", { query: "all" }, ""));
  };

  useEffect(fetchAllBankAndCurrencyList, []);

  useEffect(() => {
    if (Suppliers && Suppliers.length) {
      setValue("supplier", "all");

      let startDate = moment().utc();
      let endDate = moment().utc();

      setFilter({ supplier: "all", startDate, endDate });

      dispatch(
        DataGetAction(
          "getAllDues",
          { supplier: "all", startDate, endDate },
          "supplierInvoiceSpinner"
        )
      );
    }
  }, [Suppliers]);
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  const pdf = CheckUserAccess('supplierInvoices',["pdf"])
  return (
    <div className="p-3">
      <CForm className="d-flex flex-wrap" onSubmit={handleSubmit(onFinish)}>
        <div className="col-md-3 me-3">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
                initialSettings={{
                  startDate: moment(),
                  endDate: moment(),
                }}
                value={value}
                onApply={handleApply}
                onCallback={(start, end) => {
                  onChange([start, end]);
                }}
              >
                <input type="text" className="form-control col-4" />
              </DateRangePicker>
            )}
          />
          <div className="error-message ">{errors.date?.message}</div>
        </div>
        <div className="col-md-3 me-3">
          <div className="">
            <Controller
              control={control}
              name="supplier"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.supplier ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  defaultValue={{
                    value: 'all' , 
                    label: all,
                  }}
                  placeholder= {intlPlaceholder("SupplierProductFilter.selectSupplier")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                 
                  options={renderOptions([{name :all, _id : 'all'},...Suppliers], "name", "_id")}
                />
              )}
            />
         
            <div className="error-message ">{errors.supplier?.message}</div>
          </div>
        </div>

        <div className={`col-md-4`}>
          <div className="d-flex">
            <CLoadingButton
              className="d-flex align-items-center"
              type="submit"
              color="primary"
              id="filterInvoice"
            >
              <i className="gx-icon-filter-300 me-1 text-white"></i>
              <span className="text-white">
                <IntlMessages id="balanceSheet.generateButton" />
              </span>
            </CLoadingButton>

         { pdf &&   <CButton color="danger" className="ms-3" onClick={downloadPDF}>
              <span style={{ color: "white" }}>
                {/* <CIcon icon={cilFile} className="me-2" /> */}
                <i className="gx-icon-pdf-300 me-1"></i>
                <IntlMessages id="pdf" />
              </span>
            </CButton>}
          </div>
        </div>
      </CForm>
    </div>
  );
};

export default Filter;
