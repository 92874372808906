import React from "react";
import {
  CForm,
  CLoadingButton,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../../util/ShowToast";
import CheckboxGroup from "react-checkbox-group";
import permissionData from "../../_data";
import menusData from "../../../company/_data";
import { isArray } from "lodash-es";
import { useHistory } from "react-router-dom";
const UserRoleForm = ({ defaultValues, action }) => {
  const comProps = useSelector((state) => state.users);
  const { LogeInUser } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  // form validation rules
  const validationSchema = Yup.object().shape({
    role: Yup.string().required(
      <IntlMessages id="userRoles.roleNames_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    ...(defaultValues && {
      defaultValues: {
        role: defaultValues.name,
        ...defaultValues.permissionAccess,
      },
    }),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const getPermissionsList = (values) => {
    let permissionArray = [];
    for (const key in values) {
      if (values[key] && isArray(values[key]) && values[key].length > 0) {
        let filteredItem = menusData.filter((item) => item.value === key);
        let subMenu =
          filteredItem && filteredItem.length > 0
            ? filteredItem[0].subMenu
            : [];
        let arr = [key, ...subMenu];
        permissionArray.push(...arr);
      }
    }
    return [
      ...new Set(permissionArray),
      "resetPassword",
      "dashboard",
      "profile",
    ];
  };

  const onFinish = (values) => {
    const onlyPermissions = getPermissionsList(values);
    const { role, ...permissionAccess } = values;
    for (const key in permissionAccess) {
      if (
        Array.isArray(permissionAccess[key]) &&
        permissionAccess[key].length === 0
      ) {
        delete permissionAccess[key];
      }
    }
    let obj = {
      permissionAccess: permissionAccess,
      name: role,
      permission: onlyPermissions,
    };

    if (defaultValues === null) {
      dispatch(
        DataRequestAction(
          "POST",
          "addUserRole",
          obj,
          "",
          "addingUserRole",
          false
        )
      );
    } else {
      obj._id = defaultValues._id;
      dispatch(
        DataRequestAction(
          "PUT",
          "editUserRole",
          obj,
          "",
          "addingUserRole",
          false
        )
      );
    }
  };
  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.textMessage);
    notify("success", messageLng);
    history.push("/userRoles");
    dispatch({
      type: "user_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.textMessage);
    notify("error", messageLng);
    dispatch({
      type: "user_UPDATE",
    });
  }

  const renderMenus = (menus) => {
    return menus.map((item) => {
      if (
        LogeInUser.permissions &&
        LogeInUser.permissions.includes(item.permissionsKey)
      ) {
        return (
          <Controller
            control={control}
            name={item.name}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <div className="w=100 ps-2">
                    <span
                      style={{ minWidth: "220px" }}
                      className="d-inline-block"
                    >
                      <IntlMessages id={item.name} />
                    </span>
                    <CheckboxGroup
                      value={value}
                      onChange={(value) => {
                        if (value && value.length > 0) {
                          if (value.includes("fullAccess")) {
                            item.permissions.map((Item) => {
                              if (!value.includes(Item)) {
                                value.push(Item);
                              }
                            });
                            onChange(value);
                          } else if (!value.includes("view")) {
                            value.push("view");
                            onChange(value);
                          } else {
                            onChange(value);
                          }
                        } else {
                          onChange(value);
                        }
                      }}
                    >
                      {(Checkbox) => (
                        <>
                          {item.permissions.map((x) => {
                            return (
                              <label
                                style={{
                                  marginRight: "30px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Checkbox
                                  disabled={
                                    action === "onlyView" ? true : false
                                  }
                                  value={x}
                                />
                                <span className="text-capitalize ms-2">
                                  <IntlMessages id={`permission.${x}`} />
                                </span>
                              </label>
                            );
                          })}
                        </>
                      )}
                    </CheckboxGroup>
                  </div>
                </>
              );
            }}
          />
        );
      }
    });
  };

  const renderRolePermissions = () => {
    return permissionData.map((item) => {
      if (item && item.permissionsKey) {
        if (
          LogeInUser.permissions &&
          LogeInUser.permissions.includes(item.permissionsKey)
        ) {
          return (
            <div className="mb-4">
              <fieldset>
                <legend>
                  <label className="ps-2 pe-3">
                    <IntlMessages id={item.name} />
                  </label>
                </legend>
                {renderMenus(item.menus)}
              </fieldset>
            </div>
          );
        }
      } else {
        return (
          <div className="mb-4">
            <fieldset>
              <legend>
                <label className="ps-2 pe-3">
                  <IntlMessages id={item.name} />
                </label>
              </legend>
              {renderMenus(item.menus)}
            </fieldset>
          </div>
        );
      }
    });
  };

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <p style={{ color: "#8999AB" }}>
        <IntlMessages id={"createUserRoleAndAssign"} />
      </p>

      <div className="mb-3">
        <CFormFloating className="mb-3 light-border">
          <CFormInput
            className={errors.role ? "is-invalid " : ""}
            {...register("role")}
            placeholder={intlPlaceholder("userRoles.roleName")}
            type="text"
            id="role"
          />
          <CFormLabel htmlFor="name">
            {intlPlaceholder("userRoles.roleName")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.role?.message}</div>
        </CFormFloating>
      </div>

      <p style={{ color: "#8999AB" }}>
        <IntlMessages id={"assignPermissionToRole"} />
      </p>

      {renderRolePermissions()}

      <div className="mt-4 text-end d-flex justify-content-end">
        {action !== "onlyView" && (
          <CLoadingButton
            className="d-flex align-items-center"
            type="submit"
            color="primary"
            loading={comProps.addingUserRoles}
            disabled={comProps.addingUserRoles}
          >
            {defaultValues ? (
              <IntlMessages id={"updateRole"} />
            ) : (
              <IntlMessages id={"createRole"} />
            )}
          </CLoadingButton>
        )}
      </div>
    </CForm>
  );
};

export default UserRoleForm;
