
import React  from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react-pro";
import DocsExample from "../../../components/DocsExample";
import IntlMessages from "../../../util/IntlMessages";
import UserList from "./components/UserList";
import AddModal from "./components/AddModal";
import BulkImportUser from "./components/BulkImportUser";
import EditModal from "./components/EditModal";
import CheckUserAccess from "../../../util/checkUserAccess";
const Index = () => {


  const create = CheckUserAccess("trustUser", ["create"]) 



  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="trust.title" />
              </h1>
              
            </CCardHeader>
            <CCardBody>
            <div className="ps-3 d-flex" style={{gap : '10px'}}>
            {create && <AddModal />}
             <BulkImportUser />

            </div>
              <DocsExample href="components/smart-table/">
                <UserList/>
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditModal />
      </CRow>
    </>
  );
};

export default Index;

