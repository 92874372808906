import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCollapse,
  CLoadingButton,
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable";
import IntlMessages from "../../util/IntlMessages";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { useSelector } from "react-redux";
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const StockStatusGrid = ({ data, spinner, filterValue }) => {
  const [details, setDetails] = useState([]);
  const [startSpin, setStartSpin] = useState(false);
  const history = useHistory();
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;

  const columns = [
    {
      label: <IntlMessages id="report.stockStatus.productName" />,
      key: "productName",
      _style: { width: "40%" },
      columnSorter: false,
    },
    {
      label: <IntlMessages id="report.stockStatus.orders" />,
      key: "orders",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.sales" />,
      key: "sales",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.nonDelivered" />,
      key: "nonDelivered",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.stock" />,
      key: "stock",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.stockValue" />,
      key: "stockPrice",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.nonDeliveredStockValue" />,
      key: "nonDeliveredStockValue",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="report.stockStatus.amount" />,
      key: "total",
      columnSorter: false,
      sorter: false,
      _style: { width: "40%" },
    },
    {
      label: "",
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const viewDetail = (data) => {
    history.push({
      pathname: "/viewStockStatus",
      state: data,
    });
  };
  let headerTitleAccountSummery = intlPlaceholder("report.stockStatus.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");
  let bodyData = [
    [
      { text: intlPlaceholder("stockStatus.date"), style: "tableHeader" },
      {
        text: intlPlaceholder("stockStatus.description"),
        style: "tableHeader",
      },
      { text: intlPlaceholder("stockStatus.Ref"), style: "tableHeader" },
      { text: intlPlaceholder("stockStatus.out"), style: "tableHeader" },
      { text: intlPlaceholder("stockStatus.in"), style: "tableHeader" },
      { text: intlPlaceholder("stockStatus.stock"), style: "tableHeader" },
    ],
  ];
  const downloadPDF = (data) => {
    setStartSpin(true);
    const token = localStorage.getItem("token");
    const { _id } = data;
    let startDate = moment().toString();
    let endDate = moment().toString();
    if (filterValue) {
      startDate = filterValue.startDate;
      endDate = filterValue.endDate;
    }

    let dataObj = {
      startDate: startDate,
      endDate: endDate,
      productId: _id,
    };

    fetch(`${ROOT_URL}/stockStatusSheet/${JSON.stringify(dataObj)}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: token,
      }),
    }).then((response) => {
      response.json().then((response) => {
        let Record = response.Record.reverse();
        Record.forEach((Item, index) => {
          let createdAt = {
            text: ChangeDateFormate(Item.createdAt),
            style: "tableProduct",
          };
          let description = { text: Item.description, style: "tableProduct" };
          let prefix = { text: Item.prefix, style: "tablePrefix" };
          let outStock = Item.outStock
            ? parseFloat(Item.outStock).toFixed(3)
            : parseFloat(0).toFixed(3);
          let inStock = Item.inStock
            ? parseFloat(Item.inStock).toFixed(3)
            : parseFloat(0).toFixed(3);
          let stock = Item.stock
            ? parseFloat(Item.stock).toFixed(3)
            : parseFloat(0).toFixed(3);
          let objArr = [createdAt];
          objArr.push(description);
          objArr.push(prefix);
          objArr.push(outStock);
          objArr.push(inStock);
          objArr.push(stock);

          bodyData = [...bodyData, objArr];
        });

        bodyData = [...bodyData];

        const logoImage = localStorage.getItem("image")
          ? localStorage.getItem("image")
          : process.env.REACT_APP_LOGO_DUMMY;

        let headerTitle = `${headerTitleAccountSummery.toUpperCase()} ${
          Record && Record[0] && Record[0]["productName"]
        }  ${reportFrom} ${ChangeDateFormate(
          startDate,
          "DD-MM-YYYY"
        )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
        let footerAddress = localStorage.getItem("address");
        let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

        let docDefinition = {
          pageOrientation: "landscape",
          content: [
            {
              image: `data:image/jpeg;base64,${logoImage}`,
              fit: [250, 250],
              width: 150,
              height: 150,
            },
            {
              text: headerTitle,
              style: { fontSize: 18, bold: true, alignment: "center" },
              margin: [15, 10],
            },
            {
              style: "tableBody",
              headerRows: 1,

              table: {
                widths: ["*", 200, "*", "*", "*", "*"],
                body: bodyData,
              },
            },
          ],
          footer: {
            text: [
              {
                text: footerAddress,
                alignment: "center",
                fontSize: 10,
                width: "100%",
              },
              {
                text: `\n ${footerAddress2}`,
                alignment: "center",
                fontSize: 10,
                width: "100%",
              },
            ],
          },

          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              textAlign: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: "100%",
            },
            tableOpacityExample: {
              margin: [0, 5, 0, 15],
              fillColor: "blue",
              fillOpacity: 0.3,
            },
            tableHeader: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
            tableBody: {
              bold: false,
              fontSize: 10,
              alignment: "right",
            },
            tableProduct: {
              bold: false,
              fontSize: 10,
              alignment: "left",
            },
            tablePrefix: {
              bold: false,
              fontSize: 10,
              alignment: "center",
            },
          },
        };
        setStartSpin(false);
        pdfMake.createPdf(docDefinition).download("stockStatusSheet.pdf");
      });
    });
  };

  return (
    <>
      <CSmartTable
        loading={spinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={spinner ? [] : data}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          orders: (record) => {
            let ordersVal = record.orders ? record.orders : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(ordersVal).toFixed(3))}
                </span>
              </td>
            );
          },
          sales: (record) => {
            let salesVal = record.sales ? record.sales : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(salesVal).toFixed(3))}
                </span>
              </td>
            );
          },
          stock: (record) => {
            let stockVal = record.stock ? record.stock : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(stockVal).toFixed(3))}
                </span>
              </td>
            );
          },
          nonDelivered: (record) => {
            let nonDelivered = record.nonDelivered ? record.nonDelivered : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(nonDelivered).toFixed(3))}
                </span>
              </td>
            );
          },
          stockPrice: (record) => {
            let stockPriceVal = record.stockPrice ? record.stockPrice : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(stockPriceVal).toFixed(3))}
                </span>
              </td>
            );
          },
          nonDeliveredStockValue: (record) => {
            let nonDStockValue = record.nonDStockValue ? record.nonDStockValue : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(nonDStockValue).toFixed(2))}
                </span>
              </td>
            );
          },
          total: (record) => {
            let totalVal = record.total ? record.total : 0;
            return (
              <td className="text-end">
                <span>
                  {ChangeNumberFormate(parseFloat(totalVal).toFixed(2))}
                </span>
              </td>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? (
                    <IntlMessages id="hide" />
                  ) : (
                    <IntlMessages id="show" />
                  )}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  <CButton
                    color="warning"
                    className="me-3"
                    onClick={() => viewDetail(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-editor me-1"></span>
                      <IntlMessages id="viewDetails" />
                    </span>
                  </CButton>
                  <CLoadingButton
                    loading={startSpin}
                    color="danger"
                    onClick={() => downloadPDF(record)}
                  >
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf" />
                    </span>
                  </CLoadingButton>
                </CCardBody>
              </CCollapse>
            );
          },
        }}
        summary={(pageData) => {
          let totalPrice = 0;
          pageData.forEach(({ total }) => {
            totalPrice += total;
          });
          let nonDeliveredTotalValue = 0;
          pageData.forEach(({ nonDStockValue }) => {
            nonDeliveredTotalValue += nonDStockValue;
          });

          return (
            <tr>
              <th colSpan={2} className="text-center">
                <h5>
                  <IntlMessages id="report.stockStatus.total" />
                </h5>
              </th>
              <th colSpan={5} className="text-end">
                <span>
                  <h5>{ChangeNumberFormate(nonDeliveredTotalValue.toFixed(2))}</h5>
                </span>
              </th>
              <th  className="text-end">
                <span>
                  <h5>{ChangeNumberFormate(totalPrice.toFixed(2))}</h5>
                </span>
              </th>
            </tr>
          );
        }}
      />
    </>
  );
};

export default StockStatusGrid;
