import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCollapse,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { ChangeNumberFormate } from 'src/constants/CommonFn';
import { useDispatch } from "react-redux";
import DeleteModal from '../../../components/Modals/DeleteModal'
import CheckUserAccess from "../../../util/checkUserAccess";
import CSmartTable from "src/components/CSSmartTable"
const CurrencyTable = ({ Currencies ,currencySpinner}) => {


  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);



  let columns = [
    {
      label:<IntlMessages id="currency.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label:  <IntlMessages id="currency.symbol" />,
      key: "symbol",
      _style: { width: "40%" },
    },
    {
      label:<IntlMessages id="currency.factor" />,
      key: "factor",
      _style: { width: "40%" },
    },
    {
      label:<IntlMessages id="currency.createdBy" />,
      key: "createdByName",
      _style: { width: "40%" },
    },
   
  ];

  const found =CheckUserAccess('currency',['edit','delete']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  
  const editRecord = (data) => {
    dispatch({
      type: 'show_currency_modal_edit',
      payload: data,
    });
  };

  const edit = CheckUserAccess('currency',['edit'])
  const deleted = CheckUserAccess('currency',['delete'])
  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
       loading={currencySpinner}
        items={currencySpinner?[]:Currencies && Currencies.length > 0 ? Currencies : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          factor: (record) => {
            return <td><span>{ChangeNumberFormate(parseFloat(record.factor).toFixed(2))}</span></td>
        },

          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
         
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  
                  
                {edit &&  <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                    <span className="icon icon-edit me-2"></span>
                    <IntlMessages id="store.action.edit" />
                    </span>
                  </CButton>}

                  {record.type !== 'local' && deleted &&  <DeleteModal
                      contentLangueId="currency.deleteModal.message"
                      type="deleteCurrency"
                      selectedItem={record}
                      ButtonTitleId="store.action.delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />}
                  


                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
     
    </>
  );
};

export default CurrencyTable;
