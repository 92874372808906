import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CProgress,
    CRow,
} from '@coreui/react-pro'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import {
    cilCloudDownload,
} from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'src/redux/actions/http'
import { intlPlaceholder } from 'src/util/intlPlaceholder'
import Select from "react-select";
import { NumberWithCommas, selectStyle } from 'src/constants/CommonFn'


const SupplierInvoiceAndReceiptGraph = () => {

    const dispatch = useDispatch()
    const stateProps = useSelector(state => state.dashboard)
    const comProps = useSelector((state) => state.currencyReducer);
    const [SelectedValue, setSelectedValue] = useState({})
    const { supplierInvoiceAndReceiptData, supplierInvoiceAndReceiptLoader } = stateProps
    const { Currencies } = comProps;

    const fetchAllBankAndCurrencyList = () => {
        // dispatch(DataGetAction('getAllCategory', { query: "all" }));
        dispatch(DataGetAction("getAllCurrency", { query: "all" }, ""));
    };

    useEffect(fetchAllBankAndCurrencyList, []);


    const fetchGraphDetail = (currency) => {
        setSelectedValue(currency)
        dispatch(DataGetAction('supplierInvoiceAndReceipt',
            {
                'query': "all",
                startDate: moment(),
                currencyId: currency._id
            },
            'supplierInvoiceAndReceiptSpinner'))
    }

    const fetchClientReceiptAndInvoiceGraphData = () => {
        if (Currencies && Currencies.length > 0) {

            fetchGraphDetail(Currencies[0])
        }
    }

    useEffect(fetchClientReceiptAndInvoiceGraphData, [Currencies])



    const random = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const renderGraph = () => {
        if (!supplierInvoiceAndReceiptLoader && supplierInvoiceAndReceiptData) {

            const { labels, dataSetInvoice, dataSetReceipt } = supplierInvoiceAndReceiptData

            return (
                <CChartLine
                    style={{ height: '300px', marginTop: '10px' }}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                label: intlPlaceholder('SupplierSettlement'),
                                backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
                                borderColor: getStyle('--cui-info'),
                                pointHoverBackgroundColor: getStyle('--cui-info'),
                                borderWidth: 2,
                                data: dataSetReceipt,
                                fill: true,
                            },
                            {
                                label: intlPlaceholder('SupplierOrder'),
                                backgroundColor: 'transparent',
                                borderColor: getStyle('--cui-success'),
                                pointHoverBackgroundColor: getStyle('--cui-success'),
                                borderWidth: 2,
                                data: dataSetInvoice,
                            },

                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                align: 'end'
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    drawOnChartArea: true,
                                },
                            },
                            y: {
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 5,
                                    stepSize: Math.ceil(250 / 5),
                                    max: 250,
                                },
                            },
                        },
                        elements: {
                            line: {
                                tension: 0.4,
                            },
                            point: {
                                radius: 0,
                                hitRadius: 10,
                                hoverRadius: 4,
                                hoverBorderWidth: 3,
                            },
                        },
                    }}
                />
            )
        }

        if (supplierInvoiceAndReceiptLoader) {
            return <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        }
    }

    const renderOptions = (data, label, key) => {
        if (data && data.length > 0) {
            return data.map((data) => ({
                label: data[label],
                value: data[key],
            }));
        }
    };

    return (
        <CCard className="mb-4">
            <CCardBody>
                <CRow>
                    <CCol >
                        <h4 id="traffic" className="card-title mb-0">
                            {intlPlaceholder('supplierGraphTitle')}
                        </h4>
                        <div className="small text-medium-emphasis"> {intlPlaceholder('clientsGraphSubTitle')}</div>
                    </CCol>
                    <CCol sm={3}>
                        {Currencies && <div className="">
                            <Select
                                isSearchable={true}
                                styles={selectStyle}
                                value={{
                                    value: SelectedValue._id,
                                    label: SelectedValue.name

                                }}
                                placeholder={''}
                                onChange={(e) => {
                                    fetchGraphDetail({
                                        _id: e.value,
                                        name: e.label
                                    })
                                }}

                                options={renderOptions(Currencies, "name", "_id")}
                            />
                        </div>
                        }
                    </CCol>
   
                </CRow>
                {renderGraph()}
                <CRow>
                    <CCol style={{ textAlign:'center'}}>
                        {intlPlaceholder('TotalOrder')}: {supplierInvoiceAndReceiptData && NumberWithCommas(supplierInvoiceAndReceiptData.currentDayTotalInvoices) || 0} <br></br>
                        {intlPlaceholder('TotalSettlement')}: {supplierInvoiceAndReceiptData && NumberWithCommas(supplierInvoiceAndReceiptData.currentDayTotalReceipt) || 0}
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    )
}

export default SupplierInvoiceAndReceiptGraph