import React, { useState, useRef, useEffect } from "react";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import IntlMessages from "../../../util/IntlMessages";
import {intlPlaceholder} from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction } from "../../../redux/actions/http";
import {notify} from '../../../util/ShowToast'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
  CRow,
  CTooltip,
  CForm,
  CFormInput,
  CInvalidFeedback,
  CFormLabel,
  CFormTextarea,
  CFormFeedback,
  CToast,
  CToastBody,
  CToastClose,
  CToastHeader,
  CToaster,
  CLoadingButton,
} from "@coreui/react-pro";
const ResetPasswordModal = () => {
  const [validated, setValidated] = useState(false);
  const [toast, addToast] = useState(0);

  const dispatch = useDispatch();
  const toaster = useRef();
  const comProps = useSelector((state) => state.company);
  const {
    passModalVisible,
    selectedItem,
    resetPasswordSuccess,
    passwordLoader,
  } = comProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirm: Yup.string().required("Confirm Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const modalType = "show_company_modal";
  const showPasswordModal = "show_password_modal";

  useEffect(() => {
    reset();
  }, [passModalVisible]);

  const exampleToast = (
    <CToast>
      <CToastHeader close>
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#007aff"></rect>
        </svg>
        <strong className="me-auto">Success</strong>
      </CToastHeader>
      <CToastBody>Password reset successfully!</CToastBody>
    </CToast>
  );

  if (resetPasswordSuccess) {
    notify('success', intlPlaceholder('passwordResetSuccessfully'));
    dispatch({
      type: "reset_company_states",
    });
    dispatch({
      type: "hide_password_modal",
    });
    addToast(exampleToast);
    reset();
  }

  const onFinish = (values) => {


    values._id = selectedItem;
    dispatch(
      DataRequestAction(
        "POST",
        "resetUserPassword",
        values,
        "",
        "resettingPassword",
        false
      )
    );
  };

  return (
    <>
      <CModal visible={passModalVisible}>
        <CModalHeader
          onDismiss={() =>
            dispatch(DataEntryModalAction(showPasswordModal, null))
          }
        >
          <CModalTitle>
            <IntlMessages id="resetPassword" />
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)} validated={validated}>
          <CModalBody>
            <div className="mb-3">
              <CFormInput
                className={errors.password ? "is-invalid" : ""}
                {...register("password")}
                placeholder="New Password"
                type="password"
                id="password"
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </div>
            <div className="mb-3">
              <CFormInput
                className={errors.confirm ? "is-invalid" : ""}
                {...register("confirm")}
                placeholder="Confirm New Password"
                type="password"
                id="confirm"
              />
              <div className="invalid-feedback">{errors.confirm?.message}</div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() =>
                dispatch(DataEntryModalAction(showPasswordModal, null))
              }
            >
              Close
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={passwordLoader}
              disabled={passwordLoader}
            >
              Submit
            </CLoadingButton>
            {/* <CButton type="submit" color="primary" className="px-4">
                            Login
                        </CButton> */}
          </CModalFooter>
        </CForm>

        <CToaster ref={toaster} push={toast} placement="top-end" />
      </CModal>
    </>
  );
};

export default ResetPasswordModal;

