import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import QuickSaleDetail from './QuickSaleDetail'

import { intlPlaceholder } from "src/util/intlPlaceholder";

const SaleNotesViewDetail = () => {
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.quickSaleReducer);

  const { showViewDialog, data, storeSaleDetailSpinner, saleDetailData } = invProps;
  const title = intlPlaceholder("quickSale.modal.viewTitle");
  const closeForm = () => {
    dispatch({ type: "closeViewSale" });
  };

  const fetchInvoiceInfo = () => {
    if (showViewDialog) {
      dispatch(
        DataGetAction(
          "getQuickSaleDetail",
          { _id: data._id },
          "fetchingSaleInvoiceSpinner"
        )
      );
    }
  };

  useEffect(fetchInvoiceInfo, [showViewDialog]);

  return (
    <>
      <CModal size="lg" visible={showViewDialog}>
        <CModalHeader
          onDismiss={() => {
            closeForm();
          }}
        >
        </CModalHeader>
        <CForm>
          <CModalBody>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    {storeSaleDetailSpinner ? <CSpinner /> : <QuickSaleDetail />}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                closeForm();
              }}
            >
              {intlPlaceholder("companyModal.close")}
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export default SaleNotesViewDetail;
