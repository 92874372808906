import React from "react";
import {
  CButton,
  CFormSelect,
  CForm,
  CFormInput,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";


const SubUnitform = () => {
  const dispatch = useDispatch();

  const subUnitNameRequired = intlPlaceholder("unit.addUnitPage.subUnitName_message")
  const operationRequired = intlPlaceholder("unit.addUnitPage.pleaseSelectAOperation_message")
  const factorRequired = intlPlaceholder("unit.addUnitPage.factor_message")

  const validationSchema = Yup.object().shape({
    subUnitName: Yup.string().required(subUnitNameRequired),
    operation: Yup.string().required( operationRequired),
    factor: Yup.string().required( factorRequired),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
  const enableSubUnitForm = useSelector(
    (state) => state.unitReducer.enableSubUnitForm
  );

  const { errors } = formState;
  const onFinish = (data) => {
   
    
    dispatch({
      type: "addSubUnit_SUCCESS",
      payload: data,
    });
    reset();
  };
  return (
    <CForm>
      <div className="row mt-3 mb-3">
        <div className="col-md-3">
          <CFormFloating>
            <CFormInput
              className={errors.subUnitName ? "is-invalid" : ""}
              {...register("subUnitName")}
              placeholder={intlPlaceholder("unit.subUnitName")}
              type="text"
              id="subUnitName"
            />
            <div className="invalid-feedback">
              {errors.subUnitName?.message}
            </div>
            <CFormLabel htmlFor="subUnitName">{intlPlaceholder("unit.subUnitName")}</CFormLabel>
          </CFormFloating>
        </div>
        <div className="col-md-3">
          <CFormFloating>
            <CFormSelect
              className={errors.operation ? "is-invalid" : ""}
              {...register("operation")}
              type="text"
              id="operation"
            >
             
              <option value="divide">{intlPlaceholder("Divide")}</option>
              <option value="multiple">{intlPlaceholder("Multiple")}</option>
            </CFormSelect>
            <CFormLabel htmlFor="operation">
              {intlPlaceholder("unit.addUnitPage.pleaseSelectAOperation")}
            </CFormLabel>
            <div className="invalid-feedback">{errors.operation?.message}</div>
          </CFormFloating>
        </div>

        <div className="col-md-3">
        <CFormFloating>
          <CFormInput
            className={errors.factor ? "is-invalid" : ""}
            {...register("factor")}
            placeholder={intlPlaceholder("unit.addUnitPage.factor")}
            type="text"
            id="factor"
          />
          <CFormLabel htmlFor="factor">
          {intlPlaceholder("unit.addUnitPage.factor")}
        </CFormLabel>
          <div className="invalid-feedback">{errors.factor?.message}</div>
          </CFormFloating>
        </div>
        <div className="col-md-3 d-grid gap-2">
          <CLoadingButton
            className="btn btn-primary  btn-block"
            type="button"
            onClick={handleSubmit(onFinish)}
            color="primary"
            loading={false}
          >
            <IntlMessages id="unit.addUnitPage.addItem" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default SubUnitform;
