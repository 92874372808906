import React, { useState, useEffect } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage,
} from "src/constants/CommonFn";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { DataGetAction } from "../../../redux/actions/http";
import CheckUserAccess from "../../../util/checkUserAccess";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const StoreBalanceSummaryList = ({
  Data,
  Spinner,
  filterDate,
  setFilterDate,
  accountStatus,
}) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const blProps = useSelector((state) => state.balanceSheet);
  const { locale } = useSelector((state) => state.Setting);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  let headerTitleAccountSummery = intlPlaceholder("report.store.summary");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("Description"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("supp.debit"), style: "tableHeader" },
      { text: intlPlaceholder("supp.credit"), style: "tableHeader" },
      { text: intlPlaceholder("Balance"), style: "tableHeader" },
    ],
  ];

  let TotalText = intlPlaceholder("Total");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  let atLabel = intlPlaceholder("pdfDetail.atLabel");
  let qtyLabel = intlPlaceholder("pdfDetail.qty");
  let rebateLabel = intlPlaceholder("invoiceDetail.rebate");

  const { StoreSummery, StoreSummeryOriginal, storeBalanceSummarySpinner } =
    blProps;
  const history = useHistory();

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.names" />,
      key: "storeName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.montant" />,
      key: "balance",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.initial" />,
      key: "initialBalance",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.purchases" />,
      key: "purchases",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.totalSettlement" />,
      key: "settlement",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.cededStock" />,
      key: "cededStock",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.receivedStock" />,
      key: "receivedStock",
      _style: { width: "40%" },
      sorter: false,
    },
    {
      label: <IntlMessages id="balanceSheet.withdrawal" />,
      key: "withdrawal",
      _style: { width: "40%" },
      sorter: false,
    },

    {
      label: "",
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];

  const fetchBalanceSheet = () => {
    dispatch(
      DataGetAction(
        "StoreBalanceSummery",
        {
          startDate: filterDate.startDate,
          endDate: filterDate.endDate,
          initialEndDate: moment().toString(),
          noDateSelected: "selected",
          accountStatus: accountStatus,
        },
        "storeBalanceSummarySpinner"
      )
    );
  };

  useEffect(fetchBalanceSheet, []);

  const viewDetail = (data) => {
    history.push({
      pathname: "/storeBalanceSheet",
      state: { data, filterDate },
    });
    // history.push({pathname :"/storeBalanceSheet", query : { name:'Mr',age:23 }});
  };
  const onChangeName = (e) => {
    dispatch({
      type: "StoreBalanceSheetSummeryByName",
      payload: e,
    });
  };

  const downloadPDF = async (data) => {
    const token = localStorage.getItem("token");
    const { storeId, storeName } = data;
    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let param = {
      startDate: startDate,
      endDate: endDate,
      store: storeId,
    };

    param = JSON.stringify(param);
    fetch(`${ROOT_URL}/storeBalanceSheet/${param}`, { headers }).then(
      (response) => {
        response.json().then((response) => {
          let Record = response.Record.reverse();

          let Total = 0;
          let debitTotal = 0;
          let creditTotal = 0;
          console.log("====== Record ======", Record);
          Record.forEach((Item, index) => {
            let debit = parseFloat(Item.debit).toFixed(2);
            let credit = parseFloat(Item.credit).toFixed(2);
            let balance = parseFloat(Item.balance).toFixed(2);

            debitTotal = debitTotal + parseFloat(debit);
            creditTotal = creditTotal + parseFloat(credit);
            Total = Total + parseFloat(balance);

            debit = Item.debit !== 0 ? NumberWithCommas(debit) : "";
            credit = Item.credit !== 0 ? NumberWithCommas(credit) : "";
            balance = NumberWithCommas(balance);

            let date = ChangeDateFormate(Item.createdAt);

            let objArr = [date];
            //conversionInLanguage(locale.locale ,Item.description)
            let desContent = Item.remark
              ? Item.remark
              : conversionInLanguage(locale.locale, Item.description);
            if (Item.prefix === "FC") {
              let invoiceDetail = Item.invoiceDetail;
              if (invoiceDetail && invoiceDetail.length > 0) {
                desContent = "";
                let productInInvoice = invoiceDetail[0].latestProduct;
                productInInvoice.map((singleProduct) => {
                  let unitNameProduct = singleProduct.unitName;
                  if (singleProduct.retail) {
                    unitNameProduct = singleProduct.subunitName;
                  }
                  desContent =
                    desContent +
                    `${singleProduct.productName} ${atLabel} ${singleProduct.salePrice} ${qtyLabel}: ${singleProduct.quantity} / ${unitNameProduct} \n`;
                });

                desContent =
                  desContent + `${rebateLabel}: ${invoiceDetail[0].rebate}`;
              }
            }

            //  : `Description \n Product Name:Test1 \n Qte:2 \n totalAmount:4200 \n Rebate:200`;
            let desc = [
              {
                text: desContent,
                alignment: "left",
              },
            ];

            let ref = [
              {
                text: "",
                alignment: "center",
              },
            ];

            objArr.push(desc);
            if (
              (Item.description === "Store Employee Salary Posted" ||
                Item.description === "Store Expense") &&
              Item.prefix &&
              Item.showReference
            ) {
              ref[0].text = `${Item.prefix}-${Item.showReference}`;
              objArr.push(ref);
            } else if (Item.prefix && Item.reference) {
              ref[0].text = `${Item.prefix}-${Item.reference}`;
              objArr.push(ref);
            } else {
              ref[0].text = `----`;
              objArr.push(ref);
            }

            objArr.push(debit);
            objArr.push(credit);
            objArr.push(balance);

            bodyData = [...bodyData, objArr];
          });

          debitTotal = parseFloat(debitTotal).toFixed(2);
          creditTotal = parseFloat(creditTotal).toFixed(2);

          let letTotal = [
            {
              text: TotalText,
              style: "tableHeader",
              colSpan: 3,
              alignment: "center",
            },
            {},
            {},
            { text: NumberWithCommas(debitTotal), style: "tableBody" },
            { text: NumberWithCommas(creditTotal), style: "tableBody" },
            {},
          ];
          bodyData = [...bodyData, letTotal];

          let headerTitle = `${headerTitleAccountSummery.toUpperCase()} ${storeName.toUpperCase()} ${reportFrom.toUpperCase()} ${ChangeDateFormate(
            startDate,
            "DD-MM-YYYY"
          )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;

          let footerAddress = localStorage.getItem("address");

          let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - '${CellLabel}.: +${LogeInUser.phoneNumber}`;
          let docDefinition = {
            pageOrientation: "landscape",
            content: [
              {
                image: `data:image/jpeg;base64,${logoImage}`,
                fit: [250, 250],
                width: 150,
                height: 150,
              },

              {
                text: headerTitle,
                margin: [15, 10],
                style: { fontSize: 22, bold: true, alignment: "center" },
              },
              {
                style: "tableBody",
                headerRows: 1,

                table: {
                  widths: [80, "*", 70, 90, 90, 100],
                  body: bodyData,
                },
              },
            ],
            footer: {
              text: [
                {
                  text: footerAddress,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
                {
                  text: `\n ${footerAddress2}`,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
              ],
            },

            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                textAlign: "center",
              },
              tableExample: {
                margin: [0, 5, 0, 15],
                width: "100%",
              },
              tableOpacityExample: {
                margin: [0, 5, 0, 15],
                fillColor: "blue",
                fillOpacity: 0.3,
              },
              tableHeader: {
                bold: true,
                fontSize: 14,
                color: "black",
                alignment: "center",
              },
              tableBody: {
                bold: false,
                fontSize: 10,
                alignment: "right",
              },
            },
          };
          pdfMake.createPdf(docDefinition).download("storeSummeryReport.pdf");
        });
      }
    );
  };

  const pdfAccess = CheckUserAccess("storeAccounts", ["pdf"]);

  return (
    <>
      <CSmartTable
        loading={storeBalanceSummarySpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={storeBalanceSummarySpinner ? [] : StoreSummery}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          storeName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.storeName && record.storeName}
                </td>
              </>
            );
          },
          balance: (record) => {
            return (
              <>
                <td className="text-end">
                  {record.balance && record.balance > 0 ? (
                    <span style={{ color: "green" }}>
                      {ChangeNumberFormate(
                        parseFloat(record.balance).toFixed(2)
                      )}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {ChangeNumberFormate(
                        parseFloat(record.balance).toFixed(2)
                      )}
                    </span>
                  )}
                </td>
              </>
            );
          },
          initialBalance: (record) => {
            return (
              <>
                <td className="text-end">
                  {record.initialBalance && record.initialBalance > 0 ? (
                    <span style={{ color: "green" }}>
                      {ChangeNumberFormate(
                        parseFloat(record.initialBalance).toFixed(2)
                      )}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {ChangeNumberFormate(
                        parseFloat(record.initialBalance).toFixed(2)
                      ) || 0}
                    </span>
                  )}
                </td>
              </>
            );
          },
          purchases: (record) => {
            return (
              <>
                <td className="text-end">
                  <span>
                    {ChangeNumberFormate(
                      parseFloat(record.purchases).toFixed(2)
                    )}
                  </span>
                </td>
              </>
            );
          },
          settlement: (record) => {
            return (
              <>
                <td className="text-end">
                  <span>
                    {ChangeNumberFormate(
                      parseFloat(record.settlement).toFixed(2)
                    )}
                  </span>
                </td>
              </>
            );
          },
          cededStock: (record) => {
            return (
              <>
                <td className="text-end">
                  <span>
                    {ChangeNumberFormate(
                      parseFloat(record.cededStock).toFixed(2)
                    )}
                  </span>
                </td>
              </>
            );
          },
          receivedStock: (record) => {
            return (
              <>
                <td className="text-end">
                  <span>
                    {ChangeNumberFormate(
                      parseFloat(record.receivedStock).toFixed(2)
                    )}
                  </span>
                </td>
              </>
            );
          },
          withdrawal: (record) => {
            return (
              <>
                <td className="text-end">
                  <span>
                    {ChangeNumberFormate(
                      parseFloat(record.withdrawal).toFixed(2)
                    )}
                  </span>
                </td>
              </>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? (
                    <IntlMessages id="hide" />
                  ) : (
                    <IntlMessages id="show" />
                  )}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  <CButton
                    color="warning"
                    className="me-3"
                    onClick={() => viewDetail(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-editor"></span>
                      <IntlMessages id="view" />
                    </span>
                  </CButton>

                  {pdfAccess && (
                    <CButton color="danger" onClick={() => downloadPDF(record)}>
                      <span style={{ color: "white" }}>
                        <i className="gx-icon-pdf-300 me-1"></i>
                        <IntlMessages id="pdf" />
                      </span>
                    </CButton>
                  )}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
        summary={(pageData) => {
          let totalBalance = 0;
          let totalInitialBalance = 0;
          let totalPurchases = 0;
          let totalReceivedStock = 0;
          let totalSettlement = 0;
          let totalCededStock = 0;
          let totalWithdrawal = 0;
          pageData.forEach(
            ({
              balance,
              initialBalance,
              purchases,
              settlement,
              cededStock,
              receivedStock,
              withdrawal,
            }) => {
              totalBalance += parseFloat(balance);
              totalInitialBalance += parseFloat(initialBalance);
              totalPurchases += parseFloat(purchases);
              totalReceivedStock += parseFloat(receivedStock);
              totalSettlement += parseFloat(settlement);
              totalCededStock += parseFloat(cededStock);
              totalWithdrawal += parseFloat(withdrawal);
            }
          );

          return (
            <>
              <tr>
                <th colSpan={1} className="text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalBalance.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalInitialBalance.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalPurchases.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalSettlement.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalCededStock.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th colSpan={1} className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalReceivedStock.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th className="text-end">
                  <>
                    <h5>
                      {ChangeNumberFormate(totalWithdrawal.toFixed(2)) || 0}
                    </h5>
                  </>
                </th>
                <th></th>
              </tr>
            </>
          );
        }}
      />
    </>
  );
};

export default StoreBalanceSummaryList;
