import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import CategoryForm from "./CategoryForm";
const AddModal = () => {
  const dispatch = useDispatch();


  const comProps = useSelector((state) => state.categoryReducer);

  const { categoryLoader, selectedItem, modalVisible, success } = comProps;

  const modalType = "show_category_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };



  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
      <i className="icon icon-add me-1"></i>
      <IntlMessages id="category.add.category.button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            { intlPlaceholder('category.addCategoryModal.addCategory')}
          </CModalTitle>
        </CModalHeader>
       
          <CModalBody>
            <CategoryForm
              defaultValues={null}
              ButtonComponent={
                <CModalFooter>
                  <CButton
                    color="secondary"
                    onClick={() =>
                      dispatch(DataEntryModalAction(modalType, null))
                    }
                  >
                  <IntlMessages id="admin.addAdminModal.cancel" />
                  </CButton>

                  <CLoadingButton
                    type="submit"
                    color="primary"
                    loading={categoryLoader}
                    disabled={categoryLoader}
                  >
                    {intlPlaceholder('category.addCategoryModal.save')}
                  </CLoadingButton>
                </CModalFooter>
              }
            />


          </CModalBody>
       
      </CModal>
    </div>
  );
};

export default AddModal;
