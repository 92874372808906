import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DataGetAction, DataRequestAction } from "src/redux/actions/http";
import { notify } from '../../../util/ShowToast'
import SubProductList from "src/views/invoice/components/SubProductList";
import SubProductForm from "src/views/invoice/components/SubProductForm";
import Select from 'react-select';

const EditInvoiceModal = ({ modalType }) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.productReducer);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, SetSelectedRow] = useState([]);
  const [SelectedSupplier, setSelectedSupplier] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const { Suppliers, SubProducts, selectedReferenceNo, updatedProductList, editModalRecord } = comProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    referenceNo: Yup.string().required(
      <IntlMessages id="product.addProductPage.referenceNo_message" />
    ),
    supplier: Yup.string().required(
      <IntlMessages id="product.addProductPage.pleaseSelectSupplier_message" />
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };


  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue, getValues } = useForm(formOptions);
  const { errors } = formState;

  const addAtLeastOneProduct = intlPlaceholder('addAtLeastOneProduct');

  const closeForm = () => {
    dispatch(DataEntryModalAction(modalType, null));
    document.getElementById('filterInvoice').click();
  }
  
  const onUpdate = (values) => {

    const referenceNo = getValues("referenceNo");
    const supplier = getValues("supplier");

    if (referenceNo && supplier && SubProducts && SubProducts.length > 0) {
      setSelectedRowKeys([]);
      SetSelectedRow([]);
      const data = {
        _id: editModalRecord._id,
        referenceNo: referenceNo,
        supplier: supplier,
        products: SubProducts,
        invoiceId: editModalRecord.invoiceId
      };

      setDisableSaveBtn(true);

      // reset();
      dispatch(
        DataRequestAction("POST", "updateCompanyStock", data, "", "product", true)
      );
    }
    else {
      notify('error', addAtLeastOneProduct)
    }
  };

  if (comProps.success) {
    notify('success', intlPlaceholder('Stock.edit.success'));
    dispatch({
      type: "product_UPDATE",
    });

    closeForm();
    setDisableSaveBtn(false);
  }

  const renderSupplier = (data) => {
    if (data && data.length > 0) {
      return (data.map(data => ({ label: data.label, value: data.key })))
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '59px',
      height: '59px',
      boxShadow: state.isFocused ? `0 0 0 0.25rem rgba(50, 31, 219 , 25%)` : '',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '59px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '59px',
    }),
  };

  const changeTheInitialValue = () => {
    if (comProps.editInvoiceModal && comProps.editModalRecord) {
      setValue("referenceNo", editModalRecord.referenceNo);
      setValue("supplier", editModalRecord.supplierId);
      //setValue("products", editModalRecord.products); fill list productId
      setValue("currency", editModalRecord.currencyId);
    }
  };
  useEffect(changeTheInitialValue, [comProps.editInvoiceModal]);

  useEffect(() => {
    dispatch(DataGetAction("fetchProductDropDowns", ""));
    dispatch(DataGetAction("getAllProductStock", { query: "all" }));
  }, [])

  return (
    <div>
      <CModal size="xl" visible={comProps.editInvoiceModal}>
        <CModalHeader
          onDismiss={() => {closeForm();}}
        >
          <CModalTitle>{intlPlaceholder("editInvoiceModalTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit((d) => onUpdate(d))}>
          <CModalBody>
            <div className="row mb-4">
              {/* referenceNo */}
              <div className="col-md-6">
                <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.referenceNo ? "is-invalid" : ""}
                    {...register("referenceNo")}
                    placeholder={intlPlaceholder(
                      "product.addProductPage.referenceNo"
                    )}
                    type="text"
                    disabled={false}
                  />
                  <CFormLabel htmlFor="text">
                    {intlPlaceholder(
                      "product.addProductPage.referenceNo"
                    )}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.referenceNo?.message}
                  </div>
                </CFormFloating>
              </div>
              {/* supplier */}
              <div className="col-md-6">
                <div className="mb-3 ">
                  <Controller
                    control={control}
                    name="supplier"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        className={errors.supplier ? "is-invalid" : ""}
                        styles={customStyles}
                        isDisabled={true}
                        placeholder={intlPlaceholder("product.addProductPage.pleaseSelectSupplier")}
                        // value={SelectedSupplier || editModalRecord?.suppliers}
                        defaultValue={editModalRecord?.suppliers}
                        options={renderSupplier(Suppliers)}
                        isSearchable={true}
                        onChange={(e) => {
                          setSelectedSupplier(e.value);
                          onChange(e.value);
                        }}
                      />
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.supplier?.message}
                  </div>
                </div>
              </div>
            </div>

            <SubProductForm key="subProductFormInvoice" selectedSupplier={SelectedSupplier} />

            <div className="mt-4">
              <SubProductList key="subProductListInvoice"
                selectedRowKeys={selectedRowKeys}
                selectedRow={selectedRow}
                setSelectedRowKeys={setSelectedRowKeys}
                SetSelectedRow={SetSelectedRow}
                setDisableSaveBtn={setDisableSaveBtn}
              />
            </div>

          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {closeForm();}}
            >
              {intlPlaceholder("CancelButtonTitle")}
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.productLoader}
              disabled={disableSaveBtn}
            >
              {intlPlaceholder("SaveButtonTitle")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default EditInvoiceModal;
