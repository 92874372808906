
import saMessages from "../locales/fr_FR.json";

const saLang = {
  messages: {
    ...saMessages
  },
  locale: 'fr-FR'
};
export default saLang;
