import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CSpinner,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import ResetForm from "./components/ResetForm";

const Index = () => {
  const dispatch = useDispatch();

  const { loader } = useSelector((state) => state.profile);







  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <strong>
                <IntlMessages id="profile.companyProfile" />
              </strong>
            </CCardHeader>
            
              <CCardBody>
                <div className="row">
                  <div className="col-md-6">
                  <ResetForm />
                  </div>
                </div>
                 
              </CCardBody>
           
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
