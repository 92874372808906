import _ from 'lodash';


const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    userLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    Users: [],
    allUsersSpinner : false,
    editModalVisible : false,
    textMessage : '',
    addingUser : false,
    allUserRolesSpinner : false,
    UserRoles : [],
    addingUserRoles : false,
    userPermissionModalVisible : false,
    userRoleData : false,
    reset_user_password_modal : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        // this is manage users
        case 'gettingAllUsers_START':{
            return{
                ...state,
                allUsersSpinner : true
            }
        }

        case 'getAllUsers_SUCCESS': {
            return { ...state, Users: action.payload.Record, allUsersSpinner : false };
        }
        case 'getAllUsers_FAILURE': {
            return { ...state,  allUsersSpinner : false };
        }
        case 'show_user_modal':{
            return{
                ...state,
                modalVisible : !state.modalVisible,
            }
        }
        case 'edit_user_modal':{
            return{
                ...state,
                editModalVisible : !state.editModalVisible,
                selectedItem: action.payload ?  action.payload : null
            }
        }

        case 'addingUser_START':{
            return{
                ...state,
                addingUser : true
            }
        }
        case 'inviteUser_SUCCESS':{
            return{
                ...state,
                Users: [action.payload.Record, ...state.Users],
                addingUser : false,
                message : action.payload.message,
                success : true
            }
        }
        case 'inviteUser_FAILURE':{
            return{
                ...state,
                message : action.payload,
                addingUser : false,
                error : true
            }
        }
        case 'editUser_SUCCESS':{
            const updatedList = getListAfterUpdate(state.Users, action.payload.Record);
            return{
                ...state,
                Users: [...updatedList],
                addingUser : false,
                success : true,
                message : action.payload.message
            }
        }
        case 'editUser_FAILURE':{
            return{
                ...state,
                textMessage : action.payload,
                addingUser : false,
                error : true,
                message : action.payload
            }
        }
        case 'deleteUser_SUCCESS': {
            const deletedRecord = action.payload.Record._id;
            const oldCat = state.Users;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord);
            return { ...state, Users: [...newCat],message : action.payload.message, success : true };
        }
        case 'deleteUser_FAILURE': {
            return { ...state, message : action.payload , error : true };
        }
        case 'reset_user_password_modal':{
            return{
                ...state,
                resetUserPasswordModal : !state.reset_user_password_modal
            }
        }


        // this is user roles

        case 'gettingAllUserRoles_START':{
            return{
                ...state,
                allUserRolesSpinner : true
            }
        }
        case 'getAllRole_SUCCESS': {
            return { ...state, UserRoles: action.payload.Record, allUserRolesSpinner : false };
        }
        case 'getAllRole_FAILURE': {
            return { ...state,  allUserRolesSpinner : false };
        }
        case 'show_userRole_modal':{
            return{
                ...state,
                modalVisible : !state.modalVisible,
            }
        }
        case 'edit_userRole_modal':{
            return{
                ...state,
                editModalVisible : !state.editModalVisible,
                selectedItem: action.payload ?  action.payload : null
            }
        }
        case 'addingUserRole_START':{
            return{
                ...state,
                addingUserRoles : true
            }
        }
        case 'addUserRole_SUCCESS':{
            return{
                ...state,
                UserRoles : [action.payload.Record, ...state.UserRoles],
                addingUserRoles : false,
                success : true,
                textMessage : action.payload.message ?  action.payload.message  : 'user role added successfully!'
            }
        }
        case 'addUserRole_FAILURE':{
            return{
                ...state,
                addingUserRoles : false,
                error : true,
                textMessage : action.payload ?  action.payload  : 'some error occurred!'
            }
        }
        case 'editUserRole_SUCCESS':{
            const updatedList = getListAfterUpdate(state.UserRoles, action.payload.Record);
            return{
                ...state,
                UserRoles : [...updatedList],
                addingUserRoles : false,
                success : true,
                textMessage : action.payload.message ?  action.payload.message  : 'user role updated successfully!'
            }
        }
        case 'editUserRole_FAILURE':{
            return{
                ...state,
                addingUserRoles : false,
                error : true,
                textMessage : action.payload ?  action.payload  : 'some error occurred!'
            }
        }
        case 'User_Permission_Modal':{
            return {
                ...state,
                userPermissionModalVisible : !state.userPermissionModalVisible,
                userRoleData : action.payload ?  action.payload  : null
            }
        }
        case 'getRoleDropdown_SUCCESS':{
            return{
                ...state,
                getRoleDropdown : action.payload.Record
            }
        }
        case 'deleteRole_SUCCESS':{
            const deletedRecord = action.payload.Record;
            const oldCat = state.UserRoles;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, UserRoles: newCat, selectedItem: null, success:true, message: action.payload.message };
        }
        case 'deleteRole_FAILURE':{
            return{
                ...state,
                error : true,
                message : action.payload
            }
        }


        case 'newUserActivatedInfo_SUCCESS': {
            const updateUserList = getUserList(action.payload, state.Users);
            return { ...state, Users: updateUserList }
        }

        case 'show_user_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'show_password_modal': {
            return { ...state, passModalVisible: !state.passModalVisible, selectedItem: action.payload };
        }

        case 'user_START': {
            return { ...state, userLoader: true };
        }
        case 'user_STOP': {
            return { ...state, userLoader: false };
        }



        case 'inviteUser_SUCCESS': {
            return { ...state, success: true, Users: [action.payload.user, ...state.Users], userLoader: false };
        }
        case 'inviteUser_FAILURE': {
            return { ...state, error: true, success: false, userLoader: false, message: action.payload };
        }

        case 'resendInvitation_SUCCESS': {
            return { ...state, resendSuccess: true, message: action.payload };
        }
        case 'resendInvitation_FAILURE': {
            return { ...state, resendError: true, resendSuccess: false, message: action.payload };
        }

        // case 'resetUserPassword_SUCCESS': {
        //     return { ...state, resetPasswordSuccess: true, userLoader: false };
        // }
        // case 'resetUserPassword_FAILURE': {
        //     return { ...state, error: true, success: false, resetPasswordSuccess: false, userLoader: false, message: action.payload };
        // }

        case 'editUser_SUCCESS': {
            let oldCat = state.Users;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Users: newCat, userLoader: false };
        }
        case 'editUser_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, userLoader: false, message: action.payload };
        }
        case 'user_UPDATE': {
            return { ...state, recordEditSuccess: false, recordEditError: false, modalVisible: false, resendError: false, resendSuccess: false, userLoader: false, resetPasswordSuccess: false, error: false, success: false, message: "",editModalVisible : false };
        }
        case 'releaseNumberFromUser_SUCCESS': {
            const oldUserRecord = state.Users;
            let newUserRecord = [];
            oldUserRecord.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    newUserRecord.push(action.payload.Record);
                } else {
                    newUserRecord.push(Item);
                }
                return Item
            })

            return { ...state, Users: newUserRecord };
        }

    

        default:
            return state;
    }


    function getUserList(latest, record) {
        const newArray = [];
        record.map(Item => {
            if (Item._id === latest._id) {
                newArray.push(latest);
            }else{
                newArray.push(Item);
            }
            return Item
        })

        return newArray;
    }
    function getListAfterUpdate(oldData, newData) {
        let newArray = [];
        oldData.map(Item => {
            if (Item._id === newData._id) {
                newArray.push(newData)
            } else {
                newArray.push(Item);
            }
            return Item
        })
        return newArray;
    }
}
