import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import EditSaleInvoiceList from "./EditSaleInvoiceList";
import {
  CForm,
  CButton,
  CLoadingButton,
  CSpinner,
  CFormFloating,
  CFormInput,
  CFormLabel,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
const EditSaleInvoiceForm = ({
  _id,
  localProducts,
  initialValues,
  editSpinner,
}) => {
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [productIds, selectProductIds] = useState([]);
  const quickProps = useSelector((state) => state.quickSaleReducer);

  const { addSpinner, editSuccess, UpdateSpinner } = quickProps;
  const history = useHistory();
  const location = useLocation();
  const productProps = useSelector((state) => state.ProductR);
  const { ProductList, originalLocalProduct } = productProps;

  const saleProps = useSelector((state) => state.saleInvoiceReducer);
  const {
    editSaleInvoiceSuccess,
    editSaleInvoiceSpinner,
    editSaleInvoiceError,
  } = saleProps;
  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    products: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: "onChange",
  };
  const {
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
    register,
    resetField,
  } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = () => {
    let updatedProducts = null;
    if (localProducts.length > 0) {
      updatedProducts = localProducts.map((item) => {
        return {
          Price: item.localPrice,
          Qty: item.Qty,
          product: item._id,
          productName: item.name,
          delivered: item.delivered,
          unit: item.unit,
          modify: item.modify,
          unitName: item.unitName,
          retail: item.retail,
          subunitName: item.subunitName,
          subunit: item.subunit,
          packaging: item.packaging,
        };
      });
      const data = {
        invoiceSaleId: initialValues && initialValues._id,
        products: updatedProducts,
      };

      dispatch(
        DataRequestAction(
          "PUT",
          "editSaleInvoice",
          data,
          "",
          "editSaleInvoiceSpin",
          false
        )
      );
      reset();
    } else {
      notify("error", intlPlaceholder("noProductSelected"));
    }
  };

  if (editSaleInvoiceSuccess) {
    let messageText = intlPlaceholder("saleInvoice.edit.successfully");
    notify("success", messageText);

    dispatch(
      DataGetAction(
        "getSaleList",
        {
          startDate: moment().toString(),
          endDate: moment().toString(),
        },
        "allSaleInvoicesSpinner"
      )
    );

    dispatch({
      type: "resetEditModalValues",
    });
  }
  if (editSaleInvoiceError) {
    let messageText = intlPlaceholder("saleInvoice.edit.error");
    notify("error", messageText);
    dispatch({
      type: "resetEditModalValues",
    });
  }

  const fetchList = () => {
    dispatch(
      DataGetAction("getAllProductStock", {
        query: "qty",
        invoiceType: "saleInvoice",
        stockQty: "search",
        editId: _id,
      })
    );
    dispatch(
      DataGetAction(
        "getEditSaleInvoice",
        {
          query: "all",
          _id: _id,
        },
        "EditRecordSpin"
      )
    );
    // if (localProducts && localProducts.length > 0) {
    //   let selectedId = [];
    //   localProducts.map((x) => {
    //     selectedId.push(x.productId);
    //   });
    //   selectProductIds(selectedId)
    //   setValue("product", selectedId);
    // }
  };

  useEffect(fetchList, []);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setLocalProductSaleInvoice",
      payload: { values, ProductList },
    });
  };

  if (quickProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
  }
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  const setInitialValue = () => {
    if (
      initialValues &&
      initialValues.products &&
      initialValues.products.length > 0
    ) {
      setValue("products", initialValues.products);
    }

    if (initialValues && initialValues.remark) {
      setValue("remark", initialValues.remark);
      reset(initialValues);
    }
  };

  useEffect(setInitialValue, [initialValues]);

  const renderData = () => {
    if (editSpinner) {
      return <CSpinner />;
    } else {
      return (
        <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="mb-3">
                <Controller
                  control={control}
                  name="products"
                  key={1}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        styles={selectStyle}
                        defaultValue={renderOptions(
                          localProducts,
                          "name",
                          "_id"
                        )}
                        className={errors.store ? "is-invalid" : ""}
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        disabled={
                          localProducts && localProducts.length > 0
                            ? true
                            : false
                        }
                        placeholder={intlPlaceholder(
                          "addSaleInvoice.choseProduct"
                        )}
                        onChange={(values) => {
                          let selectedIds = values.map((item) => item.value);
                          selectProductIds(selectedIds);

                          onChange(selectedIds);
                        }}
                        options={renderOptions(ProductList, "name", "_id")}
                      />
                    );
                  }}
                />

                <div className="error-message">{errors.product?.message}</div>
              </div>
            </div>
            <div className="col-md-12">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.remark ? "is-invalid" : ""}
                  {...register("remark")}
                  placeholder={intlPlaceholder("Remark")}
                  type="text"
                />
                <CFormLabel htmlFor="text">
                  {intlPlaceholder("Remark")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.remark?.message}</div>
              </CFormFloating>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4">
              <CButton className="d-block w-100" type="submit">
                <i className="icon icon-add me-1"></i>
                <IntlMessages id="distributeProducts.add" />
              </CButton>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <EditSaleInvoiceList
                setValue={setValue}
                reset={setValue}
                quantityTitle={"stockQuantityAllow"}
                setDisableSaveBtn={setDisableSaveBtn}
                formType="edit"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <CButton
                className="d-block w-100 btn btn-light btn-block"
                color="dark"
                variant="outline"
                onClick={() => {
                  dispatch({
                    type: "editSaleInvoiceModalHide",
                  });
                }}
              >
                <IntlMessages id="distributeProducts.cancel" />
              </CButton>
            </div>
            <div className="col-md-4">
              <CLoadingButton
                disabled={disableSaveBtn || editSaleInvoiceSpinner}
                className="d-block w-100"
                onClick={() => onFinish()}
                color="primary"
                loading={editSaleInvoiceSpinner}
              >
                <span className="text-white">
                  <IntlMessages id="quickSale.modal.Update" />
                </span>
              </CLoadingButton>
            </div>
          </div>
        </CForm>
      );
    }
  };

  return renderData();
};

export default EditSaleInvoiceForm;
