
import _ from 'lodash'

const INIT_STATE = {
    ResourceStore: [],
    DestinationStore: [],
    Products: [],
    selectedProduct: null,
    units: [],
    Price: 0,
    oldUnit: null,
    Qty: 0,
    message: '',
    ProductsLocal: [],
    Invoices: [],
    invoiceLoader: false,
    InvoiceDetail: null,
    invoiceCreatedSuccess: false,
    invoiceNumber: 0,
    Receipts: [],
    ReceiptDetail: null,
    receiptLoader: false,
    selectedUnit: null,
    getStoretostore: false,
    localProducts: [],
    fetchProductsStoreStockSpinner : false,
    invoiceCreatedError : false,
    loadingSpinner: false,
    debitNotesDetail:[],
    creditNotesDetail:[]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'deleteProductLocalStoreToStore_SUCCESS': {
            const deletedRecord = getRecordAfterDeleted(state.ProductsLocal, action.payload);
            return { ...state, ProductsLocal: deletedRecord, Qty: 0, Price: 0, deleteRecordSuccess: true }
        }
        case 'getStoreToStoreReceiptDetail_SUCCESS': {
            return { ...state, ReceiptDetail: action.payload.Record[0] }
        }
        case 'getAllStoreToStoreReceipt_SUCCESS': {
            return { ...state, Receipts: action.payload.Record }
        }
        case 'fetchingInvoice_START': {
            return { ...state, invoiceLoader: true }
        }
        case 'fetchingInvoice_STOP': {
            return { ...state, invoiceLoader: false }
        }
        case 'getStoreInVoiceDetail_SUCCESS': {
            return { ...state, invoiceLoader: false, InvoiceDetail: action.payload.Record[0] }
        }
        case 'fetchingStoreToStoreSale_START': {
            return { ...state, getStoretostore: true }
        }
        case 'fetchingStoreToStoreSale_STOP': {
            return { ...state, getStoretostore: false }
        }
        case 'storeToStoreSale_SUCCESS': {
            return { ...state, getStoretostore: false, invoiceCreatedSuccess: true, invoiceNumber: action.payload.Record.invoiceNumber }
        }
        case 'storeToStoreSale_FAILURE': {
            return { ...state, getStoretostore: false, invoiceCreatedError: true}
        }
        case 'getAllStoreToStoreInvoices_SUCCESS': {
            return { ...state, Invoices: action.payload.Record }
        }
        case 'AddInLocalProduct': {
            const result = addDataInLocalProduct(action.payload, state.ProductsLocal, state.units, state.Qty);
            return { ...state, ProductsLocal: result.newArray, Qty: 0, Price: 0 }
        }
        case 'setStoreToStoreProducts': {
            const result = getLocalProducts(action.payload, state.localProducts, state.Products,);
            return {
                ...state,
                localProducts: [...result]
            }
        }
        case 'updateStoreToStoreProduct': {
            const result = getUpdatedLocalProducts(
                action.payload,
                state.localProducts
            );
            return {
                ...state,
                localProducts: [...result],
            };
        }
        case 'deleteLocalProductStoreToStore':{
            const  updatedProducts = deleteLocalProduct(
                state.localProducts,
                action.payload
              );
        
              return {
                ...state,
                localProducts: [...updatedProducts],
               
              };
        }
        case 'STORE_TO_STORE_PRODUCTS':{
            return{
                ...state,
                localProducts: [],
            }
        }

        case 'ChangeThePriceAndQty': {
            const QtyPriceObj = ChangeThePriceAndQty(action.payload, state.selectedProduct, state.units, state.oldUnit, state.ProductsLocal)
            return { ...state, Price: QtyPriceObj.Price, Qty: QtyPriceObj.Qty, selectedUnit: action.payload }
        }
        case 'setUnits': {
            const unitObj = getUnitListByProductId(action.payload, state.Products, state.ProductsLocal);
            return { ...state, units: unitObj.Unit, selectedProduct: unitObj.selectedProduct, selectedUnit: unitObj.selectedUnit, oldUnit: unitObj.selectedUnit }
        }

        case 'getAllStore_SUCCESS': {
            return { ...state, ResourceStore: action.payload.Record }
        }
        case 'fetchDestinationStore': {
            const dsStore = getDestinationStore(state.ResourceStore, action.payload)
            return { ...state, DestinationStore: dsStore }
        }
        case 'fetchProductsStoreStockSpinner_START':{
            return {
                ...state,
                fetchProductsStoreStockSpinner : true
            }
        }
        case 'fetchProductsStoreStockSpinner_STOP':{
            return {
                ...state,
                fetchProductsStoreStockSpinner : false
            }
        }

        case 'loadingSpinner_START':{
            return {
                ...state,
                loadingSpinner : true
            }
        }
        case 'loadingSpinner_STOP':{
            return {
                ...state,
                loadingSpinner : false
            }
        }

        case 'fetchProductsStoreStock_SUCCESS': {
            return { ...state, Products: action.payload.Record, Qty: 0, Price: 0, oldUnit: null }
        }

        case 'update_StoreToStoreState': {
            return {
                ...state,
                ResourceStore: [],
                DestinationStore: [],
                Products: [],
                selectedProduct: null,
                units: [],
                Price: 0,
                oldUnit: null,
                Qty: 0,
                message: '',
                ProductsLocal: [],
                Invoices: [],
                invoiceLoader: false,
                invoiceCreatedSuccess: false,
                invoiceCreatedError : false

            }
        }

        case 'resetAll': {
            return {
                ...state,
                ResourceStore: [],
                DestinationStore: [],
                Products: [],
                selectedProduct: null,
                units: [],
                Price: 0,
                oldUnit: null,
                Qty: 0,
                message: '',
                ProductsLocal: [],
                Invoices: [],
                invoiceLoader: false,
                InvoiceDetail: null,
                invoiceCreatedSuccess: false,
                invoiceNumber: 0,
                Receipts: [],
                ReceiptDetail: null,
                receiptLoader: false
            }
        }

        case 'editProductStoreInvoice_Modal': {
            return {
                ...state,
                localProducts: []
            }
        }
        case 'setResourceStore': {
            return { ...state, ResourceStore: action.payload }
        }
        case 'setLocalProducts': {
            return { ...state, localProducts: action.payload }
        }
        case 'getDebitNotesDetail_SUCCESS': {
            action.payload.Record[0].products.forEach((node) => { node.qty = node.quantity});
            return { ...state, debitNotesDetail: action.payload.Record[0], localProducts:  action.payload.Record[0].products}
        }
        case 'getCreditNotesDetail_SUCCESS': {
            action.payload.Record[0].products.forEach((node) => { node.qty = node.quantity});
            return { ...state, creditNotesDetail: action.payload.Record[0], localProducts:  action.payload.Record[0].products}
        }

        default: {
            return { ...state }
        }
    }






    function getRecordAfterDeleted(oldRecord, deletedRecord) {
        const newRecord = [];
        oldRecord.map(Item => {
            if (Item.product !== deletedRecord.product) {
                newRecord.push(Item);
            }
            return Item;
        })
        return newRecord;
    }



    function addDataInLocalProduct(newItem, oldItemArray, units, QtyInStock) {
        let newArray = [];
        const index = _.findIndex(oldItemArray, x => x.productName === newItem.productName);
        QtyInStock = parseFloat(QtyInStock) - parseFloat(newItem.Qty)

        if (index > -1) {
            let product = null;
            const selectedProduct = oldItemArray[index];

            if (selectedProduct.unit === newItem.unit) {
                product = selectedProduct;

                product.Qty = parseFloat(product.Qty) + parseFloat(newItem.Qty);
                product.price = newItem.Price;
                oldItemArray[index] = product;
                newArray = [...oldItemArray];
            } else {
                const oldUnitProduct = selectedProduct.unit;

                let newUnitObj = _.filter(units[0].subUnits, x => x._id === newItem.unit);
                product = selectedProduct;

                if (oldUnitProduct !== units[0]._id) {
                    let oldUnitObj = _.filter(units[0].subUnits, x => x._id === oldUnitProduct);
                    if (newUnitObj.length > 0) {
                        product.Qty = ((parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor)) * parseFloat(newUnitObj[0].factor)) + parseFloat(newItem.Qty);
                    } else {
                        product.Qty = (parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor)) + parseFloat(newItem.Qty);
                    }
                } else {
                    product.Qty = (parseFloat(product.Qty) * parseFloat(newUnitObj[0].factor)) + parseFloat(newItem.Qty);
                }


                product.unit = newItem.unit;
                product.unitName = newItem.unitName
                product.Price = newItem.Price;
                oldItemArray[index] = product;
                newArray = [...oldItemArray];
            }

        } else {
            newArray = [newItem, ...oldItemArray];
        }


        return { newArray, QtyInStock };
    }


    function ChangeThePriceAndQty(selectedUnit, selectedProduct, units, oldUnit, LocalProduct) {
        let newQty = selectedProduct.quantity;
        let newPrice = selectedProduct.localPrice;

        const subUnits = units[0].subUnits;

        if (selectedUnit !== units[0]._id) {
            let selectedUnitArr = _.filter(subUnits, x => x._id === selectedUnit);

            if (oldUnit !== null && oldUnit !== units[0]._id) {
                let oldUnitObj = _.filter(subUnits, x => x._id === oldUnit);
                let selectedPUnitFactor = oldUnitObj[0].factor;
                if (selectedUnitArr) {
                    newQty = (newQty / parseFloat(selectedPUnitFactor)) * parseFloat(selectedUnitArr[0].factor);
                    newPrice = (newPrice * parseFloat(selectedPUnitFactor)) / parseFloat(selectedUnitArr[0].factor);
                }
            } else {
                //======= Old unit is parent unit
                if (selectedUnitArr) {
                    newQty = newQty * parseFloat(selectedUnitArr[0].factor);
                    newPrice = newPrice / parseFloat(selectedUnitArr[0].factor);
                }
            }

        } else {

            if (oldUnit !== null) {
                let oldUnitObj = _.filter(subUnits, x => x._id === oldUnit);
                if (oldUnitObj.length > 0) {
                    let selectedPUnitFactor = oldUnitObj[0].factor;
                    newQty = newQty / parseFloat(selectedPUnitFactor);
                    newPrice = newPrice * parseFloat(selectedPUnitFactor);
                }
            }

        }

        return { Qty: newQty, Price: newPrice, SelectedUnit: selectedUnit }
    }


    function getUnitListByProductId(productId, productData, localProduct) {

        let Units = [];

        const selectedProduct = _.filter(productData, x => x._id === productId);
        const localProductArr = _.filter(localProduct, x => x.products === productId);
        Units = selectedProduct[0].units;

        let Qty = 0;
        let Price = selectedProduct[0].localPrice;


        if (localProductArr.length > 0) {
            const oldUnit = localProductArr[0].unit;
            const checkParentIndex = _.findIndex(Units, x => x._id === oldUnit);
            if (checkParentIndex > -1) {
                Qty = parseFloat(selectedProduct[0].quantity) - parseFloat(localProductArr[0].Qty)
            } else {
                let fIndex = null;
                _.map(Units, (x, index) => {
                    const subUnitIndex = _.findIndex(x.subUnits, y => y._id === oldUnit);
                    if (subUnitIndex > -1) {
                        fIndex = { pIndex: index, cIndex: subUnitIndex }
                    }
                });
                const oldUnitObj = Units[fIndex.pIndex].subUnits[fIndex.cIndex];
                Qty = (parseFloat(selectedProduct[0].quantity) * parseFloat(oldUnitObj.factor)) - parseFloat(localProductArr[0].Qty)
                Price = (parseFloat(selectedProduct[0].localPrice) / parseFloat(oldUnitObj.factor))
            }


        } else {
            Qty = selectedProduct[0].quantity
        }


        let selectUnit = selectedProduct[0].unitId
        if (localProductArr.length > 0) {
            selectUnit = localProductArr[0].unit
        }

        return { Unit: Units, Qty: Qty, Price: Price, selectedUnit: selectUnit, selectedProduct: selectedProduct[0] };

    }

    // function getUnitsForSelectedProduct(products, selectedProductId) {
    //     const selectedProduct = _.filter(products, x => x._id === selectedProductId);
    //     const Unit = selectedProduct[0].units
    //     return { Unit, selectedProduct: selectedProduct[0] }

    // }


    function getDestinationStore(stores, removeStoreId) {
        const dsStore = _.filter(stores, x => x._id !== removeStoreId);
        return dsStore
    }
}
// localPrice: 600
// price: 600
// productId: "61cc6f27e12684995bcea1dd"
// productName: "Tube Carré 20*1,2 mm"
// qty: 1
// quantity: -2
// stockId: "61d178c2a040153fe518d044"
// unitId: "61cc5209641b6bc937a26c82"
// unitName: "Unite"
// units: [,…]
// _id: "61d1cbfea04015f7b018d825"
const findUniqueArray = (arr1, arr2) => {
    let result = [];
    let indexFound = []
    arr1.map((item, index) => {
        let d = arr2.findIndex(items => items === item);
        if (d >= 0) {
            indexFound.push(d)
        }
    })
    for (let i = 0; i !== arr2.length; i++) {
        let d = indexFound.filter(items => items === i);
        if (d.length < 1) {
            result.push(arr2[i])
        }
        else {

            console.log("eeee", indexFound[i])
        }
    }
    return result
}

const getLocalProducts = (payload, localProducts, apiProducts) => {
    let { products: productIds } = payload;
    let prevSelectedIds = localProducts && localProducts.length > 0 ? localProducts.map(item => item._id) : []


    let originalProducts = JSON.parse(JSON.stringify(apiProducts));

    let uniqueIds = findUniqueArray(prevSelectedIds, productIds);

    let uniqueResult = originalProducts.filter((item) => {
        if (uniqueIds.includes(item._id)) {
            item.totalPrice = parseFloat(item.qty) * parseFloat(item.localPrice)
            return item
        }
    });


    let updatedArray = [];
    if (localProducts && localProducts.length > 0) {
        updatedArray = localProducts.filter((item) => {
            if (productIds.includes(item._id)) {
                item.qty = parseFloat(item.qty) + 1
                item.totalPrice = parseFloat(item.qty) * parseFloat(item.localPrice)
                return item
            }
            else {
                return item
            }
        });
    }
    return [...updatedArray, ...uniqueResult];
};


const getUpdatedLocalProducts = (payload, localProducts) => {
    let updatedResult = [];
    let { fieldChanged, updatedRow, newValue } = payload;
    if (fieldChanged === "qty") {
      updatedResult = localProducts.map((item) => {
        if (item._id === updatedRow._id) {
          return {
            ...item,
            totalPrice : parseFloat(newValue) * parseFloat(item.localPrice),
            qty: parseFloat(newValue),
          };
        } else {
          return item;
        }
      });
    } else if (fieldChanged === "localPrice") {
      updatedResult = localProducts.map((item) => {
        if (item._id === updatedRow._id) {
          return {
            ...item,
            totalPrice : parseFloat(item.qty) * parseFloat(newValue),
            localPrice: parseFloat(newValue),
          };
        } else {
          return item;
        }
      });
    }
    else if (fieldChanged === "price") {
      updatedResult = localProducts.map((item) => {
        if (item._id === updatedRow._id) {
          return {
            ...item,
            price :  parseFloat(newValue),
          };
        } else {
          return item;
        }
      });
    }
  
    return [...updatedResult];
  };

  function deleteLocalProduct(oldRecord, deletedRecord) {
    const newRecord = oldRecord.filter((Item) => {
      if (Item._id !== deletedRecord._id) {
        return Item;
      }
    });
    return [...newRecord];
  }