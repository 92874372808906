import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import InvoiceDetail from "./deliveryDetail";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const viewDelivered = () => {
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.deliveredR);
  const { initialValues, ShowDeliveredModal } = invProps;

  const fetchInvoiceInfo = () => {
    if (ShowDeliveredModal && initialValues) {
      dispatch(
        DataGetAction(
          "getDeliveryDetail",
          { _id: initialValues._id },
          "ViewDeliveryModalSpin"
        )
      );
    }
  };

  useEffect(fetchInvoiceInfo, [ShowDeliveredModal]);

  const closeForm = () => {
    dispatch({ type: "Hide_View_Delivered_Modal" });
  };
  return (
    <>
      <CModal size="lg" visible={ShowDeliveredModal}>
        <CModalHeader
          onDismiss={() => {
            closeForm();
          }}
        ></CModalHeader>
        <CForm>
          <CModalBody>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    {invProps.DeliveryDetailModalSpin ? <CSpinner /> : <InvoiceDetail />}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                closeForm();
              }}
            >
              {intlPlaceholder("companyModal.close")}
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export default viewDelivered;
