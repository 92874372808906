import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import TransactionType from "../../../components/InvoiceTable";
import IntlMessages from "../../../util/IntlMessages";
import { ChangeDateFormate, ChangeNumberFormate, NumberWithCommas } from "../../../constants/CommonFn";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from '@coreui/react-pro'
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { notify } from "src/util/ShowToast";
import { useHistory } from "react-router";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const InvoiceDetail = () => {
  const componentRef = useRef();
  const comProps = useSelector(state => state.currencyExchangeR);
  const { cancelCurrencyExchangeSuccess, cancelCurrencyExchangeError, message } = comProps
  const logoImage = localStorage.getItem('profileImage');
  const address = localStorage.getItem('address');
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    customerName,
    createdAt,
    remark,
    createdBy,
    currencyExchangeId,
    prefix,
    _id,
    receiptType

  } = comProps.CurrencyExchangeList.length > 0 ? comProps.CurrencyExchangeList[0] : '000-000-000'

  let oldId = comProps.CurrencyExchangeList && comProps.CurrencyExchangeList.length > 0 && comProps.CurrencyExchangeList[0].oldCurrencyExchangeId && comProps.CurrencyExchangeList[0].oldCurrencyExchangeId
  const isRefund = comProps.CurrencyExchangeList && comProps.CurrencyExchangeList.length > 0 && comProps.CurrencyExchangeList[0].refund && comProps.CurrencyExchangeList[0].refund
  const columns = [
    {
      label: <IntlMessages id="currencyExchange.date" />,
      key: "date",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.givenAmount" />,
      key: "givenAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.receivedAmount" />,
      key: "receivedAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="currencyExchange.factor" />,
      key: "factor",
      _style: { width: "40%" },
    },
  ];




  if (cancelCurrencyExchangeSuccess) {
    history.push("/currencyExchange");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "currencyExchange_UPDATE",
    });
  }

  if (cancelCurrencyExchangeError) {
    let messageLng = intlPlaceholder(message);
    notify("error", messageLng);
    dispatch({
      type: "currencyExchange_UPDATE",
    });
  }

  const areYouSureYouWantToRefundTheReceiptNumber = intlPlaceholder(
    "areYouSureYouWantToRefundTheReceiptNumber"
  );


  return (
    <div>
      <div className="d-flex justify-content-end text-end">
        {isRefund && <CButton
          color="success"
          className="text-white me-3"

        >
          <span><IntlMessages id="refunded" /></span>

        </CButton>}
        {/* receipt comment */}
        {!oldId && !isRefund && <DeleteModal
          contentLangueId={`${areYouSureYouWantToRefundTheReceiptNumber} ${prefix}-${currencyExchangeId}`}
          method="POST"
          type="cancelCurrencyExchange"
          selectedItem={{ _id }}
          ButtonTitleId="receipt.refund"
          titleLanguageId="receipt.refund"
          size="md"
          textColor="#ffffff"
          color="danger"
          variant=""
        />}

        {!isRefund && <ReactToPrint
          trigger={() => (
            <CButton
              color="info"
              className="text-white me-3"
            >

              <CIcon icon={cilPrint} className="me-2" />
              <IntlMessages id="print" />
            </CButton>
          )}
          content={() => componentRef.current}
        />}
      </div>
      <div className="print-invoice p-3 float-left w-100 pe-5 ps-5 " ref={componentRef}>
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          {/* <IntlMessages id="currencyExchange.currencyExchangeLabel" /> */}

          {oldId ? `${intlPlaceholder('refundOfCurrency')} ${prefix}-${oldId} ` : <IntlMessages id="currencyExchange.currencyExchangeLabel" />}
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="currencyExchange.customerName_label" />
              </h4>
              <p style={{ color: "grey" }}>{customerName}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="currencyExchange.srNo_label" />
              </h4>
              <p style={{ color: "grey" }}>
                {prefix + "-" + currencyExchangeId}
              </p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="currencyExchange.createdBy_label" />
              </h4>
              <p style={{ color: "grey" }}>{createdBy}</p>
            </div>
          </div>
        </div>
        <br />


        <div className="Table mb-2">
          <TransactionType

            items={comProps.CurrencyExchangeList}
            columns={columns}

            scopedColumns={{
              date: (record) => {
                return <td><span style={{
                  textTransform: 'capitalize'
                }}>{ChangeDateFormate(record.createdAt)}</span></td>;
              },
              givenAmount: (record) => {
                return <td><span style={{
                  textTransform: 'capitalize'
                }}>{record.givenCurrencySymbol} {ChangeNumberFormate(parseFloat(record.givenAmount).toFixed(2))}</span></td>;
              },
              receivedAmount: (record) => {
                return <td><span style={{
                  textTransform: 'capitalize'
                }}>{record.receivedCurrencySymbol} {ChangeNumberFormate(parseFloat(record.receivedAmount).toFixed(2))}</span></td>;
              },
              factor: (record) => {
                return <td><span>{record.givenCurrencySymbol} {Number.isInteger(parseFloat(record.factor)) ? ChangeNumberFormate(parseFloat(record.factor).toFixed(2)) : ChangeNumberFormate(parseFloat(record.factor).toFixed(2))}</span></td>;
              },


            }}
          />

        </div>
        <p>
          {remark && <IntlMessages id="invoiceDetail.note" />}
          {remark && `: ${remark === 'CancelTransaction' ? intlPlaceholder("CancelTransaction") : remark}`}
        </p>
        <InvoiceSignature data={{ clientName: customerName }} />
      </div>
    </div>
  );
};

export default InvoiceDetail;
