import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataGetAction } from "src/redux/actions/http";
import IntlMessages from "src/util/IntlMessages";
import DeliveredForm from "./deliveredForm";

const AddDelivered = () => {
  const dispatch = useDispatch();
  const fetchDistributeDropDown = () => {
    dispatch(DataGetAction("distributeDrop", ""));
  };
  useEffect(fetchDistributeDropDown, []);
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <h1>
              <IntlMessages id="addDelivered.title" />
            </h1>
          </CCardHeader>
          <CCardBody>
            <DeliveredForm />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AddDelivered;
