import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";

import { useLocation } from "react-router-dom";
import ViewDetail from './ViewDetail'
const Index = () => {
   
    const dispatch = useDispatch();
    const location = useLocation();
    const comProps = useSelector(state => state.ceReducer);
    const fetchInvoiceInfo = () => {

        const { _id } = location.state;
        dispatch(DataGetAction('getAllExpense', { query: "id", _id }, 'fetchingDetail'))
    }

    useEffect(fetchInvoiceInfo, [])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            
            <CCardBody>
            
            {comProps.fetchDetail ?<CSpinner/> : <ViewDetail />}
            </CCardBody>
          </CCard>
        </CCol>
        
      
      </CRow>
    </>
  );
};

export default Index;
