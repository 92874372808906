import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import CategoryList from "./components/CategoryList";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {

  const dispatch = useDispatch();
  const modalType = "show_productStock_modal"



  const comProps = useSelector(state => state.categoryReducer);
  

  const fetchProducts = () => {
    dispatch(DataGetAction('getAllCategory', { query: "all" }, 'categorySpinner'));
}
useEffect(fetchProducts, [comProps.fetchBankInfo])

  const create = CheckUserAccess('category',['create'])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="category.title" />
              </h1>
              
            </CCardHeader>
            <CCardBody>
            <div className="ps-3">
            {create &&  <AddModal />}
            </div>
              <DocsExample href="components/smart-table/">
                <CategoryList Data={comProps.Categories} Spinner={comProps.categorySpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditModal />
      </CRow>
    </>
  );
};

export default Index;
