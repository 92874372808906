import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  companyLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  Companies: [],
  passwordLoader: false,
  permissionModalVisible: false,
  permissionData: [],
  editModalVisible: false,
  CompanyStoreData: [],
  CompanyStoreSpin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getCompanyStore_SUCCESS": {
      return {
        CompanyStoreData: action.payload.Record,
        CompanyStoreSpin: false,
      };
    }
    case "getCompanyStore_FAILURE": {
      return {
        CompanyStoreSpin: false,
      };
    }
    case "getCompanyStoreSpin_START": {
      return {
        CompanyStoreSpin: true,
      };
    }
    case "Permission_Modal": {
      return {
        ...state,
        permissionModalVisible: !state.permissionModalVisible,
        permissionData: action.payload ? action.payload : null,
      };
    }
    case "getAllCompanies_SUCCESS": {
      return { ...state, Companies: action.payload.Record };
    }

    case "newCompanyActivatedInfo_SUCCESS": {
      const updateCompanyList = getCompanyList(action.payload, state.Companies);
      return { ...state, Companies: updateCompanyList };
    }

    case "show_company_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }
    case "edit_company_modal": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        selectedItem: action.payload,
      };
    }
    case "show_password_modal": {
      return {
        ...state,
        passModalVisible: !state.passModalVisible,
        selectedItem: action.payload,
      };
    }
    case "hide_password_modal": {
      return { ...state, passModalVisible: false, selectedItem: null };
    }

    case "company_START": {
      return { ...state, companyLoader: true };
    }
    case "company_STOP": {
      return { ...state, companyLoader: false };
    }
    case "resettingPassword_START": {
      return { ...state, passwordLoader: true };
    }

    case "resetUserPassword_SUCCESS": {
      return { ...state, resetPasswordSuccess: true, passwordLoader: false };
    }
    case "resetUserPassword_FAILURE": {
      return {
        ...state,
        resetPasswordError: true,
        passwordLoader: false,
        message: action.payload,
      };
    }

    case "inviteCompany_SUCCESS": {
      return {
        ...state,
        success: true,
        Companies: [action.payload.user, ...state.Companies],
        companyLoader: false,
      };
    }
    case "inviteCompany_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        companyLoader: false,
        message: action.payload,
      };
    }

    case "resendInvitation_SUCCESS": {
      return { ...state, resendSuccess: true, message: action.payload };
    }
    case "resendInvitation_FAILURE": {
      return {
        ...state,
        resendError: true,
        resendSuccess: false,
        message: action.payload,
      };
    }

    case "resetAgentPassword_SUCCESS": {
      return { ...state, resetPasswordSuccess: true, companyLoader: false };
    }
    case "resetAgentPassword_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        resetPasswordSuccess: false,
        companyLoader: false,
        message: action.payload,
      };
    }

    case "editCompany_SUCCESS": {
      return {
        ...state,
        editModalVisible: !state.editModalVisible,
        recordEditSuccess: true,
        companyLoader: false,
      };
    }
    case 'ResetCompany_RESET' :{
      return {
        ...state,
        recordEditSuccess: false,
      }
    }
    case "editCompany_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        companyLoader: false,
        message: action.payload,
      };
    }
    case "company_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        editModalVisible: false,
        resendError: false,
        resendSuccess: false,
        companyLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        message: "",
      };
    }
    case "reset_company_states": {
      return {
        ...state,
        resetPasswordSuccess: false,
        resetPasswordError: false,
      };
    }
    case "releaseNumberFromCompany_SUCCESS": {
      const oldCompanyRecord = state.Companies;
      let newCompanyRecord = [];
      oldCompanyRecord.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          newCompanyRecord.push(action.payload.Record);
        } else {
          newCompanyRecord.push(Item);
        }
        return Item;
      });

      return { ...state, Companies: newCompanyRecord };
    }

    case "deleteCompany_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Companies;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return { ...state, Companies: newCat };
    }

    default:
      return state;
  }

  function getCompanyList(latest, record) {
    const newArray = [];
    record.map((Item) => {
      if (Item._id === latest._id) {
        newArray.push(latest);
      } else {
        newArray.push(Item);
      }
      return Item;
    });

    return newArray;
  }
};
