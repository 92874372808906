import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";

import _ from 'lodash'
import moment from "moment";
const EmployeeFilter = ({ setFilters, initialValues }) => {
  
    const dispatch = useDispatch();
  const BSProps = useSelector((state) => state.balanceSheet);
  const emProps = useSelector((state) => state.employee);
  const { Banks } = BSProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(
      <IntlMessages id="dateRequired" />
    ),
    employeeId: Yup.string().required(
        <IntlMessages id="employeeIdRequired" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(),new Date().toISOString()], // Default value here
    },
  };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {

    
    values.startDate = values.date[0];
    values.endDate = values.date[1];

    if (values.type) {
      values.bankName =
        values.type === "cash"
          ? "cash"
          : _.filter(Banks, (x) => x._id === values.bank)[0].name;
    }
    setFilters(values);
    dispatch(
      DataGetAction(
        "employeeBalanceSheet",
        values,
        "fetchingEmployeeBalanceSheet"
      )
    );
  };

  const fetchAllBankAndCurrencyList = () => {
      setValue('employeeId',initialValues.employeeId)
    dispatch(DataGetAction("getAllBank", { query: "all" }));
    dispatch(DataGetAction("getAllCurrency", { query: "all" }));
  };

  useEffect(fetchAllBankAndCurrencyList, []);
  const dateFormat = "YYYY/MM/DD";
  const { Employees } = emProps;
  const handleChange = (e) => {
  };
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );
  };
  return (
    <div className="p-4 pb-3">
      <CForm className="d-flex flex-wrap" onSubmit={handleSubmit(onFinish)}>
        <div className=" me-3">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
            )}
          />
            <div className="error-message">{errors.date?.message}</div>
        </div>
        <div className=" me-3">
          <div className="">
            <CFormSelect {...register("employeeId")}
            
            >
              <option value="all">All</option>
              {Employees &&
                Employees.map((Item) => (
                  <option value={Item._id}>
                    {Item.firstName} {Item.lastName} ({Item.empId})
                  </option>
                ))}
            </CFormSelect>
            <div className="error-message">{errors.employeeId?.message}</div>
            
          </div>
        </div>

        <div className={``}>
          <div className="">
            <CLoadingButton type="submit" color="primary">
              <IntlMessages id="generateButton" />
            </CLoadingButton>
          </div>
        </div>
      </CForm>
    </div>
  );
};

export default EmployeeFilter;
