import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";

// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { notify } from "src/util/ShowToast";
import { useHistory } from "react-router";

const ReceiptDetail = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.receiptReducer);

  const history = useHistory();

  const { refundCompanyReceiptSuccess, refundCompanyReceiptError, message } = invProps;
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const {
    receiptNo,
    prefix,
    createdAt,
    supplier,
    supplierAddress,
    supplierEmail,
    createdBy,
    createdByAddress,
    paymentMode,
    currencyName,
    currencyId,
    supplierId,
    currencySymbol,
    paymentMethod,
    paid,
    _id,
    receiptType,
    remark
  } =
    invProps.selectedSupplierReceipt !== null
      ? invProps.selectedSupplierReceipt
      : "000-000-000";



  const emailSent = supplierEmail ? supplierEmail : "";

  const isRefund = invProps.selectedSupplierReceipt && invProps.selectedSupplierReceipt.refund && invProps.selectedSupplierReceipt.refund
  const oldId = invProps.selectedSupplierReceipt && invProps.selectedSupplierReceipt.refundReceiptRefNo && invProps.selectedSupplierReceipt.refundReceiptRefNo

  const columns = [
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.bankName" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionType" />,
      key: "TransactionType",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionNo" />,
      key: "TransactionNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.Amount" />,
      key: "Amount",
      _style: { width: "40%" },
    },
  ];

  const renderPaymentDetail = () => {
    const paymentModeArray = paymentMode ? [paymentMode] : [];

    return (
      <TransactionType
        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          mode: (record) => {
            return (
              <td>
                {" "}
                <IntlMessages id={`${record.mode}.paymentMethod`} />
              </td>
            );
          },
          bankName: (record) => {
            return <td>{record.bank ? record.bank.name : "---"}</td>;
          },
          TransactionType: (record) => {
            return (
              <td>{record.transactionType ? record.transactionType : "---"}</td>
            );
          },
          TransactionNo: (record) => {
            return (
              <td>
                {record.transactionNumber ? record.transactionNumber : "---"}
              </td>
            );
          },
          Amount: (record) => {
            return (
              <td>
                {currencyName} {ChangeNumberFormate(paid.toFixed(2))}
              </td>
            );
          },
          chequeDate: (record) => {
            return (
              <td>{ChangeDateFormate(record.chequeDate, "YYYY-MM-DD")}</td>
            );
          },
        }}
      />
    );
  };

  if (
    paymentMode &&
    paymentMode.mode === "bank" &&
    paymentMode.transactionType === "cheque"
  ) {
    columns.push({
      label: <IntlMessages id="receiptDetail.chequeDate" />,
      key: "chequeDate",
      _style: { width: "40%" },
    });
  }

  const getRecordValue = () => {
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    const paymentModeArray = paymentMode ? [paymentMode] : [];

    let data = [];

    if (paymentModeArray && paymentModeArray.length > 0) {
      paymentModeArray.map((item) => {
        let { mode, quantity, salePrice, total, ...rest } = item;

        mode = intlPlaceholder(`${mode}.paymentMethod`);

        let obj = {
          productMode: mode,
          bankName: item.bank ? item.bank.name : "----",
          transactionType: item.transactionType ? item.transactionType : "---",
          transactionNumber: item.transactionNumber
            ? item.transactionNumber
            : "---",
          amount: currencyName + NumberWithCommas(parseFloat(paid)),
        };

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.receiptFor");
    let key3 = intlPlaceholder("receiptDetail.receiptNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("receiptDetail.PaymentMode"),
      intlPlaceholder("receiptDetail.bankName"),
      intlPlaceholder("receiptDetail.TransactionType"),
      intlPlaceholder("receiptDetail.TransactionNo"),
      intlPlaceholder("receiptDetail.Amount"),
    ];

    let detailObj = {
      [key1]: { name: supplier, address: supplierAddress },
    };

    detailObj = {
      ...detailObj,
      [key3]: prefix + "-" + receiptNo,
      [key4]: createdBy,
    };

    let obj = {
      subject: `${intlPlaceholder(
        "receiptDetail.ReceiptLabel"
      )}  ${intlPlaceholder("from")}  ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: intlPlaceholder("receiptDetail.ReceiptLabel"),
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,
    };

    return obj;
  };

  if (refundCompanyReceiptSuccess) {
    history.push("/receipt");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  if (refundCompanyReceiptError) {
    let messageLng = intlPlaceholder(message);
    notify("error", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  const areYouSureYouWantToRefundTheReceiptNumber = intlPlaceholder(
    "areYouSureYouWantToRefundTheReceiptNumber"
  );


  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">


        <div className="d-flex align-items-center">
          {isRefund && <CButton
            color="success"
            className="text-white me-3"
          >
            <span><IntlMessages id="refunded" /> {prefix}-{receiptNo}</span>
          </CButton>}
          {/* receipt comment */}
          {(receiptType !== 'refund' && !isRefund) && <DeleteModal
            contentLangueId={`${areYouSureYouWantToRefundTheReceiptNumber} ${prefix}-${receiptNo}`}
            method="POST"
            type="refundSupplierReceipts"
            selectedItem={{ _id, receiptNo, supplierId, currencyId }}
            textColor={'#ffffff'}
            ButtonTitleId="receipt.refund"
            titleLanguageId="receipt.refund"
            size="md"
            color="danger"
            variant=""
          />}
          {receiptType !== 'refund' && !isRefund &&
            <CButton
              color="warning"
              className="text-white me-3"
              onClick={() => {
                dispatch({
                  type: "send_email_modal",
                });
              }}
            >
              <i className="icon icon-mail-open me-1 mb-1"></i>
              <IntlMessages id="sendMail" />
            </CButton>}

          {!isRefund && <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />}
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5"
        ref={componentRef}
      >
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{
            color: "#B1B1B1",
            width: "200px",
            textTransform: "capitalize",
          }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          {receiptType === 'refund' ? `${intlPlaceholder('refundOfReceipt')} ${prefix}-${oldId} ` : <IntlMessages id="receiptDetail.ReceiptLabel" />}
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.receiptFor" />
              </h4>
              <p style={{ color: "#B1B1B1", marginBottom: 0 }}>{supplier}</p>
              <p style={{ color: "#B1B1B1" }}>{supplierAddress}</p>
            </div>
            {/* <div>
                            <h4 style={{ fontWeight: 'bold' }}><IntlMessages id="receiptDetail.payableTo" /></h4>
                            <p style={{ color: '#B1B1B1' }}>{supplier}</p>
                        </div> */}
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                {" "}
                <IntlMessages id="receiptDetail.receiptNumber" />{" "}
              </h4>
              <p style={{ color: "#B1B1B1" }}>{prefix + "-" + receiptNo}</p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.createdBy" />{" "}
              </h4>
              <p style={{ color: "#B1B1B1" }}>{createdBy}</p>
            </div>
          </div>
          <div></div>
        </div>
        <br />

        <div className="w-100 mt-5">{renderPaymentDetail()}</div>

        <p>
          {remark && <IntlMessages id="invoiceDetail.note" />}
          {remark && `:  ${remark}`}
        </p>
        <InvoiceSignature data={{ clientName: supplier }} />
      </div>
      <SendEmailModal
        initialValues={{ email: emailSent }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default ReceiptDetail;
