
import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    trustLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    TrustUsers: [],
    depositModalVisible: false,
    withDrawModalVisible: false,
    Employee: [],
    BalanceSheetList: [],
    LastBalance: 0,
    balanceChange: false,
    TrustSheetDetail: [],
    TrustDetail: [],
    allTrustUserSpinner: false,
    fetchingBalanceSheetForTrustUser: false,
    CompanyBankList: [],
    editModalVisible: false,
    bulkModalVisible: false,
    bulkUpload_success: false,
    textMessage: '',
    bulkUpload_error: false,
    latestDepositRecord: null,
    latestWithDrawRecord: null,
    WithDrawSuccess: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getCompanyAccountByCurrencyId_SUCCESS': {
            return {
                ...state,
                CompanyBankList: action.payload.Record
            }
        }

        case 'removeTheResultTrustFund': {
            return { ...state, BalanceSheetList: [] }
        }
        case 'fetchingDetail_START': {
            return { ...state, TrustDetailSpinner: true }
        }
        case 'getTrustDetail_SUCCESS': {
            return { ...state, TrustDetail: action.payload.Record, TrustDetailSpinner: false }
        }
        case "resetAmount": {
            return { ...state, balanceChange: false }
        }
        case 'getUserLastBalance_SUCCESS': {
            return { ...state, LastBalance: action.payload.LastBalance, balanceChange: true }
        }
        case 'balanceSheetForTrustUser_SUCCESS': {
            return { ...state, BalanceSheetList: action.payload.Record }
        }
        case 'fetchingBalanceSheetForTrustUser_START': {
            return { ...state, fetchingBalanceSheetForTrustUser: true };
        }
        case 'fetchingBalanceSheetForTrustUser_STOP': {
            return { ...state, fetchingBalanceSheetForTrustUser: false };
        }
        case 'depositAmount_SUCCESS': {
            return { ...state, success: true, latestDepositRecord: action.payload.Record, message: "amountDepositSuccessfully" }
        }
        case 'withDrawAmount_SUCCESS': {
            return { ...state, WithDrawSuccess: true, success:true, latestWithDrawRecord: action.payload.Record, message: "amountWithdrawSuccessfully" }
        }
        case 'getAllEmployeeWithTrust_SUCCESS': {
            return { ...state, Employee: action.payload.Record }
        }
        case 'Deposit_modal': {
            return { ...state, depositModalVisible: !state.depositModalVisible }
        }
        case 'WithDraw_modal': {
            return { ...state, withDrawModalVisible: !state.withDrawModalVisible }
        }
        case 'getAllTrustUser_SUCCESS': {
            return { ...state, TrustUsers: action.payload.Record };
        }
        case 'allTrustUserSpinner_START': {
            return { ...state, allTrustUserSpinner: true };
        }
        case 'allTrustUserSpinner_STOP': {
            return { ...state, allTrustUserSpinner: false };
        }
        case 'newTrustUserActivatedInfo_SUCCESS': {
            const updateTrustUserList = getTrustUserList(action.payload, state.TrustUsers);
            return { ...state, TrustUsers: updateTrustUserList }
        }

        case 'show_trust_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'bulk_import_modal': {
            return { ...state, bulkModalVisible: !state.bulkModalVisible };
        }
        case 'edit_trust_modal': {
            return { ...state, editModalVisible: !state.editModalVisible, selectedItem: action.payload };
        }


        case 'trust_START': {
            return { ...state, trustLoader: true };
        }
        case 'trust_STOP': {
            return { ...state, trustLoader: false };
        }

        case 'addTrustUser_SUCCESS': {
            return { ...state, success: true, TrustUsers: [action.payload.Record, ...state.TrustUsers], trustLoader: false, message: action.payload.message };
        }
        case 'addTrustUser_FAILURE': {
            return { ...state, error: true, success: false, trustLoader: false, message: action.payload };
        }
        case 'bulkImportTrustUser_SUCCESS': {
            return {
                ...state,
                bulkUpload_success: true,
                textMessage: action.payload.message
            }
        }
        case 'bulkImportTrustUser_FAILURE': {

            return {
                ...state,
                bulkUpload_error: true,
                textMessage: action.payload
            }
        }

        case 'editTrustUser_SUCCESS': {
            let oldCat = state.TrustUsers;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, TrustUsers: newCat, trustLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editTrustUser_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, trustLoader: false, message: action.payload, modalVisible: false, error: true };
        }

        case 'trust_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                trustLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                message: "",
                selectedItem: null,
                depositModalVisible: false,
                withDrawModalVisible: false,
                editModalVisible: false,
                bulkModalVisible: false,
                bulkUpload_success: false,
                textMessage: '',
                bulkUpload_error: false,
                latestDepositRecord: null,
                latestWithDrawRecord: null,
                WithDrawSuccess: false

            };
        }

        case 'deleteTrustUser_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.TrustUsers;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, TrustUsers: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }


    function getTrustUserList(latest, record) {
        const newArray = [];
        record.map(Item => {
            if (Item._id === latest._id) {
                newArray.push(latest);
            } else {
                newArray.push(Item);
            }
            return Item
        })

        return newArray;
    }

}
