import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
} from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import InvoiceList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import pdfImage from "../../assets/images/avatars/pdf.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CIcon from '@coreui/icons-react'
import { cilFile } from '@coreui/icons'
import moment from "moment";
import CheckUserAccess from "src/util/checkUserAccess";
import EditInvoiceModal from './components/EditInvoiceModal'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Index = () => {
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({ status: 'approved' });

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const dispatch = useDispatch();
  const invProps = useSelector((state) => state.invoiceReducer);
  const supplierProps = useSelector((state) => state.supplier);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;
  const [ReceiptNumber, setReceiptNumber] = useState();
  const [filterValue, setFilterValue] = useState();

  const { CreatedByList } = invProps;
  const { Suppliers } = supplierProps;
  const history = useHistory();
  let ConstHeader = intlPlaceholder("supplier.invoice.supplierReport");
  let constHeaderTo = intlPlaceholder("report.to");
  let constHeaderFrom = intlPlaceholder("report.from");



 

  const ViewInvoice = (data) => {

    history.push({
      pathname: "/viewInvoice",
      state: {
        InvoiceNo: data.referenceNo,
        supplierId: data.supplierId,
        currencyId: data.currencyId,
        ourId: data.invoiceId
      },
    });
  };

  const handleSearch = () => {
    // let splitOnArray = ReceiptNumber.split('-')
    // let number = splitOnArray[0];
    // if(splitOnArray.length === 2){
    //     number = splitOnArray[1]
    // }

    dispatch({
      type: "Search_supplier_invoice_number",
      payload: ReceiptNumber,
    });
  };

  const searchBySupplierName = (e) => {
    dispatch({
      type: "Search_invoice_supplier_Id",
      payload: e,
    });
  };

  const Search_by_createdBy = (e) => {
    dispatch({
      type: "Search_supplier_Invoice_by_created_by",
      payload: e,
    });
  };
  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("supplierName"), style: "tableHeader" },
      { text: intlPlaceholder("amount"), style: "tableHeader" },
      { text: intlPlaceholder("createdBy"), style: "tableHeader" },
    ],
  ];
  const downloadPDF = async () => {
    let data = invProps.Invoices
    let Record = data;


    Record.forEach((Item, index) => {
      let date = ChangeDateFormate(Item.createdAt);
      let amount = parseFloat(Item.totalAmount).toFixed(2);
      amount = NumberWithCommas(amount);
      let objArr = [date];

      objArr.push(`${Item.prefix}-${Item.invoiceId}/${Item.referenceNo}`);
      objArr.push(Item.suppliers[0].label);
      objArr.push(amount);
      objArr.push(Item.createdBy);

      bodyData = [...bodyData, objArr];
    });

    let startDate = moment(filterValue.startDate).format('YYYY-MM-DD');
    let endDate = ChangeDateFormate(filterValue.endDate, "YYYY-MM-DD");



    let headerTitle =
      ConstHeader.toUpperCase() +
      " " +
      constHeaderFrom +
      " " +
      startDate +
      " " +
      constHeaderTo +
      " " +
      endDate;

    const logoImage = localStorage.getItem('image') ? localStorage.getItem('image') : process.env.REACT_APP_LOGO_DUMMY


    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `Email : ${LogeInUser.email}  - Cell.: +${LogeInUser.phoneNumber}`;
    let docDefinition = {
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },

        {
          text: headerTitle,
          margin: [15, 10],
          style: { fontSize: 12, bold: true, alignment: "center" },
        },
        {
          style: "tableExample",
          headerRows: 1,

          table: {
            widths: ["*", "*", "*", "auto", "auto"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download("supplerSummery.pdf");
  };

  const columns = [
    {
      label: <IntlMessages id="invoice.referenceNo" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.supplierName" />,
      key: "supplierName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.amount" />,
      key: "totalAmount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "status",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approved'), value: 'approved', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },
    {
      label: '',
      sorter: false,
      filter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];



  const editRecord = (data) => {
    data.products.forEach((node) => {
      node.currency = node.currencyId
      node.unit = node.unitId
      node.quantity = node.purchasedQty
    });

    dispatch({
      type: 'editProductInvoice_Modal',
      payload: data
    })
  }
  const edit = CheckUserAccess('stockEntry', ['edit'])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4"></CCard>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="invoice.supplierInvoice.title" />
              </h1>
              <div className="d-flex">

                {/* <CButton
                  color="dark"
                  onClick={() => downloadPDF(invProps.Invoices)}
                >
                
                  <span style={{color : 'white'}}>
                  
                  <span className="icon icon-files me-2"></span>
                    <IntlMessages id="pdf" />
                  </span>
                </CButton> */}
              </div>
            </CCardHeader>
            <CCardBody>
              <Filter setFilter={setFilterValue} downloadPDF={downloadPDF} />
              <DocsExample href="components/smart-table/">
                <InvoiceList
                  loading={invProps.supplierInvoiceSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={
                    invProps.supplierInvoiceSpinner ? [] : invProps.dueInvoices
                  }
                  columns={columns}
                  columnFilter
                  columnFilterValue={staticFilterValue}
                  onColumnFilterChange={(a, b, c) => {
                    console.log("e", a, b, c);
                  }}
                  filterValueChangeTo={(value) => {
                    if (value.status) {
                      setStaticFilterValue(value)
                    }
                  }}
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    status: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.status === 'approved' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.status}`)}</CBadge>
                        </td>
                      );
                    },

                    supplierName: (record) => {
                      return (
                        <td>
                          <span>
                            {record.supplierName}
                          </span>
                        </td>
                      );
                    },
                    totalAmount: (record) => {
                      return (
                        <td className="text-end">
                          {ChangeNumberFormate(record.totalAmount.toFixed(2))}
                        </td>
                      );
                    },
                    createdBy: (record) => {
                      return (
                        <td>{record.createdBy}</td>
                      );
                    },

                    createdAt: (record) => {
                      return <td>{record.createdAt}</td>;
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => ViewInvoice(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                            {edit && <CButton size="sm" color="warning" onClick={() => editRecord(record)}>
                              <i className="icon icon-edit text-white me-1"></i>
                              <span style={{ color: 'white' }}>
                                <IntlMessages id="Edit" /></span>
                            </CButton>}
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let total = 0;


                    pageData.forEach(({ balanceWithSupplier }) => {
                      total += balanceWithSupplier;
                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center"><h5><IntlMessages id='Total' /></h5></th>

                        <th colSpan={2} className="text-end">
                          <span><h5>{ChangeNumberFormate(total.toFixed(2))}</h5></span>
                        </th>
                        <th>
                          <span></span>
                        </th>
                        <th><span></span></th>
                      </tr>

                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <EditInvoiceModal key="editInvoiceModal" modalType="editProductInvoice_Modal" title={intlPlaceholder("editInvoiceModalTitle")} />
    </>
  );
};

export default Index;
