import React, { useState } from "react";
import {
  CCardBody,
  CCardHeader,
  CCard,
  CRow,
  CCol,
  CLoadingButton,
} from "@coreui/react-pro";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import UserRoleForm from "./UserRoleForm";
import { useLocation } from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";
const AddUserRole = () => {
  const location = useLocation();
  const [key, setKey] = useState(1);
  const defaultValue = location?.state?.data ? location?.state?.data : null;
  //const [defaultValue, setDefaultValue] = useState(location?.state?.data ? location?.state?.data : null)
  const action = location?.state?.action;

  // const data = {

  //     "permissionAccess": {
  //         "stockEntry": [
  //             "view",
  //             "create"
  //         ],
  //         "supplier": [
  //             "create",
  //             "view",
  //             "edit",
  //             "delete"
  //         ],
  //         "currency": [
  //             "view",
  //             "delete",
  //             "edit",
  //             "create"
  //         ],
  //         "product": [
  //             "view",
  //             "create",
  //             "edit",
  //             "delete"
  //         ],
  //         "units": [
  //             "view",
  //             "create",
  //             "delete",
  //             "edit"
  //         ],
  //         "category": [
  //             "view",
  //             "create",
  //             "edit",
  //             "delete"
  //         ],
  //         "companyToStore": [
  //             "view"
  //         ],
  //         "storeToStore": [
  //             "view"
  //         ],
  //         "supplierInvoices": [
  //             "view",
  //             "pdf"
  //         ],
  //         "storeInvoices": [
  //             "view",
  //             "create",
  //             "pdf"
  //         ],
  //         "stores": [
  //             "view",
  //             "create",
  //             "edit",
  //             "delete",
  //             "assignUnassign"
  //         ],
  //         "supplierReceipts": [
  //             "view",
  //             "create",
  //             "pdf"
  //         ],
  //         "storeReceipts": [
  //             "view",
  //             "create",
  //             "pdf"
  //         ],
  //         "bank": [
  //             "view",
  //             "edit",
  //             "create",
  //             "delete"
  //         ],
  //         "invoices": [
  //             "view",
  //             "create"
  //         ],
  //         "receipts": [
  //             "view",
  //             "create"
  //         ],
  //         "customer": [
  //             "view",
  //             "create",
  //             "edit",
  //             "delete"
  //         ],
  //         "currencyExchange": [
  //             "view",
  //             "create"
  //         ],
  //         "companyExpenses": [
  //             "view",
  //             "create"
  //         ],
  //         "salary": [
  //             "view",
  //             "create"
  //         ],
  //         "storeExpenses": [
  //             "view",
  //             "create"
  //         ],
  //         "storeWithDrawals": [
  //             "view",
  //             "create"
  //         ],
  //         "fundTransfers": [
  //             "view",
  //             "create"
  //         ],
  //         "trustFunds": [
  //             "view",
  //             "deposit",
  //             "withdraw"
  //         ],
  //         "companyAccounts": [
  //             "view",
  //             "pdf"
  //         ],
  //         "storeAccounts": [
  //             "view",
  //             "pdf"
  //         ],
  //         "supplierAccounts": [
  //             "view",
  //             "pdf"
  //         ],
  //         "supplierOrderHistory": [
  //             "view",
  //             "pdf"
  //         ],
  //         "customerOrderHistory": [
  //             "view",
  //             "pdf"
  //         ],
  //         "trustUser": [
  //             "view",
  //             "create",
  //             "edit",
  //             "delete"
  //         ],
  //         "account": [
  //             "view",
  //             "create"
  //         ]
  //     }
  // }
  return (
    <div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <strong>{intlPlaceholder("userRoles.addModalTitle")}</strong>
              {/* {action !== 'onlyView' && <CLoadingButton
                                className="d-flex align-items-center"
                                onClick={() => {
                                    setDefaultValue(data)
                                    setKey(key + 1)
                                }}
                                color="primary"

                            >
                                 <IntlMessages id={"userRoleForm.button.autoFill"} />
                            </CLoadingButton>} */}
            </CCardHeader>
            <CCardBody>
              <UserRoleForm
                defaultValues={defaultValue}
                action={action}
                key={key}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default AddUserRole;
