import React, { useState,useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormInput,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DataRequestAction } from "src/redux/actions/http";
import {notify} from '../../../util/ShowToast'
const EditStockModal = ({modalType}) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.productReducer);
  const { editModalRecord } = comProps;
  const [price, setDefaultPrice] = useState(0);

    // form validation rules
    const validationSchema = Yup.object().shape({
        price: Yup.string().required(
            intlPlaceholder('EditPriceModalRequire')
        ),
        // qty: Yup.string().required(
        //   intlPlaceholder('EditStockModalRequire')
        // ),
      
      });
    
      const formOptions = { resolver: yupResolver(validationSchema) };
    
    
      // get functions to build form with useForm() hook
      const { register, handleSubmit, reset, formState, control,setValue } = useForm(formOptions);
      const { errors } = formState;

  const onCreate = (values) => {
    
    values._id = comProps.editModalRecord._id;
    values.currencyId = comProps.editModalRecord.currencyId;
    values.productId = comProps.editModalRecord.productId;

    
    dispatch(
      DataRequestAction(
        "PUT",
        "updateStock",
        values,
        "",
        "updatingStock",
        false
      )
    );
  };

  if (comProps.success) {
    notify('success',intlPlaceholder('recordUpdateSuccessfully'));
    dispatch({
      type: "unit_UPDATE",
    });
  }

  const changeTheInitialValue = () => {
    if (comProps.EditStockModal) {
      setDefaultPrice(editModalRecord.localPrice);
      setValue("price", editModalRecord.localPrice);
    }
  };

  useEffect(changeTheInitialValue, [comProps.EditStockModal]);

  return (
    <div>
      <CModal visible={comProps.EditStockModal}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("EditStockModalTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
        <CModalBody>
      
            <div className="mb-3">
              <strong>Prix unitaire { editModalRecord?.productName }</strong>
              <CFormInput
                className={errors.price ? "is-invalid" : ""}
                {...register("price")}
                placeholder={intlPlaceholder("price")}
                type="number"
                id="name"
              />
              <div className="invalid-feedback">{errors.price?.message}</div>
            </div>
            <div className="mb-3">
            <strong>Quantité à ajouter / diminuer </strong>
              <CFormInput
                
                {...register("qty")}
                placeholder={intlPlaceholder("ModifyStockPlaceholder")}
                type="number"
                step="any"
                id="stock"
                
              />
             
            </div>

          
        
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => dispatch(DataEntryModalAction(modalType, null))}
          >
            {intlPlaceholder("CancelButtonTitle")}
          </CButton>

          <CLoadingButton
            type="submit"
            color="primary"
            loading={comProps.updatingStockLoader}
            disabled={comProps.updatingStockLoader}
          >
            {intlPlaceholder("SaveButtonTitle")}
          </CLoadingButton>
        </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default EditStockModal;
