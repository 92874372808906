import React, { useEffect } from "react";
import {
  CButton,
  CFormSelect,
  CForm,
  CFormInput,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/http";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import UnitListLocal from "./UnitListLocal";
import SubUnitForm from "./SubUnitform";
import { notify } from "../../../util/ShowToast";
const AddUnitForm = () => {
  const dispatch = useDispatch();

  const SubUnit = useSelector((state) => state.unitReducer.SubUnits);
  const subProps = useSelector((state) => state.unitReducer);
  const history = useHistory();
  const { register, handleSubmit, reset, formState, control } = useForm({
    defaultValues: subProps.selectedItem,
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: "unit_UPDATE",
      });
    };
  }, []);

  const { errors } = formState;
  const onFinish = (values) => {
    const data = {
      _id: values._id,
      name: values.name,
      subUnits: SubUnit,
    };

    dispatch(DataRequestAction("PUT", "editUnit", data, "", "unit", false));
  };
  let unitEditSuccess = intlPlaceholder("unitEditSuccess");
  if (subProps.success) {
   
    notify("success", unitEditSuccess);
    history.push("/units");
    dispatch({
      type: "unit_UPDATE",
    });
  }

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row">
        <div className="col">
          <CFormInput
            className={errors.name ? "is-invalid" : ""}
            {...register("name")}
            placeholder="Name"
            type="text"
            id="name"
          />
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
      </div>
      <SubUnitForm />
      <UnitListLocal />

      <div className="row">
        <div className="col-4 d-grid gap-2">
          <CLoadingButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "unit_UPDATE",
              });
              reset();
              history.push("/units");
            }}
          >
            <IntlMessages id="unit.addUnitPage.cancel" />
          </CLoadingButton>
        </div>
        <div className="col-4 d-grid gap-2">
          <CLoadingButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              if (subProps.editSubUnits && subProps.editSubUnits !== null) {
                dispatch({
                  type: "reset_form_edit",
                });
              } else {
                dispatch({
                  type: "unit_UPDATE",
                });
              }

              reset();
            }}
          >
            <IntlMessages id="unit.addUnitPage.reset" />
          </CLoadingButton>
        </div>
        <div className="col-4 d-grid gap-2">
          <CLoadingButton
            className="btn btn-primary  btn-block"
            type="submit"
            color="primary"
            loading={subProps.unitLoader}
            disabled={subProps.unitLoader}
          >
            <IntlMessages id="unit.addUnitPage.save" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default AddUnitForm;
