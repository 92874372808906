import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import TransactionType from "../../../../components/InvoiceTable";
import IntlMessages from "../../../../util/IntlMessages";
import {
  ChangeDateFormate,
  NumberWithCommas,
} from "../../../../constants/CommonFn";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import SendEmailModal from "src/components/SendEmailModal";
const ViewDetail = () => {
  const componentRef = useRef();
  const comProps = useSelector((state) => state.trustFund);
  const { TrustDetail } = comProps;
  const dispatch = useDispatch();
  const {
    createdAt,
    prefix,
    refId,
    balance,
    currency,
    empId,
    name,
    createdBy,
    jobTitle,
    receiptType,
    depositDate,
    remark,
    emailUser,
    paymentMode,
    bankName
  } = TrustDetail && TrustDetail.length > 0 ? TrustDetail[0] : "";
  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");
  let columns = [
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "paymentMode",
      _style: { width: "40%" },
    },

  ];

  if(bankName){
    columns.push({
      label: <IntlMessages id="receiptDetail.bankName" />,
      key: "bankName",
     
    },)
  }

  columns = [...columns, {
    label: <IntlMessages id="receiptDetail.TotalPayable" />,
    key: "TotalPayable",
    _style: { width: "40%" },
  },
  {
    label: <IntlMessages id="receiptDetail.notes" />,
    key: "notes",
    _style: { width: "40%" },
  }]
  const renderPaymentDetail = () => {
    const paymentModeArray = TrustDetail ? TrustDetail : [];
    return (
      <TransactionType
        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          bankName: (record) =>{
            return (
              <td align="left">
                {record.bankName}
              </td>
            );
          },
          paymentMode: (record) => {
            if (record.paymentMode === 'cash') {
              return (
                <td>
                  <IntlMessages id="cashEspeces.paymentMethod" />
                </td>
              );
            } else {
              return (
                <td>
                  <IntlMessages id="bc.bank" />
                </td>
              );
            }
          },
          TotalPayable: (record) => {
            return (
              <td>
                <span>
                  {record.currency}
                  {parseInt(record.debit) === 0
                    ? NumberWithCommas(parseFloat(record.credit))
                    : NumberWithCommas(parseFloat(record.debit))}
                </span>
              </td>
            );
          },
          notes: (record) => {
            return (
              <td>
                <span>{intlPlaceholder(record.description)}</span>
              </td>
            );
          },
        }}
      />
    );
  };

  const getRecordValue = () => {
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");
    const trustFundData = TrustDetail && TrustDetail.length > 0 ? TrustDetail[0] : [];
    const subjectTrustFund = trustFundData.receiptType && trustFundData.receiptType === "withdraw" ? `${intlPlaceholder("withdrawal")}` : `${intlPlaceholder("Deposit")}`;

    let obj = {
      subject: `${companyName} - ${subjectTrustFund}`,
      title: {
        dear: intlPlaceholder("emailTemplate.dear"),
        content: trustFundData.receiptType && trustFundData.receiptType === "withdraw"
          ? intlPlaceholder("emailTemplate.trustFund.withdrawnContent")
          : intlPlaceholder("emailTemplate.trustFund.depositContent"),
        notReply: intlPlaceholder("emailTemplate.notReply"),
        thankyou: intlPlaceholder("emailTemplate.thankyou"),
        trustFundName: intlPlaceholder("emailTemplate.trustFund.name"),
        trustFundAccountId: intlPlaceholder("emailTemplate.trustFund.accountId"),
        trustFundCreatedBy: intlPlaceholder("emailTemplate.trustFund.createdBy"),
        trustFundDetail: intlPlaceholder("emailTemplate.trustFund.detail"),
        trustFundTotalPayable: intlPlaceholder("emailTemplate.trustFund.totalPayable"),
        trustFundMode: intlPlaceholder("emailTemplate.trustFund.mode"),
        bankName: intlPlaceholder("receiptDetail.bankName"),
        trustFundNotes: intlPlaceholder("emailTemplate.trustFund.notes"),
        trustFundBalance: intlPlaceholder("emailTemplate.trustFund.balance"),
        trustFundDate: intlPlaceholder("emailTemplate.trustFund.date"),
      },
      data: {
        logo: logo,
        address: mainAddress,
        date: ChangeDateFormate(trustFundData.createdAt),
        companyName,
        name: trustFundData.name,
        reference: trustFundData.prefix + "-" + trustFundData.refId,
        accountId: trustFundData.empId,
        createdBy: trustFundData.createdBy,
        bankName: bankName?bankName:intlPlaceholder('cashEspeces.paymentMethod'),
        type: trustFundData.receiptType && trustFundData.receiptType === "withdraw"
          ? `${intlPlaceholder("withdrawal").toLocaleUpperCase()} ${intlPlaceholder("emailTemplate.number")}`
          : `${intlPlaceholder("Deposit").toLocaleUpperCase()} ${intlPlaceholder("emailTemplate.number")}`,
        paymentMode: trustFundData.paymentMode === 'cash'?intlPlaceholder('cashEspeces.paymentMethod'):intlPlaceholder('bc.bank'),
        notes: trustFundData.remark,
        currency: trustFundData.currency,
        amount: parseInt(trustFundData.debit) === 0 ? NumberWithCommas(parseFloat(trustFundData.credit)) : NumberWithCommas(parseFloat(trustFundData.debit)),
        amountBalance: NumberWithCommas(parseFloat(trustFundData.balance))
      },
    };


    return obj;
  };

  let [printBalance, setPrintBalance] = useState(true);
  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <CButton
            color="warning"
            size="sm"
            className="text-white me-3"
            onClick={() => {
              dispatch({
                type: "send_email_modal",
              });
            }}
          >
            <i className="icon icon-mail-open me-1 mb-1"></i>
            <IntlMessages id="sendMail" />
          </CButton>
          <ReactToPrint
            trigger={() => (
              <CButton color="info" className="text-white me-3 ">
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />
          <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="printNotBalance" />
              </CButton>
            )}
            content={() => componentRef.current}
            onBeforeGetContent={() => {
              setPrintBalance(false)
              return Promise.resolve();
            }
            }
            onAfterPrint={() => {
              setPrintBalance(true)
              return Promise.resolve();
            }
            }
          />
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 "
        ref={componentRef}
      >
        <img src={logoImage} alt="" width="300" className="mb-2" />
        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          <IntlMessages id="ts.SheetLabel" />
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {depositDate
            ? ChangeDateFormate(depositDate, "YYYY-MM-DD")
            : ChangeDateFormate(createdAt, "YYYY-MM-DD")}
        </p>
        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}><IntlMessages id="trustUserReceipt.name" /> </h4>
              <p style={{ color: "grey" }}>{name}</p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}> <IntlMessages id="trustUserReceipt.accountId" /></h4>
              <p style={{ color: "grey" }}>{empId}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>  <IntlMessages id="trustUserReceipt.ref" />#</h4>
              <p style={{ color: "grey" }}>{prefix + "-" + refId}</p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}> <IntlMessages id="trustUserReceipt.createdBy" /></h4>
              <p style={{ color: "grey" }}>{createdBy}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}> <IntlMessages id="trustUserReceipt.jobTitle" /></h4>
              <p style={{ color: "grey" }}>{jobTitle ? jobTitle : "----"}</p>
            </div>
            <div>
              <h4 style={{ fontWeight: "bold" }}>  <IntlMessages id="trustUserReceipt.type" /></h4>
              {receiptType && <p style={{ color: "grey", textTransform: "capitalize" }}>
                {receiptType && receiptType === "withdraw"
                  ? intlPlaceholder("withdrawal")
                  : intlPlaceholder(receiptType)}
              </p>}
            </div>
          </div>
        </div>
        <br />
        <div className="d-flex  pt-4 mb-0">
          <h3>
            <IntlMessages id="receiptDetail.PaymentDetail" />:
          </h3>
        </div>
        <div className="w-100">{renderPaymentDetail()}</div>
        <div className="d-flex justify-content-between mt-4">
          <p>
            {remark && <IntlMessages id="invoiceDetail.note" />}
            {remark && `: ${remark}`}
          </p>
          {printBalance && (
            <div style={{ width: "430px" }}>
              <div className="d-flex">
                <span
                  className="d-inline-block"
                  style={{ width: "40%", fontSize: 16, color: "#003366" }}
                >
                  <IntlMessages id="trustUserReceipt.balance" />:
                </span>
                <span
                  className="d-inline-block "
                  style={{ width: "60%", fontSize: 16, fontWeight: 'bold' }}
                >
                  {currency} {NumberWithCommas(parseFloat(balance))}
                </span>
              </div>
            </div>
          )}
        </div>
        <InvoiceSignature data={{ clientName: name }} />
        <SendEmailModal
          initialValues={{ email: emailUser }}
          record={getRecordValue()}
          type='trustFundDetail'
        />
      </div>
    </div>
  );
};
export default ViewDetail;