import React from "react";
import CSmartTable from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";
const StoreBalanceSheetList = ({ Data, Spinner }) => {
  const blProps = useSelector((state) => state.balanceSheet);
  const param = useLocation();
  const dispatch = useDispatch();
  const { filterDate } = param.state;

  const history = useHistory();
  const redirectToPage = (data) => {
    if (data.recordType === "salaryPosted") {
      history.push("/viewSalaryDetails", { guid: data.reference });
    } else if (
      data.recordType === "storeExpense" ||
      data.recordType === "refundStoreExpense"
    ) {
      history.push("/viewStoreExpenseDetail", { _id: data.reference });
    } else if (
      data.recordType === "companyToCustomer" ||
      data.recordType === "refundCompanyToCustomer"
    ) {
      history.push("/viewStoreReceipt", {
        ReceiptNo: parseInt(data.reference),
      });
    } else if (data.recordType === "storeToStoreSale") {
      history.push("/storeInvoiceDetail", {
        InvoiceNo: parseInt(data.reference),
        prefix: data.prefix,
        invoiveType: "StoreToStore",
      });
    } else if (data.recordType === "storeToStoreSaleReceipt") {
      history.push("/storeToStoreCredit", {
        InvoiceNo: parseInt(data.reference),
        prefix: data.prefix
      });
    } else if (data.recordType === "purchasedProduct") {
      history.push(`/viewReceipt`, { ReceiptNo: parseInt(data.reference) });
    } else if (data.credit === 0 && data.prefix === "RM") {
      history.push(`/viewReceipt`, { ReceiptNo: parseInt(data.reference) });
    } else if (
      data.credit === 0 &&
      (data.prefix === "FC" || data.prefix === "FS")
    ) {
      history.push(`/viewStoreInvoice`, {
        InvoiceNo: parseInt(data.reference),
        prefix: data.prefix
      });
    } else if (data.debit === 0 && data.description !== "Store withdraw") {
      history.push(`/viewStoreInvoice`, {
        InvoiceNo: parseInt(data.reference),
        prefix: data.prefix
      });
    } else if (data.recordType === "Sale Invoice") {
      history.push("/saleInvoice");
      dispatch({
        type: "Show_View_Sale_Invoice_Modal",
        payload: { ...data, sendRef: true },
      });
    } else {
      history.push(`/viewReceipt`, { ReceiptNo: parseInt(data.reference) });
    }
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.date" />,
      key: "date",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="balanceSheet.description" />,
      key: "description",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.Ref" />,
      key: "Ref",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.debit" />,
      key: "debit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.credit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.balance" />,
      key: "balance",
      _style: { width: "40%" },
    },
  ];

  const { StoreBalanceSheetData, storeBalanceSheetSpinner } = blProps;

  // if(filterDate){
  //      startDate = filterDate.startDate
  //      endDate = filterDate.endDate

  // }

  return (
    <>
      <CSmartTable
        loading={storeBalanceSheetSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={storeBalanceSheetSpinner ? [] : StoreBalanceSheetData}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={200}
        columnSorter
        pagination
        underTableSlot={<>Total: {"sfds"}</>}
        scopedColumns={{
          description: (record) => {
            return (
              <td>
                {record.remark ? (
                  record.remark
                ) : (
                  <IntlMessages id={record.description} />
                )}
              </td>
            );
          },
          date: (record) => {
            return <td>{ChangeDateFormate(record.createdAt)}</td>;
          },
          Ref: (record) => {
            return (
              <td>
                {record.prefix ? (
                  <span
                    onClick={() => redirectToPage(record)}
                    style={{
                      cursor: "pointer",

                      color: "blue",
                    }}
                  >
                    {record.showReference
                      ? `${record.prefix}-${record.showReference}`
                      : `${record.prefix}-${record.reference}`}
                  </span>
                ) : (
                  <span>---</span>
                )}
              </td>
            );
          },
          debit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.debit && record.debit.toFixed(2))}
              </td>
            );
          },
          credit: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(record.credit && record.credit.toFixed(2))}
              </td>
            );
          },
          balance: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.balance && parseFloat(record.balance).toFixed(2)
                )}
              </td>
            );
          },
        }}
        summary={(pageData) => {
          let totalBorrow = 0;
          let totalRepayment = 0;

          pageData.forEach(({ debit, credit }) => {
            totalBorrow += debit;
            totalRepayment += credit;
          });

          return (
            <tr>
              <th colSpan={3} className="gx-text-center">
                TOTAL
              </th>
              <th className="text-end">
                <span>
                  {ChangeNumberFormate(
                    totalBorrow > 0 ? totalBorrow.toFixed(2) : 0
                  )}
                </span>
              </th>
              <th className="text-end">
                <span>
                  {ChangeNumberFormate(
                    totalRepayment > 0 ? totalRepayment.toFixed(2) : 0
                  )}
                </span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          );
        }}
      />
    </>
  );
};

export default StoreBalanceSheetList;
