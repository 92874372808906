import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CForm,
  CMultiSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
const AddStoreModal = () => {
  const dispatch = useDispatch();
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const comProps = useSelector((state) => state.storeReducer);




  const {
    storeLoader,
    selectedItem,
    assignEmployeeModalVisible,
    success,
  } = comProps;

  const initialValues = selectedItem;

  const modalType = "assign_employee_modal";

  const onFinish = () => {
    let values = {}
    if (selectedItem && selectedItem !== null) {
      values.storeInfo = selectedItem;
      values.employees = selectedEmployees

      dispatch(
        DataRequestAction("POST", "assignEmployees", values, "", "store", false)
      );
    }
  };

  const fetchEmployee = () => {
    if (assignEmployeeModalVisible) {
      dispatch(DataGetAction("fetchEmployees", "", "fetching"));
    }
  };
  useEffect(fetchEmployee, [assignEmployeeModalVisible]);

  if (success) {
    notify("success", intlPlaceholder("recordAddeddSuccessfully"));

    dispatch({
      type: "store_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message)
    notify("error", messageLng);
    dispatch({
      type: "store_UPDATE",
    });
  }
  const options = [
    {
      value: 0,
      text: "Angular",
    },
    {
      value: 1,
      text: "Bootstrap",
    },
    {
      value: 2,
      text: "React.js",
      selected: true,
    },
    {
      value: 3,
      text: "Vue.js",
    },
  ];

  let Employee = null;
  if (comProps.selectedItem !== null && comProps.selectedItem.employeeAssign.length > 0) {
    Employee = [...comProps.selectedItem.employeeInfo, ...comProps.Employees]
  } else if (comProps.selectedItem && comProps.selectedItem.employeeAssign.length === 0 && comProps.Employees && comProps.Employees) {
    Employee = comProps.Employees;
  }


  return (
    <div>
      <CModal visible={assignEmployeeModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            <IntlMessages id="store.assignEmployeeModal.assignEmployees" />
          </CModalTitle>
        </CModalHeader>
        <CForm >
          <CModalBody>

        { comProps && comProps.selectedItem && comProps.selectedItem.employeeInfo && comProps.selectedItem.employeeInfo.length > 0 && comProps.selectedItem.employeeInfo[0]?.label ?     <Select
              defaultValue={comProps?.selectedItem?.employeeInfo}
              styles={selectStyle}
              isMulti={true}
              isSearchable={true}
              placeholder={intlPlaceholder(
                "store.assignEmployeeModal.chooseEmployees"
              )}
              onChange={(values) => {
                let ids = values && values.length > 0 && values.map(item => item.value)
                setSelectedEmployees(ids);
              }}
              options={Employee && Employee}
            /> :  <Select
           
            styles={selectStyle}
            isMulti={true}
            isSearchable={true}
            placeholder={intlPlaceholder(
              "store.assignEmployeeModal.chooseEmployees"
            )}
            onChange={(values) => {
              let ids = values && values.length > 0 && values.map(item => item.value)
              setSelectedEmployees(ids);
            }}
            options={Employee && Employee}
          />}


          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="store.assignEmployeeModal.cancel" />
            </CButton>

            <CLoadingButton onClick={() => { onFinish() }} color="primary" loading={storeLoader} disabled={storeLoader}>
              {`${initialValues === null
                  ? intlPlaceholder("store.assignEmployeeModal.Assign")
                  : intlPlaceholder("store.assignEmployeeModal.Assign")
                }`}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddStoreModal;
