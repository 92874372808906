import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import { CButton } from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
const SalaryDetail = () => {
  const componentRef = useRef();
  const comProps = useSelector((state) => state.esReducer);
  const history = useHistory();
  const logoImage = localStorage.getItem('profileImage');
  const { Salaries } = comProps;
  
  const { createdAt } = Salaries && Salaries.length > 0 ? Salaries[0] : "";

  const columns = [
    {
      label: <IntlMessages id="es.name" />,
      key: "name",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "paymentMethod",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.credit" />,
      key: "credit",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="es.SalarySlip" />,
      key: "SalarySlip",
      _style: { width: "40%" },
    },
  ];

  const renderPaymentDetail = () => {
    const paymentModeArray = Salaries ? Salaries : [];
    return (
      <TransactionType
        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          SalarySlip: (record) => {
            return (
              <td>
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/viewSalaryDetail`,
                      state: { _id: record._id },
                    })
                  }
                  style={{
                    cursor: "pointer",
                    color: "blue",
                  }}
                >
                  <IntlMessages id="view" />
                </span>
              </td>
            );
          },
        }}
      />
    );
  };

  const renderLogoHere = () => {
    
    return <img src={logoImage} alt="" width="300" className="mb-2" />
  };

  return (
    <div>
      <div className="text-end">
        <ReactToPrint
          trigger={() => (
            <CButton color="info" className="text-white me-3">
              <CIcon icon={cilPrint} className="me-2" />
              <IntlMessages id="print" />
            </CButton>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 "
        ref={componentRef}
      >
        <h2 className="text-center">
          
          <IntlMessages id="es.EmployeeSalaryDetailLabel" />
        </h2>
        <div className="d-flex justify-content-between mb-3">
          <h2>{renderLogoHere()}</h2>
          <div className="float-right">
            <p className="mb-0">
              <span className="font-weight-bold mr-2"><IntlMessages id="date" />:</span>
              {ChangeDateFormate(createdAt)}
            </p>
            <p className="mb-0">
              <span className="font-weight-bold mr-2"><IntlMessages id="type" />:</span><IntlMessages id="salary" />
            </p>
            {/* <p className="mb-0"><span className="font-weight-bold mr-2">Created By:</span>{createdByUser && createdByUser[0].name}</p> */}
          </div>
        </div>

        <div className="mt-5">
          <div className="d-flex mt-4 pt-4 mb-0">
            <h3>
              <IntlMessages id="es.PaymentDetails" />:
            </h3>
          </div>
          <div className="w-100">{renderPaymentDetail()}</div>
        </div>

        {/* <InvoiceSignature /> */}
      </div>
    </div>
  );
};

export default SalaryDetail;
