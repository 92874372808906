import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    adminLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    Admins: [],
    adminSpinner: false ,
    editModalVisible : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getAllAdmin_SUCCESS': {
            return { ...state, Admins: action.payload.Record };
        }
        case 'adminSpinner_START':{
            return { ...state,  adminSpinner: true };
        }
        case 'adminSpinner_STOP':{
            return { ...state,   adminSpinner: false };
        }
        case 'newAdminActivatedInfo_SUCCESS': {
            const updateAdminList = getAdminList(action.payload, state.Admins);
            return { ...state, Admins: updateAdminList }
        }

        case 'show_admin_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'edit_admin_modal': {
            return { ...state, editModalVisible: !state.editModalVisible, selectedItem: action.payload };
        }


        case 'admin_START': {
            return { ...state, adminLoader: true };
        }
        case 'admin_STOP': {
            return { ...state, adminLoader: false };
        }

        case 'inviteAdmin_SUCCESS': {
            return { ...state, success: true, message:"InvitationSendSuccessfully", Admins: [action.payload.user, ...state.Admins], adminLoader: false };
        }
        case 'inviteAdmin_FAILURE': {
            return { ...state, error: true, success: false, adminLoader: false, message: action.payload };
        }


        case 'editAdmin_SUCCESS': {
            let oldCat = state.Admins;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Admins: newCat, adminLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editAdmin_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, adminLoader: false, message: action.payload, modalVisible: false };
        }

        case 'admin_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                adminLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                message: "",
                selectedItem: null,
                editModalVisible : false

            };
        }

        case 'deleteAdmin_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Admins;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Admins: newCat, selectedItem: null, success:true, message:'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }


    function getAdminList(latest, record) {
        const newArray = [];
        record.map(Item => {
            if (Item._id === latest._id) {
                newArray.push(latest);
            } else {
                newArray.push(Item);
            }
            return Item
        })

        return newArray;
    }

}
