import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import CustomerTable from "./components/CustomerTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddCustomerModal from "./components/AddCustomerModal";
import EditCustomerModal from "./components/EditCustomerModal";
import CheckUserAccess from "../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { notify } from "src/util/ShowToast";
import BulkImportCustomer from "./components/BulkImportCustomer";

const SmartTable = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.customer);

  const authProps = useSelector((state) => state.auth);
  const {permissionAccess} = authProps
  let role = authProps.LogeInUser.role
  const { Customers,customerSpinner,customerBulkUpload_success, customerBulkUpload_error,textMessage } = comProps;

  const fetchCustomer = () => {
    dispatch(DataGetAction('getAllCustomer', { query: "all" },'customerSpinner'));
}
useEffect(fetchCustomer, [])

const create =CheckUserAccess('customer',['create']) 



if(customerBulkUpload_success){
  let messageLng =  intlPlaceholder(textMessage)
  fetchCustomer()
  notify('success',messageLng)
  dispatch({
    type : 'customer_UPDATE'
  })
}
if(customerBulkUpload_error){
 
  let messageLng =  intlPlaceholder(textMessage)
  notify('error',messageLng)
  dispatch({
    type : 'customer_UPDATE'
  })
}

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="customer.title" />
              </h1>
             
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex" style={{gap : 10}}>
             { create && <AddCustomerModal />}
             {/* <BulkImportCustomer/> */}
              </div>
              <DocsExample href="components/smart-table/">
                <CustomerTable Customers={Customers} customerSpinner={customerSpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
        
        <EditCustomerModal />
      </CRow>
    </>
  );
};

export default SmartTable;
