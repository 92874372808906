import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import {useHistory} from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction,DataGetAction } from "../../../redux/actions/http";
import {notify} from '../../../util/ShowToast'
import NumberFormat from "react-number-format";
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
const ReceiptForm = ({ modalType }) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.receiptReducer);
  const { Store, CompanyAccount, customerDue } = comProps;
  const history = useHistory();

  const [PayType, setPayType] = useState("online");
  const [AmountDue, setAmountDue] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState("cash");
  const [storeId, setStoreId] = useState("");

  const [customerData, setCustomerData] = useState([]);
  const [fetchingCustomer, setFetchingCustomer] = useState(false);
  const [customerValue, setCustomerValue] = useState(null);

  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
        <IntlMessages id="receiptForm.storeRequired" />
    ),
    customer: Yup.string().required(
        <IntlMessages id="receiptForm.customerRequired" />
    ),
    // customerDue: Yup.string().required(
    //     <IntlMessages id="receiptForm.paymentRequired" />
    // ),
    paymentMethod: Yup.string().required(
        <IntlMessages id="receipt.message" />
    ),

    bank: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receiptForm.bankRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    transactionType: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receiptForm.transactionTypeRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    chequeOrTransactionNo: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        `${PayType === "online" ?intlPlaceholder("transactionRequired") : intlPlaceholder("chequeRequired")} `
      ),
      otherwise: Yup.string().notRequired(),
    }),
    amountReceived: Yup.string().required(
        <IntlMessages id="receiptForm.amountReceivedRequired" />
    ),
   
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const fetchCompanyBank = () => {
    if (comProps.modalVisible) {
      dispatch(DataGetAction("fetchCompanyBank", ""));
    }
    setValue('customerDue',0)
  };

  useEffect(fetchCompanyBank, [comProps.modalVisible]);

  const getCustomerDue = (e) => {
    dispatch(
      DataGetAction("getCustomerDue", { customerId: e, storeId: storeId })
    );
  };



  if ((customerDue !== undefined || customerDue !== null)   && !AmountDue) {
    setValue("customerDue", customerDue, {shouldValidate : true});
    setAmountDue(true);
  }


  const fetchCustomer = () => {
    // setCustomerData([]);
    // setFetchingCustomer(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };

    fetch(
      `${process.env.REACT_APP_ROOT_URL}/getAllCustomer/${JSON.stringify({
        query: "all",
      })}`,
      { headers }
    )
      .then((response) => response.json())
      .then((body) => {
        const data = body.Record.map((Item) => ({
          text: `${Item.name}`,
          value: Item._id,
        }));
        setCustomerData(data);
        // setFetchingCustomer(false)
      });
  };

  useEffect(fetchCustomer, []);

  const handleChangeCustomer = (value) => {
    setCustomerValue(value);
    getCustomerDue(value);
  };

  const onCreate = (values) => {
  
    if (values.paymentMethod === "bank") {
      const selectedBank = CompanyAccount.filter(item=>item._id === values.bank)
      values.bankName = selectedBank[0].bankName;
      values.accountBalanceId = selectedBank[0].balanceId
  }


    values.paymentMode = { mode: values.paymentMethod };
    if (values.bankName) {
      values.paymentMode.bankName = values.bankName;
    }
  
    values.receiptType = "companySaleToClient";
    values.customer = customerValue;
 

    reset()
    setCustomerValue(null);
    setValue("customerDue", 0);
    setValue("store", "");
    setValue("customer", "");
   
   
    dispatch(
      DataRequestAction("POST", "addStoreReceipt", values, "", "receipt", false)
    );
  };

  if (comProps.success) {
    let messageLng = intlPlaceholder( comProps.message)
    notify('success',messageLng)
    // message.success(comProps.message);
    dispatch({
      type: "receipt_UPDATE",
    });
    history.push({
      pathname: "/viewStoreReceipt",
      state: { ReceiptNo: comProps.receiptNo },
    });
  }

  const fetchStore = () => {
    if (comProps.modalVisible) {
      dispatch(DataGetAction("getReceiptDropDown", ""));
    }
  };
  useEffect(fetchStore, [comProps.modalVisible]);

  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="receipt.add.receipt.button" />
      </CButton>

      <CModal visible={comProps.modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("StoreSaleReceiptTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
          <CModalBody>
            <div className="row">
              {/* store */}
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="store"
                    render={({ field: { onChange, value } }) => (
                      <>
                       <Select
                          className={errors.store ? "is-invalid" : ""}
                          isSearchable={true}
                          styles={selectStyle}
                          placeholder={intlPlaceholder("selectStore")}
                          onChange={(e) => {
                            setAmountDue(false);
                            setStoreId(e.value);
                            onChange(e.value);
                          }}
                          options={renderOptions([...Store], "label", "key")}
                        />
                   
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.store?.message}
                  </div>
                </div>
              </div>

              {/* customer */}
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="customer"
                    render={({ field: { onChange, value } }) => (
                      <>
                       <Select
                           className={errors.customer ? "is-invalid" : ""}
                          isSearchable={true}
                          isDisabled={storeId === '' ? true : false}
                          styles={selectStyle}
                          placeholder= {intlPlaceholder("selectCustomer")}
                          onChange={(e) => {
                            setAmountDue(false);
                            handleChangeCustomer(e.value);
                            onChange(e.value);
                          }}
                          options={renderOptions([...customerData], "text", "value")}
                        />
                  
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.customer?.message}
                  </div>
                </div>
              </div>

              {/* customerDue */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormInput
                    className={errors.customerDue ? "is-invalid" : ""}
                    {...register("customerDue")}
                    placeholder={intlPlaceholder('amountDue')}
                    type="text"
                    id="customerDue"
                    disabled={true}
                  />
                  <div className="invalid-feedback">
                    {errors.customerDue?.message}
                  </div>
                </div>
              </div>


              {/* paymentMethod */}
              <div className="col-md-12">
                <div className="mb-3">
                <CFormFloating className="mb-3">   
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({ field: { onChange, value } }) => (
                      <CFormSelect
                        className={errors.paymentMethod ? "is-invalid" : ""}
                        value={value}
                        disabled={storeId === '' || customerValue === null ? true : false}
                        onChange={(e) => {
                            setPaymentMethod(e.target.value);
                            onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                        {intlPlaceholder('choosePaymentMethod')}
                        </option>
                        <option value="cash">{intlPlaceholder('cash')}</option>
                        {CompanyAccount && CompanyAccount.length > 0 && <option value="bank">{intlPlaceholder('bankTransfer')}</option>}
                      </CFormSelect>
                    )}
                  />
<CFormLabel htmlFor="">
            {intlPlaceholder("choosePaymentMethod")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.paymentMethod?.message}</div>
        </CFormFloating>
                  
                </div>
              </div>
             
             
         { PaymentMethod === "bank" &&   
            <>
              <div className="col-md-12">
              <div className="mb-3">
              <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="bank"
                    render={({ field: { onChange, value } }) => (
                      <CFormSelect
                        className={errors.bank ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                        {intlPlaceholder('chooseBank')}
                        </option>
                        <option value="cash">{intlPlaceholder('cash')}</option>
                        {CompanyAccount && CompanyAccount.map(Item => <option value={Item._id}>{Item.bankName}({Item.number})</option>)}
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
            {intlPlaceholder("chooseBank")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.bank?.message}</div>
        </CFormFloating>

    
                </div>
              </div>
              <div className="col-md-12">
              <div className="mb-3">
              <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="transactionType"
                    render={({ field: { onChange, value } }) => (
                      <CFormSelect
                        className={errors.transactionType ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                            setPayType(e.target.value)
                            onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                        {intlPlaceholder('transactionType')}
                        </option>
                        <option value="online">{intlPlaceholder('Online')}</option>
                            <option value="cheque">{intlPlaceholder('Cheque')}</option>
                      </CFormSelect>
                    )}
                  />
<CFormLabel htmlFor="">
            {intlPlaceholder("transactionType")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.transactionType?.message}</div>
        </CFormFloating>
              
                </div>
              </div>
              <div className="col-md-12">
              <div className="mb-3">
              <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.chequeOrTransactionNo ? "is-invalid" : ""}
                    {...register("chequeOrTransactionNo")}
                    placeholder={`${PayType === "online" ? intlPlaceholder("transactionNumber") :intlPlaceholder("chequeNumber")}}`}
                    type="text"
                    id="customerDue"
                    
                  />
                  <CFormLabel htmlFor="">
            {intlPlaceholder(`${PayType === "online" ? intlPlaceholder("transactionNumber") : intlPlaceholder("chequeNumber")}`)}
          </CFormLabel>
          <div className="invalid-feedback">{errors.chequeOrTransactionNo?.message}</div>
        </CFormFloating>
                  
                </div>
              </div>
              </>
        }
            {/* amountReceived */}
            <div className="col-md-12">
            <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        disabled={storeId === '' || customerValue === null ? true : false}
                        className={errors.amountReceived ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder('amountReceived')} 
                      />
                    )}
                    name={"amountReceived"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amountReceived?.message}
                  </div>
                </div>
            </div>

            {/* Remark */}
            <div className="col-md-12">
            <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.remark ? "is-invalid" : ""}
                    {...register("remark")}
                    type="text"
                    id="remark"
                    placeholder={intlPlaceholder('Remark')}
                  />
                  <CFormLabel htmlFor="remark">
                  {intlPlaceholder("Remark")}
                </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.remark?.message}
                  </div>
                </CFormFloating>
            </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              {intlPlaceholder("CancelButtonTitle")}
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.receiptLoader}
              disabled={comProps.receiptLoader}
            >
              {intlPlaceholder("SaveButtonTitle")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default ReceiptForm;
