

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '',
  ShowSignUpLoader: false,
  ShowLoginLoader: false,
  authUser: JSON.parse(localStorage.getItem('user')),
  success: false,
  error: false,
  LogeInUser: null,
  loginSuccess: false,
  ShowRestLoader: false,
  logoutSuccess: false,
  ShowSetNewLoader: false,
  ShowForgotLoader: false,
  signUpSuccess: false,
  updateUserSuccess: false,
  updateUserError: false,
  unAuthorized_modal: false,
  userCheckerLoader: false,
  userError: false,
  message: "",
  permissions : [],
  loginError : false,
  permissionAccess : null,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'setUserNameError': {
      return {
        ...state,
        userError: true,
        message: "username required"
      }
    }

    case 'userNameCheckLoader_START': {
      return { ...state, userCheckerLoader: true }
    }
    case 'userNameCheckLoader_STOP': {
      return { ...state, userCheckerLoader: false }
    }

    case 'userNameCheck_SUCCESS': {
      return { ...state, userError: false, message: '', userCheckerLoader: false }
    }
    case 'userNameCheck_FAILURE': {
      return { ...state, userError: true, message: action.payload, userCheckerLoader: false }
    }

    case 'UN_AUTHORIZED': {
      return {
        ...state,
        unAuthorized_modal: true
      }
    }
    case 'UN_AUTHORIZED_close': {
      return {
        ...state,
        unAuthorized_modal: false
      }
    }

    case 'INIT_URL': {
      return { ...state, initURL: action.payload };
    }

    case 'signup_START': {
      return { ...state, ShowSignUpLoader: true };
    }
    case 'signup_STOP': {
      return { ...state, ShowSignUpLoader: false };
    }

    case 'login_START': {
      return { ...state, ShowLoginLoader: true };
    }
    case 'login_STOP': {
      return { ...state, ShowLoginLoader: false };
    }

    case 'reset_START': {
      return { ...state, ShowRestLoader: true };
    }
    case 'reset_STOP': {
      return { ...state, ShowRestLoader: false };
    }
    case 'forgot_START': {
      return { ...state, ShowForgotLoader: true };
    }
    case 'forgot_STOP': {
      return { ...state, ShowForgotLoader: false };
    }

    case 'setNew_START': {
      return { ...state, ShowSetNewLoader: true };
    }
    case 'setNew_STOP': {
      return { ...state, ShowSetNewLoader: false };
    }


    case 'login_SUCCESS': {
      localStorage.setItem('token', action.payload.user.token);
      localStorage.setItem('companyName', action.payload.user.companyName);
      localStorage.setItem('assignToStore', action.payload.user.assignToStore);
      localStorage.setItem('storeName', action.payload.user.storeName);

      return { ...state, 
          logoutSuccess: false, 
          token: action.payload.user.token, 
          LogeInUser: action.payload.user, 
          ShowSignUpLoader: false, 
          error: false, 
          loginSuccess: true, 
          message: "", 
          permissions : action.payload.user.permissions,
          ShowLoginLoader : false,  
          permissionAccess : action?.payload?.user?.permissionAccess
        };
    }
    case 'login_FAILURE':{
      return {
        ...state,
        ShowSignUpLoader : false,
        loginError : true
      }
    }

    case 'updateCompanyInfo_SUCCESS': {
      return { ...state, logoutSuccess: false, token: action.payload.user.token, updateUserSuccess: true, LogeInUser: action.payload.user, ShowSignUpLoader: false, error: false, loginSuccess: true, message: "" };
    }
    case 'updateCompanyInfo_FAILURE': {
      return { ...state, logoutSuccess: false, token: null, LogeInUser: null, ShowSignUpLoader: false, updateUserError: true, loginSuccess: false, message: "someErrorOccurWhileUpdatingInfo" };
    }

    case 'logout_success': {
      localStorage.clear();
      localStorage.setItem('persist:root', null);
      return {
        ...state,
        initURL: '',
        LogeInUser: null,
        token: null,
        logoutSuccess: true,
        ShowSignUpLoader: false,
        authUser: null,
        loginSuccess: false,
        ShowLoginLoader: false,
        error: false,
        success: false,
        message: ""
      };
    }
    case 'login_FAILURE': {
      return { ...state, ShowSignUpLoader: false, error: true, success: false, message: action.payload };
    }




    case 'signup_SUCCESS': {
      return { ...state, ShowSignUpLoader: false, error: false, signUpSuccess: true, success: true, message: "YouAreSuccessfullyRegister" };
    }
    case 'signup_FAILURE': {
      return { ...state, ShowSignUpLoader: false, error: true, signUpSuccess: false, success: false, message: action.payload };
    }

    case 'forgetPassword_SUCCESS': {
      return { ...state, ShowForgotLoader: false, error: false, success: true, message: "passResetSuccess" };
    }
    case 'forgetPassword_FAILURE': {
      return { ...state, ShowForgotLoader: false, error: true, success: false, message: action.payload };
    }
    case 'resetPassword_SUCCESS': {
      return { ...state, ShowRestLoader: false, error: false, success: true, message: "passResetSuccess" };
    }
    case 'resetPassword_FAILURE': {
      return { ...state, ShowRestLoader: false, error: true, success: false, message: action.payload };
    }

    case 'setNewPassword_SUCCESS': {
      return { ...state, ShowSetNewLoader: false, error: false, success: true, message: "passResetSuccess" };
    }
    case 'setNewPassword_FAILURE': {
      return { ...state, ShowSetNewLoader: false, error: true, success: false, message: "errorWhileUpdatingPassword" };
    }

    case 'auth_UPDATE': {
      return { ...state, signUpSuccess: false, updateUserSuccess: false, updateUserError: false, ShowForgotLoader: false, ShowSetNewLoader: false, ShowSignUpLoader: false, ShowRestLoader: false, loginSuccess: false, logoutSuccess: false, ShowLoginLoader: false, error: false, success: false, message: "" ,loginError : false};
    }

    default:
      return state;
  }
}
