import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import ProductForm from "./ProductForm";
const AddModal = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.ProductR);

  const { productLoader, selectedItem, modalVisible, success } = comProps;

  const modalType = "show_productStock_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
    dispatch({
      type: "getSubunit_SUCCESS",
      payload: { Record: [] },
    });
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="product.add.product.page.button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("product.addProductModal.addCategory")}
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <ProductForm
            defaultValues={null}
            action="add"
            ButtonComponent={
              <CModalFooter>
                <CButton
                  color="secondary"
                  onClick={() =>
                    dispatch(DataEntryModalAction(modalType, null))
                  }
                >
                  <IntlMessages id="admin.addAdminModal.cancel" />
                </CButton>

                <CLoadingButton
                  type="submit"
                  color="primary"
                  loading={productLoader}
                  disabled={productLoader}
                >
                  {intlPlaceholder("product.addProductModal.save")}
                </CLoadingButton>
              </CModalFooter>
            }
          />
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddModal;
