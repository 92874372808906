import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import ProductTable from "./components/ProductTable";
import BulkImportProduct from "./components/BulkImportProduct";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import CheckUserAccess from "../../util/checkUserAccess";
import { notify } from "src/util/ShowToast";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const Index = () => {

  const dispatch = useDispatch();
  const modalType = "show_productStock_modal"



  const comProps = useSelector(state => state.ProductR);
  

  const fetchProducts = () => {
    dispatch(DataGetAction('getAllProductStock', { query: "all" }, 'productSpinner'));
    dispatch(DataGetAction('getAllCategory', { query: "all" }));
    dispatch(DataGetAction('getAllUnit', { query: "all" }));
}
useEffect(fetchProducts, [comProps.fetchBankInfo])

const {  productBulkUpload_success, productBulkUpload_error,textMessage } = comProps;

if(productBulkUpload_success){
  let messageLng =  intlPlaceholder(textMessage)
  fetchProducts()
  notify('success',messageLng)
  dispatch({
    type : 'productStock_UPDATE'
  })
}
if(productBulkUpload_error){
 
  let messageLng =  intlPlaceholder(textMessage)
  notify('error',messageLng)
  dispatch({
    type : 'productStock_UPDATE'
  })
}

  const create = CheckUserAccess('product',['create'])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="product.page.title" />
              </h1>
             
            </CCardHeader>
            <CCardBody>
            <div className="ps-3 d-flex" style={{gap : '10px'}}>
            {create && <AddModal />}
            <BulkImportProduct />
            </div>
              <DocsExample href="components/smart-table/">
                <ProductTable Data={comProps.ProductList} Spinner={comProps.productSpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditModal />
      </CRow>
    </>
  );
};

export default Index;
