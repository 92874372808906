import { CButton, CForm, CFormInput, CLoadingButton } from "@coreui/react-pro";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import IntlMessages from "src/util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import AddDeliveredList from "./addDeliveredList";
import { useHistory } from "react-router-dom";
import { DataGetAction, DataRequestAction } from "src/redux/actions/http";
import { notify } from "src/util/ShowToast";
const DeliveredForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [InvoiceData, setInvoiceData] = useState("");
  const [selectStoreId, setStoreId] = useState(null);
  const deliveredProps = useSelector((state) => state.deliveredR);
  const {
    deliveredLocalProduct,
    deliveredLocalProductSpin,
    createDeliveredProductSpin,
    createDeliveredProductSuccess,
    createDeliveredProductError,
    createDeliveredProductData,
    textMessage,
  } = deliveredProps;
  const productProps = useSelector((state) => state.ProductR);
  const { localProducts } = productProps;
  const saleInvoiceProps = useSelector((state) => state.saleInvoiceReducer);
  const { stores } = saleInvoiceProps;
  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="addDelivered.pleaseSelectStore_message" />
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };
  const { control, formState, reset } = useForm(formOptions);
  const { errors } = formState;
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  const searchInvoices = (value) => {
    setInvoiceData(value);
    dispatch(
      DataGetAction(
        "getSaleInvoiceForDeliverySlip",
        { storeId: selectStoreId, invoiceNumber: value },
        "getSaleInvoiceForDeliverySpin"
      )
    );
  };

  const onFinish = () => {
    let updateArray = [];
    if (deliveredLocalProduct && deliveredLocalProduct.length > 0) {
      deliveredLocalProduct.map((item) => {
        if (item.qtyToDeliver !== 0) {
          updateArray.push(item);
        }
      });
    }
    const data = {
      invoiceId: updateArray && updateArray[0].saleId,
      storeId: selectStoreId,
      products: updateArray,
    };
    dispatch(
      DataRequestAction(
        "POST",
        "createDeliverySlip",
        data,
        "",
        "createDeliverySpin"
      )
    );
  };

  if (createDeliveredProductSuccess) {
    if (createDeliveredProductData !== null) {
      dispatch({
        type: "Show_View_Delivered_Modal",
        payload: createDeliveredProductData,
      });
    }
    notify("success", intlPlaceholder("addDeliveredSuccessMessage"));
    history.goBack();
    dispatch({ type: "RESET_Delivered_LOCAL_PRODUCT" });
    dispatch({ type: "deliveredValueRest" });
  }
  if (createDeliveredProductError) {
    notify("error", intlPlaceholder(textMessage));
    dispatch({ type: "deliveredValueRest" });
  }

  return (
    <CForm>
      <div className="row mb-1">
        {/* store */}
        <div className="col-md-6">
          <Controller
            control={control}
            name="store"
            render={({ field: { onChange } }) => (
              <Select
                className={errors.store ? "is-invalid" : ""}
                isSearchable={true}
                styles={selectStyle}
                isDisabled={
                  localProducts && localProducts.length > 0 ? true : false
                }
                placeholder={intlPlaceholder("addDelivered.pleaseSelectStore")}
                onChange={(e) => {
                  setStoreId(e.value);
                  onChange(e.value);
                }}
                options={renderOptions(stores, "label", "key")}
              />
            )}
          />
          <div className="invalid-feedback">{errors.store?.message}</div>
        </div>
        <div className="col-md-6">
          <Controller
            control={control}
            name="searchInvoice"
            render={({ field: { onChange, onBlur, value } }) => (
              <CFormInput
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                readOnly={selectStoreId === null ? true : false}
                type="searchInvoice"
                onBlur={() => searchInvoices(value)}
                id="searchInvoice"
                placeholder={intlPlaceholder("addDelivered.searchProduct")}
                aria-describedby="exampleFormControlInputHelpInline"
              />
            )}
          />
        </div>
      </div>

      <div className="row mt-4 mb-1">
        <div className="col-md-12">
          <AddDeliveredList
            setDisableSaveBtn={setDisableSaveBtn}
            deliveredLocalProduct={deliveredLocalProduct}
            deliveredLocalProductSpin={deliveredLocalProductSpin}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_Delivered_LOCAL_PRODUCT",
              });
              reset();
              history.goBack();
            }}
          >
            <IntlMessages id="addSaleInvoice.cancel" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_Delivered_LOCAL_PRODUCT",
              });
            }}
          >
            <IntlMessages id="addSaleInvoice.reset" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CLoadingButton
            disabled={
              disableSaveBtn ||
              createDeliveredProductSpin ||
              (deliveredLocalProduct && deliveredLocalProduct.length === 0)
            }
            className="d-block w-100"
            onClick={() => onFinish()}
            color="primary"
            loading={createDeliveredProductSpin}
          >
            <span className="text-white">
              <IntlMessages id="addSaleInvoice.create" />
            </span>
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default DeliveredForm;
