import React, { useState, useEffect } from "react";
import { CFormInput, CFormSelect, CBadge } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner"; // Import the spinner
import moment from "moment";
import { ChangeNumberFormate } from "src/constants/CommonFn";

const ContractTable = ({ Contracts, contractSpinner }) => {
  const [details, setDetails] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    setFilteredContracts(Contracts);
  }, [Contracts]);

  const columns = [
    { label: <IntlMessages id="contract.buildingName" />, key: "buildingName", _style: { width: "15%" } },
    { label: <IntlMessages id="contract.unitNumber" />, key: "unitNumber", _style: { width: "10%" } },
    { label: <IntlMessages id="contract.client" />, key: "clientName", _style: { width: "15%" } },
    { label: <IntlMessages id="contract.phoneNumber" />, key: "phoneNumber", _style: { width: "15%" } },
    { label: <IntlMessages id="contract.monthlyRent" />, key: "monthlyRent", _style: { width: "10%" } },
    { label: <IntlMessages id="contract.currency" />, key: "currency", _style: { width: "10%" } },
    { label: <IntlMessages id="contract.startDate" />, key: "startDate", _style: { width: "10%" } },
    { label: <IntlMessages id="contract.endDate" />, key: "endDate", _style: { width: "10%" } },
    { label: <IntlMessages id="contract.status" />, key: "status", _style: { width: "5%" } },
  ];

  const transformData = (contracts) => {
    const groupedData = _.groupBy(contracts, "propertyName");
    return Object.keys(groupedData).map((building) => ({
      buildingName: building,
      units: groupedData[building].map((unit) => ({
        unitNumber: unit.pUnitNumber || "",
        clientName: unit.clientName,
        phoneNumber: unit.phoneNumber.join(", "),
        monthlyRent: unit.monthlyRent,
        currency: unit.currency,
        startDate: new Date(unit.startDate).toLocaleDateString(),
        endDate: new Date(unit.endDate).toLocaleDateString(),
        status: unit.status,
      })),
    }));
  };

  const formatData = (contracts) => {
    return transformData(contracts);
  };

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);
    const filtered = Contracts.filter((contract) =>
      Object.values(contract).some((val) => val && val.toString().toLowerCase().includes(value))
    );
    setFilteredContracts(filtered);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0); // Reset to the first page when items per page changes
  };

  const renderTableRows = (data) => {
    return data
      .map((item, buildingIndex) => {
        const unitRows = item.units.map((unit, unitIndex) => (
          <tr key={`${buildingIndex}-${unitIndex}`}>
            {unitIndex === 0 && (
              <td rowSpan={item.units.length} className="align-middle">
                <span>{item.buildingName}</span>
              </td>
            )}
            <td>{unit.unitNumber}</td>
            <td>{unit.clientName}</td>
            <td>{unit.phoneNumber}</td>
            <td className="d-flex justify-content-end"> {ChangeNumberFormate(unit.monthlyRent.toFixed(2))}</td>
            <td>{unit.currency}</td>
            <td>{moment(unit.startDate).format("DD/MM/YYYY")}</td>
            <td>{moment(unit.endDate).format("DD/MM/YYYY")}</td>
            <td>
              <CBadge
                  style={{
                    textTransform: "capitalize",
                  }}
                  color={
                    unit.status === "active"
                      ? "success"
                      : "danger"
                  }
                >
                  {" "}
                  <IntlMessages id={`contract.status.${unit.status}`} />
              </CBadge>
            </td>
          </tr>
        ));
        return unitRows;
      })
      .flat();
  };

  const offset = currentPage * itemsPerPage;
  const paginatedContracts = filteredContracts?.slice(offset, offset + itemsPerPage);

  return (
    <>
      <CFormInput
        type="text"
        placeholder="Search..."
        value={filter}
        onChange={handleFilterChange}
        className="mb-3"
        style={{ width: "250px" }}
      />
      {contractSpinner ? (
        <div className="text-center my-5 d-flex justify-content-center">
          <Oval
            height={80}
            width={80}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#321FDB"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
                {columns.map((col) => (
                  <th key={col.key} style={col._style}>
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderTableRows(formatData(paginatedContracts))}</tbody>
          </table>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <span className="me-2">Items per page:</span>
            <CFormSelect value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ width: "100px" }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CFormSelect>
          </div>
        </>
      )}
    </>
  );
};

export default ContractTable;
