import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  currencyExchangeLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  CurrencyExchangeList: [],
  fetchCurrencyInfo: false,
  FetchingCurrencyDetail: false,
  currencyExchangeId: null,
  cancelCurrencyExchangeSuccess: false,
  cancelCurrencyExchangeError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "cancelCurrencyExchange_SUCCESS": {
      return {
        ...state,
        cancelCurrencyExchangeSuccess: true,
        message: action.payload.message,
      };
    }

    case "cancelCurrencyExchange_FAILURE": {
      return {
        ...state,
        cancelCurrencyExchangeError: true,
        message: action.payload,
      };
    }

    case "": {
      return { ...state, FetchingCurrencyDetail: true };
    }
    case "getAllCurrencyExchange_SUCCESS": {
      return {
        ...state,
        CurrencyExchangeList: action.payload.Record,
        fetchCurrencyInfo: false,
      };
    }
    case "fetchingCurrencyExchange_START": {
      return { ...state, currencyExchangeSpinner: true };
    }
    case "fetchingCurrencyExchange_STOP": {
      return { ...state, currencyExchangeSpinner: false };
    }
    case "show_currencyExchange_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }

    case "currencyExchange_START": {
      return { ...state, currencyExchangeLoader: true };
    }
    case "currencyExchange_STOP": {
      return { ...state, currencyExchangeLoader: false };
    }
    case "currencyExchange_START": {
      return { ...state, currencyExchangeLoader: true };
    }
    case "currencyExchange_STOP": {
      return { ...state, currencyExchangeLoader: false };
    }
    case "addCurrencyExchange_SUCCESS": {
      return {
        ...state,
        currencyExchangeId: action.payload.Record.currencyExchangeId,
        success: true,
        message: "recordAddedSuccessfully",
        currencyExchangeLoader: false,
      };
    }
    case "addCurrencyExchange_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        currencyExchangeLoader: false,
        message: action.payload,
      };
    }

    case "editCurrency_SUCCESS": {
      let oldCat = state.CurrencyExchangeList;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        CurrencyExchangeList: newCat,
        currencyExchangeLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editCurrency_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        currencyExchangeLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "currencyExchange_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        currencyExchangeLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        cancelCurrencyExchangeSuccess: false,
        cancelCurrencyExchangeError: false,
      };
    }

    case "deleteCurrency_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.CurrencyExchangeList;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        CurrencyExchangeList: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    default:
      return state;
  }
};
