import React, { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { CFormCheck, CFormInput } from "@coreui/react-pro";
import NumberFormat from "react-number-format";
const AddSaleInvoiceList = ({
  setDisableSaveBtn,
  setShowPaymentInput,
  showPaymentInput,
  setInvoiceAmountValue,
  invoiceAmountValue,
  formType,
}) => {
  const { localProducts } = useSelector((state) => state.ProductR);
  const dispatch = useDispatch();

  let priceShouldBeNumeric = intlPlaceholder("priceShouldBeNumeric");
  let priceShouldBeBiggerThan1 = intlPlaceholder("priceShouldBeBiggerThan1");

  const deleteRecord = (record) => {
    dispatch({
      type: "deleteLocalProduct",
      payload: record,
    });
  };

  const renderTotalPrice = () => {
    let total = 0;
    if (localProducts && localProducts.length > 0) {
      localProducts.forEach((item) => {
        total = item.totalPrice + total;
      });
      total = ChangeNumberFormate(total.toFixed(2));
      return total;
    }
  };

  let columnsNew = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },
    {
      dataField: "unitName",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },

    {
      text: <IntlMessages id="product.retail" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <CFormCheck
            onChange={(e) => {
              dispatch({
                type: "updateLocalProduct",
                payload: {
                  fieldChanged: "retail",
                  updatedRow: { ...record, retail: e.target.checked },
                },
              });
            }}
          />
        );
      },
    },
    {
      dataField: "Qty",
      text: <IntlMessages id="product.quantity" />,
      editable: true,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        let qty = newValue;

        if (qty <= 0) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="negativeQuantity" />,
          };
        } else {
          setDisableSaveBtn(false);
        }

        let sockQty = row.sockQty;

        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="stock.numeric" />,
          };
        }
        // if (qty > sockQty) {
        //   setDisableSaveBtn(true);
        //   return {
        //     valid: false,
        //     message: <IntlMessages id="quantity" />,
        //   };
        // } else {
        //   setDisableSaveBtn(false);
        //   return true;
        // }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "sockQty",
      text: <IntlMessages id="product.sockQty" />,
      editable: false,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(3)}</span>;
      },
    },
    {
      dataField: "localPrice",
      text: <IntlMessages id="product.UnitPrice" />,
      editable: true,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue < 1) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeBiggerThan1,
          };
        } else {
          setDisableSaveBtn(false);
          return {
            valid: true,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "totalPrice",
      text: <IntlMessages id="product.TotalPrice" />,
      editable: false,
      formatter: (cell, record) => {
        return <span> {ChangeNumberFormate(parseFloat(cell).toFixed(2))}</span>;
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },
    },
  ];

  const setAmountValue = (val) => {
    setInvoiceAmountValue(val);
  };

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        data={localProducts}
        columns={columnsNew}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField;
            dispatch({
              type: "updateLocalProduct",
              payload: {
                fieldChanged: changedField,
                updatedRow: row,
                newValue: newValue,
              },
            });
          },
        })}
      />

      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tr>
          <td colspan="5" style={{ textAlign: "center" }}>
            <h5>Total</h5>
          </td>

          <td colspan="3" style={{ textAlign: "center" }}>
            <h5>{renderTotalPrice()}</h5>
          </td>
        </tr>
        {/* <br/>
        {formType !== "edit" && (
          <tr>
            <td colspan="5" style={{ textAlign: "left" }}>
              <div style={{ width: "250px" }}>
                <strong>
                <CFormCheck
                  onChange={() => {
                    setInvoiceAmountValue("");
                    setShowPaymentInput(!showPaymentInput);
                  }}
                  disabled
                  id="flexCheckDefault"
                  label={intlPlaceholder("addSaleInvoice.checkbox.label")}
                />
                </strong>
              </div>
            </td>

            <td colspan="3" style={{ textAlign: "left" }}>
              <NumberFormat
                className="form-control"
                disabled={!showPaymentInput}
                allowNegative={false}
                isAllowed={(values) => {
                  const { value, floatValue } = values;
                  if (
                    typeof floatValue === "undefined" ||
                    typeof value === "undefined"
                  ) {
                    return true;
                  }
                  if (value.charAt(0) === "0") {
                    if (value.charAt(1) && value.charAt(1) != ".") {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }}
                thousandSeparator={true}
                onValueChange={(v) => setAmountValue(v.value)}
                value={invoiceAmountValue}
                placeholder={intlPlaceholder("addSaleInvoice.price")}
              />
            </td>
          </tr>
          
        )}
        <br/> */}
      </table>
    </>
  );
};

export default AddSaleInvoiceList;
