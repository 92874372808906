import React, { useState } from "react";
import {
  CForm,
  CFormInput,
  CModalBody,
  CFormFloating,
  CFormLabel,
  CButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { notify } from "../../../../util/ShowToast";
const AddUserForm = ({ defaultValues, ButtonComponent }) => {
  const [phoneNumberList, setPhoneNumberList] = useState(
    defaultValues && defaultValues.phoneNumbers
      ? defaultValues.phoneNumbers
      : []
  );
  const [phoneNum, setPhoneNum] = useState("");
  const comProps = useSelector((state) => state.trustFund);
  const dispatch = useDispatch();
  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      <IntlMessages id="trust.addUserModal.name_message" />
    ),
    location: Yup.string().required(
      <IntlMessages id="trust.addUserModal.address_message" />
    ),
    email: Yup.string().required(<IntlMessages id="trust.email" />),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;
  const onFinish = (values) => {
    if (phoneNum && phoneNum.length > 0) {
      if (phoneNumberList && phoneNumberList.length > 0) {
        values["phoneNumbers"] = [...phoneNumberList, phoneNum];
      } else {
        values["phoneNumbers"] = [phoneNum];
      }
    } else {
      values["phoneNumbers"] = phoneNumberList;
    }
    if (comProps.selectedItem === null) {
      dispatch(
        DataRequestAction("POST", "addTrustUser", values, "", "trust", true)
      );
    } else {
      values._id = comProps.selectedItem._id;
      dispatch(
        DataRequestAction("PUT", "editTrustUser", values, "", "trust", false)
      );
    }
  };
  if (comProps.success) {
    let message = intlPlaceholder(comProps.message);
    notify("success", message);
    dispatch({
      type: "trust_UPDATE",
    });
  }
  if (comProps.error) {
    let message = intlPlaceholder(comProps.message);
    notify("error", message);
    dispatch({
      type: "trust_UPDATE",
    });
  }

  const setPhoneNumerInArray = () => {
    if (phoneNumberList.includes(phoneNum)) {
      setPhoneNum("");
    } else {
      setPhoneNumberList((oldData) => [`+${phoneNum}`, ...oldData]);
      setPhoneNum("");
    }
  };
  const onRemoveImageFormList = (index) => {
    setPhoneNumberList([
      ...phoneNumberList.slice(0, index),
      ...phoneNumberList.slice(index + 1, phoneNumberList.length),
    ]);
  };
  const renderPhoneList = () => {
    if (phoneNumberList && phoneNumberList.length > 0) {
      return phoneNumberList.map((item, index) => {
        if (item && item.length > 0) {
          return (
            <div
              className="mb-2"
              style={{ width: "fit-content", position: "relative" }}
            >
              <span
                style={{ background: "#C7C9C9", borderRadius: "6px" }}
                className="ps-2 pe-3 py-1"
              >
                {item}
              </span>
              <span
                onClick={() => onRemoveImageFormList(index)}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  cursor: "pointer",
                }}
              >
                ⛔
              </span>
            </div>
          );
        }
      });
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit(onFinish)}>
        <CModalBody>
          <div className="mb-3">
            <CFormFloating className="mb-3">
              <CFormInput
                className={errors.firstName ? "is-invalid" : ""}
                {...register("firstName")}
                placeholder={intlPlaceholder("trust.addUserModal.name")}
                type="text"
                id="firstName"
              />
              <CFormLabel htmlFor="firstName">
                {intlPlaceholder("trust.addUserModal.name")}
              </CFormLabel>
              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>
            </CFormFloating>
          </div>
          <div className="mb-3">
            <CFormFloating className="mb-3">
              <CFormInput
                className={errors.location ? "is-invalid" : ""}
                {...register("location")}
                placeholder={intlPlaceholder("trust.addUserModal.address")}
                type="text"
                id="location"
              />
              <CFormLabel htmlFor="location">
                {intlPlaceholder("trust.addUserModal.address")}
              </CFormLabel>
              <div className="invalid-feedback">{errors.location?.message}</div>
            </CFormFloating>
            <div className="invalid-feedback">{errors.location?.message}</div>
          </div>
          <div className="mb-3">
            <CFormFloating className="mb-3">
              <CFormInput
                className={errors.email ? "is-invalid" : ""}
                {...register("email")}
                placeholder={intlPlaceholder("trust.email")}
                type="text"
                id="email"
              />
              <CFormLabel htmlFor="email">
                {intlPlaceholder("trust.email")}
              </CFormLabel>
              <div className="invalid-feedback">{errors.email?.message}</div>
            </CFormFloating>
          </div>
          <div className="d-flex align-items-center gap-3 mb-3">
            <span style={{ width: "100%" }}>
              <Controller
                control={control}
                name={"phoneNumbers"}
                render={(props) => {
                  return (
                    <PhoneInput
                      country={"gn"}
                      value={phoneNum}
                      onChange={(v) => {
                        if (v && v.length > 8) {
                          setPhoneNum(v);
                        }
                        props.field.onChange(v);
                      }}
                    />
                  );
                }}
              />
            </span>
            <CButton
              disabled={!(phoneNum && phoneNum.length > 7)}
              size="sm"
              color="primary"
              className="py-1"
              onClick={() => setPhoneNumerInArray()}
            >
              Add
            </CButton>
          </div>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {renderPhoneList()}
          </div>
        </CModalBody>
        {ButtonComponent}
      </CForm>
    </>
  );
};
export default AddUserForm;
