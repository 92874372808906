import { CModal, CModalHeader, CModalTitle, CButton, CFooter } from "@coreui/react-pro";
import ViewTrustDetail from '../../views/trustBank/trustUserBalanceSheet/components/modalView'

const Modal = ({
    title = "",
    size = "lg",
    visible = false,
    onDismiss,
    id
}) => {
    return (
        <CModal size={size} visible={visible}>
            <CModalHeader
                onDismiss={() => {
                    onDismiss()
                }}
            >
                <CModalTitle>{title}</CModalTitle>
            </CModalHeader>
            <ViewTrustDetail _id={id} />
            <div style={{
                float: 'right',
                textAlign: "right",
                paddingRight: 20,
                paddingBottom: 20
            }}>
                <CButton onClick={() => onDismiss()} className={"bg-primary"} >Close</CButton>
            </div>
        </CModal >
    )
};

export default Modal