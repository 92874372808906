import React from "react";
import { useSelector, useDispatch } from "react-redux";
import IntlMessages from "../../../util/IntlMessages";
import DocsExample from "../../../components/DocsExample";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
const UnitListLocal = ({selectedUnitName}) => {
  const dispatch = useDispatch();

  const unitName = useSelector((state) => state.unitReducer.selectedUnitName);
  const SubUnit = useSelector((state) => state.unitReducer.SubUnits);



  const deleteRecord = (record) => {
    dispatch({
      type: "deleteSubUnit_SUCCESS",
      payload: record,
    });
  };

  const columns = [
    {
      label:  <IntlMessages id="unit.subUnitName" />,
      key: "subUnitName",
    },
    {
      label: <IntlMessages id="unit.factor" />,
      key: "factor",
    },
    {
        label: <IntlMessages id="unit.formula" />,
      key:  "formula",
      sorter : false
     
    },
    {
      key: "actions",
      sorter : false,
      label: "",
    },
  ];

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <strong>
              <IntlMessages id="unit.addUnitPage.conversionFormula" />
            </strong>
          </CCardHeader>
          <CCardBody>
            <DocsExample href="components/smart-table/">
              <CSmartTable
                sorterValue={{ column: "subUnitName", state: "asc" }}
                clickableRows
                activePage={3}
                items={SubUnit || []}
                columns={columns}
                columnSorter
                scopedColumns={{
                  actions: (record) => {
                    return (
                      <td>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={() => deleteRecord(record)}
                        >
                          <span className="icon icon-trash"></span>
                        </span>
                      </td>
                    );
                  },
                  formula : (record)=>{
                      return <td>{unitName || selectedUnitName}= {record.subUnitName}{record.operation === "divide" ? "/" : "x"}{record.factor}</td>
                  }
                }}
              />
            </DocsExample>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default UnitListLocal;
