import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment'
const Filter = () => {
  const dispatch = useDispatch();
  const [SelectedType, setSelectedType] = useState();
  const receiptProps = useSelector((state) => state.receiptReducer);
  const comProps = useSelector((state) => state.ceReducer);
  const { CompanyAccount } = receiptProps;
  const { createdBy } = comProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(<IntlMessages id="dateRequired" />),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(), new Date().toISOString()], // Default value here
    },
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const typeWatch = useWatch({
    control,
    name: "type",
  });
  const onFinish = (values) => {
    values.startDate = values.date[0];
    values.endDate = values.date[1];

    dispatch(
      DataGetAction("getAllExpense", values, "fetchingBankBalanceSheet")
    );
  };

  const fetchAllBankAndCurrencyList = () => {
    dispatch(DataGetAction("getAllBank", { query: "all" }));
  };

  useEffect(fetchAllBankAndCurrencyList, []);



  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );
  };
  return (
   
      <CForm className="d-flex flex-wrap" style={{gap : 16}}  onSubmit={handleSubmit(onFinish)}>
        <div className="">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
                initialSettings={{
                  startDate: moment(),
                  endDate: moment(),
                }}
                value={value}
                onApply={handleApply}
                onCallback={(start, end) => {
                  onChange([start.toISOString(), end.toISOString()]);
                }}
              >
                <input type="text" className="form-control col-4" />
              </DateRangePicker>
            )}
          />
        </div>
        {/* <div className="">
          <div className="">
            <CFormSelect {...register("status")}>
              <option value="" hidden>
                {intlPlaceholder("ce.filter.selectStatus")}
              </option>
              <option value="all">All</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
            </CFormSelect>
            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div> */}
        {/* <div className="">
          <div className="">
            <CFormSelect {...register("createdBy")}>
              <option value="" hidden>
                {intlPlaceholder("ce.filter.selectCreatedBy")}
              </option>
              <option value="all">All</option>
              {createdBy &&
                createdBy.map((Item) => (
                  <option value={Item._id}>{Item.name}</option>
                ))}
            </CFormSelect>
            <div className="invalid-feedback">{errors.createdBy?.message}</div>
          </div>
        </div> */}

        {/* <div className="">
          <div className="">
            <CFormSelect {...register("type")}>
              <option value="" hidden>
                {intlPlaceholder("ce.filter.selectType")}
              </option>

              <option value="all">All</option>
              <option value="cash">Cash</option>
              <option value="bank">Bank</option>
            </CFormSelect>
            <div className="invalid-feedback">{errors.type?.message}</div>
          </div>
        </div>

        {typeWatch === "bank" && (
          <div className=" mt-3">
            <div className="">
              <CFormSelect {...register("bank")}>
                <option value="" hidden>
                  {intlPlaceholder("ce.filter.selectBank")}
                </option>

                {CompanyAccount &&
                  CompanyAccount.map((Item) => (
                    <option value={Item._id}>
                      {Item.bankName}({Item.number})
                    </option>
                  ))}
              </CFormSelect>
              <div className="invalid-feedback">{errors.bank?.message}</div>
            </div>
          </div>
        )} */}

        <div className={`${typeWatch === "bank" ? "mt-3" : "mt-0"}`}>
          <div className="">
            <CLoadingButton
              className="d-flex align-items-center"
              type="submit"
              color="primary"
            >
              <i className="gx-icon-filter-300 me-1 text-white"></i>
              <IntlMessages id="generateButton" />
            </CLoadingButton>
          </div>
        </div>
      </CForm>
   
  );
};

export default Filter;
