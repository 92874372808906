import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import moment from "moment";
import NumberFormat from "react-number-format";
import { selectStyle } from "../../../constants/CommonFn";
const AddModal = () => {
  const dispatch = useDispatch();
  const modalType = "showWithDrawModal";
  const [amount, setAmount] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [transType, setTransactionType] = useState();

  const validationSchema = Yup.object().shape({
    storeId: Yup.string().required(
      <IntlMessages id="store.addStoreModal.name_message" />
    ),
    paymentMethod: Yup.string().required(
      <IntlMessages id="storeWithDraw.paymentMethod.message" />
    ),

    bank: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="storeWithDraw.bank.message" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    transactionType: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="storeWithDraw.transactionType.message" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    chequeOrTransactionNo: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        `${
          transType === "online"
            ? intlPlaceholder("transactionRequired")
            : intlPlaceholder("chequeRequired")
        }`
      ),
      otherwise: Yup.string().notRequired(),
    }),

    // chequeDate: Yup.string().when(["paymentMethod", "transactionType"], {
    //   is: ([paymentMethod, transactionType]) => {
    //     return paymentMethod === "bank" && transactionType !== "online";
    //   },
    //   then: Yup.string().required(
    //     <IntlMessages id="storeWithDraw.chequeDate.message" />
    //   ),
    //   otherwise: Yup.string().notRequired(),
    // }),

    amount: Yup.string().required(
      <IntlMessages id="store.addStoreModal.address_message" />
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;


  const paymentMethodWatch = useWatch({
    control,
    name: "paymentMethod",
    defaultValue: "cash", // default value before the render
  });

  const transactionTypeWatch = useWatch({
    control,
    name: "transactionType",
    defaultValue: "online", // default value before the render
  });

  // get functions to build form with useForm() hook

  const comProps = useSelector((state) => state.storeReducer);
  const { Stores } = comProps;

  const resProps = useSelector((state) => state.receiptReducer);
  const { CompanyAccount } = resProps;

  const onCreate = (values) => {

    dispatch(
      DataRequestAction(
        "POST",
        "storeWithdraw",
        values,
        "",
        "withdrawing",
        false
      )
    );
  };

  if (comProps.withDrawSuccess) {
    notify("success", intlPlaceholder("withdrawProcessSuccessfull"));
    reset();
    dispatch({
      type: "store_UPDATE",
    });
  }

  const fetchCompanyBank = (e) => {
    if (comProps.ShowWithDrawModal) {
      dispatch(DataGetAction("fetchCompanyBank", ""));
      dispatch(DataGetAction("getAllStore", { query: "all" }));
    }
    reset({
      chequeDate: new Date().toISOString(),
    });
  };

  useEffect(fetchCompanyBank, [comProps.ShowWithDrawModal]);

  const showWithDrawModal = () => {
    dispatch({
      type: "showWithDrawModal",
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };


  return (
    <div>
      <CButton
        color="primary"
        className="d-flex align-items-center"
        onClick={() => showWithDrawModal(null)}
      >
        <i className="gx-icon-money-withdrawal-1 me-2"></i>
        <IntlMessages id="store.add.store.buttonWithdraw" />
      </CButton>

      <CModal visible={comProps.ShowWithDrawModal} size="lg">
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("store.WithdrawTitle")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
          <CModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="storeId"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <>
                        <Select
                          styles={selectStyle}
                          className={errors.storeId ? "is-invalid" : ""}
                          isSearchable={true}
                          placeholder={intlPlaceholder("selectStore")}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={renderOptions([...Stores], "name", "_id")}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.storeId?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormSelect
                        className={errors.paymentMethod ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder("choosePaymentMethod")}
                        </option>
                        <option value="cash">{intlPlaceholder("cash")}</option>
                        {CompanyAccount && CompanyAccount.length > 0 && (
                          <option value="bank">
                            {intlPlaceholder("bankTransfer")}
                          </option>
                        )}
                      </CFormSelect>
                    )}
                  />
                  <CFormLabel htmlFor="">
                    {intlPlaceholder("choosePaymentMethod")}
                  </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.paymentMethod?.message}
                  </div>
                </CFormFloating>
              </div>

              {paymentMethodWatch === "bank" && (
                <>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="bank"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={errors.bank ? "is-invalid" : ""}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder("chooseBank")}
                            </option>
                            {CompanyAccount &&
                              CompanyAccount.map((Item) => (
                                <option value={Item._id}>
                                  {Item.bankName}({Item.number})
                                </option>
                              ))}
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                        {intlPlaceholder("chooseBank")}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.bank?.message}
                      </div>
                    </CFormFloating>
                  </div>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="transactionType"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.transactionType ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              setTransactionType(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder("transactionType")}
                            </option>
                            <option value="online">
                              {intlPlaceholder("Online")}
                            </option>
                            <option value="cheque">
                              {intlPlaceholder("Cheque")}
                            </option>
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                        {intlPlaceholder("transactionType")}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.transactionType?.message}
                      </div>
                    </CFormFloating>
                  </div>
                  <div className="com-md-12">
                    <CFormFloating className="mb-3">
                      <CFormInput
                        className={
                          errors.chequeOrTransactionNo ? "is-invalid" : ""
                        }
                        {...register("chequeOrTransactionNo")}
                        placeholder={`${
                          transactionTypeWatch === "online"
                            ? intlPlaceholder("transactionNumber")
                            : intlPlaceholder("chequeNumber")
                        }`}
                        type="text"
                        id="chequeOrTransactionNo"
                      />
                      <CFormLabel htmlFor="">
                        {intlPlaceholder(
                          `${
                            transactionTypeWatch === "online"
                            ? intlPlaceholder("transactionNumber")
                            : intlPlaceholder("chequeNumber")
                          }`
                        )}
                      </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.chequeOrTransactionNo?.message}
                      </div>
                    </CFormFloating>
                  </div>
                  {transactionTypeWatch !== "online" && (
                    <div className="col-md-12">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="chequeDate"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <DatePicker
                              value={moment(value).format("YYYY-MM-DD")}
                              className={errors.chequeDate ? "is-invalid" : ""}
                              selected={selectedDate}
                              format="dd/mm/yyyy"
                              onChange={(date) => {
                                setSelectedDate(date);
                                onChange(date.toISOString());
                              }}
                              wrapperClassName="custom-date-picker"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="col-md-12">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.amount ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder("amount")}
                      />
                    )}
                    name={"amount"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amount?.message}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      className={errors.remark ? "is-invalid" : ""}
                      {...register("remark")}
                      placeholder={intlPlaceholder("Remark")}
                      type="text"
                      id="remark"
                    />

                    <CFormLabel htmlFor="remark">
                      {intlPlaceholder("Remark")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.remark?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              {<IntlMessages id="store.addStoreModal.cancel" />}
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.WithDrawLoader}
              disabled={comProps.WithDrawLoader}
            >
              {intlPlaceholder("store.addStoreModal.withDrawButton")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddModal;
