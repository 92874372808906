import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import AccountList from "./components/AccountList";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import CreditBalance from "./components/CreditBalance";
import CheckUserAccess from "../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { notify } from "src/util/ShowToast";
import BulkImportAccount from "./components/BulkImportAccount"
const Index = () => {
  const dispatch = useDispatch();
  const modalType = "show_productStock_modal";

  const comProps = useSelector((state) => state.accountReducer);
  const { accountBulkUpload_success,accountBulkUpload_error, textMessage } = comProps;

  const fetchProducts = () => {
    dispatch(
      DataGetAction("getAllAccount", { query: "all" }, "accountSpinner")
    );
  };
  useEffect(fetchProducts, [comProps.fetchAccountInfo]);

  const create = CheckUserAccess("account", ["create"]);

  if (accountBulkUpload_success) {
    let messageLng = intlPlaceholder(textMessage);
    fetchProducts();
    notify("success", messageLng);
    dispatch({
      type: "account_UPDATE",
    });
  }
  if (accountBulkUpload_error) {
    let messageLng = intlPlaceholder(textMessage);
    notify("error", messageLng);
    dispatch({
      type: "account_UPDATE",
    });
  }
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
              <IntlMessages id="account.title" />
              </h1>
          
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex" style={{gap : 10}}>
                {create && <AddModal />}
                <BulkImportAccount/>  
              </div>
              <DocsExample href="components/smart-table/">
                <AccountList
                  Data={comProps.Accounts}
                  Spinner={comProps.accountSpinner}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>

        <EditModal />
        <CreditBalance />
      </CRow>
    </>
  );
};

export default Index;
