import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormSelect,
} from "@coreui/react-pro";
import Select from "react-select";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ProductForm from "./distributeForm";
import { notify } from "../../../util/ShowToast";
import {selectStyle} from '../../../constants/CommonFn'
const Index = () => {
  const componentRef = useRef();
  const salStoreStock = useSelector((state) => state.saleStoreStock);
  const dispatch = useDispatch();
  const history = useHistory();

  const [customerData, setCustomerData] = useState([]);
  const [fetchingCustomer, setFetchingCustomer] = useState(false);
  const [customerValue, setCustomerValue] = useState();



  const fetchCustomer = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };

    fetch(
      `${process.env.REACT_APP_ROOT_URL}/getAllCustomer/${JSON.stringify({
        query: "all",
      })}`,
      { headers }
    )
      .then((response) => response.json())
      .then((body) => {
        const data =
          body.Record &&
          body.Record.length > 0 &&
          body.Record.map((Item) => ({
            text: `${Item.name}`,
            value: Item._id,
          }));
        setCustomerData(data);
      });
  };

  useEffect(fetchCustomer, []);

  const handleChangeCustomer = (value) => {
    setCustomerValue(value);
  };

  if (salStoreStock.invoiceCreatedSuccess) {
    let messageLng = intlPlaceholder(salStoreStock.message)
    notify("success", messageLng);
    // message.success(salStoreStock.message);
    dispatch({
      type: "updateState",
    });
    dispatch({
      type: "distributed_UPDATE",
    });
    history.push({
      pathname: "/viewStoreInvoice",
      state: {
        InvoiceNo: salStoreStock.invoiceNumber,
      },
    });
  }
  if (salStoreStock.invoiceCreatedError) {

    let messageLng = intlPlaceholder(salStoreStock.message)
    notify("error", messageLng);
    // message.error(salStoreStock.message);
    dispatch({
      type: "updateState",
    });
  }
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
    else{
      return []
    }
  };
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="invoice.createInvoicePage.storeSale" />
              </h1>
            </CCardHeader>

            <CCardBody>
              <div className="row">
                <div className="col-md-6">
                  <h2>
                    <IntlMessages id="invoice.createInvoicePage.createInvoice" />
                  </h2>
                </div>
                <div className="col-md-6">
                  <p className="d-flex align-items-center">
                    <span
                      className="fw-bold mr-2 float-left"
                      style={{
                        width: "120px",
                      }}
                    >
                      <IntlMessages id="invoice.createInvoicePage.date" />:
                    </span>
                    {moment().format("MM-DD-YYYY HH:MM")}
                  </p>

                  <div className="d-flex align-items-center ">
                    <label
                      style={{
                        width: "120px",
                        fontWeight: "bold",
                      }}
                      htmlFor=""
                    >
                      <IntlMessages id="invoice.createInvoicePage.customer" />:
                    </label>
                    <div className="w-100">
                      <Select
                      styles={selectStyle}
                        isSearchable={true}
                        placeholder={intlPlaceholder(
                          "invoice.createInvoicePage.customer"
                        )}
                        onChange={(e) => {
                          handleChangeCustomer(e.value);
                        }}
                        options={renderOptions(
                          customerData && customerData.length > 0 && [...customerData],
                          "text",
                          "value"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <ProductForm customerValue={customerValue && customerValue} />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
