import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import DistributeProductList from "./DistributeProductList";
import {
  CFormInput,
  CForm,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import AsyncSelect from "react-select/async";
const DistributeForm = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(1);
  const [rebateValue, setRebateValue] = useState(0);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [defaultPriceValue, setDefaultPriceValue] = useState();
  const [productIds, selectProductIds] = useState([]);
  const disProps = useSelector((state) => state.distributeReducer);
  const { Price, distributeLoader } = disProps;
  const history = useHistory();
  const productProps = useSelector((state) => state.ProductR);
  const { localProducts, ProductList, distributeDropSpin } = productProps;
  const storeProps = useSelector((state) => state.storeReducer);
  const { Stores } = storeProps;
  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="distributeProducts.pleaseSelectStore_message" />
    ),
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      ),
    // unit: Yup.string().required(
    //   <IntlMessages id="distributeProducts.pleaseSelectUnit_message" />
    // ),

    // Qty: Yup.number()
    // .typeError( intlPlaceholder("distributeProducts.qty_message"))
    // .required(
    //   intlPlaceholder("invoice.createInvoicePage.qty_message"))
    // .min(0.1,
    //   intlPlaceholder("invoice.createInvoicePage.qtyGreaterThenZero_message")
    // )
    // .max(Qty,
    //   intlPlaceholder("invoice.createInvoicePage.qtyGreaterStock_message")
    // ),

    // Price: Yup.string().required(
    //   <IntlMessages id="distributeProducts.unitPrice_message" />
    // ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

  const changeUnitPrice = () => {
    setDefaultPriceValue(Price);
  };
  useEffect(changeUnitPrice, [Price]);

  const onFinish = () => {
    const storeValue = getValues("store");
    if (rebateValue < 0) {
      return;
    }
    if (localProducts.length > 0 && storeValue) {
      let updatedProducts = localProducts.map((item) => {
        return {
          Price: item.localPrice,
          Qty: item.Qty,
          product: item._id,
          productName: item.name,
          store: storeValue,
          unit: item.unit,
          unitName: item.unitName,
          retail: item.retail,
          subunitName: item.subunitName,
          subunit: item.subunit,
          packaging: item.packaging,
        };
      });
      const data = {
        storeId: storeValue,
        products: updatedProducts,
        rebate: rebateValue,
        originalProduct: ProductList
      };

      reset();
      dispatch(
        DataRequestAction(
          "POST",
          "distribute",
          data,
          "",
          "distributeProduct",
          false
        )
      );

      dispatch({
        type: "ViewTheProduct",
      });
    } else {
      notify("error", intlPlaceholder("noProductSelected"));
    }
  };

  if (disProps.success) {
    let messageText = intlPlaceholder("distribute.product.successfully");
    // message.success(messageText);
    notify("success", messageText);
    dispatch({
      type: "distributed_UPDATE",
    });
    console.log("===== disProps ====", disProps);
    history.push({
      pathname: "/viewStoreInvoice",
      state: { InvoiceNo: disProps.InvoiceNo, prefix: "FC" },
    });
  }

  if (disProps.error) {
    let messageText = intlPlaceholder("distribute.product.error");
    notify("error", messageText);
    dispatch({
      type: "product_update_error",
    });
  }

  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getAllProductStock",
        { query: "qty" },
        "getAllProductStockSpin"
      )
    );
    dispatch({
      type: "goBack_initialState",
    });
    dispatch({
      type: "RESET_LOCAL_PRODUCT",
    });
    return () => {
      dispatch({
        type: "goBack_initialState",
      });
      dispatch({
        type: "RESET_LOCAL_PRODUCT",
      });
    };
  };

  useEffect(fetchList, []);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setLocalProduct",
      payload: values,
    });
  };

  const changeThePriceAndQty = (e) => {
    dispatch({
      type: "ChangeThePriceAndQty_SUCCESS",
      payload: e,
    });
  };

  if (disProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });

    //setValue("unit", undefined);
    // setValue("product", undefined);
  }

  const onPreview = () => {
    dispatch({
      type: "ViewTheProduct",
      payload: getValues("store"),
    });
  };

  const renderDefaultValue = () => {
    if (defaultPriceValue && defaultPriceValue) {
      if (Number.isInteger(defaultPriceValue)) {
        return defaultPriceValue;
      } else {
        return defaultPriceValue.toFixed(2);
      }
    }
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length === 2 || inputValue.length === 0) {
      dispatch({ type: "CleanStore_SUCCESS" });
    }

    if (!inputValue.trim() || Stores.length > 0) {
      let data = Stores.filter((x) =>
        x.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const formattedOptions = data.map((option) => ({
        value: option._id,
        label: option.name,
      }));
      callback(formattedOptions);
      return;
    }
    try {
      let requestStoreId = "";
      if (
        localStorage.getItem("selectedCompanyStore") &&
        JSON.parse(localStorage.getItem("swithStoreToCompany"))
      ) {
        const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
        if (obj && obj.value !== "null") {
          requestStoreId = obj.value;
        }
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        requestStoreId: requestStoreId,
      };
      if (inputValue && inputValue.length >= 3) {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}/findStore/${JSON.stringify({
            query: inputValue,
          })}`,
          { headers }
        );
        const data = await response.json();

        dispatch({
          type: "findStore_SUCCESS",
          payload: { Record: data.Record },
        });
        const formattedOptions = data.Record.map((option) => ({
          value: option._id,
          label: option.name,
        }));
        callback(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
      <div className="row mb-4">
        {/* store */}
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <AsyncSelect
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  loadOptions={loadOptions}
                  styles={selectStyle}
                  isDisabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder(
                    "distributeProducts.pleaseSelectStore"
                  )}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                />
              )}
            />

            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div>
        {/* product */}
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="product"
              key={key}
              //defaultValue={[]}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  isLoading={distributeDropSpin}
                  disabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                  onChange={(values) => {
                    let selectedIds = values.map((item) => item.value);
                    selectProductIds(selectedIds);

                    onChange(selectedIds);
                  }}
                  options={
                    !distributeDropSpin
                      ? renderOptions(ProductList, "name", "_id")
                      : []
                  }
                />
              )}
            />

            <div className="error-message">{errors.product?.message}</div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        {/* unit */}
        {/* <div className="col-md-3">
          <div className="mb-3">
            <Controller
              control={control}
              name="unit"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <CFormSelect
                  className={errors.unit ? "is-invalid" : ""}
                  value={value}
                  onChange={(e) => {
                    changeThePriceAndQty(e.target.value);
                    onChange(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    {intlPlaceholder("distributeProducts.pleaseSelectUnit")}
                  </option>
                  {units &&
                    units.map((Item) => {
                      if (Item.subUnits) {
                        return (
                          <>
                            <option value={Item._id}> {Item.name}</option>
                            {Item.subUnits.map((x) => (
                              <option value={x._id}> {x.subUnitName}</option>
                            ))}
                          </>
                        );
                      } else {
                        return <option value={Item._id}> {Item.name}</option>;
                      }
                    })}
                </CFormSelect>
              )}
            />

            <div className="invalid-feedback">{errors.unit?.message}</div>
          </div>
        </div> */}

        {/* Qty */}
        {/* <div className="col-md-3">
          <div className="mb-3">
            <CInputGroup>
            <Controller
              control={control}
              name="Qty"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <CFormInput
                onChange={(e)=>{
                  
                    onChange(e.target.value)
                }}
                value={!isNaN(value) ? value : undefined}
                className={errors.Qty ? "is-invalid" : ""}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                placeholder={intlPlaceholder("distributeProducts.qty")}
              />
              )}
            />


              <CInputGroupText id="basic-addon2">{`/${
               Qty
              }`}</CInputGroupText>
            </CInputGroup>
            <div className="error-message">{errors.Qty?.message}</div>
          </div>
        </div> */}

        {/* price */}
        {/* <div className="col-md-3">
          <div className="mb-3 number-format-input">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <NumberFormat
                  className={errors.Price ? "is-invalid" : ""}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(v) => onChange(v.value)}
                  value={value}
                  defaultValue={renderDefaultValue(defaultPriceValue)}
                  placeholder={intlPlaceholder(
                    "invoice.createInvoicePage.unitPrice"
                  )}
                />
              )}
              name={"Price"}
              control={control}
            />
            <div className="invalid-feedback">{errors.Price?.message}</div>
          </div>
        </div> */}

        {
          <div className="col-md-12">
            <CButton
              disabled={productIds && productIds.length > 0 ? false : true}
              className="d-block w-100"
              type="submit"
            >
              <i className="icon icon-add me-1"></i>
              <IntlMessages id="distributeProducts.add" />
            </CButton>
          </div>
        }
      </div>

      <div className="row">
        <div className="col-md-12">
          <DistributeProductList
            setValue={setValue}
            setRebateValue={setRebateValue}
            rebateValue={rebateValue}
            reset={setValue}
            setDisableSaveBtn={setDisableSaveBtn}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "product_UPDATE",
              });
              reset();
              history.push("/stock");
            }}
          >
            <IntlMessages id="distributeProducts.cancel" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_LOCAL_PRODUCT",
              });
            }}
          >
            <IntlMessages id="distributeProducts.reset" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CLoadingButton
            disabled={disableSaveBtn || distributeLoader}
            className="d-block w-100"
            onClick={() => onFinish()}
            color="primary"
            loading={distributeLoader}
          >
            <span className="text-white">
              <IntlMessages id="distributeProducts.create" />
            </span>
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default DistributeForm;
