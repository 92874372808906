import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import _ from "lodash";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import moment from "moment";
const ReceiptForm = ({ modalType }) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.receiptReducer);
  const history = useHistory();

  const { Supplier, Currency, totalDueByCompany, CompanyAccount } = comProps;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [PayType, setPayType] = useState("online");
  const [supplierSelected, selectedSupplier] = useState("");
  const [PaymentMethod, setPaymentMethod] = useState("cash");
  const [CurrencyDefault, setCurrencyDefault] = useState();

  const validationSchema = Yup.object().shape({
    referenceNumber: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.refNo_message" />
    ),
    supplier: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.pleaseSelectSupplier_message" />
    ),
    currency: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.chooseCurreny_message" />
    ),
    totalDueByCompany: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.amountDue_message" />
    ),
    paymentMethod: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.choosePaymentMethod_message" />
    ),

    bank: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receipt.generateReceiptModal.generateReceipt" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    transactionType: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receipt.generateReceiptModal.generateReceipt" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    chequeOrTransactionNo: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        `${
          PayType === "online"
            ? intlPlaceholder("transactionRequired")
            : intlPlaceholder("chequeRequired")
        }`
      ),
      otherwise: Yup.string().notRequired(),
    }),

    // chequeDate: Yup.string().when(["paymentMethod", "transactionType"], {
    //   is: ([paymentMethod, transactionType]) => {
    //     return paymentMethod === "bank" && transactionType !== "online";
    //   },
    //   then: Yup.string().required(
    //     <IntlMessages id="storeWithDraw.chequeDate.message" />
    //   ),
    //   otherwise: Yup.string().notRequired(),
    // }),

    paid: Yup.string().required(
      <IntlMessages id="receipt.generateReceiptModal.amountPaid_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      chequeDate: new Date().toISOString(),
    },
  };

  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  // if (initialValues !== null) {
  //     form.setFieldsValue(initialValues)
  // } else {
  //     form.resetFields(["referenceNumber", "supplier", "currency", "totalDueByCompany", "paymentMethod", "bank", "paid", ""]);
  // }

  const getCompanyDue = (e) => {
    setPaymentMethod("cash");
    setPayType("online");
    setValue("paymentMethod", "");
    setValue("bank", "");
    setValue("transactionType", "");
    setValue("chequeOrTransactionNo", "");
    dispatch(
      DataGetAction(
        "getCompanyDues",
        { supplierId: supplierSelected, currencyId: e },
        "CompanyDue"
      )
    );
    dispatch(DataGetAction("getCompanyAccounts", { currencyId: e }));
  };

  const changeAmount = () => {
    setValue("totalDueByCompany", totalDueByCompany);
  };
  useEffect(changeAmount, [totalDueByCompany]);

  useEffect(() => {
    if(CurrencyDefault) {
      getCompanyDue(CurrencyDefault);
      reset({ currency: CurrencyDefault, supplier: supplierSelected, totalDueByCompany: totalDueByCompany})
    }
  }, [CurrencyDefault])
  
  const checkTheRefNoExit = (e) => {
    dispatch(
      DataRequestAction(
        "POST",
        "checkSupplierRefNo",
        { referenceNo: e },
        "",
        "checkSupplierRefNo",
        false
      )
    );
  };

  const onCreate = (values) => {
    values.paymentMode = { mode: values.paymentMethod };
    if (values.bankName) {
      values.paymentMode.bankName = values.bankName;
    }

    dispatch(
      DataRequestAction(
        "POST",
        "createSupplierReceipts",
        values,
        "",
        "receipt",
        false
      )
    );
  };

  let noSufficientBalance = intlPlaceholder("noSufficientBalance");

  const onFinish = (values) => {

    if (values.paymentMethod === "bank") {
      const selectedBank = _.filter(
        CompanyAccount,
        (x) => x._id === values.bank
      );
      // if (parseInt(selectedBank[0].balance) >= parseInt(values.paid)) {
        values.bankName = selectedBank[0].bankName;
        values.accountBalanceId = selectedBank[0].balanceId;
        reset();
        onCreate(values);
      // } else {
      //   notify("error", noSufficientBalance);
      // }
    } else {
      reset();
      onCreate(values);
    }
  };
  const receiptCreatedSuccessfully = intlPlaceholder(
    "receiptCreatedSuccessfully"
  );
  if (comProps.companyReceiptSuccess) {
    notify("success", receiptCreatedSuccessfully);
    // message.success(comProps.message);
    dispatch({
      type: "receipt_UPDATE",
    });
    history.push({
      pathname: "/duesReceipt",
      state: { ReceiptNo: comProps.receiptNo },
    });
  }

  const fetchStore = () => {
    if (comProps.modalVisible) {
      dispatch(DataGetAction("getReceiptDropDown", ""));
    }
  };
  useEffect(fetchStore, [comProps.modalVisible]);

  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  return (
    <div>
      <CButton
        color="success"
        className="text-white"
        onClick={() => showModal(null)}
      >
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="receipt.add.receipt.button" />
      </CButton>

      <CModal visible={comProps.modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            <IntlMessages id="receipt.generateReceiptModal.generateReceipt" />
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="row">
              {/* referenceNumber */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      className={errors.referenceNumber ? "is-invalid" : ""}
                      {...register("referenceNumber")}
                      placeholder={intlPlaceholder("RefNumber")}
                      type="text"
                    />
                    <CFormLabel htmlFor="email">
                      {intlPlaceholder("RefNumber")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.referenceNumber?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>

              {/* supplier */}
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="supplier"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          className={errors.supplier ? "is-invalid" : ""}
                          isSearchable={true}
                          styles={selectStyle}
                          placeholder={intlPlaceholder(
                            "SupplierProductFilter.selectSupplier"
                          )}
                          onChange={(e) => {                            
                            selectedSupplier(e.value);

                            const supplierHasCurrency = Supplier.filter((item) => item._id === e.value);
                            const currencyDefault = supplierHasCurrency && supplierHasCurrency[0].currencyId;
                            setCurrencyDefault(currencyDefault);

                            onChange(e.value);
                          }}
                          options={renderOptions([...Supplier], "label", "key")}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.supplier?.message}
                  </div>
                </div>
              </div>

              {/* currency */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <Controller
                      control={control}
                      name="currency"
                      render={({ field: { onChange, value } }) => (
                        <CFormSelect
                          className={errors.currency ? "is-invalid" : ""}
                          value={value}
                          //disabled={supplierSelected === "" ? true : false}
                          disabled={true}
                          onChange={(e) => {
                            getCompanyDue(e.target.value);
                            onChange(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {intlPlaceholder("chooseCurrency")}
                          </option>
                          {Currency &&
                            Currency.map((Item) => (
                              <option value={Item.key}>{Item.label}</option>
                            ))}
                        </CFormSelect>
                      )}
                    />
                    <CFormLabel htmlFor="">
                      {intlPlaceholder("chooseCurrency")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.currency?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>

              {/* totalDueByCompany */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      className={errors.totalDueByCompany ? "is-invalid" : ""}
                      {...register("totalDueByCompany")}
                      placeholder={intlPlaceholder("amountDue")}
                      disabled={true}
                      type="text"
                      // defaultValue={totalDueByCompany}
                    />

                    <CFormLabel htmlFor="">
                      {intlPlaceholder("amountDue")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.totalDueByCompany?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>

              {/* payment method */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                    <Controller
                      control={control}
                      name="paymentMethod"
                      render={({ field: { onChange, value } }) => (
                        <CFormSelect
                          className={errors.paymentMethod ? "is-invalid" : ""}
                          value={value}
                          onChange={(e) => {
                            setPaymentMethod(e.target.value);
                            onChange(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {intlPlaceholder("choosePaymentMethod")}
                          </option>
                          <option value="cash">
                            {intlPlaceholder("cash")}
                          </option>
                          {CompanyAccount && CompanyAccount.length > 0 && (
                            <option value="bank">
                              {intlPlaceholder("bankTransfer")}
                            </option>
                          )}
                        </CFormSelect>
                      )}
                    />
                    <CFormLabel htmlFor="">
                      {intlPlaceholder("choosePaymentMethod")}
                    </CFormLabel>
                    <div className="invalid-feedback">
                      {errors.paymentMethod?.message}
                    </div>
                  </CFormFloating>
                </div>
              </div>

              {PaymentMethod === "bank" && (
                <>
                  {/* bank */}
                  <div className="col-md-12">
                    <div className="mb-3">
                      <CFormFloating className="mb-3">
                        <Controller
                          control={control}
                          name="bank"
                          render={({ field: { onChange, value } }) => (
                            <CFormSelect
                              className={errors.bank ? "is-invalid" : ""}
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            >
                              <option value="" hidden>
                                {intlPlaceholder("chooseBank")}
                              </option>

                              {CompanyAccount &&
                                CompanyAccount.map((Item) => (
                                  <option value={Item._id}>
                                    {Item.bankName}({Item.number})
                                  </option>
                                ))}
                            </CFormSelect>
                          )}
                        />
                        <CFormLabel htmlFor="">
                          {intlPlaceholder("chooseBank")}
                        </CFormLabel>
                        <div className="invalid-feedback">
                          {errors.bank?.message}
                        </div>
                      </CFormFloating>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <CFormFloating className="mb-3">
                        <Controller
                          control={control}
                          name="transactionType"
                          render={({ field: { onChange, value } }) => (
                            <CFormSelect
                              className={
                                errors.transactionType ? "is-invalid" : ""
                              }
                              value={value}
                              onChange={(e) => {
                                setPayType(e.target.value);
                                onChange(e.target.value);
                              }}
                            >
                              <option value="" hidden>
                                {intlPlaceholder("transactionType")}
                              </option>
                              <option value="online">
                                {intlPlaceholder("Online")}
                              </option>
                              <option value="cheque">
                                {intlPlaceholder("Cheque")}
                              </option>
                            </CFormSelect>
                          )}
                        />
                        <CFormLabel htmlFor="email">
                          {intlPlaceholder("transactionType")}
                        </CFormLabel>
                        <div className="invalid-feedback">
                          {errors.transactionType?.message}
                        </div>
                      </CFormFloating>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          className={
                            errors.chequeOrTransactionNo ? "is-invalid" : ""
                          }
                          {...register("chequeOrTransactionNo")}
                          placeholder={`${PayType === "online" ? intlPlaceholder("transactionNumber") :intlPlaceholder("chequeNumber")}}`}
                          type="text"
                        />
                        <CFormLabel htmlFor="">
                          {intlPlaceholder(
                            `${
                              PayType === "online"
                                ? intlPlaceholder("transactionNumber")
                                : intlPlaceholder("chequeNumber")
                            }`
                          )}
                        </CFormLabel>
                        <div className="invalid-feedback">
                          {errors.chequeOrTransactionNo?.message}
                        </div>
                      </CFormFloating>
                    </div>
                  </div>

                  {PayType !== "online" && (
                    <div className="col-md-12">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="chequeDate"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <DatePicker
                              value={moment(value).format("YYYY-MM-DD")}
                              selected={selectedDate}
                              format="dd/mm/yyyy"
                              onChange={(date) => {
                                setSelectedDate(date);
                                onChange(date.toISOString());
                              }}
                              wrapperClassName="custom-date-picker"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* paid */}
              <div className="col-md-12">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.paid ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder("enterAmount")}
                      />
                    )}
                    name={"paid"}
                    control={control}
                  />
                  <div className="invalid-feedback">{errors.paid?.message}</div>
                </div>
              </div>


              <div className="mb-3">
                  <CFormTextarea
                  rows="4"
                    className={errors.remark ? "is-invalid" : ""}
                    {...register("remark")}
                    placeholder={intlPlaceholder(
                      "currencyExchange.exchangeModal.remark_placeholder"
                    )}
                    type="text"
                    id="name"
                  />
                  <div className="invalid-feedback">
                    {errors.remark?.message}
                  </div>
                </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="receipt.generateReceiptModal.generateReceipt.cancelButton" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.receiptLoader}
              disabled={comProps.receiptLoader}
            >
              {intlPlaceholder("admin.addAdminModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default ReceiptForm;
