import React, { useState } from "react";
import {
  
  CButton,
  CCardBody,
  CCollapse,
  CBadge,
  
} from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import DeleteModal from '../../../../components/Modals/DeleteModal'
import ResetPasswordModal from './ResetPasswordModal'
import CheckUserAccess from "../../../../util/checkUserAccess";
const UsersList = ({ Data ,Spinner}) => {


  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const modalType = "show_bank_modal";
  const edit = CheckUserAccess('manageUsers',['edit']) 
  const deleted = CheckUserAccess('manageUsers',['delete']) 
  const resetPasswordPermission = CheckUserAccess('manageUsers',['resetPassword']) 

  const columns = [
    {
      label: <IntlMessages id="user.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="user.email" />,
      key: "email",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="user.phone" />,
      key: "phoneNumber",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="user.role" />,
      key: "roleName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="user.status" />,
      key: "status",
      _style: { width: "40%" },
    },
  ];

  const found =CheckUserAccess('manageUsers',['edit','delete','resetPassword']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  
  const editRecord = (data) => {
    dispatch({
      type: 'edit_user_modal',
      payload: data,
    });
  };
  const getBadge = (status) => {
    switch (status) {
      case "invited":
        return "warning";
      case "active":
        return "success";
      default:
        return "primary";
    }
  };



  const resetPassword = (data) => {
    dispatch({
      type : 'show_password_modal',
      payload : data
    });
  };


  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={Spinner}
        items={Spinner?[]:Data && Data.length > 0 ? Data : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          status: (record) => {
            return (
              <td>
                <span>

                    <CBadge color={getBadge(record.status)}>
                      <IntlMessages id={record.status} />
                    </CBadge>
                 
                </span>
              </td>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
         
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  
                  <div className="d-flex">
                {edit &&  <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                    <span className="icon icon-edit me-2"></span>
                    <IntlMessages id="Edit" />
                    </span>
                    
                  </CButton>}

                  {deleted &&   <DeleteModal
                      contentLangueId="user.deleteModal.message"
                      type="deleteUser"
                      selectedItem={record}
                      ButtonTitleId="Delete"
                      titleLanguageId="Delete"
                      size="sm"
                    />}
                  
              {resetPasswordPermission &&    <CButton
                    size="sm"
                    color="success"
                    className="me-3"
                    onClick={() => resetPassword(record._id)}
                  >
                    <span style={{ color: "white" }}>
                    <span className="icon icon-edit me-2"></span>
                    Reset password
                    </span>
                    
                  </CButton>}
                  </div>
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
     <ResetPasswordModal/>
    </>
  );
};

export default UsersList;
