import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormCheck,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
const UserRolePermissionModal = ({ Permission_Modal }) => {
  const dispatch = useDispatch();
  const userProps = useSelector((state) => state.users);
  const { userPermissionModalVisible, userRoleData } = userProps;


  const renderValues = (arr) => {
    if(arr && arr.length > 0){
      return arr.map((item) => {
        return (
          <div >
            <CFormCheck defaultChecked disabled />
            <span
              className="mx-3 text-capitalize d-inline-block"
              style={{ width: "50px" }}
            >
              {item}
            </span>
          </div>
        );
      });
    }
    else{
      return '----'
    }

  };

  const renderPermissionAccess = () => {
    let arr = [];
    let permissions = userRoleData?.permissionAccess;
    if (userRoleData && permissions) {
      for (const key in permissions) {
        arr.push(
          <div className="d-flex mb-3">
            <span style={{ width: "220px", display: "inline-block" }}>
              <IntlMessages id={key} />
            </span>
            {renderValues(permissions[key])}
          </div>
        );
      }
    }

    return arr;
  };

  return (
    <>
      <CModal visible={userPermissionModalVisible} size="lg">
        <CModalHeader
          onDismiss={() =>
            dispatch({
              type: "User_Permission_Modal",
              payload: null,
            })
          }
        >
          <CModalTitle>
            {/* <IntlMessages id="inviteCompany.permissionDetails" /> */}
            user permissions
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <div className="row mt-3">{renderPermissionAccess()}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() =>
              dispatch({
                type: "User_Permission_Modal",
                payload: null,
              })
            }
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default UserRolePermissionModal;
