import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import QuickSaleProductList from "./QuickSaleProductList";
import { CForm, CButton, CLoadingButton, CSpinner } from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const QuickListForm = ({ _id }) => {
  const dispatch = useDispatch();

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const [productIds, selectProductIds] = useState([]);
  const quickProps = useSelector((state) => state.quickSaleReducer);
  const { addSpinner, editSuccess, UpdateSpinner } = quickProps;
  const history = useHistory();
  const location = useLocation();
  const productProps = useSelector((state) => state.ProductR);
  const {
    localProducts,
    ProductList,
    editSpinner,
    initialValue,
    originalLocalProduct,
  } = productProps;
  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      ),
  });

  if (editSuccess) {
    dispatch({
      type: "reset_edit",
    });
    dispatch({
      type: "Close_Edit_Modal",
    });
    dispatch(
      DataGetAction(
        "getSale",
        {
          query: "all",
          startDate: moment().toString(),
          endDate: moment().toString(),
        },
        "SaleSpinner"
      )
    );
  }

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValue,
    mode: "onChange",
  };

  const { handleSubmit, reset, formState, control, setValue, getValues } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = () => {
    if (localProducts.length > 0) {
      let updatedProducts = localProducts.map((item) => {
        return {
          Price: item.localPrice,
          Qty: item.Qty,
          product: item._id,
          productName: item.name,
          unit: item.unit,
          unitName: item.unitName,
          retail: item.retail,
          subunitName: item.subunitName,
          subunit: item.subunit,
          packaging: item.packaging,
        };
      });

      const data = {
        quickSaleId: originalLocalProduct[0].saleId,
        products: updatedProducts,
      };

      dispatch(
        DataRequestAction(
          "PUT",
          "editSale",
          data,
          "",
          "StartEditedSpinner",
          false
        )
      );
      reset();

      dispatch({
        type: "ViewTheProduct",
      });
    } else {
      notify("error", intlPlaceholder("noProductSelected"));
    }
  };

  if (quickProps.success) {
    let messageText = intlPlaceholder("distribute.product.successfully");
    // message.success(messageText);
    notify("success", messageText);
    dispatch({
      type: "distributed_UPDATE",
    });
    history.push({
      pathname: "/viewStoreInvoice",
      state: { InvoiceNo: disProps.InvoiceNo },
    });
  }

  if (quickProps.error) {
    let messageText = intlPlaceholder("distribute.product.error");
    notify("error", messageText);
    dispatch({
      type: "product_update_error",
    });
  }

  const fetchList = () => {
    dispatch(
      DataGetAction("getAllProductStock", {
        query: "qty",
        stockQty: "search",
        editId: _id,
      })
    );
    dispatch(
      DataGetAction(
        "getEditProduct",
        {
          query: "all",
          _id: _id,
        },
        "EditRecordSpinner"
      )
    );
    // if (localProducts && localProducts.length > 0) {
    //   let selectedId = [];
    //   localProducts.map((x) => {
    //     selectedId.push(x.productId);
    //   });
    //   selectProductIds(selectedId)
    //   setValue("product", selectedId);
    // }
  };

  useEffect(fetchList, []);

  const setInitialValue = () => {
    if (
      initialValue &&
      initialValue.product &&
      initialValue.product.length > 0
    ) {
      setValue("product", initialValue.product);
    }
  };

  useEffect(setInitialValue, [initialValue]);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setLocalProduct",
      payload: values,
    });
  };

  if (quickProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      let arrayTest = data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
      return arrayTest;
    }
  };

  const renderData = () => {
    if (editSpinner) {
      return <CSpinner />;
    } else {
      return (
        <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="mb-3">
                <Controller
                  control={control}
                  name="product"
                  key={1}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Select
                      styles={selectStyle}
                      className={errors.store ? "is-invalid" : ""}
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      defaultValue={renderOptions(localProducts, "name", "_id")}
                      isMulti={true}
                      disabled={
                        localProducts && localProducts.length > 0 ? true : false
                      }
                      placeholder={intlPlaceholder(
                        "product.addProductPage.choseProduct"
                      )}
                      onChange={(values) => {
                        let selectedIds = values.map((item) => item.value);
                        selectProductIds(selectedIds);

                        onChange(selectedIds);
                      }}
                      options={renderOptions(ProductList, "name", "_id")}
                    />
                  )}
                />

                <div className="error-message">{errors.product?.message}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <CButton className="d-block w-100" type="submit">
                <i className="icon icon-add me-1"></i>
                <IntlMessages id="distributeProducts.add" />
              </CButton>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <QuickSaleProductList
                setValue={setValue}
                reset={setValue}
                quantityTitle={"stockQuantityAllow"}
                setDisableSaveBtn={setDisableSaveBtn}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <CButton
                className="d-block w-100 btn btn-light btn-block"
                color="dark"
                variant="outline"
                onClick={() => {
                  dispatch({
                    type: "Close_Edit_Modal",
                  });
                }}
              >
                <IntlMessages id="distributeProducts.cancel" />
              </CButton>
            </div>
            <div className="col-md-4">
              <CLoadingButton
                disabled={disableSaveBtn}
                className="d-block w-100"
                onClick={() => onFinish()}
                color="primary"
                loading={UpdateSpinner}
              >
                <span className="text-white">
                  <IntlMessages id="quickSale.modal.Update" />
                </span>
              </CLoadingButton>
            </div>
          </div>
        </CForm>
      );
    }
  };

  return renderData();
};

export default QuickListForm;
