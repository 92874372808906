import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import InvoiceReceiptDetail from "../storeToStore/components/InvoiceReceiptDetail";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const CreditNotesViewDetail = () => {
  const dispatch = useDispatch();
  const invProps = useSelector(state => state.storeToStoreReducer);
  const helperProps = useSelector((state) => state.helperReducer);
  const creditData = helperProps.data;

  const title = intlPlaceholder("stockTransfer.creditNotes.viewTitle")
  const closeForm = () => {
    dispatch({ type: "closeViewDialog" });
  };

  const fetchInvoiceInfo = () => {
    if(creditData){
        dispatch(DataGetAction('getStoreInVoiceDetail', { InvoiceNo: creditData.invoiceNo, prefix: creditData.prefix, invoiceType:"StoreToStoreRecipt" }, 'fetchingInvoice'))
        dispatch(DataGetAction('getStoreDue', { InvoiceNo: creditData.invoiceNo, prefix: creditData.prefix, invoiceType:"StoreToStoreRecipt" }, ))
    }
  };

  useEffect(fetchInvoiceInfo, [helperProps.showViewDialog]);

  return (
    <>
      <CModal size="lg" visible={helperProps.showViewDialog}>
        <CModalHeader
          onDismiss={() => {
            closeForm();
          }}
        >
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CForm>
          <CModalBody>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    {invProps.invoiceLoader ? <CSpinner /> : <InvoiceReceiptDetail />}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                closeForm();
              }}
            >
              {intlPlaceholder("CancelButtonTitle")}
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  );
};

export default CreditNotesViewDetail;
