import React from "react";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CLoadingButton } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import ContractForm from "./ContractForm";

const AddContractModal = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.contractReducer);
  const { contractLoader, modalVisible } = comProps;

  const modalType = "show_contract_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="contract.add.button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}>
          <CModalTitle>{intlPlaceholder("contract.modal.addContract")}</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <ContractForm
            defaultValues={null}
            ButtonComponent={
              <CModalFooter>
                <CButton color="secondary" onClick={() => dispatch(DataEntryModalAction(modalType, null))}>
                  <IntlMessages id="admin.addAdminModal.cancel" />
                </CButton>

                <CLoadingButton type="submit" color="primary" loading={contractLoader} disabled={contractLoader}>
                  {intlPlaceholder("contract.addContractModal.save")}
                </CLoadingButton>
              </CModalFooter>
            }
          />
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddContractModal;
