import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
import moment from "moment";
const ReceiptForm = ({ modalType }) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.receiptReducer);
  const location = useLocation();
  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const { Store, CompanyAccount, totalAmountDue } = comProps;
  const [PayType, setPayType] = useState("online");
  const [PaymentMethod, setPaymentMethod] = useState("cash");

  const validationSchema = Yup.object().shape({
    store: Yup.string().required(intlPlaceholder("storeRequired")),
    totalAmountDue: Yup.string().required(intlPlaceholder("amountDueRequired")),
    paymentMethod: Yup.string().required(
      intlPlaceholder("paymentMethodRequired")
    ),

    bank: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receiptForm.bankRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    transactionType: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        <IntlMessages id="receiptForm.transactionTypeRequired" />
      ),
      otherwise: Yup.string().notRequired(),
    }),

    chequeOrTransactionNo: Yup.string().when("paymentMethod", {
      is: (val) => val === "bank",
      then: Yup.string().required(
        `${
          PayType === "online"
            ? intlPlaceholder("transactionRequired")
            : intlPlaceholder("chequeRequired")
        }}`
      ),
      otherwise: Yup.string().notRequired(),
    }),

    // chequeDate: Yup.string().when(["paymentMethod", "transactionType"], {
    //   is: ([paymentMethod, transactionType]) => {
    //     return paymentMethod === "bank" && transactionType !== "online";
    //   },
    //   then: Yup.string().required(intlPlaceholder("checkDateRequired")),
    //   otherwise: Yup.string().notRequired(),
    // }),

    amountReceived: Yup.string().required(intlPlaceholder("amountRequired")),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues  : {
      chequeDate : new Date().toISOString()
    }
  };
  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  // if (initialValues !== null) {
  //     form.setFieldsValue(initialValues)
  // } else {
  //     form.setFieldsValue({ name: "", address: "" })
  // }

  const fetchCompanyBank = () => {
    if (comProps.modalVisible) {
      dispatch(DataGetAction("fetchCompanyBank", ""));
    }
  };

  useEffect(fetchCompanyBank, [comProps.modalVisible]);

  const getStoreDue = (e) => {
    dispatch(DataGetAction("getStoreDue", { _id: e }));
  };

  if (
    (totalAmountDue !== null || totalAmountDue !== undefined) &&
    comProps.modalVisible
  ) {
    setValue("totalAmountDue", totalAmountDue);
  }

  const onCreate = (values) => {
    values.paymentMode = { mode: values.paymentMethod };
    if (values.bankName) {
      values.paymentMode.bankName = values.bankName;
    }

    values.receiptType = "storeSale";
    if (location.pathname === "/companyReceipt") {
      values.receiptType = "companySale";
    }
    dispatch(
      DataRequestAction("POST", "addReceipt", values, "", "receipt", false)
    );
  };

  const onFinish = (values) => {
    if (values.paymentMethod === "bank") {
      const selectedBank = _.filter(
        CompanyAccount,
        (x) => x._id === values.bank
      );
      values.bankName = selectedBank[0].bankName;
      values.accountBalanceId = selectedBank[0].balanceId;
    }
    reset();
    setValue("totalAmountDue", 0);
    setValue("store", "");
    onCreate(values);
  };

  if (comProps.success) {
    notify("success", intlPlaceholder("success"));
    dispatch({
      type: "receipt_UPDATE",
    });
    history.push({
      pathname: "/viewReceipt",
      state: { ReceiptNo: comProps.receiptNo },
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message)
    notify("error", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
    
  }

  const fetchStore = () => {
    if (comProps.modalVisible) {
      dispatch(DataGetAction("getReceiptDropDown", ""));
    }
  };
  useEffect(fetchStore, [comProps.modalVisible]);
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  return (
    <div>
      <CButton
        color="success"
        className="text-white"
        onClick={() => showModal(null)}
      >
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="receipt.add.receipt.button" />
      </CButton>

      <CModal visible={comProps.modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>{intlPlaceholder("GenerateReceiptLabel")}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="row">
              {/* store */}
              <div className="col-md-12">
                <div className="mb-3">
                  
                  <Controller
                    control={control}
                    name="store"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                        styles={selectStyle}
                          className={errors.store ? "is-invalid" : ""}
                          isSearchable={true}
                          placeholder={intlPlaceholder("selectStore")}
                          onChange={(e) => {
                            getStoreDue(e.value);
                            onChange(e.value);
                          }}
                          options={renderOptions([...Store], "label", "key")}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.store?.message}
                  </div>
                </div>
              </div>

              {/* totalAmountDue */}
              <div className="col-md-12">
                <div className="mb-3">
                  <CFormInput
                    className={errors.totalAmountDue ? "is-invalid" : ""}
                    {...register("totalAmountDue")}
                    placeholder={intlPlaceholder("amountDue")}
                    type="text"
                    disabled
                  />
                  <div className="invalid-feedback">
                    {errors.totalAmountDue?.message}
                  </div>
                </div>
              </div>

              {/* payment method */}
              <div className="col-md-12">
                <div className="mb-3">
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({ field: { onChange, value } }) => (
                      <CFormSelect
                        className={errors.paymentMethod ? "is-invalid" : ""}
                        value={value}
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                          onChange(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          {intlPlaceholder("choosePaymentMethod")}
                        </option>
                        <option value="cash">{intlPlaceholder("cash")}</option>
                        {CompanyAccount && CompanyAccount.length > 0 && (
                          <option value="bank">
                            {intlPlaceholder("bankTransfer")}
                          </option>
                        )}
                      </CFormSelect>
                    )}
                  />
<CFormLabel htmlFor="">
            {intlPlaceholder("choosePaymentMethod")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.paymentMethod?.message}</div>
        </CFormFloating>

                  
                </div>
              </div>

              {PaymentMethod === "bank" && (
                <>
                  {/* bank */}
                  <div className="col-md-12">
                    <div className="mb-3">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="bank"
                        render={({ field: { onChange, value } }) => (
                          <CFormSelect
                            className={errors.bank ? "is-invalid" : ""}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder("chooseBank")}
                            </option>

                            {CompanyAccount &&
                              CompanyAccount.map((Item) => (
                                <option value={Item._id}>
                                  {Item.bankName}({Item.number})
                                </option>
                              ))}
                          </CFormSelect>
                        )}
                      />
<CFormLabel htmlFor="">
            {intlPlaceholder("chooseBank")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.bank?.message}</div>
        </CFormFloating>
                      
                    </div>
                  </div>

                  {/* transactionType */}
                  <div className="col-md-12">
                    <div className="mb-3">
                    <CFormFloating className="mb-3">

                      <Controller
                        control={control}
                        name="transactionType"
                        render={({ field: { onChange, value } }) => (
                          <CFormSelect
                            className={
                              errors.transactionType ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              setPayType(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder("transactionType")}
                            </option>
                            <option value="online">
                              {intlPlaceholder("Online")}
                            </option>
                            <option value="cheque">
                              {intlPlaceholder("Cheque")}
                            </option>
                          </CFormSelect>
                        )}
                      />
<CFormLabel htmlFor="">
            {intlPlaceholder("transactionType")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.transactionType?.message}</div>
        </CFormFloating>
                    
                    </div>
                  </div>

                  {/* chequeOrTransactionNo */}
                  <div className="col-md-12">
                    <div className="mb-3">
                    <CFormFloating className="mb-3">
                      <CFormInput
                        className={
                          errors.chequeOrTransactionNo ? "is-invalid" : ""
                        }
                        {...register("chequeOrTransactionNo")}
                        placeholder={`${
                          PayType === "online"
                            ? intlPlaceholder("transactionNumber") 
                            :intlPlaceholder("chequeNumber")
                        }`}
                        type="text"
                      />
                      <CFormLabel htmlFor="">
            {intlPlaceholder(`${
                          PayType === "online"
                          ? intlPlaceholder("transactionNumber") 
                          :intlPlaceholder("chequeNumber")
                        }`)}
          </CFormLabel>
          <div className="invalid-feedback">{errors.chequeOrTransactionNo?.message}</div>
        </CFormFloating>
                     
                    </div>
                  </div>

                  {PayType !== "online" && (
                    <div className="col-md-12">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="chequeDate"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <DatePicker
                            value={moment(value).format('YYYY-MM-DD')}
                            selected={selectedDate}
                              format="dd/mm/yyyy"
                              onChange={(date) =>{
                                setSelectedDate(date) 
                                onChange(date.toISOString())
                                }}
                              wrapperClassName="custom-date-picker"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* amountReceived */}
              <div className="col-md-12">
                <div className="mb-3 number-format-input">
               

                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.paid ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder("amountRequired")}
                      />
                    )}
                    name={"amountReceived"}
                    control={control}
                  />
                  
                  <div className="invalid-feedback">
                    {errors.amountReceived?.message}
                  </div>
                </div>
              </div>

              {/* remark */}
              <div className="col-md-12">
                <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.remark ? "is-invalid" : ""}
                    {...register("remark")}
                    placeholder={intlPlaceholder("Remark")}
                    type="text"
                  />
                  <CFormLabel htmlFor="text">
                  {intlPlaceholder("Remark")}
                </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.remark?.message}
                  </div>
                </CFormFloating>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="receipt.generateReceiptModal.generateReceipt.cancelButton" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={comProps.receiptLoader}
              disabled={comProps.receiptLoader}
            >
              {intlPlaceholder("admin.addAdminModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default ReceiptForm;
