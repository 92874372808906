import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CLoadingButton,
  CButton,
  CForm,
  CFormSelect,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import DistributeProductList from "./DistributeProductList";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import {notify} from '../../../util/ShowToast'
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
const DistributeForm = ({ customerValue }) => {

  const [data, setData] = useState([]);
  const [defaultPriceValue, setDefaultPriceValue] = useState();
  const [fetching, setFetching] = useState(false);
  const [supplierData, setSupplierData] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const dispatch = useDispatch();
  const disProps = useSelector((state) => state.distributeReducer);
  const salProps = useSelector((state) => state.saleStoreStock);
  const { units, products, Qty, Price, ProductsLocal, selectedUnit,fetchProductsStoreStockDistributeSpinner } = disProps;
  const { creatingInvoiceLoader } = salProps;
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="invoice.createInvoicePage.pleaseSelectStore_message" />
    ),
    product: Yup.string().required(
      <IntlMessages id="invoice.createInvoicePage.pleaseSelectProduct_message" />
    ),
    unit: Yup.string().required(
      <IntlMessages id="invoice.createInvoicePage.pleaseSelectUnit_message" />
    ),
    Qty: Yup.string().required(
      <IntlMessages id="invoice.createInvoicePage.qty_message" />
    ),

    // Qty: Yup.number()
    // .typeError( intlPlaceholder("distributeProducts.qty_message"))
    // .required(
    //   intlPlaceholder("invoice.createInvoicePage.qty_message"))
    // .min(0.1,
    //   intlPlaceholder("invoice.createInvoicePage.qtyGreaterThenZero_message")
    // )
    // .max(Qty,
    //   intlPlaceholder("invoice.createInvoicePage.qtyGreaterStock_message")
    // ),
    Price: Yup.string().required(
      <IntlMessages id="invoice.createInvoicePage.unitPrice_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const { register, handleSubmit, reset, formState, control, setValue,getValues } =
    useForm(formOptions);
  const { errors } = formState;
 

  const onFinish = () => {

    const storeValue = getValues('store')
  
    
    if (ProductsLocal && ProductsLocal.length > 0 && storeValue) {
        let SubTotal = 0;
        ProductsLocal.map((x) => {
          SubTotal = parseFloat(x.Price) * parseFloat(x.Qty) + SubTotal;
          return x;
        });

        const data = {
          customerId: customerValue,
          storeId: storeValue,
          products: ProductsLocal,
          discount: 0,
          subTotal: SubTotal,
        };
         
        setDefaultPriceValue();
        reset()
       
        dispatch(
          DataRequestAction(
            "POST",
            "storeSaleInvoice",
            data,
            "",
            "creatingStoreSaleInvoice",
            false
          )
        );
        dispatch({
          type: "ViewTheProduct",
        });
      } 
      else{
          notify('error', intlPlaceholder('productNotSelected'))
      }

  };

  useEffect(() => {

      setDefaultPriceValue(Price);
      setValue("Price", Price > 0 ? Price : '');

  }, [Price]);

  if (disProps.success) {
    notify('success',intlPlaceholder("distribute.product.successfully"));
    // message.success("Record distribute Successfully");
    dispatch({
      type: "distributed_UPDATE",
    });
    history.push("/productSale");
  }

  if (disProps.error) {
    notify('error',intlPlaceholder("Stock.add.error"));
    // message.error("Some Error Occur while adding the Inventory");
    dispatch({
      type: "product_update_error",
    });
  }

  const fetchList = () => {
    return () => {
      dispatch({
        type: "product_UPDATE",
      });
    };
  };

  useEffect(fetchList, []);

  const addProductInLocalArray = (values) => {
    const result = _.filter(products, (x) => x._id === values.product)[0]
    const productName = result.productName
    const productId = result.productId
    let unitName = _.filter(units, (x) => x._id === values.unit);
    if (unitName && unitName.length) {
      unitName = unitName[0].label;
    } else {
      const subUnit = _.filter(units[0].subUnits, (x) => x._id === values.unit);
      if (subUnit && subUnit.length) {
        unitName = subUnit[0].subUnitName;
      }
    }
    values.productName = productName;
    values.product = productId;

    values.unitName = unitName;
 
    dispatch({
      type: "AddInLocalProduct_SUCCESS",
      payload: values,
    });
    // reset();
      setDefaultPriceValue();
      setValue('product',null)
      setValue('unit','')
      setValue('Qty','')
      setValue("Price", '');
  };

  const fetchProductBySupplierId = (e) => {
    dispatch(
      DataGetAction("fetchProductsStoreStockDistribute", { storeId: e },'fetchProductsStoreStockDistribute')
    );
  };
  const selectTheUnitOfProduct = (e) => {
    const selectedProduct = products.filter((Item) => Item._id === e); 
    dispatch({
      type: "SelectUnitOfProduct_SUCCESS",
      payload: selectedProduct[0],
    });
  };

  const changeThePriceAndQty = (e) => {
    dispatch({
      type: "ChangeThePriceAndQty_SUCCESS",
      payload: e,
    });
  };

  if (disProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
    setValue("unit", "");
    setValue("product", undefined);
  }

  const fetchStore = (value) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    fetch(
      `${process.env.REACT_APP_ROOT_URL}/getAllStore/${JSON.stringify({
        query: "all",
      })}`,
      { headers }
    )
      .then((response) => response.json())
      .then((body) => {
        const data =
          body.Record &&
          body.Record.length > 0 &&
          body.Record.map((Item) => ({
            text: `${Item.name}`,
            value: Item._id,
          }));
        setData(data);
      });
  };

  useEffect(fetchStore, []);

  const handleChange = (data) => {
    fetchProductBySupplierId(data);
    // setStoreId(data)
  };

  if (selectedUnit) {
    setValue("unit", selectedUnit.unit);
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
    else{
      return []
    }
  };


  return (
    <div>
      <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
        <div className="row mb-4">
          {/* store */}
          <div className="col-md-6">
            <div className="mb-3">
              <Controller
                control={control}
                name="store"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <>
               
                  <Select
                  styles={selectStyle}
                 className={errors.store ? "is-invalid" : ""}
                 isDisabled={
                  ProductsLocal && ProductsLocal.length > 0 ? true : false
                }
                  isSearchable={true}
                  placeholder={intlPlaceholder("invoice.createInvoicePage.pleaseSelectStore")}
                  
                  onChange={(e) => {
                    handleChange(e.value);
                    onChange(e.value);
                  }}
                 
                  options={renderOptions(
                    data && data.length&& [...data], 
                    "text", 
                    "value")}
                />
                  </>
                )}
              />

              <div className="invalid-feedback">{errors.store?.message}</div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* product */}
          <div className="col-md-3">
            <div className="">
              <Controller
                control={control}
                name="product"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <>
                

                  <Select
                 className={errors.product ? "is-invalid" : ""}
                 styles={selectStyle}
                 isLoading={fetchProductsStoreStockDistributeSpinner}
                  isSearchable={true}
                  placeholder=  {intlPlaceholder("invoice.createInvoicePage.pleaseSelectProduct")}
                  onChange={(e) => {
                    selectTheUnitOfProduct(e.value);
                    onChange(e.value);
                  }}
                 
                  options={renderOptions([...products], "productName", "_id")}
                />
                  </>
                )}
              />

              <div className="invalid-feedback">{errors.product?.message}</div>
            </div>
          </div>

          {/* unit */}
          <div className="col-md-3">
            <div className="">
              <Controller
                control={control}
                name="unit"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <CFormSelect
                    className={errors.product ? "is-invalid" : ""}
                    value={value}
                    // defaultValue={[{ key: selectedUnit.unit }]}
                    onChange={(e) => {
                      changeThePriceAndQty(e.target.value);
                      onChange(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      {intlPlaceholder(
                        "invoice.createInvoicePage.pleaseSelectUnit"
                      )}
                    </option>
                    {units &&
                      units.map((Item) => {
                        if (Item.subUnits) {
                          return (
                            <>
                              <option value={Item.key}> {Item.label}</option>
                              {Item.subUnits.map((x) => (
                                <option value={x._id}> {x.subUnitName}</option>
                              ))}
                            </>
                          );
                        } else {
                          return (
                            <option value={Item.key}> {Item.label}</option>
                          );
                        }
                      })}
                  </CFormSelect>
                )}
              />

              <div className="invalid-feedback">{errors.unit?.message}</div>
            </div>
          </div>
          {/* qty */}
          <div className="col-md-2">
            <CInputGroup>
              <CFormInput
                {...register("Qty")}
                className={errors.Qty ? "is-invalid" : ""}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                placeholder={intlPlaceholder("invoice.createInvoicePage.qty")}
              />
              {/* <CInputGroupText id="basic-addon2">{`/${Qty}`}</CInputGroupText> */}
            </CInputGroup>
            <div className="error-message">{errors.Qty?.message}</div>
          </div>
          <div className="col-md-2">
            <div className="mb-3 number-format-input">
              <Controller
                render={({field: { onChange, onBlur, value, name, ref },fieldState: { invalid, isTouched, isDirty, error },formState,}) =>{
                  return (
                  <NumberFormat
                    className={invalid ? "is-invalid" : ""}
                    allowNegative={false}
                    thousandSeparator={true}
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    //defaultValue={defaultPriceValue}
                    suffix={`/${defaultPriceValue}`}
                    placeholder={intlPlaceholder(
                      "invoice.createInvoicePage.unitPrice"
                    )}
                  />
                )}}
                name={"Price"}
                control={control}
              />
              <div className="invalid-feedback">{errors.Price?.message}</div>
            </div>
          </div>
          <div className="col-md-2">
            <CButton className="d-block w-100" type="submit">
            <i className="icon icon-add me-1"></i>
              <IntlMessages id="invoice.createInvoicePage.add" />
            </CButton>
          </div>
        </div>

        {/* DistributeProductList */}
        <div className="row mb-4">
          <div className="col-md-12">
            <DistributeProductList setValue={setValue} reset={reset} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <CButton
             
              className="d-block w-100 btn btn-light btn-block"
              color="dark"
              variant="outline"
             
              onClick={() => {
                dispatch({
                  type: "product_UPDATE",
                });
                reset();
                history.push("/productSale");
              }}
            >
            
                <IntlMessages id="invoice.createInvoicePage.cancel" />
              
            </CButton>
          </div>
          <div className="col-md-4">
            <CButton
              
              className="d-block w-100 btn btn-light btn-block" 
              color="dark"
              variant="outline"
              
              onClick={() => {
                dispatch({
                  type: "product_UPDATE",
                });
                reset();
              }}
            >
             
                <IntlMessages id="invoice.createInvoicePage.reset" />
             
            </CButton>
          </div>
          <div className="col-md-4">
            <CLoadingButton
            className="d-block w-100"
              onClick={() => onFinish()}
              color="primary"
              loading={creatingInvoiceLoader}
              disabled={creatingInvoiceLoader}
            >
                <span className="text-white">
               

                <IntlMessages id="invoice.createInvoicePage.create" />
                </span>
             
            </CLoadingButton>
          </div>
        </div>
      </CForm>
    </div>
  );
};

export default DistributeForm;
