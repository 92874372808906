import React, { memo, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  connectedRouter,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import AppLocale from "./lngProvider";
import { IntlProvider } from "react-intl";
import MainApp from "./MainApp";
import PageNotFound from "./PageNotFound";
import { ConnectedRouter } from "connected-react-router";

import { CSpinner } from "@coreui/react-pro";
import "./scss/style.scss";
import { history } from "./store";
// Containers
import MenuList from "./Menu";
import { ToastContainer } from "react-toastify";
import navigationMenu from "./_nav";
import Login from "./views/pages/login/Login";
import Signup from "./views/pages/signup";
import ForgotPassword from "./views/pages/forgotPassword";
import SetNewPassword from "./views/pages/setNewPassword";
import UpdateCompanyDetail from "./views/pages/updateCompanyDetail";

const getMenuObj = (menu, path) => {
  if (menu && menu.length > 0) {
    let selectedPath = null;
    menu.map((Item) => {
      if (Item.items && Item.items.length > 0) {
        Item.items.map((x) => {
          if (x.to === path) {
            selectedPath = x;
          }
        });
      } else if (Item.to === path) {
        selectedPath = Item;
      }
    });
    return selectedPath;
  }
};
const RestrictedRoute = ({
  component: Component,
  location,
  token,
  authUser,
  permissions,
  ...rest
}) => {
  let userAllowRote = true;

  if (permissions && token && authUser?.role !== "mainSuperAdmin") {
    let navigation = navigationMenu[authUser?.role];
    const getSelectedMenu = getMenuObj(navigation, location.pathname);

    if (
      getSelectedMenu &&
      permissions.includes(getSelectedMenu.permissionKey)
    ) {
      userAllowRote = true;
    } else {
      let pathName = location.pathname.split("/");
      if (permissions.includes(pathName[1])) {
        userAllowRote = true;
      } else {
        userAllowRote = false;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        let routeExit = false;
        const userRole = authUser && authUser.role;
        const MenuData = MenuList[userRole];

        if (token && userAllowRote) {
          return <Component {...props} />;
        }
        // else if(token && !userAllowRote){
        //   return <PageNotFound {...props} />;
        // }
        else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const { token, initURL, LogeInUser, permissions } = useSelector(
    ({ auth }) => auth
  );

  const { locale } = useSelector(({ Setting }) => Setting);

  const location = useLocation();
  // const history = useHistory();
  const match = useRouteMatch();

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} />

      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/signup"
            name="Sign Up"
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path="/forgotPassword"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/setNewPassword/:guid"
            name="Set New Password"
            render={(props) => <SetNewPassword {...props} />}
          />
          <Route
            exact
            path="/accountDetail"
            name="Account Detail"
            render={(props) => <UpdateCompanyDetail {...props} />}
          />
          <RestrictedRoute
            path={`${match.url}`}
            token={token}
            authUser={LogeInUser}
            location={location}
            permissions={permissions}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </>
  );
};

export default App;
