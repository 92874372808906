import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { selectStyle } from "../constants/CommonFn";
import {
  CContainer,
  CButtonGroup,
  CButton,
  CFormCheck,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
} from "@coreui/react-pro";
import { cilMoon, cilSun } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { switchLanguage } from "../redux/actions/Setting";
import { AppBreadcrumb } from "./index";
import IntlMessages from "../util/IntlMessages";
import { AppHeaderDropdown } from "./header/index";
import UnAuthorizedModal from "./UnAuthorizedModal";
import CheckboxGroup from "react-checkbox-group";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { DataGetAction } from "src/redux/actions/http";
import { useLocation } from "react-router-dom";
const AppHeader = () => {
  const [language, setLanguage] = useState("english");
  const location = useLocation();
  const dispatch = useDispatch();
  const { CompanyStoreData, CompanyStoreSpin } = useSelector(
    (state) => state.company
  );
  const settingProps = useSelector((state) => state.Setting);
  const history = useHistory();
  const { sidebarShow, asideShow, theme, locale } = settingProps;

  const authProps = useSelector((state) => state.auth);
  const { LogeInUser, permissions } = authProps;
  const [SelectedCompanyStore, setSelectedCompanyStore] = useState(
    localStorage.getItem("selectedCompanyStore")
      ? JSON.parse(localStorage.getItem("selectedCompanyStore"))
      : null
  );
  const checkPermission = () => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.includes("userRoles") ||
        permissions.includes("manageUsers")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const languageData = [
    {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "us",
    },
    {
      languageId: "french",
      locale: "fr",
      name: "French",
      icon: "fr",
    },
  ];

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const [value, setValue] = useState(["apple"]);

  const getBackground = (item) => {
    if (value.includes(item)) {
      return "blue";
    } else {
      return "white";
    }
  };
  const getColor = (item) => {
    if (value.includes(item)) {
      return "white";
    } else {
      return "black";
    }
  };

  let groupItems = ["home", "transactions", "reports", "administration"];

  const callStoreApi = () => {
    dispatch(DataGetAction("getCompanyStore", "", "getCompanyStoreSpin"));
  };

  useEffect(callStoreApi, []);
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((newData) => ({
        label: newData[label],
        value: newData[key],
      }));
    }
  };

  const renderLocalCheck = () => {
    if (localStorage.getItem("swithStoreToCompany")) {
      if (JSON.parse(localStorage.getItem("swithStoreToCompany")) === true) {
        if (localStorage.getItem("selectedCompanyStore")) {
          let store = JSON.parse(localStorage.getItem("selectedCompanyStore"));
          return store.label;
        }
      } else {
        let store = "";
        if (LogeInUser.assignToStore) {
          store = localStorage.getItem("storeName");
        } else {
          store = localStorage.getItem("companyName");
        }
        return store;
      }
    } else {
      let store = "";
      if (LogeInUser.assignToStore) {
        store = localStorage.getItem("storeName");
      } else {
        store = localStorage.getItem("companyName");
      }
      return store;
    }
  };
  const renderTextCheck = () => {
    if (localStorage.getItem("swithStoreToCompany")) {
      if (JSON.parse(localStorage.getItem("swithStoreToCompany")) === true) {
        return <IntlMessages id="switchToCompany" />;
      } else {
        return <IntlMessages id="switchToStore" />;
      }
    } else {
      return <IntlMessages id="switchToStore" />;
    }
  };
  const setSwitchValue = () => {
    if (localStorage.getItem("swithStoreToCompany")) {
      if (JSON.parse(localStorage.getItem("swithStoreToCompany")) === true) {
        localStorage.removeItem("selectedCompanyStore");
        localStorage.setItem("swithStoreToCompany", JSON.stringify(false));
        window.location.reload();
      } else {
        localStorage.setItem("swithStoreToCompany", JSON.stringify(true));
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("selectedCompanyStore")) {
      setSelectedCompanyStore(
        JSON.parse(localStorage.getItem("selectedCompanyStore"))
      );
    }
  }, [CompanyStoreData]);

  const setOnChangeValue = (value) => {
    setSelectedCompanyStore(value);
    localStorage.setItem("selectedCompanyStore", JSON.stringify(value));
    localStorage.setItem("swithStoreToCompany", JSON.stringify(true));
    window.location.reload();
  };
  const renderStoreName = () => {
    let checkStoreName = [];
    if (CompanyStoreData && CompanyStoreData.length > 0) {
      checkStoreName = CompanyStoreData.filter(
        (x) => x._id === LogeInUser && LogeInUser.storeId
      );
    }
    return (
      <h6 className="mb-0">
        <IntlMessages id="headerLoginTitle" /> -{" "}
        {checkStoreName && checkStoreName[0] && checkStoreName[0].label}
      </h6>
    );
  };
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon name="cil-menu" size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon name="logo" height="48" alt="Logo" />
        </CHeaderBrand>
        <div className="d-flex">
          <CHeaderNav className="d-none d-md-flex me-auto">
            <CNavItem>
              <CNavLink
                to="/dashboard"
                component={NavLink}
                activeClassName="active"
              >
                <IntlMessages id="leftSideMenu.dashboard" />
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
          {/* team management */}
          {checkPermission() && (
            <CHeaderNav className="ms-auto me-4">
              <CDropdown variant="nav-item" alignment="end">
                <CDropdownToggle caret={false}>
                  <div>
                    <span style={{ marginLeft: "15px" }}>
                      {" "}
                      <IntlMessages id="leftSideMenu.teamManagement" />
                    </span>
                    <i className="icon icon-chevron-down ms-1"></i>
                  </div>
                </CDropdownToggle>
                <CDropdownMenu placement="bottom-end" className="pt-0">
                  {permissions.includes("userRoles") && (
                    <CDropdownItem
                      style={{ cursor: "pointer" }}
                      className="d-block"
                      onClick={() => {
                        history.push("/userRoles");
                      }}
                    >
                      <div className="small mb-1">
                        {" "}
                        <IntlMessages id="leftSideMenu.userRoles" />
                      </div>
                    </CDropdownItem>
                  )}
                  {permissions.includes("manageUsers") && (
                    <CDropdownItem
                      style={{ cursor: "pointer" }}
                      className="d-block"
                      onClick={() => {
                        history.push("/manageUsers");
                      }}
                    >
                      <div className="small mb-1">
                        <IntlMessages id="leftSideMenu.manageUsers" />
                      </div>
                    </CDropdownItem>
                  )}
                </CDropdownMenu>
              </CDropdown>
            </CHeaderNav>
          )}
        </div>

        {/* <div className="d-flex">
          <CheckboxGroup
            value={value}
            onChange={(value) => {
              setValue(value);
            }}
          >
            {(Checkbox) => (
              <>
                {groupItems.map((item, index) => {
                  return (
                    <label>
                      <div className="d-none">
                        <Checkbox value={item} />
                      </div>

                      <div
                        className={`nav-group-menu ${index+1=== groupItems.length ? 'last' : '' }`}
                        style={{
                          background: getBackground(item),
                          color: getColor(item),
                        }}
                      >
                        {item}
                      </div>
                    </label>
                  );
                })}
              </>
            )}
          </CheckboxGroup>
        </div> */}
        {(permissions.includes("selectStoreName") ||
          (LogeInUser && LogeInUser.role === "superAdmin")) && (
          <CHeaderNav className="d-flex justify-content-center ms-auto">
            <div className="d-flex align-items-center gap-2">
              {!JSON.parse(localStorage.getItem("swithStoreToCompany")) && (
                <Select
                  isSearchable={true}
                  styles={selectStyle}
                  value={SelectedCompanyStore}
                  className="customHeaderDropDown"
                  placeholder={ <IntlMessages id="accountToSwtich" /> }
                  isLoading={CompanyStoreSpin}
                  options={renderOptions(CompanyStoreData, "label", "_id")}
                  onChange={(value) => {
                    setOnChangeValue(value);
                  }}
                />
              )}
              {JSON.parse(localStorage.getItem("swithStoreToCompany")) && (
                <CButton onClick={() => setSwitchValue()}>
                  {renderTextCheck()}
                </CButton>
              )}
            </div>
          </CHeaderNav>
        )}
        <CHeaderNav className="ms-auto me-4">
          <CDropdown variant="nav-item" alignment="end">
            <CDropdownToggle caret={false}>
              {locale.languageId === "english" ? (
                <span>
                  🇺🇸 <span className="ms-2">English</span>
                </span>
              ) : (
                <span>
                  🇫🇷 <span className="ms-2">French</span>{" "}
                </span>
              )}
              <i className="icon icon-chevron-down ms-1"></i>
            </CDropdownToggle>
            <CDropdownMenu placement="bottom-end" className="pt-0">
              <CDropdownItem
                style={{ cursor: "pointer" }}
                className="d-block"
                onClick={() => {
                  dispatch({
                    type: "SWITCH_LANGUAGE",
                    payload: languageData[0],
                  });
                }}
              >
                <div className="small mb-1">
                  🇺🇸
                  <span className="ms-2">English</span>
                </div>
              </CDropdownItem>
              <CDropdownItem
                style={{ cursor: "pointer" }}
                className="d-block"
                onClick={() => {
                  dispatch({
                    type: "SWITCH_LANGUAGE",
                    payload: languageData[1],
                  });
                }}
              >
                <div className="small mb-1">
                  {" "}
                  🇫🇷
                  <span className="ms-2">French</span>
                </div>
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>

        <CHeaderNav className="me-4">
          <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: "primary" }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === "default"}
              onChange={() => dispatch({ type: "set", theme: "light" })}
            />
            <CFormCheck
              type="radio"
              button={{ color: "primary" }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === "dark"}
              onChange={() => dispatch({ type: "set", theme: "dark" })}
            />
          </CButtonGroup>
        </CHeaderNav>
        <CHeaderNav className="ms-3 me-4">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer
        fluid
        className="d-flex align-items-center justify-content-between flex-wrap"
      >
        <AppBreadcrumb />

        {(permissions.includes("selectStoreName") ||
          (LogeInUser && LogeInUser.role === "superAdmin")) && (
          <h6 className="mb-0">
            {" "}
            <IntlMessages id="headerLoginTitle" /> - {renderLocalCheck()}
          </h6>
        )}
      </CContainer>

      <UnAuthorizedModal />
    </CHeader>
  );
};

export default AppHeader;
