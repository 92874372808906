import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../../util/IntlMessages";
import { intlPlaceholder } from "../../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../../redux/actions/Common";
import AddUserForm from "./AddUserForm";
const AddModal = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.trustFund);

  const { trustLoader, selectedItem, modalVisible, success } = comProps;

  const modalType = "show_trust_modal";
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <i className="icon icon-add me-1"></i>
        <IntlMessages id="trust.add.trust.button" />
      </CButton>

      <CModal visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder("trust.addUserModal.addTrustUser")}
          </CModalTitle>
        </CModalHeader>

       
          <AddUserForm 
          defaultValues={null}
          ButtonComponent ={<CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="trust.addUserModal.cancel" />
            </CButton>
  
            <CLoadingButton type="submit" color="primary" loading={trustLoader} disabled={trustLoader}>
              {intlPlaceholder("trust.addUserModal.save")}
            </CLoadingButton>
          </CModalFooter>}
          />
        
        
      </CModal>
    </div>
  );
};

export default AddModal;
