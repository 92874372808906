import React from "react";
import {
  CForm,
  CFormInput,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction,DataGetAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "src/util/ShowToast";

const ProfileForm = ({fileUrl}) => {
  const dispatch = useDispatch();
  const profileProps = useSelector((state) => state.profile);
  const {LogeInUser} = useSelector((state) => state.auth);

  const role = LogeInUser.role

  const {
    profileImageBase64,
    profileInfo,
    success,
    updatingProfileLoader,
    error
  } = profileProps;

 

  const onFinish = (values) => {
    
    if (fileUrl) {
      values = {
        profileImage: fileUrl,
        profileImageBase64: profileImageBase64,
        companyName: values.companyName,
        address: values.address,
      };
    }
    dispatch(
      DataRequestAction(
        "POST",
        "profileUpdate",
        values,
        "",
        "updatingProfile",
        false
      )
    );
   
    
  };


  if(success){
    let message = intlPlaceholder(profileProps.message)
    notify('success',message)
    dispatch(DataGetAction('getDashboard', ''));
    dispatch({
      type : 'update_profile'
    })
  }

  if(error){
    let message = intlPlaceholder(profileProps.message)
    notify('error',message)
    dispatch({
      type : 'update_profile'
    })
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(
      <IntlMessages id="profile.companyNameMessage" />
    ),
    address: Yup.string().required(
      <IntlMessages id="profile.addressMessage" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: profileInfo,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } =
    useForm(formOptions);
  const { errors } = formState;

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      

      <div className="mb-3">
        <CFormInput
          className={errors.companyName ? "is-invalid" : ""}
          {...register("companyName")}
          placeholder={intlPlaceholder("profile.companyNamePlaceholder")}
          type="text"
          id="companyName"
        />
        <div className="invalid-feedback">{errors.companyName?.message}</div>
      </div>

      <div className="mb-3">
        <CFormInput
          className={errors.address ? "is-invalid" : ""}
          {...register("address")}
          placeholder={intlPlaceholder("profile.addressPlaceholder")}
          type="text"
          id="address"
        />
        <div className="invalid-feedback">{errors.address?.message}</div>
      </div>

    {role !== 'team' &&  <div className="mb-3">
        <CLoadingButton
          type="submit"
          color="primary"
          loading={updatingProfileLoader}
          disabled={updatingProfileLoader}
        >
          <IntlMessages id="profile.saveButton" />
        </CLoadingButton>
      </div>}
    </CForm>
  );
};

export default ProfileForm;
