import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import {
  CFormInput,
  CForm,
  CButton,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { selectStyle } from "../../../constants/CommonFn";
import AddSaleInvoiceList from "./AddSaleInvoiceList";
const SaleInvoiceForm = () => {
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [showPaymentInput, setShowPaymentInput] = useState(false);
  const [invoiceAmountValue, setInvoiceAmountValue] = useState("");
  const [productIds, selectProductIds] = useState([]);
  const siProps = useSelector((state) => state.saleInvoiceReducer);
  const { saleInvoiceLoader, CreateInvoiceData } = siProps;
  const storeProps = useSelector((state) => state.storeReducer);
  const { Stores } = storeProps;
  const history = useHistory();
  const productProps = useSelector((state) => state.ProductR);
  const { localProducts, ProductList } = productProps;
  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");
  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="addSaleInvoice.pleaseSelectStore_message" />
    ),
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="addSaleInvoice.pleaseSelectProduct_message" />
      ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = () => {
    const storeValue = getValues("store");
    const remark = getValues("remark");

    if (localProducts.length > 0 && storeValue) {
      let updatedProducts = localProducts.map((item) => {
        return {
          Price: item.localPrice,
          Qty: item.Qty,
          product: item._id,
          productName: item.name,
          store: storeValue,
          unit: item.unit,
          retail: item.retail,
          subunitName: item.subunitName,
          subunit: item.subunit,
          packaging: item.packaging,
          unitName: item.unitName,
        };
      });
      const data = {
        storeId: storeValue,
        customerPay: showPaymentInput,
        paidAmount: invoiceAmountValue,
        products: updatedProducts,
        remark: remark,
        originalProduct: ProductList
      };
      reset();
      dispatch(
        DataRequestAction(
          "POST",
          "createSaleInvoice",
          data,
          "",
          "saleInvoiceProduct",
          false
        )
      );

      dispatch({
        type: "ViewTheProduct",
      });
    } else {
      notify("error", intlPlaceholder("noProductSelected"));
    }
  };

  if (siProps.success) {
    if (CreateInvoiceData !== null) {
      dispatch({
        type: "Show_View_Sale_Invoice_Modal",
        payload: CreateInvoiceData,
      });
      history.goBack();
    }

    if (siProps.message === "recordInvoiceCreatedButReceiptFail") {
      notify("info", intlPlaceholder(siProps.message));
    } else {
      notify("success", intlPlaceholder(siProps.message));
    }
    // message.success(messageText);

    dispatch({
      type: "saleInvoice_UPDATE",
    });
  }

  if (siProps.error) {
    let messageText = intlPlaceholder("addSaleInvoice.product.error");
    notify("error", messageText);
    dispatch({
      type: "saleInvoice_RESET",
    });
  }

  const fetchList = () => {
    dispatch(DataGetAction("getAllProductStock", { query: "qty" }));
    dispatch({
      type: "goBack_initialState",
    });
    dispatch({
      type: "RESET_LOCAL_PRODUCT",
    });
    return () => {
      dispatch({
        type: "goBack_initialState",
      });
      dispatch({
        type: "RESET_LOCAL_PRODUCT",
      });
    };
  };

  useEffect(fetchList, []);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setLocalProduct",
      payload: values,
    });
  };

  if (siProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length === 2 || inputValue.length === 0) {
      dispatch({ type: "CleanStore_SUCCESS" });
    }
    if (!inputValue.trim() || Stores.length > 0) {
      let data = Stores.filter((x) =>
        x.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const formattedOptions = data.map((option) => ({
        value: option._id,
        label: option.name,
      }));
      callback(formattedOptions);
      return;
    }
    try {
      let requestStoreId = "";
      if (
        localStorage.getItem("selectedCompanyStore") &&
        JSON.parse(localStorage.getItem("swithStoreToCompany"))
      ) {
        const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
        if (obj && obj.value !== "null") {
          requestStoreId = obj.value;
        }
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        requestStoreId: requestStoreId,
      };
      if (inputValue && inputValue.length >= 3) {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}/findStore/${JSON.stringify({
            query: inputValue,
          })}`,
          { headers }
        );
        const data = await response.json();

        dispatch({
          type: "findStore_SUCCESS",
          payload: { Record: data.Record },
        });
        const formattedOptions = data.Record.map((option) => ({
          value: option._id,
          label: option.name,
        }));
        callback(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
      <div className="row mb-1">
        {/* store */}
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <AsyncSelect
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  loadOptions={loadOptions}
                  isDisabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder(
                    "addSaleInvoice.pleaseSelectStore"
                  )}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                />
              )}
            />

            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div>
        {/* product */}
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="product"
              key={1}
              render={({ field: { onChange } }) => (
                <Select
                  styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  disabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder("addSaleInvoice.choseProduct")}
                  onChange={(values) => {
                    let selectedIds = values.map((item) => item.value);
                    selectProductIds(selectedIds);

                    onChange(selectedIds);
                  }}
                  options={renderOptions(ProductList, "name", "_id")}
                />
              )}
            />

            <div className="error-message">{errors.product?.message}</div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-md-12">
          <CFormFloating className="mb-3">
            <CFormInput
              className={errors.remark ? "is-invalid" : ""}
              {...register("remark")}
              placeholder={intlPlaceholder("Remark")}
              type="text"
            />
            <CFormLabel htmlFor="text">{intlPlaceholder("Remark")}</CFormLabel>
            <div className="invalid-feedback">{errors.remark?.message}</div>
          </CFormFloating>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12">
          <CButton
            disabled={productIds && productIds.length > 0 ? false : true}
            className="d-block w-100"
            type="submit"
          >
            <i className="icon icon-add me-1"></i>
            <IntlMessages id="addSaleInvoice.add" />
          </CButton>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <AddSaleInvoiceList
            showPaymentInput={showPaymentInput}
            setShowPaymentInput={setShowPaymentInput}
            setDisableSaveBtn={setDisableSaveBtn}
            setInvoiceAmountValue={setInvoiceAmountValue}
            invoiceAmountValue={invoiceAmountValue}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "product_UPDATE",
              });
              reset();
              history.goBack();
            }}
          >
            <IntlMessages id="addSaleInvoice.cancel" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_LOCAL_PRODUCT",
              });
            }}
          >
            <IntlMessages id="addSaleInvoice.reset" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CLoadingButton
            disabled={disableSaveBtn || saleInvoiceLoader}
            className="d-block w-100"
            onClick={() => onFinish()}
            color="primary"
            loading={saleInvoiceLoader}
          >
            <span className="text-white">
              <IntlMessages id="addSaleInvoice.create" />
            </span>
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default SaleInvoiceForm;
