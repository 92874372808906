import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilBuilding,
  cilUserFollow,
} from "@coreui/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const { message, success, error, ShowSignUpLoader } = authProps;

  // form validation rules

  const firstNameReq =  intlPlaceholder("signUp.firstName_message")
  const lastNameReq =  intlPlaceholder("signUp.lastName_message")
  const companyNameReq =  intlPlaceholder("signUp.companyName_message")
  const username =  intlPlaceholder("signUp.userName_message")
  const email =  intlPlaceholder("signUp.email_message")
  const phoneNumber =  intlPlaceholder("signUp.phone_message")
  const password =  intlPlaceholder("signUp.password_message")
  const confirm =  intlPlaceholder("signUp.confirmPassword_message")
  const confirmMatch =  intlPlaceholder("signUp.confirmMatch_message")
  const reqForSignUpSuccess =  intlPlaceholder("signUp.reqForSignUpSuccess")
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(firstNameReq),
    lastName: Yup.string().required(lastNameReq),
    companyName: Yup.string().required(companyNameReq),
    username: Yup.string().required(username),
    email: Yup.string().required(email).email(),
    phoneNumber: Yup.string().required(phoneNumber),
    password: Yup.string().required(password),
    confirm: Yup.string()
      .required(confirm)
      .oneOf([Yup.ref("password")], confirmMatch),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    dispatch(DataRequestAction("POST", "signup", values, "", "signup", false));
  };

  const redirectToLoginPage = () => {
    dispatch(updateAllState("auth"));
    history.push("/");
  };
  const emptyPasswordField = () => {
    dispatch(updateAllState("auth"));
  };

  const showSuccessAlert = () => {
    return (
      <SweetAlert
        success
        title="Congrats!!!"
        onConfirm={() => redirectToLoginPage()}
      >
       
        {reqForSignUpSuccess}
      </SweetAlert>
    );
  };
  const showErrorAlert = () => {
    return (
      <SweetAlert
        error
        title={message}
        onConfirm={() => emptyPasswordField()}
        btnSize="sm"
      />
    );
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div>
        {success && showSuccessAlert()}
        {error && showErrorAlert()}
      </div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit(onFinish)}>
                  <h1>
                    <IntlMessages id="signUp.register" />
                  </h1>
                  <p className="text-medium-emphasis">
                    <IntlMessages id="signUp.createYourAccount" />
                  </p>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                      <CFormInput
                        
                        placeholder={intlPlaceholder("signUp.firstName")}
                        autoComplete="firstName"
                        id="firstName"
                        className={errors.firstName ? "is-invalid" : ""}
                        {...register("firstName")}
                      />

                      <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.firstName")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.firstName?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                      <CFormInput
                        placeholder={intlPlaceholder("signUp.lastName")}
                        autoComplete="lastName"
                        id="lastName"
                        className={errors.lastName ? "is-invalid" : ""}
                        {...register("lastName")}
                      />

                      <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.lastName")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                    {errors.lastName?.message}
                  </div>
                  </CInputGroup>
                 

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilBuilding} />
                    </CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                    <CFormInput
                      placeholder={intlPlaceholder("signUp.companyName")}
                      autoComplete="companyName"
                      id="companyName"
                      className={errors.companyName ? "is-invalid" : ""}
                      {...register("companyName")}
                    />
                   
                    <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.companyName")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.companyName?.message}
                    </div>
                  </CInputGroup>
                 

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUserFollow} />
                    </CInputGroupText>
                     <CFormFloating style={{ flex: 1 }}>
                    <CFormInput
                      placeholder={intlPlaceholder("signUp.userName")}
                      autoComplete="userName"
                      id="userName"
                      className={errors.username ? "is-invalid" : ""}
                      {...register("username")}
                    />
                   
                    <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.userName")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.username?.message}
                    </div>
                  </CInputGroup>
                 

                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                    <CFormInput
                      placeholder={intlPlaceholder("signUp.email")}
                      autoComplete="email"
                      id="email"
                      className={errors.email ? "is-invalid" : ""}
                      {...register("email")}
                    />
                  
                    <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.email")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.email?.message}
                    </div>
                  </CInputGroup>
                

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                    <CFormInput
                      type="password"
                      placeholder={intlPlaceholder("signUp.password")}
                      autoComplete="new-password"
                      id="password"
                      className={errors.password ? "is-invalid" : ""}
                      {...register("password")}
                    />
                  
                    <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.password")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.password?.message}
                    </div>
                  </CInputGroup>
              

                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormFloating style={{ flex: 1 }}>
                    <CFormInput
                      placeholder={intlPlaceholder("signUp.repeatPassword")}
                      type="password"
                      autoComplete="new-password"
                      id="confirm"
                      className={errors.confirm ? "is-invalid" : ""}
                      {...register("confirm")}
                    />
                   
                    <CFormLabel htmlFor="">
                        {intlPlaceholder("signUp.repeatPassword")}
                      </CFormLabel>
                    </CFormFloating>
                    <div className="error-message">
                      {errors.confirm?.message}
                    </div>
                  </CInputGroup>
                

                  <CInputGroup className="mb-4">
                    <Controller
                      control={control}
                      defaultValue=""
                      name={"phoneNumber"}
                      className="mb-4"
                      render={(props) => {
                        return (
                          <PhoneInput
                            country={"gn"}
                            onChange={(v) => props.field.onChange(v)}
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                            }}
                          />
                        );
                      }}
                    />
                    <div className="error-message">
                      {errors.phoneNumber?.message}
                    </div>
                  </CInputGroup>

                  <div className="d-grid">
                    <CLoadingButton
                      color="success"
                      type="submit"
                      loading={ShowSignUpLoader}
                      disabled={ShowSignUpLoader}
                    >
                      <IntlMessages id="signUp.createAccountBtn" />
                    </CLoadingButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Index;
