import _ from "lodash";

const INIT_STATE = {
  stores: [],
  suppliers: [],
  SelectedProduct: null,
  SelectedUnit: null,
  units: [],
  products: [],
  success: false,
  error: false,
  message: "",
  Qty: 0,
  Price: 0,
  ProductsLocal: [],
  deleteRecordSuccess: false,
  ShowPreview: false,
  InvoiceNo: 0,
  selectedUnit: 0,
  distributeLoader: false,
  productListDrop: [],
  fetchProductsStoreStockDistributeSpinner: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "distributeProduct_START": {
      return { ...state, distributeLoader: true };
    }
    case "ViewTheProduct": {
      return { ...state, ShowPreview: !state.ShowPreview };
    }

    case "distribute_SUCCESS": {
      return {
        ...state,
        distributeLoader: false,
        ProductsLocal: [],
        InvoiceNo: action.payload.Record[0],
        success: true,
        message: "recordDistributeSuccessfully",
      };
    }
    case "distributeDrop_SUCCESS": {
      return {
        ...state,
        stores: action.payload.Record.stores,
        suppliers: action.payload.Record.suppliers,
        productListDrop: action.payload.Record.productList,
      };
    }

    case "fetchProducts_SUCCESS": {
      return {
        ...state,
        products: action.payload.Record,
        units: [],
        Qty: 0,
        Price: 0,
      };
    }
    case "fetchProductsStoreStockDistribute_START": {
      return {
        ...state,
        fetchProductsStoreStockDistributeSpinner: true,
      };
    }
    case "fetchProductsStoreStockDistribute_STOP": {
      return {
        ...state,
        fetchProductsStoreStockDistributeSpinner: false,
      };
    }
    case "fetchProductsStoreStockDistribute_SUCCESS": {
      return {
        ...state,
        products: action.payload.Record,
        units: [],
        Qty: 0,
        Price: 0,
      };
    }

    case "fetchProductStock_SUCCESS": {
      const Record = action.payload.Record;
      const data = getUnitListByProductId(Record, state.ProductsLocal);

      return {
        ...state,
        SelectedProduct: Record.productId,
        units: Record.units,
        Qty: data.Qty,
        Price: data.Price,
        selectedUnit: { unit: data.selectedUnit },
        SelectedUnit: data.selectedUnit,
      };
    }

    case "SelectUnitOfProduct_SUCCESS": {
      const selectedData = getUnitListByProductId(
        action.payload,
        state.ProductsLocal
      );
      return {
        ...state,
        SelectedProduct: action.payload,
        units: selectedData.Units,
        Qty: selectedData.Qty,
        Price: selectedData.Price,
        selectedUnit: { unit: selectedData.selectedUnit },
        SelectedUnit: selectedData.selectedUnit,
      };
    }
    case "AddInLocalProduct_SUCCESS": {
      const result = addDataInLocalProduct(
        action.payload,
        state.ProductsLocal,
        state.units,
        state.Qty,
        state.SelectedUnit
      );
      return {
        ...state,
        ProductsLocal: result.newArray,
        Qty: 0,
        Price: 0,
        selectedUnit: 0,
        SelectedUnit: 0,
      };
    }
    case "ChangeThePriceAndQty_SUCCESS": {
      const PQR = ChangeThePriceAndQty(
        action.payload,
        state.Qty,
        state.Price,
        state.units,
        state.SelectedUnit
      );
      return {
        ...state,
        Qty: PQR.Qty,
        Price: PQR.Price,
        SelectedUnit: PQR.SelectedUnit,
        selectedUnit: { unit: PQR.SelectedUnit },
      };
    }

    case "deleteProductLocal_SUCCESS": {
      const deletedRecord = getRecordAfterDeleted(
        state.ProductsLocal,
        action.payload
      );

      return {
        ...state,
        ProductsLocal: deletedRecord,
        Qty: 0,
        Price: 0,
        product: undefined,
        unit: undefined,
        deleteRecordSuccess: true,
      };
    }

    case "deleteRecord_update": {
      return { ...state, deleteRecordSuccess: false };
    }

    case "distributed_UPDATE": {
      return {
        ...state,
        success: false,
        error: false,
        message: "",
        Qty: 0,
        Price: 0,
        ShowPreview: false,
        ProductsLocal: [],
      };
    }

    case "goBack_initialState": {
      return {
        ...state,
        stores: [],
        suppliers: [],
        SelectedProduct: null,
        SelectedUnit: null,
        units: [],
        products: [],
        success: false,
        error: false,
        message: "",
        Qty: 0,
        Price: 0,
        ProductsLocal: [],
        deleteRecordSuccess: false,
        ShowPreview: false,
        InvoiceNo: 0,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getRecordAfterDeleted(oldRecord, deletedRecord) {
    const newRecord = [];
    oldRecord.map((Item) => {
      if (Item.product !== deletedRecord.product) {
        newRecord.push(Item);
      }
      return Item;
    });
    return newRecord;
  }

  function ChangeThePriceAndQty(selectedUnit, Qty, Price, units, oldUnit) {
    let newQty = Qty;
    let newPrice = Price;
    const subUnits = units[0].subUnits;

    if (selectedUnit !== units[0]._id) {
      let selectedUnitArr = _.filter(subUnits, (x) => x._id === selectedUnit);

      if (oldUnit !== null && oldUnit !== units[0]._id) {
        //======= Old unit is not parent unit

        let oldUnitObj = _.filter(subUnits, (x) => x._id === oldUnit);

        let selectedPUnitFactor =
          oldUnitObj && oldUnitObj.length > 0 && oldUnitObj[0].factor
            ? oldUnitObj[0].factor
            : 1;
        if (selectedUnitArr) {
          newQty =
            (Qty / parseFloat(selectedPUnitFactor)) *
            parseFloat(selectedUnitArr[0].factor);

          newPrice =
            (Price * parseFloat(selectedPUnitFactor)) /
            parseFloat(selectedUnitArr[0].factor);
        }
      } else {
        //======= Old unit is parent unit
        if (selectedUnitArr) {
          newQty = Qty * parseFloat(selectedUnitArr[0].factor);

          newPrice = Price / parseFloat(selectedUnitArr[0].factor);
        }
      }
    } else {
      if (oldUnit !== null) {
        let oldUnitObj = _.filter(subUnits, (x) => x._id === oldUnit);
        if (oldUnitObj.length > 0) {
          let selectedPUnitFactor = oldUnitObj[0].factor;
          newQty = Qty / parseFloat(selectedPUnitFactor);

          newPrice = Price * parseFloat(selectedPUnitFactor);
        } else {
          newQty = Qty;

          newPrice = Price;
        }
      }
    }

    return { Qty: newQty, Price: newPrice, SelectedUnit: selectedUnit };
  }

  function addDataInLocalProduct(
    newItem,
    oldItemArray,
    units,
    QtyInStock,
    SelectedUnit
  ) {
    let newArray = [];

    const index = _.findIndex(
      oldItemArray,
      (x) => x.productName === newItem.productName
    );
    QtyInStock = (parseFloat(QtyInStock) - parseFloat(newItem.Qty)).toFixed(2);

    if (index > -1) {
      let product = null;
      const selectedProduct = oldItemArray[index];
      if (selectedProduct.unit === newItem.unit) {
        product = selectedProduct;
        product.Qty = parseFloat(product.Qty) + parseFloat(newItem.Qty);
        product.price = newItem.Price;
        oldItemArray[index] = product;
        newArray = [...oldItemArray];
      } else {
        const oldUnitProduct = selectedProduct.unit;
        let newUnitObj = _.filter(
          units[0].subUnits,
          (x) => x._id === newItem.unit
        );
        product = selectedProduct;

        if (oldUnitProduct !== units[0]._id) {
          //==================== if old product hasn't parent unit ============
          let oldUnitObj = _.filter(
            units[0].subUnits,
            (x) => x._id === oldUnitProduct
          );
          if (newUnitObj.length > 0) {
            product.Qty =
              (parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor)) *
                parseFloat(newUnitObj[0].factor) +
              parseFloat(newItem.Qty);
          } else {
            product.Qty =
              parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor) +
              parseFloat(newItem.Qty);
          }
        } else {
          product.Qty =
            parseFloat(product.Qty) * parseFloat(newUnitObj[0].factor) +
            parseFloat(newItem.Qty);
        }
        product.unit = newItem.unit;
        product.Price = newItem.Price;
        oldItemArray[index] = product;
        newArray = [...oldItemArray];
      }
    } else {
      newArray = [newItem, ...oldItemArray];
    }

    return { newArray, QtyInStock };
  }

  function getUnitListByProductId(product, localProduct) {
    let Units = [];
    const selectedProduct = product;

    const localProductArr = _.filter(
      localProduct,
      (x) => x.product === selectedProduct.productId
    );
    Units = selectedProduct.units;

    let Qty = 0;
    let Price = selectedProduct.localPrice;

    if (localProductArr.length > 0) {
      const oldUnit = localProductArr[0].unit;
      const checkParentIndex = _.findIndex(Units, (x) => x._id === oldUnit);
      if (checkParentIndex > -1) {
        Qty =
          parseFloat(selectedProduct.quantity) -
          parseFloat(localProductArr[0].Qty);
      } else {
        let fIndex = null;
        _.map(Units, (x, index) => {
          const subUnitIndex = _.findIndex(
            x.subUnits,
            (y) => y._id === oldUnit
          );
          if (subUnitIndex > -1) {
            fIndex = { pIndex: index, cIndex: subUnitIndex };
          }
        });
        if (fIndex) {
          const oldUnitObj = Units[fIndex.pIndex].subUnits[fIndex.cIndex];
          if (oldUnitObj._id === selectedProduct.unitId) {
            Qty =
              parseFloat(selectedProduct.quantity) -
              parseFloat(localProductArr[0].Qty);

            Price = parseFloat(selectedProduct.localPrice);
          } else {
            Qty =
              parseFloat(selectedProduct.quantity) *
                parseFloat(oldUnitObj.factor) -
              parseFloat(localProductArr[0].Qty);

            Price =
              parseFloat(selectedProduct.localPrice) /
              parseFloat(oldUnitObj.factor);
          }
        }
      }
    } else {
      Qty = selectedProduct.quantity.toFixed(3);
    }

    let selectUnit = selectedProduct.unitId;
    if (localProductArr.length > 0) {
      selectUnit = localProductArr[0].unit;
    }

    return { Units, Qty: Qty, Price: Price, selectedUnit: selectUnit };
  }
};
