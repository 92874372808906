import React, { useState } from "react";
import UnitList from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";

import { useDispatch, useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { CFormCheck } from "@coreui/react-pro";
const QuickSaleProductList = ({
  showAction = true,
  setValue,
  reset,
  setDisableSaveBtn,
  quantityTitle,
}) => {
  const [details, setDetails] = useState([]);

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const prodProps = useSelector((state) => state.distributeReducer);
  const { localProducts, ProductList } = useSelector((state) => state.ProductR);
  const dispatch = useDispatch();

  let priceShouldBeNumeric = intlPlaceholder("priceShouldBeNumeric");
  let priceShouldBeBiggerThan1 = intlPlaceholder("priceShouldBeBiggerThan1");

  const deleteRecord = (record) => {
    dispatch({
      type: "deleteLocalProduct",
      payload: record,
    });
  };

  const renderTotalPrice = () => {
    let total = 0;

    localProducts &&
      localProducts.length > 0 &&
      localProducts.forEach((item) => {
        total = item.totalPrice + total;
      });
    total = ChangeNumberFormate(total.toFixed(2));
    return total;
  };

  let columnsNew = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="product.name" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
    },
    {
      dataField: "unitName",
      text: <IntlMessages id="product.unit" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <span>{record.retail ? record.subunitName : record.unitName}</span>
        );
      },
    },
    {
      text: <IntlMessages id="product.retail" />,
      editable: false,
      formatter: (cell, record) => {
        return (
          <CFormCheck
            defaultChecked={record.retail}
            onChange={(e) => {
              dispatch({
                type: "updateLocalProduct",
                payload: {
                  fieldChanged: "retail",
                  updatedRow: { ...record, retail: e.target.checked },
                },
              });
            }}
          />
        );
      },
    },
    {
      dataField: "Qty",
      text: <IntlMessages id="product.quantity" />,
      editable: true,
      formatter: (cell) => {
        return <span>{parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        let qty = newValue;
        let sockQty = row.sockQty;

        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="stock.numeric" />,
          };
        }
        if (
          qty > parseFloat(row.sockQty) * parseFloat(row.packaging) &&
          !row.edit &&
          row.retail
        ) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        } else if (qty > parseFloat(sockQty) && row.retail && row.edit) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        }
        if (qty > sockQty && !row.retail) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: <IntlMessages id="quantity" />,
          };
        } else {
          setDisableSaveBtn(false);
          return true;
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "sockQty",
      text: <IntlMessages id={`product.${quantityTitle}`} />,
      editable: false,
      formatter: (cell, record) => {
        const sockQtyPackaging =
          parseFloat(record.sockQty) * parseFloat(record.packaging);
        return (
          <span>
            {record.retail && !record.edit
              ? sockQtyPackaging.toFixed(3)
              : parseFloat(cell).toFixed(3)}
          </span>
        );
      },
    },
    {
      dataField: "localPrice",
      text: <IntlMessages id="product.UnitPrice" />,
      editable: true,
      formatter: (cell) => {
        return <span> {parseFloat(cell).toFixed(2)}</span>;
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeNumeric,
          };
        }
        if (newValue < 1) {
          setDisableSaveBtn(true);
          return {
            valid: false,
            message: priceShouldBeBiggerThan1,
          };
        } else {
          setDisableSaveBtn(false);
          return {
            valid: true,
          };
        }
      },
      classes: () => {
        return "selectable-cell";
      },
    },
    {
      dataField: "totalPrice",
      text: <IntlMessages id="product.TotalPrice" />,
      editable: false,
      formatter: (cell, record) => {
        return <span> {ChangeNumberFormate(parseFloat(cell).toFixed(2))}</span>;
      },
    },

    {
      dataField: "action",
      editable: false,
      text: <IntlMessages id="product.action" />,
      formatter: (cell, record) => {
        return (
          <span
            style={{
              cursor: "pointer",
              color: "red",
            }}
            onClick={() => deleteRecord(record)}
          >
            <span className="icon icon-trash"></span>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="_id"
        striped
        data={localProducts}
        columns={columnsNew}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
            let changedField = column.dataField;
            dispatch({
              type: "updateLocalProduct",
              payload: {
                fieldChanged: changedField,
                updatedRow: row,
                newValue: newValue,
              },
            });
          },
        })}
      />

      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tr>
          <td colspan="5" style={{ textAlign: "center" }}>
            <h5>Total</h5>
          </td>

          <td colspan="3" style={{ textAlign: "center" }}>
            <h5>{renderTotalPrice()}</h5>
          </td>
        </tr>
      </table>
    </>
  );
};

export default QuickSaleProductList;
