import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import TransactionType from "../../../components/InvoiceTable";
import SendEmailModal from '../../../components/SendEmailModal'
import { CButton } from '@coreui/react-pro'
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
import { useHistory } from "react-router";
import { notify } from "src/util/ShowToast";
const ReceiptDetail = () => {
  const componentRef = useRef();
  const invProps = useSelector((state) => state.receiptReducer);
  const { refundStoreReceiptSuccess, message, refundStoreReceiptError, successStoreWithdrawRefund } = invProps
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.Setting);
  const { languageId } = locale;
  const {
    receiptNumber,
    prefix,
    createdAt,
    store,
    createdBy,
    paymentMode,
    totalAmountDue,
    paid,
    currency,
    remark,
    storeId,
    companyId,
    _id,
    receiptType
  } = invProps.ReceiptDetail !== null ? invProps.ReceiptDetail : "000-000-000";


  const isRefund = invProps.ReceiptDetail && invProps.ReceiptDetail.refund && invProps.ReceiptDetail.refund

  const storeName = store && store[0] ? store[0].label : "";
  const storeEmail = store && store[0] ? store[0].email : "";
  const createdByName = createdBy && createdBy[0] ? createdBy[0].name : "";
  const oldReceiptNumber = invProps.ReceiptDetail && invProps.ReceiptDetail.oldReceiptNumber && invProps.ReceiptDetail.oldReceiptNumber

  const logoImage = localStorage.getItem("profileImage");
  const address = localStorage.getItem("address");

  const history = useHistory()

  const columns = [
    {
      label: <IntlMessages id="receiptDetail.PaymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="receiptDetail.bankName" />,
      key: "bankName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionType" />,
      key: "TransactionType",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.TransactionNo" />,
      key: "TransactionNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receiptDetail.Amount" />,
      key: "Amount",
      _style: { width: "40%" },
    },
  ];

  if (
    paymentMode &&
    paymentMode.mode === "bank" &&
    paymentMode.transactionType === "cheque"
  ) {
    columns.push({
      label: <IntlMessages id="receiptDetail.chequeDate" />,
      key: "chequeDate",
      _style: { width: "40%" },
    });
  }

  const renderPaymentDetail = () => {
    const paymentModeArray = paymentMode ? [paymentMode] : [];
    return (
      <TransactionType

        items={paymentModeArray}
        columns={columns}
        scopedColumns={{
          mode: (record) => {
            return <td>{<IntlMessages id={`${record.mode}.paymentMethod`} />}</td>
          },
          bankName: (record) => {
            return <td>{record.bank ? record.bank.name : "---"}</td>;
          },
          TransactionType: (record) => {
            return (
              <td>{record.transactionType ? record.transactionType : "---"}</td>
            );
          },
          TransactionNo: (record) => {
            return (
              <td>
                {record.transactionNumber ? record.transactionNumber : "---"}
              </td>
            );
          },
          Amount: (record) => {
            return (
              <td>
                {ChangeNumberFormate(paid.toFixed(2))} {" "}
                {currency && currency.length > 0 ? currency[0].name : ""}
              </td>
            );
          },
          chequeDate: (record) => {
            return (
              <td>{ChangeDateFormate(record.chequeDate, "YYYY-MM-DD")}</td>
            );
          },
        }}
      />
    );
  };




  const renderAmountDune = () => {
    let amountDue = -1 * totalAmountDue;
    return (
      <div className="d-flex">
        <span
          className="d-inline-block "
          style={{ width: "40%", fontSize: 13, color: "#003366" }}
        >
          <IntlMessages id="invoiceDetail.amountDueWord" />:
        </span>
        <span
          className="d-inline-block text-capitalize"
          style={{ width: "60%", fontSize: 13 }}
        >
          {totalAmountDue < 0
            ? ChangeNumberToWord(amountDue, languageId)
            : ChangeNumberToWord(totalAmountDue, languageId)}
        </span>
      </div>
    );
  };

  const getRecordValue = () => {
    const paymentModeArray = paymentMode ? [paymentMode] : [];
    const logo = localStorage.getItem("profileImage");
    const companyName = localStorage.getItem("companyName");
    const mainAddress = localStorage.getItem("address");

    let totalBillFigure = `${currency && currency.length > 0 ? currency[0].name : ""
      } ${NumberWithCommas(parseFloat(paid))}`;
    let totalBillWords = ChangeNumberToWord(paid, languageId);

    let totalBalanceFigure = `${currency && currency.length > 0 ? currency[0].name : ""
      } ${NumberWithCommas(parseFloat(totalAmountDue))}`;
    let amountDue = totalAmountDue;
    if (amountDue < 0) {
      amountDue = -1 * amountDue;
    }

    let totalBalanceWords = ChangeNumberToWord(amountDue, languageId);

    let data = [];

    if (paymentModeArray && paymentModeArray.length > 0) {
      paymentModeArray.map((item) => {
        let { mode, quantity, salePrice, total, ...rest } = item;


        mode = intlPlaceholder(`${mode}.paymentMethod`)


        let amount = totalBillFigure;
        let obj = {
          mode,
          bankName: item.bank ? item.bank.name : "---",
          transactionType: item.transactionType ? item.transactionType : "---",
          transactionNumber: item.transactionNumber
            ? item.transactionNumber
            : "---",
          amount: amount,
        };

        if (paymentMode && paymentMode.mode === "bank" && paymentMode.transactionType === "cheque") {
          obj["chequeDate"] = ChangeDateFormate(item.chequeDate, "YYYY-MM-DD");
        }

        data.push(obj);
      });
    }

    let key1 = intlPlaceholder("receiptDetail.store");
    let key3 = intlPlaceholder("receiptDetail.receiptNumber");
    let key4 = intlPlaceholder("receiptDetail.createdBy");

    let tableColumn = [
      intlPlaceholder("receiptDetail.PaymentMode"),
      intlPlaceholder("receiptDetail.bankName"),
      intlPlaceholder("receiptDetail.TransactionType"),
      intlPlaceholder("receiptDetail.TransactionNo"),
      intlPlaceholder("receiptDetail.Amount"),
    ];

    if (
      paymentMode &&
      paymentMode.mode === "bank" &&
      paymentMode.transactionType === "cheque"
    ) {
      tableColumn.push(intlPlaceholder("receiptDetail.chequeDate"));
    }

    let detailObj = {
      [key1]: {
        name: storeName,
        address: store && store.length > 0 && store[0].address,
      },
    };

    detailObj = {
      ...detailObj,
      [key3]: prefix + "-" + receiptNumber,
      [key4]: createdByName,
    };


    let obj = {
      subject: `${intlPlaceholder("receiptDetail.ReceiptLabel")}  ${intlPlaceholder('from')} ${companyName}`,
      logo: logo,
      address: mainAddress,
      title: (receiptType && receiptType === 'storeRefund') ? `${intlPlaceholder('refundOfReceipt')} ${prefix}-${receiptNumber} ` : receiptType === 'companySale' ? intlPlaceholder("clientReceipt.title") : intlPlaceholder("storewithdrawalDetail.title"),
      date: ChangeDateFormate(createdAt),

      details: detailObj,
      tableColumn: tableColumn,
      tableRow: data,
      totalBillKey: intlPlaceholder("invoiceDetail.totalBill"),
      totalBillWorldKey: intlPlaceholder("invoiceDetail.totalBillWords"),
      totalBalanceKey: intlPlaceholder("invoiceDetail.amountDue"),
      totalBalanceWorldKey: intlPlaceholder("invoiceDetail.amountDueWord"),
      totalBillFigures: totalBillFigure,
      totalBillWords: totalBillWords,
      totalBalanceFigure: totalBalanceFigure,
      totalBalanceWords: totalBalanceWords,
      remark,
      note: intlPlaceholder("invoiceDetail.note"),
    };


    return obj;
  };
  const areYouSureYouWantToRefundTheReceiptNumber = intlPlaceholder(
    "areYouSureYouWantToRefundTheReceiptNumber"
  );

  if (refundStoreReceiptSuccess) {
    history.push("/companyReceipt");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  if (successStoreWithdrawRefund) {
    history.push("/storeWithdraw");
    let messageLng = intlPlaceholder(message);
    notify("success", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  if (refundStoreReceiptError) {
    let messageLng = intlPlaceholder(message);
    history.push("/companyReceipt");
    notify("error", messageLng);
    dispatch({
      type: "receipt_UPDATE",
    });
  }

  const renderReceiptTitle = () => {
    if (receiptType === 'storeRefund') {
      return `${intlPlaceholder('refundOfReceipt')} ${prefix}-${oldReceiptNumber}`
    }
    if (receiptType === 'storeWithdrawRefund') {
      return `${intlPlaceholder('refundWithDrawlTitle')} ${prefix}-${oldReceiptNumber}`
    }
    else if (receiptType === 'companySale') {
      return <IntlMessages id="clientReceipt.title" />
    }
    else if (receiptType === 'withdraw') {
      return <IntlMessages id="storewithdrawalDetail.title" />
    }
  }

  let [printBalance, setPrintBalance] = useState(true);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">

        <div className="d-flex align-items-center">
          {isRefund && <CButton
            color="success"
            className="text-white me-3"

          >
            <span><IntlMessages id="refunded" /></span>

          </CButton>}
          {/* receipt comment */}
          {(receiptType !== 'storeRefund' && !isRefund) && <DeleteModal
            contentLangueId={`${areYouSureYouWantToRefundTheReceiptNumber} ${prefix}-${receiptNumber}`}
            method="POST"
            type={receiptType === 'withdraw' ? "storeWithdrawRefund" : "refundStoreReceipt"}
            selectedItem={{ _id, receiptNumber, storeId, companyId }}
            ButtonTitleId="receipt.refund"
            titleLanguageId="receipt.refund"
            textColor="#ffffff"
            size="md"
            color="danger"
            variant=""
          />
          }

          {receiptType !== 'storeRefund' && !isRefund && <CButton
            color="warning"
            className="text-white me-3"
            onClick={() => {
              dispatch({
                type: "send_email_modal",
              });
            }}
          >
            <i
              className="icon icon-mail-open me-1 mb-1"

            ></i>
            <IntlMessages id="sendMail" />
          </CButton>}

          {!isRefund && <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >

                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />}

          {!isRefund && <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="printNotBalance" />
              </CButton>
            )}
            content={() => componentRef.current}
            onBeforeGetContent={() => {
              setPrintBalance(false)
              return Promise.resolve();
            }
            }
            onAfterPrint={() => {
              setPrintBalance(true)
              return Promise.resolve();
            }
            }
          />}
        </div>
      </div>
      <div className="print-invoice p-3 float-left w-100 pe-5 ps-5 " ref={componentRef}>
        <img src={logoImage} alt="" width="300" className="mb-2" />

        <p
          className="mb-4"
          style={{ color: "grey", width: "200px", textTransform: "capitalize" }}
        >
          {address}
        </p>
        <h1 style={{ color: "#012447", fontWeight: "bold" }} className=" mb-2">
          {renderReceiptTitle()}
          {/* { receiptType === 'storeRefund' ? `${intlPlaceholder('refundOfReceipt')} ${prefix}-${oldReceiptNumber} ` : <IntlMessages id="storewithdrawalDetail.title" />} */}
        </h1>
        <p style={{ color: "#003366" }} className=" mb-5 font-weight-bold">
          {ChangeDateFormate(createdAt)}
        </p>

        <div className="d-flex flex-row justify-content-between">
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.store" />
              </h4>
              <p style={{ color: "grey", marginBottom: 0 }}>{storeName}</p>
              <p style={{ color: "grey" }}>
                {store && store.length > 0 && store[0].address}
              </p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="receiptDetail.receiptNumber" />
              </h4>
              <p style={{ color: "grey" }}>{prefix + "-" + receiptNumber}</p>
            </div>
          </div>
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                <IntlMessages id="currencyExchange.createdBy_label" />
              </h4>
              <p style={{ color: "grey" }}>{createdByName}</p>
            </div>
          </div>
        </div>
        <br />

        <div className="w-100">{renderPaymentDetail()}</div>

        <div className="d-flex justify-content-between mt-4">
          <p>
            {remark && <IntlMessages id="invoiceDetail.note" />}
            {remark && `: ${remark}`}
          </p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 13, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBill" />:
              </span>
              <span
                className="d-inline-block "
                style={{ width: "60%", fontSize: 20, fontWeight: 'bold' }}
              >
                {ChangeNumberFormate(parseFloat(paid).toFixed(2))} {" "}
                {currency && currency.length > 0 ? currency[0].name : ""}
              </span>
            </div>
            <div className="d-flex">
              <span
                className="d-inline-block"
                style={{ width: "40%", fontSize: 13, color: "#003366" }}
              >
                <IntlMessages id="invoiceDetail.totalBillWords" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "60%", fontSize: 13 }}
              >
                {ChangeNumberToWord(paid)}
              </span>
            </div>

            {printBalance && (
              <div>
                <div className="d-flex mt-2">
                  <span
                    className="d-inline-block"
                    style={{ width: "40%", fontSize: 13, color: "#003366" }}
                  >
                    <IntlMessages id="invoiceDetail.amountDue" />:
                  </span>
                  <span
                    className="d-inline-block "
                    style={{ width: "60%", fontSize: 20, fontWeight: 'bold' }}
                  >
                    {ChangeNumberFormate(parseFloat(totalAmountDue).toFixed(2))} {" "}
                    {currency && currency.length > 0 ? currency[0].name : ""}
                    
                  </span>
                </div>
                {renderAmountDune()}
              </div>)}
          </div>
        </div>
        <InvoiceSignature data={{ clientName: storeName }} />
      </div>
      <SendEmailModal
        initialValues={{ email: storeEmail }}
        record={getRecordValue()}
      />
    </div>
  );
};

export default ReceiptDetail;
