const INIT_STATE = {
    profileImage: "",
    profileImageBase64: "",
    success: false,
    profileInfo: null,
    loader:false,
    error : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'fetchingData_START':{
            return {...state, loader:true}
        }

        case 'updatingProfile_START': {
            return { ...state, updatingProfileLoader: true };
        }
        case 'profileUpdate_FAILURE': {
            return { ...state, updatingProfileLoader: false,error : true };
        }


        case 'profileUpdate_SUCCESS': {
            localStorage.setItem('profileImage',action.payload.Record.profileImage)
            localStorage.setItem('image',action.payload.Record.profileImageBase64)
            localStorage.setItem('address',action.payload.Record.address)
            return { ...state, message: "profileUpdateSuccessfully", success: true ,updatingProfileLoader: false}
        }
        case 'profileImageUrl_Upload': {
            return { ...state, profileImage: action.payload.location || "", profileImageBase64: action.image  };
        }

        case 'getProfileInfo_SUCCESS': {
            let data = action.payload.Record[0]
            localStorage.setItem('profileImage',data.profileImage)
            localStorage.setItem('image',data.profileImageBase64)
            localStorage.setItem('address',data.address)
            return { ...state, loader:false, profileInfo: action.payload && action.payload.Record && action.payload.Record[0], profileImage: action.payload && action.payload.Record && action.payload.Record[0].profileImage , profileImageBase64:action.payload && action.payload.Record && action.payload.Record[0].profileImage || "" }
        }
        case 'update_profile': {
            return { ...state, success: false,error: false,message : action.payload }
        }
        default:
            return { ...state }
    }
}