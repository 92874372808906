import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {  colorPropType } from './Types'

const CTableBody = forwardRef(
    ({ children, className, color, ...rest }, ref) => {
        const _className = classNames(
            {
                [`table-${color}`]: color,
            },
            className,
        )

        return (
            <tbody className={_className ? _className : undefined} {...rest} ref={ref}>
                {children}
            </tbody>
        )
    }
)



CTableBody.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
}

export default CTableBody
