const INIT_STATE = {
  Team: 0,
  TwNumber: 0,
  Stores: 0,
  Suppliers: 0,
  trustFundDetail: [],
  cashAccountDetail: [],
  bankAccountDetail: [],
  currencyExchangeStatisticData: [],
  clientInvoiceAndReceiptData: null,
  clientInvoiceAndReceiptDataLoader: false,
  supplierInvoiceAndReceiptData: null,
  supplierInvoiceAndReceiptLoader: false,
  companyExpensesGraphLoader: false,
  companyExpensesGraphData: [],

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
   

    case "companyExpensesGraphSpinner_START": {
      return {
        ...state,
        companyExpensesGraphLoader: true,
      };
    }
    case "companyExpensesGraph_SUCCESS": {
      return {
        ...state,
        companyExpensesGraphData: action.payload.Record,
        companyExpensesGraphLoader: false,
      };
    }
    case "supplierInvoiceAndReceiptSpinner_START": {
      return {
        ...state,
        supplierInvoiceAndReceiptDataLoader: true,
      };
    }
    case "supplierInvoiceAndReceipt_SUCCESS": {
      return {
        ...state,
        supplierInvoiceAndReceiptData: action.payload.Record,
        supplierInvoiceAndReceiptLoader: false,
      };
    }
    case "clientReceiptInvoiceSpinner_START": {
      return {
        ...state,
        clientInvoiceAndReceiptDataLoader: true,
      };
    }
    case "clientReceiptAndInvoice_SUCCESS": {
      return {
        ...state,
        clientInvoiceAndReceiptData: action.payload.Record,
        clientInvoiceAndReceiptDataLoader: false,
      };
    }
    case "currencyExchangeStatistic_SUCCESS": {
      return {
        ...state,
        currencyExchangeStatisticData: action.payload.Record,
      };
    }
    case "statisticsOfCashAccount_SUCCESS": {
      return {
        ...state,
        cashAccountDetail: action.payload.Record,
      };
    }
    case "bankAccountStatistics_SUCCESS": {
      return {
        ...state,
        bankAccountDetail: action.payload.Record,
      };
    }

    case "trustFundStatistic_SUCCESS": {
      return {
        ...state,
        trustFundDetail: action.payload.Record,
      };
    }

    case "getDashboard_SUCCESS": {
      localStorage.setItem(
        "profileImage",
        action.payload.Record[0].profileImage
      );
      localStorage.setItem(
        "image",
        action.payload.Record[0].profileImageBase64
      );
      localStorage.setItem("address", action.payload.Record[0].address);
      return {
        ...state,
        Team: action.payload.Record[0].users,
        Stores: action.payload.Record[0].stores,
        Suppliers: action.payload.Record[0].suppliers,
      };
    }
    case "getAdminDashboardData_SUCCESS": {
      return { ...state, Team: action.payload.Record[0].totalUser };
    }
    case "getTwNumberCount_SUCCESS": {
      return { ...state, TwNumber: action.payload.Record[0].totalNumber };
    }

    case "newUserActivated_SUCCESS": {
      const team = parseInt(state.Team) + 1;
      return { ...state, Team: team };
    }

    default:
      return state;
  }
};
