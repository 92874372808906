import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment'
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
const Filter = () => {
  const dispatch = useDispatch();
  const receiptProps = useSelector((state) => state.receiptReducer);
  const comProps = useSelector((state) => state.seReducer);
  const { Store } = receiptProps;
  const { createdBy } = comProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    // store: Yup.string().required(
    //   <IntlMessages id="storeFilter.store.message" />
    // ),
    date: Yup.array().required(
      <IntlMessages id="storeFilter.date.message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(),new Date().toISOString()], // Default value here
    },
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {

    values.startDate = values.date[0];
    values.endDate = values.date[1];
    dispatch(
      DataGetAction("getAllStoreExpense", values, "fetchingBankBalanceSheet")
    );
  };

  const fetchAllBankAndCurrencyList = () => {
    dispatch(DataGetAction("getAllBank", { query: "all" }));
    dispatch(DataGetAction("getReceiptDropDown", ""));
  };

  useEffect(fetchAllBankAndCurrencyList, []);

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );
  };

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };


  return (
   
      <CForm className="d-flex" style={{gap : 16}} onSubmit={handleSubmit(onFinish)}>
        {/* date */}
        <div className="">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-12" />
            </DateRangePicker>
             
            )}
          />
            <div className="error-message">{errors.date?.message}</div>
        </div>
        {/* store */}
        {/* <div className="col-md-3">
          <div className="">
          <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  placeholder=   {intlPlaceholder("storeFilter.store.placeholder")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                 
                  options={renderOptions([...Store], "label", "key")}
                />
              )}
            />
            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div> */}
        {/* status */}
        {/* <div className="col-md-2">
          <div className="">
            <CFormSelect {...register("status")}
           
            >
              <option value="" hidden>
                {intlPlaceholder("storeFilter.status.placeholder")}
              </option>
              <option value="all">{intlPlaceholder("All")}</option>
              <option value="approved">{intlPlaceholder("Approved")} </option>
              <option value="pending">{intlPlaceholder("Pending")}</option>
            </CFormSelect>
            <div className="invalid-feedback">{errors.status?.message}</div>
          </div>
        </div> */}
        {/* created by */}
        {/* <div className="col-md-2">
          <div className="">
            <CFormSelect {...register("createdBy")}>
              <option value="" hidden>
                {intlPlaceholder("storeFilter.createdBy.placeholder")}
              </option>

              <option value="all">{intlPlaceholder("All")}</option>
              {createdBy &&
                createdBy.map((Item) => (
                  <option value={Item._id}>{Item.name}</option>
                ))}
            </CFormSelect>
            <div className="invalid-feedback">{errors.createdBy?.message}</div>
          </div>
        </div> */}

        <div >
          <div className="">
            <CLoadingButton className="d-flex align-items-center" type="submit" color="primary">
            <i className="gx-icon-filter-300 me-1 text-white"></i>
              <IntlMessages id="generateButton" />
            </CLoadingButton>
          </div>
        </div>
      </CForm>
    
  );
};

export default Filter;
