import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import QuickSaleProductList from "./QuickSaleProductList";
import {
  CForm,
  CButton,
  CLoadingButton,
  CFormInput,
  CFormCheck,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import IntlMessages from "../../../util/IntlMessages";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
import PhoneInput from "react-phone-input-2";
import CheckUserAccess from "src/util/checkUserAccess";

const QuickListForm = () => {
  const dispatch = useDispatch();
  const [phoneNum, setPhoneNum] = useState("");
  const [sendPdfMessage, setSendPdfMessage] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [productIds, selectProductIds] = useState([]);
  const quickProps = useSelector((state) => state.quickSaleReducer);
  const { addSpinner, addSuccess, RecordSuccess } = quickProps;
  const history = useHistory();

  const productProps = useSelector((state) => state.ProductR);
  const { localProducts, ProductList, loadingProductSpinner } = productProps;

  const selectAtLeastOneProduct = intlPlaceholder("selectAtLeastOneProduct");

  const validationSchema = Yup.object().shape({
    product: Yup.array()
      .min(1, selectAtLeastOneProduct)
      .required(
        <IntlMessages id="distributeProducts.pleaseSelectProduct_message" />
      ),
  });

  if (addSuccess) {
    dispatch({
      type: "reset_add",
    });
    history.push("/quickSale");
    dispatch({ type: "openSaleModalDialog", payload: RecordSuccess });
  }

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const { handleSubmit, reset, formState, control, setValue, getValues } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = () => {
    if (localProducts.length > 0) {
      let updatedProducts = localProducts.map((item) => {
        return {
          Price: item.localPrice,
          Qty: item.Qty,
          product: item._id,
          productName: item.name,
          unit: item.unit,
          unitName: item.unitName,
          retail: item.retail,
          subunitName: item.subunitName,
          subunit: item.subunit,
          packaging: item.packaging,
        };
      });

      const data = {
        products: updatedProducts,
        originalProduct: ProductList,
        customer: {
          phoneNumber: phoneNum.length > 0 ? `+${phoneNum}` : "",
          customerName: customerName.value,
          sendPDFWANumber: sendPdfMessage,
        },
      };
      dispatch(
        DataRequestAction(
          "POST",
          "addSale",
          data,
          "",
          "StartSpinnerAddSAlE",
          false
        )
      );
      setDisableSaveBtn(true);
      reset();

      dispatch({
        type: "ViewTheProduct",
      });
    } else {
      notify("error", intlPlaceholder("noProductSelected"));
    }
  };

  if (quickProps.success) {
    let messageText = intlPlaceholder("distribute.product.successfully");
    // message.success(messageText);
    notify("success", messageText);
    dispatch({
      type: "distributed_UPDATE",
    });
    history.push({
      pathname: "/viewStoreInvoice",
      state: { InvoiceNo: disProps.InvoiceNo },
    });
  }

  if (quickProps.error) {
    let messageText = intlPlaceholder("distribute.product.error");
    notify("error", messageText);
    dispatch({
      type: "product_update_error",
    });
  }

  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getAllProductStock",
        { query: "qty", stockQty: "search" },
        "LoadingProduct"
      )
    );
    dispatch(DataGetAction("getCustomers"));
  };

  useEffect(fetchList, []);

  const addProductInLocalArray = (values) => {
    dispatch({
      type: "setLocalProduct",
      payload: values,
    });
  };

  if (quickProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };
  let checkBoxPermission = CheckUserAccess("waNotification", ["quickSale"]);
  return (
    <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="mb-3">
            <Controller
              control={control}
              name="product"
              key={1}
              //defaultValue={[]}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  isLoading={loadingProductSpinner}
                  disabled={
                    localProducts && localProducts.length > 0 ? true : false
                  }
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                  onChange={(values) => {
                    let selectedIds = values.map((item) => item.value);
                    selectProductIds(selectedIds);

                    onChange(selectedIds);
                  }}
                  options={renderOptions(ProductList, "name", "_id")}
                />
              )}
            />

            <div className="error-message">{errors.product?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <CFormInput
            type="customerName"
            id="customerName"
            placeholder="Nom du client"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </div>
        <div className="col-md-6">
          <span style={{ width: "100%" }}>
            <Controller
              control={control}
              name={"phoneNumber"}
              render={(props) => {
                return (
                  <PhoneInput
                    country={"gn"}
                    containerStyle={{ height: "40px" }}
                    inputStyle={{ height: "40px" }}
                    value={phoneNum}
                    onChange={(v) => {
                      setPhoneNum(v);
                      props.field.onChange(v);
                    }}
                  />
                );
              }}
            />
          </span>
        </div>
        {checkBoxPermission && (
          <div className="col-md-5 d-flex align-items-center gap-2" style={{  border: "1px solid #D3D3D3" }}>
            
              <>
                <CFormCheck
                  type="checkbox"
                  onChange={(e) => {
                    setSendPdfMessage(e.target.checked);
                  }}
                />
                <p className="mb-0">
                  <IntlMessages id="sendPDFCheckboxLabel" />
                </p>
              </>
            
          </div>
        )}
      </div>
      
      <div className="row">
        <div className="col-md-12 mb-4">
          <CButton
            disabled={productIds && productIds.length > 0 ? false : true}
            className="d-block w-100"
            type="submit"
          >
            <i className="icon icon-add me-1"></i>
            <IntlMessages id="distributeProducts.add" />
          </CButton>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <QuickSaleProductList
            setValue={setValue}
            quantityTitle={"sockQty"}
            reset={setValue}
            setDisableSaveBtn={setDisableSaveBtn}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="dark"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "product_UPDATE",
              });
              reset();
              history.push("/quickSale");
            }}
          >
            <IntlMessages id="distributeProducts.cancel" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CButton
            className="d-block w-100 btn btn-light btn-block"
            color="danger"
            variant="outline"
            onClick={() => {
              dispatch({
                type: "RESET_LOCAL_PRODUCT",
              });
            }}
          >
            <IntlMessages id="distributeProducts.reset" />
          </CButton>
        </div>
        <div className="col-md-4">
          <CLoadingButton
            disabled={disableSaveBtn}
            className="d-block w-100"
            onClick={() => onFinish()}
            color="primary"
            loading={addSpinner}
          >
            <span className="text-white">
              <IntlMessages id="distributeProducts.create" />
            </span>
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default QuickListForm;
