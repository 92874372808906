import React, { useState, useEffect } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";

import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage
} from "src/constants/CommonFn";
import { cilFile } from "@coreui/icons";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { DataGetAction } from "../../../redux/actions/http";
import CheckUserAccess from "../../../util/checkUserAccess";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const SupplierSummaryList = ({ filterDate, setFilterDate, setFilteredCurrency }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  const { locale } = useSelector((state) => state.Setting);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  let headerTitleAccountSummery = intlPlaceholder("report.supplier.summary");
  let textIn = intlPlaceholder("report.in");
  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("Description"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("supp.debit"), style: "tableHeader" },
      { text: intlPlaceholder("supp.credit"), style: "tableHeader" },
      { text: intlPlaceholder("Balance"), style: "tableHeader" },
    ],
  ];

  let TotalText = intlPlaceholder("Total");
  let EmailLabel = intlPlaceholder("Email");    
  let CellLabel = intlPlaceholder("Cell");

  const blProps = useSelector((state) => state.balanceSheet);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;
  const {
    SupplierSummery,
    SupplierSummeryOriginal,
    balanceSummerySupplierSpinner,
  } = blProps;
  const history = useHistory();

  const fetchBalanceSheet = () => {



    dispatch(
      DataGetAction(
        "BalanceSummerySupplier",
        {
          startDate: filterDate.startDate,
          endDate: filterDate.endDate,
          initialEndDate: filterDate.startDate,
          noDateSelected: "selected"
          // startDate: moment().startOf("year").toString(),
          // endDate: moment().toString(),
          // initialEndDate: moment().toString(),
          // noDateSelected: "selected",
        },
        "balanceSummerySupplierSpinner"
      )
    );
  };

  useEffect(fetchBalanceSheet, []);

  const dateFormat = "YYYY/MM/DD";

  const viewDetail = (data) => {
    history.push({
      pathname: "/supplierBalanceSheet",
      state: { data, filterDate },
    });
  };

  const columns = [
    {
      label: <IntlMessages id="balanceSheet.names" />,
      key: "supplierName",
      _style: { width: "60%" },
    },
    {
      label: <IntlMessages id="balanceSheet.currency" />,
      key: "currencySymbole",
      _style: { width: "20%" },
    },

    {
      label: <IntlMessages id="balanceSheet.montant" />,
      key: "balance",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.initial" />,
      key: "initialBalance",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.totalOrder" />,
      key: "TotalOrder",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="balanceSheet.totalSettlement" />,
      key: "TotalSettlement",
      _style: { width: "40%" },
    },
  

    {
      label: "",
      key: "actions",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    },
  ];

  const fetchRecord = (data) => {
    if (data) {
      setFilterDate({
        startDate: moment(data[0]).toString(),
        endDate: moment(data[1]).toString(),
      });
      dispatch(
        DataGetAction(
          "BalanceSummerySupplier",
          {
            startDate: moment(data[0]).toString(),
            endDate: moment(data[1]).toString(),
            initialEndDate: moment(data[0]).toString(),
            noDateSelected: "selected",
          },
          "balanceSummerySupplierSpinner"
        )
      );
    } else {
      dispatch(
        DataGetAction(
          "BalanceSummerySupplier",
          {
            startDate: moment().startOf("year").toString(),
            endDate: moment().toString(),
            initialEndDate: moment().toString(),
            noDateSelected: "noDateSelected",
          },
          "balanceSummerySupplierSpinner"
        )
      );
    }
  };

  const downloadPDF = async (data) => {
    const token = localStorage.getItem("token");
    const { currencyId, supplierId, currencySymbole, supplierName } = data;
    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();

    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }

    let param = {
      startDate: startDate,
      endDate: endDate,
      supplier: supplierId,
      currency: currencyId,
    };

    param = JSON.stringify(param);
    fetch(`${ROOT_URL}/supplierBalanceSheet/${param}`, { headers }).then(
      (response) => {
        response.json().then((response) => {
          let Record = response.Record.reverse();

          let Total = 0;
          let debitTotal = 0;
          let creditTotal = 0;

          Record.forEach((Item, index) => {



            let debit = parseFloat(Item.debit).toFixed(2);
            let credit = parseFloat(Item.credit).toFixed(2);
            let balance = parseFloat(Item.balance).toFixed(2);

            debitTotal = debitTotal + parseFloat(debit);
            creditTotal = creditTotal + parseFloat(credit);
            Total = Total + parseFloat(balance);

            debit = Item.debit !== 0 ? NumberWithCommas(debit) : "";
            credit = Item.credit !== 0 ? NumberWithCommas(credit) : "";
            balance = NumberWithCommas(balance);
            let date = ChangeDateFormate(Item.createdAt);

            let objArr = [date];

            //let desc = conversionInLanguage(locale.locale, Item.description)
            let desContent = Item.remark ? Item.remark : conversionInLanguage(locale.locale, Item.description);
            let desc = [
              {
                text: desContent,
                alignment: "left"
              }
            ]
            let refContent = `${Item.prefix}-${Item.ourId}/${Item.referenceNo}`
            let ref = [
              {
                text: refContent,
                alignment: "center"
              }
            ]

            objArr.push(desc);
            objArr.push(ref);
            objArr.push(debit);
            objArr.push(credit);
            objArr.push(balance);

            bodyData = [...bodyData, objArr];
          });

          debitTotal = parseFloat(debitTotal).toFixed(2);
          creditTotal = parseFloat(creditTotal).toFixed(2);

          let letTotal = [
            {
              text: TotalText,
              style: "tableBody",
              colSpan: 3,
              alignment: "center",
            },
            {},
            {},
            { text: NumberWithCommas(debitTotal), style: "tableBody" },
            { text: NumberWithCommas(creditTotal), style: "tableBody" },
            {},
          ];
          bodyData = [...bodyData, letTotal];

          let headerTitle = ` ${headerTitleAccountSummery.toUpperCase()} ${supplierName.toUpperCase()} ${textIn.toUpperCase()} ${currencySymbole} ${reportFrom} ${ChangeDateFormate(
            startDate,
            "DD-MM-YYYY"
          )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;

          let footerAddress = localStorage.getItem("address");

          let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
          let docDefinition = {
            pageOrientation: 'landscape',
            content: [
              {
                image: `data:image/jpeg;base64,${logoImage}`,
                fit: [250, 250],
                width: 150,
                height: 150,
              },
              {
                text: headerTitle,
                margin: [15, 10],
                style: { fontSize: 22, bold: true, alignment: "center" },
              },
              {
                style: "tableBody",
                headerRows: 1,

                table: {
                  widths: [80, "*", 100, 110, 110, 110],
                  body: bodyData,
                },
              },
            ],
            footer: {
              text: [
                {
                  text: footerAddress,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
                {
                  text: `\n ${footerAddress2}`,
                  alignment: "center",
                  fontSize: 10,
                  width: "100%",
                },
              ],
            },

            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
                textAlign: "center",
              },
              tableExample: {
                margin: [0, 5, 0, 15],
                width: "100%",
              },
              tableOpacityExample: {
                margin: [0, 5, 0, 15],
                fillColor: "blue",
                fillOpacity: 0.3,
              },
              tableHeader: {
                bold: true,
                fontSize: 14,
                color: "black",
                alignment: "center"
              },
              tableBody: {
                bold: false,
                fontSize: 10,
                alignment: "right"
              },
            },
          };
          pdfMake.createPdf(docDefinition).download("supplerSummery.pdf");
        });
      }
    );
  };

  const pdfAccess = CheckUserAccess("supplierAccounts", ["pdf"])

  return (
    <>
      <CSmartTable
        loading={balanceSummerySupplierSpinner}
        sorterValue={{ column: "supplierName", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={balanceSummerySupplierSpinner ? [] : SupplierSummery}
        columns={columns}
        columnFilter
        onColumnFilterChange={(obj) => {


          if (obj['currencySymbole'] !== undefined || obj['currencySymbole'] !== null) {
            let value = obj['currencySymbole']
            setFilteredCurrency(value)
          }
        }}
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          supplierName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.supplierName && record.supplierName}
                </td>
              </>
            );
          },
          currencySymbole: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.currencySymbole && record.currencySymbole}
                </td>
              </>
            );
          },
          balance: (record) => {
            return (
              <td className="text-end">
                {record.balance && record.balance > 0 ? (
                  <span style={{ color: "green" }}>
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {ChangeNumberFormate(parseFloat(record.balance).toFixed(2))}
                  </span>
                )}
              </td>
            );
          },
          initialBalance: (record) => {
            return (
              <td className="text-end">
                {record.initialBalance && record.initialBalance > 0 ? (
                  <span style={{ color: "green" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.initialBalance).toFixed(2)
                    ) || 0}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    {ChangeNumberFormate(
                      parseFloat(record.initialBalance).toFixed(2)
                    ) || 0}
                  </span>
                )}
              </td>
            );
          },
          TotalOrder: (record) => {
            return (
              <td className="text-end">
                   <span>
                    {ChangeNumberFormate(
                      parseFloat(record.TotalOrder).toFixed(2)
                    ) || 0}
                  </span>
              </td>
            );
          },
          TotalSettlement: (record) => {
            return (
              <td className="text-end">
                   <span>
                    {ChangeNumberFormate(
                      parseFloat(record.TotalSettlement).toFixed(2)
                    ) || 0}
                  </span>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                  <CButton
                    color="warning"
                    className="me-3"
                    onClick={() => viewDetail(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-editor"></span>
                      <IntlMessages id="viewDetails" />
                    </span>
                  </CButton>

                  {pdfAccess && (
                    <CButton color="danger" onClick={() => downloadPDF(record)}>
                      <span style={{ color: "white" }}>
                        <i className="gx-icon-pdf-300 me-1"></i>
                        <IntlMessages id="pdf" />
                      </span>
                    </CButton>
                  )}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
        summary={(pageData) => {
          let totalBalance = 0;
          let totalInitialBalance = 0;
          let totalOrder = 0;
          let totalSettlement = 0
          pageData.forEach(({ balance, initialBalance, TotalOrder, TotalSettlement }) => {
            totalBalance += parseFloat(balance);
            totalInitialBalance += parseFloat(initialBalance);
            totalOrder += parseFloat(TotalOrder);
            totalSettlement += parseFloat(TotalSettlement);
          });

          return (
            <>
              <tr>
                <th colSpan={2} className="text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalBalance.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalInitialBalance.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalOrder.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th  className="text-end">
                  <>
                    <h5>{ChangeNumberFormate(totalSettlement.toFixed(2)) || 0}</h5>
                  </>
                </th>
                <th></th>
              </tr>
            </>
          );
        }}
      />
    </>
  );
};

export default SupplierSummaryList;
