import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    ceLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Banks: [],
    fetchBankInfo: false,
    CompanyAccount: [],
    fetchDetail: false,
    createdBy: [],
    allStoreExpenseSpinner: false,
    refundStoreExpenseSuccess: false,
    refundStoreExpenseError: false,
    message : '',
    isStoreExpensedAdded : false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case "refundStoreExpense_SUCCESS": {
            return {
              ...state,
              refundStoreExpenseSuccess: true,
              message: action.payload.message,
            };
          }
      
          case "refundStoreExpense_FAILURE": {
            return {
              ...state,
              refundStoreExpenseError: true,
              message: action.payload,
            };
          }

        case 'createdByFromStoreExpense': {
            const createdByArr = [];
            state.Banks && state.Banks.map(Item => {
                if (Item.createdByUser) {
                    createdByArr.push(Item.createdByUser[0]);
                }
                return Item
            })
            return { ...state, createdBy: _.uniqBy(createdByArr, 'id') }
        }

        case 'fetchingStoreDetail_START': {
            return { ...state, fetchDetail: true }
        }

        case 'getAllStoreExpense_SUCCESS': {
            return { ...state, Banks: action.payload.Record, fetchBankInfo: false, fetchDetail: false };
        }
        case 'allStoreExpenseSpinner_START':{
            return { ...state,  allStoreExpenseSpinner: true };
        }
        case 'allStoreExpenseSpinner_STOP':{
            return { ...state,   allStoreExpenseSpinner: false };
        }
        case 'show_se_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }

        case 'se_START': {
            return { ...state, ceLoader: true };
        }
        case 'se_STOP': {
            return { ...state, ceLoader: false };
        }

        case 'addStoreExpense_SUCCESS': {
            return { ...state, success: true, message: 'recordAddedSuccessfully', isStoreExpensedAdded : true, Banks: [action.payload.Record, ...state.Banks], ceLoader: false };
        }
        case 'addStoreExpense_FAILURE': {
            return { ...state, error: true, success: false, ceLoader: false, message: action.payload };
        }


        case 'editStoreExpense_SUCCESS': {
            let oldCat = state.Banks;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, isStoreExpensedAdded : true, Banks: newCat, ceLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editStoreExpense_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, ceLoader: false, message: action.payload, modalVisible: false };
        }

        case 'se_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                ceLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null,
                refundStoreExpenseSuccess: false,
                refundStoreExpenseError: false,
                isStoreExpensedAdded : false,

            };
        }

        case 'deleteStoreExpense_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Banks;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Banks: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }

}
