import React from "react";
import { CCard, CCardBody, CCol, CRow, CCardHeader } from "@coreui/react-pro";
import EditQuickSaleForm from "./EditQuickSaleForm";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { Modal } from "src/components/Modals/CModal";
const EditQuickSaleModal = ({editModalVisible, _id, }) => {

  let title = intlPlaceholder("bc.quickSale")

  return (
    <Modal
      title={`${title}`}
      visible={editModalVisible}
    >
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardBody>
              <EditQuickSaleForm _id={_id} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
   </Modal>
  );
};

export default EditQuickSaleModal;
