import React, { useEffect } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import SmartTableBasixExample from "./SmartTableBasixExample";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import AddCompanyModal from "./components/AddCompanyModal";
const SmartTable = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.company);
  const { recordEditSuccess } = comProps;
  const fetchCategory = () => {
    dispatch(DataGetAction("getAllCompanies", { query: "all" }));
  };
  useEffect(fetchCategory, []);
  if (recordEditSuccess) {
    fetchCategory();
    dispatch({ type: "ResetCompany_RESET" });
  }
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <strong>
                <IntlMessages id="admin.Company" />
              </strong>
              <AddCompanyModal />
            </CCardHeader>
            <CCardBody>
              <DocsExample href="components/smart-table/">
                <SmartTableBasixExample />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SmartTable;
