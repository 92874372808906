import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import {
  cilLockLocked,
  cilCompass,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useDispatch,useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import IntlMessages from "../../util/IntlMessages";

const AppHeaderDropdown = () => {
  const {LogeInUser} = useSelector(state => state.auth)

  const companyName = localStorage.getItem('companyName');


  const dispatch = useDispatch()
  const logoutFn = () => {
    dispatch({
      type: "logout_success"
    })
  }
  const history = useHistory()

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        {LogeInUser.role === 'team' ?  LogeInUser?.name : companyName}
        <i className="icon icon-chevron-down ms-1"></i>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
       
  
        <CDropdownItem >
          <span style={{cursor : 'pointer'}} className="mt-3" onClick={()=>{history.push('/profile')}}>
            <CIcon icon={cilUser} className="me-2" />
            
            <IntlMessages id="dropdown.profile" />
          </span>
        </CDropdownItem>
        <CDropdownItem >
          <span style={{cursor : 'pointer'}} className="mt-3" onClick={()=>{history.push('/resetPassword')}}>
            <CIcon icon={cilCompass} className="me-2" />
            
            <IntlMessages id="dropdown.resetPassword" />
          </span>
        </CDropdownItem>
      
        {/* <CDropdownDivider /> */}
        <CDropdownItem href="#" onClick={() => logoutFn()}>
          <CIcon icon={cilLockLocked} className="me-2" />
          
          <IntlMessages id="dropdown.logout" />
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
