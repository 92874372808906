import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import React from "react";
import IntlMessages from "src/util/IntlMessages";
import AddQuotationsForm from "./AddQuotationsForm";

const AddQuotations = () => {
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <h1>
              <IntlMessages id="addQuotation.title" />
            </h1>
          </CCardHeader>
          <CCardBody>
            <AddQuotationsForm/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AddQuotations;
