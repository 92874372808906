const menuData = [
  {
    text: "manageUsers",
    value: "manageUsers",
    subMenu: [],
  },
  {
    text: "propertyManagement",
    value: "manageProperty",
    subMenu: ["property", "contract"],
  },
  {
    text: "saleInvoice",
    value: "saleInvoice",
    subMenu: ["addSaleInvoice", "editSaleInvoice", "viewSaleInvoice"],
  },
  {
    text: "waNotification",
    value: "waNotification",
    subMenu: ["invoice", "storeToStore", "receipt", "trustFund", "quickSale"],
  },
  {
    text: "delivered",
    value: "delivered",
    subMenu: ["addDelivered", "editDelivered", "viewDelivered"],
  },
  {
    text: "userRoles",
    value: "userRoles",
    subMenu: ["addUserRoles", "editUserRoles"],
  },
  {
    text: "stockEntry",
    value: "stockEntry",
    subMenu: ["addStock", "stock"],
  },
  {
    text: "quotations",
    value: "quotations",
    subMenu: ["addQuotations", "editQuotations", "quotations"],
  },
  {
    text: "quickSale",
    value: "quickSale",
    subMenu: ["addQuickSale", "editQuickSale", "quickSale"],
  },
  {
    text: "companyToStore",
    value: "companyToStore",
    subMenu: ["distribute", "viewStoreInvoice"],
  },
  {
    text: "storeToStore",
    value: "storeToStore",
    subMenu: ["storeInvoiceDetail"],
  },

  {
    text: "property",
    value: "property",
    subMenu: ["property"],
  },
  {
    text: "contract",
    value: "contract",
    subMenu: ["contract"],
  },
  {
    text: "supplierInvoices",
    value: "supplierInvoices",
    subMenu: ["viewInvoice"],
  },

  {
    text: "storeInvoices",
    value: "storeInvoices",
    subMenu: ["storeInvoiceDetail"],
  },
  {
    text: "supplierReceipts",
    value: "supplierReceipts",
    subMenu: ["duesReceipt"],
  },
  {
    text: "storeReceipts",
    value: "storeReceipts",
    subMenu: ["storeToStoreCredit", "viewReceipt", "viewStoreReceipt"],
  },

  {
    text: "invoices",
    value: "invoices",
    subMenu: ["viewStoreInvoice", "createInvoice"],
  },
  {
    text: "receipts",
    value: "receipts",
    subMenu: ["viewStoreReceipt"],
  },
  {
    text: "currencyExchange",
    value: "currencyExchange",
    subMenu: ["viewCurrencyExchangeReceipt"],
  },

  {
    text: "companyExpenses",
    value: "companyExpenses",
    subMenu: ["viewExpenseDetail"],
  },
  {
    text: "salary",
    value: "salary",
    subMenu: ["employeeBalanceSheet", "viewSalaryDetail"],
  },

  {
    text: "storeExpenses",
    value: "storeExpenses",
    subMenu: ["viewStoreExpenseDetail"],
  },
  {
    text: "storeWithDrawals",
    value: "storeWithDrawals",
    subMenu: [],
  },
  {
    text: "fundTransfers",
    value: "fundTransfers",
    subMenu: ["fundTransferDetail"],
  },
  // {
  //   text: "trustFunds",
  //   value: "trustFunds",
  //   subMenu : ['viewTrustDetail']
  // },
  {
    text: "deposit",
    value: "deposit",
    subMenu: ["viewTrustDetail"],
  },
  {
    text: "withdraw",
    value: "withdraw",
    subMenu: ["viewTrustDetail"],
  },

  {
    text: "companyAccounts",
    value: "companyAccounts",
    subMenu: [
      "bankBalanceSheet",
      "viewTrustDetail",
      "fundTransferDetail",
      "viewReceipt",
      "viewSalaryDetails",
      "viewExpenseDetail",
      "storeToStoreReceiptDetail",
      "viewStoreReceipt",
      "viewCurrencyExchangeReceipt",
    ],
  },
  {
    text: "storeAccounts",
    value: "storeAccounts",
    subMenu: [
      "storeBalanceSheet",
      "viewSalaryDetails",
      "viewStoreExpenseDetail",
      "viewStoreReceipt",
      "storeInvoiceDetail",
      "storeToStoreCredit",
      "viewStoreInvoice",
      "viewReceipt",
    ],
  },
  {
    text: "supplierAccounts",
    value: "supplierAccounts",
    subMenu: ["supplierBalanceSheet", "duesReceipt", "viewInvoice"],
  },
  {
    text: "supplierOrderHistory",
    value: "supplierOrderHistory",
    subMenu: [],
  },
  {
    text: "customerOrderHistory",
    value: "customerOrderHistory",
    subMenu: [],
  },
  {
    text: "trustUserAccounts",
    value: "trustUserAccounts",
    subMenu: ["TrustUserBalanceSheetList"],
  },
  // {
  //   text: "admin",
  //   value: "admin",
  //   subMenu : []
  // },
  // {
  //   text: "employee",
  //   value: "employee",
  //   subMenu : []
  // },
  {
    text: "trustUser",
    value: "trustUser",
    subMenu: [],
  },
  {
    text: "account",
    value: "account",
    subMenu: [],
  },
  {
    text: "category",
    value: "category",
    subMenu: [],
  },
  {
    text: "product",
    value: "product",
    subMenu: [],
  },
  {
    text: "bank",
    value: "bank",
    subMenu: [],
  },
  {
    text: "currency",
    value: "currency",
    subMenu: [],
  },
  {
    text: "customer",
    value: "customer",
    subMenu: [],
  },
  {
    text: "stores",
    value: "stores",
    subMenu: [],
  },
  {
    text: "supplier",
    value: "supplier",
    subMenu: [],
  },
  {
    text: "units",
    value: "units",
    subMenu: ["addUnit", "editUnit"],
  },
  {
    text: "stockStatus",
    value: "stockStatus",
    subMenu: ["stockStatus", "viewStockStatus"],
  },
  {
    text: "debitNotes",
    value: "debitNotes",
    subMenu: ["debitNotes"],
  },
  {
    text: "creditNotes",
    value: "creditNotes",
    subMenu: ["creditNotes"],
  },
  {
    text: "selectStoreName",
    value: "selectStoreName",
    subMenu: ["selectStoreName"],
  },
];

export default menuData;
