import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    categoryLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Categories: [],
    fetchCategoryInfo: false,
    deleteError:false,
    categorySpinner: false ,
    editModalVisible : false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case 'getAllCategory_SUCCESS': {
            return { ...state, Categories: action.payload.Record, fetchCategoryInfo: false };
        }
        case 'categorySpinner_START':{
            return { ...state,  categorySpinner: true };
        }
        case 'categorySpinner_STOP':{
            return { ...state,   categorySpinner: false };
        }
        case 'show_category_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'edit_category_modal': {
            return { ...state, editModalVisible: !state.editModalVisible, selectedItem: action.payload };
        }
   
        case 'category_START': {
            return { ...state, categoryLoader: true };
        }
        case 'category_STOP': {
            return { ...state, categoryLoader: false };
        }

        case 'addCategory_SUCCESS': {
            return { ...state, success: true, message: "recordAddedSuccessfully", Categories: [action.payload.Record, ...state.Categories], categoryLoader: false };
        }
        case 'addCategory_FAILURE': {
            return { ...state, error: true, success: false, categoryLoader: false, message: action.payload };
        }


        case 'editCategory_SUCCESS': {
            let oldCat = state.Categories;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Categories: newCat, categoryLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editCategory_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, categoryLoader: false, message: action.payload, modalVisible: false };
        }

        case 'category_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                categoryLoader: false,
                resetPasswordSuccess: false,
                deleteError: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null,
                editModalVisible : false,

            };
        }

        case 'deleteCategory_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Categories;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Categories: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        case 'deleteCategory_ERROR': {
            return { ...state,  selectedItem: null, error: true, message: action.payload, deleteError:true };
        }
        default:
            return state;
    }

}
