import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CMultiSelect,
  CFormSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGetAction } from "../../../redux/actions/http";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {selectStyle} from '../../../constants/CommonFn'
const SubProductForm = (props) => {
  const dispatch = useDispatch();
  const [defaultPriceValue, setDefaultPriceValue] = useState();
  const prodProps = useSelector((state) => state.productReducer);
  const productProps = useSelector((state) => state.ProductR);

  const { ProductList, productForMultiSelect } = productProps;
  const { Currency, Units, selectedCurrency, selectedReferenceNo } = prodProps;



  const validationSchema = Yup.object().shape({
    products: Yup.array()
      .required(
        <IntlMessages id="product.addProductPage.productName_message" />
      )
      .nullable(),
    currency: Yup.string().required(
      <IntlMessages id="product.addProductPage.selectCurrency_message" />
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, reset, formState, control, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {

    const currencySelected = Currency.filter((Item) => Item._id === values.currency);
    values.currencyType = currencySelected[0].type;
    let newObj = [];
    let selectedProduct = values.products;

    selectedProduct.map((Item) => {
      let obj = JSON.parse(Item);

      const localUnitPrice =
        parseFloat(obj.price) * parseFloat(currencySelected[0].factor);

      let objSingleProduct = {
        productId: obj._id,
        productName: obj.name,
        currencyFactor: currencySelected[0].factor,
        price: obj.price,
        quantity: "1",
        unit: obj.unit,
        supplierId: props.selectedSupplier,
        totalLocalPrice: localUnitPrice,
        currencyType: values.currencyType,
        currency: values.currency,
        totalUnitPrice: obj.price,
        localPrice: localUnitPrice,
      };
      newObj.push(objSingleProduct);
    });
    dispatch({
      type: "addSubProduct_SUCCESS",
      payload: newObj,
    });
    setDefaultPriceValue();
    // reset();
  };

  const fetchDropDownValues = () => {
    dispatch(DataGetAction("fetchProductDropDowns", ""));
    dispatch(DataGetAction("getAllProductStock", { query: "all" }));
  };
  useEffect(fetchDropDownValues, []);

  useEffect(() => {
    if(props.currencyDefault) {
      reset({ currency: props.currencyDefault})
    }
  }, [props.currencyDefault])

  const renderProducts = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => ({
        label: item.name,
        value: JSON.stringify(item),
      }));
    }
  };
  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="row mb-4">
        {/* products */}
        <div className="col-md-8">
          <div className="mb-3">
            <Controller
              control={control}
              name="products"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.products ? "is-invalid" : ""}
                  closeMenuOnSelect={false}
                  options={renderProducts(ProductList)}
                  autoBlur={true}
                  styles={selectStyle}
                  onChange={(value) => {
                    let ids =
                      value &&
                      value.length > 0 &&
                      value.map((item) => item.value);

                    onChange(ids);
                  }}
                  isSearchable={true}
                  isMulti={true}
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                />
              )}
            />

            <div className="invalid-feedback">{errors.products?.message}</div>
          </div>
        </div>
        {/* currency */}
        <div className="col-md-4">
          <div className="mb-3">
            <Controller
              control={control}
              name="currency"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <CFormSelect
                  className={errors.currency ? "is-invalid" : ""}
                  value={value}
                  //disabled={selectedCurrency !== "" ? true : false}
                  disabled={true}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    {intlPlaceholder("product.addProductPage.selectCurrency")}
                  </option>
                  {Currency &&
                    Currency.map((Item) => (
                      <option value={Item.key}>{Item.label}</option>
                    ))}
                </CFormSelect>
              )}
            />

            <div className="invalid-feedback">{errors.currency?.message}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CLoadingButton
            className="w-100 d-block"
            type="submit"
            color="primary"
            disabled={selectedReferenceNo === "" ? false : false}
          >
            <CIcon icon={cilPlus} className="me-2" />
            <IntlMessages id="product.addProductPage.addItem" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default SubProductForm;
