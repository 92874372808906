import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton
} from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import TrustUserSummaryList from "./components/TrustUserSummaryList";
import moment from "moment";
import { useDispatch ,useSelector} from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeDateFormate,
  conversionInLanguage
} from "src/constants/CommonFn";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { intlPlaceholder } from "src/util/intlPlaceholder";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const TrustFundAccounts = () => {

  
  const [filterDate, setFilterDate] = useState(null);

  const [filteredCurrency, setFilteredCurrency] = useState('');
  

  const dispatch = useDispatch();
  const {trustUserBalanceSummary} = useSelector((state) => state.balanceSheet);
  const {LogeInUser} = useSelector((state) => state.auth);
  const {locale} = useSelector((state) => state.Setting);

  const onDateChange = (data) => {
    if (data && data.length > 0 && data[0] !== null && data[1] !== null) {
      setFilterDate({
        startDate: moment(data[0]).toString(),
        endDate: moment(data[1]).toString(),
      });
      dispatch(
        DataGetAction(
          "getTrustUserBalanceSheet",
          {
            startDate: moment(data[0]).toString(),
            endDate: moment(data[1]).toString(),
            initialEndDate: moment(data[0]).toString(),
            noDateSelected: "selected",
          },
          "getTrustBalanceSheetSpinner"
        )
      );
    } else if (
      data &&
      data.length > 0 &&
      data[0] === null &&
      data[1] === null
    ) {
      dispatch(
        DataGetAction(
          "getTrustUserBalanceSheet",
          {
            startDate: moment().startOf("today").toString(),
            endDate: moment().toString(),
            initialEndDate: moment().toString(),
            noDateSelected: "noDateSelected",
          },
          "getTrustBalanceSheetSpinner"
        )
      );
    }
  };

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };


  let TotalText = intlPlaceholder("Total");
  let headerTitleAccountSummery = intlPlaceholder("trustUserAccountSummaryReport");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");


  let reportFrom = intlPlaceholder("report.from");
  let reportTo = intlPlaceholder("report.to");


  let headerBodyData = [
    [
      { text: intlPlaceholder("balanceSheet.names"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.currency"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.montant"), style: "tableHeader" },
      { text: intlPlaceholder("balanceSheet.initial"), style: "tableHeader" },
     
    ],
  ];


  const downloadSummaryPdf = async () => {
   

    let startDate = moment().startOf("year").toString();
    let endDate = moment().toString();
    if (filterDate) {
      startDate = filterDate.startDate;
      endDate = filterDate.endDate;
    }
   
    let Record = trustUserBalanceSummary;
    let bodyData = [...headerBodyData]

    
    if(filteredCurrency){
      Record = Record.filter((item)=>{
        let currencySymbole = item.currencySymbole.toLowerCase()
        let curr = filteredCurrency.toLowerCase()
        if(currencySymbole.includes(curr)){
          return item
        }
      })
    }


    Record.sort((a, b) => a.trustUserName.localeCompare(b.trustUserName));

    let Total = 0;
    let balanceTotal = 0;
    let initialBalanceTotal = 0;
    
    Record.forEach((Item) => {
      let name = [
        {
          text: Item.trustUserName,
          alignment: "left"
        }
      ];
      let currency =Item.currencySymbole
      let balance = parseFloat(Item.balance).toFixed(2);
      let initialBalance = parseFloat(Item.initial).toFixed(2);
      

    
      balanceTotal = balanceTotal + parseFloat(balance);
      initialBalanceTotal = initialBalanceTotal + parseFloat(initialBalance);
     

   
      balance = balance !== 0 ? NumberWithCommas(balance) : "";
      initialBalance = initialBalance !== 0 ? NumberWithCommas(initialBalance) : "";


      // let date = ChangeDateFormate(Item.createdAt);

      let objArr = [];
      objArr.push(name);
      objArr.push(currency);
      objArr.push(balance);
      objArr.push(initialBalance);
     
       bodyData = [...bodyData, objArr];
    });

    
    balanceTotal = parseFloat(balanceTotal).toFixed(2);
    initialBalanceTotal = parseFloat(initialBalanceTotal).toFixed(2);

    let letTotal = [
      {
        text: TotalText,
        style: "tableHeader",
        colSpan: 2,
        alignment: "center",
      },
      {},

      { text: NumberWithCommas(balanceTotal), style: "tableBody", bold: true },
      { text: NumberWithCommas(initialBalanceTotal), style: "tableBody", bold: true },
      
      
    ];
    bodyData = [...bodyData, letTotal];

     const logoImage = localStorage.getItem('image') ?  localStorage.getItem('image') : process.env.REACT_APP_LOGO_DUMMY;
    

    let headerTitle = `${headerTitleAccountSummery} ${reportFrom} ${ChangeDateFormate(startDate,"DD-MM-YYYY")} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

    let docDefinition = {
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },
        {
          text: headerTitle,
          style: { fontSize: 22, bold: true, alignment: "center" },
          margin: [15, 10],
        },
        {
          style: "tableBody",
          headerRows: 1,

          table: {
            widths: ["*", "auto", "auto","auto"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "100%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
          alignment: "center"
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          alignment: "right"
        },
      },
    };
    
    pdfMake.createPdf(docDefinition).download("trustUserSummaryReport.pdf");

    


  };
  

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="balanceSheet.trustUser.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3 d-flex align-items-center">
              <div style={{width : '240px'}}>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment(),
                  }}
                  onApply={handleApply}
                  onCallback={(start, end) => {
                    onDateChange([start, end]);
                  }}
                >
                  <input type="text" className="form-control col-4" />
                </DateRangePicker>
                  </div>
                <div className="ms-3">
                  <CButton color="danger" onClick={() => downloadSummaryPdf()}>
                    <span style={{ color: "white" }}>
                      <i className="gx-icon-pdf-300 me-1"></i>
                      <IntlMessages id="pdf.generate.button" />
                    </span>
                  </CButton>
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <TrustUserSummaryList
                  filterDate={filterDate}
                  setFilterDate={setFilterDate}
                  setFilteredCurrency={setFilteredCurrency}
                  
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default TrustFundAccounts;
