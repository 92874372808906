import React, { useState } from "react";
import {

  CButton,
  CCardBody,
  CCollapse,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import CSmartTable from "src/components/CSSmartTable"
import { useDispatch } from "react-redux";
import DeleteModal from "../../../components/Modals/DeleteModal";
import CheckUserAccess from "../../../util/checkUserAccess";
const StoreTable = ({ Stores, storeSpinner }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  const renderPhoneNumbers = (phoneNumbers) => {
    if (phoneNumbers && phoneNumbers.length > 0) {
      return phoneNumbers.map((item, index) => {
        return (
          <span>
            {index !== 0 ? ", " : ""}
            {item}
          </span>
        );
      });
    }
  };
  let columns = [
    {
      label: <IntlMessages id="store.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="store.email" />,
      key: "email",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="store.phone" />,
      key: "phoneNumbers",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="store.address" />,
      key: "address",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="store.employee" />,
      key: "employeeName",
      _style: { width: "40%" },
    },

  ];

  const found =CheckUserAccess('stores',['edit','delete','assignUnassign']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const editRecord = (data) => {
    dispatch({
      type: "edit_store_modal",
      payload: data,
    });
  };

  const assignEmployee = (data) => {
    dispatch({
      type: "assign_employee_modal",
      payload: data,
    });
  };

  const edit = CheckUserAccess('stores',['edit']) 
  const deleted = CheckUserAccess('stores',['deleted']) 
  const assignUnassign = CheckUserAccess('stores',['assignUnassign']) 
  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={storeSpinner}
        items={storeSpinner ? [] : Stores && Stores.length > 0 ? Stores : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          phoneNumbers: (record) => {
            return (
              <td className="py-2">
                {renderPhoneNumbers(record.phoneNumbers)}
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                {edit&&  <CButton
                    size="sm"
                    color="danger"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-edit me-2"></span>
                      <IntlMessages id="store.action.edit" />
                    </span>
                  </CButton>}

                  {assignUnassign&&    <CButton
                    size="sm"
                    color="success"
                    className="me-3"
                    onClick={() => assignEmployee(record)}
                  >
                    <span style={{ color: "white" }}>
                      {/* <CIcon icon={cilBan} className="me-2" /> */}
                      <span className="icon icon-user-o me-2"></span>
                      <IntlMessages id="store.action.assignUnAssignEmployee" />
                    </span>
                  </CButton>}

               {deleted &&   <DeleteModal
                    contentLangueId="store.deleteModal.message"
                    type="deleteStore"
                    selectedItem={record}
                    ButtonTitleId="Delete"
                    titleLanguageId="Delete"
                    size="sm"
                  />}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default StoreTable;
