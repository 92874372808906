import React, { useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import CategoryForm from "./CategoryForm";
const AddCustomerModal = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();

  const comProps = useSelector((state) => state.categoryReducer);

  const { categoryLoader, selectedItem, editModalVisible, success } = comProps;

  const modalType = "edit_category_modal";

  return (
    <div>
    
      <CModal visible={editModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            { intlPlaceholder('category.addCategoryModal.addCategory')}
          </CModalTitle>
        </CModalHeader>
       
          <CModalBody>
            <CategoryForm
              defaultValues={selectedItem}
              ButtonComponent={
                <CModalFooter>
                  <CButton
                    color="secondary"
                    onClick={() =>
                      dispatch(DataEntryModalAction(modalType, null))
                    }
                  >
                    <IntlMessages id="admin.addAdminModal.cancel" />
                  </CButton>

                  <CLoadingButton
                    type="submit"
                    color="primary"
                    loading={categoryLoader}
                    disabled={categoryLoader}
                  >
                    {intlPlaceholder('category.addCategoryModal.save')}
                  </CLoadingButton>
                </CModalFooter>
              }
            />


          </CModalBody>
       
      </CModal>
    </div>
  );
};

export default AddCustomerModal;
