import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import _ from 'lodash'
import moment from 'moment'
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
const Filter = ({ setFilter,receiptType }) => {
  const dispatch = useDispatch();
  const BSProps = useSelector(state => state.balanceSheet)
  const categoryProps = useSelector(state => state.categoryReducer)
  const storeProps = useSelector(state => state.storeReducer);
  const productProps = useSelector(state => state.ProductR)
  const { Stores } = storeProps;
  const  all  = intlPlaceholder("All")

    // form validation rules
    const validationSchema = Yup.object().shape({
      date: Yup.array().required(
        <IntlMessages id="dateRequired" />
      ),
      store: Yup.string().required(
        <IntlMessages id="SupplierProductFilter.supplier_error_message" />
      ),
    });
  
    const formOptions = {
      resolver: yupResolver(validationSchema),
      defaultValues: {
        date: [new Date().toISOString(),new Date().toISOString()], // Default value here
        store : 'all'
      },
    };
      // get functions to build form with useForm() hook
      const { register, handleSubmit, reset, formState, control, setValue } =
      useForm(formOptions);
    const { errors } = formState;

  const onFinish = (values) => {
      if (values.date) {
          values.startDate = moment(values.date[0]).utc();
          values.endDate = moment(values.date[1]).utc();
      } else {
          values.startDate = moment().utc();
          values.endDate = moment().utc();
      }

      let findSupplier = [{ name: 'all' }];
      if (values.store !== "all") {
          findSupplier = Stores.filter(Item => Item._id === values.store)
      }

      let filterObj;
      if (values.date) {
          filterObj = {
              store: findSupplier[0].name,
              startDate: values.startDate,
              endDate: values.endDate,
              rk: 'receiptType', rv: receiptType
          }
      } else {
          filterObj = {
              store: findSupplier[0].name,
              startDate: moment().toString(),
              endDate: moment().toString(),
              rk: 'receiptType', rv: receiptType
          }
      }


      setFilter(filterObj)
      dispatch(DataGetAction('getAllReceipt', values, 'storeReceiptSpinner'))
  }

  const fetchAllBankAndCurrencyList = () => {
      // dispatch(DataGetAction('getAllCategory', { query: "all" }));
      if (Stores && Stores.length === 0) {
          dispatch(DataGetAction('getAllStore', { query: "all" }));
      }
  }

  useEffect(fetchAllBankAndCurrencyList, [])

  useEffect(() => {
      if (Stores && Stores.length) {
         
          setValue("store", "all")
          let startDate = moment().toString();
          let endDate = moment().toString();

          setFilter({ "store": "all", startDate, endDate })

          dispatch(DataGetAction('getAllReceipt', {
              "store": "all",
              startDate, endDate,
              rk: 'receiptType', rv: receiptType
          }, 'storeReceiptSpinner'))
      }

  }, [Stores])
    const handleApply = (event, picker) => {
        picker.element.val(
          picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
      };

      const renderOptions = (data, label, key) => {
        if (data && data.length > 0) {
          return data.map((data) => ({
            label: data[label],
            value: data[key],
          }));
        }
      };
  return (
    <div className="p-3">
      <CForm className="d-flex flex-wrap" onSubmit={handleSubmit(onFinish)}>
        <div className=" me-3">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
            )}
          />
           <div className="error-message ">{errors.date?.message}</div>
        </div>
        <div className=" me-3">
          <div style={{width : '200px'}}>
          
            <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  defaultValue={{
                    value: 'all' , 
                    label: all,
                  }}
                  placeholder=  {intlPlaceholder('SupplierProductFilter.selectSupplier')}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                 
                  options={renderOptions([{name :all, _id : 'all'},...Stores], "name", "_id")}
                />
              )}
            />
            <div className="error-message ">{errors.store?.message}</div>
          </div>
        </div>






        <div className={``}>
          <div className="">
            <CLoadingButton className="d-flex align-items-center" type="submit" color="primary">
            <i className="gx-icon-filter-300 me-1 text-white"></i>
            <IntlMessages id="balanceSheet.generateButton" />
            </CLoadingButton>
          </div>
        </div>
      </CForm>
    </div>
  );
};

export default Filter;
