import React, { useState, useEffect } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { ChangeNumberFormate, ChangeDateFormate } from "src/constants/CommonFn";

import { DataGetAction } from "../../../redux/actions/http";
import { cibTheMovieDatabase } from "@coreui/icons";
import CSmartTable from '../../../components/CSSmartTable'
const SupplierProductList = ({ Data, Spinner }) => {
  const dispatch = useDispatch();
  const blProps = useSelector((state) => state.balanceSheet);
  const categoryProps = useSelector((state) => state.categoryReducer);
  const productProps = useSelector((state) => state.ProductR);

  const { Categories } = categoryProps;
  const { SupplierProduct, supplierProductSpinner } = blProps;
  const { ProductList } = productProps;

  const fetchBalanceSheet = () => {
    // dispatch(DataGetAction('getSupplierProduct', { query: "all" }));
    dispatch(DataGetAction("getAllCategory", { query: "all" }));
    dispatch(
      DataGetAction(
        "getAllProductStock",
        { query: "all" },
        "supplierProductSpinnerTest"
      )
    );

    return () => {
      dispatch({
        type:'resetSupplierProductList'
      })
    }
  };

  useEffect(fetchBalanceSheet, []);

  const columns = [
    {
      label: <IntlMessages id="supplierProduct.category" />,
      key: "categoryName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="supplierProduct.product" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="supplierProduct.quantity" />,
      key: "qty",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="supplierProduct.total" />,
      key: "amount",
      _style: { width: "40%" },
    },

  ];

  return (
    <>
      <CSmartTable
        loading={supplierProductSpinner}
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        items={supplierProductSpinner ? [] : SupplierProduct}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={200}
        columnSorter
        pagination
        scopedColumns={{
          categoryName: (record) => {
            return (
              <>
                <td style={{ textTransform: "capitalize" }}>
                  {record.categoryName && record.categoryName}
                </td>
              </>
            );
          },
          productName: (record) => {
            return <td>{record.productName && record.productName}</td>;
          },
          qty: (record) => {
            return (
              <td className="text-end">
                {Number.isInteger(parseFloat(record.qty))
                  ? record.qty
                  : parseFloat(record.qty).toFixed(3)}
              </td>
            );
          },

          amount: (record) => {
            return (
              <td className="text-end">
                {ChangeNumberFormate(
                  record.amount &&
                  (
                    parseFloat(record.amount)
                  ).toFixed(2)
                )}
              </td>
            );
          },
          

        }}
        summary={(SupplierProduct) => {
          let totalAmount = 0;
          let totalQuantity = 0;
          SupplierProduct.forEach(({ amount, qty }) => {
            totalAmount += parseFloat(amount);
            totalQuantity += parseFloat(qty)
          });



          return (
            <>
              <tr>
                <th colSpan={2} className="gx-text-center">
                  <h5>
                    <IntlMessages id="supplierProduct.TotalAmount" />
                  </h5>
                </th>
                <th className="text-end">
                  <h5>{ChangeNumberFormate(totalQuantity.toFixed(3)) || 0}</h5>
                </th>

                <th className="text-end">
                  <h5>{ChangeNumberFormate(totalAmount.toFixed(2)) || 0}</h5>
                </th>
               <th></th>
              </tr>
            </>
          );
        }}

      />
    </>
  );
};

export default SupplierProductList;
