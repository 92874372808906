const INIT_STATE = {
  grossProfitCalculationSpin: false,
  grossProfitCalculationData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "grossProfitCalculation_SUCCESS": {
      return {
        ...state,
        grossProfitCalculationData: action.payload.Record,
        grossProfitCalculationSpin: false,
      };
    }
    case "grossProfitCalculationSpin_START": {
      return {
        ...state,
        grossProfitCalculationSpin: true,
      };
    }
    default:
      return state;
  }
};
