import Select from "react-select";
import { Controller } from "react-hook-form";
import { selectStyle } from "../../constants/CommonFn";
import { FormError } from "./error";

export const FormInput = (props) => {
  const { control, name, defaultValue = '', ...inputProps} = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => FormGroup(inputProps, { onChange, value, name })}
    />
  );
};

// Switch case type input
const FormGroup = (props, { onChange, value, name }) => {
  const {
    errors,
    type,
    placeholder = '',
    options = [],
    classError,
    isMulti = false,
    isDisabled = false,
    onHandleChange
  } = props;

  let formGroup;
  
  switch (type) {
    case "select":
      formGroup = (
        <Select
          isSearchable={true}
          value={value}
          isMulti={isMulti}
          isDisabled={isDisabled}
          styles={selectStyle}
          placeholder={placeholder}
          options={options}
          onChange={value => {
            onChange(onHandleChange ? onHandleChange(value) : value)

          }}
        />
      );
      break;

    default:
      formGroup = <div></div>;
  }

  return (
    <>
      {formGroup}
      <FormError name={name} errors={errors} classError={classError} />
    </>
  )
}