import React, { useEffect } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import PropertyTable from "./components/PropertyTable";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import AddPropertyModal from "./components/AddPropertyModal";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.propertyReducer);
  const { LogeInUser } = useSelector((state) => state.auth);
  console.log("==userInfo", LogeInUser);
  console.log("===Data", comProps);

  const fetchProperty = () => {
    dispatch(DataGetAction("properties", { query: "all" }, "propertySpinner"));
  };
  useEffect(fetchProperty, [comProps?.fetchPropertyInfo]);

  console.log("===Data", comProps);

  const create = CheckUserAccess("property", ["create"]);
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="property.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3">{create && <AddPropertyModal />}</div>

              <DocsExample href="components/smart-table/">
                <PropertyTable Properties={comProps?.Properties} propertySpinner={comProps?.propertySpinner} />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
