import _ from "lodash";
const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  passModalVisible: false,
  selectedItem: null,
  productLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  Products: [],
  FirstTimeLoadProduct: [],
  fetchProductInfo: false,
  Suppliers: [],
  Currency: [],
  Units: [],
  selectedCurrency: "",
  SubProducts: [],
  CreatedByList: [],
  checkProductLoader: false,
  productError: false,
  editSubProducts: null,
  selectedReferenceNo: "",
  EditStockModal: false,
  editModalRecord: null,
  EditStockSuccess: false,
  allStockSpinner: false,
  updatedProductList: [],
  editInvoiceModal: false,
  spinner: false,
  StockStatus: [],
  DebitNotesList: [],
  CreditNotesList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

   

    case "updatedProductList": {
      return {
        ...state,
        SubProducts: action.payload,
      };
    }

    case "Update_Stock_Reset": {
      return {
        ...state,
        EditStockSuccess: false,
      };
    }

    case "updatingStock_START": {
      return {
        ...state,
        updatingStockLoader: true,
      };
    }
    case "updatingStock_STOP": {
      return {
        ...state,
        updatingStockLoader: false,
      };
    }
    case "updateStock_SUCCESS": {
      return {
        ...state,
        EditStockModal: false,
        EditStockSuccess: true,
        updatingStockLoader: false,
      };
    }

    case "Edit_Stock_Modal": {
      return {
        ...state,
        EditStockModal: !state.EditStockModal,
        editModalRecord: action.payload,
      };
    }
    case "Search_stock_by_createdBy": {
      const result = searchByCreatedBy(
        action.payload,
        state.FirstTimeLoadProduct
      );
      return {
        ...state,
        Products: [...result],
      };
    }
    case "Search_stock_by_supplier": {
      const result = getProductBySupplierId(
        action.payload,
        state.FirstTimeLoadProduct
      );
      return {
        ...state,
        Products: [...result],
      };
    }
    case "Search_stock_by_currency": {
      const result = getProductByCurrencyId(
        action.payload,
        state.FirstTimeLoadProduct
      );

      return {
        ...state,
        Products: [...result],
      };
    }
    case "Search_stock_by_product_name": {
      const result = getProductByProductId(
        action.payload,
        state.FirstTimeLoadProduct
      );

      return {
        ...state,
        Products: [...result],
      };
    }
    case "fetchProductDropDowns_SUCCESS": {
      const data = action.payload.Record;
      return {
        ...state,
        Suppliers: data.suppliers,
        Currency: data.currencies,
        Units: data.units,
      };
    }

    case "productQuantityRow": {
      const newArray = getUpdatedProduct(action.payload, state.SubProducts);
      return { ...state, SubProducts: newArray };
    }
    case "addSubProduct_SUCCESS": {
      const newArray = getNewProduct(
        action.payload,
        state.SubProducts,
        state.Currency,
        state.Units
      );
      return {
        ...state,
        SubProducts: newArray,
        selectedCurrency: newArray[0].currency,
      };
    }
    case "deleteSubProduct_SUCCESS": {
      const deletedRecord = getRecordAfterDeleted(
        state.SubProducts,
        action.payload
      );
      let selectedCur = "";
      if (deletedRecord.length > 0) {
        selectedCur = deletedRecord[0].currency;
      }
      return {
        ...state,
        SubProducts: deletedRecord,
        selectedCurrency: selectedCur,
      };
    }

    case "checkRefNoSpinier_START": {
      return { ...state, checkProductLoader: true };
    }
    case "checkRefNoSpinier_STOP": {
      return { ...state, checkProductLoader: false };
    }

    case "checkRefNo_SUCCESS": {
      return {
        ...state,
        checkProductLoader: false,
        productError: false,
        selectedReferenceNo: action.payload.Record.referenceNo,
        enableSubProductForm: action.payload.Record.unit !== "" ? false : true,
      };
    }
    case "checkRefNo_FAILURE": {
      return {
        ...state,
        productError: true,
        message: action.payload,
        enableSubProductForm: true,
      };
    }

    case "getAllStock_SUCCESS": {
      const createdByList = getProductCreatedByList(action.payload.Record);
      return {
        ...state,
        CreatedByList: createdByList,
        FirstTimeLoadProduct: action.payload.Record,
        Products: action.payload.Record,
        fetchProductInfo: false,
      };
    }
    case "allStockSpinner_START": {
      return { ...state, allStockSpinner: true };
    }
    case "allStockSpinner_STOP": {
      return { ...state, allStockSpinner: false };
    }
    case "show_product_modal": {
      return {
        ...state,
        modalVisible: !state.modalVisible,
        selectedItem: action.payload,
      };
    }

    case "product_START": {
      return { ...state, productLoader: true };
    }
    case "product_STOP": {
      return { ...state, productLoader: false };
    }

    case "addProduct_SUCCESS": {
      return {
        ...state,
        success: true,
        message: 'recordAddedSuccessfully',
        FirstTimeLoadProduct: [action.payload.Record, ...state.Products],
        Products: [action.payload.Record, ...state.Products],
        productLoader: false,
      };
    }
    case "addProduct_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        productLoader: false,
        message: action.payload,
      };
    }

    case "editProduct_SUCCESS": {
      let oldCat = state.Products;
      let newCat = oldCat.map((Item) => {
        if (Item._id === action.payload.Record._id) {
          return action.payload.Record;
        } else {
          return Item;
        }
      });
      return {
        ...state,
        recordEditSuccess: true,
        FirstTimeLoadProduct: newCat,
        Products: newCat,
        productLoader: false,
        success: true,
        message: "recordUpdateSuccessfully",
        modalVisible: false,
      };
    }
    case "editProduct_FAILURE": {
      return {
        ...state,
        recordEditError: true,
        recordEditSuccess: false,
        productLoader: false,
        message: action.payload,
        modalVisible: false,
      };
    }

    case "product_update_error": {
      return {
        ...state,
        error: false,
        success: false,
        checkProductLoader: false,
      };
    }

    case "product_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        resendError: false,
        resendSuccess: false,
        productLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
        selectedCurrency: "",
        SubProducts: [],
        checkProductLoader: false,
        productError: false,
        editSubProducts: null,
        selectedReferenceNo: "",
      };
    }

    case "deleteStock_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Products;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return {
        ...state,
        FirstTimeLoadProduct: newCat,
        Products: newCat,
        selectedItem: null,
        success: true,
        message: "recordDeletedSuccessfully",
      };
    }

    case "editProductInvoice_Modal": {
      return {
        ...state,
        editInvoiceModal: !state.editInvoiceModal,
        editModalRecord: action.payload,
        SubProducts: action.payload ? action.payload.products : [],
        // Currency:[]
      };
    }

    case "updateCompanyStock_SUCCESS": {
      return {
        ...state,
        success: true,
        //editInvoiceModal: false,
        message: 'recordAddedSuccessfully',
        FirstTimeLoadProduct: [action.payload.Record, ...state.Products],
        Products: [action.payload.Record, ...state.Products],
        productLoader: false,
      };
    }
    
    case "updateCompanyStock_FAILURE": {
      return {
        ...state,
        error: true,
        success: false,
        productLoader: false,
        message: action.payload,
      };
    }

    case "stockStatusSummary_SUCCESS": {
      return {
        ...state,        
        //success: true,
        StockStatus: action.payload.Record,
      };
    }
    case "spinner_START": {
      return { ...state, spinner: true };
    }
    case "spinner_STOP": {
      return { ...state, spinner: false };
    }
    case "getDebitNotesList_SUCCESS": {
      return {
        ...state,
        DebitNotesList: action.payload.Record,
      };
    }
    case "getCreditNotesList_SUCCESS": {
      return {
        ...state,
        CreditNotesList: action.payload.Record,
      };
    }

    default:
      return state;
  }


  function getNewProduct(newProduct, oldProduct) {

    let newArray = [];
    const findUniqueArray = (arr1, arr2) => {
      //Find values that are in newProduct but not in oldProduct
      var uniqueResultOne = arr1.filter(function (obj) {
        return !arr2.some(function (obj2) {
          return obj.productId == obj2.productId;
        });
      });

      //Find values that are in oldProduct but not in newProduct
      var uniqueResultTwo = arr2.filter(function (obj) {
        return !arr1.some(function (obj2) {
          return obj.productId == obj2.productId;
        });
      });

      //Combine the two arrays of unique entries
      var result = [...uniqueResultOne, ...uniqueResultTwo];

      return result;
    };

    if (oldProduct && oldProduct.length > 0) {
      const uniqueArray = findUniqueArray(newProduct, oldProduct);
      
      let updatedArray = [];
      oldProduct.forEach((oldItem) => {
        newProduct.forEach((newItem) => {
          if (oldItem.productId === newItem.productId) {
            let newQuantity =
              parseFloat(oldItem.quantity) + parseFloat(newItem.quantity);
            let totalLocalPrice = newQuantity * oldItem.localPrice;
            let totalUnitPrice = newQuantity * oldItem.price;

            oldItem.currencyFactor = newItem.currencyFactor;
            oldItem.currency = newItem.currency;
            oldItem.currencyId = newItem.currency;
            oldItem.quantity = newQuantity;
            oldItem.totalLocalPrice = totalLocalPrice;
            oldItem.totalUnitPrice = totalUnitPrice;
            updatedArray.push(oldItem);
          }
        });
      });
      newArray = [...updatedArray, ...uniqueArray];
    } else {
      newArray = newProduct;
    }

    return newArray;
  }

  function getRecordAfterDeleted(oldRecord, deletedRecord) {
    const newRecord = [];
    oldRecord.map((Item) => {
      if (Item.productName !== deletedRecord.productName) {
        newRecord.push(Item);
      }
      return Item;
    });
    return newRecord;
  }
};
function getUpdatedProduct(updatedRecord, oldRecord) {
  const newRecord = [];
  oldRecord.map((Item) => {
    if (Item.productId === updatedRecord.productId) {
      updatedRecord.price = parseFloat(updatedRecord.price);
      if (updatedRecord.currencyType !== "local") {
        updatedRecord.localPrice =
          parseFloat(updatedRecord.price) *
          parseFloat(updatedRecord.currencyFactor);
      } else {
        updatedRecord.localPrice = parseFloat(updatedRecord.price);
      }
      updatedRecord.totalLocalPrice =
        parseFloat(updatedRecord.localPrice) *
        parseFloat(updatedRecord.quantity);
      updatedRecord.totalUnitPrice =
        parseFloat(updatedRecord.price) * parseFloat(updatedRecord.quantity);
      updatedRecord.quantity = parseFloat(updatedRecord.quantity);
      newRecord.push(updatedRecord);
    } else {
      newRecord.push(Item);
    }
    return Item;
  });
  return newRecord;
}

const getProductCreatedByList = (data) => {
  let cratedByList = [];
  if (data && data.length > 0) {
    let temp = data[0].createdByUser[0]._id;
    cratedByList.push(data[0].createdByUser[0]);
    data.map((Item) => {
      if (temp !== Item.createdByUser[0]._id) {
        cratedByList.push(Item.createdByUser[0]);
        temp = Item.createdByUser[0]._id;
      }
    });
  }

  return cratedByList;
};

const searchByCreatedBy = (createdBy, oldData) => {
  let result = [];
  if (oldData) {
    result = oldData.filter((Item) => Item.createdByUser[0]._id === createdBy);
  }
  return result;
};

const getProductBySupplierId = (supplierId, oldProduct) => {
  let result = [];
  if (oldProduct) {
    result = oldProduct.filter((Item) => Item.supplierId === supplierId);
  }
  return result;
};

const getProductByCurrencyId = (currencyId, oldProduct) => {
  let result = [];
  if (oldProduct) {
    result = oldProduct.filter((Item) => Item.currencyId === currencyId);
  }
  return result;
};

const getProductByProductId = (productId, oldProduct) => {
  let result = [];
  if (oldProduct) {
    result = oldProduct.filter((Item) => Item.productId === productId);
  }
  return result;
};
