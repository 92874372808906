import React, { useEffect,  useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCollapse,
  CCardHeader
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import InvoiceList from "../../components/CSSmartTable";
import CheckUserAccess from "../../util/checkUserAccess";
const CompanySale = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const invProps = useSelector((state) => state.invoiceReducer);
  const history = useHistory();
  const fetchProduct = () => {
    dispatch(
      DataGetAction(
        "getAllInvoices",
        { query: "all", rk: "invoiceType", rt: "CompanyToClient" },
        "allStoreInvoicesSpinner"
      )
    );
  };
  useEffect(fetchProduct, []);

  const redirectToPage = () => {
    history.push("/createInvoice");
  };

  const ViewInvoice = (data) => {
    history.push({
      pathname: "/viewStoreInvoice",
      state: { InvoiceNo: data.invoiceNumber, prefix: data.prefix },
    });
  };

  const columns = [
    {
      label: <IntlMessages id="invoice.number" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.storeName" />,
      key: "store",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.address" />,
      key: "address",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.amount" />,
      key: "totalPrice",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.createdBy" />,
      key: "createdBy",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: '',
      sorter : false,
      filter : false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];
  const create = CheckUserAccess('invoices',['create'])

  return (
    <>
      <CRow>
        <CCol xs={12}>
          
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="storeToStore.invoice.title" />
              </h1>
           
            </CCardHeader>
            <CCardBody>
            <div className="p-3">
            {create &&  <CButton color="primary" onClick={() => redirectToPage()}>
              <i className="icon icon-add me-1"></i>
                  <IntlMessages id="invoice.createInvoice" />
                </CButton>}
            </div>
              <DocsExample href="components/smart-table/">
                <InvoiceList
                  loading={invProps.storeSaleInvoiceSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  
                  items={
                    invProps.storeSaleInvoiceSpinner ? [] : invProps.Invoices
                  }
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                  
                    address: (record) => {
                      return (
                        <td>
                          {record.address}
                        </td>
                      );
                    },
                    totalPrice: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                          {ChangeNumberFormate(record && record.totalPrice && record.totalPrice.toFixed(2))}
                          </span>
                        </td>
                      );
                    },
                    createdBy: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                          {record.createdBy}
                          </span>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                          {ChangeDateFormate(record.createdAt)}
                          </span>
                        </td>
                      );
                    },

                    
                    status: (record) => {
                      return (
                        <td>
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {record.status}
                          </span>
                        </td>
                      );
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => ViewInvoice(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default CompanySale;
