import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { colorPropType } from './Types' 
const CTableRow = forwardRef((props, ref) => {

    const { children, active, align, className, color, ...rest } = props
    const _className = classNames(
        {
            [`align-${align}`]: align,
            'table-active': active,
            [`table-${color}`]: color,
        },
        className,
    )
    return (
        <tr className={_className ? _className : undefined} {...rest} ref={ref}>
            {children}
        </tr>
    )
})


CTableRow.propTypes = {
    active: PropTypes.bool,
    align: PropTypes.oneOf(['bottom', 'middle', 'top']),
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
}

export default CTableRow
