import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import { useForm } from "react-hook-form";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { notify } from "src/util/ShowToast";
const Login = () => {
  const authProps = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const redirectToPage = (url) => {
    history.push(url);
  };

  if (authProps.loginSuccess) {
    const LogeInUser = authProps.LogeInUser;

    if (
      LogeInUser &&
      (LogeInUser.role === "superAdmin" || LogeInUser.role === "team")
    ) {
      history.push("/dashboard");
    } else if (LogeInUser && LogeInUser.role === "mainSuperAdmin") {
      history.push("/adminDashboard");
    }
  }

  const loginError = intlPlaceholder("loginError");
  if (authProps.loginError) {
    notify("error", loginError);
    dispatch({
      type: "auth_UPDATE",
    });
  }

  const LoadPage = () => {
    return () => {
      dispatch(updateAllState("auth"));
    };
  };
  useEffect(LoadPage, []);

  const onFinish = (data) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        data.emailUserName
      )
    ) {
      data.email = data.emailUserName;
    } else {
      data.username = data.emailUserName;
    }

    dispatch(DataRequestAction("POST", "login", data, "", "login", false));
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onFinish)}>
                    <h1>
                      <IntlMessages id="login.login" />{" "}
                    </h1>
                    <p className="text-medium-emphasis ">
                      <IntlMessages id="login.signIn" />
                    </p>
                    <CInputGroup className="mb-3 custom-login-width">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormFloating>
                        <CFormInput
                          {...register("emailUserName")}
                          placeholder={intlPlaceholder("userName.email")}
                          autoComplete="username"
                        />
                        <CFormLabel htmlFor="">
                          {intlPlaceholder("userName.email")}
                        </CFormLabel>
                      </CFormFloating>
                    </CInputGroup>
                    <CInputGroup className="mb-4 custom-login-width">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormFloating>
                        <CFormInput
                          {...register("password")}
                          type="password"
                          placeholder={intlPlaceholder("password")}
                          autoComplete="current-password"
                        />
                        <CFormLabel htmlFor="">
                          {intlPlaceholder("password")}
                        </CFormLabel>
                      </CFormFloating>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CLoadingButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          loading={authProps.ShowLoginLoader}
                          disabled={authProps.ShowLoginLoader}
                        >
                          <IntlMessages id="login.login" />
                        </CLoadingButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton
                          color="link"
                          className="px-0"
                          onClick={() => {
                            history.push("/forgotPassword");
                          }}
                        >
                          <IntlMessages id="login.forgotPassword" />
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>
                      {" "}
                      <IntlMessages id="app.userAuth.signUp" />
                    </h2>
                    <p>
                      <IntlMessages id="app.userAuth.signUp_message" />
                    </p>
                    <Link to="/signup">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        <IntlMessages id="app.userAuth.registerNow" />
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
