import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CForm,
  CFormTextarea,
} from "@coreui/react-pro";
import _ from "lodash";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../redux/actions/Common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataGetAction, DataRequestAction } from "../../redux/actions/http";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { selectStyle } from "../../constants/CommonFn";
import { notify } from "src/util/ShowToast";
import moment from "moment";
const AddDebitOrCreditModal = ({ modalType }) => {
  const dispatch = useDispatch();
  const supplierProps = useSelector((state) => state.supplier);
  const {
    Suppliers,
    supplierSpinner,
    addSupplierDebitOrCreditSpin,
    addSupplierDebitOrCreditSuccess,
    addSupplierDebitOrCreditError,
  } = supplierProps;

  const validationSchema = Yup.object().shape({
    supplier: Yup.string().required(
      <IntlMessages id="addDebitOrCreditModal_supplier_message" />
    ),
    amount: Yup.string().required(
      <IntlMessages id="addDebitOrCreditModal_amount_message" />
    ),
    transactionType: Yup.string().required(
      <IntlMessages id="addDebitOrCreditModal_transactionType_message" />
    ),
    remark: Yup.string().required(
      <IntlMessages id="addDebitOrCreditModal_remark_message" />
    ),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    let obj = JSON.parse(values.supplier);
    let valueObj = {
      supplierId: obj._id,
      currencyId: obj.currencyId,
      amount: values.amount,
      remark: values.remark,
      transactionType: values.transactionType,
    };
    dispatch(
      DataRequestAction(
        "POST",
        "addSupplierDebitOrCredit",
        valueObj,
        "",
        "addSupplierDebitOrCreditSpin"
      )
    );
  };

  const getAllSupplier = () => {
    if (supplierProps.modalVisible) {
      dispatch(
        DataGetAction("getAllSupplier", { query: "all" }, "supplierSpinner")
      );
    }
  };
  useEffect(getAllSupplier, [supplierProps.modalVisible]);

  const showModal = (Item) => {
    dispatch({
      type: modalType,
    });
  };
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: JSON.stringify({ _id: data._id, currencyId: data.currencyId }),
      }));
    }
  };

  if (addSupplierDebitOrCreditSuccess) {
    reset();
    let messageLng = intlPlaceholder("recordAddedSuccessfully");
    notify("success", messageLng);
    dispatch(
      DataGetAction(
        "BalanceSummerySupplier",
        {
          startDate: moment().startOf("today").toString(),
          endDate: moment().toString(),
          initialEndDate: moment().toString(),
          noDateSelected: "noDateSelected",
        },
        "balanceSummerySupplierSpinner"
      )
    );
    showModal();
    dispatch({ type: "ResetAddSupplier" });
  }
  if (addSupplierDebitOrCreditError) {
    let messageLng = intlPlaceholder("addDeliveredErrorMessage");
    notify("error", messageLng);
    dispatch({ type: "ResetAddSupplier" });
  }

  return (
    <div>
      <CButton color="primary" onClick={() => showModal(null)}>
        <span style={{ color: "white" }}>
          <IntlMessages id="debitCredit.button" />
        </span>
      </CButton>

      <CModal visible={supplierProps.modalVisible}>
        <CModalHeader
          onDismiss={() => {
            reset();
            dispatch(DataEntryModalAction(modalType, null));
          }}
        >
          <CModalTitle>
            <IntlMessages id="debitCredit.button" />
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="row">
              {/* supplier */}
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="supplier"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          isLoading={supplierSpinner}
                          className={errors.supplier ? "is-invalid" : ""}
                          isSearchable={true}
                          styles={selectStyle}
                          placeholder={intlPlaceholder(
                            "SupplierProductFilter.selectSupplier"
                          )}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={renderOptions([...Suppliers], "name", "_id")}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.supplier?.message}
                  </div>
                </div>
              </div>
              {/* Select Type */}
              <div className="col-md-12">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="transactionType"
                    defaultValue={"debit"}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          className={errors.transactionType ? "is-invalid" : ""}
                          styles={selectStyle}
                          placeholder={intlPlaceholder(
                            "SupplierSelectTransactionType"
                          )}
                          defaultValue={{ label: "Debit", value: "debit" }}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={[
                            { label: "Debit", value: "debit" },
                            { label: "Credit", value: "credit" },
                          ]}
                        />
                      </>
                    )}
                  />

                  <div className="invalid-feedback">
                    {errors.transactionType?.message}
                  </div>
                </div>
              </div>
              {/* amount */}
              <div className="col-md-12">
                <div className="mb-3 number-format-input">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <NumberFormat
                        className={errors.amount ? "is-invalid" : ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        onValueChange={(v) => onChange(v.value)}
                        value={value}
                        placeholder={intlPlaceholder("amount")}
                      />
                    )}
                    name={"amount"}
                    control={control}
                  />
                  <div className="invalid-feedback">
                    {errors.amount?.message}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <CFormTextarea
                  rows="4"
                  className={errors.remark ? "is-invalid" : ""}
                  {...register("remark")}
                  placeholder={intlPlaceholder(
                    "currencyExchange.exchangeModal.remark_placeholder"
                  )}
                  type="text"
                  id="name"
                />
                <div className="invalid-feedback">{errors.remark?.message}</div>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                reset();
                dispatch(DataEntryModalAction(modalType, null));
              }}
            >
              <IntlMessages id="receipt.generateReceiptModal.generateReceipt.cancelButton" />
            </CButton>
            <CLoadingButton
              loading={addSupplierDebitOrCreditSpin}
              disabled={addSupplierDebitOrCreditSpin}
              type="submit"
              color="primary"
            >
              {intlPlaceholder("admin.addAdminModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddDebitOrCreditModal;
