const INIT_STATE = {
    Salary: [],
    Salaries: [],
    ShowEmployeeSalaryModal: false,
    postingSalary: false,
    salaryPostedSuccess: false,
    salaryPostedError: false,
    message: '',
    salaryDetail: [],
    fetchDetail: false,
    PostSalary:[],
    AllEmployeeFetched:[],
    employeeSalarySpinner: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'filterTheSalaryEmployeeArray':{
            let filterPostSalary = state.AllEmployeeFetched;
            if (action.payload !== "all") {
                filterPostSalary = getFilteredPostSalary(state.AllEmployeeFetched, action.payload);
            }
            return { ...state, PostSalary: filterPostSalary } 
        }
        case 'updateSalaryData': {
            const updateRecord = recordAfterUpdate(state.PostSalary, action.payload)
            return { ...state, PostSalary: updateRecord, fetchDetail: false, AllEmployeeFetched:updateRecord }
        }
        case 'getSalaryDetails_SUCCESS': {
            return { ...state, Salaries: action.payload.Record, fetchDetail: false }
        }
        case 'fetchingSalaryDetail_START': {
            return { ...state, fetchDetail: true }
        }
        case 'fetchingSalaryDetails_START': {
            return { ...state, fetchDetail: true }
        }
        case 'getSalaryDetail_SUCCESS': {
            return { ...state, salaryDetail: action.payload.Record, fetchDetail: false }
        }
        case 'postingSalary_START': {
            return { ...state, postingSalary: true }
        }
        case 'postSalaryToEmployee_SUCCESS': {
            return { ...state, postingSalary: false, salaryPostedSuccess: true, message: "salaryPostedToEmployee" }
        }
        case 'postSalaryToEmployee_ERROR': {
            return { ...state, postingSalary: false, salaryPostedSuccess: false, salaryPostedError: true, message: "errorWhilePostingSalary" }
        }
        case 'show_es_modal': {
            return { ...state, ShowEmployeeSalaryModal: !state.ShowEmployeeSalaryModal, PostSalary:action.payload?[...action.payload]:[], AllEmployeeFetched : action.payload?[...action.payload]:[] }
        }

        case 'getEmployeeSalary_SUCCESS': {
            return { ...state, Salary: action.payload.Record }
        }
        case 'employeeSalarySpinner_START':{
            return { ...state,  employeeSalarySpinner: true };
        }
        case 'employeeSalarySpinner_STOP':{
            return { ...state,   employeeSalarySpinner: false };
        }
        case 'es_UPDATE': {
            return {
                ...state,
                postingSalary: false,
                salaryPostedSuccess: false,
                salaryPostedError: false,
                message: ''
            };
        }

        default:
            return state;
    }

}

const getFilteredPostSalary = (oldData, employeeId) => {
    const filterData = oldData.filter(Item => Item._id === employeeId)
    return filterData
}

const recordAfterUpdate = (oldData, newData) => {
    let newArray = [];
    oldData.map(Item => {
        if (Item._id === newData._id) {
            newArray.push(newData)
        } else {
            newArray.push(Item);
        }
        return Item
    })
    return newArray;
}