import React from 'react';

const FormError = (props) => {
  const { name, errors, classError } = props;

  return (
    <div className={`error-message ${classError || ''}`}>{errors[name]?.message}</div>
  )
};

export { FormError };
