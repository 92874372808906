import React, { useState } from "react";
import { CForm, CFormInput, CFormFloating, CFormLabel } from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataRequestAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { notify } from "../../../util/ShowToast";

const AddForm = ({ defaultValues, ButtonComponent }) => {
  const comProps = useSelector((state) => state.propertyReducer);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  // form validation rules

  const validationSchema = Yup.object().shape({
    propertyName: Yup.string().required(<IntlMessages id="property.addPropertyName_message" />),
    address: Yup.string().required(<IntlMessages id="property.addPropertyAddress_message" />),
    block: Yup.string().required(<IntlMessages id="property.addPropertyBlock_message" />),
    numberOfUnits: Yup.string().required(<IntlMessages id="property.addNumberOfUnits_message" />),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setValue, formState, control } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    console.log("===values", values);

    dispatch(DataRequestAction("POST", "addProperty", values, "", "property", false));
  };
  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("success", messageLng);
    dispatch({
      type: "property_UPDATE",
    });
  }
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message);
    notify("error", messageLng);
    dispatch({
      type: "property_UPDATE",
    });
  }

  const handleChange = (e) => {
    const val = e.target.value;
    if (val === "" || (Number(val) > 0 && /^[0-9]*$/.test(val))) {
      setInputValue(val);
      setValue("numberOfUnits", val);
    }
  };

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.name ? "is-invalid" : ""}
            {...register("propertyName")}
            placeholder={intlPlaceholder("property.propertyName")}
            type="text"
            id="propertyName"
          />
          <CFormLabel htmlFor="propertyName">{intlPlaceholder("property.propertyName")}</CFormLabel>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.name ? "is-invalid" : ""}
            {...register("address")}
            placeholder={intlPlaceholder("property.address")}
            type="text"
            id="address"
          />
          <CFormLabel htmlFor="address">{intlPlaceholder("property.address")}</CFormLabel>
          <div className="invalid-feedback">{errors.address?.message}</div>
        </CFormFloating>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.block ? "is-invalid" : ""}
            {...register("block")}
            placeholder={intlPlaceholder("property.addBlock")}
            type="text"
            id="block"
          />
          <CFormLabel htmlFor="block">{intlPlaceholder("property.addBlock")}</CFormLabel>
          <div className="invalid-feedback">{errors.block?.message}</div>
        </CFormFloating>
      </div>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <CFormInput
            className={errors.numberOfUnits ? "is-invalid" : ""}
            {...register("numberOfUnits")}
            placeholder={intlPlaceholder("property.numberOfUnits")}
            type="number"
            min="1"
            value={inputValue}
            onChange={handleChange}
            id="numberOfUnits"
          />
          <CFormLabel htmlFor="numberOfUnits">{intlPlaceholder("property.numberOfUnits")}</CFormLabel>
          <div className="invalid-feedback">{errors.numberOfUnits?.message}</div>
        </CFormFloating>
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default AddForm;
