import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    modalCreditVisible: false,
    passModalVisible: false,
    selectedItem: null,
    accountLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Accounts: [],
    fetchAccountInfo: false,
    Banks: [],
    Currencies: [],
    DestinationAccount: [],
    transferringLoader: false,
    transferringSuccess: false,
    transferringError: false,
    TransferRecord: [],
    showTransferModal: false,
    FundTransferLoader: false,
    FundTransferDetail: null,
    accountSpinner: false,
    allBankTransferSpinner: false,
    editModalVisible : false,
    accountBulkModalVisible : false,
    accountBulkUpload_error: false, 
    accountBulkUpload_success: false, 
    bulkAccountSpinner : false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case 'fetchingSupplierReceipt_START': {
            return {
                ...state,
                FundTransferLoader: true
            }
        }
        case 'getFundTransferDetail_SUCCESS': {
            return {
                ...state,
                FundTransferDetail: action.payload.Record,
                FundTransferLoader: false
            }
        }

        case 'getFundTransferDetail_FAILURE': {
            return {
                ...state,
                FundTransferLoader: false
            }
        }

        case 'show_fundTransfer_modal': {
            return {
                ...state,
                showTransferModal: !state.showTransferModal
            }
        }
        case 'getAllBankTransfer_SUCCESS': {
            return {
                ...state,
                TransferRecord: action.payload.Record
            }
        }
        case 'allBankTransferSpinner_START':{
            return { ...state,  allBankTransferSpinner: true };
        }
        case 'allBankTransferSpinner_STOP':{
            return { ...state,   allBankTransferSpinner: false };
        }
        case 'transferringAmount_START': {
            return {
                ...state,
                transferringLoader: true
            }
        }
        case 'transferAmount_SUCCESS': {
            return {
                ...state,
                transferringLoader: false,
                transferringSuccess: true,
                message : action.payload.message
            }
        }
        case 'transferAmount_FAILURE': {
            return {
                ...state,
                transferringLoader: false,
                transferringError: true,
            }
        }

        case 'getSameCurrencyAccount_SUCCESS': {
            return { ...state, DestinationAccount: action.payload.Record }
        }
        case 'creditBalance_SUCCESS': {
            return { ...state, success: true, message: "balanceUpdatedSuccessfully", modalCreditVisible: false, fetchAccountInfo: true }
        }
        case 'creditBalance_FAILURE': {
            return { ...state, error: true, success: false, message: "someErrorOccurWhileUpdatingBalance" }
        }
        case 'getAccountDropDowns_SUCCESS': {
            return {
                ...state,
                Banks: action.payload.Record[0] && action.payload.Record[0].bankInfo,
                Currencies: action.payload.Record[0] && action.payload.Record[0].currenciesInfo,
            }
        }

        case 'getAllAccount_SUCCESS': {
            return { ...state, Accounts: action.payload.Record, fetchAccountInfo: false };
        }
        case 'accountSpinner_START':{
            return { ...state,  accountSpinner: true };
        }
        case 'accountSpinner_STOP':{
            return { ...state,   accountSpinner: false };
        }
        case 'show_account_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }
        case 'edit_account_modal': {
            return { ...state, editModalVisible: !state.editModalVisible, selectedItem: action.payload };
        }
        case 'show_credit_balance': {
            return { ...state, modalCreditVisible: !state.modalCreditVisible, selectedItem: action.payload };
        }

        case 'account_START': {
            return { ...state, accountLoader: true };
        }
        case 'account_STOP': {
            return { ...state, accountLoader: false };
        }

        case 'addAccount_SUCCESS': {

            return { ...state, success: true, message: "recordAddedSuccessfully", fetchAccountInfo: true, accountLoader: false };
        }
        case 'addAccount_FAILURE': {
            return { ...state, error: true, success: false, accountLoader: false, message: action.payload };
        }


        case 'editAccount_SUCCESS': {
            let oldCat = state.Accounts;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Accounts: newCat, accountLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editAccount_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, accountLoader: false, message: action.payload, modalVisible: false };
        }

        case 'bulk_import_account':{
            return{
                ...state,
                accountBulkModalVisible : !state.accountBulkModalVisible,
               
            }
        }
        case 'accountBulkSpinner_START':{
            return{
                ...state,
                bulkAccountSpinner : true
            }
        }
        case 'bulkImportCompanyAccount_SUCCESS':{
            return{
                ...state,
                accountBulkUpload_success: true, 
                bulkAccountSpinner : false,
                textMessage : action.payload.message
            }
        }
        case 'bulkImportCompanyAccount_FAILURE':{
            
            return{
                ...state,
                bulkAccountSpinner : false,
                accountBulkUpload_error: true, 
                textMessage : action.payload
            }
        }

        case 'account_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                credit_balance: false,
                accountLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null,
                transferringLoader: false,
                transferringSuccess: false,
                transferringError: false,
                showTransferModal: false,
                editModalVisible : false,
                accountBulkModalVisible : false,
                accountBulkUpload_error: false, 
                accountBulkUpload_success: false, 
                bulkAccountSpinner : false,

            };
        }

        case 'deleteAccount_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Accounts;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Accounts: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }

}
