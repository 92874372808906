import React, { useState, forwardRef, useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import CSmartTableHead from './CSmartTableHead'
import CTable from './components/CTable'
import CSmartTableBody from './CSmartTableBody'
import CTableFoot from './components/CTableFoot'
import { CSmartPagination, CSpinner } from '@coreui/react-pro'
import CSmartTableFilter from './CSmartTableFilter'
import CSmartTableCleaner from './CSmartTableCleaner'
import { CSmartTableItemsPerPageSelector } from './CSmartTableItemsPerPageSelector'
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from 'src/util/intlPlaceholder'

const CSmartTable = forwardRef(({
    activePage = 1,
    cleaner,
    clickableRows,
    columnFilter,
    columnFilterValue, // TODO: consider to use only columnFilter prop
    columns,
    columnSorter,
    footer,
    header = true,
    items,
    itemsPerPage = 100,
    itemsPerPageLabel = <IntlMessages id="itemsPerPage" />,
    itemsPerPageOptions = [100, 200, 500, 1000, 1500, 2000],
    itemsPerPageSelect,
    loading,
    noItemsLabel = <IntlMessages id="noItemsFound" />,
    onActivePageChange,
    onColumnFilterChange,
    onFilteredItemsChange,
    onItemsPerPageChange,
    onRowClick,
    onSorterChange,
    onTableFilterChange,
    pagination,
    paginationProps,
    scopedColumns,
    sorterValue,
    customClass,
    sortingIcon = (
        <CIcon
            width={18}
            icon={[
                '512 512',
                '<polygon fill="var(--ci-primary-color, currentColor)" points="384 433.373 384 160 352 160 352 434.51 282.177 364.687 259.55 387.313 367.432 495.196 475.313 387.313 452.687 364.687 384 433.373" class="ci-primary"></polygon><polygon fill="var(--ci-primary-color, currentColor)" points="159.432 17.372 51.55 125.255 74.177 147.882 144 78.059 144 352 176 352 176 79.195 244.687 147.882 267.313 125.255 159.432 17.372" class="ci-primary"></polygon>',
            ]}
            key="csv"
        />
    ),
    sortingIconAscending = (
        <CIcon
            width={18}
            icon={[
                '512 512',
                '<polygon fill="var(--ci-primary-color, currentColor)" points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625" class="ci-primary"></polygon>',
            ]}
            key="cat"
        />
    ),
    sortingIconDescending = (
        <CIcon
            width={18}
            icon={[
                '512 512',
                '<polygon fill="var(--ci-primary-color, currentColor)" points="367.997 338.75 271.999 434.747 271.999 17.503 239.999 17.503 239.999 434.745 144.003 338.75 121.376 361.377 256 496 390.624 361.377 367.997 338.75" class="ci-primary"></polygon>',
            ]}
            key="cab"
        />
    ),
    tableBodyProps,
    tableFootProps,
    tableFilter,
    tableFilterLabel = <IntlMessages id="Filter" />,
    tableFilterPlaceholder = intlPlaceholder('typeString'),
    tableFilterValue, // TODO: consider to use only tableFilter prop
    tableHeadProps,
    tableProps,
    summary,
    filterValueChangeTo,
    ...rest
}, ref) => {



    const compData = useRef({ firstRun: true, columnFiltered: 0, changeItems: 0 }).current

    const [_items, setItems] = useState(items || [])
    const [_itemsPerPage, setItemsPerPage] = useState(itemsPerPage)
    const [_activePage, setActivePage] = useState(activePage)
    const [sorterState, setSorterState] = useState(sorterValue || {})
    const [tableFilterState, setTableFilterState] = useState(
        tableFilterValue ? tableFilterValue : '',
    )
    const [columnFilterState, setColumnFilterState] = useState(
        columnFilterValue ? columnFilterValue : {},
    )


    const isSortable = (i) => {
        const isDataColumn = itemsDataColumns.includes(rawColumnNames[i])
        let column
        if (columns) column = columns[i]
        return (
            columnSorter &&
            (!columns ||
                typeof column !== 'object' ||
                (typeof column === 'object' &&
                    (typeof column.sorter === 'undefined' || column.sorter))) &&
            isDataColumn
        )
    }

    const sorterChange = (column, index) => {
        if (!isSortable(index)) {
            return
        }
        //if column changed or sort was descending change asc to true
        const state = sorterState

        if (state.column === column) {
            if (state.state === 0) {
                state.state = 'asc'
            } else if (state.state === 'asc') {
                state.state = 'desc'
            } else {
                state.state = 0
            }
        } else {
            state.column = column
            state.state = 'asc'
        }
        setSorterState({ ...state })
    }


    const itemsPerPageChange = (event) => {
        if (
            typeof itemsPerPageSelect !== 'object' ||
            (typeof itemsPerPageSelect === 'object' && !itemsPerPageSelect.external)
        )
            setItemsPerPage(Number(event.target.value))
    }

    const columnFilterChange = (colName, value, type) => {


        const isLazy = columnFilter || (typeof columnFilter === 'object' && columnFilter.lazy === true)
        if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
            return
        }
        const newState = { ...columnFilterState, [`${colName}`]: value }
        if (filterValueChangeTo) {
            filterValueChangeTo({ [`${colName}`]: value })
        }
        setColumnFilterState(newState)

    }

    const tableFilterChange = (value, type) => {
        const isLazy = tableFilter && typeof tableFilter === 'object' && tableFilter.lazy === true
        if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
            return
        }
        setTableFilterState(value)
    }


    const clean = () => {
        setTableFilterState('')
        setColumnFilterState({})
        setSorterState({})
    }

    const genCols = Object.keys(_items[0] || {}).filter((el) => el.charAt(0) !== '_')


    const rawColumnNames = columns
        ? columns.map((column) => {
            if (typeof column === 'object') return column.key
            else return column
        })
        : genCols
    const itemsDataColumns = rawColumnNames.filter((name) => genCols.includes(name))



    // variables

    useMemo(() => {
        compData.columnFiltered++
    }, [
        JSON.stringify(columnFilter),
        JSON.stringify(columnFilterState),
        itemsDataColumns.join(''),
        compData.changeItems,
    ])


    const columnFiltered = useMemo(() => {
        let items = _items
        if (columnFilter && typeof columnFilter === 'object' && columnFilter.external) {
            return items
        }
        Object.entries(columnFilterState).forEach(([key, value]) => {
            const columnFilter = String(value).toLowerCase()
            if (columnFilter && itemsDataColumns.includes(key)) {
                items = items.filter((item) => {
                    return String(item[key]).toLowerCase().includes(columnFilter)
                })
            }
        })
        return items
    }, [compData.columnFiltered])


    const tableFiltered = useMemo(() => {
        let items = columnFiltered
        if (
            !tableFilterState ||
            (tableFilter && typeof tableFilter === 'object' && tableFilter.external)
        ) {
            return items
        }
        const filter = tableFilterState.toLowerCase()
        const valueContainFilter = (val) => String(val).toLowerCase().includes(filter)
        items = items.filter((item) => {
            return !!itemsDataColumns.find((key) => valueContainFilter(item[key]))
        })
        return items
    }, [compData.columnFiltered, tableFilterState, JSON.stringify(tableFilter)])


    const sortedItems = useMemo(() => {
        const col = sorterState.column
        if (
            !col ||
            !itemsDataColumns.includes(col) ||
            (columnSorter && typeof columnSorter === 'object' && columnSorter.external)
        ) {
            return tableFiltered
        }
        //if values in column are to be sorted by numeric value they all have to be type number
        // const flip = sorterState.asc ? 1 : -1
        const flip = sorterState.state === 'asc' ? 1 : sorterState.state === 'desc' ? -1 : 0
        const sorted = tableFiltered.slice().sort((item, item2) => {
            const value = item[col]
            const value2 = item2[col]
            const a = typeof value === 'number' ? value : String(value).toLowerCase()
            const b = typeof value2 === 'number' ? value2 : String(value2).toLowerCase()
            return a > b ? 1 * flip : b > a ? -1 * flip : 0
        })
        return sorted
    }, [JSON.stringify(tableFiltered), JSON.stringify(sorterState), JSON.stringify(columnSorter)])


    const numberOfPages = _itemsPerPage ? Math.ceil(sortedItems.length / _itemsPerPage) : 1 //!ok ||







    const firstItemOnActivePageIndex = _activePage ? (_activePage - 1) * _itemsPerPage : 0 //!ok


    const itemsOnActivePage = sortedItems.slice(
        firstItemOnActivePageIndex,
        firstItemOnActivePageIndex + _itemsPerPage,
    )



    const currentItems = _activePage ? itemsOnActivePage : sortedItems

    const isFiltered =
        tableFilterState || sorterState.column || Object.values(columnFilterState).join('')



    useEffect(() => {
        onActivePageChange && onActivePageChange(_activePage)
    }, [_activePage])

    useEffect(() => {
        onItemsPerPageChange && onItemsPerPageChange(_itemsPerPage)
        setActivePage(1) // TODO: set proper page after _itemsPerPage update
    }, [_itemsPerPage])

    useEffect(() => {
        onSorterChange && onSorterChange(sorterState)
    }, [JSON.stringify(sorterState)])

    // useEffect(() => {
    //     let isDropDownFilterApply = columns.filter(x => x.filterType === 'dropdown')
    //     if (isDropDownFilterApply && isDropDownFilterApply.length > 0 && onColumnFilterChange) {
    //         let filterDefaultValue = isDropDownFilterApply[0].defaultValue
    //         // onColumnFilterChange(columnFilterState)
    //     }
    // }, [])

    useEffect(() => {
        onColumnFilterChange && onColumnFilterChange(columnFilterState)
    }, [JSON.stringify(columnFilterState)])

    useEffect(() => {
        onTableFilterChange && onTableFilterChange(tableFilterState)
    }, [tableFilterState])

    useEffect(() => {
        !compData.firstRun && onFilteredItemsChange && onFilteredItemsChange(sortedItems)
    }, [JSON.stringify(sortedItems)])

    // watch
    useMemo(() => setItemsPerPage(itemsPerPage), [itemsPerPage])
    useMemo(() => setSorterState({ ...sorterValue }), [sorterValue])
    useMemo(() => setColumnFilterState({ ...columnFilterValue }), [columnFilterValue])

    //items
    useMemo(() => {
        if (
            items &&
            !compData.firstRun &&
            (items.length !== _items.length || JSON.stringify(items) !== JSON.stringify(_items))
        ) {
            setItems(items)
            compData.changeItems++
        }
    }, undefined)

    compData.firstRun = false






    return (
        <React.Fragment>
            <div {...rest} ref={ref}>
                {(itemsPerPageSelect || tableFilter || cleaner) && (
                    <div className={`row ${(tableFilter || cleaner) ? "my-2" : ""}  mx-0`}>
                        {(tableFilter || cleaner) && (
                            <>
                                <div className="col-auto p-0">
                                    {tableFilter && (
                                        <CSmartTableFilter
                                            filterLabel={tableFilterLabel}
                                            filterPlaceholder={tableFilterPlaceholder}
                                            onInput={(e) => {
                                                tableFilterChange(e.target.value, 'input')
                                            }}
                                            onChange={(e) => {
                                                tableFilterChange(e.target.value, 'change')
                                            }}
                                            value={tableFilterState || ''}
                                        />
                                    )}
                                </div>
                                <div className="col-auto p-0">
                                    {cleaner && (
                                        <CSmartTableCleaner
                                            isFiltered={isFiltered}
                                            onClick={() => clean()}
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter') clean()
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            <div className={`position-relative ${customClass}`}>
                <CTable {...tableProps}>
                    {header && (
                        <CSmartTableHead
                            {...tableHeadProps}
                            columnFilter={columnFilter}
                            columns={columns ? columns : rawColumnNames}
                            columnSorter={columnSorter}
                            sorterState={sorterState}
                            sortingIcon={sortingIcon}
                            sortingIconAscending={sortingIconAscending}
                            sortingIconDescending={sortingIconDescending}
                            handleSort={(key, index) => sorterChange(key, index)}
                            handleFilterOnChange={(key, event) => columnFilterChange(key, event, 'change')}
                            handleFilterOnInput={(key, event) => columnFilterChange(key, event, 'input')}
                        />
                    )}
                    <CSmartTableBody
                        currentItems={currentItems}
                        firstItemOnActivePageIndex={firstItemOnActivePageIndex}
                        noItemsLabel={noItemsLabel}
                        onRowClick={(item, index, columnName, event) =>
                            clickableRows && onRowClick && onRowClick(item, index, columnName, event)
                        }
                        rawColumnNames={rawColumnNames}
                        scopedColumns={scopedColumns}
                        summary={summary}
                        {...tableBodyProps}
                    />
                    {footer && (
                        <CSmartTableHead
                            component={CTableFoot}
                            {...tableFootProps}
                            columnFilter={false}
                            columnSorter={false}
                            columns={columns ? columns : rawColumnNames}
                        />
                    )}
                </CTable>
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-50%)',
                        }}
                    >
                        <CSpinner variant="grow" color="primary" />
                    </div>
                )}
            </div>

            {(pagination || itemsPerPageSelect) && (
                <div className="row">
                    <div className="col-auto">
                        {pagination && numberOfPages > 1 && (
                            <CSmartPagination
                                {...paginationProps}
                                onActivePageChange={(page) => {
                                    setActivePage(page)
                                }}
                                pages={numberOfPages}
                                activePage={_activePage}
                            />
                        )}
                    </div>
                    <div className="col-auto ms-auto">
                        {itemsPerPageSelect && (
                            <CSmartTableItemsPerPageSelector
                                itemsPerPage={_itemsPerPage}
                                itemsPerPageLabel={itemsPerPageLabel}
                                itemsPerPageOptions={itemsPerPageOptions}
                                onChange={(event) => itemsPerPageChange(event)}
                            />
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
)


CSmartTable.propTypes = {
    activePage: PropTypes.number,
    cleaner: PropTypes.bool,
    clickableRows: PropTypes.bool,
    columnFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    columnFilterValue: PropTypes.object,
    columns: PropTypes.array,
    columnSorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    footer: PropTypes.bool,
    header: PropTypes.bool,
    items: PropTypes.array,
    itemsPerPage: PropTypes.number,
    itemsPerPageLabel: PropTypes.string,
    itemsPerPageOptions: PropTypes.array,
    itemsPerPageSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    loading: PropTypes.bool,
    noItemsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onActivePageChange: PropTypes.func,
    onColumnFilterChange: PropTypes.func,
    onFilteredItemsChange: PropTypes.func,
    onItemsPerPageChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onSorterChange: PropTypes.func,
    onTableFilterChange: PropTypes.func,
    pagination: PropTypes.bool,
    paginationProps: PropTypes.any, // TODO: update
    scopedColumns: PropTypes.object,
    sorterValue: PropTypes.object,
    sortingIcon: PropTypes.node,
    sortingIconAscending: PropTypes.node,
    sortingIconDescending: PropTypes.node,
    tableBodyProps: PropTypes.object,
    tableFootProps: PropTypes.object,
    tableFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    tableFilterLabel: PropTypes.string,
    tableFilterPlaceholder: PropTypes.string,
    tableFilterValue: PropTypes.string,
    tableHeadProps: PropTypes.object,
    tableProps: PropTypes.object,
}


export default CSmartTable
