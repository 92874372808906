import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";

import { useLocation } from "react-router-dom";
import ViewDetailComponent from './salaryDetails'
const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const comProps = useSelector(state => state.esReducer);
  const fetchInvoiceInfo = () => {
      const { guid } = location.state;
      dispatch(DataGetAction('getSalaryDetails', { query: "guid", guid }, 'fetchingSalaryDetails'))
  }

  useEffect(fetchInvoiceInfo, [])



  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            
            <CCardBody>
            {comProps.fetchDetail ? <CSpinner/> :<ViewDetailComponent />}
            </CCardBody>
          </CCard>
        </CCol>
        
      
      </CRow>
    </>
  );
};

export default Index;
