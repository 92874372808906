import React, { useState } from 'react'
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CForm,
    CLoadingButton
} from '@coreui/react-pro'
import { useDispatch } from 'react-redux'
import { DeletedRecord } from '../../redux/actions/Common'
import { intlPlaceholder } from 'src/util/intlPlaceholder';
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const DeleteModal = ({ type, method = "Delete", variant = "outline", selectedItem, titleLanguageId, contentLangueId, Icon, ButtonTitleId, okButton, cancelButton, size, color = "danger", textColor = "#000000" }) => {


    const dispatch = useDispatch();

    let yesText = intlPlaceholder('yes')
    let noText = intlPlaceholder('no')

    const [showDeleteModal, setChowDeleteModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const toggleDeleteModal = () => {
        setChowDeleteModal(!showDeleteModal)
    }

    const DeleteOkayCall = () => {
        try {

            setShowLoader(true)
            const token = localStorage.getItem('token');
            fetch(`${ROOT_URL}/${type}`, {
                method: method,
                headers: new Headers({
                    "Content-Type": "application/json",
                    'Authorization': token,
                }),
                body: JSON.stringify(selectedItem)
            }).then((response) => {
                response.json().then((response) => {
                    if (response.status === "ERROR") {
                        dispatch({
                            type: `${type}_FAILURE`,
                            payload: response.message,
                        });
                        // message.error("Impossible de supprimer, car un enregistrement existe dans certaines entrées");
                        // message.error("Can not delete, as record exist in some entries");
                    }
                    setShowLoader(false)
                    setChowDeleteModal(false)
                    dispatch(DeletedRecord(type, response))

                })
            }).catch(err => {
                setShowLoader(false)

            })
        } catch (error) {
            setShowLoader(false)
        }
    }

    return (
        <>
            <CButton size={size} color={color} variant={variant} className="me-3" onClick={() => toggleDeleteModal()}>
                <span style={{
                    color: textColor
                }}>{intlPlaceholder(ButtonTitleId)}</span>
            </CButton>
            <CModal visible={showDeleteModal} className="delete-modal">
                <CForm >
                    <CModalBody style={{ padding: '32px 32px 24px' }}>
                        <div className="d-flex align-items-start">
                            {Icon ? Icon : <i className="icon icon-exclamation me-4" style={{ color: '#fa8c16', fontSize: '22px' }}></i>}
                            <div>
                                <p className="mb-2" style={{ lineHeight: 'initial' }}>{intlPlaceholder(titleLanguageId)}</p>
                                <p style={{ lineHeight: 'initial', fontSize: '14px', color: 'gray' }}>{intlPlaceholder(contentLangueId)}</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end" style={{ marginTop: '24px' }}>
                            {okButton ? okButton : <CLoadingButton className="me-3" disabled={showLoader} loading={showLoader} type="button" variant="outline" color="danger" onClick={() => {
                                if (!showLoader) {
                                    DeleteOkayCall()
                                }
                            }}>
                                {yesText}
                            </CLoadingButton>}
                            {cancelButton ? cancelButton : <CButton color="secondary" variant="outline"  onClick={() => toggleDeleteModal()}>
                                {noText}
                            </CButton>}

                        </div>
                    </CModalBody>
                </CForm>
            </CModal>
        </>
    )
}

export default DeleteModal
