import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { CButton, CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import { selectStyle } from "../../constants/CommonFn";

const StockStatusFilter = ({ setFilter }) => {
  const dispatch = useDispatch();

  const available = intlPlaceholder("report.stockStatus.available");
  const all = intlPlaceholder("report.stockStatus.all");
  const unavailable = intlPlaceholder("report.stockStatus.unavailable");

  // form validation rules
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(<IntlMessages id="dateRequired" />),
    status: Yup.string().required(<IntlMessages id="fieldRequired" />),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(), new Date().toISOString()], // Default value here
      status: "available",
    },
  };
  const { register, handleSubmit, reset, formState, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    if (values.date) {
      values.startDate = moment(values.date[0]).utc();
      values.endDate = moment(values.date[1]).utc();
    } else {
      values.startDate = moment().utc();
      values.endDate = moment().utc();
    }

    let filterObj;
    if (values.date) {
      filterObj = {
        status: values.status,
        startDate: values.startDate,
        endDate: values.endDate,
      };
    } else {
      filterObj = {
        status: values.status,
        startDate: moment().toString(),
        endDate: moment().toString(),
      };
    }
    setFilter(filterObj);
    dispatch({ type: "setFilterDateInRedux", payload: filterObj });
  };

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  return (
    <CForm className="d-flex flex-wrap p-3" onSubmit={handleSubmit(onFinish)}>
      <div className=" me-3">
        <Controller
          control={control}
          name="date"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
          )}
        />
        <div className="error-message ">{errors.date?.message}</div>
      </div>
      <div className=" me-3">
        <div style={{ width: "200px" }}>
          <Controller
            control={control}
            name="status"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Select
                className={errors.status ? "is-invalid" : ""}
                isSearchable={true}
                styles={selectStyle}
                defaultValue={{
                  value: "available",
                  label: available,
                }}
                placeholder={intlPlaceholder(
                  "SupplierProductFilter.selectSupplier"
                )}
                onChange={(e) => {
                  onChange(e.value);
                }}
                options={[
                  { label: all, value: "all" },
                  { label: available, value: "available" },
                  { label: unavailable, value: "unavailable" },
                ]}
              />
            )}
          />

          <div className="error-message ">{errors.status?.message}</div>
        </div>
      </div>

      <div className={``}>
        <div className="">
          <CLoadingButton
            className="d-flex align-items-center"
            type="submit"
            color="primary"
          >
            <i className="gx-icon-filter-300 me-1 text-white"></i>
            <IntlMessages id="balanceSheet.generateButton" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default StockStatusFilter;
