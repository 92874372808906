import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import CheckUserAccess from "../../../util/checkUserAccess";
const BankTable = ({ Data, Spinner }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);

  let columns = [
    {
      label: <IntlMessages id="productStock.Id" />,
      key: "productId",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="productStock.name" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="productStock.category" />,
      key: "categoryName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="productStock.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="productStock.price" />,
      key: "price",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="productStock.size" />,
      key: "size",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="productStock.packaging" />,
      key: "packaging",
      _style: { width: "40%" },
    },
    
  ];

  let found = CheckUserAccess("product", ["edit","delete"])
  if(found){
    columns.push({
      key: "actions",
      label : '',
      _style: { width: "40%" },
      filter: false,
      sorter : false
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const editRecord = (data) => {
    dispatch({
      type: "edit_productStock_modal",
      payload: data,
    });
  };

  const edit = CheckUserAccess('product',['edit']) 
  const deleted = CheckUserAccess('product',['delete']) 
  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={Spinner}
        items={Spinner ? [] : Data && Data.length > 0 ? Data : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          price: (record) => {
            return (
              <td>
                {ChangeNumberFormate(parseFloat(record.price).toFixed(2))}
              </td>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
               {edit &&  <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() => editRecord(record)}
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-edit me-2"></span>
                      <IntlMessages id="Edit" />
                    </span>
                  </CButton>}

                {deleted &&   <DeleteModal
                    contentLangueId="areYouSureYouWantToDelete"
                    type="deleteProductStock"
                    selectedItem={record}
                    ButtonTitleId="Delete"
                    titleLanguageId="Delete"
                    size="sm"
                  />}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default BankTable;
