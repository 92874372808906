import React, { useState, useEffect } from "react";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { DataGetAction } from "../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import {selectStyle} from '../../../constants/CommonFn'
import DateRangePicker from "react-bootstrap-daterangepicker";
const StoreProductFilter = ({ setFilters }) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [key, setKey] = useState(1)


  const dispatch = useDispatch();
  const BSProps = useSelector(state => state.balanceSheet)
  const onFinish = (values) => {

    let selectedStore = Store.filter(x => x._id === values.store);

    if (values.date) {
      values.startDate = values.date[0];
      values.endDate = values.date[1];
    } else {
      values.startDate = moment().startOf().toString();
      values.endDate = moment().toString();
    }



    values.storeName = selectedStore[0].name
    setFilters(values)
    dispatch(DataGetAction('storeProductSheet', values, ''))
  }

  const fetchAllBankAndCurrencyList = () => {
    dispatch(DataGetAction('getAllStore', { query: "all" }, 'getAllStorestart'));
  }

  useEffect(fetchAllBankAndCurrencyList, [])
  const { Store, storeFetchSuccess,storeProductSpinner } = BSProps;


  const validationSchema = Yup.object().shape({
    store: Yup.string().required(
      <IntlMessages id="balanceSheet.Storefilter.selectStore_message" />
    ),
    
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValue: {
      store: Store && Store.length > 0 && Store[0]._id,
      date: [new Date().toISOString(), new Date().toISOString()],
    }
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control, setValue, watch } =
    useForm(formOptions);
  const { errors } = formState;

  const setInitialValue = () =>{
    if(Store && Store.length >0){
      reset({
        store : Store && Store.length >0 && Store[0]._id
      })
    }
    
  }

  useEffect(setInitialValue, [storeFetchSuccess])

  if (storeFetchSuccess && Store) {
    if (Store && Store.length) {
      // form.setFieldsValue({ "store": Store[0]._id })

      // let startDate = moment().startOf('year').toString();
      // let endDate = moment().toString();

      // setFilters({ "store": Store[0]._id, storeName: Store[0].name, startDate, endDate })
      // dispatch(DataGetAction('storeProductSheet', { "store": Store[0]._id, storeName: Store[0].name, startDate, endDate }, 'fetchingStoreProductSheet'))
      // setKey(key+1)
    }
    dispatch({
      type: 'resetAllStore'
    })
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };


  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const onDateChange = (data) => {
    const [start, end] = data;
    setStartDate(start);
    setEndDate(end);

   
  };


 
  return (
    <div className="p-3">
   <CForm className="d-flex" onSubmit={handleSubmit(onFinish)} key={key}>
        
   <div className=" me-3">
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
             
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker >
            )}
          />
           <div className="error-message ">{errors.date?.message}</div>
        </div>
        <div className=" me-4">
          <div style={{width : '220px'}}>
          <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  styles={selectStyle}
                  // defaultValue={{
                  //   value: Store && Store.length >0 && Store[0]._id , 
                  //   label: Store && Store.length >0 && Store[0].name,
                  // }}
                  placeholder=  {intlPlaceholder("storeProductFilter.store")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                 
                  options={renderOptions([...Store], "name", "_id")}
                />
              )}
            />
           
          
            <div className="invalid-feedback">{errors.store?.message}</div>
          </div>
        </div>
        <div className="">
          <div className="">
            <CLoadingButton className="d-flex align-items-center" type="submit" color="primary">
            <i className="gx-icon-filter-300 me-1 text-white"></i>
              <IntlMessages id="balanceSheet.generateButton" />
            </CLoadingButton>
          </div>
        </div>
       
      </CForm>
    </div>
  );
};

export default StoreProductFilter;
