const INIT_STATE = {
  deliveredLocalProduct: [],
  deliveredLocalProductSpin: false,
  DeliveryList: [],
  DeliveryListSpin: true,
  ShowDeliveredModal: false,
  initialValues: null,
  DeliveryDetailModalSpin: false,
  DeliveryDetailModal: false,
  initialValues: null,
  createDeliveredProductData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getSaleInvoiceForDeliverySlip_SUCCESS": {
      const Record = getDeliveredLocalProducts(action.payload.Record);
      return {
        ...state,
        deliveredLocalProduct: [...Record],
        deliveredLocalProductSpin: false,
      };
    }
    case "getSaleInvoiceForDeliverySlip_FAILURE": {
      return {
        ...state,
        deliveredLocalProductSpin: false,
      };
    }
    case "getSaleInvoiceForDeliverySpin_START": {
      return {
        ...state,
        deliveredLocalProductSpin: true,
      };
    }
    case "RESET_Delivered_LOCAL_PRODUCT": {
      return {
        ...state,
        deliveredLocalProduct: [],
      };
    }
    case "updateDeliveryLocalProduct": {
      const result = getUpdatedLocalProducts(
        action.payload,
        state.deliveredLocalProduct
      );
      return {
        ...state,
        deliveredLocalProduct: [...result],
      };
    }

    case "createDeliverySlip_SUCCESS": {
      return {
        ...state,
        createDeliveredProductData: action.payload.Record,
        createDeliveredProductSpin: false,
        createDeliveredProductSuccess: true,
      };
    }
    case "createDeliverySlip_FAILURE": {
      return {
        ...state,
        createDeliveredProductSpin: false,
        createDeliveredProductError: true,
        textMessage: action.payload,
      };
    }
    case "createDeliverySpin_START": {
      return {
        ...state,
        createDeliveredProductSpin: true,
      };
    }

    case "deliveredValueRest": {
      return {
        ...state,
        createDeliveredProductSuccess: false,
        createDeliveredProductError: false,
      };
    }
    case "getDeliveryList_SUCCESS": {
      return {
        ...state,
        DeliveryList: action.payload.Record,
        DeliveryListSpin: false,
      };
    }
    case "deliveryListSpin_START": {
      return {
        ...state,
        DeliveryListSpin: true,
      };
    }
    case "Show_View_Delivered_Modal": {
      return {
        ...state,
        ShowDeliveredModal: true,
        initialValues: action.payload,
      };
    }
    case "Hide_View_Delivered_Modal": {
      return {
        ...state,
        ShowDeliveredModal: false,
        initialValues: null,
      };
    }
    case "getDeliveryDetail_SUCCESS": {
      return {
        ...state,
        DeliveryDetailModal: action.payload.Record,
        DeliveryDetailModalSpin: false,
      };
    }
    case "ViewDeliveryModalSpin_START": {
      return {
        ...state,
        DeliveryDetailModalSpin: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};
const getUpdatedLocalProducts = (payload, localProducts) => {
  let updatedResult = [];
  let { fieldChanged, updatedRow, newValue } = payload;
  if (fieldChanged === "qtyToDeliver") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return { ...item, qtyToDeliver: parseFloat(newValue) };
      } else {
        return item;
      }
    });
  }
  return [...updatedResult];
};

const getDeliveredLocalProducts = (data) => {
  let updatedResult = [];
  updatedResult = data.map((item) => {
    return { ...item, qtyToDeliver: 0 };
  });
  return [...updatedResult];
};
