const INIT_STATE = {
    message: '',
    success: false,
    spinner: false,
    error : false,
    showViewDialog : false,
    showEditDialog : false,
    data : null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {        

        case "openViewDialog": {
            return {
              ...state,
              showViewDialog: true,
              data: action.payload,
            };
        }

        case "openEditDialog": {
            return {
              ...state,
              showEditDialog: true,
              data: action.payload,
            };
        }

        case "closeViewDialog": {
            return {
              ...state,
              showViewDialog: false,
              data: null,
            };
        }

        case "closeEditDialog": {
          return {
            ...state,
            showEditDialog: false,
            data: null,
          };
      }
        //custom data: page has many dialog
        case "openCustomDialog": {
            return {
              ...state,
              showDialog: true,
              //dataCustom: action.payload,
            };
        }
        
        default:
            return state;
    }
}
