import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangeDateFormate, ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import {intlPlaceholder} from "../../util/intlPlaceholder";
import AddModal from './components/AddModal'
import StoreList from '../../components/CSSmartTable'
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
  const history = useHistory()
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({ payableStatus: 'approved' });

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const columns = [
    {
      label:<IntlMessages id="store.name" />,
      key: "storeName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="store.paymentType" />,
      key: "paymentType",
      _style: { width: "40%" },
    },
    {
      label:  <IntlMessages id="store.receiptNumber" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="store.amount" />,
      key: "paid",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "payableStatus",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approved'), value: 'approved', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="store.action" />,
      label : '',
      key: "show_details",
      filter : false,
      sorter : false,
      _style: { width: "40%" },
    },

  ];

  const dispatch = useDispatch();
  const comProps = useSelector(state => state.storeReducer);
  const modalType = "show_store_modal";

  const editRecord = (data) => {
      dispatch({
          type: modalType,
          payload: data
      })
  }

  const assignEmployee = (data) => {
      dispatch({
          type: 'assign_employee_modal',
          payload: data
      })
  }


  const showWithDrawModal = () => {
      dispatch({
          type: "showWithDrawModal",
      });
  }

  const fetchStore = () => {

      dispatch(DataGetAction('getAllStoreWithdraw', { query: "all" },'storeWithDrawSpinner'));
  }
  useEffect(fetchStore, [comProps.fetchStoreInfo])

 
  if(comProps.withDrawSuccess){
    fetchStore()
    dispatch({
      type : 'store_UPDATE'
    })
  }
  const create = CheckUserAccess('storeWithDrawals',['create'])
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="store.WithdrawTitle" />
              </h1>
             
            </CCardHeader>
            <CCardBody>
            <div className="p-3 pb-0">
           
            {create &&  <AddModal />}
            </div>
              <DocsExample href="components/smart-table/">
                <StoreList
                  loading={comProps.storeWithDrawSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  filterValueChangeTo={(value) => {
                    if (value.payableStatus) {
                      setStaticFilterValue(value)
                    }
                  }}
                  items={comProps.storeWithDrawSpinner ? [] : comProps.storeWithdrawList}
                  columns={columns}
                  columnFilter
                  columnFilterValue={staticFilterValue}
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={200}
                  columnSorter
                  pagination
                  scopedColumns={{
                    payableStatus: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.payableStatus === 'approved' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.payableStatus}`)}</CBadge>
                        </td>
                      );
                    },
                    
                    paymentType : (record)=>{
                      return  <td  style={{
                        textTransform: "capitalize",
                      }}>{ <IntlMessages id={`${record.paymentType}.paymentMethod`} /> }</td>
                  },
                    refNo: (record) => {
                      return <td>{record.refNo}</td>;
                    },
                    paid: (record) => {
                      return <td className="text-end">{ChangeNumberFormate(record.paid.toFixed(2))}</td>;
                    },
                 
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                           <CCardBody>
                           <CButton
                            size="sm"
                            color="warning"
                            className="me-3"
                            onClick={() =>  history.push({pathname : `/viewReceipt`, state :  { ReceiptNo: parseInt(record.receiptNumber) } } )}
                          >
                            <span style={{ color: "white" }}>
                              <span className="icon icon-custom-view me-2"></span>
                              <IntlMessages id="view" />
                            </span>
                          </CButton>
                           </CCardBody>
                        
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let total = 0;
          
                    pageData.forEach(({ paid }) => {
                        total += paid;
                    });
          
                    return (
                        <tr>
                            <th colSpan={2} className="text-center"><h5><IntlMessages id='Total' /></h5></th>
                           
                            <th colSpan={2} className="text-end">
                                <span><h5>{ChangeNumberFormate(total.toFixed(2))}</h5></span>
                            </th>
                            <th colSpan={1}>
                                <span></span>
                            </th>
                        </tr>
                    );
                }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
