import React from "react";
import IntlMessages from "./util/IntlMessages";
import Dashboard from "./views/dashboard/Dashboard";
import Company from "./views/company";
import adminDashboard from "./views/dashboard/Dashboard";
import Stock from "./views/stocks";
import Units from "./views/unit";
import addUnit from "./views/unit/components/addUnit";
import editUnit from "./views/unit/components/EditUnitFormContainer";
import Supplier from "./views/Supplier";
import StoreManage from "./views/Store";
import CustomerManage from "./views/Customer";
import addStock from "./views/stocks/addStock";
import Currency from "./views/Currency";
import Bank from "./views/bank";
import Product from "./views/product";
import Category from "./views/category";
import Account from "./views/account";
import CompanyProfile from "./views/companyProfile";
import ResetPassword from "./views/ResetPassword";
import BankSummery from "./views/balanceSheet/bankSummery";
import BankBalanceSheet from "./views/balanceSheet/bankBalanceSheet";
import StoreBalanceSummery from "./views/balanceSheet/storeBalanceSummery";
import StoreBalanceSheet from "./views/balanceSheet/storeBalanceSheet";
import SupplierSummery from "./views/balanceSheet/supplierSummery";
import supplierBalanceSheet from "./views/balanceSheet/supplierBalanceSheet";
import SupplierProduct from "./views/balanceSheet/supplierProduct";
import storeProductReport from "./views/balanceSheet/storeProductReport";
import TrustBank from "./views/trustBank/users";
import TrustUserBalanceSheet from "./views/trustBank/trustUserBalanceSheet";
import FundTransfer from "./views/fundTransfer";
import FundTransferDetail from "./views/fundTransfer/view";
import StoreWithdraw from "./views/StoreWithdraw";
import ViewReceipt from "./views/StoreWithdraw/components/viewReceipt";
import CompanyExpenses from "./views/companyExpenses";
import CompanyExpensesView from "./views/companyExpenses/components/companyExpensesView";
import StoreExpenses from "./views/storeExpenses";
import StoreExpensesView from "./views/storeExpenses/components/storeExpensesView";
import CurrencyExchange from "./views/currencyExchange";
import ViewCurrencyExchangeReceipt from "./views/currencyExchange/components/viewCurrencyExchangeReceipt";
import Salary from "./views/salary";
import AddSalary from "./views/salary/components/addSalary";
import EmployeeBalanceSheet from "./views/balanceSheet/employeeBalanceSheet";
import CompanySaleOnBeHalfOfStore from "./views/storeInvoice/companySale";
import CreateInvoice from "./views/storeInvoice/components/createInvoice";
import ViewStoreInvoice from "./views/storeInvoice/components/viewStoreInvoice";
import CustomerReceipt from "./views/customerReceipt";
import ViewStoreReceipt from "./views/customerReceipt/components/viewStoreReceipt";
import Receipt from "./views/receipt";
import DueReceipt from "./views/receipt/components/viewDueReceipt";
import StoreReceipt from "./views/storeReceipt";
import Invoice from "./views/invoice";
import ViewInvoice from "./views/invoice/components/viewInvoice";
import StoreInvoice from "./views/storeInvoice";
import Distribute from "./views/products/distribute";
import StoreToStoreCreateInvoice from "./views/storeToStore/components/createInvoice";
import ViewStoreToStoreInvoice from "./views/storeToStore/viewStoreInvoice";
import ViewStoreToStoreCredit from "./views/storeToStore/viewStoreReceipt";
import ViewTrustDetail from "./views/trustBank/trustUserBalanceSheet/components/view";
import SalaryDetail from "./views/salary/components/viewSalaryDetail";
import SalaryDetails from "./views/salary/components/viewSalaryDetails";
import UserRoles from "./views/teamManagement/userRole";
import AddUserRoles from "./views/teamManagement/userRole/components/AddUserRoles";
import ManageUsers from "./views/teamManagement/manageUsers";
import TrustUserAccounts from "./views/balanceSheet/TrustUserAccounts";
import TrustUserBalanceSheetList from "./views/balanceSheet/TrustUserBalanceSheetList";
import StockStatus from "./views/report/stock-status";
import DebitNotes from "./views/stockTransfer/debit-notes";
import CreditNotes from "./views/stockTransfer/credit-notes";
import UserDeposit from "./views/userDeposit";
import UserWithdraw from "./views/userWithdraw";
import QuickSale from "./views/quickSale";
import AddQuickSale from "./views/quickSale/component/AddQuickSale";
import EditQuickSale from "./views/quickSale/component/EditQuickSaleModal";
import SaleInvoice from "./views/saleInvoice";
import Property from "./views/property";
import Contract from "./views/Contract";

import AddSaleInvoice from "./views/saleInvoice/components/AddSaleInvoice";
import ViewSaleInvoice from "./views/saleInvoice/components/viewSaleInvoice";
import Delivered from "./views/Delivered";
import AddDelivered from "./views/Delivered/components/addDelivered";
import ViewStockStatus from "./views/report/viewStockStatus";
import Quotations from "./views/quotations";
import AddQuotations from "./views/quotations/components/AddQuotations";
import EditQuotation from "./views/quotations/components/EditQuotation";
const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/dashboard",
    exact: true,
    name: <IntlMessages id="bc.dashboard" />,
    component: Dashboard,
  },
  {
    path: "/company",
    exact: true,
    name: <IntlMessages id="bc.company" />,
    component: Company,
  },
  {
    path: "/adminDashboard",
    exact: true,
    name: <IntlMessages id="bc.dashboard" />,
    component: adminDashboard,
  },
  {
    path: "/stock",
    exact: true,
    name: <IntlMessages id="bc.stock" />,
    component: Stock,
  },
  {
    path: "/addStock",
    exact: true,
    name: <IntlMessages id="bc.addStock" />,
    component: addStock,
  },
  {
    path: "/units",
    exact: true,
    name: <IntlMessages id="bc.units" />,
    component: Units,
  },
  {
    path: "/addUnit",
    exact: true,
    name: <IntlMessages id="bc.addUnit" />,
    component: addUnit,
  },
  {
    path: "/editUnit",
    exact: true,
    name: <IntlMessages id="bc.editUnit" />,
    component: editUnit,
  },
  {
    path: "/supplier",
    exact: true,
    name: <IntlMessages id="bc.supplier" />,
    component: Supplier,
  },
  {
    path: "/stores",
    exact: true,
    name: <IntlMessages id="bc.stores" />,
    component: StoreManage,
  },
  {
    path: "/customer",
    exact: true,
    name: <IntlMessages id="bc.customer" />,
    component: CustomerManage,
  },
  {
    path: "/currency",
    exact: true,
    name: <IntlMessages id="bc.currency" />,
    component: Currency,
  },
  {
    path: "/bank",
    exact: true,
    name: <IntlMessages id="bc.bank" />,
    component: Bank,
  },
  {
    path: "/product",
    exact: true,
    name: <IntlMessages id="bc.product" />,
    component: Product,
  },
  {
    path: "/category",
    exact: true,
    name: <IntlMessages id="bc.category" />,
    component: Category,
  },
  {
    path: "/account",
    exact: true,
    name: <IntlMessages id="bc.account" />,
    component: Account,
  },
  {
    path: "/profile",
    exact: true,
    name: <IntlMessages id="bc.profile" />,
    component: CompanyProfile,
  },
  {
    path: "/resetPassword",
    exact: true,
    name: <IntlMessages id="bc.resetPassword" />,
    component: ResetPassword,
  },

  {
    path: "/BankSummery",
    exact: true,
    name: <IntlMessages id="bc.companyAccounts" />,
    component: BankSummery,
  },
  {
    path: "/bankBalanceSheet",
    exact: true,
    name: <IntlMessages id="bc.bankBalanceSheet" />,
    component: BankBalanceSheet,
  },
  {
    path: "/StoreBalanceSummery",
    exact: true,
    name: <IntlMessages id="bc.storeAccounts" />,
    component: StoreBalanceSummery,
  },
  {
    path: "/storeBalanceSheet",
    exact: true,
    name: <IntlMessages id="bc.storeBalanceSheet" />,
    component: StoreBalanceSheet,
  },
  {
    path: "/SupplierSummery",
    exact: true,
    name: <IntlMessages id="bc.supplierAccounts" />,
    component: SupplierSummery,
  },
  {
    path: "/supplierBalanceSheet",
    exact: true,
    name: <IntlMessages id="bc.supplierBalanceSheet" />,
    component: supplierBalanceSheet,
  },
  {
    path: "/supplierProduct",
    exact: true,
    name: <IntlMessages id="bc.supplierOrderHistory" />,
    component: SupplierProduct,
  },
  {
    path: "/storeProductReport",
    exact: true,
    name: <IntlMessages id="bc.customerOrderHistory" />,
    component: storeProductReport,
  },
  {
    path: "/userList",
    exact: true,
    name: <IntlMessages id="bc.trustUser" />,
    component: TrustBank,
  },
  {
    path: "/userBalanceSheet",
    exact: true,
    name: <IntlMessages id="bc.trustFunds" />,
    component: TrustUserBalanceSheet,
  },
  {
    path: "/fund-transfer",
    exact: true,
    name: <IntlMessages id="bc.fundTransfer" />,
    component: FundTransfer,
  },
  {
    path: "/fundTransferDetail",
    exact: true,
    name: <IntlMessages id="bc.fundTransferDetail" />,
    component: FundTransferDetail,
  },
  {
    path: "/storeWithdraw",
    exact: true,
    name: <IntlMessages id="bc.storeWithdraw" />,
    component: StoreWithdraw,
  },
  {
    path: "/viewReceipt",
    exact: true,
    name: <IntlMessages id="bc.viewReceipt" />,
    component: ViewReceipt,
  },
  {
    path: "/companyExpenses",
    exact: true,
    name: <IntlMessages id="bc.companyExpenses" />,
    component: CompanyExpenses,
  },
  {
    path: "/viewExpenseDetail",
    exact: true,
    name: <IntlMessages id="bc.viewExpenseDetail" />,
    component: CompanyExpensesView,
  },
  {
    path: "/storeExpenses",
    exact: true,
    name: <IntlMessages id="bc.storeExpenses" />,
    component: StoreExpenses,
  },
  {
    path: "/viewStoreExpenseDetail",
    exact: true,
    name: <IntlMessages id="bc.viewStoreExpenseDetail" />,
    component: StoreExpensesView,
  },
  {
    path: "/currencyExchange",
    exact: true,
    name: <IntlMessages id="bc.currencyExchange" />,
    component: CurrencyExchange,
  },
  {
    path: "/viewCurrencyExchangeReceipt",
    exact: true,
    name: <IntlMessages id="bc.viewCurrencyExchangeReceipt" />,
    component: ViewCurrencyExchangeReceipt,
  },
  {
    path: "/salary",
    exact: true,
    name: <IntlMessages id="bc.salary" />,
    component: Salary,
  },
  {
    path: "/salary/addSalary",
    exact: true,
    name: <IntlMessages id="bc.addSalary" />,
    component: AddSalary,
  },
  {
    path: "/employeeBalanceSheet",
    exact: true,
    name: <IntlMessages id="bc.employeeBalanceSheet" />,
    component: EmployeeBalanceSheet,
  },
  {
    path: "/productSale",
    exact: true,
    name: <IntlMessages id="bc.invoices" />,
    component: CompanySaleOnBeHalfOfStore,
  },
  {
    path: "/createInvoice",
    exact: true,
    name: <IntlMessages id="bc.createInvoice" />,
    component: CreateInvoice,
  },
  {
    path: "/viewStoreInvoice",
    exact: true,
    name: <IntlMessages id="bc.viewStoreInvoice" />,
    component: ViewStoreInvoice,
  },
  {
    path: "/storeReceipt",
    exact: true,
    name: <IntlMessages id="bc.receipts" />,
    component: CustomerReceipt,
  },
  {
    path: "/viewStoreReceipt",
    exact: true,
    name: <IntlMessages id="bc.viewStoreReceipt" />,
    component: ViewStoreReceipt,
  },
  {
    path: "/receipt",
    exact: true,
    name: <IntlMessages id="bc.supplierReceipts" />,
    component: Receipt,
  },
  {
    path: "/duesReceipt",
    exact: true,
    name: <IntlMessages id="bc.duesReceipt" />,
    component: DueReceipt,
  },
  {
    path: "/companyReceipt",
    exact: true,
    name: <IntlMessages id="bc.storeReceipt" />,
    component: StoreReceipt,
  },
  {
    path: "/invoice",
    exact: true,
    name: <IntlMessages id="bc.supplierInvoices" />,
    component: Invoice,
  },
  {
    path: "/viewInvoice",
    exact: true,
    name: <IntlMessages id="bc.viewInvoice" />,
    component: ViewInvoice,
  },
  {
    path: "/storeInvoice",
    exact: true,
    name: <IntlMessages id="bc.storeInvoice" />,
    component: StoreInvoice,
  },
  {
    path: "/distribute",
    exact: true,
    name: <IntlMessages id="bc.companyToStore" />,
    component: Distribute,
  },
  {
    path: "/storeToStoreCreateInvoice",
    exact: true,
    name: <IntlMessages id="bc.storeToStore" />,
    component: StoreToStoreCreateInvoice,
  },
  {
    path: "/storeInvoiceDetail",
    exact: true,
    name: <IntlMessages id="bc.storeInvoiceDetail" />,
    component: ViewStoreToStoreInvoice,
  },
  {
    path: "/viewTrustDetail",
    exact: true,
    name: <IntlMessages id="bc.viewTrustDetail" />,
    component: ViewTrustDetail,
  },
  {
    path: "/storeToStoreCredit",
    exact: true,
    name: <IntlMessages id="bc.storeToStoreCredit" />,
    component: ViewStoreToStoreCredit,
  },
  {
    path: "/viewSalaryDetail",
    exact: true,
    name: <IntlMessages id="bc.viewSalaryDetail" />,
    component: SalaryDetail,
  },
  {
    path: "/viewSalaryDetails",
    exact: true,
    name: <IntlMessages id="bc.viewSalaryDetails" />,
    component: SalaryDetails,
  },
  {
    path: "/userRoles",
    exact: true,
    name: <IntlMessages id="bc.userRoles" />,
    component: UserRoles,
  },
  {
    path: "/addUserRoles",
    exact: true,
    name: <IntlMessages id="bc.userRoles" />,
    component: AddUserRoles,
  },
  {
    path: "/manageUsers",
    exact: true,
    name: <IntlMessages id="bc.manageUsers" />,
    component: ManageUsers,
  },
  {
    path: "/trustUserAccounts",
    exact: true,
    name: <IntlMessages id="bc.trustUserAccounts" />,
    component: TrustUserAccounts,
  },
  {
    path: "/TrustUserBalanceSheetList",
    exact: true,
    name: <IntlMessages id="bc.trustUserAccounts" />,
    component: TrustUserBalanceSheetList,
  },

  {
    path: "/stockStatus",
    exact: true,
    name: <IntlMessages id="bc.stockStatus" />,
    component: StockStatus,
  },
  {
    path: "/debitNotes",
    exact: true,
    name: <IntlMessages id="bc.debitNotes" />,
    component: DebitNotes,
  },
  {
    path: "/creditNotes",
    exact: true,
    name: <IntlMessages id="bc.creditNotes" />,
    component: CreditNotes,
  },

  {
    path: "/userDeposit",
    exact: true,
    name: <IntlMessages id="bc.userDeposit" />,
    component: UserDeposit,
  },
  {
    path: "/viewTrustDetail",
    exact: true,
    name: <IntlMessages id="bc.viewTrustDetail" />,
    component: ViewTrustDetail,
  },
  {
    path: "/userWithdraw",
    exact: true,
    name: <IntlMessages id="bc.userWithdraw" />,
    component: UserWithdraw,
  },

  {
    path: "/quotations",
    exact: true,
    name: <IntlMessages id="bc.quotations" />,
    component: Quotations,
  },
  {
    path: "/quickSale",
    exact: true,
    name: <IntlMessages id="bc.quickSale" />,
    component: QuickSale,
  },
  {
    path: "/addQuickSale",
    exact: true,
    name: <IntlMessages id="bc.addQuickSale" />,
    component: AddQuickSale,
  },
  {
    path: "/editQuickSale",
    exact: true,
    name: <IntlMessages id="bc.addQuickSale" />,
    component: EditQuickSale,
  },

  // Property Management

  {
    path: "/property",
    exact: true,
    name: <IntlMessages id="bc.property" />,
    component: Property,
  },
  {
    path: "/contract",
    exact: true,
    name: <IntlMessages id="bc.contract" />,
    component: Contract,
  },

  {
    path: "/saleInvoice",
    exact: true,
    name: <IntlMessages id="bc.saleInvoice" />,
    component: SaleInvoice,
  },
  {
    path: "/saleInvoice",
    exact: true,
    name: <IntlMessages id="bc.saleInvoice" />,
    component: SaleInvoice,
  },

  {
    path: "/saleInvoice",
    exact: true,
    name: <IntlMessages id="bc.saleInvoice" />,
    component: SaleInvoice,
  },
  {
    path: "/addSaleInvoice",
    exact: true,
    name: <IntlMessages id="bc.addSaleInvoice" />,
    component: AddSaleInvoice,
  },
  {
    path: "/viewSaleInvoice",
    exact: true,
    name: <IntlMessages id="bc.viewSaleInvoice" />,
    component: ViewSaleInvoice,
  },
  {
    path: "/deliveries",
    exact: true,
    name: <IntlMessages id="bc.delivered" />,
    component: Delivered,
  },
  {
    path: "/addDelivered",
    exact: true,
    name: <IntlMessages id="bc.delivered" />,
    component: AddDelivered,
  },
  {
    path: "/viewStockStatus",
    exact: true,
    name: <IntlMessages id="bc.viewStockStatus" />,
    component: ViewStockStatus,
  },

  {
    path: "/addQuotations",
    exact: true,
    name: <IntlMessages id="bc.addQuotations" />,
    component: AddQuotations,
  },
  {
    path: "/editQuotations",
    exact: true,
    name: <IntlMessages id="bc.editQuotations" />,
    component: EditQuotation,
  },
];

export default routes;
