import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { CForm, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import _ from "lodash";
import moment from "moment";
import Select from "react-select/async";
import { selectStyle } from "../../../constants/CommonFn";
const Filter = ({ setFilter }) => {
  const dispatch = useDispatch();
  const storeProps = useSelector((state) => state.storeReducer);
  const { Stores } = storeProps;
  const all = intlPlaceholder("All");
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(<IntlMessages id="dateRequired" />),
    store: Yup.string().required(
      <IntlMessages id="SupplierProductFilter.supplier_error_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(), new Date().toISOString()], // Default value here
      store: "all",
    },
  };
  const { handleSubmit, formState, control, setValue } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    if (values.date) {
      values.startDate = moment(values.date[0]).utc();
      values.endDate = moment(values.date[1]).utc();
    } else {
      values.startDate = moment().utc();
      values.endDate = moment().utc();
    }

    let findSupplier = [{ name: "all" }];
    if (values.store !== "all") {
      findSupplier = Stores.filter((Item) => Item._id === values.store);
    }

    let filterObj;
    if (values.date) {
      filterObj = {
        store: findSupplier[0].name,
        startDate: values.startDate,
        endDate: values.endDate,
      };
    } else {
      filterObj = {
        store: findSupplier[0].name,
        startDate: moment().utc(),
        endDate: moment().utc(),
      };
    }

    setFilter(filterObj);
    dispatch(DataGetAction("getSaleList", values, "allSaleInvoicesSpinner"));
  };


  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length === 2 || inputValue.length === 0) {
      dispatch({ type: "CleanStore_SUCCESS" });
    }
    if (!inputValue.trim() || Stores.length > 0) {
      let data = Stores.filter((x) =>
        x.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const formattedOptions = data.map((option) => ({
        value: option._id,
        label: option.name,
      }));
      callback(formattedOptions);
      return;
    }
    try {
      let requestStoreId = "";
      if (
        localStorage.getItem("selectedCompanyStore") &&
        JSON.parse(localStorage.getItem("swithStoreToCompany"))
      ) {
        const obj = JSON.parse(localStorage.getItem("selectedCompanyStore"));
        if (obj && obj.value !== "null") {
          requestStoreId = obj.value;
        }
      }
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        requestStoreId: requestStoreId,
      };
      if (inputValue && inputValue.length >= 3) {
        const response = await fetch(
          `${process.env.REACT_APP_ROOT_URL}/findStore/${JSON.stringify({
            query: inputValue,
          })}`,
          { headers }
        );
        const data = await response.json();
        
        dispatch({
          type: "findStore_SUCCESS",
          payload: { Record: data.Record },
        });
        const formattedOptions = data.Record.map((option) => ({
          value: option._id,
          label: option.name,
        }));
        callback(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <CForm
      className="d-flex flex-wrap align-items-center p-3"
      onSubmit={handleSubmit(onFinish)}
    >
      <div className="me-3">
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}
              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
          )}
        />
        <div className="error-message ">{errors.date?.message}</div>
      </div>
      <div className="me-3">
        <div style={{ width: "200px" }}>
          <Controller
            control={control}
            name="store"
            render={({ field: { onChange } }) => (
              <Select
            
                styles={selectStyle}
                className={errors.store ? "is-invalid" : ""}
                isSearchable={true}
                loadOptions={loadOptions}
                placeholder={intlPlaceholder(
                  "storeProductFilter.store"
                )}
                onChange={(e) => {
                  onChange(e.value);
                }}
                
              />
            )}
          />
          <div className="error-message ">{errors.store?.message}</div>
        </div>
      </div>

      <div className={``}>
        <div className="">
          <CLoadingButton
            className="d-flex align-items-center"
            type="submit"
            color="primary"
            id="filterStoreInvoice"
          >
            <i className="gx-icon-filter-300 me-1 text-white"></i>
            <IntlMessages id="balanceSheet.generateButton" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>
  );
};

export default Filter;
