import PropTypes from 'prop-types'

export const Breakpoints = 'xs' || 'sm' || 'md' || 'lg' || 'xl' || 'xxl'

const string = ''
export const Colors =
    'primary'
    || 'secondary'
    || 'success'
    || 'danger'
    || 'warning'
    || 'info'
    || 'dark'
    || 'light'
    || string

export const colorPropType = PropTypes.oneOfType([
    PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'dark',
        'light',
    ]),
    PropTypes.string,
])

export const Gradients =
    'primary-gradient'
    || 'secondary-gradient'
    || 'success-gradient'
    || 'danger-gradient'
    || 'warning-gradient'
    || 'info-gradient'
    || 'dark-gradient'
    || 'light-gradient'

export const gradientsPropType = PropTypes.oneOf([
    'primary-gradient',
    'secondary-gradient',
    'success-gradient',
    'danger-gradient',
    'warning-gradient',
    'info-gradient',
    'dark-gradient',
    'light-gradient',
])

export const Placements =
    'auto'
    || 'auto-start'
    || 'auto-end'
    || 'top-end'
    || 'top'
    || 'top-start'
    || 'bottom-end'
    || 'bottom'
    || 'bottom-start'
    || 'right-start'
    || 'right'
    || 'right-end'
    || 'left-start'
    || 'left'
    || 'left-end'
    || undefined

export const placementPropType = PropTypes.oneOf < Placements > ([
    'auto',
    'auto-start',
    'auto-end',
    'top-end',
    'top',
    'top-start',
    'bottom-end',
    'bottom',
    'bottom-start',
    'right-start',
    'right',
    'right-end',
    'left-start',
    'left',
    'left-end',
])

export const Shapes =
    'rounded'
    || 'rounded-top'
    || 'rounded-end'
    || 'rounded-bottom'
    || 'rounded-start'
    || 'rounded-circle'
    || 'rounded-pill'
    || 'rounded-0'
    || 'rounded-1'
    || 'rounded-2'
    || 'rounded-3'
    || string

export const shapePropType = PropTypes.oneOfType([
    PropTypes.oneOf([
        'rounded',
        'rounded-top',
        'rounded-end',
        'rounded-bottom',
        'rounded-start',
        'rounded-circle',
        'rounded-pill',
        'rounded-0',
        'rounded-1',
        'rounded-2',
        'rounded-3',
    ]),
    PropTypes.string,
])

export const TextColors = Colors || 'white' || 'muted' || string

export const textColorsPropType = PropTypes.oneOfType([
    colorPropType,
    PropTypes.oneOf(['white', 'muted']),
    PropTypes.string,
])

export const Triggers = 'hover' || 'focus' || 'click'

export const triggerPropType = PropTypes.oneOf < Triggers > (['hover', 'focus', 'click'])
