import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import DocsExample from "../../components/DocsExample";
import { DataGetAction } from "../../redux/actions/http";
import StockStatusGrid from "./stock-status-grid";
import StockStatusFilter from "./stock-status-filter";
import moment from "moment";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { ChangeDateFormate, NumberWithCommas } from "src/constants/CommonFn";

const StockStatus = () => {
  const dispatch = useDispatch();
  const { LogeInUser } = useSelector((state) => state.auth);
  const comProps = useSelector((state) => state.productReducer);
  const [filterValue, setFilterValue] = useState(null);
  const [pdfSpinner, setPdfSinner] = useState(false);

  const fetchData = () => {
    if (filterValue) {
      let obj = {
        ...filterValue,
      };
      if (
        moment(filterValue.startDate).format("DD-MM-YYYY") ===
          moment(new Date()).format("DD-MM-YYYY") ||
        moment(filterValue.endDate).format("DD-MM-YYYY") ===
          moment(new Date()).format("DD-MM-YYYY")
      ) {
        obj["search"] = "searchByDate";
      }
      dispatch(DataGetAction("stockStatusSummary", obj, "spinner"));
    } else {
      let filterObj = {
        status: "available",
        search: "default",
      };
      dispatch({ type: "setFilterDateInRedux", payload: null });
      dispatch(DataGetAction("stockStatusSummary", filterObj, "spinner"));
    }
  };

  useEffect(fetchData, [filterValue]);
  let headerTitleAccountSummery = intlPlaceholder("stockStatusReport");
  let reportFrom = intlPlaceholder("report.from");
  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");
  let reportTo = intlPlaceholder("report.to");
  let headerBodyData = [
    [
      {
        text: intlPlaceholder("report.stockStatus.productName"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.orders"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.sales"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.nonDelivered"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.stock"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.stockValue"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.nonDeliveredStockValue"),
        style: "tableHeader",
      },
      {
        text: intlPlaceholder("report.stockStatus.amount"),
        style: "tableHeader",
      },
    ],
  ];
  let totalLabel = intlPlaceholder("report.stockStatus.total");
  let SpinnerStop = false;
  const downloadStockStatusPdf = async () => {
    let startDate = moment().toString();
    let endDate = moment().toString();
    if (filterValue) {
      startDate = filterValue.startDate;
      endDate = filterValue.endDate;
    }
    let Record = comProps.StockStatus;
    let bodyData = [...headerBodyData];

    let productName = "";
    let orders = 0;
    let sales = 0;
    let stock = 0;
    let stockPrice = 0;
    let nonDelivered = 0;
    let total = 0;
    let TotalAmount = 0;
    let nonDeliveredAmount = 0;
    let nonDeliveredTotal = 0;
    Record.forEach((Item) => {
      productName = { text: Item.productName, style: "tableProduct" };
      orders = Item.orders
        ? parseFloat(Item.orders).toFixed(3)
        : parseFloat(0).toFixed(3);
      sales = Item.sales
        ? parseFloat(Item.sales).toFixed(3)
        : parseFloat(0).toFixed(3);
      stock = Item.stock
        ? parseFloat(Item.stock).toFixed(3)
        : parseFloat(0).toFixed(3);
      nonDelivered = Item.nonDelivered
        ? parseFloat(Item.nonDelivered).toFixed(3)
        : parseFloat(0).toFixed(3);
      stockPrice = parseFloat(Item.stockPrice).toFixed(2);

      nonDeliveredAmount = Item.nonDStockValue ? parseFloat(Item.nonDStockValue).toFixed(2) : parseFloat(0).toFixed(2);

      total = Item.total  ? parseFloat(Item.total).toFixed(2) : parseFloat(0).toFixed(2);

      nonDeliveredTotal = parseFloat(nonDeliveredTotal) + parseFloat(nonDeliveredAmount);
  
      TotalAmount = parseFloat(TotalAmount) + parseFloat(Item.total);

      let objArr = [];
      objArr.push(productName);
      objArr.push(orders);
      objArr.push(sales);
      objArr.push(nonDelivered);
      objArr.push(stock);
      objArr.push(NumberWithCommas(stockPrice));
      objArr.push(NumberWithCommas(nonDeliveredAmount));
      objArr.push(NumberWithCommas(total));
      bodyData = [...bodyData, objArr];
    });
    TotalAmount = NumberWithCommas(TotalAmount.toFixed(2));
    nonDeliveredTotal = NumberWithCommas(nonDeliveredTotal.toFixed(2));


    let letTotalAmount = [
      {
        text: totalLabel,
        style: "tableBody",
        bold: true,
        colSpan: 6,
        alignment: "center",
      },
      {},
      {},
      {},
      {},
      {},
      { text: nonDeliveredTotal, style: "tableBody", bold: true },
      { text: TotalAmount, style: "tableBody", bold: true },
    ];
    bodyData = [...bodyData, letTotalAmount];

    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let headerTitle = `${headerTitleAccountSummery} ${reportFrom} ${ChangeDateFormate(
      startDate,
      "DD-MM-YYYY"
    )} ${reportTo} ${ChangeDateFormate(endDate, "DD-MM-YYYY")}`;
    let footerAddress = localStorage.getItem("address");
    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;

    let docDefinition = {
      pageMargins: [10, 50, 10, 45],
      pageOrientation: "landscape",
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },
        {
          text: headerTitle,
          style: { fontSize: 22, bold: true, alignment: "center" },
          margin: [15, 10],
        },
        {
          style: "tableBody",
          headerRows: 1,

          table: {
            widths: [150, "*", "*", "*", "*", "*", "*", "*"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 50, 0, 15],
          fontSize: 10,
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          alignment: "center",
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          alignment: "right",
        },
        tableProduct: {
          bold: false,
          fontSize: 10,
          alignment: "left",
        },
      },
    };

    pdfMake.createPdf(docDefinition).download("stockStatus.pdf");
  };
  if (pdfSpinner) {
    setInterval(function () {
      setPdfSinner(false);
    }, 2000);
  }
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <h1>
              <IntlMessages id="report.stockStatus" />
            </h1>
          </CCardHeader>
          <CCardBody>
            <div className="d-flex align-items-center flex-wrap">
              <StockStatusFilter
                key={"StockStatusFilter"}
                setFilter={setFilterValue}
              />
              <div className="mb-1" onClick={() => setPdfSinner(true)}>
                <CLoadingButton
                  loading={pdfSpinner}
                  color="danger"
                  onClick={() => {
                    downloadStockStatusPdf();
                  }}
                >
                  <span style={{ color: "white" }}>
                    <i className="gx-icon-pdf-300 me-1"></i>
                    <IntlMessages id="permission.pdf" />
                  </span>
                </CLoadingButton>
              </div>
            </div>
            <DocsExample href="components/smart-table/">
              <StockStatusGrid
                key={"StockStatusGrid"}
                data={comProps.StockStatus}
                spinner={comProps.spinner}
                filterValue={filterValue}
              />
            </DocsExample>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default StockStatus;
