import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { colorPropType } from './Types'



const CTableDataCell = forwardRef(
    ({ children, active, align, className, color, ...rest }, ref) => {
        const _className = classNames(
            {
                [`align-${align}`]: align,
                'table-active': active,
                [`table-${color}`]: color,
            },
            className,
        )

        return (
            <td className={_className ? _className : undefined} {...rest} ref={ref}>
                {children}
            </td>
        )
    },
)

CTableDataCell.propTypes = {
    active: PropTypes.bool,
    align: PropTypes.oneOf(['bottom', 'middle', 'top']),
    children: PropTypes.node,
    className: PropTypes.string,
    color: colorPropType,
}






export default CTableDataCell
