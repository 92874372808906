import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/http";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { CForm, CFormSelect, CLoadingButton } from "@coreui/react-pro";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DateRangePicker from "react-bootstrap-daterangepicker";
import _ from 'lodash'
import moment from 'moment'
import Select from "react-select";
import { selectStyle } from '../../constants/CommonFn'

const DebitNotesFilter = ({ setFilter }) => {
  const dispatch = useDispatch();
  const storeProps = useSelector((state) => state.storeReducer);
  const { StoresDropDownList } = storeProps;

  const  all  = intlPlaceholder("All")
  // form validation rules
  const validationSchema = Yup.object().shape({
    date: Yup.array().required(
      <IntlMessages id="dateRequired" />
    ),
    store: Yup.string().required(
      <IntlMessages id="SupplierProductFilter.supplier_error_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: [new Date().toISOString(),new Date().toISOString()], // Default value here
      store : 'all'
    },
  };
  const { register, handleSubmit, reset, formState, control, setValue } = useForm(formOptions);
  const { errors } = formState;

  const onFinish = (values) => {
    if (values.date) {
      values.startDate = moment(values.date[0]).utc();
      values.endDate = moment(values.date[1]).utc();
    } else {
      values.startDate = moment().utc();
      values.endDate = moment().utc();
    }
    
    let filterObj;
    if (values.date) {
      filterObj = {
        store: values.store,
        startDate: values.startDate,
        endDate: values.endDate
      }
    } else {
      filterObj = {
        store: values.store,
        startDate: moment().toString(),
        endDate: moment().toString()
      }
    }

    setFilter(filterObj);
  }

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
      ' - ' +
      picker.endDate.format('MM/DD/YYYY')
    );
  };
  
  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  return (

    <CForm className="d-flex flex-wrap p-3" onSubmit={handleSubmit(onFinish)}>
      <div className=" me-3">
        <Controller
          control={control}
          name="date"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <DateRangePicker
              initialSettings={{
                startDate: moment(),
                endDate: moment(),
              }}

              value={value}
              onApply={handleApply}
              onCallback={(start, end) => {
                onChange([start.toISOString(), end.toISOString()]);
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker >
          )}
        />
        <div className="error-message ">{errors.date?.message}</div>
      </div>
      <div className="me-3">
        <div style={{width : '200px'}}>      
          <Controller
              control={control}
              name="store"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                styles={selectStyle}
                  className={errors.store ? "is-invalid" : ""}
                  isSearchable={true}
                  defaultValue={{
                    value: 'all' , 
                    label: all,
                  }}
                  placeholder=  {intlPlaceholder("SupplierProductFilter.selectSupplier")}
                  onChange={(e) => {
                    onChange(e.value);
                  }}
                 
                  options={[{label :all, value : 'all'},...StoresDropDownList]}
                />
              )}
            />
          <div className="error-message ">{errors.store?.message}</div>
        </div>
      </div>

      <div className={``}>
        <div className="">
          <CLoadingButton className="d-flex align-items-center" type="submit" color="primary" id="filterDebitNotes">
            <i className="gx-icon-filter-300 me-1 text-white"></i>
            <IntlMessages id="balanceSheet.generateButton" />
          </CLoadingButton>
        </div>
      </div>
    </CForm>

  );
};

export default DebitNotesFilter;
