import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  ChangeNumberFormate } from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import ReceiptForm from "./components/ReceiptForm";
import ReceiptList from "../../components/CSSmartTable";
import _ from "lodash";
import CheckUserAccess from "../../util/checkUserAccess";
const Index = () => {
    const [details, setDetails] = useState([]);
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const history = useHistory();
  const ViewReceipt = (data) => {
    history.push({
      pathname: "/viewStoreReceipt",
      state: { ReceiptNo: data.receiptNumber },
    });
  };
  const columns = [
    {
      label: <IntlMessages id="receipt.number" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.customer" />,
      key: "customer",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.storeName" />,
      key: "store",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.amountPaid" />,
      key: "paid",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.createdBy" />,
      key: "createdBy",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: '',
      sorter : false,
      filter : false,
      key: "show_details",
      _style: { width: "40%" },
    },


  ];

  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.receiptReducer);
  const modalType = "show_receipt_modal";

  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  const fetchReceipt = () => {
    let receiptType = "companySaleToClient";
    dispatch(
      DataGetAction(
        "getAllStoreReceipt",
        { query: "all", rk: "receiptType", rv: receiptType },
        "allStoreReceiptSpinner"
      )
    );
    return () => {
      dispatch({
        type: "removeAllReceipt",
      });
    };
  };
  useEffect(fetchReceipt, [comProps.fetchReceiptInfo]);
  const create = CheckUserAccess('receipts',['create']) 
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="storeToStore.receipt.title" />
              </h1>
           
            </CCardHeader>
            <CCardBody>
              <DocsExample href="components/smart-table/">
              <div className="mb-4">
              {create&&   <ReceiptForm modalType={modalType} />}
               
              </div>
                <ReceiptList
                  loading={comProps.allStoreReceiptSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={
                    comProps.allStoreReceiptSpinner ? [] : comProps.Receipts
                  }
                  columns={columns}
                  columnFilter
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    
                    paid: (record) => {
                      return (
                        <td className="text-end">
                          <span style={{ textTransform: "capitalize" }}>
                          {ChangeNumberFormate(record.paid.toFixed(2))}
                          </span>
                        </td>
                      );
                    },
                    createdBy: (record) => {
                      return (
                        <td>
                          <span style={{ textTransform: "capitalize" }}>
                          {record.createdBy}
                          </span>
                        </td>
                      );
                    },
                    createdAt: (record) => {
                      return (
                        <td>
                          <span >
                          {record.createdAt}
                          </span>
                        </td>
                      );
                    },
                  
                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                             {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => ViewReceipt(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
