import React, { useState, useRef, useEffect } from "react";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";

import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormCheck,
} from "@coreui/react-pro";
import menuData from "../_data";
const PermissionModal = ({ Permission_Modal }) => {
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.company);
  const { permissionModalVisible, permissionData } = comProps;


  let result  = []
  menuData.map(item=>{
    if(permissionData && permissionData.permissions && permissionData.permissions.length > 0 && permissionData.permissions.includes(item.value)){
      result.push(item)
    }
  })



  // const camelCaseToNormal = (string) => {
  //   if (string) {
  //     var text = string;
  //     var result = text.replace(/([A-Z])/g, " $1");
  //     var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  //     return finalResult;
  //   }
  // };
  return (
    <>
      <CModal visible={permissionModalVisible} size="lg">
        <CModalHeader
          onDismiss={() =>
            dispatch(DataEntryModalAction(Permission_Modal, null))
          }
        >
          <CModalTitle>
            <IntlMessages id="inviteCompany.permissionDetails" />
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <span className="mb-1 fw-bold">
            <IntlMessages id="companyName" />
          </span>
          <div className="permission-heading mb-3 ">
            {permissionData &&
              permissionData.companyName &&
              permissionData.companyName}
          </div>

          <permissionData className="mb-3 fw-bold">
            <IntlMessages id="moduleDetails" />
          </permissionData>

          <div className="row mt-3">
            {result &&
              
              result.length > 0 &&
              result.map((item) => {
                return (
                  <p className="col-md-4">
                    <span className="me-1">
                      <CFormCheck defaultChecked disabled />
                    </span>
                    {intlPlaceholder(item.value)}
                  </p>
                );
              })}
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() =>
              dispatch(DataEntryModalAction(Permission_Modal, null))
            }
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default PermissionModal;
