import React from "react";
import { CCard, CCardBody, CCol, CRow, CCardHeader } from "@coreui/react-pro";
import EditSaleInvoiceForm from "./EditSaleInvoiceForm";
import { intlPlaceholder } from "src/util/intlPlaceholder";
import { Modal } from "src/components/Modals/CModal";
import { useDispatch, useSelector } from "react-redux";
const EditSaleInvoiceModal = () => {
  const dispatch = useDispatch();
  const {
    editSaleInvoiceModalVisible,
    initialValues,
    localProducts,
    editSpinner,
  } = useSelector((state) => state.saleInvoiceReducer);
  let title = intlPlaceholder("bc.saleInvoice");

  return (
    <Modal
      onDismiss={() => dispatch({ type: "editSaleInvoiceModalHide" })}
      title={`${title}`}
      visible={editSaleInvoiceModalVisible}
    >
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardBody>
              <EditSaleInvoiceForm
                _id={initialValues && initialValues._id}
                localProducts={localProducts}
                initialValues={initialValues}
                editSpinner={editSpinner}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </Modal>
  );
};

export default EditSaleInvoiceModal;
