import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../../redux/actions/http";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { notify } from "src/util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../../constants/CommonFn";
const AddSalary = ({ modalType, setSelectedRows, selectedRows }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const comProps = useSelector((state) => state.esReducer);
  const { PostSalary, ShowEmployeeSalaryModal, postingSalary } = comProps;
  const emProps = useSelector((state) => state.employee);
  const all = intlPlaceholder("All");


  const pleaseSelectAtLeastOneEmployee = intlPlaceholder(
    "pleaseSelectAtLeastOneEmployee"
  );

  useEffect(() => {
    setTableData(PostSalary);
  }, [PostSalary, ShowEmployeeSalaryModal]);

  const postTheSalary = () => {
    if (selectedRows && selectedRows.length > 0) {
      const newSelectedArray = [];
      tableData.map((Item) => {
        selectedRows.map((x) => {
          if (x._id === Item._id) {
            newSelectedArray.push(Item);
          }
          return x;
        });
        return Item;
      });

      

      if (selectedRows.length > 0) {
        dispatch(
          DataRequestAction(
            "POST",
            "postSalaryToEmployee",
            newSelectedArray,
            "",
            "postingSalary",
            false
          )
        );
      }
    } else {
      notify("error", pleaseSelectAtLeastOneEmployee);
    }
  };

  const columns = [
    {
      dataField: "name",
      editable: false,
      text: <IntlMessages id="es.name" />,
      formatter: (cell, record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>{record.name}</span>
        );
      },
      footer: (columnData) => {
        return <h5></h5>;
      },
    },
    {
      dataField: "storeName",
      editable: false,
      text: <IntlMessages id="es.source" />,
      formatter: (cell, record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record.storeName === 'cashCaisse' ? <IntlMessages id={`${record.storeName}.bankName`} /> : record.storeName}
          </span>
        );
      },
      footer: (columnData) => {
        return (
          <h5>
            {" "}
            <IntlMessages id="es.totalExpense" />
          </h5>
        );
      },
    },
    {
      dataField: "balance",
      editable: false,
      text: <IntlMessages id="es.balance" />,
      formatter: (cell, record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {ChangeNumberFormate(parseFloat(record.balance).toFixed(2)) || 0}
          </span>
        );
      },
      footer: (columnData) => {
        return <h5></h5>;
      },
    },
    {
      dataField: "monthlySalary",
      editable: false,
      text: <IntlMessages id="es.monthlySalary" />,
      formatter: (cell, record) => {
        return (
          <span>
            {ChangeNumberFormate(parseFloat(record.monthlySalary).toFixed(2))}
          </span>
        );
      },
      footer: (columnData) => {
        let result = columnData.reduce(
          (acc, item) => parseInt(acc) + parseInt(item),
          0
        );

        return <h5>GNF {ChangeNumberFormate(result.toFixed(2)) || 0}</h5>;
      },
    },
    {
      dataField: "bonus",
      editable: true,
      text: <IntlMessages id="es.bonus" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
      classes: () => {
        return "selectable-cell";
      },
      footer: (columnData) => {
        return <h5></h5>;
      },
    },
    {
      dataField: "notes",
      editable: true,
      text: <IntlMessages id="es.notes" />,
      formatter: (cell) => {
        return <span>{cell}</span>;
      },
      classes: () => {
        return "selectable-cell";
      },
      footer: (columnData) => {
        return <h5></h5>;
      },
    },
  ];

  const fetchEmployee = () => {
    dispatch(DataGetAction("getAllEmployee", { query: "all" }));
  };
  useEffect(fetchEmployee, []);

  const { Employees } = emProps;

  const handleChange = (e) => {
    dispatch({
      type: "filterTheSalaryEmployeeArray",
      payload: e,
    });
  };

  const setRows = (row) =>{
    let prevSelectedRows = [...selectedRows]
    let index = prevSelectedRows.findIndex((item) => row._id === item._id);
    if(index > -1){
      //item found
      let alreadyExistedRow = prevSelectedRows[index]
      let updatedUpdatedRows = prevSelectedRows.filter(item=>item._id !== alreadyExistedRow._id)
      setSelectedRows(updatedUpdatedRows)
    }
    else{
      //item not found
      setSelectedRows([...selectedRows , row])
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToEdit: true,
    onSelect: (row) => {

      setRows(row)

    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedRows(rows);
      } else {
        setSelectedRows([]);
      }
    },
  };
  const renderOptions = (Employee) => {
    if (Employee && Employee.length > 0) {
      return Employee.map((data) => {
        if (data.value) {
          return {
            label: "All",
            value: "all",
          };
        } else {
          return {
            label: `${data.firstName} ${data.lastName ? data.lastName : ""} (${
              data.empId
            })`,
            value: data._id,
          };
        }
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <CModal size="xl" visible={ShowEmployeeSalaryModal}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            <IntlMessages id="es.postSalary" />
          </CModalTitle>

          <div style={{ width: "220px", marginLeft: "20px" }}>
            <Select
              styles={selectStyle}
              isSearchable={true}
              defaultValue={{
                value: "all",
                label: all,
              }}
              placeholder={intlPlaceholder("es.filter.searchEmployee")}
              onChange={(e) => {
                handleChange(e.value);
              }}
              options={renderOptions([
                { value: all, label: "All" },
                ...Employees,
              ])}
            />
          </div>
        </CModalHeader>

        <CModalBody>
          <div className="ps-3">
            <BootstrapTable
              keyField="_id"
              striped
              hover
              bordered={false}
              data={tableData}
              columns={columns}
              selectRow={selectRow}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                beforeSaveCell: (oldValue, newValue, row, column) => {
                  let changedField = column.dataField;
                  let newData =
                    tableData &&
                    tableData.length > 0 &&
                    tableData.map((item) => {
                      if (item._id === row._id) {
                        item[changedField] = newValue;
                        return { ...item };
                      } else {
                        return { ...item };
                      }
                    });
                  setTableData(newData);
                },
              })}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CLoadingButton
            onClick={() => {
              postTheSalary();
            }}
            color="primary"
            loading={postingSalary}
            disabled={postingSalary}
          >
            <IntlMessages id="es.postSalary" />
          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AddSalary;
