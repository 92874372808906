import React, { useEffect, useRef, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CCardHeader,
  CCollapse,
  CBadge
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  conversionInLanguage
} from "src/constants/CommonFn";
import { DataGetAction } from "../../redux/actions/http";
import DocsExample from "../../components/DocsExample";
import IntlMessages from "../../util/IntlMessages";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import ReceiptForm from "./components/ReceiptForm";
import ReceiptList from "../../components/CSSmartTable";
import Filter from "./components/Filter";
import pdfImage from "../../assets/images/avatars/pdf.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CheckUserAccess from "../../util/checkUserAccess";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;
const Index = () => {
  const [details, setDetails] = useState([]);
  const [staticFilterValue, setStaticFilterValue] = useState({ payableStatus: 'approved' });

  const { locale } = useSelector((state) => state.Setting);

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const history = useHistory();
  const [ReceiptNumber, setReceiptNumber] = useState();
  const dispatch = useDispatch();
  const comProps = useSelector((state) => state.receiptReducer);
  const supplierProps = useSelector((state) => state.supplier);
  const authProps = useSelector((state) => state.auth);
  const { LogeInUser } = authProps;

  const { SupplierCreatedByList, SupplierBankList } = comProps;
  const modalType = "show_receipt_modal";

  const { Suppliers } = supplierProps;

  const [filterValue, setFilterValue] = useState(null);
  let ConstHeader = intlPlaceholder("report.supplier.receipt");
  let constHeaderTo = intlPlaceholder("report.to");
  let constHeaderFrom = intlPlaceholder("report.from");

  let EmailLabel = intlPlaceholder("Email");
  let CellLabel = intlPlaceholder("Cell");

  let bodyData = [
    [
      { text: intlPlaceholder("Date"), style: "tableHeader" },
      { text: intlPlaceholder("RefNumber"), style: "tableHeader" },
      { text: intlPlaceholder("supplierName"), style: "tableHeader" },
      { text: intlPlaceholder("amount"), style: "tableHeader" },
      { text: intlPlaceholder("account"), style: "tableHeader" },
      { text: intlPlaceholder("paymentMethod"), style: "tableHeader" },
      { text: intlPlaceholder("checkDate"), style: "tableHeader" },
    ],
  ];

  const ViewReceipt = (data) => {
    history.push({
      pathname: "/duesReceipt",
      state: { ReceiptNo: data.receiptNo },
    });
  };




  const downloadPDF = async (data) => {
    let Record = data;

    Record.forEach((Item, index) => {
      if (Item.payableStatus === staticFilterValue.payableStatus || staticFilterValue.payableStatus === '') {
        let bankName = "Cash";
        let PaymentMethod = "Cash";

        if (Item.paymentMode.mode !== "cash") {
          bankName = Item.paymentMode.bank.name;

          const lower = Item.paymentMode.transactionType.toLowerCase();
          let str =
            Item.paymentMode.transactionType.charAt(0).toUpperCase() +
            lower.slice(1);
          PaymentMethod = str;
        }

        let checkDate = "---";
        if (Item.paymentMode.mode === "bank") {
          checkDate = ChangeDateFormate(
            Item.paymentMode.chequeDate,
            "YYYY-MM-DD"
          );
        }

        let date = ChangeDateFormate(Item.createdAt, "YYYY-MM-DD");

        let amount = Item.paid.toFixed(2);

        amount = Item.currency[0].symbol + NumberWithCommas(amount);
        let objArr = [date];

        let paymentModeLng = conversionInLanguage(locale.locale, `${Item.mode}.paymentMethod`)
        let bankNameLng = Item.bankName === 'cashCaisse' ? conversionInLanguage(locale.locale, `${Item.bankName}.bankName`) : bankName
        bankNameLng = bankNameLng + "-" + Item.currencyName
        objArr.push(`${Item.prefix}-${Item.receiptNo}/${Item.referenceNo}`);
        objArr.push(Item.supplier);
        objArr.push(amount);
        objArr.push(bankNameLng);
        objArr.push(paymentModeLng);
        objArr.push(checkDate);

        bodyData = [...bodyData, objArr];
      }
    });




    let startDate = moment().startOf("year").toString();
    startDate = ChangeDateFormate(startDate, "YYYY-MM-DD");
    let endDate = moment().toString();
    endDate = ChangeDateFormate(endDate, "YYYY-MM-DD");

    if (filterValue.startDate && filterValue.endDate) {
      startDate = ChangeDateFormate(filterValue.startDate, "YYYY-MM-DD");
      endDate = ChangeDateFormate(filterValue.endDate, "YYYY-MM-DD");
    }

    let headerTitle =
      ConstHeader.toUpperCase() +
      " " +
      constHeaderFrom +
      " " +
      startDate +
      " " +
      constHeaderTo +
      " " +
      endDate;

    const logoImage = localStorage.getItem("image")
      ? localStorage.getItem("image")
      : process.env.REACT_APP_LOGO_DUMMY;

    let footerAddress = localStorage.getItem("address");

    let footerAddress2 = `${EmailLabel} : ${LogeInUser.email}  - ${CellLabel}.: +${LogeInUser.phoneNumber}`;
    let docDefinition = {
      content: [
        {
          image: `data:image/jpeg;base64,${logoImage}`,
          fit: [250, 250],
          width: 150,
          height: 150,
        },

        {
          text: headerTitle,
          margin: [15, 10],
          style: { fontSize: 12, bold: true, alignment: "center" },
        },
        {
          style: "tableExample",
          headerRows: 1,

          table: {
            widths: ["auto", 50, 80, 80, 80, 50, "auto"],
            body: bodyData,
          },
        },
      ],
      footer: {
        text: [
          {
            text: footerAddress,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
          {
            text: `\n ${footerAddress2}`,
            alignment: "center",
            fontSize: 10,
            width: "100%",
          },
        ],
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
          width: "80%",
        },
        tableOpacityExample: {
          margin: [0, 5, 0, 15],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(docDefinition).download("supplierReceipt.pdf");
  };

  const columns = [
    {
      label: <IntlMessages id="receipt.referenceNo" />,
      key: "refNo",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.supplier" />,
      key: "supplier",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.amountPaid" />,
      key: "paid",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.paymentMode" />,
      key: "mode",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="receipt.chequeDate" />,
      key: "chequeDate",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="receipt.bank" />,
      key: "bankName",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="invoice.createdAt" />,
      key: "createdAt",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoice.status" />,
      key: "payableStatus",
      filterType: 'dropDown',
      filterDropDownValue: [{ label: intlPlaceholder('invoice.all'), value: 'all' }, { label: intlPlaceholder('invoice.approved'), value: 'approved', selected: true }, { label: intlPlaceholder('invoice.cancelled'), value: 'cancelled' }],
      _style: { width: "40%" },
    },

    {
      label: "",
      filter: false,
      sorter: false,
      key: "show_details",
      _style: { width: "40%" },
    },
  ];


  const create = CheckUserAccess("supplierReceipts", ["create"])
  const pdf = CheckUserAccess("supplierReceipts", ["pdf"])
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="receipt.supplierReceipt.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex align-items-center">
                <Filter setFilter={setFilterValue} />
                <div className="d-flex mb-1">
                  {create && (
                    <ReceiptForm modalType={modalType} />
                  )}



                  {pdf && (
                    <CButton
                      color="danger"
                      onClick={() => downloadPDF(comProps.SupplierReceipts)}
                      className="ms-3"
                    >
                      <span style={{ color: "white" }}>
                        <i className="gx-icon-pdf-300 me-1"></i>
                        <IntlMessages id="pdf" />
                      </span>
                    </CButton>
                  )}
                </div>
              </div>
              <DocsExample href="components/smart-table/">
                <ReceiptList
                  loading={comProps.supplierReceiptSpinner}
                  sorterValue={{ column: "name", state: "asc" }}
                  clickableRows
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  activePage={3}
                  items={
                    comProps.supplierReceiptSpinner
                      ? []
                      : comProps.SupplierReceipts
                  }
                  columns={columns}
                  filterValueChangeTo={(value) => {
                    if (value.payableStatus) {
                      setStaticFilterValue(value)
                    }
                  }}
                  columnFilter
                  columnFilterValue={staticFilterValue}
                  tableFilter
                  cleaner
                  itemsPerPageSelect
                  itemsPerPage={100}
                  columnSorter
                  pagination
                  scopedColumns={{
                    payableStatus: (record) => {
                      return (
                        <td className="text-start">
                          <CBadge style={{
                            textTransform: "capitalize",
                          }} color={record.payableStatus === 'approved' ? 'success' : 'danger'}> {intlPlaceholder(`invoice.${record.payableStatus}`)}</CBadge>
                        </td>
                      );
                    },
                    paid: (record) => {
                      return (
                        <td className="text-end">
                          <span>
                            {ChangeNumberFormate(record.paid.toFixed(2))}
                          </span>
                        </td>
                      );
                    },
                    mode: (record) => {
                      return (
                        <td>
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {<IntlMessages id={`${record.mode}.paymentMethod`} />}
                          </span>
                        </td>
                      );
                    },
                    chequeDate: (record) => {
                      return (
                        <td>
                          <span
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {record.paymentMode.mode === "bank"
                              ? record.paymentMode.transactionType === "cheque"
                                ? ChangeDateFormate(
                                  record.paymentMode.chequeDate,
                                  "YYYY-MM-DD"
                                )
                                : "---"
                              : "---"}
                          </span>
                        </td>
                      );
                    },

                    bankName: (record) => {
                      return <td>{record.bankName === 'cashCaisse' ? <IntlMessages id={`${record.bankName}.bankName`} /> : record.bankName} - {record.currencyName}</td>;
                    },
                    createdAt: (record) => {
                      return <td>{record.createdAt}</td>;
                    },

                    show_details: (record) => {
                      return (
                        <td className="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            shape="square"
                            size="sm"
                            onClick={() => {
                              toggleDetails(record._id);
                            }}
                          >
                            {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                          </CButton>
                        </td>
                      );
                    },
                    details: (record) => {
                      return (
                        <CCollapse visible={details.includes(record._id)}>
                          <CCardBody>
                            <CButton
                              size="sm"
                              color="warning"
                              className="me-3"
                              onClick={() => ViewReceipt(record)}
                            >
                              <span style={{ color: "white" }}>
                                <span className="icon icon-custom-view me-2"></span>
                                <IntlMessages id="view" />
                              </span>
                            </CButton>
                          </CCardBody>
                        </CCollapse>
                      );
                    },
                  }}
                  summary={pageData => {
                    let total = 0;


                    pageData.forEach(({ paid, refundReceiptRefNo }) => {
                      if (refundReceiptRefNo) {
                        total -= paid;
                      }
                      else {
                        total += paid;
                      }

                    });

                    return (
                      <tr>
                        <th colSpan={1} className="text-center"><h5><IntlMessages id='Total' /></h5></th>

                        <th colSpan={2} className="text-end">
                          <span><h5>{ChangeNumberFormate(total.toFixed(2))}</h5></span>
                        </th>
                        <th colSpan={5}>
                          <span></span>
                        </th>
                      </tr>

                    );
                  }}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Index;
