import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
const PropertyTable = ({ Properties, propertySpinner }) => {
  const [details, setDetails] = useState([]);

  let columns = [
    {
      label: <IntlMessages id="property.name" />,
      key: "propertyName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="property.blocks" />,
      key: "block",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="property.totalUnits" />,
      key: "totalUnits",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="property.available" />,
      key: "availableUnitsCount",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="property.address" />,
      key: "address",
      _style: { width: "40%" },
    },

    
  ];

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "desc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={propertySpinner}
        items={propertySpinner ? [] : Properties && Properties.length > 0 ? Properties : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                  {details.includes(record._id) ? <IntlMessages id="hide" /> : <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody></CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </>
  );
};

export default PropertyTable;
