import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'react-phone-input-2/lib/style.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { icons } from './assets/icons'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import store, { history, persisStore } from './store';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";

React.icons = icons


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persisStore}>
      <ConnectedRouter history={history}>
      <BrowserRouter>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
          </BrowserRouter>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
