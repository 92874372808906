import React , {useState} from "react";
import UnitList from "../../../components/CSSmartTable";
import IntlMessages from "../../../util/IntlMessages";
import {intlPlaceholder} from "../../../util/intlPlaceholder";

import { useDispatch,useSelector } from "react-redux";
import { ChangeNumberFormate } from "src/constants/CommonFn";
import {

    CCardBody,
    CButton,
    CCollapse,
  } from "@coreui/react-pro";
  import BootstrapTable from "react-bootstrap-table-next";
  import cellEditFactory from "react-bootstrap-table2-editor";
const DistributeProductList = ({ showAction = true,setValue,reset,setDisableSaveBtn }) => {
    
    const [details, setDetails] = useState([]);
    const toggleDetails = (index) => {
      const position = details.indexOf(index);
      let newDetails = details.slice();
      if (position !== -1) {
        newDetails.splice(position, 1);
      } else {
        newDetails = [...details, index];
      }
      setDetails(newDetails);
    };
    const prodProps = useSelector(state => state.storeToStoreReducer)
    const dispatch = useDispatch();

    const deleteRecord = (record) => {

        dispatch({
            type: 'deleteLocalProductStoreToStore',
            payload: record
        })
    }
    const { ProductsLocal ,localProducts} = prodProps;

    let priceShouldBeNumeric =  intlPlaceholder("priceShouldBeNumeric")
    let priceShouldBeBiggerThan1 =  intlPlaceholder("priceShouldBeBiggerThan1")
   

    const columns = [

        {
            label: <IntlMessages id="product.name" />,
            key: "productName",
            _style: { width: "40%" },
          },
        {
            label: <IntlMessages id="product.unit" />,
            key: "unitName",
            _style: { width: "40%" },
          },
        {
            label: <IntlMessages id="product.quantity" />,
            key: "quantity",
            _style: { width: "40%" },
          },
        {
            label:<IntlMessages id="product.UnitPrice" />,
            key: "UnitPrice",
            _style: { width: "40%" },
          },
        {
            label:<IntlMessages id="product.TotalPriceStore" />,
            key: "TotalPrice",
            _style: { width: "40%" },
          },
       




    ];

    if (showAction) {
        columns.push( {
            label: <IntlMessages id="product.action" />,
            key: "show_details",
            _style: { width: "40%" },
          })
    }

    const renderTotalPrice = ()=>{
      let total = 0
    
      localProducts && localProducts.length > 0 &&  localProducts.forEach(item => {
        total = item.totalPrice + total
      })
      total = ChangeNumberFormate(total.toFixed(2))
      return total
    }


    let columnsNew = [
      {
        dataField: "productName",
        editable: false,
        text:<IntlMessages id="product.name" />,
        formatter: (cell) => {
          return <span>{cell}</span>;
        },
       
      },
      {
        dataField: "unitName",
        text: <IntlMessages id="product.unit" />,
        editable: false,
        formatter: (cell) => {
          return <span>{cell}</span>;
        },
        
      },
      {
        dataField: "qty",
        text: <IntlMessages id="product.quantity" />,
        editable: true,
        formatter: (cell) => {
         
          return <span>{parseFloat(cell).toFixed(2)}</span>;
        },
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: 'Price should be numeric'
            };
          }
          else{
            return true
          }
          
        },
        classes: () => {
          return "selectable-cell";
        },
       
      },
      // {
      //   dataField: "quantity",
      //   text: <IntlMessages id="product.stockQuantity" />,
      //   editable: false,
      //   formatter: (cell) => {
      //     return <span>{cell}</span>;
      //   },
        
      // },

      {
        dataField: "price",
        text: <IntlMessages id="product.creditPrice" />,
        editable: true,
        formatter: (cell) => {
          
          return <span> {ChangeNumberFormate(parseFloat(cell).toFixed(2))}</span>;
        },
        classes: () => {
          return "selectable-cell";
        },
        
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            setDisableSaveBtn(true)
            return {
              valid: false,
              message: priceShouldBeNumeric,
            };
          }
          if (newValue < 1) {
            setDisableSaveBtn(true)
            return {
              valid: false,
              message: priceShouldBeBiggerThan1,
            };
          }
          else{
            setDisableSaveBtn(false)
            return {
              valid: true
            };
          }
          
        },
      },
      {
        dataField: "localPrice",
        text:<IntlMessages id="product.debitPrice" />,
        editable: true,
        formatter: (cell,record) => {
         
          return <span> {ChangeNumberFormate(
            (parseFloat(cell)).toFixed(2)
          )}</span>;
        },
        classes: () => {
          return "selectable-cell";
        },
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            setDisableSaveBtn(true)
            return {
              valid: false,
              message: priceShouldBeNumeric,
            };
          }
          if (newValue < 1) {
            setDisableSaveBtn(true)
            return {
              valid: false,
              message: priceShouldBeBiggerThan1,
            };
          }
          else{
            setDisableSaveBtn(false)
            return {
              valid: true
            };
          }
          
        },
  
      },
      {
        dataField: "totalPrice",
        text:<IntlMessages id="product.TotalPriceStore" />,
        editable: false,
        formatter: (cell,record) => {
          
          return <span> {ChangeNumberFormate(
            (parseFloat(cell)).toFixed(2)
          )}</span>;
        },
      },
      
      {
        dataField: "action",
        editable: false,
        text: <IntlMessages id="product.action" />,
        formatter: (cell, record) => {
          return (
            <span
              style={{
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => deleteRecord(record)}
            >
              <span className="icon icon-trash"></span>
            </span>
          );
        },
       
      },
    ];
  return (
    <>
     <BootstrapTable
        keyField="_id"
        striped
        data={localProducts}
        columns={columnsNew}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,

          beforeSaveCell: (oldValue, newValue, row, column) => {
              let changedField = column.dataField;
              dispatch({
                type : 'updateStoreToStoreProduct',
                payload : {
                  fieldChanged : changedField,
                  updatedRow : row,
                  newValue : newValue
                }
              })
          },
        })}
      />
<table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tr>
          <td colspan="5" style={{ textAlign: 'center' }}>
            <h5><IntlMessages id="product.total" /></h5>
          </td>

       
          <td colspan="3" style={{ textAlign: 'center' }}>
            <h5>{renderTotalPrice()}</h5>
          </td>
        </tr>

      </table>
    </>
  );
};

export default DistributeProductList;
