import React, { useState } from "react";
import { CButton, CCardBody, CCollapse } from "@coreui/react-pro";
import CSmartTable from "src/components/CSSmartTable"
import IntlMessages from "../../../../util/IntlMessages";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { useHistory } from "react-router-dom";
import UserRolePermissionModal from "./UserRolePermissionModal";
import { useSelector } from "react-redux";
import { notify } from "../../../../util/ShowToast";
import CheckUserAccess from "../../../../util/checkUserAccess";
import { intlPlaceholder } from "src/util/intlPlaceholder";
const UserRoleList = ({ Data, Spinner }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const history = useHistory();
  const userProps = useSelector((state) => state.users);
  const edit = CheckUserAccess('userRoles',['edit']) 
  const deleted = CheckUserAccess('userRoles',['delete']) 

  const { userPermissionModalVisible, userRoleData, message, error, success } =
    userProps;
  const columns = [
    {
      label: <IntlMessages id="userRoles.role" />,
      key: "name",
      _style: { width: "40%" },
    },

    {
      label: <IntlMessages id="userRoles.noOfUsers" />,
      key: "totalNoOfUser",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="userRoles.permissions" />,
      key: "permissionAccess",
      _style: { width: "40%" },
    },
    
  ];

  const found =CheckUserAccess('userRoles',['edit','delete']) 

  if(found){
    columns.push({
      key: "actions",
      label: "",
      _style: { width: "40%" },
      filter: false,
      sorter: false,
    })
  }

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  if (error) {
    let messageLng = intlPlaceholder(message)
    notify("error", messageLng);
    dispatch({
      type: "user_UPDATE",
    });
  }
  if (success) {
    let messageLng = intlPlaceholder(message)
    notify("success", messageLng);
    dispatch({
      type: "user_UPDATE",
    });
  }

  return (
    <>
      <CSmartTable
        sorterValue={{ column: "name", state: "asc" }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
        }}
        activePage={3}
        loading={Spinner}
        items={Spinner ? [] : Data && Data.length > 0 ? Data : []}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={100}
        columnSorter
        pagination
        scopedColumns={{
          permissionAccess: (record) => {
            return (
              <td className="py-2">
                <span
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    // dispatch({
                    //   type: 'User_Permission_Modal',
                    //   payload: record
                    // });
                    history.push({
                      pathname: "addUserRoles",
                      state: {
                        data: record,
                        action: "onlyView",
                      },
                    });
                  }}
                >
                  view
                </span>
              </td>
            );
          },
          actions: (record) => {
            return (
              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(record._id);
                  }}
                >
                   {details.includes(record._id) ?  <IntlMessages id="hide" /> :  <IntlMessages id="show" />}
                </CButton>
              </td>
            );
          },
          details: (record) => {
            return (
              <CCollapse visible={details.includes(record._id)}>
                <CCardBody>
                 {edit && <CButton
                    size="sm"
                    color="warning"
                    className="me-3"
                    onClick={() =>
                      history.push({
                        pathname: "addUserRoles",
                        state: { data: record },
                      })
                    }
                  >
                    <span style={{ color: "white" }}>
                      <span className="icon icon-edit me-2"></span>
                      <IntlMessages id="Edit" />
                    </span>
                  </CButton>}

                 {deleted && <DeleteModal
                    contentLangueId="userRole.deleteModal.message"
                    type="deleteRole"
                    selectedItem={record}
                    ButtonTitleId="Delete"
                    titleLanguageId="Delete"
                    size="sm"
                  />}
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />

      {userPermissionModalVisible && <UserRolePermissionModal />}
    </>
  );
};

export default UserRoleList;
