import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormFloating,
  CFormLabel,
  CForm,
  CFormInput,
  CLoadingButton,
  CMultiSelect,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { notify } from "../../../util/ShowToast";
import menuData from "../_data";
const AddCompanyModal = () => {
  const dispatch = useDispatch();

  const comProps = useSelector((state) => state.company);
  const { editModalVisible, selectedItem, success, companyLoader ,recordEditSuccess} = comProps;

  const modalType = "edit_company_modal";

  const name_message = intlPlaceholder("companyModal.name_message");
  const localCurrencySymbol_message = intlPlaceholder(
    "companyModal.localCurrencySymbol_message"
  );
  const factor_message = intlPlaceholder("companyModal.factor_message");
  const firstNameRequired = intlPlaceholder("firstNameRequired");
  const lastNameRequired = intlPlaceholder("lastNameRequired");
  const companyNameRequired = intlPlaceholder("companyNameRequired");
  const emailRequired = intlPlaceholder("emailRequired");
  const phoneNumberRequired = intlPlaceholder("phoneNumberRequired");

  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(firstNameRequired),
    lastName: Yup.string().required(lastNameRequired),
    companyName: Yup.string().required(companyNameRequired),
    email: Yup.string().required(emailRequired).email(),
    phoneNumber: Yup.string().required(phoneNumberRequired),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (selectedItem) {
      reset(selectedItem);
    }
  }, [editModalVisible]);







  
  // useEffect(() => {
  //   if (selectedItem && selectedItem.permissions) {
  //     let data = []
  //      data =  menuData && menuData.map((item,index)=>{
  //       if(selectedItem.permissions[index] && selectedItem.permissions[index].value === item.value){
  //         return {
  //           ...item,
  //           selected : true
  //         }
  //       }
  //       else{
  //         return item
  //       }
  //     })
  //     setMenuData(data)

  //   }

  //   reset(selectedItem);

  // }, [editModalVisible]);

  const onFinish = (values) => {
    let data = [];
    values.permissions.map((item) => {
      let result = menuData.filter((x) => x.value === item);
      data = [...data, item, ...result[0].subMenu];
    });

    values.permissions = data;

    let newSetPermission = [...data, "resetPassword", "dashboard", "profile"];
    values["permissions"] = newSetPermission;

    let obj = {
      ...values,
      permissions: newSetPermission
    }
  
    if (selectedItem) {
      dispatch(
        DataRequestAction("POST", "editCompany", obj, "", "company", false)
      );
    }
  };

  if (success) {
    reset();
    notify("success", intlPlaceholder("recordAddeddSuccessfully"));
    dispatch(updateAllState("company"));
  }

  const renderOptions = () => {
    if (selectedItem?.permissions && selectedItem?.permissions?.length > 0) {
      let data = [];
      data =
        menuData &&
        menuData.map((item, index) => {
          if (selectedItem.permissions.includes(item.value)) {
            return {
              ...item,
              selected: true,
              text: intlPlaceholder(item.text),
            };
          } else {
            return {
              ...item,
              text: intlPlaceholder(item.text),
            };
          }
        });
      return data;
    } else {
      if (menuData && menuData.length > 0) {
        let data = menuData.map((data) => ({
          text: intlPlaceholder(data.text),
          value: data.value,
        }));

        return data;
      } else return [];
    }
  };
  return (
    <div>
      <CModal visible={editModalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>Invite Company</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onFinish)}>
          <CModalBody>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.firstName ? "is-invalid" : ""}
                  {...register("firstName")}
                  placeholder={intlPlaceholder("companyModal.firstName")}
                  type="text"
                  id="firstName"
                />
                <CFormLabel htmlFor="firstName">
                  {intlPlaceholder("companyModal.firstName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </CFormFloating>

              {/* <CFormFeedback valid={false}>Looks good!</CFormFeedback> */}
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.lastName ? "is-invalid" : ""}
                  {...register("lastName")}
                  placeholder={intlPlaceholder("companyModal.lastName")}
                  type="text"
                  id="lastName"
                />
                <CFormLabel htmlFor="lastName">
                  {intlPlaceholder("companyModal.lastName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </CFormFloating>
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.companyName ? "is-invalid" : ""}
                  {...register("companyName")}
                  placeholder={intlPlaceholder("companyModal.companyName")}
                  type="text"
                  id="companyName"
                />
                <CFormLabel htmlFor="companyName">
                  {intlPlaceholder("companyModal.companyName")}
                </CFormLabel>
                <div className="invalid-feedback">
                  {errors.companyName?.message}
                </div>
              </CFormFloating>
            </div>
            <div className="mb-3">
              <CFormFloating className="mb-3">
                <CFormInput
                  className={errors.email ? "is-invalid" : ""}
                  {...register("email")}
                  placeholder={intlPlaceholder("companyModal.email")}
                  type="text"
                  id="email"
                />
                <CFormLabel htmlFor="email">
                  {intlPlaceholder("companyModal.email")}
                </CFormLabel>
                <div className="invalid-feedback">{errors.email?.message}</div>
              </CFormFloating>
            </div>

            <Controller
              control={control}
              // defaultValues={selectedItem && selectedItem.phoneNumber}
              name={"phoneNumber"}
              placeholder={intlPlaceholder(
                "employee.addEmployeeModal.phoneNumber"
              )}
              render={(props) => {
                return (
                  <PhoneInput
                    country={"gn"}
                    value={selectedItem && selectedItem.phoneNumber}
                    onChange={(v) => props.field.onChange(v)}
                    placeholder={intlPlaceholder(
                      "employee.addEmployeeModal.phoneNumber"
                    )}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                  />
                );
              }}
            />
            <div className="error-message">{errors.phoneNumber?.message}</div>

            <div style={{ width: "100%" }} className="mt-3">
              <Controller
                control={control}
                name="permissions"
                defaultValue={selectedItem && selectedItem.permissions}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <>
                    <CMultiSelect
                      className={errors.permissions ? "is-invalid" : ""}
                      value={selectedItem && selectedItem.permissions}
                      onChange={(value) => {
                        let updatedValue =
                          value && value.map((item) => item.value);
                        onChange(updatedValue);
                      }}
                      placeholder={intlPlaceholder("inviteCompany.permissions")}
                      optionsStyle="text"
                      options={renderOptions}
                      multiple={true}
                    />
                  </>
                )}
              />

              <div className="invalid-feedback">
                {errors.permissions?.message}
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="companyModal.close" />
            </CButton>

            <CLoadingButton
              type="submit"
              color="primary"
              loading={companyLoader}
              disabled={companyLoader}
            >
              <IntlMessages id="Update" />
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default AddCompanyModal;
