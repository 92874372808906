import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilCalendar,
  cilChartPie,
  cilCursor,
  cilDrop,
  cilEnvelopeOpen,
  cilGrid,
  cilLayers,
  cilMap,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilBuilding,
  cilSpreadsheet,
  cilStar,
  cilNoteAdd,
  cilList,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = {
  mainSuperAdmin: [
    {
      component: CNavItem,
      name: "dashboard",
      to: "/dashboard",
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "company",
      to: "/company",
      icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    },
  ],
  superAdmin: [
    {
      component: CNavItem,
      name: "dashboard",
      to: "/dashboard",
      customIcon: "dashboard",
      permissionKey: "dashboard",
      group: "home",
    },

    {
      component: CNavItem,
      name: "saleInvoice",
      to: "/saleInvoice",
      customIcon: "invoice",
      permissionKey: "saleInvoice",
      group: "home",
    },
    {
      component: CNavItem,
      name: "deliveries",
      to: "/deliveries",
      customIcon: "invoice",
      permissionKey: "delivered",
      group: "home",
    },
    {
      component: CNavItem,
      name: "stock.entry",
      to: "/stock",
      customIcon: "stocks",
      permissionKey: "stockEntry",
      group: "home",
    },

    {
      component: CNavItem,
      name: "quickSale",
      to: "/quickSale",
      customIcon: "stocks",
      permissionKey: "quickSale",
      group: "home",
    },
    {
      component: CNavGroup,
      name: "stockTransfer",
      to: "/stockTransfer",
      customIcon: "stock-market",
      permissionKey: "stockTransfer",
      items: [
        // {
        //   component: CNavItem,
        //   name: 'Company.to.store',
        //   to: '/distribute',
        //   permissionKey : 'companyToStore',
        //   group : "home"
        // },
        {
          component: CNavItem,
          name: "Store.to.store",
          to: "/storeToStoreCreateInvoice",
          permissionKey: "storeToStore",
          group: "home",
        },
        {
          component: CNavItem,
          name: "stockTransfer.debitNotes",
          to: "/debitNotes",
          permissionKey: "debitNotes",
          group: "home",
        },
        {
          component: CNavItem,
          name: "stockTransfer.creditNotes",
          to: "/creditNotes",
          permissionKey: "creditNotes",
          group: "home",
        },
      ],
    },
    {
      component: CNavGroup,
      name: "invoices",
      to: "/invoice",
      customIcon: "invoice",
      items: [
        {
          component: CNavItem,
          name: "invoices.supplierInvoices",
          to: "/invoice",
          permissionKey: "supplierInvoices",
          group: "home",
        },
        {
          component: CNavItem,
          name: "invoices.storeInvoices",
          to: "/storeInvoice",
          permissionKey: "storeInvoices",
          group: "home",
        },
      ],
    },
    {
      component: CNavGroup,
      name: "receipts",
      to: "/receipt",
      customIcon: "receipt",
      permissionKey: "receipts",
      items: [
        {
          component: CNavItem,
          name: "receipts.supplierReceipts",
          to: "/receipt",
          permissionKey: "supplierReceipts",
          group: "home",
        },
        {
          component: CNavItem,
          name: "receipts.storeReceipts",
          to: "/companyReceipt",
          permissionKey: "storeReceipts",
          group: "home",
        },
      ],
    },
    {
      component: CNavItem,
      name: "quotations",
      to: "/quotations",
      customIcon: "invoice-1",
      permissionKey: "quotations",
      group: "home",
    },
    {
      component: CNavGroup,
      name: "storeSales",
      to: "/productSale",
      customIcon: "store-200",
      permissionKey: "storeSales",
      items: [
        {
          component: CNavItem,
          name: "storeSales.invoices",
          to: "/productSale",
          permissionKey: "invoices",
          group: "home",
        },
        {
          component: CNavItem,
          name: "storeSales.receipts",
          to: "/storeReceipt",
          permissionKey: "receipts",
          group: "home",
        },
      ],
    },
    {
      component: CNavItem,
      name: "currencyExchange",
      to: "/currencyExchange",
      customIcon: "currency-exchange",
      permissionKey: "currencyExchange",
      group: "transactions",
    },
    {
      component: CNavGroup,
      name: "expenses",
      to: "/expenses",
      customIcon: "expenses",
      permissionKey: "expenses",
      items: [
        {
          component: CNavItem,
          name: "companyExpenses",
          to: "/companyExpenses",
          permissionKey: "companyExpenses",
          group: "transactions",
        },
        {
          component: CNavItem,
          name: "salary.postSalary",
          to: "/salary",
          permissionKey: "salary",
          group: "transactions",
        },
        {
          component: CNavItem,
          name: "stores.storeExpenses",
          to: "/storeExpenses",
          permissionKey: "storeExpenses",
          group: "transactions",
        },
      ],
    },
    {
      component: CNavItem,
      name: "storeWithdraw",
      to: "/storeWithdraw",
      customIcon: "cash-withdrawal",
      permissionKey: "storeWithDrawals",
      group: "transactions",
    },
    {
      component: CNavItem,
      name: "fundTransfer",
      to: "/fund-transfer",
      customIcon: "Trust-Fund-200",
      permissionKey: "fundTransfers",
      group: "transactions",
    },

    {
      component: CNavGroup,
      name: "trustFund",
      to: "/userBalanceSheet",
      customIcon: "bill",
      permissionKey: "trustFunds",
      items: [
        {
          component: CNavItem,
          name: "trustFund.deposit",
          to: "/userDeposit",
          customIcon: "calculator1",
          permissionKey: "deposit",
        },
        {
          component: CNavItem,
          name: "trustFund.withdraw",
          to: "/userWithdraw",
          customIcon: "withdraw1",
          permissionKey: "withdraw",
        },
      ],
    },
    {
      component: CNavGroup,
      name: "propertyManagement",
      to: "/",
      customIcon: "stock-market",
      permissionKey: "manageProperty",
      items: [
        {
          component: CNavItem,
          name: "property",
          to: "/property",
          permissionKey: "property",
        },
        {
          component: CNavItem,
          name: "contract",
          to: "/contract",
          permissionKey: "contract",
        }
      ],
    },

    {
      component: CNavGroup,
      name: "balanceSheet",
      to: "/balance_sheet",
      customIcon: "receipt-21",
      permissionKey: "reports",
      items: [
        {
          component: CNavItem,
          name: "balanceSheet.bank",
          to: "/BankSummery",
          permissionKey: "companyAccounts",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "balanceSheet.store",
          to: "/StoreBalanceSummery",
          permissionKey: "storeAccounts",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "balanceSheet.supplier",
          to: "/SupplierSummery",
          permissionKey: "supplierAccounts",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "stockStatus",
          to: "/stockStatus",
          permissionKey: "stockStatus",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "report.supplierProduct",
          to: "/supplierProduct",
          permissionKey: "supplierOrderHistory",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "report.storeProduct",
          to: "/storeProductReport",
          permissionKey: "customerOrderHistory",
          group: "reports",
        },
        {
          component: CNavItem,
          name: "report.trustUserAccounts",
          to: "/trustUserAccounts",
          permissionKey: "trustUserAccounts",
          group: "reports",
        },
      ],
    },

    // {
    //   component: CNavItem,
    //   name: 'userRoles',
    //   to: '/userRoles',
    //   customIcon : 'bill',
    //   permissionKey : 'userRoles',
    //   group : "administration"
    // },
    // {
    //   component: CNavItem,
    //   name: 'manageUsers',
    //   to: '/manageUsers',
    //   customIcon : 'bill',
    //   permissionKey : 'manageUsers',
    //   group : "administration"
    // },

    {
      component: CNavGroup,
      name: "utilities",
      to: "/utilities",
      gaxonIcon: "extra-components",
      permissionKey: "utilities",
      items: [
        {
          component: CNavItem,
          name: "utilities.trustUser",
          to: "/userList",
          permissionKey: "trustUser",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.account",
          to: "/account",
          permissionKey: "account",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "category",
          to: "/category",
          permissionKey: "category",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "product",
          to: "/product",
          permissionKey: "product",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.bank",
          to: "/bank",
          permissionKey: "bank",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.currency",
          to: "/currency",
          permissionKey: "currency",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.customer",
          to: "/customer",
          permissionKey: "customer",
          group: "administration",
        },

        {
          component: CNavItem,
          name: "stores.viewStore",
          to: "/stores",
          permissionKey: "stores",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.supplier",
          to: "/supplier",
          permissionKey: "supplier",
          group: "administration",
        },
        {
          component: CNavItem,
          name: "utilities.unit",
          to: "/units",
          permissionKey: "units",
          group: "administration",
        },
      ],
    },
  ],
  team: [
    {
      component: CNavItem,
      name: "dashboard",
      to: "/dashboard",
      customIcon: "dashboard",
      permissionKey: "dashboard",
    },
    {
      component: CNavItem,
      name: "saleInvoice",
      to: "/saleInvoice",
      customIcon: "invoice",
      permissionKey: "saleInvoice",
      group: "home",
    },
    {
      component: CNavItem,
      name: "delivered",
      to: "/deliveries",
      customIcon: "invoice",
      permissionKey: "delivered",
      group: "home",
    },
    {
      component: CNavItem,
      name: "stock.entry",
      to: "/stock",
      customIcon: "stocks",
      permissionKey: "stockEntry",
    },

    {
      component: CNavItem,
      name: "quickSale",
      to: "/quickSale",
      customIcon: "stocks",
      permissionKey: "quickSale",
      group: "home",
    },
    {
      component: CNavGroup,
      name: "stockTransfer",
      to: "/stockTransfer",
      customIcon: "stock-market",
      permissionKey: "stockTransfer",
      items: [
        // {
        //   component: CNavItem,
        //   name: 'Company.to.store',
        //   to: '/distribute',
        //   permissionKey : 'companyToStore',
        // },
        {
          component: CNavItem,
          name: "Store.to.store",
          to: "/storeToStoreCreateInvoice",
          permissionKey: "storeToStore",
        },
        {
          component: CNavItem,
          name: "stockTransfer.debitNotes",
          to: "/debitNotes",
          permissionKey: "debitNotes",
        },
        {
          component: CNavItem,
          name: "stockTransfer.creditNotes",
          to: "/creditNotes",
          permissionKey: "creditNotes",
        },
      ],
    },
    {
      component: CNavGroup,
      name: "invoices",
      to: "/invoice",
      customIcon: "invoice",
      items: [
        {
          component: CNavItem,
          name: "invoices.supplierInvoices",
          to: "/invoice",
          permissionKey: "supplierInvoices",
        },
        {
          component: CNavItem,
          name: "invoices.storeInvoices",
          to: "/storeInvoice",
          permissionKey: "storeInvoices",
        },
      ],
    },
    {
      component: CNavGroup,
      name: "receipts",
      to: "/receipt",
      customIcon: "receipt",
      permissionKey: "receipts",
      items: [
        {
          component: CNavItem,
          name: "receipts.supplierReceipts",
          to: "/receipt",
          permissionKey: "supplierReceipts",
        },
        {
          component: CNavItem,
          name: "receipts.storeReceipts",
          to: "/companyReceipt",
          permissionKey: "storeReceipts",
        },
      ],
    },
    {
      component: CNavItem,
      name: "quotations",
      to: "/quotations",
      customIcon: "invoice-1",
      permissionKey: "quotations",
      group: "home",
    },
    {
      component: CNavGroup,
      name: "storeSales",
      to: "/productSale",
      customIcon: "store-200",
      permissionKey: "storeSales",
      items: [
        {
          component: CNavItem,
          name: "storeSales.invoices",
          to: "/productSale",
          permissionKey: "invoices",
        },
        {
          component: CNavItem,
          name: "storeSales.receipts",
          to: "/storeReceipt",
          permissionKey: "receipts",
        },
      ],
    },
    {
      component: CNavItem,
      name: "currencyExchange",
      to: "/currencyExchange",
      customIcon: "currency-exchange",
      permissionKey: "currencyExchange",
    },
    {
      component: CNavGroup,
      name: "expenses",
      to: "/expenses",
      customIcon: "expenses",
      permissionKey: "expenses",
      items: [
        {
          component: CNavItem,
          name: "companyExpenses",
          to: "/companyExpenses",
          permissionKey: "companyExpenses",
        },
        {
          component: CNavItem,
          name: "salary.postSalary",
          to: "/salary",
          permissionKey: "salary",
        },
        {
          component: CNavItem,
          name: "stores.storeExpenses",
          to: "/storeExpenses",
          permissionKey: "storeExpenses",
        },
      ],
    },
    {
      component: CNavItem,
      name: "storeWithdraw",
      to: "/storeWithdraw",
      customIcon: "cash-withdrawal",
      permissionKey: "storeWithDrawals",
    },
    {
      component: CNavItem,
      name: "fundTransfer",
      to: "/fund-transfer",
      customIcon: "Trust-Fund-200",
      permissionKey: "fundTransfers",
    },

    {
      component: CNavGroup,
      name: "trustFund",
      to: "/userBalanceSheet",
      customIcon: "bill",
      permissionKey: "trustFunds",
      items: [
        {
          component: CNavItem,
          name: "trustFund.deposit",
          to: "/userDeposit",
          customIcon: "calculator1",
          permissionKey: "deposit",
        },
        {
          component: CNavItem,
          name: "trustFund.withdraw",
          to: "/userWithdraw",
          customIcon: "withdraw1",
          permissionKey: "withdraw",
        },
      ],
    },

    {
      component: CNavGroup,
      name: "propertyManagement",
      to: "/",
      customIcon: "stock-market",
      permissionKey: "property",
      items: [
        {
          component: CNavItem,
          name: "property",
          to: "/property",
          permissionKey: "property",
        },
        {
          component: CNavItem,
          name: "contract",
          to: "/contract",
          permissionKey: "contract",
        },
      ],
    },

    {
      component: CNavGroup,
      name: "balanceSheet",
      to: "/balance_sheet",
      customIcon: "receipt-21",
      permissionKey: "reports",
      items: [
        {
          component: CNavItem,
          name: "balanceSheet.bank",
          to: "/BankSummery",
          permissionKey: "companyAccounts",
        },
        {
          component: CNavItem,
          name: "balanceSheet.store",
          to: "/StoreBalanceSummery",
          permissionKey: "storeAccounts",
        },
        {
          component: CNavItem,
          name: "balanceSheet.supplier",
          to: "/SupplierSummery",
          permissionKey: "supplierAccounts",
        },
        {
          component: CNavItem,
          name: "stockStatus",
          to: "/stockStatus",
          permissionKey: "stockStatus",
        },
        {
          component: CNavItem,
          name: "report.trustUserAccounts",
          to: "/trustUserAccounts",
          permissionKey: "trustUserAccounts",
        },
        {
          component: CNavItem,
          name: "report.supplierProduct",
          to: "/supplierProduct",
          permissionKey: "supplierOrderHistory",
        },
        {
          component: CNavItem,
          name: "report.storeProduct",
          to: "/storeProductReport",
          permissionKey: "customerOrderHistory",
        },
      ],
    },

    // {
    //   component: CNavGroup,
    //   name: 'user',
    //   to: '/admin',
    //   customIcon : 'user-800',
    //   permissionKey : 'users',
    //   items: [
    //     {
    //       component: CNavItem,
    //       name: 'admin',
    //       to: '/admin',
    //       permissionKey : 'admin',
    //     },
    //     {
    //       component: CNavItem,
    //       name: 'employee',
    //       to: '/employee',
    //       permissionKey : 'employee',
    //     },
    //   ],
    // },
    // {
    //   component: CNavGroup,
    //   name: 'teamManagement',
    //   customIcon : 'user-800',
    //   permissionKey : 'teamManagement',
    //   items: [
    //     {
    //       component: CNavItem,
    //       name: 'userRoles',
    //       to: '/userRoles',
    //       permissionKey : 'userRoles',
    //     },
    //     {
    //       component: CNavItem,
    //       name: 'manageUsers',
    //       to: '/manageUsers',
    //       permissionKey : 'manageUsers',
    //     },
    //   ],
    // },
    {
      component: CNavGroup,
      name: "utilities",
      to: "/utilities",
      gaxonIcon: "extra-components",
      permissionKey: "utilities",
      items: [
        {
          component: CNavItem,
          name: "utilities.trustUser",
          to: "/userList",
          permissionKey: "trustUser",
        },
        {
          component: CNavItem,
          name: "utilities.account",
          to: "/account",
          permissionKey: "account",
        },
        {
          component: CNavItem,
          name: "category",
          to: "/category",
          permissionKey: "category",
        },
        {
          component: CNavItem,
          name: "product",
          to: "/product",
          permissionKey: "product",
        },
        {
          component: CNavItem,
          name: "utilities.bank",
          to: "/bank",
          permissionKey: "bank",
        },
        {
          component: CNavItem,
          name: "utilities.currency",
          to: "/currency",
          permissionKey: "currency",
        },
        {
          component: CNavItem,
          name: "utilities.customer",
          to: "/customer",
          permissionKey: "customer",
        },
        // {
        //   component: CNavItem,
        //   name: 'utilities.profile',
        //   to: '/profile',
        // },
        {
          component: CNavItem,
          name: "stores.viewStore",
          to: "/stores",
          permissionKey: "stores",
        },
        {
          component: CNavItem,
          name: "utilities.supplier",
          to: "/supplier",
          permissionKey: "supplier",
        },
        {
          component: CNavItem,
          name: "utilities.unit",
          to: "/units",
          permissionKey: "units",
        },
      ],
    },
  ],
};

export default _nav;
