import React from 'react'
import {
  CFormFloating,
  CFormLabel,
  CForm,
  CFormInput,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder"
import { DataRequestAction } from '../../../redux/actions/http'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {notify} from '../../../util/ShowToast'

const CategoryForm = ({ defaultValues, ButtonComponent }) => {



  const comProps = useSelector((state) => state.categoryReducer);



  const dispatch = useDispatch();



  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      <IntlMessages id="category.addCategoryModal.name_message" />
    ),
  
  });

  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };


  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } = useForm(formOptions);
  const { errors } = formState;



  const onFinish = values => {


    if (comProps.selectedItem === null) {
      dispatch(DataRequestAction('POST', 'addCategory', values, '', 'category', false));
  } else {
      values._id = comProps.selectedItem._id;
      dispatch(DataRequestAction('PUT', 'editCategory', values, '', 'category', false));
  }

  };
  if (comProps.success) {
    let messageLng = intlPlaceholder(comProps.message)
    notify('success',messageLng);
    dispatch({
        type: 'category_UPDATE',
    });
}
  if (comProps.error) {
    let messageLng = intlPlaceholder(comProps.message)
    notify('error',messageLng);
    dispatch({
      type: "category_UPDATE",
    });
  }
  return (
    <CForm
      onSubmit={handleSubmit(onFinish)}
    >


      <div className="mb-3">
      <CFormFloating className="mb-3">
      <CFormInput
        className={errors.name ? "is-invalid" : ""}
          {...register("name")}
          placeholder={intlPlaceholder("category.addCategoryModal.name")}
          type="text"
          id="name"
        />
          <CFormLabel htmlFor="name">
          {intlPlaceholder("category.addCategoryModal.name")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </CFormFloating>
       
       
      </div>



      {ButtonComponent}
    </CForm>
  )
}

export default CategoryForm
