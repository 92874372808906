import React, { useState, useEffect } from 'react'
import {
    CButton,

    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,

    CForm,
    CFormInput,
    CLoadingButton
} from '@coreui/react-pro'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder"
import { useDispatch, useSelector } from 'react-redux'
import { DataEntryModalAction } from '../../../redux/actions/Common'
import AddForm from './AddForm'
import {notify} from '../../../util/ShowToast'
const AddSupplierModal = () => {



    const dispatch = useDispatch();
   


    const comProps = useSelector((state) => state.supplier);

    const { supplierLoader, selectedItem, modalVisible, success } = comProps;





    const modalType = "show_supplier_modal";
    const showModal = (Item) => {
        dispatch({
            type: modalType,
            payload: Item,
        });
    }
    // form validation rules 

  

    if (success) {
        let message = intlPlaceholder(comProps.message)
        notify('success',message)

        dispatch({
            type: 'supplier_UPDATE',
        });

    }
    if (comProps.error) {
        let message = intlPlaceholder(comProps.message)
        notify('error',message)

        dispatch({
            type: 'supplier_UPDATE',
        });

    }
    

    return (
        <div>
            <CButton color='primary' onClick={() => showModal(null)}>
            <i className="icon icon-add me-1"></i>
                <IntlMessages id="supplier.add.supplier.button" />
            </CButton>
            <CModal visible={modalVisible} >
                <CModalHeader onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}>
                    <CModalTitle>{`${ intlPlaceholder('supplier.addSupplierModal.addSupplier')}`}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <AddForm defaultValues={selectedItem} ButtonComponent={
                        <CModalFooter>
                            <CButton color="secondary" onClick={() => dispatch(DataEntryModalAction(modalType, null))}>
                                <IntlMessages id="supplier.addSupplierModal.cancel" />
                            </CButton>

                            <CLoadingButton type="submit" color="primary" loading={supplierLoader} disabled={supplierLoader}>
                                {intlPlaceholder('supplier.addSupplierModal.save')}
                            </CLoadingButton>

                        </CModalFooter>
                    } />
                </CModalBody>


            </CModal>
        </div>
    )
}

export default AddSupplierModal




