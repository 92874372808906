import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from "@coreui/react-pro";
import DocsExample from "../../../components/DocsExample";
import IntlMessages from "../../../util/IntlMessages";
import UserRoleList from "./components/UserRoleList";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import { useHistory } from "react-router-dom";
import CheckUserAccess from "../../../util/checkUserAccess";
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProps = useSelector((state) => state.users);
  const create = CheckUserAccess('userRoles',['create']) 
  const fetchUserRoles = () => {
    dispatch(
      DataGetAction("getAllRole", { query: "all" }, "gettingAllUserRoles")
    );
  };
  useEffect(fetchUserRoles, []);
  

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <h1>
                <IntlMessages id="userRoles.title" />
              </h1>
            </CCardHeader>
            <CCardBody>
              <div className="ps-3">
               {create && <CButton
                  color="primary"
                  onClick={() => {
                    history.push("/addUserRoles");
                  }}
                >
                  <i className="icon icon-add me-1"></i>
                  <IntlMessages id="userRoles.addButton" />
                </CButton>}
              </div>
              <DocsExample href="components/smart-table/">
                <UserRoleList
                  //Data={data}
                  Data={userProps.UserRoles}
                  Spinner={userProps.allUserRolesSpinner}
                  //Spinner={false}
                />
              </DocsExample>
            </CCardBody>
          </CCard>
        </CCol>
        {/* <EditModal/> */}
      </CRow>
    </>
  );
};

export default Index;
