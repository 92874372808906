
import enMessages from "../locales/en_US.json";

const EnLang = {
  messages: {
    ...enMessages
  },
  locale: 'en-US'
};
export default EnLang;
