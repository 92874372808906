import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilBuilding,
  cilUserFollow,
} from "@coreui/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataRequestAction, updateAllState } from "../../../redux/actions/http";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { notify } from "../../../util/ShowToast";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const { message, success, error, ShowSetNewLoader } = authProps;

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirm: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const params = useParams();

  const LoadPage = () => {
    return () => {
      dispatch(updateAllState("auth"));
    };
  };
  useEffect(LoadPage, []);

  const redirectToLoginPage = () => {
    dispatch(updateAllState("auth"));
    history.push("/");
  };

  const onFinish = (values) => {
    values.forgetPasswordAuthToken = params.guid;
    dispatch(
      DataRequestAction("POST", "setNewPassword", values, "", "setNew", false)
    );
  };

  if (error) {
    let messageLng = intlPlaceholder(authProps.message)
    notify('error',messageLng);
    dispatch(updateAllState("auth"));
  }

  let passwordSetSuccessfully = intlPlaceholder("passwordSetSuccessfully");
  let setNewPassword = intlPlaceholder("setNewPassword");

  const showSweetAlert = () => {
    if (success) {
      return (
        <SweetAlert
          success
          title="Congrats!!!"
          onConfirm={() => redirectToLoginPage()}
        >
          {passwordSetSuccessfully}
          
        </SweetAlert>
      );
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div>{showSweetAlert()}</div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit(onFinish)}>
                  <div className="welcomeMessage gx-text-center gx-mb-5">
                    <h2 className="gx-font-weight-bold gx-mb-3">
                      
                      {setNewPassword}
                    </h2>
                    <p
                      className={`gx-text-center ${
                        authProps.error ? "error" : ""
                      }`}
                    >
                      {authProps.message}
                    </p>
                  </div>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder={intlPlaceholder("password")}
                      autoComplete="new-password"
                      id="password"
                      className={errors.password ? "is-invalid" : ""}
                      {...register("password")}
                    />
                    <div className="invalid-feedback">
                      {errors.password?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      
                      placeholder={intlPlaceholder("signUp.repeatPassword")}
                      autoComplete="new-password"
                      id="confirm"
                      className={errors.confirm ? "is-invalid" : ""}
                      {...register("confirm")}
                    />
                    <div className="invalid-feedback">
                      {errors.confirm?.message}
                    </div>
                  </CInputGroup>

                  <div className="d-grid">
                    <CLoadingButton
                      color="success"
                      type="submit"
                      loading={ShowSetNewLoader}
                      disabled={ShowSetNewLoader}
                    >
                      <IntlMessages id="send" />
                    </CLoadingButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Index;
