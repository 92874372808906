import React, { useState, useEffect } from "react";
import { CForm, CFormInput, CFormFloating, CFormLabel } from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";
import moment from "moment";
import NumberFormat from "react-number-format";
import IntlMessages from "src/util/IntlMessages";
import { intlPlaceholder } from "src/util/intlPlaceholder";

const ContractForm = ({ defaultValues, ButtonComponent }) => {
  const dispatch = useDispatch();
  const { properties, clients, currencies, vacantUnits } = useSelector((state) => state.contractReducer);

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    dispatch(DataGetAction("contractFormData", null, "contractFormData"));
  }, [dispatch]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(DataGetAction("allVacantUnits", { propertyId: selectedProperty }, "allVacantUnits"));
    }
  }, [selectedProperty, dispatch]);

  const validationSchema = Yup.object().shape({
    propertyId: Yup.string().required(<IntlMessages id="contract.selectProperty_message" />),
    pUnitId: Yup.string().required(<IntlMessages id="contract.selectUnit_message" />),
    clientId: Yup.string().required(<IntlMessages id="contract.selectClient_message" />),
    date: Yup.array().required(<IntlMessages id="contract.selectDate_message" />),
    monthlyRent: Yup.number().required(<IntlMessages id="contract.selectRent_message" />),
    currency: Yup.string().required(<IntlMessages id="contract.selectCurrency_message" />),
    deposit: Yup.number(),
    
  });

  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };

  const { register, handleSubmit, control, setValue, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (startDate) setValue("startDate", startDate);
    if (endDate) setValue("endDate", endDate);
  }, [startDate, endDate, setValue]);

  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      startDate: moment(values.date[0]).format("YYYY/MM/DD"),
      endDate: moment(values.date[1]).format("YYYY/MM/DD"),
    };
    const query = {
      property: "all",
      status: "active",
    };

    dispatch(
      DataRequestAction(
        "POST",
        "createContract",
        formattedValues,
        "",
        "contract",
        false,
        () => {
          // Callback to close the modal and refresh the table
          dispatch({ type: "show_contract_modal", payload: null });
          dispatch(DataGetAction("contracts", query, "contractSpinner"));
          dispatch({ type: "contract_STOP" });
        },
        () => {
          dispatch({ type: "contract_STOP" }); // Reset loader state if there is an error
        }
      )
    );
  };

  const handleApply = (event, picker) => {
    setDateRange([picker.startDate.toDate(), picker.endDate.toDate()]);
    setValue("date", [picker.startDate.toISOString(), picker.endDate.toISOString()]);
  };

  return (
    <CForm onSubmit={handleSubmit(onFinish)}>
      <div className="mb-3">
        <CFormFloating className="mb-3">
          <Controller
            control={control}
            name="propertyId"
            render={({ field: { onChange, value } }) => (
              <Select
                className={errors.propertyId ? "is-invalid" : ""}
                value={properties.find((option) => option._id === value)}
                onChange={(selectedOption) => {
                  setSelectedProperty(selectedOption.value);
                  onChange(selectedOption.value);
                }}
                options={properties.map((property) => ({
                  value: property._id,
                  label: property.label,
                }))}
                isSearchable
                placeholder={intlPlaceholder("contract.selectProperty")}
              />
            )}
          />

          <div className="invalid-feedback">{errors.propertyId?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <Controller
            control={control}
            name="pUnitId"
            render={({ field: { onChange, value } }) => {
              const selectedOption = vacantUnits.find((option) => option._id === value);
              return (
                <Select
                  className={errors.pUnitId ? "is-invalid" : ""}
                  value={selectedOption ? { value: selectedOption._id, label: selectedOption.pUnitNumber } : null}
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  options={vacantUnits.map((unit) => ({
                    value: unit._id,
                    label: unit.pUnitNumber,
                  }))}
                  isSearchable
                  placeholder={intlPlaceholder("contract.selectUnit")}
                  isDisabled={!selectedProperty}
                />
              );
            }}
          />

          <div className="invalid-feedback">{errors.pUnitId?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <Controller
            control={control}
            name="clientId"
            render={({ field: { onChange, value } }) => (
              <Select
                className={errors.clientId ? "is-invalid" : ""}
                value={clients.find((option) => option._id === value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                options={clients.map((client) => ({
                  value: client._id,
                  label: client.label,
                }))}
                isSearchable
                placeholder={intlPlaceholder("contract.selectClient")}
              />
            )}
          />

          <div className="invalid-feedback">{errors.clientId?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DateRangePicker
                initialSettings={{
                  startDate: startDate,
                  endDate: endDate,
                  minDate: moment().toDate(), // Ensure the calendar starts from today's date
                }}
                onApply={handleApply}
              >
                <input type="text" className={`form-control ${errors.date ? "is-invalid" : ""}`} />
              </DateRangePicker>
            )}
          />
          <CFormLabel htmlFor="date">{intlPlaceholder("contract.selectDateRange")}</CFormLabel>
          <div className="invalid-feedback">{errors.date?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3 number-format-input">
        <CFormFloating className="mb-3">
          <Controller
            name="monthlyRent"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <NumberFormat
                className={errors.monthlyRent ? "is-invalid" : ""}
                allowNegative={false}
                thousandSeparator={true}
                onValueChange={(v) => onChange(v.value)}
                value={value}
                placeholder={intlPlaceholder("contract.enterMonthlyRent")}
              />
            )}
          />

          <div className="invalid-feedback">{errors.monthlyRent?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3">
        <CFormFloating className="mb-3">
          <Controller
            control={control}
            name="currency"
            render={({ field: { onChange, value } }) => (
              <Select
                className={errors.currency ? "is-invalid" : ""}
                value={currencies.find((option) => option._id === value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                options={currencies.map((currency) => ({
                  value: currency._id,
                  label: currency.label,
                }))}
                isSearchable
                placeholder={intlPlaceholder("contract.selectCurrency")}
                styles={{}}
              />
            )}
          />

          <div className="invalid-feedback">{errors.currency?.message}</div>
        </CFormFloating>
      </div>

      <div className="mb-3 number-format-input">
        <CFormFloating className="mb-3">
          <Controller
            name="deposit"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <NumberFormat
                className={errors.deposit ? "is-invalid" : ""}
                allowNegative={false}
                thousandSeparator={true}
                onValueChange={(v) => onChange(v.value)}
                value={value}
                placeholder={intlPlaceholder("contract.enterDeposit")}
              />
            )}
          />

          <div className="invalid-feedback">{errors.deposit?.message}</div>
        </CFormFloating>
      </div>

      {ButtonComponent}
    </CForm>
  );
};

export default ContractForm;
