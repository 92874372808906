import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "../../redux/actions/http";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CCol,
  CRow,
  CForm,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import { useHistory } from "react-router-dom";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import IntlMessages from "../../util/IntlMessages";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { notify } from "../../util/ShowToast";
import Select from "react-select";
import { selectStyle } from "../../constants/CommonFn";
import { FormInput } from "./../../components/form/form-input";

const DebitNotesEditProductForm = (props) => {
  const dispatch = useDispatch();
  const disProps = useSelector((state) => state.distributeReducer);
  const stsProps = useSelector((state) => state.storeToStoreReducer);
  //const storeProps = useSelector((state) => state.storeReducer);

  const {
    ResourceStore,
    DestinationStore,
    Products,
    ProductsLocal,
    units,
    Price,
    Qty,
    selectedUnit,
    localProducts,
    fetchProductsStoreStockSpinner,
    debitNotesDetail,
  } = stsProps;
  const history = useHistory();


  let matchSourceStore =  intlPlaceholder("stockTransfer.matchSourceStore")
  let matchDestinationStore =  intlPlaceholder("stockTransfer.matchDestinationStore")

  const validationSchema = Yup.object().shape({
    resourceStore: Yup.array().required(
      <IntlMessages id="storeToStoreInvoice.createInvoicePage.sourceStore_message" />
    )
    .test('store-match', matchSourceStore, function(value){
      const valid = this.parent.destinationStore[0].value !== value[0].value;
      props.setDisableSaveBtn(!valid)

      return valid;
    }),
    
    destinationStore: Yup.array().required(
      <IntlMessages id="storeToStoreInvoice.createInvoicePage.destinationStore_message" />
    )
    .test('store-match', matchDestinationStore, function(value){
      const valid = this.parent.resourceStore[0].value !== value[0].value;
      props.setDisableSaveBtn(!valid)

      return valid;
    }),
    products: Yup.array()
      .min(1, "select at least one product")
      .required(
        <IntlMessages id="storeToStoreInvoice.createInvoicePage.products_message" />
      )
      .nullable(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (debitNotesDetail?.storeId) {
      props.setValue("resourceStore", debitNotesDetail.storeId);
      props.setValue("destinationStore", debitNotesDetail.destinationStoreId);
    }
  }, [debitNotesDetail]);

  const addProductInLocalArray = (values) => {
    let selectedIds = values.products.map(item => item.value)
    values.products = selectedIds;

    dispatch({
      type: "setStoreToStoreProducts",
      payload: values,
    });
  };

  if (disProps.deleteRecordSuccess) {
    dispatch({
      type: "deleteRecord_update",
    });
    setValue("units", "");
    setValue("product", "");
  }

  if (selectedUnit) {
    setValue("unit", selectedUnit.unit);
  }

  const renderOptions = (data, label, key) => {
    if (data && data.length > 0) {
      return data.map((data) => ({
        label: data[label],
        value: data[key],
      }));
    }
  };

  return (
    <CForm onSubmit={handleSubmit(addProductInLocalArray)}>
      <div className="row mb-4">
        {/* resourceStore */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(ResourceStore, function (o) {
                return o._id === debitNotesDetail?.storeId;
              });
              return (
                <FormInput
                  control={control}
                  errors={errors}
                  defaultValue={renderOptions(render, "label", "value")}
                  placeholder={intlPlaceholder(
                    "storeToStoreInvoice.createInvoicePage.sourceStore"
                  )}
                  name="resourceStore"
                  type="select"
                  isDisabled={false}
                  options={renderOptions(
                    ResourceStore,
                    "label",
                    "value"
                  )}
                  onHandleChange={(e) => {
                    props.setValue("resourceStore", e?.value);
                    //fetchDestinationStoreAndSupplier(e.value);
                    return !_.isEmpty(e) ? [e] : false;
                  }}
                />
              );
            })()}

            <div className="invalid-feedback">
              {errors.resourceStore?.message}
            </div>
          </div>
        </div>
        {/* destinationStore */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              const render = _.filter(ResourceStore, function (o) {
                return o._id === debitNotesDetail?.destinationStoreId;
              });
              return (
                <FormInput
                  control={control}
                  errors={errors}
                  defaultValue={renderOptions(render, "label", "value")}
                  placeholder={intlPlaceholder(
                    "storeToStoreInvoice.createInvoicePage.destinationStore"
                  )}
                  name="destinationStore"
                  type="select"
                  isDisabled={false}
                  options={renderOptions(
                    ResourceStore,
                    "label",
                    "value"
                  )}
                  onHandleChange={(e) => {
                    props.setValue("destinationStore", e?.value);
                    return !_.isEmpty(e) ? [e] : false;
                  }}
                />
              );
            })()}

            <div className="invalid-feedback">
              {errors.destinationStore?.message}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        {/* products */}
        <div className="col-md-6">
          <div className="mb-3">
            {(() => {
              // const render = _.filter(Products, function (o) {
              //   return _.map(localProducts, "_id").includes(o._id);
              // });

              return (
                <FormInput
                  control={control}
                  errors={errors}
                  // defaultValue={renderOptions(
                  //   render,
                  //   "productName",
                  //   "_id"
                  // )}
                  placeholder={intlPlaceholder(
                    "product.addProductPage.choseProduct"
                  )}
                  name="products"
                  type="select"
                  isMulti={true}
                  options={renderOptions(
                    Products,
                    "productName",
                    "_id"
                  )}
                  onHandleChange={(value) =>
                    value.length ? value : false
                  }
                />
              );
            })()}

            <div className="invalid-feedback">
              {errors.products?.message}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <CButton className="d-block w-100" color="primary" type="submit">
            <i className="icon icon-add me-1"></i>
            <IntlMessages id="storeToStoreInvoice.createInvoicePage.add" />
          </CButton>
        </div>
      </div>
    </CForm>
  );
};

export default DebitNotesEditProductForm;
