import _ from "lodash";

const INIT_STATE = {
  stores: [],
  suppliers: [],
  SelectedProduct: null,
  SelectedUnit: null,
  units: [],
  products: [],
  success: false,
  error: false,
  message: "",
  Qty: 0,
  Price: 0,
  ProductsLocal: [],
  deleteRecordSuccess: false,
  ShowPreview: false,
  InvoiceNo: 0,
  selectedUnit: 0,
  saleInvoiceLoader: false,
  SaleInvoiceLoaderFetch: false,
  productListDrop: [],
  invoiceLoader: false,
  SaleInvoiceDetail: null,
  ShowSaleInvoiceModal: false,
  initialVale: null,
  CreateInvoiceData: null,
  editSaleInvoiceModalVisible: false,
  ProductList: [],
  localProducts: [],
  editSpinner: false,
  editSaleInvoiceSuccess: false,
  editSaleInvoiceSuccess: false,
  editSaleInvoiceError: false,
  editSaleInvoiceSpinner: false,
  cancelSaleInvoiceSuccess: false,
  cancelSaleInvoiceError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "saleInvoiceProduct_START": {
      return { ...state, saleInvoiceLoader: true };
    }
    case "getSaleList_SUCCESS": {
      return {
        ...state,
        saleInvoiceList: action.payload.Record,
        SaleInvoiceLoader: false,
      };
    }
    case "Show_View_Sale_Invoice_Modal": {
      return {
        ...state,
        ShowSaleInvoiceModal: true,
        initialVale: action.payload,
      };
    }
    case "Hide_View_Sale_Invoice_Modal": {
      return {
        ...state,
        ShowSaleInvoiceModal: false,
        initialVale: null,
      };
    }
    case "getSaleInvoiceDetail_SUCCESS": {
      return {
        ...state,
        SaleInvoiceDetail: action.payload.Record,
        invoiceLoader: false,
      };
    }
    case "fetchingSaleInvoice_START": {
      return { ...state, invoiceLoader: true };
    }
    case "allSaleInvoicesSpinner_START": {
      return { ...state, SaleInvoiceLoader: true };
    }
    case "ViewTheSaleInvoiceProduct": {
      return { ...state, ShowPreview: !state.ShowPreview };
    }
    case "fetchingSaleInvoice_START": {
      return { ...state, SaleInvoiceLoaderFetch: true };
    }
    case "getInVoiceDetail_SUCCESS": {
      return {
        ...state,
        SaleInvoiceLoaderFetch: false,
        SaleInvoiceDetail: action.payload.Record,
      };
    }
    case "createSaleInvoice_SUCCESS": {
      let checkMessage = "recordSaleInvoiceSuccessfully";
      if (action.payload.message === "recordInvoiceCreatedButReceiptFail") {
        checkMessage = action.payload.message;
      }

      return {
        ...state,
        saleInvoiceLoader: false,
        ProductsLocal: [],
        CreateInvoiceData: action.payload.Record,
        success: true,
        message: checkMessage,
      };
    }
    case "createSaleInvoice_FAILURE": {
      return {
        ...state,
        saleInvoiceLoader: false,
        error: true,
      };
    }
    case "editSaleInvoice_SUCCESS": {
      return {
        ...state,
        editSaleInvoiceSuccess: true,
        editSaleInvoiceSpinner: false,
        editSaleInvoiceModalVisible: false,
        initialValues: null,
      };
    }
    case "editSaleInvoice_FAILURE": {
      return {
        ...state,
        editSaleInvoiceError: true,
        editSaleInvoiceSpinner: false,
      };
    }
    case "editSaleInvoiceSpin_START": {
      return {
        ...state,
        editSaleInvoiceSpinner: true,
      };
    }

    case "resetEditModalValues": {
      return {
        ...state,
        editSaleInvoiceSuccess: false,
        editSaleInvoiceError: false,
        editSaleInvoiceSpinner: false,
      };
    }
    case "distributeDrop_SUCCESS": {
      return {
        ...state,
        stores: action.payload.Record.stores,
        suppliers: action.payload.Record.suppliers,
        productListDrop: action.payload.Record.productList,
      };
    }
    case "fetchProducts_SUCCESS": {
      return {
        ...state,
        products: action.payload.Record,
        units: [],
        Qty: 0,
        Price: 0,
      };
    }
    case "cancelSaleInvoice_SUCCESS": {
      return {
        ...state,
        cancelSaleInvoiceSuccess: true,
      };
    }
    case "cancelSaleInvoice_FAILURE": {
      return {
        ...state,
        cancelSaleInvoiceError: true,
        cancelSaleInvoiceMessage: action.payload,
      };
    }
    case "fetchProductStock_SUCCESS": {
      const Record = action.payload.Record;
      const data = getUnitListByProductId(Record, state.ProductsLocal);

      return {
        ...state,
        SelectedProduct: Record.productId,
        units: Record.units,
        Qty: data.Qty,
        Price: data.Price,
        selectedUnit: { unit: data.selectedUnit },
        SelectedUnit: data.selectedUnit,
      };
    }
    case "SelectUnitOfProduct_SUCCESS": {
      const selectedData = getUnitListByProductId(
        action.payload,
        state.ProductsLocal
      );
      return {
        ...state,
        SelectedProduct: action.payload,
        units: selectedData.Units,
        Qty: selectedData.Qty,
        Price: selectedData.Price,
        selectedUnit: { unit: selectedData.selectedUnit },
        SelectedUnit: selectedData.selectedUnit,
      };
    }
    case "AddInLocalProduct_SUCCESS": {
      const result = addDataInLocalProduct(
        action.payload,
        state.ProductsLocal,
        state.units,
        state.Qty,
        state.SelectedUnit
      );
      return {
        ...state,
        ProductsLocal: result.newArray,
        Qty: 0,
        Price: 0,
        selectedUnit: 0,
        SelectedUnit: 0,
      };
    }
    case "editSaleInvoiceModalVisible": {
      return {
        ...state,
        editSaleInvoiceModalVisible: true,
        initialValues: action.payload,
      };
    }
    case "editSaleInvoiceModalHide": {
      return {
        ...state,
        editSaleInvoiceModalVisible: false,
        initialValues: null,
      };
    }
    case "editSaleInvoiceModalHide": {
      return {
        ...state,
        editSaleInvoiceModalVisible: false,
        initialValues: null,
      };
    }
    case "setLocalProductSaleInvoice": {
      const result = getLocalProducts(
        action.payload.values,
        state.localProducts,
        action.payload.ProductList
      );

      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "deleteLocalSaleInvoiceProduct": {
      const updatedProducts = deleteLocalProduct(
        state.localProducts,
        action.payload
      );

      return {
        ...state,
        localProducts: [...updatedProducts],
      };
    }
    case "updateLocalProductSaleInvoice": {
      const result = getUpdatedLocalProducts(
        action.payload,
        state.localProducts
      );
      return {
        ...state,
        localProducts: [...result],
      };
    }
    case "getEditSaleInvoice_SUCCESS": {
      return {
        ...state,
        localProducts: action.payload.Record,
        editSpinner: false,
      };
    }
    case "EditRecordSpin_START": {
      return {
        ...state,
        editSpinner: true,
      };
    }
    case "ChangeThePriceAndQty_SUCCESS": {
      const PQR = ChangeThePriceAndQty(
        action.payload,
        state.Qty,
        state.Price,
        state.units,
        state.SelectedUnit
      );
      return {
        ...state,
        Qty: PQR.Qty,
        Price: PQR.Price,
        SelectedUnit: PQR.SelectedUnit,
        selectedUnit: { unit: PQR.SelectedUnit },
      };
    }
    case "deleteProductLocal_SUCCESS": {
      const deletedRecord = getRecordAfterDeleted(
        state.ProductsLocal,
        action.payload
      );

      return {
        ...state,
        ProductsLocal: deletedRecord,
        Qty: 0,
        Price: 0,
        product: undefined,
        unit: undefined,
        deleteRecordSuccess: true,
      };
    }
    case "deleteRecord_update": {
      return { ...state, deleteRecordSuccess: false };
    }

    case "saleInvoice_UPDATE": {
      return {
        ...state,
        success: false,
        error: false,
        message: "",
        Qty: 0,
        Price: 0,
        ShowPreview: false,
        ProductsLocal: [],
      };
    }
    case "saleInvoice_RESET": {
      return {
        ...state,
        success: false,
        error: false,
        message: "",
        cancelSaleInvoiceSuccess: false,
        cancelSaleInvoiceError: false,
      };
    }

    case "goBack_initialState": {
      return {
        ...state,
        stores: [],
        suppliers: [],
        SelectedProduct: null,
        SelectedUnit: null,
        units: [],
        products: [],
        success: false,
        error: false,
        message: "",
        Qty: 0,
        Price: 0,
        ProductsLocal: [],
        deleteRecordSuccess: false,
        ShowPreview: false,
        InvoiceNo: 0,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getRecordAfterDeleted(oldRecord, deletedRecord) {
    const newRecord = [];
    oldRecord.map((Item) => {
      if (Item.product !== deletedRecord.product) {
        newRecord.push(Item);
      }
      return Item;
    });
    return newRecord;
  }

  function ChangeThePriceAndQty(selectedUnit, Qty, Price, units, oldUnit) {
    let newQty = Qty;
    let newPrice = Price;
    const subUnits = units[0].subUnits;

    if (selectedUnit !== units[0]._id) {
      let selectedUnitArr = _.filter(subUnits, (x) => x._id === selectedUnit);

      if (oldUnit !== null && oldUnit !== units[0]._id) {
        //======= Old unit is not parent unit

        let oldUnitObj = _.filter(subUnits, (x) => x._id === oldUnit);

        let selectedPUnitFactor =
          oldUnitObj && oldUnitObj.length > 0 && oldUnitObj[0].factor
            ? oldUnitObj[0].factor
            : 1;
        if (selectedUnitArr) {
          newQty =
            (Qty / parseFloat(selectedPUnitFactor)) *
            parseFloat(selectedUnitArr[0].factor);

          newPrice =
            (Price * parseFloat(selectedPUnitFactor)) /
            parseFloat(selectedUnitArr[0].factor);
        }
      } else {
        //======= Old unit is parent unit
        if (selectedUnitArr) {
          newQty = Qty * parseFloat(selectedUnitArr[0].factor);

          newPrice = Price / parseFloat(selectedUnitArr[0].factor);
        }
      }
    } else {
      if (oldUnit !== null) {
        let oldUnitObj = _.filter(subUnits, (x) => x._id === oldUnit);
        if (oldUnitObj.length > 0) {
          let selectedPUnitFactor = oldUnitObj[0].factor;
          newQty = Qty / parseFloat(selectedPUnitFactor);

          newPrice = Price * parseFloat(selectedPUnitFactor);
        } else {
          newQty = Qty;

          newPrice = Price;
        }
      }
    }

    return { Qty: newQty, Price: newPrice, SelectedUnit: selectedUnit };
  }

  function addDataInLocalProduct(
    newItem,
    oldItemArray,
    units,
    QtyInStock,
    SelectedUnit
  ) {
    let newArray = [];

    const index = _.findIndex(
      oldItemArray,
      (x) => x.productName === newItem.productName
    );
    QtyInStock = (parseFloat(QtyInStock) - parseFloat(newItem.Qty)).toFixed(2);

    if (index > -1) {
      let product = null;
      const selectedProduct = oldItemArray[index];
      if (selectedProduct.unit === newItem.unit) {
        product = selectedProduct;
        product.Qty = parseFloat(product.Qty) + parseFloat(newItem.Qty);
        product.price = newItem.Price;
        oldItemArray[index] = product;
        newArray = [...oldItemArray];
      } else {
        const oldUnitProduct = selectedProduct.unit;
        let newUnitObj = _.filter(
          units[0].subUnits,
          (x) => x._id === newItem.unit
        );
        product = selectedProduct;

        if (oldUnitProduct !== units[0]._id) {
          //==================== if old product hasn't parent unit ============
          let oldUnitObj = _.filter(
            units[0].subUnits,
            (x) => x._id === oldUnitProduct
          );
          if (newUnitObj.length > 0) {
            product.Qty =
              (parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor)) *
                parseFloat(newUnitObj[0].factor) +
              parseFloat(newItem.Qty);
          } else {
            product.Qty =
              parseFloat(product.Qty) / parseFloat(oldUnitObj[0].factor) +
              parseFloat(newItem.Qty);
          }
        } else {
          product.Qty =
            parseFloat(product.Qty) * parseFloat(newUnitObj[0].factor) +
            parseFloat(newItem.Qty);
        }
        product.unit = newItem.unit;
        product.Price = newItem.Price;
        oldItemArray[index] = product;
        newArray = [...oldItemArray];
      }
    } else {
      newArray = [newItem, ...oldItemArray];
    }

    return { newArray, QtyInStock };
  }

  function getUnitListByProductId(product, localProduct) {
    let Units = [];
    const selectedProduct = product;

    const localProductArr = _.filter(
      localProduct,
      (x) => x.product === selectedProduct.productId
    );
    Units = selectedProduct.units;

    let Qty = 0;
    let Price = selectedProduct.localPrice;

    if (localProductArr.length > 0) {
      const oldUnit = localProductArr[0].unit;
      const checkParentIndex = _.findIndex(Units, (x) => x._id === oldUnit);
      if (checkParentIndex > -1) {
        Qty =
          parseFloat(selectedProduct.quantity) -
          parseFloat(localProductArr[0].Qty);
      } else {
        let fIndex = null;
        _.map(Units, (x, index) => {
          const subUnitIndex = _.findIndex(
            x.subUnits,
            (y) => y._id === oldUnit
          );
          if (subUnitIndex > -1) {
            fIndex = { pIndex: index, cIndex: subUnitIndex };
          }
        });
        if (fIndex) {
          const oldUnitObj = Units[fIndex.pIndex].subUnits[fIndex.cIndex];
          if (oldUnitObj._id === selectedProduct.unitId) {
            Qty =
              parseFloat(selectedProduct.quantity) -
              parseFloat(localProductArr[0].Qty);

            Price = parseFloat(selectedProduct.localPrice);
          } else {
            Qty =
              parseFloat(selectedProduct.quantity) *
                parseFloat(oldUnitObj.factor) -
              parseFloat(localProductArr[0].Qty);

            Price =
              parseFloat(selectedProduct.localPrice) /
              parseFloat(oldUnitObj.factor);
          }
        }
      }
    } else {
      Qty = selectedProduct.quantity.toFixed(3);
    }

    let selectUnit = selectedProduct.unitId;
    if (localProductArr.length > 0) {
      selectUnit = localProductArr[0].unit;
    }

    return { Units, Qty: Qty, Price: Price, selectedUnit: selectUnit };
  }
};
const findUniqueArray = (arr1, arr2) => {
  let result = [];
  let indexFound = [];
  arr1.map((item, index) => {
    let d = arr2.findIndex((items) => items === item);
    if (d >= 0) {
      indexFound.push(d);
    }
  });
  for (let i = 0; i !== arr2.length; i++) {
    let d = indexFound.filter((items) => items === i);
    if (d.length < 1) {
      result.push(arr2[i]);
    } else {
      console.log("eeee", indexFound[i]);
    }
  }
  return result;
};
const getLocalProducts = (payload, localProducts, apiProducts) => {
  let productIds = [];
  let { products } = payload;
  if (products && products.length > 0) {
    products.map((Item) => {
      if (Item._id) {
        productIds.push(Item._id);
      } else {
        productIds.push(Item);
      }
    });
  }
  let prevSelectedIds =
    localProducts && localProducts.length > 0
      ? localProducts.map((item) => item._id)
      : [];

  let originalProducts = JSON.parse(JSON.stringify(apiProducts));

  let uniqueIds = findUniqueArray(prevSelectedIds, productIds); // [1,2]
  let uniqueResult = originalProducts.filter((item) => {
    if (uniqueIds.includes(item._id)) {
      item.totalPrice = parseFloat(item.Qty) * parseFloat(item.localPrice);
      item.minimumAllowedQty = parseFloat(item.sockQty);
      if (!item.delivered) {
        item.delivered = 0;
      }
      return item;
    }
  });

  let updatedArray = [];
  if (localProducts && localProducts.length > 0) {
    updatedArray = localProducts.filter((item) => {
      if (productIds.includes(item._id)) {
        if (item.Qty < item.sockQty) {
          item.Qty = parseFloat(item.Qty) + 1;
          item.totalPrice = parseFloat(item.Qty) * parseFloat(item.localPrice);

          return item;
        } else {
          return item;
        }
      } else {
        return item;
      }
    });
  }
  return [...updatedArray, ...uniqueResult];
};
const getUpdatedLocalProducts = (payload, localProducts) => {
  let updatedResult = [];
  let { fieldChanged, updatedRow, newValue } = payload;
  if (fieldChanged === "Qty") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        let modify = false;
        if (parseFloat(newValue) !== parseFloat(updatedRow.originalQty)) {
          modify = true;
        }
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(newValue) * parseFloat(item.localPrice),
          Qty: parseFloat(newValue),
          modify: modify,
          oldQty: item.Qty,
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "localPrice") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        return {
          ...item,
          subunit: updatedRow.subunit,
          totalPrice: parseFloat(item.Qty) * parseFloat(newValue),
          localPrice: parseFloat(newValue),
        };
      } else {
        return item;
      }
    });
  } else if (fieldChanged === "retail") {
    updatedResult = localProducts.map((item) => {
      if (item._id === updatedRow._id) {
        if (updatedRow.retail) {
          return {
            ...item,
            unitName: updatedRow.subunitName,
            oldUnitName: updatedRow.unitName,
            oldQty: item.Qty,
            oldSockQty: parseFloat(updatedRow.sockQtyQuery),
            retail: updatedRow.retail,
            sockQtyQuery:
              parseFloat(updatedRow.packaging) *
              parseFloat(updatedRow.sockQtyQuery),
            edit: true,
          };
        } else {
          return {
            ...item,
            unitName: item.oldUnitName ? item.oldUnitName : item.unitName,
            retail: updatedRow.retail,
            Qty: item.oldQty ? item.oldQty : item.Qty,
            sockQtyQuery: item.oldSockQty ? item.oldSockQty : item.sockQtyQuery,
            edit: false,
          };
        }
      } else {
        return item;
      }
    });
  }

  return [...updatedResult];
};
function deleteLocalProduct(oldRecord, deletedRecord) {
  const newRecord = oldRecord.filter((Item) => {
    if (Item._id !== deletedRecord._id) {
      return Item;
    }
  });
  return [...newRecord];
}
