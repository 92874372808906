import _ from 'lodash'
const INIT_STATE = {
    LoginVisible: false,
    modalVisible: false,
    passModalVisible: false,
    selectedItem: null,
    ceLoader: false,
    error: false,
    success: false,
    resetPasswordSuccess: false,
    resendSuccess: false,
    resendError: false,
    recordEditSuccess: false,
    recordEditError: false,
    assignEmployeeModalVisible: false,
    Banks: [],
    fetchBankInfo: false,
    CompanyAccount: [],
    fetchDetail: false,
    createdBy: [],
    companyExpensesSpinner: false,
    refundExpenseError : false,
    refundExpenseSuccess : false,
    message : ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case "refundExpense_SUCCESS": {
            return {
              ...state,
              refundExpenseSuccess: true,
              message: action.payload.message,
            };
          }
          case "refundExpense_FAILURE": {
            return {
              ...state,
              refundExpenseError: true,
              message: action.payload,
            };
          }

        case 'createdByFromExpense': {
            const createdByArr = [];
            state.Banks && state.Banks.map(Item => {
                if (Item.createdByUser) {
                    createdByArr.push(Item.createdByUser[0]);
                }
                return Item
            })
            return { ...state, createdBy: _.uniqBy(createdByArr, 'id') }
        }

        case 'fetchingDetail_START': {
            return { ...state, fetchDetail: true }
        }

        case 'getAllExpense_SUCCESS': {
            return { ...state, Banks: action.payload.Record, fetchBankInfo: false, fetchDetail: false };
        }
        case 'companyExpensesSpinner_START':{
            return { ...state,  companyExpensesSpinner: true };
        }
        case 'companyExpensesSpinner_STOP':{
            return { ...state,   companyExpensesSpinner: false };
        }

        case 'show_ce_modal': {
            return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
        }

        case 'ce_START': {
            return { ...state, ceLoader: true };
        }
        case 'ce_STOP': {
            return { ...state, ceLoader: false };
        }

        case 'addExpense_SUCCESS': {
            return { ...state, success: true, message: 'recordAddedSuccessfully', Banks: [action.payload.Record, ...state.Banks], ceLoader: false };
        }
        case 'addExpense_FAILURE': {
            return { ...state, error: true, success: false, ceLoader: false, message: action.payload };
        }


        case 'editExpense_SUCCESS': {
            let oldCat = state.Banks;
            let newCat = oldCat.map(Item => {
                if (Item._id === action.payload.Record._id) {
                    return action.payload.Record;
                } else {
                    return Item
                }
            })
            return { ...state, recordEditSuccess: true, Banks: newCat, ceLoader: false, success: true, message: 'recordUpdateSuccessfully', modalVisible: false };
        }
        case 'editExpense_FAILURE': {
            return { ...state, recordEditError: true, recordEditSuccess: false, ceLoader: false, message: action.payload, modalVisible: false };
        }

        case 'ce_UPDATE': {
            return {
                ...state,
                recordEditSuccess: false,
                recordEditError: false,
                modalVisible: false,
                resendError: false,
                resendSuccess: false,
                ceLoader: false,
                resetPasswordSuccess: false,
                error: false,
                success: false,
                assignEmployeeModalVisible: false,
                message: "",
                selectedItem: null,
                refundExpenseError : false,
    refundExpenseSuccess : false

            };
        }

        case 'deleteExpense_SUCCESS': {
            const deletedRecord = action.payload.Record;
            const oldCat = state.Banks;
            const newCat = _.filter(oldCat, x => x._id !== deletedRecord._id);
            return { ...state, Banks: newCat, selectedItem: null, success: true, message: 'recordDeletedSuccessfully' };
        }

        default:
            return state;
    }

}
