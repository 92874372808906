import React, { useEffect } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
} from "@coreui/react-pro";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import DocsExample from "../../components/DocsExample";
import { DataGetAction } from "../../redux/actions/http";
import StockList from './components/StockList'
import CheckUserAccess from '../../util/checkUserAccess'
import {notify} from '../../util/ShowToast';
const Index = () => {

    const dispatch = useDispatch();
    const comProps = useSelector(state => state.productReducer);
    const productProps = useSelector(state => state.ProductR);
    const currencyProps = useSelector(state => state.currencyReducer);
    const supplierProps = useSelector(state => state.supplier);




    const history = useHistory();


    const fetchProduct = () => {
        dispatch(DataGetAction('getAllStock', { query: "all" }, 'allStockSpinner'));
         dispatch(DataGetAction('getAllProductStock', { query: "all" }));
        dispatch(DataGetAction('getAllCurrency', { query: "all" }));
        dispatch(DataGetAction('getAllSupplier', { query: "all" }));

    }
    useEffect(fetchProduct, [comProps.fetchProductInfo])


    if (comProps.EditStockSuccess) {
        notify('success', 'Success!');
        dispatch(DataGetAction('getAllStock', { query: "all" }));
        dispatch({
            type: 'Update_Stock_Reset'
        })
    }

    const redirectToAddStockPage = () => {
        history.push('/addStock');
    }
    const create = CheckUserAccess('stockEntry',['create'])
    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    <CCardHeader className="d-flex justify-content-between align-items-center">
                        <h1>
                        <IntlMessages id="product.title" />
                        </h1>
                       {create && <CButton
                            color="primary"
                            onClick={() => redirectToAddStockPage()}
                        >
                            <i className="icon icon-add text-white me-2"></i>
                            <IntlMessages id="product.add.product.button" />
                        </CButton>}
                    </CCardHeader>
                    <CCardBody>
                        <DocsExample href="components/smart-table/">
                            <StockList data={comProps.Products} spinner={comProps.allStockSpinner} />
                        </DocsExample>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    )
}

export default Index
