import _ from "lodash";

const INIT_STATE = {
  LoginVisible: false,
  modalVisible: false,
  modalEditVisible: false,
  passModalVisible: false,
  selectedItem: null,
  contractLoader: false,
  error: false,
  success: false,
  resetPasswordSuccess: false,
  resendSuccess: false,
  resendError: false,
  recordEditSuccess: false,
  recordEditError: false,
  assignEmployeeModalVisible: false,
  Contracts: [],
  fetchContractInfo: false,
  contractSpinner: false,
  properties: [],
  clients: [],
  currencies: [],
  vacantUnits: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "contracts_SUCCESS": {
      return { ...state, Contracts: action.payload.Record, fetchContractInfo: false };
    }
    case "contractSpinner_START": {
      return { ...state, contractSpinner: true };
    }
    case "contractSpinner_STOP": {
      return { ...state, contractSpinner: false };
    }
    case "contract_START": {
      return { ...state, contractLoader: true };
    }
    case "contract_STOP": {
      return { ...state, contractLoader: false };
    }
    case "show_contract_modal": {
      return { ...state, modalVisible: !state.modalVisible, selectedItem: action.payload };
    }
    case "contractFormData_SUCCESS": {
      return {
        ...state,
        properties: action.payload.properties,
        clients: action.payload.clients,
        currencies: action.payload.currencies,
      };
    }
    case "allVacantUnits_SUCCESS": {
      return {
        ...state,
        vacantUnits: action.payload.Record,
      };
    }
    case "addContract_SUCCESS": {
      return {
        ...state,
        Contracts: [...state.Contracts, action.payload.Record],
        success: true,
        message: "recordAddedSuccessfully",
        contractLoader: false,
      };
    }
    case "addContract_FAILURE": {
      return { ...state, error: true, success: false, contractLoader: false, message: action.payload };
    }
    case "contract_UPDATE": {
      return {
        ...state,
        recordEditSuccess: false,
        recordEditError: false,
        modalVisible: false,
        modalEditVisible: false,
        resendError: false,
        resendSuccess: false,
        contractLoader: false,
        resetPasswordSuccess: false,
        error: false,
        success: false,
        assignEmployeeModalVisible: false,
        message: "",
        selectedItem: null,
      };
    }

    case "contract_STOP": {
      return { ...state, contractLoader: false };
    }
    case "deleteContract_SUCCESS": {
      const deletedRecord = action.payload.Record;
      const oldCat = state.Contracts;
      const newCat = _.filter(oldCat, (x) => x._id !== deletedRecord._id);
      return { ...state, Contracts: newCat, selectedItem: null, success: true, message: "recordDeletedSuccessfully" };
    }
    default:
      return state;
  }
};
