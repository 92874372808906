import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
// import TransactionType from 'components/Table/BasicTable'
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { cilPrint } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  ChangeDateFormate,
  ChangeNumberFormate,
  NumberWithCommas,
  ChangeNumberToWord,
} from "../../../constants/CommonFn";
import InvoiceProductList from "../../../components/InvoiceTable";
import SendEmailModal from "../../../components/SendEmailModal";
import { CButton } from "@coreui/react-pro";
import InvoiceSignature from "src/components/InvoiceSignature";
import DeleteModal from "src/components/Modals/DeleteModal";
const ReceiptDetail = () => {
  const componentRef = useRef();

  const logoImage = localStorage.getItem("profileImage");
  let address = localStorage.getItem("address");

  const dispatch = useDispatch();

  const invProps = useSelector((state) => state.quickSaleReducer);
  const authProps = useSelector((state) => state.auth);
  const { permissionAccess, LogeInUser } = authProps;
  const { locale } = useSelector(({ Setting }) => Setting);
  const { languageId } = locale;

  const {
    products,
    createdAt,
    receiptNumber,
    customerName,
    phoneNumber,
    status,
  } = invProps.saleDetailData !== null ? invProps.saleDetailData : "";

  const columns = [
    {
      label: <IntlMessages id="invoiceDetail.productName" />,
      key: "productName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.quantity" />,
      key: "Qty",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unit" />,
      key: "unitName",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.unitPrice" />,
      key: "Price",
      _style: { width: "40%" },
    },
    {
      label: <IntlMessages id="invoiceDetail.total" />,
      key: "total",
      _style: { width: "40%" },
    },
  ];

  const getAmountBill = () => {
    const Products = products && products;
    if (Products) {
      let total = 0;
      Products.map((x) => {
        total = total + parseFloat(x.Price) * parseFloat(x.Qty);
        return x;
      });




      
      return (
        <div className="d-flex justify-content-between mt-4">
          <p></p>
          <div style={{ width: "430px" }}>
            <div className="d-flex">
            <span className="d-inline-block"
              style={{
                width: "80%",
                fontSize: 13
              }}>
              </span>
              <span
                className="d-inline-block"
                style={{
                  width: "30%",
                  fontSize: 13
                }}
              >
                <IntlMessages id="invoiceDetail.totalBill" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "90%", fontSize: 20, fontWeight: "bold",  color: "#003366" }}
              >
                {ChangeNumberFormate(total)} GNF
              </span>
            </div>
            <div className="d-flex">
            <span className="d-inline-block"
              style={{
                width: "80%",
                fontSize: 13
              }}>
              </span>
              <span
                className="d-inline-block"
                style={{
                  width: "30%",
                  fontSize: 13,
                }}
              >
                <IntlMessages id="invoiceDetail.totalBillWords" />:
              </span>
              <span
                className="d-inline-block text-capitalize"
                style={{ width: "90%", fontSize: 13 }}
              >
                {ChangeNumberToWord(total, languageId)}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };
  const checkButtonPermission = (buttonPermissionKey) => {
    if (permissionAccess && LogeInUser.role !== "superAdmin") {
      let storeInvoicePermission =
        permissionAccess && permissionAccess["quickSale"];
      if (storeInvoicePermission.includes(buttonPermissionKey)) {
        return true;
      } else {
        return false;
      }
    } else if (LogeInUser.role === "superAdmin") {
      return true;
    }
  };
  const areYouSureYouWantToRefundTheInvoice = intlPlaceholder(
    "areYouSureYouWantToRefundTheQuickSale"
  );
  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          {status !== "cancelled" && checkButtonPermission("cancel") && (
            <DeleteModal
              contentLangueId={`${areYouSureYouWantToRefundTheInvoice} N°: 00${receiptNumber}`}
              method="PUT"
              type="cancelQuickSaleInvoice"
              selectedItem={{
                quickSaleId: invProps && invProps.data && invProps.data["_id"],
              }}
              textColor={"#ffffff"}
              ButtonTitleId="receipt.refund"
              titleLanguageId="receipt.refund"
              size="md"
              color="danger"
              variant=""
            />
          )}
         {status !== "cancelled" && <ReactToPrint
            trigger={() => (
              <CButton
                color="info"
                className="text-white me-3 d-flex align-items-center"
              >
                <CIcon icon={cilPrint} className="me-2" />
                <IntlMessages id="print" />
              </CButton>
            )}
            content={() => componentRef.current}
          />}
          {status === "cancelled" && (
            <CButton
              color="danger"
              size="md"
              disabled
              className="text-white me-3"
            >
              <IntlMessages id="refunded" />
            </CButton>
          )}
        </div>
      </div>
      <div
        className="print-invoice p-3 float-left w-100 pe-5 ps-5 mt-5"
        ref={componentRef}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <img src={logoImage} alt="" width="300" className="mb-2" />

            <p
              className="mb-0"
              style={{
                color: "grey",
                maxWidth: "255px",
                textAlign: "center",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              {address}
            </p>
          </div>
          {customerName && (
            <div
              className="d-flex flex-row justify-content-between"
              style={{
                textAlign: "center",
                color: "#012447",
                fontSize: "15px",
              }}
            >
              {customerName} <br />
              {phoneNumber}
            </div>
          )}
        </div>
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <div className="d-flex justify-content-center align-items-center">
              <h2 style={{ color: "#012447" }} className="mb-0">
                <span className="me-1">
                  <IntlMessages id="quickSaleDetail.Label" />
                </span>
                <span className="ms-1">N°: 00{receiptNumber}</span>
              </h2>
            </div>

            <small style={{ color: "grey" }}>
              {ChangeDateFormate(createdAt)}
            </small>
          </div>
        </div>
        <br />
        <div className="Table">
          <InvoiceProductList
            items={products}
            columns={columns}
            scopedColumns={{
              Qty: (record) => {
                return <td>{parseFloat(record.Qty).toFixed(3)}</td>;
              },
              unitName: (record) => {
                return (
                  <td>
                    {record.retail ? record.subunitName : record.unitName}
                  </td>
                );
              },
              Price: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(parseFloat(record.Price).toFixed(2))}
                  </td>
                );
              },
              total: (record) => {
                return (
                  <td style={{ textAlign: "right" }}>
                    {ChangeNumberFormate(
                      (
                        parseFloat(record.Price) * parseFloat(record.Qty)
                      ).toFixed(2)
                    )}
                  </td>
                );
              },
            }}
          />
        </div>

        {getAmountBill()}
        <br />
        <br />
        <InvoiceSignature showClient={false} />
      </div>
    </div>
  );
};

export default ReceiptDetail;
