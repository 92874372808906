import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CLoadingButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilBuilding,
  cilUserFollow,
} from "@coreui/icons";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  DataRequestAction,
  updateAllState,
} from "../../../../redux/actions/http";
import { notify } from "src/util/ShowToast";
import {intlPlaceholder} from "../../../../util/intlPlaceholder"
const Index = ({ initialValues }) => {
  const authProps = useSelector((state) => state.auth);
  const [usernameError, setUserError] = useState({
    userError: false,
    message: "",
  });
  const history = useHistory();

  const dispatch = useDispatch();




const firstNameRequired =   intlPlaceholder('firstNameRequired')
const lastNameRequired =   intlPlaceholder('lastNameRequired')
const userNameRequired =   intlPlaceholder('userNameRequired')
const emailRequired =   intlPlaceholder('emailRequired')
const passwordRequired =   intlPlaceholder('passwordRequired')
const confirmPassword_message =   intlPlaceholder('signUp.confirmPassword_message')
const confirmMatch_message =   intlPlaceholder('signUp.confirmMatch_message')
const companyUpdateSuccess =   intlPlaceholder('companyUpdateSuccess')
const tokenExpired =   intlPlaceholder('tokenExpired')
const updateYourInfo =   intlPlaceholder('updateYourInfo')
const oneTimeActivity =   intlPlaceholder('oneTimeActivity')
const congrats =   intlPlaceholder('congrats')




  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(firstNameRequired),
    lastName: Yup.string().required(lastNameRequired),
    username: Yup.string().required(userNameRequired),
    email: Yup.string().required(emailRequired).email(),
    password: Yup.string().required(passwordRequired),
    confirm: Yup.string()
      .required(confirmPassword_message)
      .oneOf([Yup.ref("password")], confirmMatch_message),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  const LoadPage = () => {
    reset(initialValues);
    return () => {
      dispatch(updateAllState("auth"));
    };
  };
  useEffect(LoadPage, []);

  const onFinishFailedCustom = ({ values, errorFields, outOfDate }) => {
    if (!values.username) {
      setUserError({
        userError: true,
        message: "Username require",
      });
    }
  };

  const onFinish = (values) => {
   
    values._id = initialValues._id;
    dispatch(
      DataRequestAction(
        "POST",
        "updateCompanyInfo",
        values,
        "",
        "signup",
        false
      )
    );
  };

  const redirectToLoginPage = () => {
    localStorage.clear();
    dispatch(updateAllState("auth"));
    history.push("/login");
  };

  const showSuccessAlert = () => {
    if (authProps.updateUserSuccess) {
      return (
        <SweetAlert
          success
          title={congrats}
          onConfirm={() => redirectToLoginPage()}
        >
          {companyUpdateSuccess}
         
        </SweetAlert>
      );
    }
  };

  // const checkUserExit = (e) => {
  //     if (form.getFieldError('userName').length === 0) {
  //         dispatch(DataRequestAction('POST', 'userNameCheck', { username: e }, '', 'userNameCheckLoader', false))
  //     } else {
  //         dispatch({
  //             type: 'userNameCheck_FAILURE',
  //             payload: form.getFieldError('userName')[0]
  //         })
  //     }
  // }

  if (authProps.updateUserError) {
    notify(
      "error",
      tokenExpired,
    );
    dispatch(updateAllState("auth"));
  }
  if (authProps.updateUserSuccess) {
    localStorage.clear();
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit(onFinish)}>
                  <div className="welcomeMessage text-center mb-5">
                    <h2 className="font-weight-bold mb-0">{updateYourInfo}</h2>
                    <p>
                     
                      {oneTimeActivity}
                    </p>
                  </div>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder={intlPlaceholder('firstName')}
                      autoComplete="firstName"
                      id="firstName"
                      className={errors.firstName ? "is-invalid" : ""}
                      {...register("firstName")}
                    />
                    <div className="invalid-feedback">
                      {errors.firstName?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                     placeholder={intlPlaceholder('lastName')}
                      autoComplete="lastName"
                      id="lastName"
                      className={errors.lastName ? "is-invalid" : ""}
                      {...register("lastName")}
                    />
                    <div className="invalid-feedback">
                      {errors.lastName?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUserFollow} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder={intlPlaceholder('userName')}
                      autoComplete="userName"
                      id="userName"
                      className={errors.username ? "is-invalid" : ""}
                      {...register("username")}
                    />
                    <div className="invalid-feedback">
                      {errors.username?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      placeholder={intlPlaceholder('email')}
                      autoComplete="email"
                      disabled
                      id="email"
                      className={errors.email ? "is-invalid" : ""}
                      {...register("email")}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder={intlPlaceholder('password')}
                      autoComplete="new-password"
                      id="password"
                      className={errors.password ? "is-invalid" : ""}
                      {...register("password")}
                    />
                    <div className="invalid-feedback">
                      {errors.password?.message}
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder={intlPlaceholder('repeatPassword')}
                      autoComplete="new-password"
                      id="confirm"
                      className={errors.confirm ? "is-invalid" : ""}
                      {...register("confirm")}
                    />
                    <div className="invalid-feedback">
                      {errors.confirm?.message}
                    </div>
                  </CInputGroup>

                  <div className="d-grid">
                    <CLoadingButton
                      color="success"
                      type="submit"
                      loading={authProps.ShowSignUpLoader}
                      disabled={authProps.ShowSignUpLoader}
                    >
                      
                     { intlPlaceholder("save")}
                    </CLoadingButton>
                  </div>
                </CForm>
                <p
                  className={`text-center ${
                    authProps.error
                      ? "error"
                      : authProps.success
                      ? "success"
                      : ""
                  }`}
                >
                  
                  {authProps.message}
                </p>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        {showSuccessAlert()}
      </CContainer>
    </div>
  );
};

export default Index;
