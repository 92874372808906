import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react-pro";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/http";

import { useLocation } from "react-router-dom";
import InvoiceDetail from './invoiceDetail'
const Index = () => {
   
  const dispatch = useDispatch();
    const location = useLocation();
    const invProps = useSelector(state => state.invoiceReducer);
    
    const fetchInvoiceInfo = () => {
        const { InvoiceNo, supplierId, currencyId, ourId } = location.state;
         dispatch(DataGetAction('getSupplerInvoiceDetail', {  supplierId, currencyId, ourId }, 'fetchingInvoice'))
        dispatch(DataGetAction('getCompanyDues', {  supplierId, currencyId, ourId }))
    }

    useEffect(fetchInvoiceInfo, [])


  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            
            <CCardBody>
            {invProps.invoiceLoader ? <CSpinner/> : <InvoiceDetail />}

        
            </CCardBody>
          </CCard>
        </CCol>
        
      
      </CRow>
    </>
  );
};

export default Index;
