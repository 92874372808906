import React, { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CLoadingButton,
  CFormSelect,
  CForm,
  CFormInput,
  CFormTextarea,
  CFormFloating,
  CFormLabel,
} from "@coreui/react-pro";
import IntlMessages from "../../../util/IntlMessages";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { DataEntryModalAction } from "../../../redux/actions/Common";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DataRequestAction } from "../../../redux/actions/http";
import NumberFormat from "react-number-format";
import {notify} from '../../../util/ShowToast'
const CurrencyExchangeForm = () => {
  const modalType = "show_currencyExchange_modal";
  const dispatch = useDispatch();
  const history = useHistory();

  const [tabChoose, setTabChoose] = useState("CustomTransaction");
  const [givenAmountDefaultValue, setGivenAmountDefaultValue] = useState();
  const [givenCurrency, setGivenCurrency] = useState([]);

  const currencyProps = useSelector((state) => state.currencyExchangeR);
  const {
    success,
    currencyExchangeId,
    message,
    ceLoader,
    modalVisible,
    selectedItem,
    currencyExchangeLoader,
  } = currencyProps;

  const comProps = useSelector((state) => state.currencyReducer);
  const { Currencies } = comProps;

  const validationSchema = Yup.object().shape({
    receivedCurrencyId: Yup.string().required(
      <IntlMessages id="currencyExchange.exchangeModal.receivingCurrency_message" />
    ),
    givenCurrencyId: Yup.string().required(
      <IntlMessages id="currencyExchange.exchangeModal.givenCurrency_message" />
    ),
    receivedAmount: Yup.string().required(
      <IntlMessages id="currencyExchange.exchangeModal.recievedAmount_message" />
    ),
    givenAmount: Yup.string().required(
      <IntlMessages id="currencyExchange.exchangeModal.givenAmount_message" />
    ),
    factor: Yup.string().required(
      <IntlMessages id="currencyExchange.exchangeModal.rate_message" />
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      givenCurrencyId: "",
      factor: "",
      receivedAmount: "",
      givenAmount: "",
    },
  };

  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;

  React.useEffect(() => {
    register("givenCurrencyId");
    register("factor");
    register("receivedAmount");
    register("givenAmount");
  }, [register, setValue]);

  const setLocalCurrencyIsDefault = () => {
    if (modalVisible) {
      setGivenCurrency([...Currencies]);
      const localCurrency =
        Currencies && Currencies.filter((Item) => Item.type === "local");

      if (localCurrency && localCurrency.length > 0) {
        reset({ givenCurrencyId: localCurrency[0]._id });
      }
    }
  };
  useEffect(setLocalCurrencyIsDefault, [modalVisible]);

  if (selectedItem !== null) {
    reset({ selectedItem });
  }

  const getGivenCurrency = (data) => {
    let currency = Currencies.filter((Item) => Item._id !== data);
    let receivedCurrencyIsNotGnf = Currencies.filter(
      (Item) => Item._id === data
    );

    setGivenCurrency([...currency]);
    if (receivedCurrencyIsNotGnf[0].type === "local") {
      setValue("givenCurrencyId", "");
      setValue("factor", "");
      setValue("receivedAmount", "");
      setValue("givenAmount", "");
    } else {
      const localCurrency =
        Currencies && Currencies.filter((Item) => Item.type === "local");
      const ReceivedCurrency = Currencies.filter((Item) => Item._id === data);

      let factor =
        parseInt(
          ReceivedCurrency &&
            ReceivedCurrency.length &&
            ReceivedCurrency[0].factor
        ) /
        parseInt(
          localCurrency && localCurrency.length && localCurrency[0].factor
        );
      if (tabChoose !== "CustomTransaction") {
        setValue("factor", factor);
      }
      setValue("receivedAmount", "");
      setValue("givenAmount", "");
    }
  };

  const getFactor = (data) => {
    const receivedCurrency = getValues("receivedCurrencyId");

   
    const givenCurrency = data;

    const ReceivedCurrency = Currencies.filter(
      (Item) => Item._id === receivedCurrency
    );
    const GivenCurrency = Currencies.filter(
      (Item) => Item._id === givenCurrency
    );
   
    if (
      ReceivedCurrency[0].symbol === process.env.REACT_APP_LOCAL_CURRENCY ||
      GivenCurrency[0].symbol === process.env.REACT_APP_LOCAL_CURRENCY
    ) {
      let factor =
        parseInt(
          ReceivedCurrency &&
            ReceivedCurrency.length &&
            ReceivedCurrency[0].factor
        ) /
        parseInt(
          GivenCurrency && GivenCurrency.length && GivenCurrency[0].factor
        );
      setValue( 'factor', factor );
    } else {
      const localCurrency = Currencies.filter((Item) => Item.type === "local");

      const PCurrencyFactor =
        parseInt(
          ReceivedCurrency &&
            ReceivedCurrency.length &&
            ReceivedCurrency[0].factor
        ) *
        parseInt(
          localCurrency && localCurrency.length && localCurrency[0].factor
        );
      const GFC = parseInt(PCurrencyFactor) / parseInt(GivenCurrency[0].factor);
      setValue( 'factor', GFC );
    }
  };
  const changeGivenAmount = (data) => {
    if (tabChoose !== "CustomTransaction") {
      const factor = getValues("factor");
      if (factor) {
        const givenAmount = parseInt(data) * factor;
        setGivenAmountDefaultValue(givenAmount);

        setValue( 'givenAmount', givenAmount );
      }
    }
  };

  const calculateTheFactor = (data) => {
    const value = getValues("receivedAmount");
    if (value !== "") {
      const factor = parseFloat(data) / parseFloat(value);
      setValue("factor", factor);
    }
  };

  const changeGivenAmountByFactor = (data) => {
    const value = getValues("receivedAmount");

    if (value) {
      const givenAmount = parseInt(data) * value;
      setGivenAmountDefaultValue(givenAmount);
      setValue('givenAmount', givenAmount );
    }
  };

  const callback = (key) => {
    setTabChoose(key);
  };

  const onCreate = (values) => {
    values.factor = parseFloat(values.factor);

    if (selectedItem === null) {
      dispatch(
        DataRequestAction(
          "POST",
          "addCurrencyExchange",
          values,
          "",
          "currencyExchange",
          false
        )
      );
    }
  };

  if (success) {
    let messageLng = intlPlaceholder(message)
    notify('success',messageLng);
    // message.success(message);
    dispatch({
      type: "currencyExchange_UPDATE",
    });
    history.push({
      pathname: "/viewCurrencyExchangeReceipt",
      state: { currencyExchangeId: currencyExchangeId },
    });
  }
  const showModal = (Item) => {
    dispatch({
      type: modalType,
      payload: Item,
    });
  };

  return (
    <div>
      <CButton color="primary" className="d-flex align-items-center" onClick={() => showModal(null)}>
        <i className="gx-icon-add-3 me-2"></i>
        <IntlMessages id="currencyExchange.add.currencyExchange.button" />
      </CButton>

      <CModal className="currency-exchange-modal" visible={modalVisible}>
        <CModalHeader
          onDismiss={() => dispatch(DataEntryModalAction(modalType, null))}
        >
          <CModalTitle>
            {intlPlaceholder(
              "currencyExchange.exchangeModal.addCurrencyExchange"
            )}
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleSubmit(onCreate)}>
          <CModalBody>
            <ul class="nav">
              <li
                class="nav-item"
                onClick={() => {
                  setTabChoose("CustomTransaction");
                  reset()
                }}
              >
                <a
                  class={`nav-link ${
                    tabChoose === "CustomTransaction" ? "active" : ""
                  }`}
                  aria-current="page"
                >
                  {<IntlMessages id="currencyExchange.exchangeModal.tab1" />}
                </a>
              </li>
              <li
                class="nav-item"
                onClick={() => {
                  setTabChoose("ManualTransaction");
                  reset()
                }}
              >
                <a
                  class={`nav-link ${
                    tabChoose === "ManualTransaction" ? "active" : ""
                  }`}
                >
                  {<IntlMessages id="currencyExchange.exchangeModal.tab2" />}
                </a>
              </li>
            </ul>

            {tabChoose === "CustomTransaction" && (
              <div className="tab-content CustomTransaction">
                <div className="row mb-4">
                  {/* receivedCurrencyId */}
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="receivedCurrencyId"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.receivedCurrencyId ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              getGivenCurrency(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder(
                                "currencyExchange.exchangeModal.receivingCurrency_placeholder"
                              )}
                            </option>
                            {Currencies &&
                              Currencies.map((Item) => {
                                return (
                                  <option value={Item._id}>
                                    {Item.symbol}
                                  </option>
                                );
                              })}
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                      {intlPlaceholder(
                        "currencyExchange.exchangeModal.receivingCurrency_placeholder"
                      )}
                    </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.receivedCurrencyId?.message}
                      </div>
                    </CFormFloating>
                  </div>

                  {/* givenCurrencyId */}
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="givenCurrencyId"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.givenCurrencyId ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder(
                                "currencyExchange.exchangeModal.givenCurrency_placeholder"
                              )}
                            </option>
                            {givenCurrency &&
                              givenCurrency.map((Item) => {
                                return (
                                  <option value={Item._id}>
                                    {Item.symbol}
                                  </option>
                                );
                              })}
                             
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                      {intlPlaceholder(
                        "currencyExchange.exchangeModal.givenCurrency_placeholder"
                      )}
                    </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.givenCurrencyId?.message}
                      </div>
                    </CFormFloating>
                  </div>
                </div>

                <div className="row">
                  {/* receivedAmount */}
                  <div className="col-md-6">
                    <div className="mb-3 number-format-input">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <NumberFormat
                            className={
                              errors.receivedAmount ? "is-invalid" : ""
                            }
                            allowNegative={false}
                            thousandSeparator={true}
                            onValueChange={(v) => {
                              changeGivenAmount(v.value);
                              onChange(v.value);
                            }}
                            value={value}
                            placeholder={intlPlaceholder(
                              "currencyExchange.exchangeModal.recievedAmount_placeholder"
                            )}
                          />
                        )}
                        name={"receivedAmount"}
                        control={control}
                      />
                      <div className="invalid-feedback">
                        {errors.receivedAmount?.message}
                      </div>
                    </div>
                  </div>
                  {/* givenAmount */}
                  <div className="col-md-6">
                    <div className="mb-3 number-format-input">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <NumberFormat
                            className={errors.givenAmount ? "is-invalid" : ""}
                            allowNegative={false}
                            thousandSeparator={true}
                            onValueChange={(v) => {
                              calculateTheFactor(v.value);

                              onChange(v.value);
                            }}
                            value={value}
                            placeholder={intlPlaceholder(
                              "currencyExchange.exchangeModal.givenAmount_placeholder"
                            )}
                          />
                         
                        )}
                        name={"givenAmount"}
                        control={control}
                      />
                      <div className="invalid-feedback">
                        {errors.givenAmount?.message}
                      </div>
                    </div>
                  </div>
                </div>

                {/* factor */}
               
                <CFormFloating className="mb-3">
                  <Controller
                    control={control}
                    name="factor"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormInput
                      onKeyUp={(e) => changeGivenAmountByFactor(e.target.value)}
                        value={value}
                        disabled
                        className={errors.factor ? "is-invalid" : ""}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        placeholder={intlPlaceholder(
                          "currencyExchange.exchangeModal.rate_placeholder"
                        )}
                        type="text"
                        id="factor"
                      />
                    )}
                  />
                  <CFormLabel htmlFor="factor">
                  {intlPlaceholder(
                    "currencyExchange.exchangeModal.rate_placeholder"
                  )}
                </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.factor?.message}
                  </div>
                </CFormFloating>

                {/* customerName */}
                <div className="mb-3">
                <CFormFloating className="mb-3">

                  <CFormInput
                    className={errors.customerName ? "is-invalid" : ""}
                    {...register("customerName")}
                    placeholder={intlPlaceholder(
                      "currencyExchange.exchangeModal.customerName_placeholder"
                    )}
                    type="text"
                    id="name"
                  />
                  <CFormLabel htmlFor="name">
            {intlPlaceholder("currencyExchange.exchangeModal.customerName_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.customerName?.message}</div>
        </CFormFloating>
         
                </div>

                {/* remark       */}
                <div className="mb-3">
                <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.remark ? "is-invalid" : ""}
                    {...register("remark")}
                    placeholder={intlPlaceholder(
                      "currencyExchange.exchangeModal.remark_placeholder"
                    )}
                    type="text"
                    id="name"
                  />
                    <CFormLabel htmlFor="name">
            {intlPlaceholder("currencyExchange.exchangeModal.remark_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.remark?.message}</div>
        </CFormFloating>
                
                </div>
              </div>
            )}
            {tabChoose === "ManualTransaction" && (
              <div className="tab-content ManualTransaction">
                <div className="row mb-4">
                  {/* receivedCurrencyId */}
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="receivedCurrencyId"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.receivedCurrencyId ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              getGivenCurrency(e.target.value);
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder(
                                "currencyExchange.exchangeModal.receivingCurrency_placeholder"
                              )}
                            </option>
                            {Currencies &&
                              Currencies.map((Item) => {
                                return (
                                  <option value={Item._id}>
                                    {Item.symbol}
                                  </option>
                                );
                              })}
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                      {intlPlaceholder(
                        "currencyExchange.exchangeModal.receivingCurrency_placeholder"
                      )}
                    </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.receivedCurrencyId?.message}
                      </div>
                    </CFormFloating>
                  </div>

                  {/* givenCurrencyId */}
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <Controller
                        control={control}
                        name="givenCurrencyId"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <CFormSelect
                            className={
                              errors.givenCurrencyId ? "is-invalid" : ""
                            }
                            value={value}
                            onChange={(e) => {
                              getFactor(e.target.value)
                              onChange(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {intlPlaceholder(
                                "currencyExchange.exchangeModal.givenCurrency_placeholder"
                              )}
                            </option>
                            {givenCurrency &&
                              givenCurrency.map((Item) => {
                                return (
                                  <option value={Item._id}>
                                    {Item.symbol}
                                  </option>
                                );
                              })}
                          </CFormSelect>
                        )}
                      />
                      <CFormLabel htmlFor="">
                      {intlPlaceholder(
                        "currencyExchange.exchangeModal.givenCurrency_placeholder"
                      )}
                    </CFormLabel>
                      <div className="invalid-feedback">
                        {errors.givenCurrencyId?.message}
                      </div>
                    </CFormFloating>
                  </div>
                </div>

                <div className="row">
                  {/* receivedAmount */}
                  <div className="col-md-6">
                    <div className="mb-3 number-format-input">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <NumberFormat
                            className={
                              errors.receivedAmount ? "is-invalid    custom-input-style" : "custom-input-style"
                            }
                            allowNegative={false}
                            thousandSeparator={true}
                            value={value}
                            onValueChange={(v) => {
                              changeGivenAmount(v.value);
                              onChange(v.value);
                            }}
                           
                            placeholder={intlPlaceholder('currencyExchange.exchangeModal.recievedAmount_placeholder')}
                          />
                        )}
                        name={"receivedAmount"}
                        control={control}
                      />
                      <div className="invalid-feedback">
                        {errors.receivedAmount?.message}
                      </div>
                    </div>
                  </div>
                  {/* factor */}
                  <div className="col-md-6">
                  <CFormFloating className="mb-3 ">
                  <Controller
                    control={control}
                    name="factor"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CFormInput
                        onKeyUp={(e) => changeGivenAmountByFactor(e.target.value)}
                        value={value}
                        
                        className={errors.factor ? "is-invalid" : ""}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        placeholder={intlPlaceholder(
                          "currencyExchange.exchangeModal.rate_placeholder"
                        )}
                        type="text"
                        id="factor"
                      />
                    )}
                  />
                  <CFormLabel htmlFor="factor">
                  {intlPlaceholder(
                    "currencyExchange.exchangeModal.rate_placeholder"
                  )}
                </CFormLabel>
                  <div className="invalid-feedback">
                    {errors.factor?.message}
                  </div>
                </CFormFloating>
                  
                  </div>
                </div>

                {/* given amount */}

                <div className="mb-3 number-format-input">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <NumberFormat
                            className={errors.givenAmount ? "is-invalid" : ""}
                            allowNegative={false}
                            disabled
                            thousandSeparator={true}
                            onValueChange={(v) => {
                              calculateTheFactor(v.value);

                              onChange(v.value);
                            }}
                            value={givenAmountDefaultValue}
                            defaultValue={givenAmountDefaultValue}
                            placeholder={intlPlaceholder(
                              "currencyExchange.exchangeModal.givenAmount_placeholder"
                            )}
                          />
                        )}
                        name={"givenAmount"}
                        control={control}
                      />
                      <div className="invalid-feedback">
                        {errors.givenAmount?.message}
                      </div>
                    </div>

                {/* customerName */}
                <div className="mb-3">
                  <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.customerName ? "is-invalid" : ""}
                    {...register("customerName")}
                    placeholder={intlPlaceholder(
                      "currencyExchange.exchangeModal.customerName_placeholder"
                    )}
                    type="text"
                    id="name"
                  />
                  <CFormLabel htmlFor="name">
            {intlPlaceholder("currencyExchange.exchangeModal.customerName_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.customerName?.message}</div>
        </CFormFloating>
               
                </div>

                {/* remark       */}
                <div className="mb-3">
                <CFormFloating className="mb-3">
                  <CFormInput
                    className={errors.remark ? "is-invalid" : ""}
                    {...register("remark")}
                    placeholder={intlPlaceholder(
                      "currencyExchange.exchangeModal.remark_placeholder"
                    )}
                    type="text"
                    id="name"
                  />
                         <CFormLabel htmlFor="name">
            {intlPlaceholder("currencyExchange.exchangeModal.remark_placeholder")}
          </CFormLabel>
          <div className="invalid-feedback">{errors.remark?.message}</div>
        </CFormFloating>
              
             </div>
              </div>
            )}
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => dispatch(DataEntryModalAction(modalType, null))}
            >
              <IntlMessages id="currencyExchange.exchangeModal.cancel" />
            </CButton>
            <CLoadingButton
              type="submit"
              color="primary"
              loading={currencyExchangeLoader}
              disabled={currencyExchangeLoader}
            >
              {intlPlaceholder("currencyExchange.exchangeModal.save")}
            </CLoadingButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
};

export default CurrencyExchangeForm;
